import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Doughnut } from "react-chartjs-2";
import { useMediaQuery } from "react-responsive";
import { FlexBox, Text } from "../../Assets/css/common";

const ChartDoughnut = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [pic, setPic] = useState(null);

  // 판매상품_상품별매출>판매 카테고리에서 percent가 NaN인 경우 name 숨김
  var sliderTemp = pic?.map(function (item) {
    if (item.percent !== "NaN") {
      return item.percent;
    }
  });

  var sliderName = pic?.map(function (item) {
    if (item.percent !== "NaN") {
      return item.name;
    }
  });

  const colors1 = [
    "#FF4A4A",
    "rgba(255, 74, 74, 0.7)",
    "rgba(255, 74, 74, 0.5)",
    "rgba(255, 74, 74, 0.3)",
    "rgba(255, 74, 74, 0.15)",
    "#FF7440",
    "rgba(255, 116, 64, 0.7)",
    "rgba(255, 116, 64, 0.5)",
    "rgba(255, 116, 64, 0.3)",
    "rgba(255, 116, 64, 0.15)",
  ];

  const COLORS1 = sliderName?.map((value, index) => {
    return { color: colors1[index], value: value };
  });

  const data1 = {
    labels: sliderName,
    datasets: [
      {
        data: sliderTemp,
        backgroundColor: [
          "#FF4A4A",
          "rgba(255, 74, 74, 0.7)",
          "rgba(255, 74, 74, 0.5)",
          "rgba(255, 74, 74, 0.3)",
          "rgba(255, 74, 74, 0.15)",
          "#FF7440",
          "rgba(255, 116, 64, 0.7)",
          "rgba(255, 116, 64, 0.5)",
          "rgba(255, 116, 64, 0.3)",
          "rgba(255, 116, 64, 0.15)",
        ],
        borderColor: [
          "#FF4A4A",
          "rgba(255, 74, 74, 0.7)",
          "rgba(255, 74, 74, 0.5)",
          "rgba(255, 74, 74, 0.3)",
          "rgba(255, 74, 74, 0.15)",
          "#FF7440",
          "rgba(255, 116, 64, 0.7)",
          "rgba(255, 116, 64, 0.5)",
          "rgba(255, 116, 64, 0.3)",
          "rgba(255, 116, 64, 0.15)",
        ],
      },
    ],
  };

  const doughnutOptions = {
    cutout: "30%",
    spanGaps: true,
    interaction: {
      mode: "index",
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#fff",
        titleColor: "#000",
        titleFont: { weight: "400", size: isMobile ? "14" : "18", family: "Montserrat" }, // 모바일일 때 폰트 크기 조정
        bodyColor: "#FF4A4A",
        bodyFont: { weight: "bold", size: isMobile ? "16" : "20", family: "Montserrat" }, // 모바일일 때 폰트 크기 조정
        borderRadius: 20,
        padding: 14,
        usePointStyle: true,
        filter: (item) => item.parsed.y !== null,
        callbacks: {
          title: (context) => context[0].label,
          label: (context) => {
            const value = context.parsed;
            return `    ${value} %`;
          },
        },
      },
    },
  };

  useEffect(() => {
    setPic(props.pic);
  }, [props.pic]);

  return (
    <>
      <FlexBox direction={"column"}>
        <>
          <Doughnut type="Doughnut" data={data1} options={doughnutOptions} />
        </>
        {sliderTemp?.[0] !== undefined && (
          <CategoryContainer>
            {COLORS1 &&
              COLORS1.map((entry, index) => (
                <DoughnutBox key={index}>
                  <ColorBox bgColor={entry.color}></ColorBox>
                  <Text size={"12px"} sizeMd={"16px"} weight={"400"} color={"#666"}>
                    {entry.value}
                  </Text>
                </DoughnutBox>
              ))}
          </CategoryContainer>
        )}
      </FlexBox>
    </>
  );
};

export default ChartDoughnut;

const CategoryContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;

  @media all and (max-width: 1024px) {
    max-width: 100%;
  }
`;

const DoughnutBox = styled(FlexBox)`
  display: flex;
  justify-content: start;
  margin: 10px;
  width: 27%;

  @media all and (max-width: 1541px) {
    width: 30%;
  }

  @media all and (max-width: 1449px) {
    width: 50%;
  }

  @media all and (max-width: 1024px) {
    width: 30%;
  }

  @media all and (max-width: 367px) {
    width: 45%;
  }
`

const ColorBox = styled.div`
  background-color: ${(props) => props.bgColor || ""};
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
`;
