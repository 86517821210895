import React, { useEffect, useState } from "react";
import styled from "styled-components";
import close from "../../../Assets/images/close.png";
import { Btn, FlexBox, TextInput, Text, Select } from "../../../Assets/css/commonVer2";
import API from "../../../Utiles/API";
import axios from "axios";

// 1,2차 분류 등록 모달

function CategoryModal(props) {
  const { setModal, onSubmit, type, inputValue, setInputValue, setSelectValue } = props;
  const [cate1Data, setCate1Data] = useState([]);
  const [cate1Value, setCate1Value] = useState("");

  // API
  const getCate1List = async () => {
    const { data } = await API.get(`/admin/productCatrgory1st`);
    if (data.status) setCate1Data(data.franchiseList);
  };

  const onCate1Change = (e) => {
    setCate1Value(e.target.value);
    setSelectValue(e.target.value);
  };
  const onClose = () => {
    setModal(false);
  };

  useEffect(() => {
    getCate1List();
  }, []);

  return (
    <ModalBg>
      <ModalConts>
        <CancelBtn onClick={onClose}>
          <img src={close} />
        </CancelBtn>
        <Text color={"#2D2D2D"} size={"16px"} weight={"700"} style={{ paddingBottom: "20px" }}>
          {type}차 분류 등록
        </Text>
        <div>
          <InputBox
            type={"text"}
            value={inputValue}
            placeholder={`${type}차 분류명을 입력해주세요.`}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </div>
        <FlexBox margin={"30px 0 0"} marginMd={"40px 0 0"}>
          <Btn
            background={"#f2f2f2"}
            color={"#666"}
            border={"none"}
            weight={"500"}
            margin={"0 10px 0 0"}
            size={"12px"}
            sizeMd={"14px"}
            padding={"9px 19px"}
            paddingMd={"9px 19px"}
            onClick={onClose}
          >
            취 소
          </Btn>
          <Btn
            background={"#FF4A4A"}
            color={"#fff"}
            border={"none"}
            weight={"700"}
            size={"12px"}
            sizeMd={"14px"}
            padding={"9px 19px"}
            paddingMd={"9px 19px"}
            onClick={onSubmit}
          >
            저 장
          </Btn>
        </FlexBox>
      </ModalConts>
    </ModalBg>
  );
}

const ModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;
const ModalConts = styled.div`
  background-color: #fff;
  max-width: 380px;
  max-height: 600px;
  width: 100%;
  padding: 60px 40px 40px;
  border-radius: 8px;
  position: relative;

  @media all and (max-width: 1024px) {
    max-width: 280px;
    padding: 40px 20px 30px;
  }
`;
const CancelBtn = styled.button`
  position: absolute;
  right: 17.5px;
  top: 17.5px;

  @media all and (max-width: 1024px) {
    top: 12px;
    right: 12px;
  }
`;
const InputBox = styled(TextInput)`
  width: 100%;
`;
const SelectBox = styled(Select)`
  width: 100%;
`;

export default CategoryModal;
