import React from "react";
import styled from "styled-components";
import Spinner from "../Assets/images/loading_spinner_200px.gif";

export const Loading = () => {
  return (
    <Background>
      <LoadingText>처리중입니다.</LoadingText>
      <LoadingImage src={Spinner} alt="로딩중" />
    </Background>
  );
};

export default Loading;

const Background = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #ffffffb7;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoadingText = styled.div`
  //   font: 1rem "Noto Sans KR";
  font-size: 18px;
  font-width: 500;
  text-align: center;
`;

const LoadingImage = styled.img`
  width: 5%; /* 변경: 모바일 화면에서 가로 너비의 10%로 설정 */

  @media all and (max-width: 1024px) {
    width: 10%;
  }
`;
