import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useMediaQuery } from "react-responsive";
import API from "../../Utiles/API";
import {
  TableWrap,
  Th,
  Td,
  Btn,
  CheckLabel,
  DisableCheckIcon,
  CheckIcon,
  Wrapper,
  Container,
  ContentContainer,
  FlexBox,
  Text,
  TextBar,
  Title,
  WhiteBox,
} from "../../Assets/css/commonVer2";
import { PURCHASE_TYPE_LIST, WHOLE_SLOT_STATUS_LIST, INDIV_SLOT_STATUS_LIST } from "../../Utiles/ListData";
import HeaderSub from "../../Components/Header/HeaderSub";
import { AddComma, str } from "../../Utiles/Custom";
import InfoModal from "../../Components/Modal/InfoModal";
import RefundModal from "../../Components/Modal/RefundModal";

// (점주) 상품관리 > 자점매입 상품관리 > 슬롯구매내역 > 슬롯구매내역 상세
// (관리자) 자점매입 > 슬롯구매내역 > 슬롯구매내역 상세

function SlotHistoryView() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const location = useLocation();
  const isAdmin = sessionStorage.getItem("gubun") === "admin";
  const [franchise_id] = useState(sessionStorage.getItem("franchise_id"));

  // 다루는 데이터
  const [slotId] = useState(location.state.idx);
  const [orderInfo, setOrderInfo] = useState({});
  const [productList, setProductList] = useState([]);
  const [refundList, setRefundList] = useState([]);
  const [checkItems, setCheckItems] = useState([]);
  const [refundMode, setRefundMode] = useState(false);
  const [canRefund, setCanRefund] = useState(false);
  const [refundData, setRefundData] = useState({});
  const [totalRefundPrice, setTotalRefundPrice] = useState(0);

  // 모달들
  const [saveModal, setSaveModal] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const [textModal, setTextModal] = useState(false); // 모달 창 표시 여부 상태
  const [textMsg, setTextMsg] = useState("");
  const [refreshModal, setRefreshModal] = useState(false);

  // 최초 정보 조회 api
  const getSlotInfo = async () => {
    const { data } = await API.get(`/slot/purchase/detail?slot_id=${slotId}`);
    if (data.status) {
      setOrderInfo({
        franchise_name: data.results.franchise.franchise_name,
        status: data.results.status,
        slotCnt: data.results.purchase_count,
        slotType: data.results.purchase_type,
        createdAt: data.results.createdAt,
        expirationAt: data.results.expirationAt,
        price: data.results.purchase_price,
      });
      setProductList(data.results.actual_slots);
      if (data.results.refund_slots.length > 0) {
        setRefundList(data.results.refund_slots);
        const totalRefundPrice = data.results.refund_slots.reduce((total, slot) => total + slot.refund_price, 0);
        setTotalRefundPrice(totalRefundPrice);
      }
    }
    if (data.results.status !== "refund") {
      setCanRefund(true);
    }
  };

  // 환불 신청 api
  const postRefund = async () => {
    // 성공하고 페이지 다시 로드
    API.post(`/slot/refund`, {
      franchise_id: parseInt(franchise_id),
      purchase_slot_id: slotId,
      request_list: checkItems,
    })
      .then(function (res) {
        if (res.data.status) {
          setTextMsg("환불신청이 완료되었습니다.");
          setRefreshModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(function (error) {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const onChangeChx = (checked, id) => {
    if (checked) {
      setCheckItems((prev) => [...prev, id]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  const onChangeAllChk = (checked) => {
    if (checked) {
      const chkArr = productList.filter((item) => item.status !== "refund").map((item) => item.idx);
      setCheckItems(chkArr);
    } else {
      setCheckItems([]);
    }
  };

  const onSubmit = (e) => {
    if (e === 1) {
      setRefundModal(false);
      postRefund();
    }
  };

  const getRefundData = () => {
    // 환불 수량
    const refundSlotCount = checkItems.length;

    // 환불 금액 계산
    let refundAmount = 0;
    let remainingDays = 0;

    // 날짜 계산
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const expiredAt = new Date(orderInfo.expirationAt);
    expiredAt.setHours(0, 0, 0, 0);

    const createdAt = new Date(orderInfo.createdAt);
    createdAt.setHours(0, 0, 0, 0);

    const totalDays = (expiredAt - createdAt) / (1000 * 60 * 60 * 24);
    remainingDays = (expiredAt - today) / (1000 * 60 * 60 * 24);

    const costPerDay = orderInfo.price / totalDays / orderInfo.slotCnt;

    const singleSlotRefund = Math.min(remainingDays * costPerDay, orderInfo.price / orderInfo.slotCnt);

    refundAmount = singleSlotRefund * refundSlotCount;

    refundAmount = Math.min(refundAmount, orderInfo.price);

    refundAmount = Math.floor(refundAmount / 10) * 10;

    setRefundData({
      today: str(today.toLocaleString()), // 환불 요청일
      remainingDays: remainingDays, // 잔여 구독 일 수
      payedPrice: orderInfo.price, //  결제 금액
      costPerDay: costPerDay.toFixed(2), //결제 금액 / 30일
      refundSlotCount: refundSlotCount, // 환불 슬롯 수량
      refundAmount: refundAmount, // 총 환불 금액
    });
    setRefundModal(true);
  };

  const checkCanRefund = () => {
    // 정기권 && 구매 후 3개월 지남 체크
    let canRefund = true;
    if (orderInfo.slotType === "regular_purchase") {
      const refund_date = new Date(orderInfo.createdAt);

      const currentDate = new Date();
      const timeDifference = currentDate.getTime() - refund_date.getTime();

      const days90InMilliseconds = 90 * 24 * 60 * 60 * 1000; // 90일

      canRefund = timeDifference >= days90InMilliseconds;
    }

    if (canRefund) {
      if (refundMode) {
        if (checkItems.length > 0) {
          getRefundData();
        } else {
          setTextMsg("환불신청할 슬롯을 선택해주세요.");
          setTextModal(true);
        }
      } else {
        setRefundMode(true);
      }
    } else {
      setTextMsg("구독형의 경우 구매 후 90일이 경과해야 \n해지할 수 있습니다.");
      setTextModal(true);
    }
  };

  useEffect(() => {
    getSlotInfo();
  }, []);

  return (
    <>
      <HeaderSub title={`슬롯 구매 내역`} />
      <ContentContainer>
        <Wrapper type={2}>
          <Container>
            <FlexBox>
              {!isMobile && (
                <FlexBox direction={"column"} align={"start"} style={{ flex: 1 }} gap={"6px"} gapMd={"10px"}>
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    슬롯 구매 내역 🛍
                  </Title>
                  <Text align={"left"} size={"14px"} sizeMd={"16px"} weight={"400"} color={"#1E1E1E"}>
                    주문, 상품 및 환불 정보의 자세한 내용을 확인해 보세요.
                  </Text>
                </FlexBox>
              )}

              <FlexBox>
                {refundMode && (
                  <Btn
                    background={"#C7C7C7"}
                    border={"1px solid #C7C7C7"}
                    color={"#fff"}
                    weight={"500"}
                    size={"14px"}
                    padding={"9px 19px"}
                    className="d-none d-md-block"
                    margin={"0 10px 0"}
                    onClick={() => {
                      setCheckItems([]);
                      setRefundMode(false);
                    }}
                  >
                    취소
                  </Btn>
                )}
                {canRefund && !isAdmin && (
                  <Btn
                    background={"#FF4A4A"}
                    color={"#fff"}
                    weight={"700"}
                    size={"14px"}
                    padding={"9px 19px"}
                    className="d-none d-md-block"
                    onClick={checkCanRefund}
                  >
                    {refundMode ? "신청하기" : "해지 신청"}
                  </Btn>
                )}
              </FlexBox>
            </FlexBox>
            <WhiteBox margin={"0 0"}>
              <FlexBox justify={"space-between"} marginMd={"0 0 10px"}>
                <Text
                  width={"100%"}
                  widthMd={"unset"}
                  color={"#FF4A4A"}
                  size={"16px"}
                  weight={"400"}
                  padding={"10px 10px"}
                  background={"rgba(255, 116, 64, 0.10)"}
                  radius={"8px"}
                  letterSpacing={"-0.32px"}
                  lineHeight={"normal"}
                >
                  주문 번호 : <span style={{ fontWeight: "700" }}>{slotId}</span>
                </Text>
              </FlexBox>
              {/* 공통 - 주문정보 */}
              <Title
                color={"#1E1E1E"}
                weight={"700"}
                size={"14px"}
                sizeMd={"20px"}
                style={{
                  marginTop: "20px",
                  paddingBottom: "10px",
                  borderBottom: "1px solid #eaeaea",
                }}
              >
                주문 정보
              </Title>
              <TableWrap>
                <table style={{ width: "100%" }}>
                  <colgroup>
                    <col style={{ width: "15%" }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr key={1}>
                      <Td
                        padding={"11px 20px"}
                        align="left"
                        bgColor={"#FAFAFB"}
                        style={{ borderTop: "1px solid #666666", borderBottom: "1px solid #F2F2F2" }}
                      >
                        상태
                      </Td>
                      <Td
                        padding={"11px 20px"}
                        color="#1E1E1E"
                        weight="700"
                        align="left"
                        style={{ borderTop: "1px solid #666666", borderBottom: "1px solid #F2F2F2" }}
                      >
                        {orderInfo.status
                          ? WHOLE_SLOT_STATUS_LIST.find((item) => item.value === orderInfo.status).text
                          : "-"}
                      </Td>
                    </tr>
                    {isAdmin && (
                      <tr key={2}>
                        <Td
                          padding={"11px 20px"}
                          align="left"
                          bgColor={"#FAFAFB"}
                          style={{ borderBottom: "1px solid #F2F2F2" }}
                        >
                          가맹점명
                        </Td>
                        <Td
                          padding={"11px 20px"}
                          color="#1E1E1E"
                          weight="700"
                          align="left"
                          style={{ borderBottom: "1px solid #F2F2F2" }}
                        >
                          {orderInfo ? orderInfo.franchise_name : ""}
                        </Td>
                      </tr>
                    )}
                    <tr key={3}>
                      <Td
                        padding={"11px 20px"}
                        align="left"
                        bgColor={"#FAFAFB"}
                        style={{ borderBottom: "1px solid #F2F2F2" }}
                      >
                        구매 슬롯 수량
                      </Td>
                      <Td
                        padding={"11px 20px"}
                        color="#1E1E1E"
                        weight="700"
                        align="left"
                        style={{ borderBottom: "1px solid #F2F2F2" }}
                      >
                        {orderInfo ? orderInfo.slotCnt : ""}개
                      </Td>
                    </tr>
                    <tr key={4}>
                      <Td
                        padding={"11px 20px"}
                        align="left"
                        bgColor={"#FAFAFB"}
                        style={{ borderBottom: "1px solid #F2F2F2" }}
                      >
                        구매 유형
                      </Td>
                      <Td
                        padding={"11px 20px"}
                        color="#1E1E1E"
                        weight="700"
                        align="left"
                        style={{ borderBottom: "1px solid #F2F2F2" }}
                      >
                        {orderInfo.slotType
                          ? PURCHASE_TYPE_LIST.find((item) => item.value === orderInfo.slotType).text
                          : "-"}
                      </Td>
                    </tr>
                    <tr key={5}>
                      <Td
                        padding={"11px 20px"}
                        align="left"
                        bgColor={"#FAFAFB"}
                        style={{ borderBottom: "1px solid #F2F2F2" }}
                      >
                        구매일
                      </Td>
                      <Td padding={"11px 20px"} align="left" style={{ borderBottom: "1px solid #F2F2F2" }}>
                        {orderInfo.createdAt ? str(orderInfo.createdAt) : ""}
                      </Td>
                    </tr>
                    <tr key={6}>
                      <Td
                        padding={"11px 20px"}
                        align="left"
                        bgColor={"#FAFAFB"}
                        style={{ borderBottom: "1px solid #F2F2F2" }}
                      >
                        {orderInfo?.slotType === "regular_purchase" ? "다음 결제일" : "만료일"}
                      </Td>
                      <Td padding={"11px 20px"} align="left" style={{ borderBottom: "1px solid #F2F2F2" }}>
                        {orderInfo.expirationAt && orderInfo?.status !== "refund" ? str(orderInfo.expirationAt) : "-"}
                      </Td>
                    </tr>
                    <tr key={7}>
                      <Td
                        padding={"11px 20px"}
                        align="left"
                        bgColor={"#FAFAFB"}
                        style={{ borderBottom: "1px solid #F2F2F2" }}
                      >
                        결제 금액
                      </Td>
                      <Td
                        padding={"11px 20px"}
                        color="#1E1E1E"
                        weight="700"
                        align="left"
                        style={{ borderBottom: "1px solid #F2F2F2" }}
                      >
                        {orderInfo ? AddComma(orderInfo.price) : ""}원
                      </Td>
                    </tr>
                  </tbody>
                </table>
              </TableWrap>
              {/* 공통 - 상품 정보 데이터 시작 */}
              <Title
                color={"#1E1E1E"}
                weight={"700"}
                size={"14px"}
                sizeMd={"20px"}
                style={{
                  marginTop: "30px",
                  paddingBottom: "20px",
                }}
              >
                상품 정보
              </Title>
              <TableWrap>
                <table style={{ width: "100%", minWidth: "1024px" }}>
                  <colgroup>
                    {refundMode && <col style={{ width: "4%" }} />}
                    <col style={{ width: "8%" }} />
                    <col style={{ width: "10%" }} />
                    <col />
                    <col />
                    <col />
                    {/* <col /> */}
                    <col style={{ width: "10%" }} />
                  </colgroup>
                  <thead>
                    <tr>
                      {refundMode && (
                        <Th>
                          <CheckLabel htmlFor="chkAll" style={{ justifyContent: "center" }}>
                            <input
                              type="checkbox"
                              id="chkAll"
                              onChange={(event) => onChangeAllChk(event.target.checked)}
                              checked={
                                checkItems.length === productList.filter((item) => item.status !== "refund").length
                                  ? true
                                  : false
                              }
                            />
                            <CheckIcon>
                              <svg viewBox="0 0 24 24">
                                <polyline points="19 7 10 17 5 12" />
                              </svg>
                            </CheckIcon>
                          </CheckLabel>
                        </Th>
                      )}

                      <Th>슬롯 번호</Th>
                      <Th>상품분류</Th>
                      <Th>바코드 번호</Th>
                      <Th>상품명</Th>
                      <Th>소비자가</Th>
                      <Th>상태</Th>
                    </tr>
                  </thead>
                  <tbody>
                    {productList?.length > 0 ? (
                      productList?.map((list, key) => (
                        <tr
                          style={{
                            cursor: refundMode ? "pointer" : "default",
                            backgroundColor: list?.status === "refund" && refundMode ? "#eaeaea" : "transparent",
                          }}
                          key={key}
                          onClick={(event) => {
                            if (refundMode) {
                              const targetElement = event.target;
                              // 체크박스 클릭 시 반복호출 방지
                              if (targetElement.nodeName !== "INPUT") {
                                // 클릭한 요소의 내용 확인
                                const dataType = targetElement.getAttribute("data-type");
                                // 환불인지 체크
                                if (list.status === "refund") {
                                  setTextMsg("이미 환불된 슬롯입니다.");
                                  setTextModal(true);
                                  return;
                                }
                                // 그 외 체크박스 함수 실행
                                const isChecked = document.getElementById(`prod${list.idx}`).checked;
                                onChangeChx(!isChecked, list.idx);
                              }
                            }
                          }}
                        >
                          {refundMode && (
                            <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                              <CheckLabel htmlFor={`prod${list.idx}`} style={{ justifyContent: "center" }}>
                                <input
                                  type="checkbox"
                                  name="prod"
                                  id={`prod${list.idx}`}
                                  readOnly
                                  checked={checkItems.includes(list.idx) ? true : false}
                                />
                                {list?.status === "refund" ? (
                                  <DisableCheckIcon>
                                    <svg viewBox="0 0 24 24">
                                      <polyline points="19 7 10 17 5 12" />
                                    </svg>
                                  </DisableCheckIcon>
                                ) : (
                                  <CheckIcon>
                                    <svg viewBox="0 0 24 24">
                                      <polyline points="19 7 10 17 5 12" />
                                    </svg>
                                  </CheckIcon>
                                )}
                              </CheckLabel>
                            </Td>
                          )}

                          <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{list.idx ? list.idx : "-"}</Td>
                          <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{list.cate ? list.cate : "-"}</Td>
                          <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                            {list.product?.barcode_num ? list.product.barcode_num : "-"}
                          </Td>
                          <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                            {list.product?.product_name ? list.product.product_name : isAdmin ? "-" : "-"}
                          </Td>
                          <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                            {list.product?.consumer_price ? AddComma(list.product.consumer_price) : "-"}
                            {list.product?.consumer_price ? "원" : ""}
                          </Td>
                          <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                            {list.status ? INDIV_SLOT_STATUS_LIST.find((item) => item.value === list.status).text : "-"}
                          </Td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={refundMode ? 6 : 7}
                          style={{
                            borderTop: "1px solid #e1e1e1",
                            textAlign: "center",
                            padding: 10,
                            fontSize: "14px",
                          }}
                        >
                          데이터가 없습니다.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </TableWrap>
              {/* 공통 - 환불 정보 데이터 시작 */}
              {refundList?.length > 0 && (
                <>
                  <FlexBox style={{ width: "100%" }} justify={"starts"} margin={"20px 0 0 "}>
                    <Title
                      color={"#1E1E1E"}
                      weight={"700"}
                      size={"14px"}
                      sizeMd={"20px"}
                      style={{
                        marginTop: "30px",
                        paddingBottom: "10px",
                      }}
                    >
                      환불 정보
                    </Title>
                  </FlexBox>
                  <FlexBox justify="start">
                    <Text
                      // width={"100%"}
                      // widthMd={"unset"}
                      color={"#FF4A4A"}
                      size={"14px"}
                      sizeMd={"16px"}
                      weight={"400"}
                      padding={"10px 20px"}
                      margin={"0 0  20px"}
                      background={"rgba(255, 116, 64, 0.10)"}
                      radius={"8px"}
                      letterSpacing={"-0.32px"}
                      lineHeight={"normal"}
                    >
                      총 환불금액 : <span style={{ fontWeight: "700" }}>{AddComma(totalRefundPrice)}원</span>
                    </Text>
                  </FlexBox>
                  <TableWrap>
                    <table style={{ width: "100%" }}>
                      <colgroup>
                        <col style={{ width: "4%" }} />
                        <col />
                        <col />
                        <col />
                        <col />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>No</Th>
                          <Th>환불일</Th>
                          <Th>환불 슬롯 수량</Th>
                          <Th>잔여 구독 일 수</Th>
                          <Th>환불 금액</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {refundList.length > 0 ? (
                          refundList.map((list, key) => (
                            <tr>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{key + 1}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {list.refund_date ? str(list.refund_date) : "-"}
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{list.count ? list.count : "-"}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {list.remain_date ? list.remain_date : "-"}
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {list.refund_price ? AddComma(list.refund_price) : "-"}
                                {list.refund_price ? "원" : ""}
                              </Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={5}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                </>
              )}
            </WhiteBox>
            {/* 공통 - 안내사항 시작 */}
            {!isAdmin && (
              <div className="greyBox" style={{ marginTop: "20px", padding: isMobile ? "20px 20px" : "28px 40px" }}>
                <Text
                  color={"#666666"}
                  size={"14px"}
                  sizeMd={"18px"}
                  weight={"700"}
                  align={"left"}
                  margin={"0 0 10px 0"}
                  style={{
                    latterSpacing: "-0.36px",
                    lineHeight: "normal",
                  }}
                >
                  이용안내
                </Text>
                <TextBar
                  align={"left"}
                  size={"14px"}
                  style={{
                    latterSpacing: "-0.28px",
                    lineHeight: "25px",
                  }}
                >
                  슬롯에 상품을 등록 후 검수 요청을 꼭 해주셔야 하며, 관리자의 검수 승인이 되어야 해당 상품을 판매할 수
                  있습니다.
                </TextBar>
                <TextBar
                  align={"left"}
                  size={"14px"}
                  style={{
                    latterSpacing: "-0.28px",
                    lineHeight: "25px",
                  }}
                >
                  모든 구매 슬롯은 중복적용 되지 않습니다. (ex. 1개월 10개 + 6개월 1개 = 11개의 슬롯)
                </TextBar>
                <TextBar
                  align={"left"}
                  size={"14px"}
                  style={{
                    latterSpacing: "-0.28px",
                    lineHeight: "25px",
                  }}
                >
                  구매 시 예치금에서 차감되며 상품을 등록 할 수 있도록 활성화 됩니다.
                </TextBar>
                <Text
                  color={"#666666"}
                  size={"14px"}
                  weight={"700"}
                  align={"left"}
                  margin={"10px 0 10px 0"}
                  style={{
                    latterSpacing: "-0.36px",
                    lineHeight: "normal",
                  }}
                >
                  1개월 이용권
                </Text>
                <TextBar
                  color={"#666666"}
                  align={"left"}
                  size={"14px"}
                  style={{
                    latterSpacing: "-0.28px",
                    lineHeight: "25px",
                  }}
                >
                  신청 및 구매 시 보유하신 ‘예치금'에서 차감되어 적용됩니다. 30일 동안 사용 가능하며, 30일 이후 자동
                  해지됩니다.
                </TextBar>
                <Text
                  color={"#666666"}
                  size={"14px"}
                  weight={"700"}
                  align={"left"}
                  margin={"10px 0 10px 0"}
                  style={{
                    latterSpacing: "-0.36px",
                    lineHeight: "normal",
                  }}
                >
                  6개월 이용권
                </Text>
                <TextBar
                  color={"#666666"}
                  align={"left"}
                  size={"14px"}
                  style={{
                    latterSpacing: "-0.28px",
                    lineHeight: "25px",
                  }}
                >
                  신청 및 구매 시 보유하신 ‘예치금'에서 차감되어 적용 됩니다. 총 180일동안 사용 가능하며, 180일 경과 후
                  자동 해지 됩니다.
                </TextBar>
                <Text
                  color={"#666666"}
                  size={"14px"}
                  weight={"700"}
                  align={"left"}
                  margin={"10px 0 10px 0"}
                  style={{
                    latterSpacing: "-0.36px",
                    lineHeight: "normal",
                  }}
                >
                  정기 결제 이용권
                </Text>
                <TextBar
                  color={"#666666"}
                  align={"left"}
                  size={"14px"}
                  style={{
                    latterSpacing: "-0.28px",
                    lineHeight: "25px",
                  }}
                >
                  정기 결제 첫 달은 신청 후 즉시 ‘예치금'에서 차감되며 적용됩니다. 두 번째 달부터는 마지막 결제일로부터
                  30일 후에 자동 결제되며 3개월 이후부터 해지 가능합니다.
                </TextBar>
                <TextBar
                  color={"#666666"}
                  align={"left"}
                  size={"14px"}
                  style={{
                    latterSpacing: "-0.28px",
                    lineHeight: "25px",
                  }}
                >
                  정기 결제일에 예치금이 부족한 경우 슬롯은 자동해지됩니다.
                </TextBar>
                <Text
                  color={"#666666"}
                  align={"left"}
                  size={"14px"}
                  margin={"10px 0 0  0"}
                  weight={"600"}
                  style={{
                    latterSpacing: "-0.28px",
                    lineHeight: "25px",
                  }}
                >
                  슬롯 해지 신청은 현재 페이지에서 가능합니다.
                </Text>
                <Text
                  color={"#666666"}
                  align={"left"}
                  size={"14px"}
                  weight={"600"}
                  style={{
                    latterSpacing: "-0.28px",
                    lineHeight: "25px",
                  }}
                >
                  환불액은 (총 결제금액 / 슬롯 개수) / 30 * 잔여 구독일 수 * 환불 슬롯 수량 입니다.
                </Text>
                <Text
                  color={"#666666"}
                  align={"left"}
                  size={"14px"}
                  margin={" 0 0 30px 0"}
                  weight={"600"}
                  style={{
                    latterSpacing: "-0.28px",
                    lineHeight: "25px",
                  }}
                >
                  환불 신청 즉시 등록된 상품은 판매중지되며 취소할 수 없습니다. 환불액은 예치금으로 반환됩니다.
                </Text>
                <Text
                  color={"#666666"}
                  size={"14px"}
                  sizeMd={"18px"}
                  weight={"700"}
                  align={"left"}
                  margin={"0 0 10px 0"}
                  style={{
                    latterSpacing: "-0.36px",
                    lineHeight: "normal",
                  }}
                >
                  주의 사항
                </Text>
                <TextBar
                  align={"left"}
                  size={"14px"}
                  style={{
                    latterSpacing: "-0.28px",
                    lineHeight: "25px",
                  }}
                >
                  자점 매입으로 구매 시 인증받지 않은 제품으로 인한 피해는 본사가 책임지지 않습니다.
                </TextBar>
                <TextBar
                  align={"left"}
                  size={"14px"}
                  style={{
                    latterSpacing: "-0.28px",
                    lineHeight: "25px",
                  }}
                >
                  구매하신 상품 중 일부 상품의 경우 검토를 통해 지원되지 않을 수 있습니다. (유통기한이 있는 상품, 출처가
                  불분명한 상품 등)
                </TextBar>
              </div>
            )}

            {refundMode && (
              <Btn
                margin={"20px 0 0"}
                width={"100%"}
                padding={"10px 30px"}
                border={"1px solid #C7C7C7"}
                background={"#C7C7C7"}
                color={"#fff"}
                radius={"6px"}
                size={"18px"}
                lineHeight={"22px"}
                weight={"700"}
                className={"block md:hidden"}
                onClick={() => {
                  setCheckItems([]);
                  setRefundMode(false);
                }}
              >
                취소
              </Btn>
            )}
            {!isAdmin && (
              <Btn
                className={"block md:hidden"}
                margin={"20px 0 0"}
                width={"100%"}
                border={"1px solid #FF4A4A"}
                padding={"10px 30px"}
                radius={"6px"}
                size={"18px"}
                lineHeight={"22px"}
                background={"#FF4A4A"}
                color={"#fff"}
                weight={"700"}
                onClick={checkCanRefund}
              >
                {refundMode ? "신청하기" : "해지 신청"}
              </Btn>
            )}
          </Container>
          <InfoModal modal={refreshModal} setModal={setRefreshModal} description={textMsg} mode={"refresh"} />
          {refundModal ? (
            <RefundModal setModal={setRefundModal} onSubmit={() => onSubmit(1)} refundData={refundData} />
          ) : (
            ""
          )}
          <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
        </Wrapper>
      </ContentContainer>
    </>
  );
}

export default SlotHistoryView;
