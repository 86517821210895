import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import {
  ContentContainer,
  Btn,
  Container,
  FlexBox,
  LeftArea,
  RightArea,
  Text,
  Title,
  Wrapper,
  WhiteBox,
  OrderAddrBox,
} from "../../Assets/css/commonVer2";
import img_banner from "../../Assets/images/banner_img.jpg";
import HeaderSub from "../../Components/Header/HeaderSub";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import API from "../../Utiles/API";
import { AddComma } from "../../Utiles/Custom";
import { hypen } from "../../Utiles/Custom";
import Authority from "../../Components/Modal/Authority";
import Loading from "../../Components/Loading";
import InfoModal from "../../Components/Modal/InfoModal";

// 택배비 금액 상관없이 5,000원으로 통일
// 주문하기

function GoodsOrder() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();
  const [franchise_id] = useState(sessionStorage.getItem("franchise_id"));
  // 에러 예외 처리 시 사용됨
  const [loading, setLoading] = useState(false);
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");

  // 이전화면에서 받은 데이터
  const location = useLocation();
  const itemList = location.state.itemList;
  const idxList = location.state.idxList;
  const price = location.state.price;

  // 서버에서 받아올 데이터
  const courierCharges = 5000;
  const [phoneNum, setPhoneNum] = useState(""); // 폰번호
  const [franchiseName, setFranchiseName] = useState(""); // 가맹점 이름
  const [franchiseAddr, setFranchiseAddr] = useState(""); // 가맹점 이름
  const [deposit, setDeposit] = useState(0); // 예치금
  const [banner, setBanner] = useState("");

  // 결제 확인 모달
  const [confirmModal, setConfirmModal] = useState(false); // 판매중 상품 모달
  const [delayModal, setDelayModal] = useState(false); // 입고지연 확인 모달
  const [delayProName, setDelayProName] = useState(""); // 입고지연 상품 이름

  // 기본 정보 받아오기
  const OrderInfo = async () => {
    try {
      const res = await API.get(`/storeowner/order?franchise_id=${franchise_id}`);
      if (res.data.status) {
        setPhoneNum(res.data.tel);
        setFranchiseName(res.data.franchise_name);
        setFranchiseAddr(res.data.address);
        setDeposit(res.data.deposit);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 주문 전 확인 -> pass(판매중)인 경우만 doOrder 실행
  const checkOrder = async () => {
    try {
      if (deposit >= courierCharges + price || franchise_id === "24") {
        setLoading(true);
        const newList = itemList.map((currentItem) => ({
          product_id: currentItem.product_id,
          quantity: currentItem.quantity,
        }));
        await API.post("/storeowner/check-order", {
          products: newList,
        }).then((res) => {
          setLoading(false);
          if (res.data.results.sale_status === "pass") {
            setConfirmModal(true);
          } else if (res.data.results.sale_status === "empty") {
            setTextMsg(
              `${res.data.results.data} 상품은\n입고지연입니다.\n장바구니에서 해당 상품 삭제 후\n재주문해 주시기를 바랍니다.`,
            );
            setTextModal(true);
          } else {
            setTextMsg(
              `${res.data.results.data} 상품이\n단종되었습니다.\n장바구니에서 해당 상품 삭제 후\n재주문해 주시기를 바랍니다.`,
            );
            setTextModal(true);
          }
        });
      } else {
        setTextMsg("예치금이 부족합니다.");
        setTextModal(true);
      }
    } catch (error) {
      setLoading(false);
      setTextMsg("E01\n잠시후 다시 시도해주세요.");
      setTextModal(true);
    }
  };
  // 주문하기
  const doOrder = async () => {
    try {
      setLoading(true);
      const newList = itemList.map((currentItem) => ({
        product_id: currentItem.product_id,
        quantity: currentItem.quantity,
      }));
      if (idxList.length > 0) {
        await API.post("/storeowner/order", {
          franchise_id: franchise_id,
          address: franchiseAddr,
          shopping_basket: idxList,
          products: newList,
        }).then((res) => {
          setLoading(false);
          setConfirmModal(false);
          setDelayModal(false);
          if (res.data.status) {
            navigate("/goods/ordercomplete", { replace: true });
          } else {
            setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
            setTextModal(true);
          }
        });
      } else {
        await API.post("/storeowner/order", {
          franchise_id: franchise_id,
          address: franchiseAddr,
          products: newList,
        }).then((res) => {
          setLoading(false);
          setConfirmModal(false);
          setDelayModal(false);
          if (res.data.status) {
            navigate("/goods/ordercomplete", { replace: true });
          } else {
            setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
            setTextModal(true);
          }
        });
      }
    } catch (error) {
      setLoading(false);
      setTextMsg("E01\n잠시후 다시 시도해주세요.");
      setTextModal(true);
    }
  };

  // 배너 데이터 가져오기
  const notice = async () => {
    await API.get(`/admin/notice-event`).then((res) => {
      if (res.data.status) {
        let bannerData = [];
        for (var i = 0; i < res.data.result.image_path.length; i++) {
          if (res.data.result.image_path[i]?.includes(isMobile ? "type3" : "type2")) {
            bannerData = {
              idx: res.data.result.idx,
              image_path_1: res.data.result.image_path[i],
            };
          }
        }
        setBanner(bannerData);
      }
    });
  };

  useEffect(() => {
    OrderInfo();
    notice();
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {loading ? <Loading /> : null}
          <HeaderSub title={"주문하기"} />
          <ContentContainer>
            <Wrapper type={2} pbM={"122px"}>
              <Container>
                {!isMobile ? (
                  <>
                    <FlexBox justify={"space-between"} style={{ flex: 1 }}>
                      <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                        주문하기
                      </Title>
                      <FlexBox />
                    </FlexBox>
                    <FlexBox
                      align={"normal"}
                      wrap={"wrap"}
                      wrapMd={"flex-wrap"}
                      gap={"30px"}
                      gapMd={"10px"}
                      gapLg={"20px"}
                      margin={"30px 0"}
                    >
                      <LeftArea>
                        <WhiteBox margin={"17px 0"} marginMd={"0 0"} paddingMd="28px 30px">
                          <Title
                            color={"#1E1E1E"}
                            weight={"700"}
                            size={"18px"}
                            style={{
                              paddingBottom: "10px",
                              borderBottom: "1px solid #666",
                            }}
                          >
                            배송지 정보
                          </Title>
                          <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                            <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"18px"}>
                              가맹점명
                            </Text>
                            <Text color={"#1E1E1E"} align={"left"} size={"18px"}>
                              {franchiseName}
                            </Text>
                          </OrderAddrBox>
                          <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                            <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"18px"}>
                              휴대폰번호
                            </Text>
                            <Text color={"#1E1E1E"} align={"left"} size={"18px"}>
                              {hypen(phoneNum)}
                            </Text>
                          </OrderAddrBox>
                          <OrderAddrBox align={"flex-start"} justify={"flex-start"} style={{ marginTop: "15px" }}>
                            <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"18px"}>
                              주소
                            </Text>
                            <Text color={"#1E1E1E"} align={"left"} size={"18px"}>
                              {franchiseAddr}
                            </Text>
                          </OrderAddrBox>
                          <div
                            style={{
                              marginTop: "20px",
                              paddingTop: "20px",
                              borderTop: "1px solid #C7C7C7",
                            }}
                          >
                            <FlexBox align={"flex-start"} justify={"flex-start"} style={{ marginTop: "15px" }}>
                              <Text width={"135px"} color={"#1E1E1E"} weight={"500"} align={"left"} size={"18px"}>
                                상품명
                              </Text>
                              <Text>
                                {itemList?.map((list) => (
                                  <Text color={"#1E1E1E"} align={"left"} size={"18px"} style={{ marginBottom: "20px" }}>
                                    {list.product_name}
                                    <span style={{ color: "gray" }}>
                                      {" x "}
                                      {list.quantity}
                                    </span>
                                  </Text>
                                ))}
                              </Text>
                            </FlexBox>
                          </div>
                        </WhiteBox>
                      </LeftArea>
                      <RightArea>
                        <WhiteBox margin={"17px 0"} marginMd={"0 0"} paddingMd="28px 30px">
                          <Title
                            color={"#1E1E1E"}
                            weight={"700"}
                            size={"18px"}
                            style={{
                              paddingBottom: "10px",
                              borderBottom: "1px solid #666",
                            }}
                          >
                            결제 정보
                          </Title>
                          <FlexBox justify={"space-between"} style={{ paddingTop: "20px" }}>
                            <Text color={"#1E1E1E"} weight={"500"} size={"18px"}>
                              결제 수단
                            </Text>
                            <Text color={"#1E1E1E"} size={"18px"}>
                              예치금 차감
                            </Text>
                          </FlexBox>
                          <Text color={"#1E1E1E"} weight={"500"} align={"left"} padding={"20px 0 0"} size={"18px"}>
                            총 결제 금액
                          </Text>
                          <WhiteBox marginMd={"20px 0 0"} paddingMd="20px">
                            <FlexBox justify={"space-between"}>
                              <Text color={"#1E1E1E"} weight={"500"} size={"18px"}>
                                상품 가격
                              </Text>
                              <Text color={"#1E1E1E"} size={"18px"}>
                                {AddComma(price)}원
                              </Text>
                            </FlexBox>
                            <FlexBox justify={"space-between"} padding={"20px 0 0"}>
                              <Text color={"#1E1E1E"} weight={"500"} size={"18px"}>
                                예상 택배비
                              </Text>
                              <Text color={"#1E1E1E"} size={"18px"}>
                                {AddComma(courierCharges)}원
                              </Text>
                            </FlexBox>
                            <FlexBox
                              justify={"space-between"}
                              margin={"20px 0 0"}
                              padding={"20px 0 0"}
                              style={{
                                borderTop: "1px solid #969696",
                              }}
                            >
                              <Text color={"#1E1E1E"} weight={"500"} size={"18px"}>
                                총 결제 예정 금액
                              </Text>
                              <Text color={"#1E1E1E"} weight={"700"} size={"18px"}>
                                {AddComma(courierCharges + price)}원
                              </Text>
                            </FlexBox>
                            <FlexBox justify={"space-between"} padding={"20px 0 0"}>
                              <Text color={"#1E1E1E"} weight={"500"} size={"18px"}>
                                결제 후 잔여 예치금
                              </Text>
                              <Text color={"#1E1E1E"} size={"18px"}>
                                {AddComma(deposit - (courierCharges + price))}원
                              </Text>
                            </FlexBox>
                          </WhiteBox>
                          <Btn
                            color={"#fff"}
                            weight={"700"}
                            background={"#FF4A4A"}
                            width={"100%"}
                            size={"14px"}
                            padding={"15px 0 16px"}
                            margin={"100px 0 0"}
                            onClick={checkOrder}
                          >
                            주문하기
                          </Btn>
                        </WhiteBox>
                      </RightArea>
                    </FlexBox>
                  </>
                ) : (
                  <>
                    <WhiteBox>
                      <Title
                        color={"#1E1E1E"}
                        weight={"700"}
                        size={"16px"}
                        style={{
                          paddingBottom: "10px",
                          borderBottom: "1px solid #666",
                        }}
                      >
                        배송지 정보
                      </Title>
                      <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"}>
                          가맹점명
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"}>
                          {franchiseName}
                        </Text>
                      </OrderAddrBox>
                      <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"}>
                          휴대폰번호
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"}>
                          {hypen(phoneNum)}
                        </Text>
                      </OrderAddrBox>
                      <OrderAddrBox align={"flex-start"} justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"}>
                          주소
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"}>
                          {franchiseAddr}
                        </Text>
                      </OrderAddrBox>
                      <div
                        style={{
                          marginTop: "16px",
                          marginBottom: "20px",
                          paddingTop: "20px",
                          borderTop: "1px solid #C7C7C7",
                        }}
                      >
                        <FlexBox align={"flex-start"} justify={"flex-start"}>
                          <Text width={"90px"} color={"#1E1E1E"} weight={"500"} align={"left"}>
                            상품명
                          </Text>
                          <Text>
                            {itemList?.map((list) => (
                              <Text color={"#1E1E1E"} align={"left"} style={{ marginBottom: "5px" }}>
                                {list.product_name}
                                <span style={{ color: "gray" }}>
                                  {" x "}
                                  {list.quantity}
                                </span>
                              </Text>
                            ))}
                          </Text>
                        </FlexBox>
                      </div>
                    </WhiteBox>
                    <WhiteBox>
                      <Title
                        color={"#1E1E1E"}
                        weight={"700"}
                        size={"16px"}
                        style={{
                          paddingBottom: "10px",
                          borderBottom: "1px solid #666",
                        }}
                      >
                        결제 정보
                      </Title>
                      <FlexBox justify={"space-between"} style={{ paddingTop: "20px" }}>
                        <Text color={"#1E1E1E"} weight={"500"}>
                          결제 수단
                        </Text>
                        <Text color={"#1E1E1E"}>예치금 차감</Text>
                      </FlexBox>
                      <Text color={"#1E1E1E"} weight={"500"} align={"left"} padding={"16px 0 0"}>
                        총 결제 금액
                      </Text>
                      <WhiteBox>
                        <FlexBox justify={"space-between"}>
                          <Text color={"#1E1E1E"} weight={"500"}>
                            상품 가격
                          </Text>
                          <Text color={"#1E1E1E"}>{AddComma(price)}원</Text>
                        </FlexBox>
                        <FlexBox justify={"space-between"} padding={"10px 0 0"}>
                          <Text color={"#1E1E1E"} weight={"500"}>
                            예상 택배비
                          </Text>
                          <Text color={"#1E1E1E"}>{AddComma(courierCharges)}원</Text>
                        </FlexBox>
                        <FlexBox
                          justify={"space-between"}
                          margin={"10px 0 0"}
                          padding={"10px 0 0"}
                          style={{
                            borderTop: "1px solid #969696",
                          }}
                        >
                          <Text color={"#1E1E1E"} weight={"500"}>
                            총 결제 예정 금액
                          </Text>
                          <Text color={"#1E1E1E"} weight={"700"}>
                            {AddComma(courierCharges + price)}원
                          </Text>
                        </FlexBox>
                        <FlexBox justify={"space-between"} padding={"10px 0 0"}>
                          <Text color={"#1E1E1E"} weight={"500"}>
                            결제 후 잔여 예치금
                          </Text>
                          <Text color={"#1E1E1E"}>{AddComma(deposit - (courierCharges + price))}원</Text>
                        </FlexBox>
                      </WhiteBox>
                    </WhiteBox>

                    <Btn
                      color={"#fff"}
                      weight={"700"}
                      background={"#FF4A4A"}
                      width={"100%"}
                      size={"14px"}
                      padding={"9px 0"}
                      margin={"20px 0 0"}
                      lineHeight="22px"
                      onClick={checkOrder}
                    >
                      주문하기
                    </Btn>
                  </>
                )}

                <BannerWrap
                  className="mt-8 md:mt-10 "
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (banner.idx) {
                      navigate("/notice/View", { state: { idx: banner.idx } });
                    }
                  }}
                >
                  <img
                    src={
                      banner
                        ? `${process.env.REACT_APP_S3_KEY}${banner.image_path_1}` //${process.env.REACT_APP_S3_KEY}/${banner.image_path_1} / https://wzsoft-website.s3.ap-northeast-2.amazonaws.com/banner_4.jpg
                        : img_banner
                    }
                    alt="공지사항 배너"
                  />
                </BannerWrap>
              </Container>
              <ConfirmModal
                modal={confirmModal}
                setModal={setConfirmModal}
                onSubmit={doOrder}
                description={"해당 내용으로 주문하시겠습니까?"}
              />
              <ConfirmModal
                modal={delayModal}
                setModal={setDelayModal}
                onSubmit={doOrder}
                description={`${delayProName[0]} 외 ${delayProName?.length - 1}개 상품은\n주문 후 영업일 기준 5일 이상\n(주말 및 공휴일 제외) 지연되며,\n묶음 배송 처리됩니다.\n해당 내용으로 주문하시겠습니까?`}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const BannerWrap = styled.div`
  position: relative;
  padding-bottom: 0;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  @media all and (max-width: 1024px) {
    padding-bottom: 0;
  }
`;

export default GoodsOrder;
