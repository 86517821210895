/* code from sub_outsourcing */

// 상품마스터 > 반품관리 반품 정보 검색용 리듀서
export const SearchTakeBackReducer = (state, action) => {
  switch (action.type) {
    // 삼품 갯수 보기 수 변경
    case "CHANGE_UNIT":
      // console.log("######unit", action.value);
      // 반품 페이지 갯수 선택 시 1 페이지로 고정합니다
      // 4페이지에서 아이템 갯수를 40개로 변경 했을 때, 불러온 아이템의 갯수가 맞지 않다면, 빈화면만 출력이 된다
      return { ...state, unit: action.value, page: 1, search: true };

    // 검색 시작 날짜 변경
    case "CHANGE_START_DATE":
      // console.log("######CHANGE_START_DATE", state);
      return { ...state, start_date: action.value };

    // 끝 날짜 변경
    case "CHANGE_END_DATE":
      // console.log("######CHANGE_START_DATE", state);
      return { ...state, end_date: action.value };

    // 반품 번호로만 찾기
    case "SEARCH_BY_TAKEBACK_ID":
      // delete state.franchise_id
      return { ...state, take_back_id: action.value };

    // 가맹점 id로만 찾기
    case "SEARCH_BY_FEANCHISE_ID":
      delete state.take_back_id;
      return { ...state, franchise_id: action.value };

    case "CHANGE_NOW_PAGE":
      return { ...state, page: action.value, search: true };

    // false로 만들면 검색을 못한다.
    case "CHANGE_SEARCH":
      return { ...state, search: action.value };

    case "SET_STATE":
      return { ...state, ...action.value };

    case "DELETE_IDS":
      delete state.take_back_id;
      delete state.franchise_id;
      // console.log("#########state", state);
      return { ...state };

    default:
      return state;
  }
};

// 상품마스터 > 주문내역 조회/관리 주문내역 검색용 리듀서
export const SearchOrderReducer = (state, action) => {
  switch (action.type) {
    // 주문 갯수 보기 수 변경
    case "CHANGE_UNIT":
      // console.log("######unit", action.value);
      // 주문 페이지 갯수 선택 시 1 페이지로 고정합니다
      // 4페이지에서 아이템 갯수를 40개로 변경 했을 때, 불러온 아이템의 갯수가 맞지 않다면, 빈화면만 출력이 된다
      return { ...state, unit: action.value, page: 1, search: true };

    // 검색 시작 날짜 변경
    case "CHANGE_START_DATE":
      // console.log("######CHANGE_START_DATE", state);
      return { ...state, start_date: action.value };

    // 끝 날짜 변경
    case "CHANGE_END_DATE":
      // console.log("######CHANGE_START_DATE", state);
      return { ...state, end_date: action.value };

    // 가맹점 id로만 찾기
    case "SEARCH_BY_FEANCHISE_ID":
      return { ...state, franchise_id: action.value };

    case "CHANGE_NOW_PAGE":
      return { ...state, page: action.value, search: true };

    // false로 만들면 검색을 못한다.
    case "CHANGE_SEARCH":
      // console.log("#########state",SearchTakeBackReducer state);
      return { ...state, search: action.value };

    case "SET_STATE":
      return { ...state, ...action.value };

    case "DELETE_IDS":
      delete state.franchise_id;
      // console.log("#########state", state);
      return { ...state };

    default:
      return state;
  }
};

// 가맹마스터 > 가맹점 매출관리
export const SearchRevenueReducer = (state, action) => {
  // console.log(action);
  switch (action.type) {
    // 삼품 갯수 보기 수 변경
    case "CHANGE_UNIT":
      // console.log("######unit", action.value);
      // 반품 페이지 갯수 선택 시 1 페이지로 고정합니다
      // 4페이지에서 아이템 갯수를 40개로 변경 했을 때, 불러온 아이템의 갯수가 맞지 않다면, 빈화면만 출력이 된다
      return { ...state, unit: action.value, page: 1 };

    // 검색 시작 날짜 변경
    case "CHANGE_START_DATE":
      // console.log("######CHANGE_START_DATE", state);
      return { ...state, start_date: action.value };

    // 끝 날짜 변경
    case "CHANGE_END_DATE":
      // console.log("######CHANGE_START_DATE", state);
      return { ...state, end_date: action.value };

    // 가맹점 id 세팅
    case "SET_FEANCHISE_ID":
      return { ...state, franchise_id: action.value };

    case "CHANGE_NOW_PAGE":
      return { ...state, page: action.value };

    // false로 만들면 검색을 못한다.
    case "CHANGE_SEARCH":
      return { ...state, search: action.value };

    case "SET_STATE":
      return { ...state, ...action.value };

    case "CHAGE_CATEGORY":
      return { ...state, category: action.value, page: 1 };

    case "CHANGE_DATE_OPTION":
      return { ...state, option: action.value };

    default:
      return state;
  }
};

// 반품 정보 검색용 리듀서
export const SearchInventoryReducer = (state, action) => {
  // console.log(action);
  switch (action.type) {
    // 삼품 갯수 보기 수 변경
    case "CHANGE_UNIT":
      return { ...state, unit: action.value, page: 1 };

    case "CHANGE_NOW_PAGE":
      return { ...state, page: action.value };

    case "CHANGE_OPTION":
      return { ...state, options: action.value };

    case "SET_FRANCHISE_NAME":
      return { ...state, franchise_id: action.value };

    default:
      return state;
  }
};

// 쇼핑몰 > 매출관리
export const SearchShopSalesReducer = (state, action) => {
  switch (action.type) {
    // 일별 월별 토글
    case "CHANGE_DATE_OPTION":
      return { ...state, option: action.value };

    // 검색 시작 날짜 변경
    case "CHANGE_START_DATE":
      return { ...state, start_date: action.value };

    // 끝 날짜 변경
    case "CHANGE_END_DATE":
      return { ...state, end_date: action.value };

    // false로 만들면 검색을 못한다.
    case "CHANGE_SEARCH":
      return { ...state, search: action.value };

    default:
      return state;
  }
};

// 쇼핑몰 > 주문내역 조회 및 관리
export const SearchShopOrderReducer = (state, action) => {
  switch (action.type) {
    // 삼품 개수 보기 수 변경
    case "CHANGE_UNIT":
      return { ...state, unit: action.value, page: 1, search: true };

    // 검색 시작 날짜 변경
    case "CHANGE_START_DATE":
      return { ...state, start_date: action.value };

    // 끝 날짜 변경
    case "CHANGE_END_DATE":
      return { ...state, end_date: action.value };

    // 반품 번호로만 찾기
    case "SEARCH_BY_SHOP_ORDER_ID":
      return { ...state, filter_value: action.value };

    // 페이지 이동
    case "CHANGE_NOW_PAGE":
      return { ...state, page: action.value, search: true };

    // false로 만들면 검색을 못한다.
    case "CHANGE_SEARCH":
      return { ...state, search: action.value };

    case "DELETE_IDS":
      delete state.filter_value;
      return { ...state };

    default:
      return state;
  }
};

// 쇼핑몰 > 반품관리
export const SearchShopTakeBackReducer = (state, action) => {
  switch (action.type) {
    // 삼품 개수 보기 수 변경
    case "CHANGE_UNIT":
      return { ...state, unit: action.value, page: 1, search: true };

    // 검색 시작 날짜 변경
    case "CHANGE_START_DATE":
      return { ...state, start_date: action.value };

    // 끝 날짜 변경
    case "CHANGE_END_DATE":
      return { ...state, end_date: action.value };

    // 반품 번호와 검수 상태로 찾기
    case "SEARCH_BY_TAKEBACK_ID_AND_REVIEW":
      return { ...state, filter_type: action.reviewValue, filter_value: action.idValue };
        
    // 반품 번호로만 찾기
    case "SEARCH_BY_TAKEBACK_ID":
      delete state.filter_type;
      return { ...state, filter_value: action.value };

    // 검수 상태로만 찾기
    case "SEARCH_BY_REVIEW":
      delete state.filter_value;
      return { ...state, filter_type: action.value };

    // 페이지 이동
    case "CHANGE_NOW_PAGE":
      return { ...state, page: action.value, search: true };

    // false로 만들면 검색을 못한다.
    case "CHANGE_SEARCH":
      return { ...state, search: action.value };

    case "DELETE_IDS":
      delete state.filter_value;
      delete state.filter_type;
      return { ...state };

    default:
      return state;
  }
};
