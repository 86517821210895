import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import {
  ContentContainer,
  Wrapper,
  Container,
  Contents,
  WhiteBox,
  FlexBox,
  Title,
  Text,
  Btn,
  TextInput,
  UploadFileBtn,
  FileImg,
  FileXBtn,
  TableWrap,
  Th,
  Td,
  SearchInput,
} from "../../Assets/css/commonVer2";
import ico_close from "../../Assets/images/ico_close.png";
import ico_plus from "../../Assets/images/ico_plus.svg";
import ico_minus from "../../Assets/images/ico_minus.svg";
import ico_minus_disable from "../../Assets/images/ico_minus_disable.svg";
import HeaderSub from "../../Components/Header/HeaderSub";
import Authority from "../../Components/Modal/Authority";
import InfoModal from "../../Components/Modal/InfoModal";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import QuillContainer from "../../Components/QuillEditor/QuillContainer";
import Loading from "../../Components/Loading";
import { AddComma, handleKeyDown } from "../../Utiles/Custom";
import API from "../../Utiles/API";
import formAPI from "../../Utiles/formAPI";

// 쇼핑몰 > 프로모션 리스트 > 수정

function ShopPromotionEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [loading, setLoading] = useState(false);
  const idx = location.state.idx; // 쇼핑몰 상품 idx

  const shopPromotionQuillRef = useRef(); // 프로모션 설명 에디터 ref

  // 모달
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [listModal, setListModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [moveToBackModal, setMoveToBackModal] = useState(false);

  // 본사상품 검색_바코드 번호 인풋
  const [inputValue, setInputValue] = useState("");

  // 수정할 프로모션 데이터
  const [productList, setProductList] = useState([]); // 수정 시 보낼 본사상품 데이터
  const [promotionName, setPromotionName] = useState("");
  const [values, setValues] = useState({
    consumer_price: "",
    discount_rate: "",
    discount_price: "",
  });
  const [description, setDescription] = useState("");
  
  // 할인가 계산 시 보여줄 데이터
  const [priceValues, setPriceValues] = useState({
    consumer_price: "",
    discount_rate: "",
    discount_price: "",
  });

  // 프로모션 상품 이미지
  const proImgRef = useRef();
  const [showProFileBtn, setShowProFileBtn] = useState(true);
  const [proImgPath, setProImgPath] = useState([]); // 등록된 이미지 path
  const [newProImg, setNewProImg] = useState([]); // 추가한 이미지 파일
  const [newProPath, setNewProPath] = useState([]); // 추가한 이미지 path
  
  // 프로모션 이벤트 배너 이미지
  const eventImgRef = useRef();
  const [showEventFileBtn, setShowEventFileBtn] = useState(true);
  const [eventImgPath, setEventImgPath] = useState([]); // 등록된 이미지 path
  const [newEventImg, setNewEventImg] = useState([]); // 추가한 이미지 파일
  const [newEventPath, setNewEventPath] = useState([]); // 추가한 이미지 path

  // 상품 수량 변경 버튼 ref
  const intervalRef = useRef(null);

  // API
  // 1. 등록된 프로모션 조회
  const getPromotion = async () => {
    let api_link = `/shoppingmall/promotion/product?idx=${idx}`;

    await API.get(api_link).then((res) => {
      if (res.status) {
        setPromotionName(res.data.results.product_name)
        setDescription(res.data.results.information_detail);
        setValues({
          consumer_price: res.data.results.consumer_price,
          discount_rate: res.data.results.discount_rate,
          discount_price: res.data.results.discount_price,
        });
        setPriceValues({
          consumer_price: res.data.results.consumer_price,
          discount_rate: res.data.results.discount_rate,
          discount_price: res.data.results.discount_price,
        });
        // 프로모션으로 등록된 본사상품들 배열
        for (let i=0; i<res.data.results?.promotion_product_eaches.length; i++) {
          const eachProduct = {
            idx: res.data.results.promotion_product_eaches[i].product_id,
            barcode_num: res.data.results.promotion_product_eaches[i].barcode_num,
            product_name: res.data.results.promotion_product_eaches[i].product_name,
            product_count: res.data.results.promotion_product_eaches[i].product_count, // 상품 수량
            product_quantity: res.data.results.promotion_product_eaches[i].product_quantity,
            purchase_quantity: res.data.results.promotion_product_eaches[i].purchase_quantity,
            purchase_price: res.data.results.promotion_product_eaches[i].purchase_price,
            order_unit: res.data.results.promotion_product_eaches[i].product_order_unit,
          }
          setProductList(prev => [...prev, eachProduct]);
        }

        // 프로모션 등록 시 첨부한 이미지 path
        const proPathArr = [];
        const eventPathArr = [];

        for (let i=0; i<res.data.results?.shoppingmall_product_imgs?.length; i++) {
          if (res.data.results.shoppingmall_product_imgs[i].type === "product") {
            proPathArr.push(res.data.results.shoppingmall_product_imgs[i].image_path);
          } else {
            eventPathArr.push(res.data.results.shoppingmall_product_imgs[i].image_path)
          }
        }

        setProImgPath(proPathArr); // 수정 시 보낼 상품 이미지 path
        setEventImgPath(eventPathArr); // 수정 시 보낼 이벤트 배너 이미지 path
      }
    });
  };

  // 2. 추가할 본사상품 조회
  const getProductInfo = async () => {
    let api_link = `/shoppingmall/product-info?barcode_num=${inputValue}`;

    await API.get(api_link)
      .then((res) => {
        if (res.data.status) {
          // 검색한 본사상품 개별 데이터
          const eachProduct = {
            idx: res.data.results.idx,
            barcode_num: res.data.results.barcode_num,
            product_name: res.data.results.product_name,
            product_count: res.data.results.product_quantity, // 상품 수량
            product_quantity: res.data.results.product_quantity,
            purchase_quantity: res.data.results.purchase_quantity,
            purchase_price: res.data.results.purchase_price,
            order_unit: res.data.results.order_unit,
          }
          // 중복여부 체크
          const isDuplicate = productList.some(item => item.barcode_num === eachProduct.barcode_num);
          if (isDuplicate) {
            setTextMsg("이미 추가된 상품입니다.");
            setTextModal(true);
          } else {
            // 본사상품들 배열 업데이트
            setProductList(prev => [...prev, eachProduct]);
          }
        } else {
          setTextMsg(res.data.message || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  // 3. 쇼핑몰 프로모션 수정
  const putPromotion = async () => {
    let formData = new FormData();
    
    if (productList.length === 0) {
      setTextMsg("프로모션 상품을 추가해주세요.");
      setTextModal(true);
      return;
    }
    if (!promotionName) {
      setTextMsg("프로모션명을 입력해주세요.");
      setTextModal(true);
      return;
    }
    if (!priceValues.consumer_price) {
      setTextMsg("소비자가를 입력해주세요.");
      setTextModal(true);
      return;
    }
    if (!priceValues.discount_rate) {
      setTextMsg("할인율을 입력해주세요.");
      setTextModal(true);
      return;
    }
    if (proImgPath.length === 0 && newProPath.length === 0) {
      setTextMsg("프로모션 상품 이미지를 등록해주세요,");
      setTextModal(true);
      return;
    }
    if (eventImgPath.length === 0 && newEventPath.length === 0) {
      setTextMsg("이벤트 배너 이미지를 등록해주세요.");
      setTextModal(true);
      return;
    }
    if (description === "") {
      setTextMsg("상품 설명을 입력해주세요.");
      setTextModal(true);
      return;
    }
    setLoading(true);

    formData.append("idx", idx);
    formData.append("product_name", promotionName);
    formData.append("product_list", JSON.stringify(productList));
    formData.append("information_detail", description);
    formData.append("consumer_price", values.consumer_price);
    formData.append("discount_rate", values.discount_rate);
    formData.append("discount_price", values.discount_price);

    // 등록 시 첨부한 프로모션 상품 이미지
    if (proImgPath.length === 0) {
      formData.append("product_pre_img", []);
    } else {
      formData.append("product_pre_img", JSON.stringify(proImgPath));
    }
    
    // 등록 시 첨부한 이벤트 배너 이미지
    if (eventImgPath.length === 0) {
      formData.append("banner_pre_img", []);
    } else {
      formData.append("banner_pre_img", JSON.stringify(eventImgPath));
    }
    
    // 새로 추가한 프모션 상품 이미지
    if (newProImg) {
      newProImg.forEach((file) => {
        formData.append("product", file);
      });
    }
    
    // 새로 추가한 이벤트 베너 이미지
    if (newEventImg) {
      newEventImg.forEach((file) => {
        formData.append("banner", file);
      });
    }

    await formAPI.put(`/shoppingmall/promotion/product`, formData)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setMoveToBackModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setLoading(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  // 프로모션 상품 이미지 추가
  const handleProImgAdd = (e) => {
    const nowSelectImageList = e.target.files;
    const nowImageURLList = [...newProPath];
    
    if (proImgPath.length === 0) {
      if (newProPath.length + nowSelectImageList.length > 3) {
        setTextMsg("이미지는 최대 3개까지 등록 가능합니다.");
        setTextModal(true);
        return;
      }
    } else if (proImgPath.length === 1) {
      if (newProPath.length + nowSelectImageList.length > 2) {
        setTextMsg("이미지는 최대 3개까지 등록 가능합니다.");
        setTextModal(true);
        return;
      }
    } else if (proImgPath.length === 2) {
      if (nowSelectImageList.length > 1) {
        setTextMsg("이미지는 최대 3개까지 등록 가능합니다.");
        setTextModal(true);
        return;
      }
    }

    for (let i = 0; i < nowSelectImageList.length; i++) {
      const nowImageUrl = URL.createObjectURL(nowSelectImageList[i]);
      nowImageURLList.push(nowImageUrl);
      newProImg.push(nowSelectImageList[i]);
    }
    setNewProPath(nowImageURLList);
  };

  // 프로모션 상품 이미지 삭제
  const handleProImgRemove = (gubun, barcode_num) => {
    if (gubun === "existing") {
      // 기존 이미지를 지울때
      let copy = [...proImgPath];
      copy.splice(barcode_num, 1);
      setProImgPath(copy);
    } else if (gubun === "new") {
      //방금 추가한 이미지를 지울때
      let copy1 = [...newProPath];
      let copy2 = [...newProImg];

      copy1.splice(barcode_num, 1);
      copy2.splice(barcode_num, 1);

      setNewProPath(copy1);
      setNewProImg(copy2);
    }
  };

  // 프로모션 이벤트 배너 이미지 추가
  const handleEventImgAdd = (e) => {
    const nowSelectImageList = e.target.files;
    const nowImageURLList = [...newEventPath];

    if (nowSelectImageList.length > 1) {
      setTextMsg("이미지는 1개만 등록 가능합니다.");
      setTextModal(true);
      return;
    }

    const nowImageUrl = URL.createObjectURL(nowSelectImageList[0]);
    nowImageURLList.push(nowImageUrl);
    newEventImg.push(nowSelectImageList[0]);
    setNewEventPath(nowImageURLList);
  };

  // 프로모션 이벤트 배너 이미지 삭제
  const handleEventImgRemove = (gubun, barcode_num) => {
    if (gubun === "existing") {
      // 기존 이미지를 지울때
      let copy = [...eventImgPath];
      copy.splice(barcode_num, 1);
      setEventImgPath(copy);
    } else if (gubun === "new") {
      //방금 추가한 이미지를 지울때
      let copy1 = [...newEventPath];
      let copy2 = [...newEventImg];

      copy1.splice(barcode_num, 1);
      copy2.splice(barcode_num, 1);

      setNewEventPath(copy1);
      setNewEventImg(copy2);
    }
  };

  // 1 목록 2 저장 버튼
  const onSubmit = (e) => {
    if (e === 1) {
      setSaveModal(false);
      navigate(-1, { replace: true });
    } else {
      putPromotion();
      setSaveModal(false);
    }
  };

  // 본사상품 검색
  const onSearch = () => {
    if (inputValue) {
      getProductInfo();
    } else {
      setTextMsg("바코드 번호를 입력해주세요.");
      setTextModal(true);
    }
  };

  // 본사상품 삭제
  const removeProductItem = (e) => {
    const newItems = productList.filter(item => item.barcode_num !== e);
    setProductList(newItems);
  };
  
  // 할인가 계산
  const handleChange = (e) => {
    const updatedValues = {
      ...values,
      [e.target.name]: e.target.value.replaceAll(",", ""),
    };
    const updatedPriceValues = {
      ...priceValues,
      [e.target.name]: e.target.value ? AddComma(e.target.value.replaceAll(",", "")) : e.target.value,
    };
    // 할인가 계산
    if (e.target.name === "consumer_price" || e.target.name === "discount_rate") {
      const price = +document.querySelector("input[name=consumer_price]").value.replaceAll(",", "") || 0;
      const rate = +document.querySelector("input[name=discount_rate]").value.replaceAll(",", "") || 0;
      if (price && rate) {
        const calPrice = price - price * (rate / 100); // 할인율을 백분율로 사용하기 위해 100으로 나눔
        // 수정 시 보낼 소비자가, 할인율, 할인가 숫자로 할당
        updatedValues.consumer_price = price;
        updatedValues.discount_rate = rate;
        updatedValues.discount_price = calPrice;
        // 할인가는 콤마 붙인 문자 형식
        updatedPriceValues.discount_price = AddComma(calPrice);
      } else if (rate === 0) {
        // 수정 시 보낼 소비자가, 할인율, 할인가 숫자로 할당
        updatedValues.consumer_price = price;
        updatedValues.discount_rate = rate;
        updatedValues.discount_price = price;
        // 할인가는 콤마 붙인 문자 형식
        updatedPriceValues.discount_price = AddComma(price);
      } else {
        updatedValues.consumer_price = "";
        updatedValues.discount_rate = "";
        updatedValues.discount_price = "";
        updatedPriceValues.discount_price = "";
      }
    }
    setValues(updatedValues);
    setPriceValues(updatedPriceValues);
  };
  
  // 상품 수량 변경 핸들러
  const handleProductCount = (barcode, isIncrease) => {
    setProductList((prevList) => {
      const newList = prevList.map((item) => {
        if (item.barcode_num === barcode) {
          if (isIncrease) {
            let updatedCount = item.product_count + item.product_quantity;
            return { ...item, product_count: updatedCount }; // product_count 업데이트
          } else {
            let updatedCount = item.product_count - item.product_quantity;
            updatedCount = Math.max(updatedCount, item.product_quantity); // product_quantity보다 작을 수 없도록 제한
            return { ...item, product_count: updatedCount };
          }
        }
        return item;
      });
      return newList;
    });
  };

  // 상품 수량 증가 클릭
  const handleIncrementClick = (barcode) => {
    handleProductCount(barcode, true);
    // 증가 버튼을 눌렀을 때 500ms마다 handleProductCount 함수를 호출하여 연속 증가하도록 설정
    intervalRef.current = setInterval(() => {
      handleProductCount(barcode, true);
    }, 500);
  };

  // 상품 수량 감소 틀릭
  const handleDecrementClick = (barcode) => {
    handleProductCount(barcode, false);
    // 감소 버튼을 눌렀을 때 500ms마다 handleProductCount 함수를 호출하여 연속 감소하도록 설정
    intervalRef.current = setInterval(() => {
      handleProductCount(barcode, false);
    }, 500);
  };

  // 상품 수량 증가/감소 버튼 클릭 해제
  const handleButtonRelease = () => {
    // 버튼이 떼어질 때 interval을 클리어하여 동작을 멈춥니다.
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    if (proImgPath?.length > 2 || newProPath?.length > 2 || proImgPath?.length + newProPath?.length > 2) {
      setShowProFileBtn(false);
    } else {
      setShowProFileBtn(true);
    }
  }, [proImgPath, newProPath]);

  useEffect(() => {
    if (eventImgPath?.length === 1 || newEventPath?.length === 1) {
      setShowEventFileBtn(false);
    } else {
      setShowEventFileBtn(true);
    }
  }, [eventImgPath, newEventPath]);

  useEffect(() => {
    getPromotion();
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {loading ? <Loading /> : null}
          <HeaderSub title={"프로모션 수정"} />
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <div style={{ width: "100%" }}>
                    <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                      프로모션 리스트 🎁
                    </Title>
                  </div>
                )}
                <FlexBox justify={"end"} width={"100%"} wrap={"wrap"} wrapMd={"unset"} gap={"10px"}>
                  <SearchInput
                    borderBottom={"1px solid #666"}
                    borderBottomColor={"#ff4a4a"}
                    transition={"border-color 0.3s ease-in-out"}
                    placeholder={"바코드 번호를 입력해주세요."}
                    value={inputValue}
                    onChange={(e) => {
                      setInputValue(e.target.value);
                    }}
                    onKeyDown={(event) => handleKeyDown(event, onSearch)}
                  />
                  <Btn
                    color={"#fff"}
                    background={"#FF4A4A"}
                    size={"14px"}
                    weight={"700"}
                    width={"100%"}
                    widthMd={"unset"}
                    padding={"8px 18px"}
                    lineHeight={"22px"}
                    style={{ flexShrink: 0 }}
                    onClick={onSearch}
                  >
                    상품 추가
                  </Btn>
                </FlexBox>
                <WhiteBox margin={"0 0"}>
                  <Title color={"#1E1E1E"} weight={"700"} size={"14px"} sizeMd={"20px"}>
                    프로모션 수정
                  </Title>
                  <TableWrap>
                    <table style={{ width: "100%" }}>
                      <colgroup>
                        <col style={{ width: "8%" }} />
                        <col />
                        <col />
                        <col style={{ width: "12%" }} />
                        <col style={{ width: "12%" }} />
                        <col style={{ width: "12%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>No</Th>
                          <Th>바코드번호</Th>
                          <Th>상품명</Th>
                          <Th>상품수량</Th>
                          <Th>발주단위</Th>
                          <Th>삭제</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {productList.length > 0 ? (
                          productList?.map((item, key) => (
                            <tr key={key}>
                              <Td>{key +1}</Td>
                              <Td>{item?.barcode_num}</Td>
                              <Td align={"left"}>{item?.product_name}</Td>
                              <Td>
                                <ProductCntBox>
                                  <FlexBox
                                    style={{
                                      border: "1px solid #c0c0c0",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    <FlexBox
                                      style={{
                                        borderRight: "1px solid #c0c0c0",
                                        padding: isMobile ? "6px" : "8px",
                                      }}
                                    >
                                      <button
                                        onMouseDown={() => {
                                          handleDecrementClick(item.barcode_num);
                                        }}
                                        onMouseUp={handleButtonRelease}
                                        onMouseOut={handleButtonRelease}
                                      >
                                        <img src={item.product_count - item.product_quantity > 0 ? ico_minus : ico_minus_disable}/>
                                      </button>
                                    </FlexBox>
                                    <Text
                                      padding="0 10px"
                                      family="Montserrat"
                                      color={"#1E1E1E"}
                                      size={"14px"}
                                      sizeMd={"18px"}
                                    >
                                      {item?.product_count}
                                    </Text>
                                    <FlexBox
                                      style={{
                                        borderLeft: "1px solid #c0c0c0",
                                        padding: isMobile ? "6px" : "8px",
                                      }}
                                    >
                                      <button
                                        onMouseDown={() => {
                                          handleIncrementClick(item.barcode_num);
                                        }}
                                        onMouseUp={handleButtonRelease}
                                        onMouseOut={handleButtonRelease}
                                      >
                                        <img src={ico_plus} />
                                      </button>
                                    </FlexBox>
                                  </FlexBox>
                                </ProductCntBox>
                              </Td>
                              <Td>{item?.order_unit}</Td>
                              <Td>
                                <Btn
                                  border={"none"}
                                  background={"transeparent"}
                                  style={{ margin: "0 auto" }}
                                  onClick={() => removeProductItem(item.barcode_num)}
                                >
                                  <DeleteImg src={ico_close} />
                                </Btn>
                              </Td>                    
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={6}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  <Contents margin={"20px 0 0 0"} marginMd={"30px 0 0 0"}>
                    <ProText>프로모션명</ProText>
                    <TextInput
                      style={{ width: "100%" }}
                      type={"text"}
                      placeholder={"프로모션명을 입력해주세요"}
                      name="promotion_name"
                      value={promotionName}
                      onChange={(e) => {
                        setPromotionName(e.target.value);
                      }}
                    />
                  </Contents>
                  <FlexBoxGrid3>
                    <Contents>
                      <ProText>소비자가</ProText>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="consumer_price"
                          value={priceValues.consumer_price}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <InputSmallText>원</InputSmallText>
                      </div>
                    </Contents>
                    <Contents>
                      <ProText>할인율</ProText>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          style={{ paddingRight: "25px", width: "100%" }}
                          type={"text"}
                          align={"right"}
                          placeholder={"0"}
                          name="discount_rate"
                          value={priceValues.discount_rate}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          />
                        <InputSmallText>%</InputSmallText>
                      </div>
                    </Contents>
                    <Contents>
                      <ProText>최종소비자가</ProText>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="discount_price"
                          value={priceValues.discount_price}
                          disabled
                        />
                        <InputSmallText>원</InputSmallText>
                      </div>
                    </Contents>
                  </FlexBoxGrid3>
                  <Contents margin={"20px 0 0 0"} marginMd={"30px 0 0 0"}>
                    <ProText>프로모션 상품 이미지 등록</ProText>
                    <Text size={"12px"} sizeMd={"16px"} align={"left"} margin={"6px 0"} marginMd={"10px 0 20px"}>
                      * 프로모션 상품 이미지를 등록해주세요. 이미지는 최대 3개까지 등록 가능합니다.
                    </Text>
                    <Contents>
                      {isMobile ? (
                        <FlexBox justify={"flex-start"} gap={"10px"} wrap={"wrap"}>
                          {showProFileBtn && (
                            <UploadFileBtn>
                              <input
                                type="file"
                                multiple="multiple"
                                accept="image/*"
                                onChange={handleProImgAdd}
                                ref={proImgRef}
                              />
                              <Text size={"14px"} color={"#C7C7C7"}>
                                이미지 등록
                              </Text>
                              <span>파일 첨부</span>
                            </UploadFileBtn>
                          )}
                          {proImgPath?.map((e, i1) => (
                            <FileImg key={i1}>
                              <FileXBtn onClick={() => handleProImgRemove("existing", i1)}>
                                {(() => {
                                  if (e !== null && e !== undefined) {
                                    return <img src={ico_close} />;
                                  }
                                })()} 
                              </FileXBtn>
                              <div>
                                {(() => {
                                  if (e !== null && e !== undefined) {
                                    return <img src={`${process.env.REACT_APP_S3_KEY}` + e} />;
                                  }
                                })()}
                              </div>
                            </FileImg>
                          ))}
                          {newProPath?.map((e, i1) => (
                            <FileImg key={i1}>
                              <FileXBtn onClick={() => handleProImgRemove("new", i1)}>
                                {(() => {
                                  if (e !== null && e !== undefined) {
                                    return <img src={ico_close} />;
                                  }
                                })()} 
                              </FileXBtn>
                              <div>
                                {(() => {
                                  return <img src={newProPath[i1]} />;
                                })()}
                              </div>
                            </FileImg>
                          ))}
                        </FlexBox>
                      ) : (
                        <WhiteBox>
                          <FlexBox justify={"flex-start"} gap={"10px"} wrap={"wrap"}>
                            {showProFileBtn && (
                              <UploadFileBtn>
                                <input
                                  type="file"
                                  multiple="multiple"
                                  accept="image/*"
                                  onChange={handleProImgAdd}
                                  ref={eventImgRef}
                                />
                                <Text size={"14px"} color={"#C7C7C7"}>
                                  이미지 등록
                                </Text>
                                <span>파일 첨부</span>
                              </UploadFileBtn>
                            )}
                            {proImgPath?.map((e, i1) => (
                              <FileImg key={i1}>
                                <FileXBtn onClick={() => handleProImgRemove("existing", i1)}>
                                  {(() => {
                                    if (e !== null && e !== undefined) {
                                      return <img src={ico_close} />;
                                    }
                                  })()} 
                                </FileXBtn>
                                <div>
                                  {(() => {
                                    if (e !== null && e !== undefined) {
                                      return <img src={`${process.env.REACT_APP_S3_KEY}` + e} />;
                                    }
                                  })()}
                                </div>
                              </FileImg>
                            ))}
                            {newProPath?.map((e, i1) => (
                              <FileImg key={i1}>
                                <FileXBtn onClick={() => handleProImgRemove("new", i1)}>
                                  {(() => {
                                    if (e !== null && e !== undefined) {
                                      return <img src={ico_close} />;
                                    }
                                  })()} 
                                </FileXBtn>
                                <div>
                                  {(() => {
                                    return <img src={newProPath[i1]} />;
                                  })()}
                                </div>
                              </FileImg>
                            ))}
                          </FlexBox>
                        </WhiteBox>
                      )}
                    </Contents>
                  </Contents>
                  <Contents margin={"20px 0 0 0"} marginMd={"30px 0 0 0"}>
                    <ProText>이벤트 배너 이미지 등록</ProText>
                    <Text size={"12px"} sizeMd={"16px"} align={"left"} margin={"6px 0"} marginMd={"10px 0 20px"}>
                    * 이벤트 배너 이미지 1개를 등록해주세요.
                    </Text>
                    <Contents>
                      {isMobile ? (
                        <FlexBox justify={"flex-start"} gap={"10px"} wrap={"wrap"}>
                          {showEventFileBtn && (
                            <UploadFileBtn>
                              <input
                                type="file"
                                multiple="multiple"
                                accept="image/*"
                                onChange={handleEventImgAdd}
                                ref={eventImgRef}
                              />
                              <Text size={"14px"} color={"#C7C7C7"}>
                                이미지 등록
                              </Text>
                              <span>파일 첨부</span>
                            </UploadFileBtn>
                          )}
                          {eventImgPath?.map((e, i1) => (
                            <FileImg key={i1}>
                              <FileXBtn onClick={() => handleEventImgRemove("existing", i1)}>
                                {(() => {
                                  if (e !== null && e !== undefined) {
                                    return <img src={ico_close} />;
                                  }
                                })()} 
                              </FileXBtn>
                              <div>
                                {(() => {
                                  if (e !== null && e !== undefined) {
                                    return <img src={`${process.env.REACT_APP_S3_KEY}` + e} />;
                                  }
                                })()}
                              </div>
                            </FileImg>
                          ))}
                          {newEventPath?.map((e, i1) => (
                            <FileImg key={i1}>
                              <FileXBtn onClick={() => handleEventImgRemove("new", i1)}>
                                {(() => {
                                  if (e !== null && e !== undefined) {
                                    return <img src={ico_close} />;
                                  }
                                })()} 
                              </FileXBtn>
                              <div>
                                {(() => {
                                  return <img src={newEventPath[i1]} />;
                                })()}
                              </div>
                            </FileImg>
                          ))}
                        </FlexBox>
                      ) : (
                        <WhiteBox>
                          <FlexBox justify={"flex-start"} gap={"10px"} wrap={"wrap"}>
                            {showEventFileBtn && (
                              <UploadFileBtn>
                                <input
                                  type="file"
                                  multiple="multiple"
                                  accept="image/*"
                                  onChange={handleEventImgAdd}
                                  ref={eventImgRef}
                                />
                                <Text size={"14px"} color={"#C7C7C7"}>
                                  이미지 등록
                                </Text>
                                <span>파일 첨부</span>
                              </UploadFileBtn>
                            )}
                          {eventImgPath?.map((e, i1) => (
                            <FileImg key={i1}>
                              <FileXBtn onClick={() => handleEventImgRemove("existing", i1)}>
                                {(() => {
                                  if (e !== null && e !== undefined) {
                                    return <img src={ico_close} />;
                                  }
                                })()} 
                              </FileXBtn>
                              <div>
                                {(() => {
                                  if (e !== null && e !== undefined) {
                                    return <img src={`${process.env.REACT_APP_S3_KEY}` + e} />;
                                  }
                                })()}
                              </div>
                            </FileImg>
                          ))}
                          {newEventPath?.map((e, i1) => (
                            <FileImg key={i1}>
                              <FileXBtn onClick={() => handleEventImgRemove("new", i1)}>
                                {(() => {
                                  if (e !== null && e !== undefined) {
                                    return <img src={ico_close} />;
                                  }
                                })()} 
                              </FileXBtn>
                              <div>
                                {(() => {
                                  return <img src={newEventPath[i1]} />;
                                })()}
                              </div>
                            </FileImg>
                          ))}
                          </FlexBox>
                        </WhiteBox>
                      )}
                    </Contents>
                  </Contents>
                  <Contents margin={"20px 0 0 0"} marginMd={"30px 0 0 0"}>
                    <ProText>프로모션 설명</ProText>
                    <QuillContainer
                      quillRef={shopPromotionQuillRef}
                      value={description}
                      setValue={setDescription}
                    />
                  </Contents>
                </WhiteBox>
                <FlexBox justify={"end"}>
                  <FlexBox flex={"1 0 auto"} flexMd={"unset"} gap={"8px"} gapMd={"10px"}>
                    <Btn
                      width={"100%"}
                      widthMd={"90px"}
                      size={"14px"}
                      color={"#666"}
                      border={"1px solid #666"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      hoverWeight={"700"}
                      hoverColor={"#fff"}
                      hoverBackground={"#C7C7C7"}
                      hoverBorder={"1px solid transparent"}
                      onClick={() => setListModal(true)}
                    >
                      목록
                    </Btn>
                    <Btn
                      width={"100%"}
                      widthMd={"90px"}
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      onClick={() => setSaveModal(true)}
                    >
                      저장
                    </Btn>
                  </FlexBox>
                </FlexBox>
              </Container>
              <ConfirmModal
                modal={listModal}
                setModal={setListModal}
                onSubmit={() => onSubmit(1)}
                description={"상품 수정을 취소하고\n목록화면으로 이동하시겠습니까?"}
              />
              <ConfirmModal
                modal={saveModal}
                setModal={setSaveModal}
                onSubmit={() => onSubmit(2)}
                description={"상품 수정을 완료하시겠습니까?"}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
              <InfoModal
                modal={moveToBackModal}
                setModal={setMoveToBackModal}
                mode={"goBack"}
                description={"수정이 완료되었습니다."}
              />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const FlexBoxGrid3 = styled(FlexBox)`
  justify-content: start;
  align-items: start;
  margin: 20px 0 0 0;
  gap: 10px;
  @media only screen and (min-width: 1024px) {
    margin: 30px 0 0 0;
    gap: 25px;
  }
  @media all and (max-width: 1024px) {
    flex-wrap: wrap;

    & > div:nth-of-type(1),
    & > div:nth-of-type(2) {
      width: calc(50% - 5px);
    }
    & > div:nth-of-type(3) {
      width: 100%;
    }
  }
`;

const ProText = styled(Text)`
  text-align: left;
  font-weight: 700;
  color: #1e1e1e;
  font-size: 14px;
  letter-spacing: -0.32px;
  margin: 0 0 8px;
  
  @media (min-width: 1024px) {
    font-size: 16px;
    margin: 0 0 10px;
  }
`

const InputSmallText = styled(Text)`
  position: absolute;
  top: 13px;
  right: 12px;
  font-size: 14px;
  color: #c7c7c7;
`
const DeleteImg = styled.img`
  width: 24px;
  
  @media all and (max-width: 1024px) {
    width: 16px;
  }
`;

const ProductCntBox = styled.div`
  width: 130px;
  margin: 0 auto;

  @media all and (max-width: 1024px) {
    width: 120px;
  }
`

export default ShopPromotionEdit;