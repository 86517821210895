import React, { useEffect, useReducer, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { utils, writeFile } from "xlsx";

import { SearchRevenueReducer } from "../../Store";
import { dateToString } from "../../Utiles/dateToString";
import { searchToObject } from "../../Utiles/searchToObject";
import { getGraph, getTable } from "../../Utiles";
import { delayCall } from "../../Utiles/delayCall";
import Pagination from "../../Components/Paging/Pagination";
import PeriodTable from "./Components/PeriodTableVer2";
import ProductTable from "./Components/ProductTableVer2";
import SalesTable from "./Components/SalesTableVer2";
import ExChart from "../../Components/Chart/ExChart";
import Header from "../../Components/Header/HeaderVer2";
import {
  Btn,
  Container,
  Wrapper,
  FlexBox,
  Text,
  DateInputBox,
  ContentContainer,
  Title,
  WhiteBox,
  RadioLabel,
  RadioIcon,
} from "../../Assets/css/commonVer2";
import API from "../../Utiles/API";
import { AddComma } from "../../Utiles/Custom";
import Authority from "../../Components/Modal/Authority";
import { useMediaQuery } from "react-responsive";
import HandleBack from "../../Components/HandleBack";
import FranSelect from "../../Components/Select/FranSelect";
import BasicSelect from "../../Components/Select/BasicSelect";
import { UNIT_10_TO_30_LIST } from "../../Utiles/ListData";
import InfoSlider from "../../Components/Slider/InfoSlider";

/* code from sub_outsourcing */

// 가맹마스터 > 가맹점 매출관리

// TODO : 검색
// 검색 객체가 변경 됐을 때 만 url을 수정한다.
// 검색 객체가 변경 되는 때는 검색을 눌렀을 때, 기간별 매출, 상품별 매출을 눌렀을 때,
// 10개씩 보기 select 박스를 조작 할 때, 페이징 번호를 누를 때 이다.
// isSearch라는 상태를 두고, true일 경우에만 검색을 하도록 한다.
// true일 경우 url을 변경한다.
// url의 상태가 변경 됐을 때, api 호출한다. 호출 완료되면 isSearch는 false로 한다.
// url의 상태가 변경 됐을 때, state의 상태를 url의 입력 값으로 바꾼다.

// graph와 table 처음에는 둘 다 불러와야한다.
// 처음에는 그래프와 table 둘 다 불러 오고
// graph가 변경 될때는 1.처음 화면을 불러올 때,  2.검색 버튼을 눌렀을 때
// table이 변경 될때는 1.처음 화면을 불러올 때,  2.검색 버튼을 눌렀을 때 3.unit을 수정 했을 때, 4.페이징햇을때 5.상품별 기간별 매출을 클릭 했을 때,
// api 상에서 그래프는 path 값이 반드시

// TODO : 날짜
// 시간(hour), 일별(day), 월별(month) 관리하는 DateState 둔 다. = 리듀서에서 option 값 추가 했음.
// dateOptionState = hour => endDate 관리하는 datepicker는 안보이게 한다.
// dateOptionState = month => datePicker의 속성값 showMonthYearPicker={true} 로 변경한다. dateFormat도 'yyyy-mm'로 변경한다.
// useEffect로 startDate, endDate 변경 시
// dateOptionState가 hour => startDate를 date, 즉 오늘 날짜로 설정한다. dispatchSearchState도 시작날, 끝날 일치 시킨다.

// 가맹점주를 선택한다. 가맹점이 선택 된다.
// 가맹점을 선택한다. 가맹주가 선택 된다.

export default function FranchiseRevenue() {
  const navigate = useNavigate();
  const { handlePopstate } = HandleBack();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const location = useLocation(); //url query 정보를 받아오기 위함

  const date = new Date();

  const [isSearch, setIsSearch] = useState(true);
  const [isGraphSearch, setIsGraphSearch] = useState(true);
  const [isTableSearch, setIsTableSearch] = useState(true);

  const [revenueInfo, setRevenueInfo] = useState({}); // 총매출, 결제건수, 객단가
  const [graphItems, setGraphItems] = useState([]); // 차트에 뿌려줄 아이템
  const [revenueItems, setRevenueItems] = useState([]); // 테이블에 뿌려줄 아이템
  const [pagination, setPagination] = useState(""); // 페이징에 사용할 스테이트

  const [unit, setUnit] = useState(10);

  // 날짜 제어 state
  const [startDate, setStartDate] = useState(new Date(searchToObject().start_date || new Date()));
  const [endDate, setEndDate] = useState(new Date(searchToObject().end_date || new Date()));
  // 기간별, 상품별 매출
  const [category, setCategory] = useState(searchToObject().category || "period");

  // 시간, 일별, 월별 상태 변경 state
  const [dateOptionState, setDateOptionState] = useState(searchToObject().option || "day");

  // 가맹점, 가맹주  state
  const [franText, setFranText] = useState("가맹점 전체");
  const [franShow, setFranShow] = useState(false);
  const [franUserText, setFranUserText] = useState("가맹주 전체");
  const [franUserShow, setFranUserShow] = useState(false);
  const [franSelect, setFranSelect] = useState(false);
  const [userSelect, setUserSelect] = useState(false);
  const [searchFran, setSearchFran] = useState([]);
  const [searchUser, setSearchUser] = useState([]);
  const [franchiseId, setFranchiseId] = useState("");
  const franName = location?.state?.franName;

  // 검색 객체
  const [searchState, dispatchSearchState] = useReducer(SearchRevenueReducer, {
    // franchise_id : 1, // 가맹점 아이디, 주문 번호 필드가 없으면 전체 제품이 검색된다
    start_date: dateToString(new Date()) + " 00:00:00",
    end_date: dateToString(new Date()) + " 23:59:59",
    page: 1,
    unit: 10,
    block: 10,
    search: true,
    option: "hour",
    figure: "table",
    category: "period",
    ...searchToObject(),
  });

  // 모바일 슬라이더
  const slideData = [
    {
      exceptionPrice: [
        `${AddComma(revenueInfo.amount_total_only_franchise_1)}`,
        `${AddComma(revenueInfo.amount_total)}`,
      ],
      exceptionTitle: ["본사 매출", "총 매출"],
    },
    { price: `${AddComma(revenueInfo.month)}`, title: "결제건수", unit: "건" },
    { price: `${AddComma(revenueInfo.average)}`, title: "객단가", unit: "원" },
  ];

  //가맹점 리스트 호출
  const getsearch_F = async () => {
    await API.get(`/admin/search-franchise`).then((res) => {
      if (res.data.status) {
        if (franName) {
          // 대쉬보드 카드의 franName이 있는 경우, franchise_id 구하고 frantText 바꾸기
          const arr = res.data.result;
          let val = "";
          val = arr.filter((e) => e.franchise_name === franName).map((e) => e.idx);
          if (val.length > 0) {
            // 불필요한 호출 방지
            setFranchiseId(val);
            setFranText(franName);
          }
        }
        setSearchFran(res.data.result);
      }
    });
  };

  //가맹주 리스트 호출
  const getsearch_S = async () => {
    await API.get(`/admin/search-storeonwer`).then((res) => {
      if (res.data.status) {
        setSearchUser(res.data.result);
      }
    });
  };

  //가맹점 가맹주 기준으로 사업자번호 반환
  const getsearch_user = async (idx, gubun) => {
    if (idx) {
      await API.get(`/admin/search-result/${idx}`).then((res) => {
        if (res.data.status) {
          if (gubun === 1) {
            setFranUserText(res.data.result[0] ? res.data.result[0].name : "가맹주 전체");
          } else {
            setFranText(res.data.result[0] ? res.data.result[0]["franchise_name"] : "가맹점 전체");
          }
        }
      });
    } else {
      if (gubun === 1) {
        setFranUserText("가맹주 전체");
      } else {
        setFranText("가맹점 전체");
      }
    }
  };

  // 해당 월의 마지막 날짜를 반환하는 함수
  function getLastDayOfCurrentMonth(date) {
    // Get the current date
    const currentDate = new Date(date);

    // Set the date to the first day of next month
    currentDate.setMonth(currentDate.getMonth() + 1, 1);

    // Subtract one day to get the last day of the current month
    currentDate.setDate(currentDate.getDate() - 1);

    // Return the last day of the current month
    return currentDate.getDate();
  }

  function search() {
    const params = {};
    Object.entries(searchState).forEach((item) => (params[item[0]] = item[1]));
    navigate(
      {
        pathname: "/franchisee/revenue",
        search: `?${createSearchParams(params)}`,
      },
      { replace: true },
    );
  }

  function callRevenue() {
    try {
      // setDataLoading(true);
      // 그래프 검색
      if (isSearch && isGraphSearch) {
        getGraph(searchState)
          .then((res) => {
            // setDataLoading(false);
            if (res.status) {
              setRevenueInfo(res.results.revenue_info);
              setIsSearch(false);
              setIsGraphSearch(false);
              setGraphItems(res.results.graph);
              setPagination(res.pagination);
            }
          })
          .catch(function (error) {
            // setDataLoading(false);
          });
      }
      // 테이블 검색
      if (isSearch && isTableSearch) {
        getTable(searchState)
          .then((res) => {
            // setDataLoading(false);
            if (res.status) {
              setRevenueItems(res.results);
              setIsSearch(false);
              setIsTableSearch(false);
              setPagination(res.pagination);
            }
          })
          .catch(function (error) {
            // setDataLoading(false);
          });
      }
    } catch (error) {
      // setDataLoading(false);
    }
  }

  // 페이지 이동 시 작동
  function movePage(e) {
    dispatchSearchState({
      type: "CHANGE_NOW_PAGE",
      value: e,
    });
    setIsSearch(true);
    setIsTableSearch(true);
  }

  function searchRevenue() {
    setIsSearch(true);
    setIsGraphSearch(true);
    setIsTableSearch(true);
  }

  function ExportHandler() {
    if (category === "period") {
      getTable({
        ...searchState,
        category: "period",
        unit: 99999999,
        page: 1,
      }).then((res) => {
        if (res.status) {
          const data = [];
          const excelTableHead = [["날짜", "총 합계", "본사", "자점매입", "카드", "현금", "포인트"]];
          // excel 문서 만들기
          const wb = utils.book_new();
          // 열 순서를 sheet화
          const ws = utils.json_to_sheet([]);
          // ws에 excelTableHead를 추가
          utils.sheet_add_aoa(ws, excelTableHead);

          res.results.forEach((item) => {
            data.push({
              date: `${item.date}`,
              amount: `${item.amount.total}원`,
              headquarter_amount: `${item.headquarter_amount.total}원`,
              franchise_amount: `${item.franchise_amount.total}원`,
              card: `${item.card.total}원`,
              cash: `${item.cash.total}원`,
              point: `${item.point.total}원`,
            });
          });

          utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
          utils.book_append_sheet(wb, ws, "Report");
          // 두 번째 arg에는 export 될 파일의 이름을 넣어주면 된다.
          writeFile(wb, `기간별매출관리_${dateToString(startDate)} ~ ${dateToString(endDate)}.xlsx`);
        } else {
          alert("잠시후 다시 시도해주세요.");
        }
      });
    } else if (category === "product") {
      getTable({
        ...searchState,
        category: "product",
        unit: 99999999,
        page: 1,
      }).then((res) => {
        if (res.status) {
          const data = [];
          const excelTableHead = [
            ["1차분류", "상품명", "바코드번호", "총합계", "카드", "현금", "포인트", "카카오페이", "페이코"],
          ];
          // excel 문서 만들기
          const wb = utils.book_new();
          // 열 순서를 sheet화
          const ws = utils.json_to_sheet([]);
          // ws에 excelTableHead를 추가
          utils.sheet_add_aoa(ws, excelTableHead);

          res.results.forEach((item) => {
            data.push({
              category1st_name: `${item.category1st_name}`,
              product_name: `${item.product_name}`,
              barcode_num: `${item.barcode_num}`,
              amount: `${item.amount.count}개 / ${item.amount.total}원`,
              card: `${item.card.count}개 / ${item.card.total}원`,
              cash: `${item.cash.count}개 / ${item.cash.total}원`,
              point: `${item.point.count}개 / ${item.point.total}원`,
              kakao: `${item.kakao.count}개 / ${item.kakao.total}원`,
            });
          });

          utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
          utils.book_append_sheet(wb, ws, "Report");
          // 두 번째 arg에는 export 될 파일의 이름을 넣어주면 된다.
          writeFile(wb, `상품별매출관리_${dateToString(startDate)} ~ ${dateToString(endDate)}.xlsx`);
        } else {
          alert("잠시후 다시 시도해주세요.");
        }
      });
    } else {
      getTable({
        ...searchState,
        category: "kiosk_sale",
        unit: 999999,
        page: 1,
      }).then((res) => {
        const data = [];
        const excelTableHead = [
          ["가맹점", "1차분류", "2차분류", "상품명", "바코드번호", "수량", "매입가", "공급가", "소비자가", "날짜"],
        ];
        // excel 문서 만들기
        const wb = utils.book_new();
        // 열 순서를 sheet화
        const ws = utils.json_to_sheet([]);
        // ws에 excelTableHead를 추가
        utils.sheet_add_aoa(ws, excelTableHead);

        res.results.forEach((item) => {
          data.push({
            franchisee_name: `${item.franchise_name}`,
            category1st_name: `${item.category1st_name}`,
            category2nd_name: `${item.category2nd_name}`,
            product_name: `${item.product_name}`,
            barcode_num: `${item.barcode_num}`,
            quantity: `${item.quantity}개`,
            purchase_price: `${item.purchase_price}원`,
            shipping_price: `${item.shipping_price}원`,
            consumer_price: `${item.consumer_price}원`,
            createdAt: `${item.createdAt}`,
          });
        });

        utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
        utils.book_append_sheet(wb, ws, "Report");
        // 두 번째 arg에는 export 될 파일의 이름을 넣어주면 된다.
        writeFile(wb, `판매건수별매출관리_${dateToString(startDate)}.xlsx`);
      });
    }
  }

  // hour/day/month 일 경우 초기값
  useEffect(() => {
    if (dateOptionState === "hour") {
      setStartDate(date);
    }
    if (dateOptionState === "day") {
      setStartDate(new Date(date.getFullYear(), date.getMonth(), 1));
      setEndDate(new Date(date.getFullYear(), date.getMonth(), getLastDayOfCurrentMonth(date)));
    }
    if (dateOptionState === "month") {
      setStartDate(new Date(date.getFullYear(), date.getMonth()));
      setEndDate(new Date(date.getFullYear(), date.getMonth()));
    }
  }, [dateOptionState]);

  useEffect(() => {
    // hour 인 경우 start date와 end date를 선택한 startDate로 일치
    if (dateOptionState === "hour") {
      dispatchSearchState({
        type: "CHANGE_START_DATE",
        value: dateToString(startDate) + " 00:00:00",
      });
      dispatchSearchState({
        type: "CHANGE_END_DATE",
        value: dateToString(startDate) + " 23:59:59",
      });
    }
    if (dateOptionState === "day") {
      dispatchSearchState({
        type: "CHANGE_START_DATE",
        value: dateToString(startDate) + " 00:00:00",
      });
    }
    if (dateOptionState === "month") {
      dispatchSearchState({
        type: "CHANGE_START_DATE",
        value: dateToString(new Date(startDate.getFullYear(), startDate.getMonth(), 1)) + " 00:00:00",
      });
    }
  }, [startDate]);

  useEffect(() => {
    if (dateOptionState === "day") {
      dispatchSearchState({
        type: "CHANGE_END_DATE",
        value: dateToString(endDate) + " 23:59:59",
      });
    }
    if (dateOptionState === "month") {
      dispatchSearchState({
        type: "CHANGE_END_DATE",
        value:
          dateToString(new Date(endDate.getFullYear(), endDate.getMonth(), getLastDayOfCurrentMonth(endDate))) +
          " 23:59:59",
      });
    }
  }, [endDate]);

  useEffect(() => {
    dispatchSearchState({ type: "CHANGE_DATE_OPTION", value: dateOptionState });
  }, [dateOptionState]);

  useEffect(() => {
    if (isSearch) {
      search();
    }
  }, [isSearch]);

  useEffect(() => {
    // 1. searchState를 searchBar에서도 변경하기 때문에 초기에 두번 작동된다.
    // 2. 검색버튼을 여러번 누를때를 대비해서 방지 가능
    // + 대쉬보드 카드 클릭해서 이동한 경우 searchState가 바뀔 때 callRevenue() 호출하여 그래프와 표에 데이터 넣기
    delayCall(callRevenue);
  }, [searchState, location]);

  // 기간별매출 상품별 매출을 변경하면 table과 관련된 API를 호출합니다.
  useEffect(() => {
    dispatchSearchState({ type: "CHAGE_CATEGORY", value: category });
    setIsSearch(true);
    setIsTableSearch(true);
  }, [category]);

  useEffect(() => {
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    if (franShow) {
      getsearch_F();
    }
    if (franUserShow) {
      getsearch_S();
    }
  }, [franShow, franUserShow]);

  // 대쉬보드 카드에서 가져온 franName이 있는 경우, 가맹점 조회 함수 호출
  useEffect(() => {
    if (franName) {
      getsearch_F();
    }
  }, [franName]);

  // franName이 있을 때 getsearch_F() 실행 후, 일치하는 가맹주명과 해당 데이터 가져옴
  useEffect(() => {
    if (franchiseId) {
      dispatchSearchState({ type: "SET_FEANCHISE_ID", value: franchiseId });
      getsearch_user(franchiseId, 1);
    }
  }, [franchiseId]);

  useEffect(() => {
    let val = "";
    if (franSelect) {
      val = searchFran.filter((e, i) => e.franchise_name === franText).map((e) => e.idx);
      dispatchSearchState({ type: "SET_FEANCHISE_ID", value: val });
      getsearch_user(val[0], 1);
    }

    if (userSelect) {
      val = searchUser.filter((e, i) => e.name === franUserText).map((e) => e.franchise_id);
      dispatchSearchState({ type: "SET_FEANCHISE_ID", value: val });
      getsearch_user(val[0], 2);
    }
  }, [franText, franUserText]);

  useEffect(() => {
    dispatchSearchState({
      type: "CHANGE_UNIT",
      value: unit,
    });
    setIsSearch(true);
    setIsTableSearch(true);
  }, [unit]);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {isMobile && <Header title={"가맹점 매출관리"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    가맹점 매출관리
                  </Title>
                )}
                {/* 필터 및 검색, 선택 영역 */}
                <FlexBox gap={"10px"} justify={"end"} wrap={"wrap"}>
                  <FlexBox wrap={"wrap"} gap={"10px"} flexMd={"unset"} flex={"1"} justify={"end"}>
                    {/* 토글버튼 */}
                    <FlexBox
                      width={"100%"}
                      widthMd={"unset"}
                      gap={"10px"}
                      margin={"0"}
                      marginMd={"0 10px 0 0"}
                      wrap={"wrap"}
                    >
                      <RadioLabel>
                        <input
                          type="radio"
                          name="hour"
                          value="hour"
                          checked={dateOptionState === "hour"}
                          onChange={() => setDateOptionState("hour")}
                        />
                        <RadioIcon>
                          <svg viewBox="0 0 18 18">
                            <circle cx="9" cy="9" r="8.5" />
                            <circle cx="9" cy="9" r="5" />
                          </svg>
                        </RadioIcon>
                        시간
                      </RadioLabel>

                      <RadioLabel>
                        <input
                          type="radio"
                          name="day"
                          value="day"
                          checked={dateOptionState === "day"}
                          onChange={() => setDateOptionState("day")}
                        />
                        <RadioIcon>
                          <svg viewBox="0 0 18 18">
                            <circle cx="9" cy="9" r="8.5" />
                            <circle cx="9" cy="9" r="5" />
                          </svg>
                        </RadioIcon>
                        일별
                      </RadioLabel>

                      <RadioLabel>
                        <input
                          type="radio"
                          name="month"
                          value="month"
                          checked={dateOptionState === "month"}
                          onChange={() => setDateOptionState("month")}
                        />
                        <RadioIcon>
                          <svg viewBox="0 0 18 18">
                            <circle cx="9" cy="9" r="8.5" />
                            <circle cx="9" cy="9" r="5" />
                          </svg>
                        </RadioIcon>
                        월별
                      </RadioLabel>
                    </FlexBox>

                    {/* 달력, 기간 선택 */}
                    <FlexBox gap={"10px"} flexMd={"unset"} flex={"1"}>
                      {/* startDate 달력 */}
                      <DateInputBox style={{ width: "100%" }}>
                        <DatePicker
                          dateFormatCalendar="yyyy년 MM월"
                          dateFormat={dateOptionState === "month" ? "yyyy-MM" : "yyyy-MM-dd"}
                          showMonthYearPicker={dateOptionState === "month" ? true : false}
                          selected={startDate}
                          maxDate={dateOptionState === "hour" ? "" : endDate}
                          onChange={(date) => setStartDate(date)}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </DateInputBox>

                      {/* endDate 달력 */}
                      {dateOptionState !== "hour" && (
                        <DateInputBox style={{ width: "100%" }}>
                          <DatePicker
                            dateFormatCalendar="yyyy년 MM월"
                            dateFormat={dateOptionState === "month" ? "yyyy-MM" : "yyyy-MM-dd"}
                            showMonthYearPicker={dateOptionState === "month" ? true : false}
                            selected={endDate}
                            minDate={startDate}
                            // maxDate={endDate}
                            onChange={(date) => setEndDate(date)}
                            startDate={startDate}
                            endDate={endDate}
                          />
                        </DateInputBox>
                      )}
                    </FlexBox>
                  </FlexBox>

                  <FranSelect
                    widthMd={"100%"}
                    gubun={"fran"}
                    selectBtnText={franText}
                    setTextChange={setFranText}
                    placeholder={"가맹점을"}
                    show={franShow}
                    setFranShow={setFranShow}
                    setFranUserShow={setFranUserShow}
                    setFranSelect={setFranSelect}
                    setUserSelect={setUserSelect}
                  />
                  <FranSelect
                    widthMd={"100%"}
                    gubun={"franUser"}
                    selectBtnText={franUserText}
                    setTextChange={setFranUserText}
                    placeholder={"가맹주를"}
                    show={franUserShow}
                    setFranShow={setFranShow}
                    setFranUserShow={setFranUserShow}
                    setFranSelect={setFranSelect}
                    setUserSelect={setUserSelect}
                  />
                  <Btn
                    color={"#fff"}
                    background={"#FF4A4A"}
                    size={"14px"}
                    weight={"700"}
                    width={"100%"}
                    widthMd={"unset"}
                    padding={"8px 18px"}
                    lineHeight={"22px"}
                    onClick={() => searchRevenue()}
                    style={{ flexShrink: 0 }}
                  >
                    검 색
                  </Btn>
                </FlexBox>

                {/* 웹버전 금액데이터 */}
                {!isMobile && (
                  <FlexBox direction={"column"} directionXl={"row"} justify={"space-between"} margin={"0 0 16px"}>
                    <CardItem className="exception" style={{ display: "unset" }}>
                      <FlexBox flex={1} justify={"space-between"}>
                        <Text size={"12px"} sizeMd={"18px"} color={"#1e1e1e"}>
                          본사 매출
                        </Text>
                        <Text size={"12px"} sizeMd={"18px"} color={"#1e1e1e"}>
                          <span
                            style={{
                              fontSize: "26px",
                              fontWeight: "700",
                            }}
                          >
                            {AddComma(revenueInfo.amount_total_only_franchise_1)}
                          </span>
                          원
                        </Text>
                      </FlexBox>
                      <FlexBox flex={1} justify={"space-between"} margin={"4px 0 0"}>
                        <Text size={"10px"} sizeMd={"16px"} color={"#1e1e1e"}>
                          총 매출
                        </Text>
                        <Text size={"10px"} sizeMd={"16px"} color={"#1e1e1e"}>
                          <span
                            style={{
                              fontSize: "24px",
                              fontWeight: "700",
                            }}
                          >
                            {AddComma(revenueInfo.amount_total)}
                          </span>
                          원
                        </Text>
                      </FlexBox>
                    </CardItem>
                    <CardItem>
                      <Text size={"12px"} sizeMd={"18px"} color={"#1e1e1e"}>
                        결제 건수
                      </Text>
                      <Text size={"12px"} sizeMd={"18px"} color={"#1e1e1e"}>
                        <span
                          style={{
                            fontSize: "26px",
                            fontWeight: "700",
                          }}
                        >
                          {AddComma(revenueInfo.amount_count)}
                        </span>
                        건
                      </Text>
                    </CardItem>
                    <CardItem>
                      <Text size={"12px"} sizeMd={"18px"} color={"#1e1e1e"}>
                        객단가
                      </Text>
                      <Text size={"12px"} sizeMd={"18px"} color={"#1e1e1e"}>
                        <span
                          style={{
                            fontSize: "26px",
                            fontWeight: "700",
                          }}
                        >
                          {AddComma(revenueInfo.average)}
                        </span>
                        원
                      </Text>
                    </CardItem>
                  </FlexBox>
                )}

                {/* 모바일버전 금액데이터 */}
                {isMobile && (
                  <InfoSlider
                    space={10}
                    naviPrev={"sellPrev"}
                    naviNext={"sellNext"}
                    contents={slideData}
                    slidesPerView={slideData.length}
                  />
                )}

                {/* 그래프 영역 */}
                <WhiteBox margin={"0"}>
                  {isMobile ? (
                    <div style={{ overflowX: "scroll", flex: 1, padding: "20px" }}>
                      <div style={{ minWidth: "600px" }}>
                        <ExChart graphItems={graphItems} />
                      </div>
                    </div>
                  ) : (
                    <ExChart graphItems={graphItems} />
                  )}
                </WhiteBox>

                {/* 테이블 영역 */}
                <WhiteBox>
                  <FlexBox justify={"space-between"} margin={"0 0 16px"}>
                    <FlexBox>
                      {!isMobile && (
                        <BasicSelect
                          width={"130px"}
                          data={UNIT_10_TO_30_LIST}
                          selectedValue={unit}
                          setSelectedValue={setUnit}
                          canSelectAll={false}
                        />
                      )}
                      <FlexBox gap={"10px"} margin={"0 0 0 10px"}>
                        <RadioLabel>
                          <input
                            type="radio"
                            name="period"
                            value="period"
                            checked={category === "period"}
                            onChange={() => setCategory("period")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          기간별 매출
                        </RadioLabel>

                        <RadioLabel>
                          <input
                            type="radio"
                            name="product"
                            value="product"
                            checked={category === "product"}
                            onChange={() => setCategory("product")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          상품별 매출
                        </RadioLabel>

                        <RadioLabel>
                          <input
                            type="radio"
                            name="kiosk_sale"
                            value="kiosk_sale"
                            checked={category === "kiosk_sale"}
                            onChange={() => setCategory("kiosk_sale")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          판매건수별 매출
                        </RadioLabel>
                      </FlexBox>
                    </FlexBox>
                    {!isMobile && (
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        border={"1px solid #666"}
                        color={"#666"}
                        hoverBackground={"#C7C7C7"}
                        hoverColor={"#fff"}
                        hoverBorder={"transparent"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        margin={"0 0 0 10px"}
                        onClick={ExportHandler}
                      >
                        엑셀 다운
                      </Btn>
                    )}
                  </FlexBox>
                  {category === "period" ? (
                    <PeriodTable revenueItems={revenueItems} />
                  ) : category === "product" ? (
                    <ProductTable revenueItems={revenueItems} />
                  ) : (
                    <SalesTable revenueItems={revenueItems} />
                  )}
                  {pagination?.total_page > 0 ? <Pagination pagination={pagination} movePage={movePage} /> : ""}
                </WhiteBox>
              </Container>
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const CardItem = styled.div`
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: calc((100% - 40px) / 3);
  margin-right: 15px;
  margin-top: 20px;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :last-child {
    margin-right: 0;
  }
  @media (max-width: 1201px) {
    width: 100%;
    margin-right: 0;
  }

  &.exception {
    padding: 23px 40px;
  }
`;
