import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import Header from "../../Components/Header/HeaderVer2";
import {
  Btn,
  TableWrap,
  Td,
  Th,
  ContentContainer,
  Container,
  Wrapper,
  CheckLabel,
  CheckIcon,
  PagerWrap,
  FlexBox,
  WhiteBox,
  Title,
} from "../../Assets/css/commonVer2";
import API from "../../Utiles/API";
import { str } from "../../Utiles/Custom";
import Authority from "../../Components/Modal/Authority";
import Paging from "../../Components/Paging/Paging";
import FranSelect from "../../Components/Select/FranSelect";
import BasicSelect from "../../Components/Select/BasicSelect";
import { UNIT_10_TO_30_LIST } from "../../Utiles/ListData";
import InfoModal from "../../Components/Modal/InfoModal";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import HandleBack from "../../Components/HandleBack";

function FranchiseeKiosk() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();
  const { handlePopstate } = HandleBack();

  const [data, setData] = useState([]); // 서버에서 받아온 키오스크 데이터
  const [checkItems, setCheckItems] = useState([]); // 체크된 아이템의 idx
  const [totalPage, setTotalPage] = useState(13);
  const [totalCount, setTotalCount] = useState(40);
  const [deleteModal, setDeleteModal] = useState(false);

  const [franShow, setFranShow] = useState(false);

  const [textModal, setTextModal] = useState(false); // 모달 창 표시 여부 상태
  const [textMsg, setTextMsg] = useState("");

  // 수정 및 등록을 다녀온 경우 저장 해놓은 파라미터 정보
  const parsedFranchiseeKioskPageState = sessionStorage.getItem("franchiseeKioskPageState")
    ? JSON.parse(sessionStorage.getItem("franchiseeKioskPageState"))
    : {};

  const [search, setSearch] = useState(
    parsedFranchiseeKioskPageState
      ? parsedFranchiseeKioskPageState?.search
        ? parsedFranchiseeKioskPageState?.search
        : ""
      : "",
  ); // 검색 키워드
  const [pageSize, setPageSize] = useState(
    parsedFranchiseeKioskPageState
      ? parsedFranchiseeKioskPageState?.pageSize
        ? parsedFranchiseeKioskPageState?.pageSize
        : 10
      : 10,
  ); // 한 페이지 당 글 갯수
  const [currentPage, setCurrentPage] = useState(
    parsedFranchiseeKioskPageState
      ? parsedFranchiseeKioskPageState?.currentPage
        ? parsedFranchiseeKioskPageState?.currentPage
        : 1
      : 1,
  );
  const [franText, setFranText] = useState(
    parsedFranchiseeKioskPageState
      ? parsedFranchiseeKioskPageState?.franText
        ? parsedFranchiseeKioskPageState?.franText
        : "가맹점 전체"
      : "가맹점 전체",
  );

  let franchiseeKioskPageState = {
    pageSize,
    search,
    currentPage,
    franText,
  };

  async function getKioskList() {
    try {
      let api_link = `/kiosk?curPage=${currentPage}&pageSize=${pageSize}`;
      if (franText !== "가맹점 전체") api_link += `&franchise_name=${franText}`;
      const res = await API.get(api_link);
      if (res.data.status) {
        setData(res.data.results);
        setTotalCount(res.data.cntAll);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  // 검색
  const Search = async () => {
    try {
      getKioskList();
    } catch (error) {
      // console.log(error);
    }
  };

  // 아이템 체크
  const handleChk = (checked, id) => {
    if (checked) {
      setCheckItems((prev) => [...prev, id]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  // 아이템 전체 체크
  const handleAllChk = (checked) => {
    if (checked) {
      const chkArr = [];
      for (let i = 0; i < data.length; i++) {
        chkArr.push(data[i].kiosk_id);
      }
      setCheckItems(chkArr);
    } else {
      setCheckItems([]);
    }
  };

  // 아이템 수정 클릭
  const onEditKiosk = () => {
    if (checkItems.length === 0) {
      setTextMsg("수정할 키오스크를 선택해주세요.");
      setTextModal(true);
      return;
    }
    if (checkItems.length > 1) {
      setTextMsg("하나만 선택해주세요.");
      setTextModal(true);
      return;
    }
    if (checkItems.length === 1) {
      const foundItem = data.find((item) => item.kiosk_id === checkItems[0]);
      navigate("write", {
        state: {
          isCreate: false,
          franchiseIdx: foundItem.franchise_id,
          franchiseName: foundItem.franchise_name,
          kioskIdx: foundItem.kiosk_id,
          isUseCashReceipt: foundItem.cash_receipts,
        },
      });
      let jsonString = JSON.stringify(franchiseeKioskPageState);
      sessionStorage.setItem("franchiseeKioskPageState", jsonString);
    }
  };

  // 아이템 삭제 클릭
  const onDeleteKiosk = () => {
    if (checkItems.length > 0) {
      setDeleteModal(true);
    } else {
      setTextMsg("삭제할 키오스크를 선택해주세요.");
      setTextModal(true);
    }
  };

  const deleteKioskList = async () => {
    await API.delete(`/kiosk?kiosk_id=${checkItems}`).then((res) => {
      if (res.data.status) {
        setDeleteModal(false);
        window.location.replace("/franchisee/kiosk"); // 새로고침
      } else {
        setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    });
  };

  // 들어오자마자 키오스크 목록 가져오기
  useEffect(() => {
    getKioskList();
    setCheckItems([]);
  }, [currentPage]);

  // 데이터 새로 가져왔다면 페이징 처리
  useEffect(() => {
    setTotalPage(Math.ceil(totalCount / pageSize));
  }, [totalCount, pageSize]);

  useEffect(() => {
    sessionStorage.removeItem("franchiseeKioskPageState");
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {isMobile && <Header title={"키오스크 관리"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    키오스크 관리 📋
                  </Title>
                )}
                <WhiteBox margin={"0 0"}>
                  {/* 검색 필드 */}
                  <FlexBox justify={"end"} wrap={"wrap"} wrapMd={"nowrap"} gap={"10px"}>
                    <FranSelect
                      widthMd={"100%"}
                      gubun={"fran"}
                      selectBtnText={franText}
                      setTextChange={setFranText}
                      placeholder={"가맹점을"}
                      show={franShow}
                      setFranShow={setFranShow}
                    />
                    <Btn
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      width={"100%"}
                      widthMd={"unset"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      onClick={() => Search()}
                      style={{ flexShrink: 0 }}
                    >
                      검 색
                    </Btn>
                  </FlexBox>

                  {/* 갯수 필터 & CUD 버튼들 */}
                  <FlexBox
                    justify={"space-between"}
                    wrap={"wrap"}
                    wrapLg={"nowrap"}
                    gap={"10px"}
                    margin={"20px 0 10px"}
                    marginMd={"20px 0 30px"}
                  >
                    {!isMobile && (
                      <BasicSelect
                        width={"130px"}
                        data={UNIT_10_TO_30_LIST}
                        selectedValue={pageSize}
                        setSelectedValue={setPageSize}
                        canSelectAll={false}
                        setCurrentPage={setCurrentPage}
                      />
                    )}

                    <FlexBox style={{ marginLeft: "auto" }}>
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        onClick={() => {
                          navigate("write", {
                            state: {
                              isCreate: true,
                              franchiseIdx: -1,
                              kioskIdx: -1,
                              isUseCashReceipt: true,
                            },
                          });
                          let jsonString = JSON.stringify(franchiseeKioskPageState);
                          sessionStorage.setItem("franchiseeKioskPageState", jsonString);
                        }}
                      >
                        등록
                      </Btn>
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        margin={"0 10px"}
                        hoverWeight={"700"}
                        onClick={onEditKiosk}
                      >
                        수정
                      </Btn>
                      <Btn
                        size={"14px"}
                        color={"#666"}
                        border={"1px solid #666"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        hoverColor={"#fff"}
                        hoverBackground={"#C7C7C7"}
                        hoverBorder={"1px solid transparent"}
                        onClick={onDeleteKiosk}
                      >
                        선택삭제
                      </Btn>
                    </FlexBox>
                  </FlexBox>

                  {/* 데이터 테이블 */}
                  <TableWrap>
                    <table style={{ width: "100%", minWidth: "1024px" }}>
                      <colgroup>
                        <col style={{ width: "4%" }} />
                        <col style={{ width: "6%" }} />
                        <col style={{ width: "20%" }} />
                        <col style={{ width: "15%" }} />
                        <col />
                        <col style={{ width: "12%" }} />
                      </colgroup>
                      <thead>
                        <tr style={{ borderRight: "1px solid #c0c0c0" }}>
                          <Th>
                            <CheckLabel htmlFor="chkAll" style={{ justifyContent: "center" }}>
                              <input
                                type="checkbox"
                                id="chkAll"
                                onChange={(event) => handleAllChk(event.target.checked)}
                                checked={checkItems.length === data.length ? true : false}
                              />
                              <CheckIcon>
                                <svg viewBox="0 0 24 24">
                                  <polyline points="19 7 10 17 5 12" />
                                </svg>
                              </CheckIcon>
                            </CheckLabel>
                          </Th>
                          <Th>No</Th>
                          <Th>가맹점명</Th>
                          <Th>가맹점주</Th>
                          <Th>주소</Th>
                          <Th>등록일</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 ? (
                          data.map((list, key) => (
                            <tr
                              style={{ cursor: "pointer" }}
                              key={key}
                              onClick={(event) => {
                                const targetElement = event.target;
                                if (targetElement.nodeName !== "INPUT") {
                                  // 체크박스를 클릭한 경우에는 이벤트를 처리하지 않음
                                  const isChecked = document.getElementById(`prod${list.kiosk_id}`).checked;
                                  handleChk(!isChecked, list.kiosk_id);
                                }
                              }}
                            >
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }} readOnly>
                                <CheckLabel htmlFor={`prod${list.kiosk_id}`} style={{ justifyContent: "center" }}>
                                  <input
                                    type="checkbox"
                                    name="prod"
                                    id={`prod${list.kiosk_id}`}
                                    readOnly
                                    checked={checkItems.includes(list.kiosk_id) ? true : false}
                                  />
                                  <CheckIcon>
                                    <svg viewBox="0 0 24 24">
                                      <polyline points="19 7 10 17 5 12" />
                                    </svg>
                                  </CheckIcon>
                                </CheckLabel>
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {(currentPage - 1) * pageSize + key + 1}
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{list ? list.franchise_name : ""}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{list ? list.user_name : ""}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {list ? list.franchise_address : ""}
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{list ? str(list.createdAt) : ""}</Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={6}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>

                  {data.length > 0 ? (
                    <PagerWrap>
                      <Paging totalPage={totalPage} limit={pageSize} page={currentPage} setPage={setCurrentPage} />
                    </PagerWrap>
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <ConfirmModal
                modal={deleteModal}
                setModal={setDeleteModal}
                onSubmit={deleteKioskList}
                description={"선택된 키오스크를 삭제하시겠습니까?"}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

export default FranchiseeKiosk;
