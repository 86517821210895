import React, { useEffect, useState } from "react";
import { PagerWrap } from "../../Assets/css/commonVer2";

const Paging = ({ totalPage, limit, page, setPage }) => {
  const [currentPageArray, setCurrentPageArray] = useState([]);
  const [totalPageArray, setTotalPageArray] = useState([]);
  const [btnActive, setBtnActive] = useState(page);

  // 현재 보고 있는 페이지의 블록 ex) 내가 1페이지 ~ 10페이지 사이에 있다면 1블록, 11~20이라면 2블록
  const [nowBlock, setNowBlock] = useState(0);

  useEffect(() => {
    if (page % limit === 1) {
      // page % limit 1 자리 숫자 ex. 1, 11, 21, 31 ...
      setCurrentPageArray(totalPageArray[Math.floor(page / limit)]); //Math.floor(page / limit) : 10자리 숫자 1~9 => 0 , 11~19 => 1, 21~29 => 2
    } else if (page % limit === 0) {
      // ex.  10, 20, 30 ...
      setCurrentPageArray(totalPageArray[Math.floor(page / limit) - 1]);
    }
    if (page === 1) {
      setBtnActive(1);
      setNowBlock(0);
    }
    if (page % 10 === 0) {
      setPage(page);
    }
  }, [page]);

  useEffect(() => {
    setBtnActive(1);
    setNowBlock(0);
  }, [limit]);

  useEffect(() => {
    const array = Array(totalPage)
      .fill()
      .map((array, i) => i);
    const slicedPageArray = chunk(array, 10);
    setTotalPageArray(slicedPageArray);
    setCurrentPageArray(slicedPageArray[0]);

    for (let i = 0; i < slicedPageArray.length; i++) {
      if (slicedPageArray[i].includes(page)) {
        if (page % 10 === 0) {
          setNowBlock(i - 1);
        } else {
          setNowBlock(i);
        }
      }
    }
  }, [totalPage]);

  useEffect(() => {
    setPage(
      window.history.state ? (window.history.state?.currentPage ? window.history.state?.currentPage : page) : page
    );
  }, [window.history.state?.currentPage]);

  useEffect(() => {
    setBtnActive(
      window.history.state
        ? window.history.state?.currentPage
          ? window.history.state?.currentPage
          : btnActive
        : btnActive
    );
  }, [window.history.state?.currentPage]);

  const setactice = (index) => {
    setBtnActive(index);
  };

  // 배열의 아이템을 n개 만큼 묶어주는 함수
  function chunk(data = [], size = 1) {
    const arr = [];
    for (let i = 0; i < data.length; i += size) {
      arr.push(data.slice(i, i + size));
    }
    return arr;
  }

  return (
    <PagerWrap>
      {nowBlock !== 0 && (
        <a
          href="#"
          className="page_prev"
          onClick={() => {
            if (totalPageArray.length > 0) {
              setPage(totalPageArray[nowBlock - 1][0] + 1);
              setactice(totalPageArray[nowBlock - 1][0] + 1);
              setNowBlock(nowBlock - 1);
            }
          }}
        >
          <i></i>
        </a>
      )}

      {totalPageArray?.[nowBlock]?.map((i) => {
        return (
          <a
            key={i + 1}
            href="#"
            className={i + 1 == btnActive ? " active" : ""}
            onClick={(e) => {
              setPage(i + 1);
              setactice(i + 1);
            }}
          >
            {i + 1}
          </a>
        );
      })}
      {nowBlock + 1 !== totalPageArray.length && (
        <a
          href="#"
          className="page_next"
          onClick={() => {
            setPage(totalPageArray[nowBlock + 1][0] + 1);
            setactice(totalPageArray[nowBlock + 1][0] + 1);
            setNowBlock(nowBlock + 1);
          }}
        >
          <i></i>
        </a>
      )}
    </PagerWrap>
  );
};

export default Paging;
