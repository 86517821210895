import React, { useState } from "react";
import DaumPostcode from "react-daum-postcode";
import styled from "styled-components";
import close from "../../Assets/images/close.png";

function SearchAddress(props) {
  const { setModal, setValue, setIsSearch } = props;

  const onSelectAddr = (data) => {
    setValue(data.address);
    setIsSearch(true);
    setModal(false);
  };
  const onClose = () => {
    setIsSearch(false);
    setModal(false);
  };

  return (
    <ModalBg>
      <ModalConts>
        <CancelBtn onClick={onClose}>
          <img src={close} />
        </CancelBtn>
        <DaumPostcode onComplete={onSelectAddr} autoClose={false} defaultQuery="" />
      </ModalConts>
    </ModalBg>
  );
}

const ModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;
const ModalConts = styled.div`
  background-color: #fff;
  max-width: 380px;
  max-height: 600px;
  width: 100%;
  padding: 36px 0 0;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  @media all and (max-width: 1024px) {
    max-width: 300px;
  }
`;
const CancelBtn = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
`;

export default SearchAddress;