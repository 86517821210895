// 외부 모듈
import React,{ memo } from "react";
import styled from 'styled-components';
import { Bar } from 'react-chartjs-2';
// 내부 모듈
import {AddComma} from "../../Utiles/Custom";

const options = {
  spanGaps: true,
  maxBarThickness: 30,
  grouped: true,
  interaction: {
    mode: 'index',
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      position: 'nearest',
      titleColor: "#000",
      titleFont: { weight: "400", size: "10", family: "Montserrat" },
      bodyColor: "#1e1e1e",
      bodyFont: { weight: "bold", family: "Montserrat" },
      backgroundColor: "#fff",
      borderRadius: 10,
      borderColor: "rgba(0, 0, 0, 0.1)",
      borderWidth: 1,
      displayColors: false,
      padding: 14,
      filter: (item) => item.parsed.y !== null,
      callbacks: {
        title: (context) => context[0].label,
        label: (context) => {
          return context.parsed.y !== null
            ? AddComma(Math.round(context.parsed.y)) + "원"
            : null;
        },
      },
    },
  },
  scales: {
    x: {
      afterTickToLabelConversion: function (scaleInstance) {
        const ticks = scaleInstance.ticks;

        const newTicks = ticks.map((tick) => {
          return {
            ...tick,
            label: tick.label
          };
        });

        scaleInstance.ticks = newTicks;
      },
      ticks: {
        font: {
          family: "Montserrat",
          size: 14,
          color: "#000000",
        },
      },
      grid: {
        display: false,
      },
      axis: 'x',
      position: 'bottom',
    },
    y: {
      type: 'linear',
      ticks: {
        font: {
          family: "Montserrat",
          size: 14,
          color: "#000000",
        },
      },
      grid: {
        display: false,
      },
      afterDataLimits: (scale) => {
        // scale.max = scale.max * 1.2;›
      },
      axis: 'y',
      display: true,
      position: 'left',
    },
  }
};

function ExChartBar({chart, selfPurchaseChart}) {
  // y: 본사 매출(페이지별 변수명 다름)
  const dataY1 = selfPurchaseChart?.map(item => ({
    x: item.x,
    y: parseInt(item.y_headquarter || item.headquarter_amount),
  }));
  
  // y: 자점매입 매출(페이지별 변수명 다름)
  const dataY2 = selfPurchaseChart?.map(item => ({
    x: item.x,
    y: parseInt(item.y_franchise || item.franchise_amount),
  }));

  // 자점매입 매출 없을 때 범례 숨김
  // y가 NaN이면 false(!NaN이면 true) && dataY2 배열 중 0이 아닌 값이 하나라도 있으면 true
  const hasDataForFranchise = dataY2?.some(item => !isNaN(item.y) && item.y !== 0);

  const selfPurchaseOptions = {
    spanGaps: true,
    maxBarThickness: 30,
    grouped: true,
    interaction: {
      mode: 'index',
    },
    plugins: {
      legend: {
        display: hasDataForFranchise,
      },
      tooltip: {
        position: 'nearest',
        titleColor: "#000",
        titleFont: { weight: "400", size: "10", family: "Montserrat" },
        bodyColor: "black",
        bodyFont: { weight: "bold", family: "Montserrat" },
        backgroundColor: "#fff",
        borderRadius: 10,
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderWidth: 1,
        padding: 14,
        filter: (item) => item.parsed.y !== null,
        callbacks: {
          title: (context) => {
            const date = context[0].label;
  
            let sum = 0;
            let parsedY;
            context.forEach((item) => {
              parsedY = item.parsed.y
              sum += item.parsed.y;
            });
 
            if (parsedY !== 0) {
              return date + '\n합계: ' + AddComma(sum) + '원';
            } else {
              return date;
            }
          },
          label: (context) => {
            const value = context.parsed.y;
            // 자점매입 매출 없을 때 tooltip 0원 숨김
            if (value !== null && value !== 0) {
              return AddComma((value)) + "원";
            }
            return null;
          },
        },
      }
    },
    scales: {
      x: {
        afterTickToLabelConversion: function (scaleInstance) {
          const ticks = scaleInstance.ticks;
  
          const newTicks = ticks.map((tick) => {
            return {
              ...tick,
              label: tick.label
              // label: tick.label.slice(-5)
            };
          });
  
          scaleInstance.ticks = newTicks;
        },
        ticks: {
          font: {
            family: "Montserrat",
            size: 14,
            color: "#000000",
          },
        },
        grid: {
          display: false,
        },
        axis: 'x',
        position: 'bottom',
        stacked: true,
      },
      y: {
        type: 'linear',
        ticks: {
          font: {
            family: "Montserrat",
            size: 14,
            color: "#000000",
          },
        },
        grid: {
          display: false,
          tickLength: 1,
          // color: '#E2E2E230',
        },
        afterDataLimits: (scale) => {
          // scale.max = scale.max * 1.2;
        },
        axis: 'y',
        display: true,
        position: 'left',
        stacked: true,
      },
    }
  };

  const data = {
    datasets: [
      {
        type: 'bar',
        backgroundColor: "rgba(255, 116, 64, 0.2)", // 피그마: 0.1(투명도 10%)
        hoverBackgroundColor: "#FF4A4A",
        borderRadius: 6,
        data: chart,
      },
    ],
  };

  const selfPurchaseData = {
    datasets: [
      {
        type: 'bar',
        label: '본사',
        backgroundColor: "rgba(255, 116, 64, 0.2)", // 피그마: 0.1(투명도 10%)
        hoverBackgroundColor: "#FF4A4A",
        borderRadius: 6,
        data: dataY1,
      },
      {
        type: 'bar',
        label: '자점매입',
        backgroundColor: "rgba(255, 235, 130, 0.2)", // 피그마: 0.1(투명도 10%)
        hoverBackgroundColor: "#FFEB82",
        borderRadius: 6,
        data: dataY2,
      },
    ]
  };
  
  return (
    <Container>
      {chart ? (
        <Bar type="bar" data={data} options={options} />
        ) : (
        <Bar type="bar" data={selfPurchaseData} options={selfPurchaseOptions} />
      )}
    </Container>
  );
};
    
export default memo(ExChartBar);

const Container = styled.div`
  width: 100%;
  max-width: 900px;
  text-align: center;
  margin: 0 auto;
  overflow: auto;

  @media (max-width: 1024px) {
    max-width: 100%;
    overflow: visible;
  }
`;