import React from "react";
import { Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./slideVer2.css";
import { Text, FlexBox } from "../../Assets/css/commonVer2";
import { AddComma } from "../../Utiles/Custom";
import { useNavigate } from "react-router-dom";

function HistorySlider(props) {
  const { space, perview, pagination, contents, breaks, mode } = props;

  const navigate = useNavigate();

  // 상세페이지 이동
  const handleMoveDetail = (detailId, mode, franchise_id) => {
    if (mode === "takeBack") {
      navigate(`/product/takeback/${detailId}`);
    } else {
      navigate(`/product/order/management/${detailId}`, {
        state: {
          order_date: detailId,
          franchise_id: franchise_id,
        },
      });
    }
  };

  return (
    <Swiper
      modules={[Pagination, A11y, Autoplay]}
      pagination={{
        clickable: true,
        el: `.${pagination}`,
        bulletClass: `swiper-pagination-bullet`,
      }}
      autoplay={false}
      loop={true}
      breakpoints={breaks}
      className="textSwiper"
    >
      <>
        {contents?.map((e, i) => (
          <SwiperSlide key={i}>
            {e?.map((item, idx) => (
              <div
                style={{
                  width: "calc(100% - 20px)",
                  background: "#fff",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                  margin: "10px",
                  padding: "12px",
                }}
                onClick={() => {
                  mode === "takeBack"
                    ? handleMoveDetail(item.take_back_id, mode)
                    : handleMoveDetail(item.order_date, mode, item.franchise_id);
                }}
              >
                <Text weight={"500"} color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"16px"} margin={"0 0 12px"}>
                  {item.franchise || item.franchise_name}
                </Text>
                <FlexBox justify={"space-between"} padding={"0 8px"}>
                  <Text size={"14px"} sizeMd={"16px"} color={"#1E1E1E"}>
                    품목 수량 :
                  </Text>
                  <Text size={"14px"} sizeMd={"16px"} color={"#2d2d2d"}>
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      {mode === "takeBack" ? AddComma(item.take_back_count) : AddComma(item.count)}
                    </span>
                    개
                  </Text>
                </FlexBox>
                <FlexBox justify={"space-between"} margin={"8px 0 0"} padding={"0 8px"}>
                  <Text size={"14px"} sizeMd={"16px"} color={"#1E1E1E"}>
                    {mode === "takeBack" ? "반품요청 금액 :" : "결제 금액 :"}
                  </Text>
                  <Text size={"14px"} sizeMd={"16px"} color={"#1E1E1E"}>
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      {mode === "takeBack" ? AddComma(item.amount) : AddComma(item.totalPrice)}
                    </span>
                    원
                  </Text>
                </FlexBox>
              </div>
            ))}
          </SwiperSlide>
        ))}
      </>
      <div className={`swiper-pagination ${pagination}`}></div>
    </Swiper>
  );
}

export default HistorySlider;
