// 외부 모듈
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// 내부 모듈
import { Title, Text } from "../../Assets/css/common";
import {
  ContentContainer,
  Wrapper,
  Container,
  Btn,
  DateInputBox,
  FlexBox,
  Contents,
  RadioLabel,
  RadioIcon,
  PagerWrap,
  HalfDiv,
} from "../../Assets/css/commonVer2";
import Header from "../../Components/Header/HeaderVer2";
import Authority from "../../Components/Modal/Authority";
import ChartPie from "../../Components/Chart/ChartPie";
import ExChartBar from "../../Components/Chart/ExChartBar";
import Pagination from "../../Components/Paging/Pagination";
import { AddComma, dateToString } from "../../Utiles/Custom";
import API from "../../Utiles/API";
import DataLoading from "../../Components/DataLoading";
import Paging from "../../Components/Paging/Paging";
import { CUSTOMER_PAYMENT_TYPE } from "../../Utiles/ListData";

function Sales() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [Franchise_id] = useState(sessionStorage.getItem("franchise_id"));

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [top, setTop] = useState([]); // 조회기간의 매출조회결과

  const [type, setType] = useState(""); // 일간/주간/월간 타입
  const [chart, setChart] = useState([]); // 일간/주간/월간 매출 현황 바그래프
  const [chart_total_sum, setChart_total_sum] = useState([]); // 일간/주간/월간 매출 현황 금액
  const [selfGoodsSum, setSelfGoodsSum] = useState(0);

  const [saleRank, setSaleRank] = useState([]); // 상품별판매순위

  const [saleViewList, setSaleViewList] = useState([]); // 판매내역

  const [pic, setPic] = useState([]); // 판매상품비중 상품 그래프
  const [saleCnt, setSaleCnt] = useState([]); // 판매상품비중 거래건수, 판매수량
  const [isSelfPurchase, setIsSelfPurchase] = useState(false); // 자점매입 데이터 여부
  const [slotPie, setSlotPie] = useState([]); // 판매상품비중 자점매입/본사 그래프

  const [dayitem, setDayitem] = useState([]); // 일자별매출현황

  // 판매내역 페이징
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [saleViewCurrentPage, setSaleViewCurrentPage] = useState(1);
  // 일자별 매출 현황 페이징
  const [dayPagination, setDayPagination] = useState("");
  const [dayCurrentPage, setDayCurrentPage] = useState(1);

  const [dataLoading, setDataLoading] = useState(false);

  // n간 매출 현황
  const getSaleChartData = async () => {
    try {
      setDataLoading(true);
      let api_link = `/storeowner/sale-select/${Franchise_id}?filterDatemin=${dateToString(
        startDate,
      )}&filterDatemax=${dateToString(endDate)}`;
      if (type) {
        api_link += `&weekSaleType=${type}`;
      }
      API.get(api_link).then((res) => {
        if (res.data.status) {
          setDataLoading(false);
          setTop(res.data.total_sales);
          setSaleRank(res.data.sale_product_list_result);
          setChart(res.data.week_sale_status_result);
          setPic(res.data.product_sale_percentage_result);
          setChart_total_sum(res.data.week_sale_status_result_total);
          setSaleCnt(res.data.product_sale_count);
          // 자점매입 매출 합계
          const sum = res.data.week_sale_status_result.reduce((a, b) => {
            return a + parseInt(b.y_franchise);
          }, 0);
          setSelfGoodsSum(sum);

          if (res.data.slot_percentage_result?.length > 0) {
            setIsSelfPurchase(true);
            setSlotPie(res.data.slot_percentage_result);
          }
        } else {
          setDataLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      setDataLoading(false);
    }
  };

  // 판매내역
  const getSaleList = async () => {
    try {
      const res = await API.get(
        `/storeowner/sale-select/product-history/${Franchise_id}?pageSize=10&curPage=${saleViewCurrentPage}&filterDatemin=${dateToString(
          startDate,
        )}&filterDatemax=${dateToString(endDate)}`,
      );
      if (res.data?.status) {
        setTotalCount(res.data.cntAll[0].cnt);
        setSaleViewList(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 일자별 매출 현황
  const dayItemList = async () => {
    try {
      const res = await API.get(
        `/storeowner/sale-select/${Franchise_id}/day-sale?filterDatemin=${dateToString(
          startDate,
        )}&filterDatemax=${dateToString(endDate)}&page=${dayCurrentPage}&unit=10&block=10`,
      );
      if (res.data?.status) {
        setDayitem(res.data.results);
        setDayPagination(res.data.pagination);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function onClickSearch() {
    getSaleChartData();
    if (saleViewCurrentPage === 1) {
      getSaleList();
    } else {
      setSaleViewCurrentPage(1);
    }
    if (dayCurrentPage === 1) {
      dayItemList();
    } else {
      setDayCurrentPage(1);
    }
  }

  function moveDayPage(e) {
    setDayCurrentPage(e);
  }

  // 타입 매칭 함수
  const getTextForType = (types) => {
    return types.map((type) => {
      const item = CUSTOMER_PAYMENT_TYPE.find((element) => element.value === type);
      return item ? item.text : "기타";
    });
  };

  useEffect(() => {
    getSaleChartData();
  }, [type]);

  useEffect(() => {
    setTotalPage(Math.ceil(totalCount / 10));
  }, [totalCount]);

  useEffect(() => {
    getSaleList();
  }, [saleViewCurrentPage]);

  useEffect(() => {
    dayItemList();
  }, [dayCurrentPage]);

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          {dataLoading ? <DataLoading /> : null}
          {isMobile && <Header title={"매출 조회"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {/* 매출 조회 결과 */}
                <Contents>
                  <FlexBox justify={"space-between"} margin={"0 0 20px"} marginMd={"0 0 10px"}>
                    {!isMobile && (
                      <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                        매출 조회
                      </Title>
                    )}
                    <DateAndBtnBox>
                      <FlexBox gap={"10px"} flexMd={"unset"} flex={"1"}>
                        <DateInputBox style={{ width: "100%" }}>
                          <DatePicker
                            dateFormatCalendar="yyyy년 MM월"
                            dateFormat="yyyy-MM-dd"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={endDate}
                          />
                        </DateInputBox>
                        <DateInputBox style={{ width: "100%" }}>
                          <DatePicker
                            dateFormatCalendar="yyyy년 MM월"
                            dateFormat="yyyy-MM-dd"
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                          />
                        </DateInputBox>
                      </FlexBox>
                      <Btn
                        width={"100%"}
                        widthMd={"unset"}
                        background={"#FF4A4A"}
                        color={"#fff"}
                        weight={"bold"}
                        padding={"8px 10px"}
                        paddingMd={"10px 19px 9px"}
                        size={"12px"}
                        sizeMd={"14px"}
                        onClick={onClickSearch}
                        style={{ minWidth: isMobile ? "48px" : "73px" }}
                      >
                        조 회
                      </Btn>
                    </DateAndBtnBox>
                  </FlexBox>
                  <Banner>
                    <BnTextSmall className="block md:hidden">
                      <span style={{ color: "#FF4A4A" }}>
                        {dateToString(startDate, ".")} - {dateToString(endDate, ".")}
                      </span>{" "}
                      매출 조회 결과
                    </BnTextSmall>
                    <div>
                      <div>
                        <BnTextSmall className="hidden md:block">
                          <span style={{ color: "#FF4A4A" }}>
                            {dateToString(startDate, ".")} - {dateToString(endDate, ".")}
                          </span>{" "}
                          매출 조회 결과
                        </BnTextSmall>
                        <FlexBox
                          style={{
                            flexDirection: "column",
                            gap: "8px",
                            gapMd: "14px",
                          }}
                        >
                          <div flex={1}>
                            <BnTextBig>
                              총 매출 <BnSpan>{AddComma(top?.amount)}원</BnSpan> 판매 건수{" "}
                              <BnSpan>{AddComma(top?.cnt)}건</BnSpan> 입니다.
                            </BnTextBig>
                          </div>
                          <BnTextBig>
                            많이 팔린 제품은 <BnSpan>{top?.product_name || "-"}</BnSpan> 입니다.
                          </BnTextBig>
                        </FlexBox>
                      </div>
                    </div>
                  </Banner>
                </Contents>
                {/* 웹 */}
                <div className="d-none d-md-block">
                  <FlexBox justify={"space-between"} align={"flex-start"} wrap={"wrap"}>
                    {/* 일간/주간/월간 매출 현황 */}
                    <Contents margin={"20px 0"} marginMd={"30px 0"}>
                      <WhiteBox style={{ minHeight: "600px" }}>
                        <FlexBox justify={"space-between"} margin={"0 0 10px"} marginMd={"0 0 20px"}>
                          <Text weight={"bold"} color={"#666"} size={"16px"}>
                            {type === "month" ? "월간 " : type === "week" ? "주간 " : "일간 "}
                            매출 현황
                          </Text>
                          <FlexBox>
                            <FlexBox gap={"10px"} justify={"start"}>
                              <RadioLabel>
                                <input type="radio" checked={type === ""} onChange={() => setType("")} />
                                <RadioIcon>
                                  <svg viewBox="0 0 18 18">
                                    <circle cx="9" cy="9" r="8.5" />
                                    <circle cx="9" cy="9" r="5" />
                                  </svg>
                                </RadioIcon>
                                일간
                              </RadioLabel>
                              <RadioLabel>
                                <input type="radio" checked={type === "week"} onChange={() => setType("week")} />
                                <RadioIcon>
                                  <svg viewBox="0 0 18 18">
                                    <circle cx="9" cy="9" r="8.5" />
                                    <circle cx="9" cy="9" r="5" />
                                  </svg>
                                </RadioIcon>
                                주간
                              </RadioLabel>
                              <RadioLabel>
                                <input type="radio" checked={type === "month"} onChange={() => setType("month")} />
                                <RadioIcon>
                                  <svg viewBox="0 0 18 18">
                                    <circle cx="9" cy="9" r="8.5" />
                                    <circle cx="9" cy="9" r="5" />
                                  </svg>
                                </RadioIcon>
                                월간
                              </RadioLabel>
                            </FlexBox>
                            <ViewAllSalesBtn onClick={() => navigate("salesview")}>전체보기</ViewAllSalesBtn>
                          </FlexBox>
                        </FlexBox>
                        <ExChartBar selfPurchaseChart={chart} />
                        <CardWrap className="d-none d-md-block" style={{ marginTop: "30px" }}>
                          <SubCardWrap>
                            <CardBox className="total">
                              <CardLine className="total" />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Text color={"#1E1E1E"} size={"14px"}>
                                  총 매출
                                </Text>
                                <span
                                  style={{
                                    color: "#FF4A4A",
                                    fontFamily: "Montserrat",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    display: "block",
                                  }}
                                >
                                  {chart_total_sum ? AddComma(chart_total_sum[0]?.amount) : 0}원
                                </span>
                              </div>
                            </CardBox>
                            <CardBox className="total">
                              <CardLine className="total" />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Text color={"#1E1E1E"} size={"14px"} align="start">
                                  자점매입
                                </Text>
                                <span
                                  style={{
                                    color: "#FF4A4A",
                                    fontFamily: "Montserrat",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    display: "block",
                                  }}
                                >
                                  {selfGoodsSum ? AddComma(selfGoodsSum) : 0}원
                                </span>
                              </div>
                            </CardBox>
                          </SubCardWrap>
                          <SubCardWrap>
                            <CardBox>
                              <CardLine />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Text size={"14px"} color={"#000"}>
                                  카드
                                </Text>
                                <span
                                  style={{
                                    color: "#000",
                                    fontFamily: "Montserrat",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    display: "block",
                                  }}
                                >
                                  {chart_total_sum ? AddComma(chart_total_sum[0]?.card) : 0}원
                                </span>
                              </div>
                            </CardBox>
                            <CardBox>
                              <CardLine />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Text size={"14px"} color={"#000"}>
                                  현금
                                </Text>
                                <span
                                  style={{
                                    color: "#000",
                                    fontFamily: "Montserrat",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    display: "block",
                                  }}
                                >
                                  {chart_total_sum ? AddComma(chart_total_sum[0]?.cash) : 0}원
                                </span>
                              </div>
                            </CardBox>
                            <CardBox>
                              <CardLine />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Text size={"14px"} color={"#000"}>
                                  포인트
                                </Text>
                                <span
                                  style={{
                                    color: "#000",
                                    fontFamily: "Montserrat",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    display: "block",
                                  }}
                                >
                                  {chart_total_sum ? AddComma(chart_total_sum[0]?.point) : 0}원
                                </span>
                              </div>
                            </CardBox>
                          </SubCardWrap>
                          <SubCardWrap>
                            <CardBox>
                              <CardLine />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Text size={"14px"} color={"#000"}>
                                  카카오페이
                                </Text>
                                <span
                                  style={{
                                    color: "#000",
                                    fontFamily: "Montserrat",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    display: "block",
                                  }}
                                >
                                  {chart_total_sum ? AddComma(chart_total_sum[0]?.kakao) : 0}원
                                </span>
                              </div>
                            </CardBox>
                            <CardBox>
                              <CardLine />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Text size={"14px"} color={"#000"}>
                                  제로페이
                                </Text>
                                <span
                                  style={{
                                    color: "#000",
                                    fontFamily: "Montserrat",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    display: "block",
                                  }}
                                >
                                  {chart_total_sum ? AddComma(chart_total_sum[0]?.zero) : 0}원
                                </span>
                              </div>
                            </CardBox>
                            <CardBox>
                              <CardLine />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Text size={"14px"} color={"#000"}>
                                  네이버페이
                                </Text>
                                <span
                                  style={{
                                    color: "#000",
                                    fontFamily: "Montserrat",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    display: "block",
                                  }}
                                >
                                  {chart_total_sum ? AddComma(chart_total_sum[0]?.naver) : 0}원
                                </span>
                              </div>
                            </CardBox>
                          </SubCardWrap>
                        </CardWrap>
                      </WhiteBox>
                    </Contents>
                    {/* 상품별 판매 순위 */}
                    <HalfDiv>
                      <Contents margin={"0 0 20px"} marginMd={"0 0 30px"}>
                        <TableWhiteBox className={saleRank?.length > 0 ? "responsive" : ""}>
                          <FlexBox justify={"space-between"} margin={"0 0 16px"} wrap={"wrap"} gap={"10px"}>
                            <Text weight={"bold"} color={"#666"} size={"16px"}>
                              상품별 판매 순위
                            </Text>
                            <FlexBox gap={"8px"} gapMd={"10px"} justify={"end"}>
                              <TotalSaleBtn onClick={() => navigate("itemrank")}>상세보기 →</TotalSaleBtn>
                            </FlexBox>
                          </FlexBox>
                          <table style={{ width: "100%" }}>
                            <thead>
                              <colgroup>
                                <col style={{ width: "25%" }} />
                                <col style={{ width: "25%" }} />
                                <col style={{ width: "25%" }} />
                                <col style={{ width: "25%" }} />
                              </colgroup>
                              <tr>
                                <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                  <Text weight={"bold"} size={"16px"}>
                                    상품분류
                                  </Text>
                                </th>
                                <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                  <Text weight={"bold"} size={"16px"}>
                                    상품명
                                  </Text>
                                </th>
                                <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                  <Text weight={"bold"} size={"16px"}>
                                    판매수량
                                  </Text>
                                </th>
                                <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                  <Text weight={"bold"} size={"16px"}>
                                    결제금액
                                  </Text>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {saleRank?.length > 0 ? (
                                saleRank?.map((list, key) => (
                                  <tr key={key}>
                                    <Td
                                      size={"12px"}
                                      sizeMd={"16px"}
                                      weight={"400"}
                                      color={"#666"}
                                      align={"center"}
                                      padding={"10px 0"}
                                      style={{ borderBottom: "1px solid #c7c7c7" }}
                                    >
                                      {list.name}
                                    </Td>
                                    <Td
                                      size={"12px"}
                                      sizeMd={"16px"}
                                      weight={"400"}
                                      color={"#666"}
                                      align={"center"}
                                      padding={"10px 0"}
                                      style={{ borderBottom: "1px solid #c7c7c7" }}
                                    >
                                      {list.product_name}
                                    </Td>
                                    <Td
                                      size={"12px"}
                                      sizeMd={"16px"}
                                      weight={"400"}
                                      color={"#666"}
                                      align={"center"}
                                      padding={"10px 0"}
                                      style={{ borderBottom: "1px solid #c7c7c7" }}
                                    >
                                      {list.cnt}
                                    </Td>
                                    <Td
                                      size={"12px"}
                                      sizeMd={"16px"}
                                      weight={"400"}
                                      color={"#666"}
                                      align={"center"}
                                      padding={"10px 0"}
                                      style={{ borderBottom: "1px solid #c7c7c7" }}
                                    >
                                      {AddComma(list.payment_amount)}원
                                    </Td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <Td
                                    colSpan={4}
                                    style={{
                                      textAlign: "center",
                                      padding: 10,
                                      fontSize: "14px",
                                      color: "#666",
                                    }}
                                  >
                                    데이터가 없습니다.
                                  </Td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </TableWhiteBox>
                      </Contents>
                    </HalfDiv>
                    {/* 판매 내역 */}
                    <HalfDiv>
                      <Contents margin={"0 0 20px"} marginMd={"0 0 30px"}>
                        <TableWhiteBox>
                          <Text
                            weight={"bold"}
                            color={"#666"}
                            size={"14px"}
                            sizeMd={"16px"}
                            align={"left"}
                            margin={"0 0 20px"}
                          >
                            판매 내역
                          </Text>
                          <table style={{ width: "100%" }}>
                            <thead>
                              <colgroup>
                                <col style={{ width: "20%" }} />
                                <col style={{ width: "20%" }} />
                                <col style={{ width: "20%" }} />
                                <col style={{ width: "20%" }} />
                                <col style={{ width: "20%" }} />
                                <col style={{ width: "20%" }} />
                              </colgroup>
                              <tr>
                                <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                  <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                    일시
                                  </Text>
                                </th>
                                <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                  <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                    결제수단
                                  </Text>
                                </th>
                                <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                  <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                    상품명
                                  </Text>
                                </th>
                                <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                  <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                    수량
                                  </Text>
                                </th>
                                <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                  <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                    개별 결제금액
                                  </Text>
                                </th>
                                <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                  <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                    총 결제금액
                                  </Text>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {saleViewList?.length > 0 ? (
                                saleViewList?.map((item, key) => (
                                  <tr key={key}>
                                    <Td
                                      size={"12px"}
                                      sizeMd={"16px"}
                                      weight={"400"}
                                      color={"#666"}
                                      align={"center"}
                                      padding={"6px 0"}
                                      style={{ borderBottom: "1px solid #c7c7c7" }}
                                    >
                                      {item.createdat}
                                    </Td>
                                    <Td
                                      size={"12px"}
                                      sizeMd={"16px"}
                                      weight={"400"}
                                      color={"#666"}
                                      align={"center"}
                                      padding={"6px 0"}
                                      style={{ borderBottom: "1px solid #c7c7c7" }}
                                    >
                                      {getTextForType(item.type).join(",")}
                                    </Td>
                                    <Td
                                      size={"12px"}
                                      sizeMd={"16px"}
                                      weight={"400"}
                                      color={"#666"}
                                      align={"center"}
                                      padding={"6px 0"}
                                      style={{ borderBottom: "1px solid #c7c7c7" }}
                                    >
                                      {item.product_name}
                                    </Td>
                                    <Td
                                      size={"12px"}
                                      sizeMd={"16px"}
                                      weight={"400"}
                                      color={"#666"}
                                      align={"center"}
                                      padding={"6px 0"}
                                      style={{ borderBottom: "1px solid #c7c7c7" }}
                                    >
                                      {item.quantity}개
                                    </Td>
                                    <Td
                                      size={"12px"}
                                      sizeMd={"16px"}
                                      weight={"400"}
                                      color={"#666"}
                                      align={"center"}
                                      padding={"6px 0"}
                                      style={{ borderBottom: "1px solid #c7c7c7" }}
                                    >
                                      {AddComma(item.payment_amount)}원
                                    </Td>
                                    <Td
                                      size={"12px"}
                                      sizeMd={"16px"}
                                      weight={"400"}
                                      color={"#666"}
                                      align={"center"}
                                      padding={"6px 0"}
                                      style={{ borderBottom: "1px solid #c7c7c7" }}
                                    >
                                      {AddComma(item.total_payment_amount)}원
                                    </Td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <Td
                                    colSpan={5}
                                    style={{
                                      textAlign: "center",
                                      padding: 10,
                                      fontSize: "14px",
                                      color: "#666",
                                    }}
                                  >
                                    데이터가 없습니다.
                                  </Td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {saleViewList.length > 0 ? (
                            <PagerWrap margin={"0"}>
                              <Paging
                                totalPage={totalPage}
                                limit={10}
                                page={saleViewCurrentPage}
                                setPage={setSaleViewCurrentPage}
                              />
                            </PagerWrap>
                          ) : (
                            ""
                          )}
                        </TableWhiteBox>
                      </Contents>
                    </HalfDiv>
                    {/* 판매 상품 비중 */}
                    <HalfDiv>
                      <Contents margin={"0 0 20px"} marginMd={"0"}>
                        <WhiteBox style={{ minHeight: "600px" }}>
                          <FlexBox justify={"space-between"} margin={"0 0 10px"} marginMd={"0 0 16px"}>
                            <Text weight={"bold"} color={"#666"} size={"14px"} sizeMd={"16px"}>
                              판매 상품 비중
                            </Text>
                          </FlexBox>
                          <FlexBox direction={"column"} align={"flex-starts"}>
                            <FlexBox justify={"left"} gap={"10px"}>
                              <PieTitBox>
                                <PieTitLine />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Text color={"#000"} size={"14px"}>
                                    거래 건수
                                  </Text>
                                  <span
                                    style={{
                                      color: "#FF4A4A",
                                      fontFamily: "Montserrat",
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                      display: "block",
                                    }}
                                  >
                                    {saleCnt.total_payment_cnt}
                                  </span>
                                </div>
                              </PieTitBox>
                              <PieTitBox>
                                <PieTitLine />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Text color={"#000"} size={"14px"}>
                                    판매 수량
                                  </Text>
                                  <span
                                    style={{
                                      color: "#FF4A4A",
                                      fontFamily: "Montserrat",
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                      display: "block",
                                    }}
                                  >
                                    {saleCnt?.total_kiosk_sale_cnt}
                                  </span>
                                </div>
                              </PieTitBox>
                            </FlexBox>
                            {isSelfPurchase ? (
                              <FlexBox margin={"30px auto"}>
                                <ChartPie pic={pic} slotPie={slotPie} />
                              </FlexBox>
                            ) : (
                              <div style={{ maxWidth: "400px", margin: "20px auto" }}>
                                <ChartPie pic={pic} />
                              </div>
                            )}
                          </FlexBox>
                        </WhiteBox>
                      </Contents>
                    </HalfDiv>
                    {/* 일자별 매출 현황 */}
                    <HalfDiv>
                      <Contents>
                        <WhiteBox style={{ minHeight: "600px" }}>
                          <FlexBox justify={"space-between"} margin={"0 0 10px"} marginMd={"0 0 20px"}>
                            <Text weight={"bold"} color={"#666"} size={"14px"} sizeMd={"16px"}>
                              일자별 매출 현황
                            </Text>
                          </FlexBox>
                          <table style={{ width: "100%" }}>
                            <thead>
                              <colgroup>
                                <col style={{ width: "25%" }} />
                                <col style={{ width: "25%" }} />
                                <col style={{ width: "25%" }} />
                                <col style={{ width: "25%" }} />
                              </colgroup>
                              <tr>
                                <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                  <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                    일자
                                  </Text>
                                </th>
                                <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                  <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                    총 매출
                                  </Text>
                                </th>
                                <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                  <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                    현금
                                  </Text>
                                </th>
                                <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                  <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                    카드
                                  </Text>
                                </th>
                                <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                  <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                    기타
                                  </Text>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {dayitem?.length > 0 ? (
                                dayitem?.map((list, key) => (
                                  <tr key={key}>
                                    <Td
                                      size={"12px"}
                                      sizeMd={"16px"}
                                      weight={"400"}
                                      color={"#666"}
                                      align={"center"}
                                      padding={"10px 0"}
                                      style={{ borderBottom: "1px solid #c7c7c7" }}
                                    >
                                      {list.createdAt}
                                    </Td>
                                    <Td
                                      size={"12px"}
                                      sizeMd={"16px"}
                                      weight={"bold"}
                                      color={"#666"}
                                      align={"center"}
                                      padding={"10px 0"}
                                      style={{ borderBottom: "1px solid #c7c7c7" }}
                                    >
                                      {AddComma(list.total_sale)}원
                                    </Td>
                                    <Td
                                      size={"12px"}
                                      sizeMd={"16px"}
                                      weight={"400"}
                                      color={"#666"}
                                      align={"center"}
                                      padding={"10px 0"}
                                      style={{ borderBottom: "1px solid #c7c7c7" }}
                                    >
                                      {AddComma(list.cash)}원
                                    </Td>
                                    <Td
                                      size={"12px"}
                                      sizeMd={"16px"}
                                      weight={"400"}
                                      color={"#666"}
                                      align={"center"}
                                      padding={"10px 0"}
                                      style={{ borderBottom: "1px solid #c7c7c7" }}
                                    >
                                      {AddComma(list.card)}원
                                    </Td>
                                    <Td
                                      size={"12px"}
                                      sizeMd={"16px"}
                                      weight={"400"}
                                      color={"#666"}
                                      align={"center"}
                                      padding={"10px 0"}
                                      style={{ borderBottom: "1px solid #c7c7c7" }}
                                    >
                                      {AddComma(list.point)}원
                                    </Td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <Td
                                    colSpan={5}
                                    style={{
                                      textAlign: "center",
                                      padding: 10,
                                      fontSize: "14px",
                                      color: "#666",
                                    }}
                                  >
                                    데이터가 없습니다.
                                  </Td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {dayPagination?.total_page > 0 ? (
                            <Pagination
                              pagination={dayPagination}
                              movePage={moveDayPage}
                              currentPage={dayCurrentPage}
                            />
                          ) : (
                            ""
                          )}
                        </WhiteBox>
                      </Contents>
                    </HalfDiv>
                  </FlexBox>
                </div>
                {/* 모바일 */}
                <div className="d-block d-md-none">
                  {/* 일간/주간/월간 매출 현황 */}
                  <Contents>
                    <WhiteBox style={{ margin: "20px 0" }}>
                      <Text weight={"bold"} color={"#666"} size={"14px"} align={"left"}>
                        {type === "month" ? "월간 " : type === "week" ? "주간 " : "일간 "}
                        매출 현황
                      </Text>
                      <RadioAndBtn>
                        <FlexBox gap={"10px"} justify={"start"}>
                          <RadioLabel>
                            <input type="radio" checked={type === ""} onChange={() => setType("")} />
                            <RadioIcon>
                              <svg viewBox="0 0 18 18">
                                <circle cx="9" cy="9" r="8.5" />
                                <circle cx="9" cy="9" r="5" />
                              </svg>
                            </RadioIcon>
                            <Text size={"12px"}>일간</Text>
                          </RadioLabel>
                          <RadioLabel>
                            <input type="radio" checked={type === "week"} onChange={() => setType("week")} />
                            <RadioIcon>
                              <svg viewBox="0 0 18 18">
                                <circle cx="9" cy="9" r="8.5" />
                                <circle cx="9" cy="9" r="5" />
                              </svg>
                            </RadioIcon>
                            <Text size={"12px"}>주간</Text>
                          </RadioLabel>
                          <RadioLabel>
                            <input type="radio" checked={type === "month"} onChange={() => setType("month")} />
                            <RadioIcon>
                              <svg viewBox="0 0 18 18">
                                <circle cx="9" cy="9" r="8.5" />
                                <circle cx="9" cy="9" r="5" />
                              </svg>
                            </RadioIcon>
                            <Text size={"12px"}>월간</Text>
                          </RadioLabel>
                        </FlexBox>
                        <ViewAllSalesBtn onClick={() => navigate("salesview")}>전체보기</ViewAllSalesBtn>
                      </RadioAndBtn>
                      <div
                        style={{
                          overflowX: "scroll",
                          flex: 1,
                          // padding: "20px",
                        }}
                      >
                        <div style={{ minWidth: "300px" }}>
                          <ExChartBar selfPurchaseChart={chart} />
                        </div>
                      </div>
                      <CardWrap className="d-block d-md-none">
                        <CardBox className="total">
                          <CardLine className="total" />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Text color={"#1E1E1E"} size={"14px"}>
                              총 매출
                            </Text>
                            <span
                              style={{
                                color: "#FF4A4A",
                                fontFamily: "Montserrat",
                                fontWeight: "bold",
                                fontSize: "14px",
                                display: "block",
                              }}
                            >
                              {chart_total_sum ? AddComma(chart_total_sum[0]?.amount) : 0}원
                            </span>
                          </div>
                        </CardBox>
                        <CardBox className="total" style={{ marginTop: "5px" }}>
                          <CardLine className="total" />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Text color={"#1E1E1E"} size={"14px"}>
                              자점매입
                            </Text>
                            <span
                              style={{
                                color: "#FF4A4A",
                                fontFamily: "Montserrat",
                                fontWeight: "bold",
                                fontSize: "14px",
                                display: "block",
                              }}
                            >
                              {selfGoodsSum ? AddComma(selfGoodsSum) : 0}원
                            </span>
                          </div>
                        </CardBox>
                        <CardBox>
                          <CardLine />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Text size={"14px"} color={"#000"}>
                              카드
                            </Text>
                            <span
                              style={{
                                color: "#000",
                                fontFamily: "Montserrat",
                                fontWeight: "400",
                                fontSize: "14px",
                                display: "block",
                              }}
                            >
                              {chart_total_sum ? AddComma(chart_total_sum[0]?.card) : 0}원
                            </span>
                          </div>
                        </CardBox>
                        <CardBox>
                          <CardLine />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Text size={"14px"} color={"#000"}>
                              현금
                            </Text>
                            <span
                              style={{
                                color: "#000",
                                fontFamily: "Montserrat",
                                fontWeight: "400",
                                fontSize: "14px",
                                display: "block",
                              }}
                            >
                              {chart_total_sum ? AddComma(chart_total_sum[0]?.cash) : 0}원
                            </span>
                          </div>
                        </CardBox>
                        <CardBox>
                          <CardLine />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Text size={"14px"} color={"#000"}>
                              포인트
                            </Text>
                            <span
                              style={{
                                color: "#000",
                                fontFamily: "Montserrat",
                                fontWeight: "400",
                                fontSize: "14px",
                                display: "block",
                              }}
                            >
                              {chart_total_sum ? AddComma(chart_total_sum[0]?.point) : 0}원
                            </span>
                          </div>
                        </CardBox>
                        <CardBox>
                          <CardLine />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Text size={"14px"} color={"#000"}>
                              카카오페이
                            </Text>
                            <span
                              style={{
                                color: "#000",
                                fontFamily: "Montserrat",
                                fontWeight: "400",
                                fontSize: "14px",
                                display: "block",
                              }}
                            >
                              {chart_total_sum ? AddComma(chart_total_sum[0]?.kakao) : 0}원
                            </span>
                          </div>
                        </CardBox>
                        <CardBox>
                          <CardLine />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Text size={"14px"} color={"#000"}>
                              제로페이
                            </Text>
                            <span
                              style={{
                                color: "#000",
                                fontFamily: "Montserrat",
                                fontWeight: "400",
                                fontSize: "14px",
                                display: "block",
                              }}
                            >
                              {chart_total_sum ? AddComma(chart_total_sum[0]?.zero) : 0}원
                            </span>
                          </div>
                        </CardBox>
                        <CardBox>
                          <CardLine />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Text size={"14px"} color={"#000"}>
                              네이버페이
                            </Text>
                            <span
                              style={{
                                color: "#000",
                                fontFamily: "Montserrat",
                                fontWeight: "400",
                                fontSize: "14px",
                                display: "block",
                              }}
                            >
                              {chart_total_sum ? AddComma(chart_total_sum[0]?.naver) : 0}원
                            </span>
                          </div>
                        </CardBox>
                      </CardWrap>
                    </WhiteBox>
                  </Contents>
                  {/* 상품별 판매 순위 */}
                  <Contents margin={"0 0 20px"}>
                    <WhiteBox>
                      <FlexBox justify={"space-between"} margin={"0 0 16px"}>
                        <Text weight={"bold"} color={"#666"} size={"14px"}>
                          상품별 판매 순위
                        </Text>
                        <FlexBox gap={"8px"} gapMd={"10px"}>
                          <TotalSaleBtn onClick={() => navigate("itemrank")}>상세보기</TotalSaleBtn>
                        </FlexBox>
                      </FlexBox>
                      <table style={{ width: "100%" }}>
                        <thead>
                          <colgroup>
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                          </colgroup>
                          <tr>
                            <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                              <Text weight={"bold"} size={"14px"}>
                                상품분류
                              </Text>
                            </th>
                            <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                              <Text weight={"bold"} size={"14px"}>
                                상품명
                              </Text>
                            </th>
                            <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                              <Text weight={"bold"} size={"14px"}>
                                판매수량
                              </Text>
                            </th>
                            <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                              <Text weight={"bold"} size={"14px"}>
                                결제금액
                              </Text>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {saleRank?.length > 0 ? (
                            saleRank?.map((list, key) => (
                              <tr key={key}>
                                <Td
                                  size={"12px"}
                                  sizeMd={"16px"}
                                  weight={"400"}
                                  color={"#666"}
                                  align={"center"}
                                  padding={"12px 0"}
                                  style={{ borderBottom: "1px solid #c7c7c7" }}
                                >
                                  {list.name}
                                </Td>
                                <Td
                                  size={"12px"}
                                  sizeMd={"16px"}
                                  weight={"400"}
                                  color={"#666"}
                                  align={"center"}
                                  padding={"12px 0"}
                                  style={{ borderBottom: "1px solid #c7c7c7" }}
                                >
                                  {list.product_name}
                                </Td>
                                <Td
                                  size={"12px"}
                                  sizeMd={"16px"}
                                  weight={"400"}
                                  color={"#666"}
                                  align={"center"}
                                  padding={"12px 0"}
                                  style={{ borderBottom: "1px solid #c7c7c7" }}
                                >
                                  {list.cnt}
                                </Td>
                                <Td
                                  size={"12px"}
                                  sizeMd={"16px"}
                                  weight={"400"}
                                  color={"#666"}
                                  align={"center"}
                                  padding={"12px 0"}
                                  style={{ borderBottom: "1px solid #c7c7c7" }}
                                >
                                  {AddComma(list.payment_amount)}원
                                </Td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <Td
                                colSpan={4}
                                style={{
                                  textAlign: "center",
                                  padding: 10,
                                  fontSize: "14px",
                                  color: "#666",
                                }}
                              >
                                데이터가 없습니다.
                              </Td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </WhiteBox>
                  </Contents>
                  {/* 판매 내역 */}
                  <Contents>
                    <WhiteBox>
                      <Text weight={"bold"} color={"#666"} size={"14px"} sizeMd={"16px"} align={"left"}>
                        판매 내역
                      </Text>
                      <MobileTableWrap>
                        <table style={{ width: "100%" }}>
                          <thead>
                            <colgroup>
                              <col style={{ width: "20%" }} />
                              <col style={{ width: "20%" }} />
                              <col style={{ width: "20%" }} />
                              <col style={{ width: "20%" }} />
                              <col style={{ width: "20%" }} />
                              <col style={{ width: "20%" }} />
                            </colgroup>
                            <tr>
                              <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                  일시
                                </Text>
                              </th>
                              <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                  결제수단
                                </Text>
                              </th>
                              <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                  상품명
                                </Text>
                              </th>
                              <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                  수량
                                </Text>
                              </th>
                              <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                  개별 결제금액
                                </Text>
                              </th>
                              <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                  총 결제금액
                                </Text>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {saleViewList?.length > 0 ? (
                              saleViewList?.map((item, key) => (
                                <tr key={key}>
                                  <Td
                                    size={"12px"}
                                    sizeMd={"16px"}
                                    weight={"400"}
                                    color={"#666"}
                                    align={"center"}
                                    padding={"6px 0"}
                                    style={{ borderBottom: "1px solid #c7c7c7" }}
                                  >
                                    {item.createdat}
                                  </Td>
                                  <Td
                                    size={"12px"}
                                    sizeMd={"16px"}
                                    weight={"400"}
                                    color={"#666"}
                                    align={"center"}
                                    padding={"6px 0"}
                                    style={{ borderBottom: "1px solid #c7c7c7" }}
                                  >
                                    {getTextForType(item.type).join(",")}
                                  </Td>
                                  <Td
                                    size={"12px"}
                                    sizeMd={"16px"}
                                    weight={"400"}
                                    color={"#666"}
                                    align={"center"}
                                    padding={"6px 0"}
                                    style={{ borderBottom: "1px solid #c7c7c7" }}
                                  >
                                    {item.product_name}
                                  </Td>
                                  <Td
                                    size={"12px"}
                                    sizeMd={"16px"}
                                    weight={"400"}
                                    color={"#666"}
                                    align={"center"}
                                    padding={"6px 0"}
                                    style={{ borderBottom: "1px solid #c7c7c7" }}
                                  >
                                    {item.quantity}개
                                  </Td>
                                  <Td
                                    size={"12px"}
                                    sizeMd={"16px"}
                                    weight={"400"}
                                    color={"#666"}
                                    align={"center"}
                                    padding={"6px 0"}
                                    style={{ borderBottom: "1px solid #c7c7c7" }}
                                  >
                                    {AddComma(item.payment_amount)}원
                                  </Td>
                                  <Td
                                    size={"12px"}
                                    sizeMd={"16px"}
                                    weight={"400"}
                                    color={"#666"}
                                    align={"center"}
                                    padding={"6px 0"}
                                    style={{ borderBottom: "1px solid #c7c7c7" }}
                                  >
                                    {AddComma(item.total_payment_amount)}원
                                  </Td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <Td
                                  colSpan={5}
                                  style={{
                                    textAlign: "center",
                                    padding: 10,
                                    fontSize: "14px",
                                    color: "#666",
                                  }}
                                >
                                  데이터가 없습니다.
                                </Td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </MobileTableWrap>
                      {saleViewList.length > 0 ? (
                        <PagerWrap margin={"0"}>
                          <Paging
                            totalPage={totalPage}
                            limit={10}
                            page={saleViewCurrentPage}
                            setPage={setSaleViewCurrentPage}
                          />
                        </PagerWrap>
                      ) : (
                        ""
                      )}
                    </WhiteBox>
                  </Contents>
                  {/* 판매 상품 비중 */}
                  <Contents>
                    <WhiteBox style={{ margin: "20px 0" }}>
                      <FlexBox justify={"space-between"} margin={"0 0 10px"} marginMd={"0 0 16px"}>
                        <Text weight={"bold"} color={"#666"} size={"14px"} sizeMd={"16px"}>
                          판매 상품 비중
                        </Text>
                      </FlexBox>
                      <FlexBox direction={"column"}>
                        <FlexBox direction={"column"} gap={"6px"} width={"100%"}>
                          <PieTitBox>
                            <PieTitLine />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Text color={"#000"} size={"14px"}>
                                거래 건수
                              </Text>
                              <span
                                style={{
                                  color: "#FF4A4A",
                                  fontFamily: "Montserrat",
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                  display: "block",
                                }}
                              >
                                {saleCnt.total_payment_cnt}
                              </span>
                            </div>
                          </PieTitBox>
                          <PieTitBox>
                            <PieTitLine />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Text color={"#000"} size={"14px"}>
                                판매 수량
                              </Text>
                              <span
                                style={{
                                  color: "#FF4A4A",
                                  fontFamily: "Montserrat",
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                  display: "block",
                                }}
                              >
                                {saleCnt?.total_kiosk_sale_cnt}
                              </span>
                            </div>
                          </PieTitBox>
                        </FlexBox>
                        {isSelfPurchase ? (
                          <FlexBox margin={"30px auto"}>
                            <ChartPie pic={pic} slotPie={slotPie} />
                          </FlexBox>
                        ) : (
                          <div style={{ margin: "20px 0" }}>
                            <ChartPie pic={pic} />
                          </div>
                        )}
                      </FlexBox>
                    </WhiteBox>
                  </Contents>
                  {/* 일자별 매출 현황 */}
                  <Contents>
                    <WhiteBox>
                      <FlexBox justify={"space-between"} margin={"0 0 10px"} marginMd={"0 0 20px"}>
                        <Text weight={"bold"} color={"#666"} size={"14px"} sizeMd={"16px"}>
                          일자별 매출 현황
                        </Text>
                      </FlexBox>
                      <MobileTableWrap>
                        <table style={{ width: "100%" }}>
                          <thead>
                            <colgroup>
                              <col style={{ width: "25%" }} />
                              <col style={{ width: "25%" }} />
                              <col style={{ width: "25%" }} />
                              <col style={{ width: "25%" }} />
                            </colgroup>
                            <tr>
                              <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                  일자
                                </Text>
                              </th>
                              <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                  총 매출
                                </Text>
                              </th>
                              <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                  현금
                                </Text>
                              </th>
                              <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                  카드
                                </Text>
                              </th>
                              <th style={{ padding: "14px 0", borderBottom: "1px solid #666" }}>
                                <Text weight={"bold"} size={"14px"} sizeMd={"16px"}>
                                  기타
                                </Text>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {dayitem?.length > 0 ? (
                              dayitem?.map((list, key) => (
                                <tr key={key}>
                                  <Td
                                    size={"12px"}
                                    sizeMd={"16px"}
                                    weight={"400"}
                                    color={"#666"}
                                    align={"center"}
                                    padding={"6px 0"}
                                    style={{ borderBottom: "1px solid #c7c7c7" }}
                                  >
                                    {list.createdAt}
                                  </Td>
                                  <Td
                                    size={"12px"}
                                    sizeMd={"16px"}
                                    weight={"bold"}
                                    color={"#666"}
                                    align={"center"}
                                    padding={"10px 0"}
                                    style={{ borderBottom: "1px solid #c7c7c7" }}
                                  >
                                    {AddComma(list.total_sale)}원
                                  </Td>
                                  <Td
                                    size={"12px"}
                                    sizeMd={"16px"}
                                    weight={"400"}
                                    color={"#666"}
                                    align={"center"}
                                    padding={"6px 0"}
                                    style={{ borderBottom: "1px solid #c7c7c7" }}
                                  >
                                    {AddComma(list.cash)}원
                                  </Td>
                                  <Td
                                    size={"12px"}
                                    sizeMd={"16px"}
                                    weight={"400"}
                                    color={"#666"}
                                    align={"center"}
                                    padding={"6px 0"}
                                    style={{ borderBottom: "1px solid #c7c7c7" }}
                                  >
                                    {AddComma(list.card)}원
                                  </Td>
                                  <Td
                                    size={"12px"}
                                    sizeMd={"16px"}
                                    weight={"400"}
                                    color={"#666"}
                                    align={"center"}
                                    padding={"6px 0"}
                                    style={{ borderBottom: "1px solid #c7c7c7" }}
                                  >
                                    {AddComma(list.point)}원
                                  </Td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <Td
                                  colSpan={5}
                                  style={{
                                    textAlign: "center",
                                    padding: 10,
                                    fontSize: "14px",
                                    color: "#666",
                                  }}
                                >
                                  데이터가 없습니다.
                                </Td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </MobileTableWrap>
                      {dayPagination?.total_page > 0 ? (
                        <Pagination pagination={dayPagination} movePage={moveDayPage} currentPage={dayCurrentPage} />
                      ) : (
                        ""
                      )}
                    </WhiteBox>
                  </Contents>
                </div>
              </Container>
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const Td = styled.td`
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.bgColor || ""};
  color: ${(props) => props.color || "#2d2d2d"};
  font-size: ${(props) => props.size || "14px"};
  font-weight: ${(props) => props.weight || "500"};
  margin: ${(props) => props.margin || "0"};
  text-align: ${(props) => props.align || "right"};
`;

const Banner = styled.div`
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 40px 44px;
  border-radius: 10px;

  @media all and (max-width: 1024px) {
    padding: 17px;
  }
`;
const BnTextSmall = styled.p`
  font-size: 18px;
  color: #1e1e1e;
  word-break: keep-all;
  margin-bottom: 40px;

  @media all and (max-width: 1024px) {
    font-size: 12px;
    margin-bottom: 16px;
  }
`;
const BnTextBig = styled.p`
  font-size: 20px;
  color: #1e1e1e;
  font-weight: 400;
  word-break: keep-all;
  line-height: 64px;

  @media all and (max-width: 1024px) {
    font-size: 12px;
    font-weight: 500;
    line-height: 42px;
  }
`;
const BnSpan = styled.span`
  font-size: 20px;
  color: #ff4a4a;
  font-weight: bold;
  background: rgba(255, 116, 64, 0.1);
  box-shadow: 0px 0px 10px rgba(255, 116, 64, 0.1);
  border-radius: 6px;
  padding: 17px;

  @media all and (max-width: 1024px) {
    font-size: 14px;
    padding: 11px 12px;
  }
`;

const CardWrap = styled(FlexBox)`
  margin-top: 8px;
  display: flex;
  max-width: 526px;

  @media all and (max-width: 1060px) {
    flex-direction: column;
  }

  @media all and (max-width: 1024px) {
    margin-top: 16px;
    flex-direction: column;
    /* padding: 15px 10px; */
    gap: 16px;
  }
`;

const SubCardWrap = styled(FlexBox)`
  justify-content: left;
  gap: 3px;
  @media all and (max-width: 1060px) {
    flex-direction: column;
  }
`;
const CardBox = styled.div`
  /* width: calc((100% - 45px) / 4); */
  width: 173px;
  height: 34px;
  display: flex;
  align-items: center;
  padding: 5px 6px;

  &.total {
    background: rgba(255, 116, 64, 0.1);
    border-radius: 6px;
    align-items: center;
  }

  @media all and (max-width: 1060px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 0;
  }
`;
const CardLine = styled.div`
  width: 4px;
  height: 24px;
  border-radius: 4px;
  background: #ff7440;
  margin-right: 10px;

  &.total {
    background: #ff4a4a;
  }
`;
const WhiteBox = styled.div`
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 32px 40px;
  /* min-height: 600px; */

  @media all and (max-width: 1024px) {
    padding: 14px 16px;
    /* min-height: unset; */
  }
`;
const ViewAllSalesBtn = styled(Btn)`
  margin: 0 0 0 10px;
  width: 87px;
  height: 40px;
  /* padding: "10px 0 9px"; */
  font-size: 14px;
  line-height: unset;
  padding: unset;

  @media all and (max-width: 1024px) {
    margin: 0 0 0 8px;
    width: 58px;
    height: 36px;
    /* padding: 17px; */
    font-size: 12px;
    font-weight: bold;
  }

  @media all and (max-width: 285px) {
    width: 100%;
  }
`;

const PieTitBox = styled.div`
  width: 155px;
  height: 34px;
  display: flex;
  align-items: center;
  padding: 5px 6px;
  background: rgba(255, 116, 64, 0.1);
  border-radius: 6px;

  @media all and (max-width: 1024px) {
    width: 100%;
  }
`;

const PieTitLine = styled.div`
  width: 4px;
  height: 24px;
  border-radius: 4px;
  background: #ff4a4a;
  margin-right: 10px;
`;

const TotalSaleBtn = styled(Btn)`
  width: 100px;
  height: 40px;
  font-size: 14px;
  line-height: unset;
  padding: unset;

  @media all and (max-width: 1024px) {
    width: 84px;
    height: 36px;
    font-size: 12px;
    font-weight: bold;
  }
`;

const DateAndBtnBox = styled(FlexBox)`
  flex-wrap: wrap;
  gap: 10px;

  @media all and (max-width: 1024px) {
    width: 100%;
    justify-content: center;
  }
`;

const RadioAndBtn = styled(FlexBox)`
  justify-content: right;
  margin: 10px 0;

  @media all and (max-width: 285px) {
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin: 20px 0;
  }
`;

const MobileTableWrap = styled.div`
  overflow-y: auto;

  table {
    min-width: 643px;
  }
`;

const TableWhiteBox = styled(WhiteBox)`
  min-height: 540px;

  @media all and (max-width: 1060px) {
    min-height: unset;
  }

  &.responsive {
    min-height: 600px;

    @media all and (max-width: 1794px) {
      min-height: 615px;
    }
    @media all and (max-width: 1771px) {
      min-height: 633px;
    }
    @media all and (max-width: 1767px) {
      min-height: 670px;
    }
    @media all and (max-width: 1764px) {
      min-height: 687px;
    }
    @media all and (max-width: 1758px) {
      min-height: 700px;
    }
    @media all and (max-width: 1754px) {
      min-height: 720px;
    }
    @media all and (max-width: 1746px) {
      min-height: 740px;
    }
    @media all and (max-width: 1737px) {
      min-height: 760px;
    }
    @media all and (max-width: 1688px) {
      min-height: 776px;
    }
    @media all and (max-width: 1258px) {
      min-height: 796px;
    }
    @media all and (max-width: 1256px) {
      min-height: 810px;
    }
    @media all and (max-width: 1253px) {
      min-height: 830px;
    }
    @media all and (max-width: 1249px) {
      min-height: 850px;
    }
    @media all and (max-width: 1243px) {
      min-height: 866px;
    }
    @media all and (max-width: 1240px) {
      min-height: 884px;
    }
    @media all and (max-width: 1232px) {
      min-height: 920px;
    }
    @media all and (max-width: 1223px) {
      min-height: 940px;
    }
    @media all and (max-width: 1173px) {
      min-height: 976px;
    }
    @media all and (max-width: 1060px) {
      min-height: unset;
    }
  }
`;

export default Sales;
