import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { HeaderContainer, ContentContainer, Container, FlexBox, Text, Wrapper } from "../../Assets/css/commonVer2";
import img_profile from "../../Assets/images/img_profile.svg";
import img_profile_mo from "../../Assets/images/img_profile_mo.svg";
import ico_next from "../../Assets/images/ico_next_24.svg";
import Header from "../../Components/Header/HeaderVer2";
import API from "../../Utiles/API";
import { useMediaQuery } from "react-responsive";

// 마이페이지

function Mypage() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();
  const [Franchise_id] = useState(sessionStorage.getItem("user_id")); //user_idx
  const [franchiseName, setFranchiseName] = useState("");
  const [gubun] = useState(sessionStorage.getItem("gubun"));
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const Myinfo = async () => {
      try {
        const res = await API.get(`/storeowner/mypage/${Franchise_id}}`);
        if (res.data.status) {
          if (gubun == "admin") {
            setFranchiseName("관리자님");
          } else {
            setFranchiseName(res.data.result.franchise.franchise_name + " 점주님");
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    Myinfo();
  }, []);

  const logout = () => {
    sessionStorage.removeItem("user_id");
    sessionStorage.removeItem("franchise_id");
    sessionStorage.removeItem("gubun");
    localStorage.removeItem("user_id");
    localStorage.removeItem("franchise_id");
    localStorage.removeItem("gubun");
    navigate("/");
  };

  const moveToTerms = () => {
    navigate("terms");
  };

  const moveToInventory = () => {
    if (gubun == "storeonwer") {
      navigate("/product/inventory/storeowner", {
        state: {
          franchiseName: franchiseName?.slice(0, -4),
        },
      });
    } else if (gubun == "admin") {
      navigate("/product/inventory");
    }
  };

  const onClickToRestock = () => {
    if (gubun == "storeonwer") {
      navigate("/mypage/restock/storeowner", {});
    }
  };

  return (
    <>
      {isMobile && <Header title={"마이페이지"} />}
      <ContentContainer>
        <Wrapper type={1}>
          <Container>
            <ContWrap>
              <FlexBox justify={"start"} margin={"0 0 30px 0"} marginMd={"0 0 30px 20px"}>
                <img src={isMobile ? img_profile_mo : img_profile} width={isMobile ? "90px" : "120px"} />
                <FlexBox direction={"column"} align={"start"} margin={"5px 0 0 20px"} marginMd={"20px 0 0 20px"}>
                  <Text size={"14px"} sizeMd={"16px"} color={"#1E1E1E"} family={"Montserrat"}>
                    My page
                  </Text>
                  <Text size={"18px"} sizeMd={"26px"} color={"#1E1E1E"} weight={"700"} padding={"10px 0 0"}>
                    {franchiseName}
                  </Text>
                </FlexBox>
              </FlexBox>

              <FlexBox gap={"20px"} direction={"column"} paddingMd={"0 20px"}>
                <MypageBtn onClick={() => navigate("/mypage/alarm")}>
                  <Text size={"14px"} sizeMd={"18px"} color={"#1E1E1E"} lineSpacing={"-0.36px"}>
                    알림설정
                  </Text>
                  <img src={ico_next} />
                </MypageBtn>
                <MypageBtn onClick={() => navigate("/mypage/edit")}>
                  <Text size={"14px"} sizeMd={"18px"} color={"#1E1E1E"} lineSpacing={"-0.36px"}>
                    내 정보
                  </Text>
                  <img src={ico_next} />
                </MypageBtn>
                <MypageBtn onClick={() => navigate("/notice")}>
                  <Text size={"14px"} sizeMd={"18px"} color={"#1E1E1E"} lineSpacing={"-0.36px"}>
                    공지사항
                  </Text>
                  <img src={ico_next} />
                </MypageBtn>

                {gubun === "admin" ? (
                  <MypageBtn onClick={() => navigate(`/product/inventory/view`)}>
                    <Text size={"14px"} sizeMd={"18px"} color={"#1E1E1E"} lineSpacing={"-0.36px"}>
                      재고관리 내역
                    </Text>
                    <img src={ico_next} />
                  </MypageBtn>
                ) : (
                  <>
                    <MypageBtn onClick={moveToInventory}>
                      <Text size={"14px"} sizeMd={"18px"} color={"#1E1E1E"} lineSpacing={"-0.36px"}>
                        재고관리
                      </Text>
                      <img src={ico_next} />
                    </MypageBtn>
                    <MypageBtn onClick={onClickToRestock}>
                      <Text size={"14px"} sizeMd={"18px"} color={"#1E1E1E"} lineSpacing={"-0.36px"}>
                        재입고 알림 내역
                      </Text>
                      <img src={ico_next} />
                    </MypageBtn>
                  </>
                )}
                <MypageBtn onClick={moveToTerms}>
                  <Text size={"14px"} sizeMd={"18px"} color={"#1E1E1E"} lineSpacing={"-0.36px"}>
                    약관보기
                  </Text>
                  <img src={ico_next} />
                </MypageBtn>
                <MypageBtn onClick={logout}>
                  <Text size={"14px"} sizeMd={"18px"} color={"#1E1E1E"} lineSpacing={"-0.36px"}>
                    로그아웃
                  </Text>
                  <img src={ico_next} />
                </MypageBtn>
              </FlexBox>
            </ContWrap>
          </Container>
          {modal ? (
            <ModalBg>
              <FindBox>
                <Text color={"#2D2D2D"} size={"16px"} weight={"700"} style={{ paddingBottom: "20px" }}>
                  PC버전에서 해주세요!
                </Text>
                <FlexBox justify={"center"} style={{ marginTop: "20px" }}>
                  <CancleBtn>
                    <Text color={"#FFFFFF"} size={"18px"} weight={"700"} onClick={() => setModal(false)}>
                      확 인
                    </Text>
                  </CancleBtn>
                </FlexBox>
              </FindBox>
            </ModalBg>
          ) : (
            ""
          )}
        </Wrapper>
      </ContentContainer>
    </>
  );
}

const ContWrap = styled.div`
  max-width: 558px;
  margin: 0 auto;
  padding: 40px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
  text-align: center;

  @media all and (max-width: 1024px) {
    box-shadow: unset;
    border: unset;
    width: 100%;
    max-width: unset;
    padding: 0;
    background-color: unset;
    margin: 0 auto;
  }
`;
const ModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FindBox = styled.div`
  background-color: #fff;
  max-width: 380px;
  max-height: 600px;
  width: 90%;
  padding: 60px 40px 40px;
  border-radius: 8px;
  position: relative;
`;
const Cancel = styled.img``;
const CancleBtn = styled.button`
  background-color: #dd1212;
  height: 40px;
  width: 84px;
  left: 0px;
  top: 0px;
  border-radius: 8px;
  outline: none;
  border: 1px solid #dd1212;
  margin: 7px;
`;
const CheckBtn = styled.button`
  background-color: #c0c0c0;
  height: 40px;
  width: 84px;
  left: 0px;
  top: 0px;
  border-radius: 8px;
  // width: 30%;
  // height: 40px;
  // border-radius: 8px;
  outline: none;
  border: 1px solid #c0c0c0;
  margin: 7px;
`;

const WrapperYellow = styled(Wrapper)`
  padding-top: ${(props) => (props.type === 1 ? "160px" : "210px")};
  min-height: 100vh;
  background-color: #fffae6;

  @media all and (max-width: 1024px) {
    padding-top: 146px;
    background-color: transparent;
  }
`;

const MypageBtn = styled(FlexBox)`
  border-radius: 6px;
  justify-content: space-between;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;

  @media all and (max-width: 1024px) {
  }
`;
export default Mypage;
