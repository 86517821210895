import React, {useEffect, useState} from "react";
import axios from "axios";
import { FlexBox, Title } from "../../Assets/css/commonVer2";
import InstaSlider from "../../Components/Slider/InstaSlider";
import SliderBtn from "../../Components/Slider/SliderBtn";

// 인스타그램

function HomeInsta() {
  const [media, setMedia] = useState([]);
 
  const fetchMedia = async () => {
    const response = await axios.get(`https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink&access_token=${process.env.REACT_APP_INSTA_ACCESS_TOKEN}`);
    setMedia(response.data.data);
  }

  useEffect(() => {
    fetchMedia();
  },[])

    return (
    <>
      {/* 모바일 */}
      <div className="d-block d-md-none" style={{marginTop: "20px"}}>
        <FlexBox justify={"space-between"}>
          <Title
            size={"18px"}
            color={"#1E1E1E"}
            weight={"bold"}
          >
            문구는 못말려 인스타그램
          </Title>
          <SliderBtn prevClass={"instaPrev"} nextClass={"instaNext"} />
        </FlexBox>
        <div 
          style={{marginTop: "14px"}}
        >
          <InstaSlider
            perview={2}
            space={8}
            naviPrev={"instaPrev"}
            naviNext={"instaNext"}
            contents={media}
            styles={{ border: true }}
          />
        </div>
      </div>
      {/* 웹 */}
      <div className="d-none d-md-block" style={{marginTop: "30px"}}>
        <FlexBox justify={"space-between"}>
          <Title
            size={"26px"}
            color={"#1E1E1E"}
            weight={"bold"}
          >
            문구는 못말려 인스타그램
          </Title>
          <SliderBtn prevClass={"sellPrev"} nextClass={"sellNext"} />
        </FlexBox>
        <div 
          style={{marginTop: "14px"}}
        >
          <InstaSlider
            perview={4}
            space={26}
            naviPrev={"sellPrev"}
            naviNext={"sellNext"}
            contents={media}
            styles={{ border: true }}
          />
        </div>
      </div>
    </>
  );
}

export default HomeInsta;