import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { dateToString } from '../../Utiles/dateToString'
import { getOrderProducts, getProducts } from '../../Utiles'
import { Link } from 'react-router-dom'

import { AddComma } from "../../Utiles/Custom";
import Authority from "../../Components/Modal/Authority";
import { useNavigate } from "react-router-dom";
import API from '../../Utiles/API'
import Header from '../../Components/Header/HeaderVer2'
import {
  Btn,
  Container,
  FlexBox,
  Text,
  Title,
  Wrapper,
  ContentContainer,
} from '../../Assets/css/commonVer2'
import { useMediaQuery } from 'react-responsive'
import InfoSlider from '../../Components/Slider/InfoSlider'
import HistorySlider from '../../Components/Slider/HistorySlider';
/* code from sub_outsourcing */

//상품마스터 > 대쉬보드

export default function DashBoard() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();
  
  const date = new Date();
  const year = date.getFullYear(); // 년
  const month = date.getMonth(); // 월
  const day = date.getDate(); // 일

  // 주문 상품 리스트
  const [orderList, setOrderList] = useState([]);

  // 반품 상품 리스트
  const [takeBackList, setTakeBackList] = useState([]);

  // 재입고 알림 상품
  const [restockData, setRestockData] = useState([]);

  // API - 재입고 알림 내역 조회
  const getRestockList = async () => {
    await API.get(`/admin/restock?page=1&unit=8&block=10`)
      .then((res) => {
        if (res.data.status) {
          setRestockData(res.data.results);
        }
      })
  };

  function onClickItem(franchise_id, order_date) {
    navigate(`/product/order/management/${order_date}`, {
      state: {
        order_date: order_date,
        franchise_id: franchise_id,
      },
    });
  }

  // 주문 검색 객체
  const orderSearchState = {
    // franchise_id : 1, // 가맹점 아이디, 반품 번호 필드가 없으면 전체 제품이 검색된다
    start_date: dateToString(new Date(year, month, day - 1)) + ' 00:00:00',
    end_date: dateToString(new Date(year, month, day - 1)) + ' 23:59:59',
    page: 1,
    unit: 8,
    block: 10,
    search: true
  }

  // 반품 검색 객체
  const takeBackSearchState = {
    // franchise_id : 1, // 가맹점 아이디, 반품 번호 필드가 없으면 전체 제품이 검색된다
    start_date: dateToString(new Date()) + ' 00:00:00',
    end_date: dateToString(new Date()) + ' 23:59:59',
    page: 1,
    unit: 8,
    block: 10,
    search: true
  }

  // 모바일 슬라이더 묶음 - 재입고 알림데이터
  const groupedRestockData = restockData.reduce((acc, cur, idx) => {
    if (idx % 2 === 0) {
      acc.push([cur]);
    } else {
      acc[acc.length - 1].push(cur);
    }
    return acc;
  }, []);

  // 모바일 슬라이더 묶음 - 재입고 알림데이터
  const groupedOrderListData = orderList.reduce((acc, cur, idx) => {
    if (idx % 2 === 0) {
      acc.push([cur]);
    } else {
      acc[acc.length - 1].push(cur);
    }
    return acc;
  }, []);

  // 모바일 슬라이더 묶음 - 재입고 알림데이터
  const groupedTakeBackData = takeBackList.reduce((acc, cur, idx) => {
    if (idx % 2 === 0) {
      acc.push([cur]);
    } else {
      acc[acc.length - 1].push(cur);
    }
    return acc;
  }, []);

  useEffect(() => {
    getOrderProducts(orderSearchState).then(res => {
      if (res.status) {
        setOrderList(res.results.rowsArr);
      }
    }).catch(res => {
      console.log(res);
    })

    getProducts(takeBackSearchState).then(res => {
      if (res.status) setTakeBackList(res.results);
    }).catch(res => {
      console.log(res);
    })
    // setOrderList(temp);
    // setTakeBackList(temp);
    getRestockList();
  }, []);

  return (
    <>
      {sessionStorage.getItem('gubun') !== 'admin' ?
        <Authority title={'관리자'} />
        :
        <>
          {isMobile && <Header title={"상품마스터"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                       대쉬보드 📋
                  </Title>
                )}
                {/* 금일 주문내역 */}

                <FlexBox justify={"space-between"} style={{ marginBottom: "10px" }}>
                  <Title
                    size={"16px"}
                    sizeMd={"20px"}
                    color={"#666"}
                    weight={"700"}
                  >
                    최근 주문내역
                  </Title>
                  {!isMobile && (
                    <Link
                      style={{ marginLeft: "auto" }}
                      to={`/product/order`} >
                      <Btn
                        color={"#fff"}
                        background={"#FF4A4A"}
                        size={"14px"}
                        weight={"700"}
                        width={"100%"}
                        widthMd={"unset"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                      >
                        전체보기
                      </Btn>
                    </Link>
                  )}

                </FlexBox>
                <FlexBox justify={"flex-start"} wrap={"wrap"} gap={"12px"}>
                  {!isMobile && (
                    orderList?.length > 0 ? (
                      orderList?.map((e, i) => (
                        <ListItem key={i}
                          onClick={() => onClickItem(e.franchise_id, e.order_date)} 
                        >
                          <Text
                            weight={"500"}
                            color={"#1E1E1E"}
                            align={"left"}
                            size={"16px"}
                            margin={"0 0 12px"}
                          >
                            {e.franchise}
                          </Text>
                          <FlexBox
                            justify={"space-between"}
                            padding={"0 8px"}
                          >
                            <Text size={"16px"} color={"#1E1E1E"}>
                              품목 수량 :
                            </Text>
                            <Text size={"16px"} color={"#1E1E1E"}>
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "700",
                                }}>{e.count}</span>개
                            </Text>
                          </FlexBox>
                          <FlexBox
                            justify={"space-between"}
                            margin={"8px 0 0"}
                            padding={"0 8px"}
                          >
                            <Text size={"16px"} color={"#1E1E1E"}>
                              결제 금액 :
                            </Text>
                            <Text size={"16px"} color={"#1E1E1E"}>
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "700",
                                }}> {AddComma(e.totalPrice)}</span>원
                            </Text>
                          </FlexBox>
                        </ListItem>)
                      )) : (
                      <Text
                        size={"14px"}
                        color={"#999"}
                        weight={"500"}
                        width={"100%"}
                        padding={"10px 0"}
                      >
                        전일 주문내역이 없습니다.
                      </Text>
                    )
                  )}
                </FlexBox>
                {isMobile && (
                    groupedOrderListData?.length > 0 ? (
                      <HistorySlider
                        contents={groupedOrderListData}
                        slidesPerView={groupedOrderListData.length}
                      />
                    ) : (
                      <Text
                        size={"14px"}
                        color={"#999"}
                        weight={"500"}
                        width={"100%"}
                        padding={"10px 0"}
                      >
                        전일 주문내역이 없습니다.
                      </Text>
                    )
                  )}

                {isMobile && (
                  <Link
                    style={{ width: "100%" }}
                    to={`/product/order`} >
                    <Btn
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      width={"100%"}
                      widthMd={"unset"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      margin={"12px 0 0"}
                    >
                      전체보기
                    </Btn>
                  </Link>
                )}

                {/* 금일 반품 내역 */}

                <FlexBox
                  justify={"space-between"}
                  margin={"20px 0 10px"}
                  marginMd={"30px 0 10px"}
                >
                  <Title
                    size={"16px"}
                    sizeMd={"20px"}
                    color={"#666"}
                    weight={"700"}
                  >
                    금일 반품내역
                  </Title>
                  {!isMobile && (
                    <Link
                      style={{ marginLeft: "auto" }}
                      to={`/product/takeback`} >
                      <Btn
                        color={"#fff"}
                        background={"#FF4A4A"}
                        size={"14px"}
                        weight={"700"}
                        width={"100%"}
                        widthMd={"unset"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                      >
                        전체보기
                      </Btn>
                    </Link>
                  )}

                </FlexBox>
                {!isMobile && (
                  <FlexBox justify={"flex-start"} wrap={"wrap"} gap={"12px"}>
                    {takeBackList?.length > 0 ? (
                      takeBackList?.map((e, i) => (
                        <ListItem key={i}>
                          <Link
                            key={e.take_back_id}
                            style={{
                              textDecoration: "none"
                            }}
                            to={`/product/takeback/${e.take_back_id}`} >
                            <Text
                              weight={"500"}
                              color={"#1E1E1E"}
                              align={"left"}
                              size={"16px"}
                              margin={"0 0 12px"}
                            >
                              {e.franchise_name}
                            </Text>
                            <FlexBox
                              justify={"space-between"}
                              padding={"0 8px"}
                            >
                              <Text size={"16px"} color={"#1E1E1E"}>
                                품목 수량 :
                              </Text>
                              <Text size={"16px"} color={"#2d2d2d"}>
                                <span
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "700",
                                  }}>{e.take_back_count}</span>개
                              </Text>
                            </FlexBox>
                            <FlexBox
                              justify={"space-between"}
                              margin={"8px 0 0"}
                              padding={"0 8px"}
                            >
                              <Text size={"16px"} color={"#1E1E1E"}>
                                반픔요청 금액 :
                              </Text>
                              <Text size={"16px"} color={"#1E1E1E"}>
                                <span
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "700",
                                  }}> {AddComma(e.amount)}</span>원
                              </Text>
                            </FlexBox>
                          </Link>
                        </ListItem>)
                      )) : (
                      <Text
                        size={"14px"}
                        color={"#999"}
                        weight={"500"}
                        width={"100%"}
                        padding={"10px 0"}
                      >
                        현재 반품내역이 없습니다.
                      </Text>
                    )}
                  </FlexBox>
                )}
                {isMobile && (
                  groupedTakeBackData?.length > 0 ? (
                    <HistorySlider
                      contents={groupedTakeBackData}
                      slidesPerView={groupedTakeBackData.length}
                      mode={"takeBack"}
                    />
                  ) : (
                    <Text
                      size={"14px"}
                      color={"#999"}
                      weight={"500"}
                      width={"100%"}
                      padding={"10px 0"}
                    >
                      현재 반품내역이 없습니다.
                    </Text>
                  )
                )}
                {isMobile && (
                  <Link
                    style={{ width: "100%" }}
                    to={`/product/takeback`} >
                    <Btn
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      width={"100%"}
                      widthMd={"unset"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      margin={"12px 0 0"}
                    >
                      전체보기
                    </Btn>
                  </Link>
                )}

                {/* 재입고 알림 신청 상품 */}
                <FlexBox
                  justify={"space-between"}
                  margin={"20px 0 10px"}
                  marginMd={"30px 0 10px"}
                >
                  <Title
                    size={"16px"}
                    sizeMd={"20px"}
                    color={"#666"}
                    weight={"700"}
                  >
                    재입고 알림 상품 순위
                  </Title>
                  {!isMobile && (
                    <Link
                      style={{ marginLeft: "auto" }}
                      to={`/product/restock`} >

                      <Btn
                        color={"#fff"}
                        background={"#FF4A4A"}
                        size={"14px"}
                        weight={"700"}
                        width={"100%"}
                        widthMd={"unset"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                      >
                        전체보기
                      </Btn>
                    </Link>
                  )}
                </FlexBox>
                {!isMobile && (
                  <FlexBox justify={"flex-start"} wrap={"wrap"} gap={"12px"}>
                    {restockData?.length > 0 ? (
                      restockData?.map((e, i) => (
                        <ListItem key={i}>
                          <Text
                            weight={"500"}
                            color={"#1E1E1E"}
                            size={"16px"}
                            align={"left"}
                            padding={"0 0 2px 0"}
                            paddingMd={"0 0 16px 0"}
                          >
                            <span
                              style={{ color: "#ff4a4a", paddingRight: "5px" }}
                            >
                              {i + 1}.
                            </span>
                            {e.product_name}
                          </Text>
                          <FlexBox
                            justify={"space-between"}
                          >
                            <Text size={"16px"} color={"#1E1E1E"}>
                              신청건수:{" "}
                            </Text>
                            <Text size={"16px"} color={"#1E1E1E"}>
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "700",
                                }}>{e.count}</span>건
                            </Text>
                          </FlexBox>
                          {/* FIXME: 상품 금액 보여줄 필요 없으면 삭제  */}
                          {/* <Text size={"12px"} color={"#2d2d2d"}>
                      공급가: {AddComma(e.shipping_price)}원
                    </Text> */}
                          {/* <Text size={"12px"} color={"#2d2d2d"}>
                      소비자가: {AddComma(e.consumer_price)}원
                    </Text> */}
                        </ListItem>
                      ))
                    ) : (
                      <Text
                        size={"14px"}
                        color={"#999"}
                        weight={"500"}
                        width={"100%"}
                        padding={"10px 0"}
                      >
                        현재 재입고 알림 신청 상품이 없습니다.
                      </Text>
                    )}
                  </FlexBox>
                )}
                {isMobile && (
                  restockData.length > 0 ? (
                    <InfoSlider
                      lists={groupedRestockData}
                      slidesPerView={groupedRestockData.length}
                    />
                  ) : (
                    <Text
                      size={"14px"}
                      color={"#999"}
                      weight={"500"}
                      width={"100%"}
                      padding={"10px 0"}
                    >
                      현재 알림신청 상품이 없습니다.
                    </Text>
                  )
                )}

                {isMobile && (
                  <Link
                    style={{ width: "100%" }}
                    to={`/product/restock`} >

                    <Btn
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      width={"100%"}
                      widthMd={"unset"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      margin={"12px 0 0"}
                    >
                      전체보기
                    </Btn>
                  </Link>
                )}
              </Container>
            </Wrapper>
          </ContentContainer>
        </>
      }
    </>
  )
}

const ListItem = styled.div`
  width: calc(25% - 9px);
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  @media all and (max-width: 1200px) {
    width: calc(50% - 6px);
  }
  @media all and (max-width: 1024px) {
    width: 100%;
    padding: 12px 10px;
  }
`;

export const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  gap: 0.7rem;
  border: 1px solid #EAEAEA;
  border-radius:  8px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  background: white;
  width: 266px;
  height: 110px;


  // 모바일 디자인
  @media screen and (max-width: 615px) {
        width: 100%;
    }

`