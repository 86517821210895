// 외부 모듈
import React, { useEffect, useState, useReducer } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { utils, writeFile } from "xlsx";

// 내부 모듈
import {
  Btn,
  Container,
  FlexBox,
  Wrapper,
  Th,
  Td,
  CheckLabel,
  CheckIcon,
  Text,
  TableWrap,
  DateInputBox,
  Title,
  WhiteBox,
  ContentContainer,
} from "../../Assets/css/commonVer2";

import "../../Assets/css/DatePicker.css";
import Header from "../../Components/Header/HeaderVer2";
import Authority from "../../Components/Modal/Authority";
import Pagination from "../../Components/Paging/Pagination";
import HandleBack from "../../Components/HandleBack";
import { SearchOrderReducer } from "../../Store";
import API from "../../Utiles/API";
import { AddComma } from "../../Utiles/Custom";
import { dateToString } from "../../Utiles/dateToString";
import { delayCall } from "../../Utiles/delayCall";
import { getOrderProductsView, getOrderProductsExcel } from "../../Utiles";
import { searchToObject } from "../../Utiles/searchToObject";
import InfoModal from "../../Components/Modal/InfoModal";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import FranSelect from "../../Components/Select/FranSelect";
import BasicSelect from "../../Components/Select/BasicSelect";
import { UNIT_10_TO_50_LIST } from "../../Utiles/ListData";

/* code from sub_outsourcing */

// 상품마스터 > 주문내역조회

export default function ProductOrder() {
  const navigate = useNavigate();
  //url query 정보를 받아오기 위함
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const { handlePopstate } = HandleBack();

  const date = new Date();
  const year = date.getFullYear(); // 년
  const month = date.getMonth(); // 월
  const day = date.getDate(); // 일
  // 날짜 제어 state
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [confirmExcelModal, setConfirmExcelModal] = useState(false);
  const [checkItems, setCheckItems] = useState([]); // 체크된 아이템의 idx
  const [unit, setUnit] = useState(10);

  const [franText, setFranText] = useState("가맹점 전체");
  const [franShow, setFranShow] = useState(false);
  const [franchiseId, setFranchiseId] = useState("");

  // 가맹점 리스트 결과
  const [franResult, setFranResult] = useState([]);

  // 페이징에 사용할 스테이트
  const [pagination, setPagination] = useState("");

  // 주문 상품 리스트
  const [orderList, setOrderList] = useState([]);

  // 총 금액
  const [totalAmount, setTotalAmount] = useState();

  // 가맹점 검색 결과를 돌려 받을 state
  const [searchFranchise, setSearchFranchise] = useState({});

  // 주문 상세를 다녀온 경우 저장 해놓은 파라미터 정보
  const parsedProductOrderPageState = sessionStorage.getItem("productOrderPageState")
    ? JSON.parse(sessionStorage.getItem("productOrderPageState"))
    : {};

  // 검색 객체
  const [searchState, dispatchSearchState] = useReducer(SearchOrderReducer, {
    // franchise_id : 1, // 가맹점 아이디, 주문 번호 필드가 없으면 전체 제품이 검색된다
    start_date: dateToString(new Date(year, month, day - 7)) + " 00:00:00",
    end_date: dateToString(new Date()) + " 23:59:59",
    page: 1,
    unit: parsedProductOrderPageState
      ? parsedProductOrderPageState?.unit
        ? parsedProductOrderPageState?.unit
        : 10
      : 10,
    block: 10,
    search: true,
    ...searchToObject(),
  });

  // 주문 상세를 갈 때 sessionStorage에 저장할 객체
  const productOrderPageState = {
    unit: searchState.unit,
    page: searchState.page,
  };

  // 검색 버튼
  function searchOrder() {
    // 가맹점 이름으로 찾기
    if (/* searchFranchise[0]?.idx */ franchiseId) {
      // 가맹점 id로만 찾기
      dispatchSearchState({
        type: "SEARCH_BY_FEANCHISE_ID",
        // value: searchFranchise[0]?.idx,
        value: franchiseId,
      });
      dispatchSearchState({ type: "CHANGE_NOW_PAGE", value: 1 });
    } else if (/* !searchFranchise[0]?.idx */ !franchiseId) {
      // 가맹점 id가 없을 때는 url에서 값을 없애줘야하기 때문에 state에서 미리 삭제시켜준다.
      dispatchSearchState({ type: "DELETE_IDS" });
    }
    dispatchSearchState({ type: "CHANGE_SEARCH", value: true });
    dispatchSearchState({ type: "CHANGE_NOW_PAGE", value: 1 });
    dispatchSearchState({
      type: "CHANGE_UNIT",
      value: parsedProductOrderPageState
        ? parsedProductOrderPageState?.unit
          ? parsedProductOrderPageState?.unit
          : searchState.unit
        : searchState.unit,
    });
  }

  // 주문 등록한 상품 정보
  function callGetProducts() {
    // 검색 객체는 자동으로 등록이 되지만 보여주기 위한 날짜 객체를 넣는다.
    // 날짜 선택 창에 검색 객체랑 동일한 날짜를 넣어준다.
    // console.log(searchState, '??')
    setStartDate(new Date(searchState.start_date));
    setEndDate(new Date(searchState.end_date));

    getOrderProductsView(searchState)
      .then((res) => {
        if (res.status) {
          // console.log(res)
          setPagination(res.pagination);
          setOrderList(res.results.rowsArr);
          setTotalAmount(res.results.entire_price);
          dispatchSearchState({ type: "CHANGE_SEARCH", value: false });
        }
      })
      .catch((res) => {
        dispatchSearchState({ type: "CHANGE_SEARCH", value: false });
      });
  }

  // 주문조회 상세로 이동
  function onClickItem(e, franchise, order_date) {
    let franchise_id;
    for (let i = 0; i < franResult.length; i++) {
      if (franchise === franResult[i].franchise_name) {
        franchise_id = franResult[i].idx;
      }
    }
    navigate(`/product/order/${order_date}`, {
      state: {
        order_date: order_date,
        franchise_id: franchise_id,
      },
    });
    let jsonString = JSON.stringify(productOrderPageState);
    sessionStorage.setItem("productOrderPageState", jsonString);
  }

  // 페이지 이동 시 작동
  function movePage(e) {
    dispatchSearchState({
      type: "CHANGE_NOW_PAGE",
      value: e,
    });
    // setIsSearch(true)
    // setIsTableSearch(true)
  }

  // 아이템 체크
  const handleChk = (checked, franchise_id, order_date) => {
    if (checked) {
      setCheckItems((prev) => [...prev, { franchise_id, order_date }]);
    } else {
      setCheckItems(checkItems.filter((el) => el.franchise_id !== franchise_id || el.order_date !== order_date));
    }
  };

  // 아이템 전체 체크
  const handleAllChk = (checked) => {
    if (checked) {
      const chkArr = orderList.map((item) => ({
        franchise_id: item.franchise_id,
        order_date: item.order_date,
      }));
      setCheckItems(chkArr);
    } else {
      setCheckItems([]);
    }
  };

  // 가맹점명으로 가맹점 아이디 찾기
  const getsearch_F = async () => {
    await API.get(`/admin/search-franchise`).then((res) => {
      if (res.data.status) {
        setFranResult(res.data.result);
      }
    });
  };

  function onClickExcelDown() {
    if (checkItems.length > 0) {
      ExportHandler();
    } else {
      setConfirmExcelModal(true);
    }
  }

  function ExportHandler() {
    if (orderList.length > 0) {
      getOrderProductsExcel(searchState.start_date, searchState.end_date, checkItems).then((res) => {
        if (res.data.status && res.data.results.length > 0) {
          const data = [];
          const excelTableHead = [
            ["No", "주문일", "주문번호", "가맹점", "주문상태", "상품명", "바코드", "수량", "공급가", "총금액"],
          ];
          // excel 문서 만들기
          const wb = utils.book_new();
          // 열 순서를 sheet화
          const ws = utils.json_to_sheet([]);
          // ws에 excelTableHead를 추가
          utils.sheet_add_aoa(ws, excelTableHead);

          res.data.results.forEach((item, idx) => {
            data.push({
              row_num: `${idx + 1}`,
              orderDate: `${item.order_date}`,
              orderId: `${item.order_id}`,
              franchise_name: `${item.franchise_name}`,
              status: `${
                item.status == "before-delivery"
                  ? "배송전"
                  : item.status == "on-delivery"
                    ? "배송중"
                    : item.status == "over-delivery"
                      ? "배송완료"
                      : item.status == "cancel-order"
                        ? "주문취소"
                        : ""
              }`,
              p_name: `${item.p_name}`,
              p_barcode_num: `${item.p_barcode_num}`,
              quantity: `${item.quantity}`,
              p_shipping_price: `${item.p_shipping_price}`,
              amount: `${item.amount}`,
            });
          });

          utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
          utils.book_append_sheet(wb, ws, "Report");
          // 두 번째 arg에는 export 될 파일의 이름을 넣어주면 된다.
          writeFile(wb, `주문내역_${dateToString(startDate)}~${dateToString(endDate)}.xlsx`);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      });
    } else {
      setTextMsg("다운받을 데이터가 없습니다.");
      setTextModal(true);
    }
  }

  // 1. searchState를 searchBar에서도 변경하기 때문에 초기에 두번 작동된다.
  // 2. 검색버튼을 여러번 누를때를 대비해서 방지 가능
  useEffect(() => {
    delayCall(callGetProducts);
  }, [location]);

  useEffect(() => {
    if (searchState.search) {
      const params = {};
      Object.entries(searchState).forEach((item) => (params[item[0]] = item[1]));
      navigate(
        {
          pathname: "/product/order",
          search: `?${createSearchParams(params)}`,
        },
        { replace: true },
      );
    }
  }, [searchState]);

  useEffect(() => {
    dispatchSearchState({
      type: "CHANGE_UNIT",
      value: unit,
    });
  }, [unit]);

  useEffect(() => {
    sessionStorage.removeItem("productOrderPageState");
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    getsearch_F();
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {isMobile && <Header title={"주문내역 조회"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    주문내역 조회 📋
                  </Title>
                )}
                <WhiteBox margin={"0 0"}>
                  <FlexBox wrap={"wrap"} justify={"end"} gap={"10px"}>
                    <FlexBox gap={"10px"} flexMd={"unset"} flex={"1"}>
                      <DateInputBox>
                        <DatePicker
                          dateFormatCalendar="yyyy년 MM월"
                          dateFormat="yyyy-MM-dd"
                          selected={startDate}
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={endDate}
                          onChange={(date) => {
                            setStartDate(date);
                            dispatchSearchState({
                              type: "CHANGE_START_DATE",
                              value: dateToString(date) + " 00:00:00",
                            });
                          }}
                        />
                      </DateInputBox>
                      <DateInputBox>
                        <DatePicker
                          dateFormatCalendar="yyyy년 MM월"
                          dateFormat="yyyy-MM-dd"
                          selected={endDate}
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          onChange={(date) => {
                            setEndDate(date);
                            dispatchSearchState({
                              type: "CHANGE_END_DATE",
                              value: dateToString(date) + " 23:59:59",
                            });
                          }}
                        />
                      </DateInputBox>
                    </FlexBox>

                    <FranSelect
                      widthMd={"100%"}
                      gubun={"fran"}
                      selectBtnText={franText}
                      setTextChange={setFranText}
                      placeholder={"가맹점을"}
                      show={franShow}
                      setIdxChange={setFranchiseId}
                      setFranShow={setFranShow}
                    />
                    <Btn
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      width={"100%"}
                      widthMd={"unset"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      onClick={searchOrder}
                      style={{ flexShrink: 0 }}
                    >
                      검 색
                    </Btn>
                  </FlexBox>
                  <FlexBox
                    justify={"space-between"}
                    gap={"10px"}
                    margin={"20px 0 10px"}
                    marginMd={"20px 0 30px"}
                    wrap={"wrap"}
                    wrapMd={"no-wrap"}
                  >
                    <FlexBox gap={"10px"} width={"100%"} widthMd={"auto"}>
                      <Text
                        width={"100%"}
                        widthMd={"unset"}
                        color={"#FF4A4A"}
                        size={"16px"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        style={{
                          flexShrink: 0,
                        }}
                      >
                        금액 합계 :{" "}
                        <span style={{ fontWeight: "700" }}>{AddComma(totalAmount ? totalAmount : "")}원</span>
                      </Text>
                      {!isMobile && (
                        <BasicSelect
                          width={"130px"}
                          data={UNIT_10_TO_50_LIST}
                          selectedValue={unit}
                          setSelectedValue={setUnit}
                          canSelectAll={false}
                        />
                      )}
                    </FlexBox>

                    <FlexBox gap={"10px"} justify={"flex-end"} width={"100%"} widthMd={"auto"}>
                      {!isMobile && (
                        <Btn
                          size={"14px"}
                          padding={"8px 18px"}
                          border={"1px solid #666"}
                          color={"#666"}
                          hoverBackground={"#C7C7C7"}
                          hoverColor={"#fff"}
                          hoverBorder={"transparent"}
                          lineHeight={"22px"}
                          hoverWeight={"700"}
                          margin={"0 0 0 10px"}
                          onClick={onClickExcelDown}
                        >
                          엑셀 다운
                        </Btn>
                      )}
                    </FlexBox>
                  </FlexBox>

                  <TableWrap>
                    <table style={{ width: "100%", minWidth: "1024px" }}>
                      <colgroup>
                        <col style={{ width: "8%" }} />
                        <col style={{ width: "15.5%" }} />
                        {/* <col style={{ width: "10.5%" }} /> */}
                        <col style={{ width: "21%" }} />
                        <col style={{ width: "10.5%" }} />
                        <col style={{ width: "15.5%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th className="d-none d-md-table-cell">
                            <CheckLabel htmlFor="chkAll" style={{ justifyContent: "center" }}>
                              <input
                                type="checkbox"
                                id="chkAll"
                                onChange={(event) => handleAllChk(event.target.checked)}
                                checked={checkItems.length === orderList.length ? true : false}
                              />
                              <CheckIcon>
                                <svg viewBox="0 0 24 24">
                                  <polyline points="19 7 10 17 5 12" />
                                </svg>
                              </CheckIcon>
                            </CheckLabel>
                          </Th>
                          <Th>주문일</Th>
                          <Th>주문번호</Th>
                          <Th>가맹점</Th>
                          <Th>품목 수량</Th>
                          <Th>총 금액</Th>
                          <Th>상 태</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderList.length > 0 ? (
                          orderList?.map((item, key) => (
                            <tr key={key} style={{ cursor: "pointer" }}>
                              <Td className="d-none d-md-table-cell" style={{ borderBottom: "1px solid #F2F2F2" }}>
                                <CheckLabel
                                  // htmlFor={`prod${item.order_date}`}
                                  style={{ justifyContent: "center" }}
                                >
                                  <input
                                    type="checkbox"
                                    name="prod"
                                    // id={`prod${item.order_date}`}
                                    onChange={(event) =>
                                      handleChk(event.target.checked, item.franchise_id, item.order_date)
                                    }
                                    checked={checkItems.some(
                                      (el) =>
                                        el.franchise_id === item.franchise_id && el.order_date === item.order_date,
                                    )}
                                    readOnly
                                  />
                                  <CheckIcon>
                                    <svg viewBox="0 0 24 24">
                                      <polyline points="19 7 10 17 5 12" />
                                    </svg>
                                  </CheckIcon>
                                </CheckLabel>
                              </Td>
                              <Td onClick={(e) => onClickItem(e, item.franchise, item.order_date)}>
                                {item.order_date}
                              </Td>
                              <Td onClick={(e) => onClickItem(e, item.franchise, item.order_date)}>
                                <Text size="12px" sizeMd="16px">
                                  {item.order_id}
                                </Text>
                              </Td>
                              <Td onClick={(e) => onClickItem(e, item.franchise, item.order_date)}>{item.franchise}</Td>
                              <Td onClick={(e) => onClickItem(e, item.franchise, item.order_date)}>{item.count}</Td>
                              <Td onClick={(e) => onClickItem(e, item.franchise, item.order_date)}>
                                {AddComma(item.totalPrice)}원
                              </Td>
                              <Td onClick={(e) => onClickItem(e, item.franchise, item.order_date)}>
                                {item.status == "before-delivery"
                                  ? "배송전"
                                  : item.status == "on-delivery"
                                    ? "배송중"
                                    : item.status == "over-delivery"
                                      ? "배송완료"
                                      : item.status == "cancel-order"
                                        ? "주문취소"
                                        : ""}
                              </Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              className="d-md-none"
                              colSpan={6}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                            <td
                              className="d-none d-md-table-cell"
                              colSpan={7}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>

                  {pagination?.total_page > 0 ? (
                    <Pagination
                      pagination={pagination}
                      movePage={movePage}
                      currentPage={
                        parsedProductOrderPageState
                          ? parsedProductOrderPageState?.page
                            ? parsedProductOrderPageState?.page
                            : searchState.page
                          : searchState.page
                      }
                    />
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <ConfirmModal
                modal={confirmExcelModal}
                setModal={setConfirmExcelModal}
                onSubmit={() => {
                  setConfirmExcelModal(false);
                  ExportHandler();
                }}
                description={`해당 기간내 주문내역의 전체를\n다운받으시겠습니까?`}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}
