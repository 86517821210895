// 외부 모듈
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { Navigation, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// 내부 모듈
import { Text } from "../../Assets/css/commonVer2";
import img_noimage470 from "../../Assets/images/no_image_470px.jpg";
import img_noimage from "../../Assets/images/no_image.jpg";

function ProductSlider(props) {
  const navigate = useNavigate();
  const { space, perview, naviPrev, naviNext, contents, styles, link } = props;
  // styles -> border, align

  return (
    <Swiper
      modules={[Navigation, A11y, Autoplay]}
      navigation={
        naviPrev && naviNext
          ? {
              prevEl: `.${naviPrev}`,
              nextEl: `.${naviNext}`,
            }
          : false
      }
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      loop={true}
      spaceBetween={space}
      slidesPerView={perview}
      className="productSwiper"
    >
      <>
        {contents?.map((e, i) => (
          <SwiperSlide
            key={i}
            style={{ cursor: link ? "pointer" : "" }}
            onClick={() => {
              if (link) navigate(link, { state: { idx: e.idx } });
            }}
          >
            {/* 상품 이미지 */}
            <SlideImg border={styles?.border}>
              <div>
                <img src={e.image_path_1 ? `${process.env.REACT_APP_S3_KEY}${e.image_path_1}` : img_noimage470} />
              </div>
            </SlideImg>
            {/* 단종 상품 이미지 */}
            {e.sold_out === "extinction" && (
              <SlideImg border={styles?.border}>
                <div>
                  <img src={e.image_path_1 ? `${process.env.REACT_APP_S3_KEY}${e.image_path_1}` : img_noimage470} />
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    background: "rgba(30, 30, 30, 0.6)",
                  }}
                >
                  <ExtinctionImgText>품절</ExtinctionImgText>
                </div>
              </SlideImg>
            )}
            {/* 상품명 */}
            {e.text && (
              <SlideText className={styles?.align}>
                <Text color={"#000"} size={"12px"} sizeMd={"16px"} className="ellipsis">
                  {e.text}
                </Text>
              </SlideText>
            )}
            {e.product_name && (
              <SlideText className={styles?.align}>
                <Text color={"#000"} size={"12px"} sizeMd={"16px"} className="ellipsis">
                  {e.product_name}
                </Text>
              </SlideText>
            )}
            {/* 입고지연 태그 */}
            {e.sold_out === "missing" && (
              <SlideText className={styles?.align}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    minHeight: "16px",
                  }}
                >
                  <ProductTag className="missingTag">
                    <Text size={"8px"} sizeMd={"12px"} color={"#fff"} lineHeight={"100%"} weight={"400"}>
                      입고지연
                    </Text>
                  </ProductTag>
                  {e.text && (
                    <Text color={"#000"} size={"12px"} className="ellipsis">
                      {e.text}
                    </Text>
                  )}
                  {e.product_name && (
                    <Text color={"#000"} size={"12px"} className="ellipsis">
                      {e.product_name}
                    </Text>
                  )}
                </div>
              </SlideText>
            )}
            {/* 단종 태그 */}
            {e.sold_out === "extinction" && (
              <SlideText className={styles?.align}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    minHeight: "16px",
                  }}
                >
                  <ProductTag className="extinctionTag">
                    <Text size={"8px"} sizeMd={"12px"} color={"#fff"} lineHeight={"100%"} weight={"400"}>
                      단종
                    </Text>
                  </ProductTag>
                  {e.text && (
                    <Text color={"#000"} size={"12px"} className="ellipsis">
                      {e.text}
                    </Text>
                  )}
                  {e.product_name && (
                    <Text color={"#000"} size={"12px"} className="ellipsis">
                      {e.product_name}
                    </Text>
                  )}
                </div>
              </SlideText>
            )}
          </SwiperSlide>
        ))}
      </>
    </Swiper>
  );
}

const SlideImg = styled.div`
  border: ${(props) => props.border && "1px solid #eaeaea"};
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  div {
    position: relative;
    padding-bottom: 100%;

    img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
    }
  }
`;

const SlideText = styled.div`
  padding-top: 10px;

  @media all and (max-width: 1024px) {
    padding-top: 5px;
  }
`;

const ExtinctionImgText = styled.text`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #1e1e1e;
  color: #fff;
  font-weight: bold;
  text-align: center;
  width: 48px;
  height: 34px;
  line-height: 34px;
  font-size: 14px;

  @media all and (max-width: 1024px) {
    width: 30px;
    height: 20px;
    line-height: 20px;
    font-size: 8px;
  }
`;

const ProductTag = styled.div`
  background: #c7c7c7;
  margin-right: 10px;
  height: 100%;
  padding: 4px 7px;
  min-width: 34px;

  @media all and (max-width: 1024px) {
    padding: 4px 5px;
    max-width: 20px;
  }
`;

export default ProductSlider;
