import React, { useEffect, useState } from "react";
import API from "../../Utiles/API";
import BasicSelect from "./BasicSelect";

// 2차분류 선택 셀렉 박스

function Cate1Select(props) {
  const { cate1Value, setCate1Value, setCate2Value, width, widthMd, borderColor, placeholder } = props;

  const [cate1Data, setCate1Data] = useState([]);

  const getCate1List = async () => {
    API.get(`/admin/productCatrgory1st`).then((res) => {
      if (res.data.status) {
        const transformedArray = res.data.franchiseList.map((item) => ({
          value: item.idx,
          text: item.name,
        }));
        setCate1Data(transformedArray);
      }
    });
  };

  useEffect(() => {
    getCate1List();
  }, []);

  return (
    <>
      {cate1Data && (
        <BasicSelect
          width={width}
          widthMd={widthMd}
          data={cate1Data}
          selectedValue={cate1Value}
          setSelectedValue={setCate1Value}
          placeholder={"상품 1차 분류"}
          canSelectAll={true}
          borderColor={borderColor}
          setCate2Value={setCate2Value}
        />
      )}
    </>
  );
}

export default Cate1Select;
