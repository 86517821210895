// 리뉴얼된 디자인 : 가맹점, 가맹점주 선택 및 검색 컴포넌트
import React, { useEffect, useState } from "react";
import * as Hangul from "hangul-js";
import API from "../../Utiles/API";
import { SelectBtn, SearchList, SelectWrap, SearchInput, SelectContainer } from "../../Assets/css/commonVer2";

// 가맹점, 가맹주 검색

function FranSelect(props) {
  let {
    gubun, // require
    selectBtnText, // require : 선택된 데이터 텍스트
    setTextChange, // require : 결과 set
    placeholder, // require
    show, // require : 하단 결과 visible
    setFranShow, // optional : 가맹점 하단 결과 visible ctl
    setFranUserShow, // optional : 가맹점주 하단 결과 visible ctl
    setIdxChange, // optional : 사용자 등록, 수정 시 등 idx가 필요한 경우 사용됨
    setFranSelect, // optional : 가맹점, 가맹점주 검색창 동시에 있는 경우 사용
    setUserSelect, // optional : 가맹점, 가맹점주 검색창 동시에 있는 경우 사용
    widthMd,
    width,
    isInventory,
  } = props;

  const [inputValue, setInputValue] = useState("");
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const getData = async () => {
    const apiPath =
      gubun === "fran"
        ? "/admin/search-franchise"
        : gubun === "admin"
        ? "/admin/users?curPage=1&pageSize=99999&gubunVal=admin"
        : "/admin/search-storeonwer";

    await API.get(apiPath).then((res) => {
      if (res.data.status) {
        const modifiedArray =
          gubun === "fran"
            ? res.data.result.map((item) => ({
                idx: item.idx,
                name: item.franchise_name,
              }))
            : gubun === "admin"
            ? res.data.result.map((item) => ({
                idx: item.idx,
                name: `${item.name}(${item.user_id})`,
              }))
            : res.data.result.map((item) => ({
                idx: item.franchise_id,
                name: item.name,
              }));
        modifiedArray?.map((item) => {
          const dis = Hangul.disassemble(item.name, true);
          const cho = dis.reduce(function (prev, elem) {
            elem = elem[0] ? elem[0] : elem;
            return prev + elem;
          }, "");
          item.diassembled = cho;
        });
        setOriginalData(modifiedArray);
        setFilteredData(modifiedArray);
      }
    });
  };

  // 검색 결과 클릭 시
  const btnClick = (e, idx) => {
    setInputValue("");
    // 검색 결과 목록 invisible & 데이터 선택했음을 알림
    if (gubun === "fran") {
      if (setFranShow) setFranShow(false);
      if (setFranSelect) setFranSelect(true);
      if (setUserSelect) setUserSelect(false);
    } else if (gubun === "franUser") {
      if (setFranUserShow) setFranUserShow(false);
      if (setUserSelect) setUserSelect(true);
      if (setFranSelect) setFranSelect(false);
    } else if (gubun === "admin") {
      if (setFranShow) setFranShow(false);
      if (setFranSelect) setFranSelect(true);
    }
    // 선택한 데이터 text set
    setTextChange(e.target.innerText);
    // idx가 필요한 경우 선택한 데이터 idx set
    if (setIdxChange) {
      setIdxChange(idx);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (inputValue.length > 0) {
      setFilteredData(
        originalData.filter(
          (item) => item.name.includes(inputValue) || item.diassembled.includes(Hangul.disassemble(inputValue).join(""))
        )
      );
    } else {
      setFilteredData(originalData);
    }
  }, [inputValue, originalData]);

  return (
    <>
      <SelectContainer width={width || "200px"} widthMd={widthMd}>
        <SelectBtn
          width={"100%"}
          show={show}
          onClick={() => {
            if (gubun === "fran" || gubun === "admin") {
              setFranShow((state) => !state);
              if (setFranUserShow) setFranUserShow(false);
            } else if (gubun === "franUser") {
              if (setFranUserShow) setFranUserShow((state) => !state);
              setFranShow(false);
            }
          }}
        >
          {selectBtnText}
        </SelectBtn>
        {originalData.length > 0 && show ? (
          <SelectWrap width={"100%"}>
            <div style={{ padding: "2px" }}>
              <SearchInput
                id={`search_text${gubun}`}
                placeholder={`${placeholder} 검색해보세요.`}
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                bgColor={"#F2F2F2"}
                radius={"20px"}
                width={"100%"}
                size={"14px"}
                border={"1px solid var(--light-gray-01, #f2f2f2)"}
                boxShadow={"0px 1px 2px 0px rgba(0, 0, 0, 0.08)"}
              />
            </div>
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              {isInventory ? (
                ""
              ) : (
                <SearchList onClick={(e) => btnClick(e)}>
                  <li>
                    <span>{placeholder.slice(0, -1)} 전체</span>
                  </li>
                </SearchList>
              )}
              {filteredData.map((item, i) => (
                <SearchList id={`search_list${gubun}`} key={i} onClick={(e) => btnClick(e, item.idx)}>
                  <li>
                    <span>{item.name}</span>
                  </li>
                </SearchList>
              ))}
            </div>
          </SelectWrap>
        ) : (
          ""
        )}
      </SelectContainer>
    </>
  );
}

export default FranSelect;
