import React from 'react';
import styled from 'styled-components';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import {
  Btn,
  FlexBox,
  DateInputBox,
  RadioLabel,
  RadioIcon,
} from '../../../Assets/css/commonVer2';
import "../../../Assets/css/DatePicker.css";
import { dateToString } from '../../../Utiles/dateToString';


// 관리자 > 쇼핑몰 > 매출관리 검색바
export default function ShopSalesSearchBar({
  dispatchSearchState,
  searchSales, // 검색 버튼 클릭 함수
  dateOptionState,
  setDateOptionState,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {

  return (
    <>
      <SearchBarBox>
        {/* 토글버튼 */}
        <FlexBox
          width={"100%"}
          widthMd={"unset"}
          gap={"10px"}
          margin={"0"}
          marginMd={"0 10px 0 0"}
          wrap={"wrap"}
        >
          <RadioLabel>
            <input
              type="radio"
              name="day"
              value="day"
              checked={dateOptionState === "day"}
              onChange={() => setDateOptionState("day")}
            />
            <RadioIcon>
              <svg viewBox="0 0 18 18">
                <circle cx="9" cy="9" r="8.5" />
                <circle cx="9" cy="9" r="5" />
              </svg>
            </RadioIcon>
            일별
          </RadioLabel>
          <RadioLabel>
            <input
              type="radio"
              name="month"
              value="month"
              checked={dateOptionState === "month"}
              onChange={() => setDateOptionState("month")}
            />
            <RadioIcon>
              <svg viewBox="0 0 18 18">
                <circle cx="9" cy="9" r="8.5" />
                <circle cx="9" cy="9" r="5" />
              </svg>
            </RadioIcon>
            월별
          </RadioLabel>
        </FlexBox>
        {/* 달력, 기간 선택 */}
        <FlexBox gap={"10px"} flexMd={"unset"} flex={"1"}>
          {/* startDate 달력 */}
          <DateInputBox style={{ width: "100%" }}>
            <DatePicker
              dateFormatCalendar="yyyy년 MM월"
              dateFormat={dateOptionState === "month" ? "yyyy-MM" : "yyyy-MM-dd"}
              showMonthYearPicker={dateOptionState === "month" ? true : false}
              selected={startDate}
              maxDate={dateOptionState === "hour" ? "" : endDate}
              onChange={(date) => {
                setStartDate(date)
                dispatchSearchState({ type: "CHANGE_START_DATE", value: dateToString(date) })
              }}
              startDate={startDate}
              endDate={endDate}
            />
          </DateInputBox>

          {/* endDate 달력 */}
          {dateOptionState !== "hour" && (
            <DateInputBox style={{ width: "100%" }}>
              <DatePicker
                dateFormatCalendar="yyyy년 MM월"
                dateFormat={dateOptionState === "month" ? "yyyy-MM" : "yyyy-MM-dd"}
                showMonthYearPicker={dateOptionState === "month" ? true : false}
                selected={endDate}
                minDate={startDate}
                onChange={(date) => {
                  setEndDate(date)
                  dispatchSearchState({ type: "CHANGE_END_DATE", value: dateToString(date) })
                }}
                startDate={startDate}
                endDate={endDate}
              />
            </DateInputBox>
          )}
        </FlexBox>
        <Btn
          color={"#fff"}
          background={"#FF4A4A"}
          size={"14px"}
          weight={"700"}
          width={"100%"}
          widthMd={"unset"}
          padding={"8px 18px"}
          lineHeight={"22px"}
          onClick={() => searchSales()}
          style={{ flexShrink: 0 }}
        >
          검 색
        </Btn>
      </SearchBarBox>
    </>
  )
}

const SearchBarBox = styled(FlexBox)`
  gap: 10px;
  flex-wrap: wrap;
  justify-content: end;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`
