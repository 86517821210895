import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import {
  TableWrap,
  Th,
  Td,
  Btn,
  CheckLabel,
  DisableCheckIcon,
  CheckIcon,
  SearchInput,
  HeaderContainer,
  Wrapper,
  Container,
  ContentContainer,
  FlexBox,
  Text,
  WhiteBox,
  TextBar,
  Title,
} from "../../Assets/css/commonVer2";
import Header from "../../Components/Header/HeaderVer2";
import { Xlsx_download, AddComma, str } from "../../Utiles/Custom";
import { INDIV_SLOT_STATUS_STOREOWNER_LIST, SEARCH_TYPE_LIST, UNIT_10_TO_30_LIST } from "../../Utiles/ListData";
import API from "../../Utiles/API";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import Authority from "../../Components/Modal/Authority";
import Pagination from "../../Components/Paging/Pagination";
import Cate2Select from "../../Components/Select/Cate2Select";
import BasicSelect from "../../Components/Select/BasicSelect";
import InfoModal from "../../Components/Modal/InfoModal";
import ProductSearchInputVer2 from "../../Components/SearchInput/ProductSearchInputVer2";
import HandleBack from "../../Components/HandleBack";
import Loading from "../../Components/Loading";

// 상품관리 > 자점매입 상품관리 (기존 페이지)

function SelfGoods() {
  const navigate = useNavigate();
  const [franchise_id] = useState(sessionStorage.getItem("franchise_id"));
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const { handlePopstate } = HandleBack();

  // 등록, 수정을 다녀온 경우 저장 해놓은 파라미터 정보
  const parsedSelfGoodsPageState = sessionStorage.getItem("selfGoodsPageState")
    ? JSON.parse(sessionStorage.getItem("selfGoodsPageState"))
    : {};

  // 필터 관련
  const [cate2Value, setCate2Value] = useState(
    parsedSelfGoodsPageState ? (parsedSelfGoodsPageState?.cate2Value ? parsedSelfGoodsPageState?.cate2Value : "") : "",
  );
  const [statusValue, setStatusValue] = useState(
    parsedSelfGoodsPageState
      ? parsedSelfGoodsPageState?.statusValue
        ? parsedSelfGoodsPageState?.statusValue
        : ""
      : "",
  );

  // 검색 관련
  const [filter, setFilter] = useState(
    parsedSelfGoodsPageState
      ? parsedSelfGoodsPageState?.filter
        ? parsedSelfGoodsPageState?.filter
        : "product_name"
      : "product_name",
  );
  const [inputValue, setInputValue] = useState(
    parsedSelfGoodsPageState ? (parsedSelfGoodsPageState?.inputValue ? parsedSelfGoodsPageState?.inputValue : "") : "",
  );
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchList, setShowSearchList] = useState(false);

  // 페이징 관련
  const [pagination, setPagination] = useState("");
  const [pageSize, setPageSize] = useState(
    parsedSelfGoodsPageState ? (parsedSelfGoodsPageState?.pageSize ? parsedSelfGoodsPageState?.pageSize : 10) : 10,
  );
  const [currentPage, setCurrentPage] = useState(
    parsedSelfGoodsPageState ? (parsedSelfGoodsPageState?.currentPage ? parsedSelfGoodsPageState?.currentPage : 1) : 1,
  );

  // 데이터 관련
  const [data, setData] = useState([]);
  const [slotCnt, setSlotCnt] = useState(0);
  const [checkItems, setCheckItems] = useState([]);

  const [hoverRow, setHoverRow] = useState(null);
  const [loading, setLoading] = useState(false);

  // 모달들
  const [requestConfirmModal, setRequestConfirmModal] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [textModal, setTextModal] = useState(false);
  const [refreshModal, setRefreshModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [reasonModal, setReasonModal] = useState(false);
  const [reasonModalTxts] = useState({
    title: "",
    text: "",
    reason: "",
  });

  // 등록, 수정 이동 시 저장되야 하는 현재 페이지에 대한 정보
  let selfGoodsPageState = {
    currentPage: currentPage,
    pageSize: pageSize,
    cate2Value: cate2Value,
    statusValue: statusValue,
    filter: filter,
    inputValue: inputValue,
  };

  const getSlotList = async () => {
    let api_link = `/slot/user/product?page=${currentPage}&unit=${pageSize}&block=10&franchise_id=${franchise_id}&gubun=storeowner`;
    if (cate2Value) api_link += `&category2nd_filter=${cate2Value}`;
    if (inputValue) api_link += `&search_type=${filter}&search_word=${inputValue}`;
    if (statusValue) api_link += `&slot_status_filter=${statusValue}`;

    await API.get(api_link).then((res) => {
      if (res.data.status) {
        setSlotCnt(res.data.cntAll);
        setPagination(res.data.pagination);
        setData(res.data.results);
      }
    });
  };

  const requestReview = async () => {
    setLoading(true);
    API.post(`/slot/admin-checking`, {
      franchise_id: parseInt(franchise_id),
      request_list: checkItems,
    })
      .then(function (res) {
        setLoading(false);
        if (res.data.status) {
          setTextMsg("검수요청이 되었습니다.");
          setRefreshModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(function (error) {
        setLoading(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const cancelReview = async () => {
    setLoading(true);
    API.delete(`/slot/admin-checking?franchise_id=${franchise_id}&request_list=${checkItems}`)
      .then(function (res) {
        setLoading(false);
        if (res.data.status) {
          setTextMsg("검수요청이 취소되었습니다.");
          setRefreshModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(function (error) {
        setLoading(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const getReason = async (idx, gubun) => {
    await API.get(`/slot/refuse-reason?slot_id=${idx}`).then((res) => {
      if (res.data.status) {
        reasonModalTxts.reason = res.data.results.refuse_reason || "잠시후 다시 시도해주세요.";
        if (gubun === "reject") {
          reasonModalTxts.title = "검수 승인이 반려되었습니다.";
          reasonModalTxts.text = "반려 사유는 아래와 같습니다. 상품 수정 후 다시 검수 요청을 해주세요.";
        } else {
          reasonModalTxts.title = "판매가 중지되었습니다.";
          reasonModalTxts.text = "판매 중지 사유는 아래와 같습니다. 상품 수정 후 다시 검수 요청을 해주세요.";
        }
        setReasonModal(true);
      } else {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    });
  };

  const onExcelDown = async () => {
    // 데이터 유무
    if (data.length > 0) {
      // api 호출
      let api_link = `/slot/user/product?page=1&unit=99999999&block=10&franchise_id=${franchise_id}&gubun=storeowner`;
      if (cate2Value) api_link += `&category2nd_filter=${cate2Value}`;
      if (inputValue) api_link += `&search_type=${filter}&search_word=${inputValue}`;
      if (statusValue) api_link += `&slot_status_filter=${statusValue}`;

      const res = await API.get(api_link);
      if (res.data.status && res.data.results.length > 0) {
        // 엑셀 포맷으로 변경
        var xlsxTemp = res.data.results.map(function (item) {
          const status = INDIV_SLOT_STATUS_STOREOWNER_LIST.find((i) => i.value === item.status)?.text || "-";
          return {
            주문번호: item.purchase_slot_id,
            슬롯번호: item.idx,
            상품분류: item.category2nd_name,
            바코드번호: item.product_barcode_num,
            상품명: item.product_product_name,
            소비자가: item.product_consumer_price,
            만료일: str(item.expirationAt),
            상태: status,
          };
        });
        // 다운로드 실행
        Xlsx_download(xlsxTemp, `자점매입상품`);
      } else {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    } else {
      setTextMsg("다운받을 데이터가 없습니다.");
      setTextModal(true);
    }
  };

  const moveToWrite = async (slotId) => {
    let jsonString = JSON.stringify(selfGoodsPageState);
    sessionStorage.setItem("selfGoodsPageState", jsonString);
    navigate("/goods/selfgoods/write", {
      state: { slot_id: slotId },
    });
  };

  const moveToEdit = async () => {
    const isNotOnUnderReview = data.find((item) => item.idx === checkItems[0])?.status !== "adminChecking";
    if (isNotOnUnderReview) {
      let jsonString = JSON.stringify(selfGoodsPageState);
      sessionStorage.setItem("selfGoodsPageState", jsonString);
      navigate("/goods/selfgoods/edit", {
        state: { slot_id: checkItems[0] },
      });
    } else {
      setTextMsg("검수중인 상품은 수정할 수 없습니다. 수정을 원하시면 검수 취소 먼저 진행해주세요.");
      setTextModal(true);
    }
  };

  const onSubmit = (e) => {
    if (e === 1) {
      setRequestConfirmModal(false);
      // 등록만 포함되어 있는지 확인
      const isAllItemsRight = checkItems.every((idx) => data.find((item) => item.idx === idx)?.status === "registered");
      if (isAllItemsRight) {
        requestReview();
      } else {
        setTextMsg(
          "상태가 '등록'인 상품만 검수요청할 수 있습니다. (검수반려 혹은 판매중지된 상품은 수정 후 다시 검수요청을 진행해주세요.)",
        );
        setTextModal(true);
      }
    } else if (e === 2) {
      setDeleteConfirmModal(false);
      // 검수중만 포함되어 있는지 확인
      const isAllItemsRight = checkItems.every(
        (idx) => data.find((item) => item.idx === idx)?.status === "adminChecking",
      );
      if (isAllItemsRight) {
        cancelReview();
      } else {
        setTextMsg("상태가 '검수중'인 상품만 검수요청을 취소할 수 있습니다.");
        setTextModal(true);
      }
    }
  };

  const movePage = (e) => {
    setCurrentPage(e);
    setCheckItems([]);
  };

  const onSearch = () => {
    setSearchResults([]);
    setShowSearchList(false);
    setCurrentPage(1);
    getSlotList();
  };

  const onChangeChx = (checked, id) => {
    if (checked) {
      setCheckItems((prev) => [...prev, id]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  const onChangeAllChk = (checked) => {
    if (checked) {
      const chkArr = data.filter((item) => item.status !== "unRegistered").map((item) => item.idx);
      setCheckItems(chkArr);
    } else {
      setCheckItems([]);
    }
  };

  useEffect(() => {
    setInputValue("");
  }, [filter]);

  useEffect(() => {
    getSlotList();
  }, [currentPage, pageSize]);

  useEffect(() => {
    sessionStorage.removeItem("selfGoodsPageState");
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          {loading ? <Loading /> : null}

          {isMobile && <Header title={"자점매입 상품관리"} />}
          <ContentContainer>
            <Wrapper>
              <Container>
                {/* 공통 - 다른 페이지로 이동 버튼 시작 */}
                <FlexBox justify={"space-between"} style={{ flex: 1 }}>
                  {!isMobile && (
                    <>
                      <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                        자점매입 상품관리 📋
                      </Title>
                      <FlexBox />
                    </>
                  )}
                  <FlexBox justify={"end"} gap={"8px"} gapMd={"15px"} width={"100%"} widthMd={"unset"}>
                    <Btn
                      color={"#fff"}
                      weight={"700"}
                      radius={"6px"}
                      padding={"9px 19px"}
                      background={"#FF4A4A"}
                      lineHeight={"22px"}
                      size={"14px"}
                      width={"100%"}
                      widthMd={"unset"}
                      onClick={() => navigate("buy")}
                    >
                      슬롯 구매 →
                    </Btn>
                    <Btn
                      color={"#fff"}
                      weight={"700"}
                      radius={"6px"}
                      padding={"9px 19px"}
                      background={"#FF4A4A"}
                      lineHeight={"22px"}
                      size={"14px"}
                      width={"100%"}
                      widthMd={"unset"}
                      onClick={() => navigate("slothistory")}
                    >
                      슬롯 구매내역 →
                    </Btn>
                  </FlexBox>
                </FlexBox>

                {/* 웹 - 안내사항 시작 */}
                <div className="greyBox d-none d-md-block" style={{ marginTop: "24px", padding: "28px 40px" }}>
                  <Text
                    color={"#666666"}
                    size={"18px"}
                    weight={"700"}
                    align={"left"}
                    margin={"0 0 10px 0"}
                    style={{
                      latterSpacing: "-0.36px",
                      lineHeight: "normal",
                    }}
                  >
                    유의사항
                  </Text>
                  <TextBar
                    align={"left"}
                    style={{
                      latterSpacing: "-0.28px",
                      lineHeight: "25px",
                    }}
                  >
                    <BoldText>자점 매입 상품</BoldText>은 가맹점주님이{" "}
                    <BoldText>상품을 직접 구매하여 등록 후 판매가 가능</BoldText> 합니다.
                  </TextBar>
                  <TextBar
                    color={"#666666"}
                    align={"left"}
                    style={{
                      latterSpacing: "-0.28px",
                      lineHeight: "25px",
                    }}
                  >
                    자점 매입은 <BoldText>상품 슬롯을 구매하여 상품 등록 후 운영 가능</BoldText> 하며, 본사는 해당
                    판매에 대한 책임을 지지 않습니다.
                  </TextBar>
                  <TextBar
                    align={"left"}
                    style={{
                      latterSpacing: "-0.28px",
                      lineHeight: "25px",
                    }}
                  >
                    슬롯에 상품을 등록 후 <BoldText>검수 요청</BoldText>을 꼭 해주셔야 하며, 관리자의 검수 승인이 되어야
                    해당 상품을 판매할 수 있습니다.
                  </TextBar>
                  <TextBar
                    align={"left"}
                    style={{
                      latterSpacing: "-0.28px",
                      lineHeight: "25px",
                    }}
                  >
                    인증받지 않은 상품을 판매하여 수반되는 피해의 경우 본사가 책임지지 않습니다.
                  </TextBar>
                </div>

                {/* 공통 - 버튼, 데이터 시작 */}
                <WhiteBox>
                  <FlexBox
                    justify={"end"}
                    gap={"10px"}
                    gapMd={"15px"}
                    margin={"0 0 "}
                    marginMd={"28px 0 20px 0 "}
                    wrap={"wrap"}
                  >
                    <Cate2Select widthMd={"31%"} idx={1} cate2Value={cate2Value} setCate2Value={setCate2Value} />
                    <BasicSelect
                      widthMd={"31%"}
                      data={INDIV_SLOT_STATUS_STOREOWNER_LIST}
                      selectedValue={statusValue}
                      setSelectedValue={setStatusValue}
                      placeholder={"상태 전체"}
                      canSelectAll={true}
                    />
                    <BasicSelect
                      widthMd={"31%"}
                      data={SEARCH_TYPE_LIST}
                      selectedValue={filter}
                      setSelectedValue={setFilter}
                      canSelectAll={false}
                    />
                    {!isMobile && (
                      <FlexBox gap={"10px"}>
                        {filter === "barcode_num" ? (
                          <SearchInput
                            placeholder="바코드번호를 입력해주세요."
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                          />
                        ) : (
                          <ProductSearchInputVer2
                            showSearchList={showSearchList}
                            setShowSearchList={setShowSearchList}
                            searchResults={searchResults}
                            setSearchResults={setSearchResults}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            onSearch={onSearch}
                            cate1value={2}
                            franchiseId={franchise_id}
                          />
                        )}
                        <Btn
                          color={"#fff"}
                          background={"#FF4A4A"}
                          radius={"6px"}
                          size={"14px"}
                          weight={"700"}
                          padding={"9px 19px"}
                          maxHeight={"40px"}
                          style={{ lineHeight: "22px" }}
                          onClick={onSearch}
                        >
                          검 색
                        </Btn>
                      </FlexBox>
                    )}
                  </FlexBox>
                  {isMobile && (
                    <FlexBox gap={"10px"} margin={"10px 0"}>
                      {filter === "barcode_num" ? (
                        <SearchInput
                          placeholder="바코드번호를 입력해주세요."
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                        />
                      ) : (
                        <ProductSearchInputVer2
                          showSearchList={showSearchList}
                          setShowSearchList={setShowSearchList}
                          searchResults={searchResults}
                          setSearchResults={setSearchResults}
                          inputValue={inputValue}
                          setInputValue={setInputValue}
                          onSearch={onSearch}
                          cate1value={2}
                          franchiseId={franchise_id}
                        />
                      )}
                      <Btn
                        color={"#fff"}
                        background={"#FF4A4A"}
                        radius={"6px"}
                        size={"14px"}
                        weight={"700"}
                        width={"80px"}
                        padding={"9px 19px"}
                        lineHeight={"22px"}
                        onClick={onSearch}
                      >
                        검 색
                      </Btn>
                    </FlexBox>
                  )}

                  <FlexBox
                    justify={"space-between"}
                    margin={"0 0 15px"}
                    marginMd={"0 0 30px"}
                    gap={"10px"}
                    gapMd={"10px"}
                    wrap={"wrap"}
                  >
                    <FlexBox justify={"starts"}>
                      <Text
                        width={"100%"}
                        widthMd={"unset"}
                        color={"#FF4A4A"}
                        size={"16px"}
                        weight={"400"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        marginMd={"0 10px 0 0 "}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        lineHeight={"normal"}
                      >
                        슬롯 수량 : <span style={{ fontWeight: "700" }}>{AddComma(slotCnt ? slotCnt : "")}개</span>
                      </Text>
                      {!isMobile && (
                        <BasicSelect
                          data={UNIT_10_TO_30_LIST}
                          selectedValue={pageSize}
                          setSelectedValue={setPageSize}
                          canSelectAll={false}
                          setCurrentPage={setCurrentPage}
                        />
                      )}
                    </FlexBox>
                    <FlexBox justify={"end"} gap={"15px"}>
                      <Btn
                        border={"1px solid #FF4A4A"}
                        color={"#FF4A4A"}
                        hoverColor={"#FFFFFF"}
                        hoverBackground={"#FF4A4A"}
                        hoverWeight={"700"}
                        padding={"9px 19px"}
                        size={"14px"}
                        radius={"6px"}
                        weight={"400"}
                        lineHeight={"22px"}
                        onClick={() => {
                          if (checkItems.length > 0) {
                            setRequestConfirmModal(true);
                          } else {
                            setTextMsg("검수요청 할 상품을 먼저 선택해주세요.");
                            setTextModal(true);
                          }
                        }}
                      >
                        검수 요청
                      </Btn>
                      <Btn
                        border={"1px solid #FF4A4A"}
                        color={"#FF4A4A"}
                        hoverColor={"#FFFFFF"}
                        hoverBackground={"#FF4A4A"}
                        hoverWeight={"700"}
                        padding={"9px 19px"}
                        size={"14px"}
                        radius={"6px"}
                        weight={"400"}
                        lineHeight={"22px"}
                        onClick={() => {
                          if (checkItems.length > 0) {
                            setDeleteConfirmModal(true);
                          } else {
                            setTextMsg("검수취소 할 상품을 먼저 선택해주세요.");
                            setTextModal(true);
                          }
                        }}
                      >
                        검수 취소
                      </Btn>
                      <Btn
                        border={"1px solid #FF4A4A"}
                        color={"#FF4A4A"}
                        hoverColor={"#FFFFFF"}
                        hoverBackground={"#FF4A4A"}
                        hoverWeight={"700"}
                        padding={"9px 19px"}
                        size={"14px"}
                        radius={"6px"}
                        weight={"400"}
                        lineHeight={"22px"}
                        onClick={() => {
                          if (checkItems.length === 1) {
                            moveToEdit();
                          } else {
                            if (checkItems.length > 1) {
                              setTextMsg("하나의 상품만 선택해주세요.");
                              setTextModal(true);
                            } else {
                              setTextMsg("수정할 상품을 선택해주세요.");
                              setTextModal(true);
                            }
                          }
                        }}
                      >
                        상품 수정
                      </Btn>
                      {!isMobile && (
                        <Btn
                          border={"1px solid #666666"}
                          hoverBorder={"1px solid #C7C7C7"}
                          hoverBackground={"#C7C7C7"}
                          color={"#666666"}
                          hoverColor={"#FFFFFF"}
                          weight={"500"}
                          hoverWeight={"700"}
                          padding={"9px 19px"}
                          size={"14px"}
                          radius={"6px"}
                          lineHeight={"22px"}
                          onClick={onExcelDown}
                        >
                          엑셀 다운
                        </Btn>
                      )}
                    </FlexBox>
                  </FlexBox>

                  <TableWrap>
                    <table style={{ width: "100%", minWidth: "1024px" }}>
                      <colgroup>
                        <col style={{ width: "4%" }} />
                        <col style={{ width: "8%" }} />
                        <col style={{ width: "8%" }} />
                        <col style={{ width: "10%" }} />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col style={{ width: "10%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>
                            <CheckLabel htmlFor="chkAll" style={{ justifyContent: "center" }}>
                              <input
                                type="checkbox"
                                id="chkAll"
                                onChange={(event) => onChangeAllChk(event.target.checked)}
                                checked={
                                  checkItems.length === data?.filter((item) => item.status !== "unRegistered").length
                                    ? true
                                    : false
                                }
                              />
                              <CheckIcon>
                                <svg viewBox="0 0 24 24">
                                  <polyline points="19 7 10 17 5 12" />
                                </svg>
                              </CheckIcon>
                            </CheckLabel>
                          </Th>
                          <Th>주문 번호</Th>
                          <Th>슬롯 번호</Th>
                          <Th>상품분류</Th>
                          <Th>바코드 번호</Th>
                          <Th>상품명</Th>
                          <Th>소비자가</Th>
                          <Th>만료일</Th>
                          <Th>상태</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 ? (
                          data?.map((list, key) => (
                            <tr
                              style={{
                                cursor: "pointer",
                                backgroundColor:
                                  list?.status === "unRegistered" //hoverRow === key &&
                                    ? "rgba(255, 116, 64, 0.10)"
                                    : "transparent",
                                // fontWeight:
                                //   hoverRow === key && list?.status === "미등록"
                                //     ? "bold"
                                //     : "normal",
                              }}
                              key={key}
                              onMouseEnter={() => {
                                setHoverRow(key);
                              }}
                              onMouseLeave={() => {
                                setHoverRow(null);
                              }}
                              onClick={(event) => {
                                const targetElement = event.target;
                                // 체크박스 클릭 시 반복호출 방지
                                if (targetElement.nodeName !== "INPUT") {
                                  // 클릭한 요소의 내용 확인
                                  const dataType = targetElement.getAttribute("data-type");

                                  // 1. 클릭한 곳이 상태(status)인지 체크
                                  if (dataType === "status") {
                                    if (list.status === "reject" || list.status === "stopSale") {
                                      getReason(list.idx, list.status);
                                      return;
                                    }
                                  }

                                  // 2. 클릭한 row가 미등록인지 체크
                                  if (list.status === "unRegistered") {
                                    // 미등록 상태면 상품 등록 페이지로 이동
                                    moveToWrite(list.idx);
                                    return;
                                  }

                                  // 3. 그 외 체크박스 함수 실행
                                  const isChecked = document.getElementById(`prod${list.idx}`).checked;
                                  onChangeChx(!isChecked, list.idx);
                                }
                              }}
                            >
                              <Td
                                align={"center"}
                                style={{
                                  borderBottom: "1px solid #F2F2F2",
                                }}
                              >
                                <CheckLabel htmlFor={`prod${list.idx}`} style={{ justifyContent: "center" }}>
                                  <input
                                    type="checkbox"
                                    name="prod"
                                    id={`prod${list.idx}`}
                                    readOnly
                                    checked={checkItems.includes(list.idx) ? true : false}
                                  />
                                  {list?.status === "unRegistered" ? (
                                    <DisableCheckIcon>
                                      <svg viewBox="0 0 24 24">
                                        <polyline points="19 7 10 17 5 12" />
                                      </svg>
                                    </DisableCheckIcon>
                                  ) : (
                                    <CheckIcon>
                                      <svg viewBox="0 0 24 24">
                                        <polyline points="19 7 10 17 5 12" />
                                      </svg>
                                    </CheckIcon>
                                  )}
                                </CheckLabel>
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {list.purchase_slot_id ? list.purchase_slot_id : "-"}
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{list.idx ? list.idx : "-"}</Td>
                              <Td
                                style={{
                                  borderBottom: "1px solid #F2F2F2",
                                }}
                              >
                                {list.category2nd_name ? list.category2nd_name : "-"}
                              </Td>
                              <Td
                                style={{
                                  borderBottom: "1px solid #F2F2F2",
                                }}
                              >
                                {list.product_barcode_num ? list.product_barcode_num : "-"}
                              </Td>
                              <Td
                                data-type="product_name"
                                style={{
                                  borderBottom: "1px solid #F2F2F2",
                                  // fontWeight:
                                  //   hoverRow === key &&
                                  //   list?.status === "unRegistered"
                                  //     ? "bold"
                                  //     : "normal",
                                }}
                                color={list?.status === "unRegistered" ? "#FF4A4A" : "#666666"}
                              >
                                {list.product_product_name ? list.product_product_name : "상품을 등록해주세요."}
                              </Td>
                              <Td
                                style={{
                                  borderBottom: "1px solid #F2F2F2",
                                }}
                              >
                                {list.product_consumer_price ? AddComma(list.product_consumer_price) : "-"}
                                {list.product_consumer_price ? "원" : ""}
                              </Td>
                              <Td
                                style={{
                                  borderBottom: "1px solid #F2F2F2",
                                }}
                              >
                                {list.purchase_type === "regular_purchase"
                                  ? "구독형 / 정기결제"
                                  : list.expirationAt
                                    ? str(list.expirationAt)
                                    : ""}
                              </Td>
                              <Td
                                data-type="status"
                                color={
                                  list.status === "reject" || list.status === "stopSale"
                                    ? "#F63958"
                                    : // : list.status == "unRegistered"
                                      // ? "#ffffff"
                                      "#666666"
                                }
                                // size={
                                //   list.status == "unRegistered" ? "12px" : "16px"
                                // }
                                style={{
                                  borderBottom: "1px solid #F2F2F2",
                                  textDecoration:
                                    list.status === "reject" || list.status === "stopSale" ? "underline" : "none",
                                  fontWeight:
                                    hoverRow === key && (list.status === "reject" || list.status === "stopSale")
                                      ? "bold"
                                      : "normal",
                                }}
                              >
                                {list.status
                                  ? INDIV_SLOT_STATUS_STOREOWNER_LIST.find((item) => item.value === list.status)
                                      ?.text || "-"
                                  : "-"}
                              </Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={9}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "16px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  {pagination?.total_page > 0 ? (
                    <Pagination pagination={pagination} movePage={movePage} currentPage={currentPage} />
                  ) : (
                    ""
                  )}
                </WhiteBox>

                <div className="greyBox d-block d-md-none" style={{ marginTop: "20px", padding: "20px 20px" }}>
                  <Text
                    color={"#666666"}
                    size={"14px"}
                    weight={"700"}
                    align={"left"}
                    margin={"0 0 10px 0"}
                    latterSpacing={"-0.36px"}
                    lineHeight={"normal"}
                  >
                    유의사항
                  </Text>
                  <TextBar color={"#6c6c6c"} align={"left"}>
                    <BoldText>자점 매입 상품</BoldText>은 가맹점주님이{" "}
                    <BoldText>상품을 직접 구매하여 등록 후 판매가 가능</BoldText> 합니다.
                  </TextBar>
                  <TextBar color={"#6c6c6c"} align={"left"}>
                    자점 매입은 <BoldText>상품 슬롯을 구매하여 상품 등록 후 운영 가능</BoldText> 하며, 본사는 해당
                    판매에 대한 책임을 지지 않습니다.
                  </TextBar>
                  <TextBar color={"#6c6c6c"} align={"left"}>
                    슬롯에 상품을 등록 후 <BoldText>검수 요청</BoldText>을 꼭 해주셔야 하며, 관리자의 검수 승인이 되어야
                    해당 상품을 판매할 수 있습니다.
                  </TextBar>
                  <TextBar color={"#6c6c6c"} align={"left"}>
                    인증받지 않은 상품을 판매하여 수반되는 피해의 경우 본사가 책임지지 않습니다.
                  </TextBar>
                </div>
              </Container>
              <InfoModal modal={refreshModal} setModal={setRefreshModal} description={textMsg} mode={"refresh"} />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
              <InfoModal
                modal={reasonModal}
                setModal={setReasonModal}
                title={reasonModalTxts.title}
                description={reasonModalTxts.text}
                detailText={reasonModalTxts.reason}
              />
              <ConfirmModal
                modal={requestConfirmModal}
                setModal={setRequestConfirmModal}
                onSubmit={() => onSubmit(1)}
                description={"선택한 상품을 검수요청하시겠습니까?"}
              />

              <ConfirmModal
                modal={deleteConfirmModal}
                setModal={setDeleteConfirmModal}
                onSubmit={() => onSubmit(2)}
                description={"선택한 상품을 검수취소하시겠습니까?"}
              />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const BoldText = styled.span`
  font-weight: bold;
`;

export default SelfGoods;
