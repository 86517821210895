import * as XLSX from "xlsx";

// 숫자 3자리마다 콤마 00,000,000
export const AddComma = (num) => {
	const num2 = num ? num : 0;
	var regexp = /\B(?=(\d{3})+(?!\d))/g;
	return num2.toString().replace(regexp, ",");
};

// 날짜 0000-00-00
export const dateToString = (date, mark = "-") => {
	return (
		date.getFullYear() +
		mark +
		(date.getMonth() + 1).toString().padStart(2, "0") +
		mark +
		date.getDate().toString().padStart(2, "0")
	);
};

export const Xlsx_download = (list, title = "Test") => {
	const ws = XLSX.utils.json_to_sheet(list);

	const wb = XLSX.utils.book_new();

	XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
	const filename = title + ".xlsx";
	XLSX.writeFile(wb, filename);
};

export const str = (txt) => {
	return txt.substr(0, 10);
};

export const month = (txt) => {
	return txt.substr(0, 7);
};

export const hypen = (hp) => {
	return hp?.replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3").replace("--", "-");
};

export const number = (number) => {
	return number.replace(/[^0-9]/g, "");
};

export const inputDate = (event) => {
	let date = event;
	date = date.replace(/-/g, "");

	let tempDate = "";
	if (date.length < 5) {
		return (tempDate = date);
	} else if (date.length < 7) {
		tempDate = `${date.substr(0, 4)}-${date.substr(4, 2)}`;
	} else {
		tempDate = `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6)}`;
	}

	return tempDate;
};

export const handleKeyDown = (event, onSearch) => {
	const key = event.code;
	switch (key) {
		case "Enter":
			if (onSearch) {
				onSearch();
			}
			break;
		default:
	}
};
