import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import {
  ContentContainer,
  Wrapper,
  Container,
  WhiteBox,
  FlexBox,
  Title,
  Text,
  Btn,
  TableWrap,
  Th,
  Td,
  CheckLabel,
  CheckIcon,
  SearchInput,
} from "../../Assets/css/commonVer2";
import Header from "../../Components/Header/HeaderVer2";
import Authority from "../../Components/Modal/Authority";
import InfoModal from "../../Components/Modal/InfoModal";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import Pagination from "../../Components/Paging/Pagination";
import BasicSelect from "../../Components/Select/BasicSelect";
import HandleBack from "../../Components/HandleBack";
import { AddComma, handleKeyDown, hypen } from "../../Utiles/Custom";
import {
  UNIT_10_TO_50_LIST,
  COUPON_PRICE_LIST,
  COUPON_STATUS_LIST,
  COUPON_SEARCH_TYPE_LIST,
} from "../../Utiles/ListData";
import API from "../../Utiles/API";
import { Xlsx_download } from "../../Utiles/Custom";

// 혜택관리 > 쿠폰관리

export default function BenefitsCoupon() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const { handlePopstate } = HandleBack();

  // 모달
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [refreshModal, setRefreshModal] = useState(false);

  // 쿠폰 등록 다녀왔을 때 state
  const parsedCouponPageState = sessionStorage.getItem("couponPageState")
    ? JSON.parse(sessionStorage.getItem("couponPageState"))
    : {};

  // 페이징
  const [pagination, setPagination] = useState(0);
  // 체크한 상품들
  const [checkItems, setCheckItems] = useState([]);

  // 데이터
  const [cnt, setCnt] = useState("");
  const [data, setData] = useState([]);

  const [pageSize, setPageSize] = useState(
    parsedCouponPageState ? (parsedCouponPageState?.pageSize ? parsedCouponPageState?.pageSize : 10) : 10,
  );
  const [currentPage, setCurrentPage] = useState(
    parsedCouponPageState ? (parsedCouponPageState?.currentPage ? parsedCouponPageState?.currentPage : 1) : 1,
  );
  const [chargeAmount, setChargeAmount] = useState(
    parsedCouponPageState ? (parsedCouponPageState?.chargeAmount ? parsedCouponPageState?.chargeAmount : "") : "",
  );
  const [couponStatus, setCouponStatus] = useState(
    parsedCouponPageState ? (parsedCouponPageState?.couponStatus ? parsedCouponPageState?.couponStatus : "") : "",
  );
  const [filter, setFilter] = useState(
    parsedCouponPageState
      ? parsedCouponPageState?.filter
        ? parsedCouponPageState?.filter
        : "coupon_num"
      : "coupon_num",
  );
  const [inputValue, setInputValue] = useState(
    parsedCouponPageState ? (parsedCouponPageState?.inputValue ? parsedCouponPageState?.inputValue : "") : "",
  );

  // 상품 등록 갈 때 sessionStorage에 저장할 객체
  let couponPageState = {
    pageSize,
    currentPage,
    chargeAmount,
    couponStatus,
    filter,
    inputValue,
  };

  // API
  // 1. 쿠폰 조회
  const getShopProductList = async () => {
    let api_link = `/admin/coupon/point?page=${currentPage}&unit=${pageSize}&block=10`;
    if (chargeAmount) api_link += `&charge_amount=${chargeAmount}`;
    if (couponStatus === 1) api_link += `&use_yn=true`;
    if (couponStatus === 2) api_link += `&use_yn=false`;
    if (inputValue) {
      if (inputValue.includes("-")) {
        const newInputValue = inputValue.replaceAll("-", "").trim();
        if (filter === "coupon_num") api_link += `&coupon_identifier=${newInputValue}`;
        if (filter === "phone") api_link += `&consumer_id=${newInputValue}`;
        if (filter === "franchise_name") api_link += `&franchise_name=${newInputValue}`;
      } else {
        if (filter === "coupon_num") api_link += `&coupon_identifier=${inputValue.trim()}`;
        if (filter === "phone") api_link += `&consumer_id=${inputValue.trim()}`;
        if (filter === "franchise_name") api_link += `&franchise_name=${inputValue}`;
      }
    }

    await API.get(api_link).then((res) => {
      if (res.data.status) {
        setCnt(res.data.cntAll);
        setPagination(res.data.pagination);
        setData(res.data.results);
      }
    });
  };

  // 2. 쿠폰 삭제
  const deleteShopProduct = async () => {
    await API.delete(`/admin/coupon/point?coupon_id=${checkItems}`).then((res) => {
      if (res.data.status) {
        setDeleteModal(false);
        setRefreshModal(true);
      } else {
        setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    });
  };

  // 3. 쿠폰 리스트 엑셀 다운
  const onExcelDown = async () => {
    // 데이터 유무
    if (data.length > 0) {
      // api 호출
      let api_link = `/admin/coupon/point?page=1&unit=99999999&block=10`;
      if (chargeAmount) api_link += `&charge_amount=${chargeAmount}`;
      if (couponStatus === 1) api_link += `&use_yn=true`;
      if (couponStatus === 2) api_link += `&use_yn=false`;
      if (inputValue) {
        if (inputValue.includes("-")) {
          const newInputValue = inputValue.replaceAll("-", "");

          if (filter === "coupon_num") api_link += `&coupon_identifier=${newInputValue}`;
          if (filter === "phone") api_link += `&consumer_id=${newInputValue}`;
          if (filter === "franchise_name") api_link += `&franchise_name=${inputValue}`;
        } else {
          if (filter === "coupon_num") api_link += `&coupon_identifier=${inputValue}`;
          if (filter === "phone") api_link += `&consumer_id=${inputValue}`;
          if (filter === "franchise_name") api_link += `&franchise_name=${inputValue}`;
        }
      }

      const res = await API.get(api_link);
      if (res.data.status && res.data.results.length > 0) {
        // 엑셀 포맷으로 변경
        var xlsxTemp = res.data.results.map(function (item, idx) {
          return {
            No: idx + 1,
            가맹점명: item.franchise_name,
            쿠폰번호: item.coupon_identifier,
            금액: item.charge_amount,
            발행일자: item.createdAt,
            휴대폰번호: item.consumer_id ? hypen(item.consumer_id) : "-",
            등록일자: item.updatedAt ? item.updatedAt : "-",
            상태: item.use_yn === true ? "등록" : "미등록",
          };
        });
        // 다운로드 실행
        Xlsx_download(xlsxTemp, `쿠폰 리스트`);
      } else {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    } else {
      setTextMsg("다운받을 데이터가 없습니다.");
      setTextModal(true);
    }
  };

  // 아이템 삭제 클릭
  const onClickDeleteProduct = () => {
    if (checkItems.length > 0) {
      // use_yn이 true 등록된 쿠폰의 id를 저장한 배열
      const registeredIds = data.filter((item) => item.use_yn).map((item) => item.coupon_id);

      // 체크한 아이템 배열에서 등록된 쿠폰 id를 포함하고 있는 경우 안내 메시지, 없는 경우 삭제 api 실행
      if (checkItems.some((el) => registeredIds.includes(el))) {
        setTextMsg("등록된 쿠폰은 삭제할 수 없습니다.");
        setTextModal(true);
      } else {
        setDeleteModal(true);
      }
    } else {
      setTextMsg("삭제할 상품을 선택해주세요.");
      setTextModal(true);
    }
  };

  // 검색 함수
  const onSearch = () => {
    if (currentPage === 1) {
      getShopProductList();
    } else {
      setCurrentPage(1);
    }
  };

  // 체크박스 개별 체크
  const onChangeChx = (checked, id) => {
    if (checked) {
      setCheckItems((prev) => [...prev, id]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  // 체크박스 전체 체크
  const onChangeAllChk = (checked) => {
    if (checked) {
      const chkArr = data.map((item) => item.coupon_id);
      setCheckItems(chkArr);
    } else {
      setCheckItems([]);
    }
  };

  const movePage = (e) => {
    setCurrentPage(e);
    setCheckItems([]);
  };

  useEffect(() => {
    getShopProductList();
  }, [currentPage, pageSize]);

  useEffect(() => {
    sessionStorage.removeItem("couponPageState");

    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {isMobile && <Header title={"쿠폰 관리"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    쿠폰 관리
                  </Title>
                )}
                <WhiteBox margin={"0 0"}>
                  <FlexBox wrap={"wrap"} gap={"10px"} justify={"end"}>
                    <BasicSelect
                      selectedValue={chargeAmount}
                      setSelectedValue={setChargeAmount}
                      data={COUPON_PRICE_LIST}
                      placeholder={"금액 전체"}
                      canSelectAll={true}
                    />
                    <BasicSelect
                      selectedValue={couponStatus}
                      setSelectedValue={setCouponStatus}
                      data={COUPON_STATUS_LIST}
                      placeholder={"상태 전체"}
                      canSelectAll={true}
                    />
                    <BasicSelect
                      selectedValue={filter}
                      setSelectedValue={setFilter}
                      data={COUPON_SEARCH_TYPE_LIST}
                      canSelectAll={false}
                    />
                    <InputAndBtnBox>
                      {filter === "coupon_num" && (
                        <SearchInput
                          borderBottom={"1px solid #666"}
                          borderBottomColor={"#ff4a4a"}
                          transition={"border-color 0.3s ease-in-out"}
                          placeholder={"쿠폰 번호를 입력해주세요."}
                          value={inputValue}
                          onChange={(e) => {
                            setInputValue(e.target.value);
                          }}
                          onKeyDown={(event) => handleKeyDown(event, onSearch)}
                        />
                      )}
                      {filter === "phone" && (
                        <SearchInput
                          borderBottom={"1px solid #666"}
                          borderBottomColor={"#ff4a4a"}
                          transition={"border-color 0.3s ease-in-out"}
                          placeholder={"휴대폰 번호를 입력해주세요."}
                          value={inputValue}
                          onChange={(e) => {
                            setInputValue(e.target.value);
                          }}
                          onKeyDown={(event) => handleKeyDown(event, onSearch)}
                        />
                      )}
                      {filter === "franchise_name" && (
                        <SearchInput
                          borderBottom={"1px solid #666"}
                          borderBottomColor={"#ff4a4a"}
                          transition={"border-color 0.3s ease-in-out"}
                          placeholder={"가맹점명을 입력해주세요."}
                          value={inputValue}
                          onChange={(e) => {
                            setInputValue(e.target.value);
                          }}
                          onKeyDown={(event) => handleKeyDown(event, onSearch)}
                        />
                      )}
                      <Btn
                        color={"#fff"}
                        background={"#FF4A4A"}
                        size={"14px"}
                        weight={"700"}
                        width={"100%"}
                        widthMd={"unset"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        onClick={onSearch}
                        setCate2Value
                        style={{ flexShrink: 0 }}
                      >
                        검 색
                      </Btn>
                    </InputAndBtnBox>
                  </FlexBox>
                  <FlexBox
                    justify={"space-between"}
                    gap={"10px"}
                    margin={"20px 0 10px"}
                    marginMd={"20px 0 30px"}
                    wrap={"wrap"}
                    wrapMd={"no-wrap"}
                  >
                    <FlexBox gap={"10px"} width={"100%"} widthMd={"auto"}>
                      {!isMobile && (
                        <BasicSelect
                          width={"130px"}
                          data={UNIT_10_TO_50_LIST}
                          selectedValue={pageSize}
                          setSelectedValue={setPageSize}
                          canSelectAll={false}
                          setCurrentPage={setCurrentPage}
                        />
                      )}
                      <Text
                        width={"100%"}
                        widthMd={"unset"}
                        color={"#FF4A4A"}
                        size={"16px"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        style={{
                          flexShrink: 0,
                        }}
                      >
                        쿠폰 수량 : <span style={{ fontWeight: "700" }}>{AddComma(cnt ? cnt : 0)}개</span>
                      </Text>
                    </FlexBox>
                    <FlexBox gap={"10px"} justify={"flex-end"} width={"100%"} widthMd={"auto"}>
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        onClick={() => {
                          navigate("/benefits/coupon/write");
                          let jsonString = JSON.stringify(couponPageState);
                          sessionStorage.setItem("couponPageState", jsonString);
                        }}
                      >
                        등록
                      </Btn>
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        onClick={onClickDeleteProduct}
                      >
                        삭제
                      </Btn>
                      {!isMobile && (
                        <Btn
                          size={"14px"}
                          padding={"8px 18px"}
                          border={"1px solid #666"}
                          color={"#666"}
                          hoverBackground={"#C7C7C7"}
                          hoverColor={"#fff"}
                          hoverBorder={"transparent"}
                          lineHeight={"22px"}
                          hoverWeight={"700"}
                          onClick={onExcelDown}
                        >
                          엑셀 다운
                        </Btn>
                      )}
                    </FlexBox>
                  </FlexBox>
                  <TableWrap>
                    <table style={{ width: "100%", minWidth: "1024px" }}>
                      <colgroup>
                        <col style={{ width: "5.5%" }} />
                        <col style={{ width: "5.5%" }} />
                        <col style={{ width: "10.5%" }} />
                        <col style={{ width: "15.5%" }} />
                        <col style={{ width: "10.5%" }} />
                        <col />
                        <col style={{ width: "15.5%" }} />
                        <col />
                        <col style={{ width: "10%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>
                            <CheckLabel htmlFor="chkAll" style={{ justifyContent: "center" }}>
                              <input
                                type="checkbox"
                                id="chkAll"
                                onChange={(event) => onChangeAllChk(event.target.checked)}
                                checked={checkItems.length === data.length ? true : false}
                              />
                              <CheckIcon>
                                <svg viewBox="0 0 24 24">
                                  <polyline points="19 7 10 17 5 12" />
                                </svg>
                              </CheckIcon>
                            </CheckLabel>
                          </Th>
                          <Th>No</Th>
                          <Th>가맹점명</Th>
                          <Th>쿠폰 번호</Th>
                          <Th>금액</Th>
                          <Th>발행일자</Th>
                          <Th>휴대폰 번호</Th>
                          <Th>등록일자</Th>
                          <Th>상태</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 ? (
                          data.map((item, key) => (
                            <tr
                              style={{ cursor: "pointer" }}
                              key={item.coupon_id}
                              onClick={(event) => {
                                const targetElement = event.target;
                                if (targetElement.nodeName !== "INPUT") {
                                  const isChecked = document.getElementById(`prod${item.coupon_id}`).checked;
                                  onChangeChx(!isChecked, item.coupon_id);
                                }
                              }}
                            >
                              <Td align={"center"}>
                                <CheckLabel htmlFor={`prod${item.coupon_id}`} style={{ justifyContent: "center" }}>
                                  <input
                                    type="checkbox"
                                    name="prod"
                                    id={`prod${item.coupon_id}`}
                                    readOnly
                                    checked={checkItems.includes(item.coupon_id) ? true : false}
                                  />
                                  <CheckIcon>
                                    <svg viewBox="0 0 24 24">
                                      <polyline points="19 7 10 17 5 12" />
                                    </svg>
                                  </CheckIcon>
                                </CheckLabel>
                              </Td>
                              <Td>{(currentPage - 1) * pageSize + key + 1}</Td>
                              {/* 가맹점명 */}
                              <Td>{item.franchise_name ? item.franchise_name : "-"}</Td>
                              <Td>{item.coupon_identifier ? item.coupon_identifier : "-"}</Td>
                              <Td weight={700}>{item.charge_amount ? AddComma(item.charge_amount) : 0}원</Td>
                              <Td>{item.createdAt ? item.createdAt : "-"}</Td>
                              <Td>{item.consumer_id ? hypen(item.consumer_id) : "-"}</Td>
                              <Td>{item.updatedAt ? item.updatedAt : "-"}</Td>
                              <Td>{item.use_yn === true ? "등록" : "미등록"}</Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={8}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  {pagination?.total_page > 0 ? (
                    <Pagination pagination={pagination} movePage={movePage} currentPage={currentPage} />
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <ConfirmModal
                modal={deleteModal}
                setModal={setDeleteModal}
                onSubmit={deleteShopProduct}
                description={"선택된 쿠폰을 삭제하시겠습니까? 동일한 쿠폰번호로 재등록은 불가합니다."}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
              <InfoModal
                modal={refreshModal}
                setModal={setRefreshModal}
                description={"쿠폰이 정상적으로 삭제되었습니다."}
                mode={"refresh"}
              />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const InputAndBtnBox = styled.div`
  display: flex;
  gap: 10px;

  @media all and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: end;
  }
`;
