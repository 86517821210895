import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import DatePicker from "react-datepicker";
import { useMediaQuery } from "react-responsive";
import {
  Btn,
  TableWrap,
  Td,
  Th,
  ContentContainer,
  Container,
  Wrapper,
  CheckLabel,
  CheckIcon,
  FlexBox,
  WhiteBox,
  Text,
  DateInputBox,
  Title,
} from "../../Assets/css/commonVer2";
import { DEPOSIT_NOTE_LIST, UNIT_10_TO_30_LIST, DEPOSIT_SEARCH_STATUS_LIST } from "../../Utiles/ListData";
import Header from "../../Components/Header/HeaderVer2";
import API from "../../Utiles/API";
import { AddComma, dateToString, Xlsx_download } from "../../Utiles/Custom";
import Paging from "../../Components/Paging/Paging";
import Authority from "../../Components/Modal/Authority";
import HandleBack from "../../Components/HandleBack";
import BasicSelect from "../../Components/Select/BasicSelect";
import InfoModal from "../../Components/Modal/InfoModal";

// 상품관리 > 예치금 현황 > 예치금 내역보기

function DepositView() {
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const { handlePopstate } = HandleBack();
  const [franchise_id] = useState(location.state?.fid || sessionStorage.getItem("franchise_id"));

  const date = new Date();
  const year = date.getFullYear(); // 년
  const month = date.getMonth(); // 월
  const day = date.getDate(); // 일
  const [startDate, setStartDate] = useState(new Date(year, month, day - 7));
  const [endDate, setEndDate] = useState(date);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalAmount, setTotalAmount] = useState([]);
  const [noteValue, setNoteValue] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [dateType, setDateType] = useState("");

  const [textModal, setTextModal] = useState(false); // 모달 창 표시 여부 상태
  const [textMsg, setTextMsg] = useState("");

  const onStatusChange = (e) => {
    if (e.target.id === "radio0") {
      if (statusValue === "") {
        setStatusValue("1");
      } else if (statusValue === "1") {
        setStatusValue("");
      } else if (statusValue === "0") {
        setStatusValue("1");
      }
    } else if (e.target.id === "radio1") {
      if (statusValue === "") {
        setStatusValue("0");
      } else if (statusValue === "0") {
        setStatusValue("");
      } else if (statusValue === "1") {
        setStatusValue("0");
      }
    }
  };

  const onSearch = () => {
    setCurrentPage(1);
    // getDepositTotalList();
    getDepositList();
  };

  const getDepositTotalList = async () => {
    let api_link = `/storeowner/deposit-history/${franchise_id}?curPage=1&pageSize=${99999}&dateType=1`;
    if (noteValue) api_link += `&filterVal2=${noteValue}`;
    if (statusValue) api_link += `&filterVal3=${statusValue}`;
    if (startDate) api_link += `&filterDatemin=${dateToString(startDate)}`;
    if (endDate) api_link += `&filterDatemax=${dateToString(endDate)}`;
    await API.get(api_link).then((res) => {
      if (res.data.status) {
        setTotalCount(res.data?.result?.cntAll);
      }
    });
  };

  const getDepositList = async () => {
    let api_link = `/storeowner/deposit-history/${franchise_id}?curPage=${currentPage}&pageSize=${limit}&dateType=1`;
    if (noteValue) api_link += `&filterVal2=${noteValue}`;
    if (statusValue) api_link += `&filterVal3=${statusValue}`;
    if (startDate) api_link += `&filterDatemin=${dateToString(startDate)}`;
    if (endDate) api_link += `&filterDatemax=${dateToString(endDate)}`;
    await API.get(api_link).then((res) => {
      if (res.data.status) {
        setData(res.data?.result?.result);
        setTotalAmount(res.data?.result?.total);
        setTotalCount(res.data?.result?.cntAll);
      }
    });
  };

  const onExcelDown = async () => {
    if (data.length > 0) {
      let api_link = `/storeowner/deposit-history/${franchise_id}?curPage=1&pageSize=99999999&dateType=1`;
      if (noteValue) api_link += `&filterVal2=${noteValue}`;
      if (statusValue) api_link += `&filterVal3=${statusValue}`;
      if (startDate) api_link += `&filterDatemin=${dateToString(startDate)}`;
      if (endDate) api_link += `&filterDatemax=${dateToString(endDate)}`;
      await API.get(api_link)
        .then((res) => {
          if (res.data.status) {
            var xlsxTemp = res.data?.result?.result.map(function (item) {
              const note = DEPOSIT_NOTE_LIST.find((i) => i.value === item.note)?.text || "-";
              return {
                상태: item.status === 0 ? "입금" : item.status === 1 ? "출금" : "",
                내용: item.content,
                금액: item.amount,
                등록일: item.createdat,
                비고: note,
              };
            });
            Xlsx_download(xlsxTemp, `예치금 내역_${dateToString(startDate)}~${dateToString(endDate)}`);
          } else {
            setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
            setTextModal(true);
          }
        })
        .catch(function (error) {
          setTextMsg("E01\n잠시후 다시 시도해주세요.");
          setTextModal(true);
        });
    } else {
      setTextMsg("다운받을 데이터가 없습니다.");
      setTextModal(true);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    getDepositList();
    // getDepositTotalList();
  }, [limit]);

  useEffect(() => {
    setTotalPage(Math.ceil(totalCount / limit));
  }, [totalCount, limit]);

  useEffect(() => {
    getDepositList();
  }, [totalPage, currentPage, limit]);

  useEffect(() => {
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          {isMobile && <Header title={"예치금 현황"} />}
          <ContentContainer>
            <Wrapper type={2} pbM={"122px"}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    예치금 현황 📋
                  </Title>
                )}
                {/* 모바일 검색바 */}
                {isMobile && (
                  <FlexBox justify={"end"} gap={"10px"} wrap={"wrap"}>
                    <FlexBox />
                    <FlexBox gap={"10px"} width="100%" widthMd="auto">
                      <DateInputBox style={{ width: "100%" }}>
                        <DatePicker
                          dateFormatCalendar="yyyy년 MM월"
                          dateFormat="yyyy-MM-dd"
                          selected={startDate}
                          maxDate={endDate}
                          onChange={(date) => setStartDate(date)}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </DateInputBox>
                      <DateInputBox style={{ width: "100%" }}>
                        <DatePicker
                          dateFormatCalendar="yyyy년 MM월"
                          dateFormat="yyyy-MM-dd"
                          selected={endDate}
                          minDate={startDate}
                          onChange={(date) => setEndDate(date)}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </DateInputBox>
                    </FlexBox>
                    <BasicSelect
                      width={"140px"}
                      data={DEPOSIT_NOTE_LIST}
                      selectedValue={noteValue}
                      setSelectedValue={setNoteValue}
                      placeholder={"비고전체"}
                      canSelectAll={true}
                    />
                    <BasicSelect
                      data={DEPOSIT_SEARCH_STATUS_LIST}
                      selectedValue={statusValue}
                      setSelectedValue={setStatusValue}
                      canSelectAll={true}
                      placeholder={"상태전체"}
                    />
                    <Btn
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      width={"100%"}
                      widthMd={"unset"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      onClick={() => {
                        onSearch();
                      }}
                      style={{ flexShrink: 0 }}
                    >
                      검 색
                    </Btn>
                  </FlexBox>
                )}
                <WhiteBox>
                  {/* 웹 검색바 */}
                  {!isMobile && (
                    <FlexBox wrap={"wrap"} justify={"end"} gap={"10px"}>
                      <FlexBox gap={"10px"} flexMd={"unset"} flex={"1"}>
                        <DateInputBox style={{ width: "100%" }}>
                          <DatePicker
                            dateFormatCalendar="yyyy년 MM월"
                            dateFormat="yyyy-MM-dd"
                            selected={startDate}
                            maxDate={endDate}
                            onChange={(date) => setStartDate(date)}
                            startDate={startDate}
                            endDate={endDate}
                          />
                        </DateInputBox>
                        <DateInputBox style={{ width: "100%" }}>
                          <DatePicker
                            dateFormatCalendar="yyyy년 MM월"
                            dateFormat="yyyy-MM-dd"
                            selected={endDate}
                            minDate={startDate}
                            onChange={(date) => setEndDate(date)}
                            startDate={startDate}
                            endDate={endDate}
                          />
                        </DateInputBox>
                      </FlexBox>

                      <FlexBox gap={"10px"} flexMd={"unset"} flex={"1 0 auto"} width={"100%"} widthMd={"unset"}>
                        <BasicSelect
                          width={"140px"}
                          data={DEPOSIT_NOTE_LIST}
                          selectedValue={noteValue}
                          setSelectedValue={setNoteValue}
                          placeholder={"비고전체"}
                          canSelectAll={true}
                        />
                      </FlexBox>
                      <FlexBox gap={"10px"} wrapMd={"unset"} wrap={"wrap"} flexMd={"unset"} flex={"1 0 auto"}>
                        <FlexBox gap={"10px"}>
                          {/* <CheckLabel htmlFor="radio0" gap={"5px"}>
                            <input
                              type="radio"
                              name="radio0"
                              id="radio0"
                              value="0"
                              checked={statusValue === "0" || statusValue === ""}
                              onClick={onStatusChange}
                            />
                            <CheckIcon>
                              <svg viewBox="0 0 24 24">
                                <polyline points="19 7 10 17 5 12" />
                              </svg>
                            </CheckIcon>
                          </CheckLabel>
                          <Text size={"14px"} onClick={() => onStatusChange("")}>
                            전체
                          </Text> */}
                          <CheckLabel htmlFor="radio0" gap={"5px"}>
                            <input
                              type="radio"
                              name="radio0"
                              id="radio0"
                              value="0"
                              checked={statusValue === "0" || statusValue === ""}
                              onClick={onStatusChange}
                            />
                            <CheckIcon>
                              <svg viewBox="0 0 24 24">
                                <polyline points="19 7 10 17 5 12" />
                              </svg>
                            </CheckIcon>
                          </CheckLabel>
                          <Text size={"14px"} onClick={() => onStatusChange("0")}>
                            입금
                          </Text>
                          <CheckLabel htmlFor="radio1" gap={"5px"}>
                            <input
                              type="radio"
                              name="radio1"
                              id="radio1"
                              value="1"
                              checked={statusValue === "1" || statusValue === ""}
                              onClick={onStatusChange}
                            />
                            <CheckIcon>
                              <svg viewBox="0 0 24 24">
                                <polyline points="19 7 10 17 5 12" />
                              </svg>
                            </CheckIcon>
                          </CheckLabel>
                          <Text size={"14px"} onClick={() => onStatusChange("0")}>
                            출금
                          </Text>
                        </FlexBox>
                        <Btn
                          color={"#fff"}
                          background={"#FF4A4A"}
                          size={"14px"}
                          weight={"700"}
                          width={"100%"}
                          widthMd={"unset"}
                          padding={"8px 18px"}
                          lineHeight={"22px"}
                          onClick={() => {
                            onSearch();
                          }}
                          style={{ flexShrink: 0 }}
                        >
                          검 색
                        </Btn>
                      </FlexBox>
                    </FlexBox>
                  )}

                  <FlexBox
                    justify={"space-between"}
                    margin={"0 0"}
                    marginMd={"20px 0 "}
                    gap={"10px"}
                    wrap={"wrap"}
                    directionMd={"row"}
                    direction={"column"}
                  >
                    <FlexBox justify={"starts"} wrap={"wrap"} gap={"10px"} width={"100%"} widthMd={"unset"}>
                      {!isMobile && (
                        <BasicSelect
                          width={"130px"}
                          data={UNIT_10_TO_30_LIST}
                          selectedValue={limit}
                          setSelectedValue={setLimit}
                          canSelectAll={false}
                          setCurrentPage={setCurrentPage}
                        />
                      )}
                      <Text
                        width={"100%"}
                        widthMd={"unset"}
                        color={"#FF4A4A"}
                        size={"16px"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        style={{
                          flexShrink: 0,
                        }}
                      >
                        기간 내 예치금 : <span style={{ fontWeight: "700" }}>{AddComma(totalAmount)}원</span>
                      </Text>
                    </FlexBox>
                    <FlexBox justify={"end"} style={{ marginLeft: "auto" }}>
                      {!isMobile && (
                        <Btn
                          size={"14px"}
                          padding={"8px 18px"}
                          border={"1px solid #666"}
                          color={"#666"}
                          hoverBackground={"#C7C7C7"}
                          hoverColor={"#fff"}
                          hoverBorder={"transparent"}
                          lineHeight={"22px"}
                          hoverWeight={"700"}
                          margin={"0 0 0 10px"}
                          onClick={onExcelDown}
                        >
                          엑셀 다운
                        </Btn>
                      )}
                    </FlexBox>
                  </FlexBox>
                  <TableWrap>
                    <table style={{ width: "100%" }}>
                      <colgroup>
                        <col style={{ width: "12%" }} />
                        <col style={{ width: "16%" }} />
                        <col style={{ width: "12%" }} />
                        <col style={{ width: "16%" }} />
                        <col style={{ width: "12%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>상 태</Th>
                          <Th>내 용</Th>
                          <Th>금 액</Th>
                          <Th>등록일</Th>
                          <Th>비 고</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length === 0 ? (
                          <tr>
                            <Td
                              colSpan={5}
                              size={"12px"}
                              sizeMd={"14px"}
                              weight={"400"}
                              color={"#6c6c6c"}
                              align={"center"}
                            >
                              데이터가 없습니다.
                            </Td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {data?.map((e, i) => {
                          const amount = AddComma(e.amount);
                          return (
                            <tr key={i}>
                              <Td color={e && e.status !== 0 ? "#F63958" : "#2C70D5"}>
                                {e && e.status !== 0 ? "출금" : "입금"}
                              </Td>

                              <Td>{e.content || "-"}</Td>
                              <Td>{amount}원</Td>
                              <Td>{e.createdat}</Td>
                              <Td>
                                {e.note ? DEPOSIT_NOTE_LIST.find((item) => item.value === e.note)?.text || "-" : "-"}
                              </Td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </TableWrap>
                  {data?.length > 0 ? (
                    <Paging totalPage={totalPage} limit={limit} page={currentPage} setPage={setCurrentPage} />
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

export default DepositView;
