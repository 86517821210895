import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

import {
  Btn,
  Container,
  ContentContainer,
  FlexBox,
  Text,
  TextInput,
  Title,
  WhiteBox,
  Wrapper,
} from "../../Assets/css/commonVer2";
import FranSelect from "../../Components/Select/FranSelect";
import API from "../../Utiles/API";
import InfoModal from "../../Components/Modal/InfoModal";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import HeaderSub from "../../Components/Header/HeaderSub";
import Authority from "../../Components/Modal/Authority";

// 포인트 충전을 위한 페이지
function BenefitsCharge() {
  const navigate = useNavigate();
  let isDeveloper = sessionStorage.getItem("user_id") === "46";
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [saveModal, setSaveModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [textModal, setTextModal] = useState(false);
  const [franShow, setFranShow] = useState(false);
  const [franText, setFranText] = useState("가맹점 전체");
  const [franIdx, setFranIdx] = useState("");
  const [values, setValues] = useState([
    {
      phone: "",
      point: "",
    },
  ]);

  const onDelete = (e) => {
    const id = parseInt(e.target.id, 10);
    setValues((prev) => prev.filter((_, i) => i !== id));
  };

  const handleChange = (e) => {
    const { id, name, value } = e.target;
    const sanitizedValue = value.replace(/-/g, "");
    setValues((prev) => {
      const updated = [...prev];
      updated[id][name] = sanitizedValue;
      return updated;
    });
  };

  const handleAddClick = () => {
    setValues((prev) => [...prev, { phone: "", point: "" }]);
  };

  const onSave = async () => {
    setSaveModal(false);
    for (const item of values) {
      if (!item.phone || !item.point) {
        setTextMsg("핸드폰번호와 금액을 모두 입력해주세요.");
        setTextModal(true);
        return;
      }
    }
    if (!franIdx) {
      setTextMsg("가맹점을 선택해주세요");
      setTextModal(true);
      return;
    }
    await API.post(`/admin/promotion-point`, {
      franchiseId: franIdx,
      franchiseType: "franchise",
      dataArr: values,
    })
      .then((res) => {
        if (res.data.status) {
          setTextMsg("등록되었습니다.");
          setTextModal(true);
          setValues([
            {
              phone: "",
              point: "",
            },
          ]);
        } else {
          console.log(res);
          setTextMsg(res.data.msg || "잠시 후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch((error) => {
        const errorMsg = error.response?.data.meta.description;
        setTextMsg(`E01\n  ${errorMsg}`);
        setTextModal(true);
      });
  };

  const onClickSave = () => {
    setSaveModal(true);
  };

  return (
    <>
      {!isDeveloper ? (
        <Authority title={"지정된 아이디"} />
      ) : (
        <>
          <HeaderSub title={"포인트 충전"} />
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    포인트 충전
                  </Title>
                )}
                <WhiteBox style={{ gap: "20px" }}>
                  {!isMobile && (
                    <FlexBox justify="end">
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        onClick={() => navigate("/benefits/charge/write")}
                      >
                        엑셀 업로드
                      </Btn>
                    </FlexBox>
                  )}
                  <div>
                    <Text
                      align={"left"}
                      weight={"700"}
                      color={"#1e1e1e"}
                      size={"14px"}
                      sizeMd={"16px"}
                      letterSpacing={"-0.32px"}
                      margin={"0 0 8px"}
                      marginMd={"0 0 10px"}
                    >
                      가맹점
                    </Text>
                    <FranSelect
                      width={"200px"}
                      widthMd={"100%"}
                      gubun={"fran"}
                      placeholder={"가맹점을"}
                      show={franShow}
                      setFranShow={setFranShow}
                      selectBtnText={franText}
                      setTextChange={setFranText}
                      setIdxChange={setFranIdx}
                    />
                  </div>
                  {values?.map((item, i) => {
                    return (
                      <FlexBox key={i} gap="10px">
                        <Contents>
                          <Text
                            align={"left"}
                            weight={"700"}
                            color={"#1e1e1e"}
                            size={"14px"}
                            sizeMd={"16px"}
                            letterSpacing={"-0.32px"}
                            margin={"0 0 8px"}
                            marginMd={"0 0 10px"}
                          >
                            핸드폰번호
                          </Text>
                          <TextInput
                            id={i.toString()}
                            style={{ width: "100%" }}
                            type={"text"}
                            placeholder={"- 없이 입력해주세요."}
                            name="phone"
                            value={item.phone}
                            onChange={(e) => {
                              handleChange(e, false);
                            }}
                          />
                        </Contents>
                        <Contents>
                          <Text
                            align={"left"}
                            weight={"700"}
                            color={"#1e1e1e"}
                            size={"14px"}
                            sizeMd={"16px"}
                            letterSpacing={"-0.32px"}
                            margin={"0 0 8px"}
                            marginMd={"0 0 10px"}
                          >
                            금액
                          </Text>
                          <TextInput
                            id={i.toString()}
                            style={{ width: "100%" }}
                            type={"text"}
                            placeholder={"금액을 입력해주세요."}
                            name="point"
                            value={item.point}
                            onChange={(e) => {
                              handleChange(e, false);
                            }}
                          />
                        </Contents>
                        <Btn
                          id={i.toString()}
                          margin={"30px 0 0 0 "}
                          width={"100px"}
                          widthMd={"100px"}
                          size={"14px"}
                          padding={"8px 18px"}
                          lineHeight={"22px"}
                          hoverWeight={"700"}
                          onClick={onDelete}
                        >
                          삭제
                        </Btn>
                      </FlexBox>
                    );
                  })}

                  <FlexBox margin={"12px 0 0"} marginMd={"17px 0 0"} gap="10px" justify="end">
                    <Btn
                      width={"100%"}
                      widthMd={"90px"}
                      size={"14px"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      hoverWeight={"700"}
                      onClick={handleAddClick}
                    >
                      추가
                    </Btn>
                    <Btn
                      width={"100%"}
                      widthMd={"90px"}
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      onClick={onClickSave}
                    >
                      등록
                    </Btn>
                  </FlexBox>
                </WhiteBox>
              </Container>
              <ConfirmModal
                modal={saveModal}
                setModal={setSaveModal}
                onSubmit={onSave}
                description={"포인트 등록을 완료하시겠습니까?"}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

export default BenefitsCharge;

const Contents = styled.div`
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  background-color: ${(props) => props.background || ""};
  width: ${(props) => props.width || "100%"};
  border-radius: ${(props) => props.radius || "8px"};
  flex-wrap: ${(props) => props.wrap ?? props.wrap};
  flex: ${(props) => props.flex ?? props.flex};
  @media only screen and (min-width: 1024px) {
    flex-direction: ${(props) => props.directionMd ?? props.directionMd};
    flex: ${(props) => props.flexMd ?? props.flexMd};
    width: ${(props) => props.widthMd ?? props.widthMd};
    margin: ${(props) => props.marginMd ?? props.marginMd};
    padding: ${(props) => props.paddingMd ?? props.paddingMd};
    flex-wrap: ${(props) => props.wrapMd ?? props.wrapMd};
  }
`;
