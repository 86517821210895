import React, { useEffect, useRef } from "react";
import debounce from "lodash.debounce";

import { handleKeyDown } from "../../Utiles/Custom";
import API from "../../Utiles/API";
import { SearchList, SelectWrap, SearchInput, SelectContainer } from "../../Assets/css/commonVer2";

// 리뉴얼 UI : 사용O

/**
 * 상품명 검색 (상품명만 필요한 경우)
 * 1. 검색어 입력 시 하단에 상품명 목록 노출
 * 2. 상품명 목록에서 상품 클릭 시 해당 텍스트를 검색창에 위치
 */

function ProductSearchInputVer2(props) {
  let {
    inputValue,
    setInputValue,
    searchResults,
    setSearchResults,
    showSearchList,
    setShowSearchList,
    onSearch,
    searchInventory,
    disabled,
    cate1value, // 필수 : 본사상품 검색인지 = 1, 자점매입 상품검색 = 2
    franchiseId, // 옵션 : 자점매입 검색의 경우 필수
    placeholder,
  } = props;

  const searchRef = useRef(null);

  // 검색 API 호출
  const searchProduct = debounce((searchQuery) => {
    if (searchQuery.length >= 2) {
      if (cate1value && cate1value === 2) {
        // 자점매입 상품 검색
        API.get("/slot/user/current-product?franchise_id=" + franchiseId + "&product_name=" + searchQuery).then(
          (res) => {
            if (res.data.status) {
              if (res.data.results.length > 0) {
                setSearchResults(res.data.results);
                setShowSearchList(true);
              } else {
                setSearchResults([]);
                setShowSearchList(false);
              }
            } else {
              setSearchResults([]);
              setShowSearchList(false);
            }
          }
        );
      } else {
        // 본사상품 검색
        API.get("/admin/product/name?product_name=" + searchQuery + "&scope=product_name").then((res) => {
          if (res.data.status) {
            if (res.data.results.length > 0) {
              setSearchResults(res.data.results);
              setShowSearchList(true);
            } else {
              setSearchResults([]);
              setShowSearchList(false);
            }
          } else {
            setSearchResults([]);
            setShowSearchList(false);
          }
        });
      }
    } else {
      setSearchResults([]);
      setShowSearchList(false);
    }
  }, 300);

  // 검색 결과 목록에서 아이템을 클릭함
  const handleItemClick = (itemName) => {
    setShowSearchList(false);
    setInputValue(itemName);
  };

  // 다른 곳 클릭 시 목록 닫히도록
  useEffect(() => {
    function handleOutside(e) {
      if (searchRef.current && !searchRef.current.contains(e.target)) {
        setShowSearchList(false);
      }
    }
    document.addEventListener("mousedown", handleOutside);
    return () => {
      document.removeEventListener("mousedown", handleOutside);
    };
  }, [searchRef]);

  return (
    <>
      <SelectContainer ref={searchRef}>
        <SearchInput
          borderBottom={"1px solid #666666"}
          borderBottomColor={"#ff4a4a"}
          transition={"border-color 0.3s ease-in-out"}
          disabled={disabled}
          placeholder={placeholder || "상품명을 검색해보세요."}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            searchProduct(e.target.value);
          }}
          onKeyDown={(event) => handleKeyDown(event, onSearch)}
        />
        {showSearchList && (
          <SelectWrap width={"100%"}>
            {searchResults?.map((item, idx) => (
              <SearchList key={idx} value={item.value}>
                <li
                  onClick={() => {
                    handleItemClick(item.product_name);
                  }}
                >
                  <span> {item.product_name}</span>
                </li>
              </SearchList>
            ))}
          </SelectWrap>
        )}
      </SelectContainer>
    </>
  );
}

export default ProductSearchInputVer2;
