import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  ContentContainer,
  Container,
  DateInputBox,
  Wrapper,
  WhiteBox,
  FlexBox,
  Btn,
  Title,
  Text,
} from "../../Assets/css/commonVer2";
import ChartDoughnut from "../../Components/Chart/ChartDoughnut.js";
import SliderBtn from "../../Components/Slider/SliderBtn";
import Header from "../../Components/Header/HeaderVer2";
import API from "../../Utiles/API";
import { dateToString } from "../../Utiles/Custom";
import { useNavigate } from "react-router";
import Authority from "../../Components/Modal/Authority";
import ProductSlider from "../../Components/Slider/ProductSlider";

// 상품별 매출

function SalesItem() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const date = new Date();
  const year = date.getFullYear(); // 년
  const month = date.getMonth(); // 월
  const day = date.getDate(); // 일
  const [startDate, setStartDate] = useState(new Date(year, month, day - 1));
  const [endDate, setEndDate] = useState(new Date());
  const [Franchise_id] = useState(sessionStorage.getItem("franchise_id"));
  const [sale10, setSale] = useState([]);
  const [count10, setCount] = useState([]);
  const navigate = useNavigate();
  const [pic, setPic] = useState([]);

  useEffect(() => {
    const SalesItem = async () => {
      try {
        const res = await API.get(
          `/storeowner/sales-by-product/${Franchise_id}?filterDatemin=${dateToString(
            startDate
          )}&filterDatemax=${dateToString(endDate)}`
        );
        if (res.data.status) {
          setCount(res.data.result.product_cnt_top10);
          setSale(res.data.result.product_sale_top10);
          setPic(res.data.result.product_sale_classification);
        }
      } catch (error) {
        console.log(error);
      }
    };
    SalesItem();
  }, []);

  const search = async () => {
    try {
      const res = await API.get(
        `/storeowner/sales-by-product/${Franchise_id}?filterDatemin=${dateToString(
          startDate
        )}&filterDatemax=${dateToString(endDate)}`
      );
      if (res.data.status) {
        setCount(res.data.result.product_cnt_top10);
        setSale(res.data.result.product_sale_top10);
        setPic(res.data.result.product_sale_classification);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          {isMobile && <Header title={"상품별 매출"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                <Contents margin={"0 0 10px"}>
                  <FlexBox justify={"space-between"} margin={"0 0 16px"}>
                    <FlexBox direction={"column"} align={"left"}>
                      {!isMobile && (
                        <>
                          <Text size={"26px"} color={"#1E1E1E"} weight={"bold"} align={"left"}>
                            상품별 매출
                          </Text>
                          <Text size={"16px"} align={"left"}>
                            상품별 판매 카테고리 비율, 매출, 판매 수 Top 10 상품을 확인해 보세요.
                          </Text>
                        </>
                      )}
                    </FlexBox>
                    <DateAndBtnBox>
                      <FlexBox gap={"10px"} flexMd={"unset"} flex={"1"}>
                        <DateInputBox style={{ width: "100%" }}>
                          <DatePicker
                            dateFormatCalendar="yyyy년 MM월"
                            dateFormat="yyyy-MM-dd"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={endDate}
                          />
                        </DateInputBox>
                        <DateInputBox style={{ width: "100%" }}>
                          <DatePicker
                            dateFormatCalendar="yyyy년 MM월"
                            dateFormat="yyyy-MM-dd"
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                          />
                        </DateInputBox>
                      </FlexBox>
                      <Btn
                        width={"100%"}
                        widthMd={"63px"}
                        background={"#FF4A4A"}
                        color={"#fff"}
                        weight={"bold"}
                        padding={"8px 0"}
                        paddingMd={"10px 0 9px"}
                        size={"12px"}
                        sizeMd={"14px"}
                        onClick={() => search()}
                      >
                        조 회
                      </Btn>
                      {!isMobile && (
                        <Btn
                          background={"#FF4A4A"}
                          color={"#fff"}
                          weight={"bold"}
                          width={"130px"}
                          padding={"10px 0 9px"}
                          size={"14px"}
                          onClick={() => navigate("/goods/all")}
                        >
                          상품 전체보기 →
                        </Btn>
                      )}
                    </DateAndBtnBox>
                  </FlexBox>
                </Contents>
                {/* 웹 */}
                <div className="d-none d-md-block">
                  <FlexBox gap={"26px"} wrap={"wrap"}>
                    <HalfDiv>
                      <WhiteBox style={{ minHeight: "868px" }}>
                        <FlexBox justify={"space-between"} margin={"0 0 16px"}>
                          <Text size={"18px"} weight={"700"} color={"#2d2d2d"}>
                            판매 카테고리
                          </Text>
                        </FlexBox>
                        <div style={{ maxWidth: "80%", margin: "70px auto 0 " }}>
                          <ChartDoughnut pic={pic} />
                        </div>
                      </WhiteBox>
                    </HalfDiv>
                    {/* <div style={{ width: "50%" }}>
                      <div style={{ maxWidth: "740px" }}>
                        <WhiteBox style={{ minHeight: "868px" }}>
                          <FlexBox justify={"space-between"} margin={"0 0 16px"}>
                            <Text size={"18px"} weight={"700"} color={"#2d2d2d"}>
                              판매 카테고리
                            </Text>
                          </FlexBox>
                          <div style={{ maxWidth: "80%", margin: "70px auto 0 " }}>
                            <ChartDoughnut pic={pic} />
                          </div>
                        </WhiteBox>
                      </div>
                    </div> */}
                    <HalfDiv>
                      <div>
                        <WhiteBox style={{ marginBottom: "20px", minHeight: "424px" }}>
                          <FlexBox align={"center"} justify={"space-between"} margin={"0 0 10px"}>
                            <Title size={"18px"} color={"#2d2d2d"} weight={"700"}>
                              매출 Top 10
                            </Title>
                            <SliderBtn prevClass={"salePrev"} nextClass={"saleNext"} />
                          </FlexBox>
                          {sale10?.length > 0 ? (
                            <div style={{ marginTop: "20px" }}>
                              <ProductSlider
                                perview={3}
                                space={10}
                                naviPrev={"salePrev"}
                                naviNext={"saleNext"}
                                contents={sale10}
                                link={"/goods/view"}
                                styles={{ border: true }}
                              />
                            </div>
                          ) : (
                            <Text size={"14px"} color={"#999"} weight={"500"}>
                              현재 Top10 내용이 없습니다.
                            </Text>
                          )}
                        </WhiteBox>
                      </div>
                      <div>
                        <WhiteBox style={{ minHeight: "424px" }}>
                          <FlexBox align={"center"} justify={"space-between"} margin={"0 0 10px"}>
                            <Title size={"18px"} color={"#2d2d2d"} weight={"700"}>
                              판매 수 Top 10
                            </Title>
                            <SliderBtn prevClass={"countPrev"} nextClass={"countNext"} />
                          </FlexBox>
                          {count10?.length > 0 ? (
                            <div style={{ marginTop: "20px" }}>
                              <ProductSlider
                                perview={3}
                                space={10}
                                naviPrev={"countPrev"}
                                naviNext={"countNext"}
                                contents={count10}
                                link={"/goods/view"}
                                styles={{ border: true }}
                              />
                            </div>
                          ) : (
                            <Text size={"14px"} color={"#999"} weight={"500"}>
                              현재 Top10 내용이 없습니다.
                            </Text>
                          )}
                        </WhiteBox>
                      </div>
                    </HalfDiv>
                    {/* <div style={{ width: "50%" }}>
                      <div>
                        <WhiteBox style={{ marginBottom: "20px", minHeight: "424px" }}>
                          <FlexBox align={"center"} justify={"space-between"} margin={"0 0 10px"}>
                            <Title size={"18px"} color={"#2d2d2d"} weight={"700"}>
                              매출 Top 10
                            </Title>
                            <SliderBtn prevClass={"salePrev"} nextClass={"saleNext"} />
                          </FlexBox>
                          {sale10?.length > 0 ? (
                            <div style={{ marginTop: "20px" }}>
                              <ProductSlider
                                perview={3}
                                space={10}
                                naviPrev={"salePrev"}
                                naviNext={"saleNext"}
                                contents={sale10}
                                link={"/goods/view"}
                                styles={{ border: true }}
                              />
                            </div>
                          ) : (
                            <Text size={"14px"} color={"#999"} weight={"500"}>
                              현재 Top10 내용이 없습니다.
                            </Text>
                          )}
                        </WhiteBox>
                      </div>
                      <div>
                        <WhiteBox style={{ minHeight: "424px" }}>
                          <FlexBox align={"center"} justify={"space-between"} margin={"0 0 10px"}>
                            <Title size={"18px"} color={"#2d2d2d"} weight={"700"}>
                              판매 수 Top 10
                            </Title>
                            <SliderBtn prevClass={"countPrev"} nextClass={"countNext"} />
                          </FlexBox>
                          {count10?.length > 0 ? (
                            <div style={{ marginTop: "20px" }}>
                              <ProductSlider
                                perview={3}
                                space={10}
                                naviPrev={"countPrev"}
                                naviNext={"countNext"}
                                contents={count10}
                                link={"/goods/view"}
                                styles={{ border: true }}
                              />
                            </div>
                          ) : (
                            <Text size={"14px"} color={"#999"} weight={"500"}>
                              현재 Top10 내용이 없습니다.
                            </Text>
                          )}
                        </WhiteBox>
                      </div>
                    </div> */}
                  </FlexBox>
                </div>
                {/* 모바일 */}
                <div className="d-block d-md-none">
                  <WhiteBox>
                    <FlexBox justify={"space-between"} margin={"0 0 16px"}>
                      <Text size={"18px"} weight={"700"} color={"#2d2d2d"}>
                        판매 카테고리
                      </Text>
                    </FlexBox>
                    <div style={{ maxWidth: "90%", margin: "20px auto 0 " }}>
                      <ChartDoughnut pic={pic} />
                    </div>
                  </WhiteBox>
                  <WhiteBox style={{ margin: "20px 0" }}>
                    <FlexBox align={"center"} justify={"space-between"} margin={"0 0 10px"}>
                      <Title size={"18px"} color={"#2d2d2d"} weight={"700"}>
                        매출 Top 10
                      </Title>
                      <SliderBtn prevClass={"salePrev"} nextClass={"saleNext"} />
                    </FlexBox>
                    {sale10?.length > 0 ? (
                      <div style={{ marginTop: "20px" }}>
                        <ProductSlider
                          perview={3}
                          space={10}
                          naviPrev={"salePrev"}
                          naviNext={"saleNext"}
                          contents={sale10}
                          link={"/goods/view"}
                          styles={{ border: true }}
                        />
                      </div>
                    ) : (
                      <Text size={"14px"} color={"#999"} weight={"500"}>
                        현재 Top10 내용이 없습니다.
                      </Text>
                    )}
                  </WhiteBox>
                  <WhiteBox>
                    <FlexBox align={"center"} justify={"space-between"} margin={"0 0 10px"}>
                      <Title size={"18px"} color={"#2d2d2d"} weight={"700"}>
                        판매 수 Top 10
                      </Title>
                      <SliderBtn prevClass={"countPrev"} nextClass={"countNext"} />
                    </FlexBox>
                    {count10?.length > 0 ? (
                      <div style={{ marginTop: "20px" }}>
                        <ProductSlider
                          perview={3}
                          space={10}
                          naviPrev={"countPrev"}
                          naviNext={"countNext"}
                          contents={count10}
                          link={"/goods/view"}
                          styles={{ border: true }}
                        />
                      </div>
                    ) : (
                      <Text size={"14px"} color={"#999"} weight={"500"}>
                        현재 Top10 내용이 없습니다.
                      </Text>
                    )}
                  </WhiteBox>
                </div>
              </Container>
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const Contents = styled.div`
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  background-color: ${(props) => props.background || ""};
  width: ${(props) => props.width || "100%"};
  border-radius: ${(props) => props.radius || "8px"};
`;

const HalfDiv = styled.div`
  width: calc(50% - 13px);
  @media all and (max-width: 1060px) {
    width: 100%;
  }
`;

const DateAndBtnBox = styled(FlexBox)`
  flex-wrap: wrap;
  gap: 10px;

  @media all and (max-width: 1024px) {
    width: 100%;
    justify-content: center;
  }
`;

export default SalesItem;
