import React from "react";
import styled from "styled-components";
import img_prev_on from "../../Assets/images/ico_slide_arr01_on.png";
import img_prev_off from "../../Assets/images/ico_slide_arr01_off.png";
import img_next_on from "../../Assets/images/ico_slide_arr02_on.png";
import img_next_off from "../../Assets/images/ico_slide_arr02_off.png";

function SliderBtn(props) {
  const { prevClass, nextClass } = props;

  return (
    <SlideBtns>
      <SlideBtn className={`prev ${prevClass}`} disabledClass>
        <i></i>
      </SlideBtn>
      <SlideBtn className={`next ${nextClass}`} disabledClass>
        <i></i>
      </SlideBtn>
    </SlideBtns>
  );
}

const SlideBtns = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SlideBtn = styled.a`
  display: block;
  padding: 7px;

  i {
    position: relative;
    display: block;
    width: 24px;
    height: 24px;

    @media (max-width: 1024px) {
      width: 10px;
      height: 10px;
    }
  }

  i::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 12px;
    height: 12px;
    border-top: 2px solid #1e1e1e;
    border-right: 2px solid #1e1e1e;
    transform: rotate(225deg);

    @media (max-width: 1024px) {
      width: 10px;
      height: 10px;
    }
  }

  &.next i::after {
    transform: rotate(45deg);
  }

  // &.prev.swiper-button-disabled i {
  //   border-top: 2px solid #c7c7c7;
  //   border-right: 2px solid #c7c7c7;
  // }

  /* FIXME: 기존 화살표 이미지 -> 테스트 후 이상없으면 삭제*/
  /* &.prev i {
    background: url(${img_prev_on}) no-repeat center center / contain;
  }
  &.prev.swiper-button-disabled i {
    background: url(${img_prev_off}) no-repeat center center / contain;
  }
  &.next i {
    background: url(${img_next_on}) no-repeat center center / contain;
  }
  &.next.swiper-button-disabled i {
    background: url(${img_next_off}) no-repeat center center / contain;
  } */
`;

export default SliderBtn;
