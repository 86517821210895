import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  TableWrap,
  Th,
  Td,
  Btn,
  Wrapper,
  Container,
  ContentContainer,
  FlexBox,
  WhiteBox,
  Title,
  DateInputBox,
  PagerWrap,
  CheckLabel,
  CheckIcon,
  Text,
} from "../../Assets/css/commonVer2";
import { PAYMENT_TYPE } from "../../Utiles/ListData";
import Header from "../../Components/Header/HeaderVer2";
import { AddComma, Xlsx_download, dateToString } from "../../Utiles/Custom";
import Paging from "../../Components/Paging/Paging";
import API from "../../Utiles/API";
import Authority from "../../Components/Modal/Authority";
import { useMediaQuery } from "react-responsive";
import HandleBack from "../../Components/HandleBack";
import InfoModal from "../../Components/Modal/InfoModal";

function SalesPayment() {
  const { handlePopstate } = HandleBack();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [Franchise_id] = useState(sessionStorage.getItem("franchise_id"));

  const date = new Date();
  const year = date.getFullYear(); // 년
  const month = date.getMonth(); // 월
  const day = date.getDate(); // 일
  const [startDate, setStartDate] = useState(new Date(year, month, day - 1));
  const [endDate, setEndDate] = useState(new Date());
  const [type, setType] = useState("card");
  const [cktype, setCktype] = useState("card");
  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPage = Math.ceil(totalCount / 10);

  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");

  const getList = async () => {
    try {
      const res = await API.get(
        `/storeowner/payment-history/${Franchise_id}?type=${type}&pageSize=10&curPage=${currentPage}&filterDatemin=${dateToString(
          startDate,
        )}&filterDatemax=${dateToString(endDate)}`,
      );
      if (res.data.status) {
        setCktype(type);
        setList(res.data.result);
        setTotalCount(res.data.cntAll);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onExcelDown = async () => {
    if (list.length > 0) {
      // api 호출
      const res = await API.get(
        `/storeowner/payment-history/${Franchise_id}?type=${type}&pageSize=99999999&curPage=${currentPage}&filterDatemin=${dateToString(
          startDate,
        )}&filterDatemax=${dateToString(endDate)}`,
      );
      if (res.data.status && res.data.result.length > 0) {
        // 엑셀 포맷으로 변경
        var xlsxTemp = res.data.result.map(function (item) {
          if (item.type === false) {
          }

          if (cktype === "card")
            return {
              승인일시: item.approval_date,
              카드사: item.card_company,
              승인번호: item.approval_num,
              결제금액: item.payment_amount,
              구분: item.gubun !== false ? "승인" : "거절",
            };
          else if (cktype === "cash")
            return {
              승인일시: item.approval_date,
              현금영수증: item.cash_receipts == "indiv" ? "개인" : item.cash_receipts == "corp" ? "법인" : "-",
              결제금액: item.payment_amount,
              구분: item.gubun !== false ? "승인" : "거절",
            };
          else if (cktype === "etc")
            return {
              승인일시: item.approval_date,
              결제방식: item.type,
              승인번호: item.approval_num,
              결제금액: item.payment_amount,
              구분: item.gubun !== false ? "승인" : "거절",
            };
          else return "";
        });
        // 다운로드 실행
        Xlsx_download(
          xlsxTemp,
          type === "card"
            ? `카드내역_${dateToString(startDate)}~${dateToString(endDate)}`
            : type === "cash"
              ? `현금내역_${dateToString(startDate)}~${dateToString(endDate)}`
              : type === "etc"
                ? `기타내역_${dateToString(startDate)}~${dateToString(endDate)}`
                : "",
        );
      } else {
        setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    } else {
      setTextMsg(
        `다운받을 ${
          type === "card" ? "카드결제내역" : type === "cash" ? "현금결제내역" : type === "etc" ? "기타결제내역" : ""
        } 데이터가 없습니다.`,
      );
      setTextModal(true);
    }
  };

  const handleCheck = (checked, type) => {
    if (checked) {
      setType(type);
    }
  };

  const onSearch = () => {
    setCurrentPage(1);
    getList();
  };

  useEffect(() => {
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    getList();
  }, [currentPage]);

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          {isMobile && <Header title={"결제 내역"} />}
          <ContentContainer>
            <Wrapper type={2} style={{ paddingBottom: "150px" }}>
              <Container>
                {!isMobile && (
                  <FlexBox justify={"space-between"} style={{ flex: 1 }} margin={"0 0 10px"}>
                    <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                      결제 내역 🧾
                    </Title>
                  </FlexBox>
                )}
                {isMobile && (
                  <FlexBox margin="0 0 10px" direction="column" gap="15px">
                    <FlexBox width="100%" marginMd={"0 0 0"}>
                      {PAYMENT_TYPE?.map((data) => (
                        <>
                          <CheckLabel htmlFor={data.value}>
                            <input
                              type="checkbox"
                              id={data.value}
                              onChange={(e) => handleCheck(e.target.checked, data.value)}
                              checked={type === data.value ? true : false}
                            />
                            <CheckIcon>
                              <svg viewBox="0 0 24 24">
                                <polyline points="19 7 10 17 5 12" />
                              </svg>
                            </CheckIcon>
                          </CheckLabel>
                          <Text
                            margin={"0 10px"}
                            size={"12px"}
                            sizeMd={"14px"}
                            onClick={(e) => handleCheck(true, data.value)}
                          >
                            {data.text}
                          </Text>
                        </>
                      ))}
                    </FlexBox>
                    <DateAndBtnBox>
                      <FlexBox gap={"10px"} flexMd={"unset"} flex={"1"}>
                        <DateInputBox style={{ width: "100%" }}>
                          <DatePicker
                            dateFormatCalendar="yyyy년 MM월"
                            dateFormat="yyyy-MM-dd"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={endDate}
                          />
                        </DateInputBox>
                        <DateInputBox style={{ width: "100%" }}>
                          <DatePicker
                            dateFormatCalendar="yyyy년 MM월"
                            dateFormat="yyyy-MM-dd"
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                          />
                        </DateInputBox>
                      </FlexBox>
                      <Btn
                        width={"100%"}
                        widthMd={"unset"}
                        color={"#fff"}
                        background={"#FF4A4A"}
                        radius={"6px"}
                        size={"12px"}
                        sizeMd={"14px"}
                        weight={"700"}
                        padding={"9px 19px"}
                        border="unset"
                        margin="2px 0 0"
                        lineHeight={"22px"}
                        style={{ minWidth: "60px" }}
                        onClick={() => onSearch()}
                      >
                        조회
                      </Btn>
                    </DateAndBtnBox>
                  </FlexBox>
                )}

                <WhiteBox margin={"17px 0 0 "} marginMd={"10px 0 0"}>
                  {!isMobile && (
                    <>
                      <FlexBox justify="end">
                        <FlexBox gap="6px" gapMd="13px" justify={"space-evenly"}>
                          <FlexBox width="100%" justify={"space-evenly"} marginMd={"0 0 0"} className={"grid3"}>
                            {PAYMENT_TYPE?.map((data) => (
                              <>
                                <CheckLabel htmlFor={data.value}>
                                  <input
                                    type="checkbox"
                                    id={data.value}
                                    onChange={(e) => handleCheck(e.target.checked, data.value)}
                                    checked={type === data.value ? true : false}
                                  />
                                  <CheckIcon>
                                    <svg viewBox="0 0 24 24">
                                      <polyline points="19 7 10 17 5 12" />
                                    </svg>
                                  </CheckIcon>
                                </CheckLabel>
                                <Text
                                  style={{ cursor: "pointer" }}
                                  size={"14px"}
                                  onClick={(e) => handleCheck(true, data.value)}
                                >
                                  {data.text}
                                </Text>
                              </>
                            ))}
                          </FlexBox>
                          <DateInputBox>
                            <DatePicker
                              dateFormatCalendar="yyyy년 MM월"
                              dateFormat="yyyy-MM-dd"
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              startDate={startDate}
                              endDate={endDate}
                              maxDate={endDate}
                            />
                          </DateInputBox>
                          <DateInputBox>
                            <DatePicker
                              dateFormatCalendar="yyyy년 MM월"
                              dateFormat="yyyy-MM-dd"
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              startDate={startDate}
                              endDate={endDate}
                              minDate={startDate}
                            />
                          </DateInputBox>
                          <Btn
                            color={"#fff"}
                            background={"#FF4A4A"}
                            radius={"6px"}
                            size={"14px"}
                            weight={"700"}
                            padding={"9px 19px"}
                            border="unset"
                            margin="2px 0 0"
                            lineHeight={"22px"}
                            width={"100px"}
                            onClick={() => onSearch()}
                          >
                            조회
                          </Btn>
                        </FlexBox>
                      </FlexBox>
                      <FlexBox justify="end" margin="20px 0 30px">
                        <Btn
                          border={"1px solid #666666"}
                          hoverBorder={"1px solid #C7C7C7"}
                          hoverBackground={"#C7C7C7"}
                          color={"#666666"}
                          hoverColor={"#FFFFFF"}
                          weight={"500"}
                          hoverWeight={"700"}
                          padding={"9px 19px"}
                          size={"14px"}
                          radius={"6px"}
                          lineHeight={"22px"}
                          width={"92px"}
                          onClick={onExcelDown}
                        >
                          엑셀 다운
                        </Btn>
                      </FlexBox>
                    </>
                  )}
                  <TableWrap>
                    <table style={{ width: "100%" }}>
                      <colgroup>
                        <col style={{ width: "22%" }} />
                        {cktype === "card" ? <col /> : cktype === "cash" ? "" : cktype === "etc" ? <col /> : ""}
                        {cktype === "card" ? (
                          <col />
                        ) : cktype === "cash" ? (
                          <col style={{ width: "15.5%" }} />
                        ) : cktype === "etc" ? (
                          <col />
                        ) : (
                          ""
                        )}
                        <col style={{ width: "18.5%" }} />
                        <col style={{ width: "15.5%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>승인일시</Th>
                          {cktype === "card" ? (
                            <>
                              <Th>카드사</Th>
                              <Th>승인번호</Th>
                            </>
                          ) : cktype === "cash" ? (
                            <>
                              <Th>현금영수증</Th>
                            </>
                          ) : cktype === "etc" ? (
                            <>
                              <Th>결제방식</Th>
                              <Th>승인번호</Th>
                            </>
                          ) : (
                            ""
                          )}
                          <Th>결제금액</Th>
                          <Th>구분</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.length > 0 ? (
                          list.map((list, key) => (
                            <tr key={key}>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{list.approval_date}</Td>
                              {cktype === "card" ? (
                                <>
                                  <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{list.card_company}</Td>
                                  <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{list.approval_num}</Td>
                                </>
                              ) : cktype === "cash" ? (
                                <>
                                  <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                    {list.cash_receipts == "indiv"
                                      ? "개인"
                                      : list.cash_receipts == "corp"
                                        ? "법인"
                                        : "-"}
                                  </Td>
                                </>
                              ) : cktype === "etc" ? (
                                <>
                                  <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{list.type}</Td>
                                  <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                    {list.approval_num ? list.approval_num : "-"}
                                  </Td>
                                </>
                              ) : (
                                ""
                              )}
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{AddComma(list.payment_amount)}원</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {list.gubun === 0 ? "승인" : "취소"}
                              </Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={cktype !== "cash" ? 5 : 4}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  {list.length > 0 ? (
                    <PagerWrap>
                      <Paging totalPage={totalPage} limit={10} page={currentPage} setPage={setCurrentPage} />
                    </PagerWrap>
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const DateAndBtnBox = styled(FlexBox)`
  flex-wrap: wrap;
  gap: 10px;

  @media all and (max-width: 1024px) {
    width: 100%;
    justify-content: center;
  }
`;

export default SalesPayment;
