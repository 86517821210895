import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import API from "../../Utiles/API";
import BasicSelect from "../Select/BasicSelect";

// 2차분류 선택 셀렉 박스

function Cate2Select(props) {
  const { idx, cate2Value, setCate2Value, width, widthMd, borderColor, placeholder, disabled } = props;

  const location = useLocation();

  const [cate2Data, setCate2Data] = useState([]);

  const getCate2List = async () => {
    if (idx) {
      API.get(`/admin/productCatrgory2nd/${idx}`).then((res) => {
        if (res.data.status) {
          const transformedArray = res.data.category2nd.map((item) => ({
            value: item.idx,
            text: item.name,
          }));
          if (location.pathname === "/goods/all") {
            transformedArray.unshift({ value: 100, text: "신상품" });
          }
          setCate2Data(transformedArray);
        }
      });
    } else {
      setCate2Data([]);
    }
  };

  useEffect(() => {
    getCate2List();
  }, [idx]);

  return (
    <>
      {cate2Data && (
        <BasicSelect
          width={width}
          widthMd={widthMd}
          data={cate2Data}
          selectedValue={cate2Value}
          setSelectedValue={setCate2Value}
          placeholder={placeholder ?? "상품 분류"}
          canSelectAll={true}
          borderColor={borderColor}
          disabled={disabled}
        />
      )}
    </>
  );
}

export default Cate2Select;
