// 외부 모듈
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

// 내부 모듈
import { ContentContainer, Btn, Container, FlexBox, Text, Title, Wrapper, WhiteBox } from "../../Assets/css/commonVer2";
import ico_plus from "../../Assets/images/ico_plus.svg";
import ico_minus from "../../Assets/images/ico_minus.svg";
import ico_minus_disable from "../../Assets/images/ico_minus_disable.svg";
import img_noimage470 from "../../Assets/images/no_image_470px.jpg";
import HeaderSub from "../../Components/Header/HeaderSub";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import Authority from "../../Components/Modal/Authority";
import API from "../../Utiles/API";
import { AddComma } from "../../Utiles/Custom";
import InfoModal from "../../Components/Modal/InfoModal";
import Slider from "../../Components/Slider/Slider";

// 상품관리 > 전체상품 > 상품 상세

function GoodsView(param) {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();
  const location = useLocation();
  const lcIdx = location.state?.idx;

  const [cnt, setCnt] = useState(1);
  const [basicShipmentQuantity, setBasicShipmentQuantity] = useState(1);
  const [btnactive, setBtnActive] = useState(false);
  const [data, setData] = useState();
  const [idx] = useState(lcIdx);
  const [img, setImg] = useState([]);
  const [saveModal, setSaveModal] = useState(false);
  const [alarmModal, setAlarmModal] = useState(false);
  const [soldOutCartModal, setSoldOutCartModal] = useState(false); // 입고지연_장바구니담기 확인 모달
  const [soldOutOrderModal, setSoldOutOrderModal] = useState(false); // 입고지연_주문하기 확인 모달

  const [textModal, setTextModal] = useState(false); // 모달 창 표시 여부 상태
  const [textMsg, setTextMsg] = useState("");
  const [moveToBackModal, setMoveToBackModal] = useState(false);

  const getData = async () => {
    try {
      const res = await API.get(`/storeowner/product-management/${idx}`);
      if (res.data.status) {
        setData(res.data.results[0]);
        const imgPaths = [];
        for (let i = 1; i <= 3; i++) {
          const imagePath = res.data.results[0][`image_path_${i}`];
          if (imagePath !== null) {
            imgPaths.push({ image_path_1: imagePath });
          }
        }
        if (imgPaths.length === 0) {
          imgPaths.push({ img: img_noimage470 });
        }
        setImg(imgPaths);
        if (res.data.results[0].basic_shipment_quantity) {
          setCnt(res.data.results[0].basic_shipment_quantity);
          setBasicShipmentQuantity(res.data.results[0].basic_shipment_quantity);
        } else {
          setMoveToBackModal(true);
        }
      } else {
        setMoveToBackModal(true);
      }
    } catch (error) {
      setMoveToBackModal(true);
    }
  };

  const shopping = async () => {
    API.post(`/storeowner/shopping-basket`, {
      user_id: sessionStorage.getItem("user_id"),
      product_id: idx,
      quantity: cnt,
    }).then(function (res) {
      if (res.data.status) {
        setSoldOutCartModal(false);
        setBtnActive(true);
      } else {
        setSoldOutCartModal(false);
        setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    });
  };

  const restock = async () => {
    API.post(`/storeowner/restock`, {
      franchise_id: sessionStorage.getItem("franchise_id"),
      product_id: idx,
    })
      .then(function (res) {
        if (res.data.status) {
          setTextMsg("재입고 알림 신청이 완료되었습니다.");
          setTextModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(function (error) {
        setTextMsg(error.response.data.msg || "E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const cntUpClick = () => {
    setCnt((state) => state + basicShipmentQuantity);
  };

  const cntDownClick = () => {
    if (cnt - basicShipmentQuantity > 0) {
      setCnt((state) => state - basicShipmentQuantity);
    }
  };

  const putCartClick = () => {
    if (cnt > 0) {
      if (data?.sale_status === "missing") {
        setSoldOutCartModal(true);
      } else {
        setSaveModal(true);
      }
    } else {
      setTextMsg("주문수량을 입력해주세요.");
      setTextModal(true);
    }
  };

  const keepShppingClick = () => {
    setBtnActive(false);
  };

  const goShopClick = () => {
    navigate("/goods/shopping");
  };

  const onSubmit = (e) => {
    if (e === 2) {
      setSaveModal(false);
      shopping();
    } else if (e === 3) {
      setAlarmModal(false);
      restock();
    }
  };

  function onClickMoveToOrder() {
    if (data?.sale_status === "missing") {
      setSoldOutOrderModal(true);
    } else {
      moveToOrder();
    }
  }

  function moveToOrder() {
    if (cnt > 0) {
      const newList = [];
      newList.push({
        product_id: idx,
        quantity: cnt,
        product_name: data.product_name,
      });

      navigate("/goods/order", {
        state: {
          itemList: newList,
          price: cnt * data.shipping_price,
          idxList: [],
        },
      });
    } else {
      setTextMsg("주문수량을 입력해주세요.");
      setTextModal(true);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          <HeaderSub title={"상품 상세"} />
          <ContentContainer>
            <Wrapper type={2} pbM={"176px"}>
              <Container>
                {!isMobile ? (
                  <WhiteBox margin={"0 0"}>
                    <Title size={"26px"} color={"#1E1E1E"} weight={"700"} padding={"0 0 16px 0"}>
                      상품상세
                    </Title>
                    <GoodsBox align={"flex-start"} gap={"20px"} wrap={"wrap"}>
                      <SliderWrap>
                        <Slider space={10} perview={1} pagination={"pager"} contents={img} />
                      </SliderWrap>
                      <ContWrap>
                        <ContBox>
                          <FlexBox width="100%" justify={"space-between"}>
                            <FlexBox direction="column" align={"start"}>
                              <Text color={"#666"} align={"left"}>
                                {data ? data.brand : ""}
                              </Text>
                              <FlexBox>
                                <Text color={"#1E1E1E"} align={"left"} weight={"700"} size={"16px"} sizeMd={"26px"}>
                                  {data ? data.product_name : ""}
                                </Text>
                                {data?.sale_status === "missing" && (
                                  <Btn
                                    color={"#fff"}
                                    background={"#C7C7C7"}
                                    size={"14px"}
                                    radius="unset"
                                    padding={"4px 7px"}
                                    border="unset"
                                    margin="0 10px"
                                  >
                                    입고지연
                                  </Btn>
                                )}
                                {data?.sale_status === "extinction" && (
                                  <Btn
                                    color={"#fff"}
                                    background={"#C7C7C7"}
                                    size={"14px"}
                                    radius="unset"
                                    padding={"4px 7px"}
                                    border="unset"
                                    margin="0 10px"
                                  >
                                    단종
                                  </Btn>
                                )}
                              </FlexBox>
                            </FlexBox>
                            <FlexBox>
                              {data?.sale_status === "missing" && (
                                <Btn
                                  size="14px"
                                  color={"#fff"}
                                  weight={"700"}
                                  padding="9px 19px"
                                  background="#FF4A4A"
                                  onClick={() => setAlarmModal(true)}
                                >
                                  재입고 알림
                                </Btn>
                              )}
                            </FlexBox>
                          </FlexBox>

                          <Text
                            color={"#666"}
                            align={"left"}
                            size={"14px"}
                            sizeMd={"18px"}
                            padding={"13px 0 0"}
                            paddingMd={"0"}
                          >
                            바코드 번호: {data ? data.barcode_num : ""}
                          </Text>
                          <FlexBox
                            direction="column"
                            width="100%"
                            padding="16px 0"
                            paddingMd="30px"
                            margin="20px 0"
                            style={{ borderTop: "1px solid #C7C7C7", borderBottom: "1px solid #C7C7C7" }}
                          >
                            <FlexBox justify={"space-between"} width="100%" margin="0 0 12px">
                              <Text color={"#1E1E1E"} size={"14px"} sizeMd={"18px"}>
                                공급가
                              </Text>
                              <Text family="Montserrat" color={"#1E1E1E"} size={"14px"} sizeMd={"20px"}>
                                {data ? AddComma(data.shipping_price) : 0}원
                              </Text>
                            </FlexBox>
                            <FlexBox justify={"space-between"} width="100%" margin="0 0 12px">
                              <Text color={"#1E1E1E"} size={"14px"} sizeMd={"18px"}>
                                소비자가
                              </Text>
                              <Text family="Montserrat" color={"#1E1E1E"} size={"14px"} sizeMd={"20px"}>
                                {data ? AddComma(data.consumer_price) : 0}원
                              </Text>
                            </FlexBox>
                            <FlexBox justify={"space-between"} width="100%" margin="0 0 12px">
                              <Text color={"#1E1E1E"} size={"14px"} sizeMd={"18px"}>
                                마진율
                              </Text>
                              <Text family="Montserrat" color={"#1E1E1E"} size={"14px"} sizeMd={"20px"}>
                                {data ? (1 - data.shipping_price / data.consumer_price).toFixed(2) * 100 + "%" : "0%"}
                              </Text>
                            </FlexBox>
                            <FlexBox justify={"space-between"} width="100%" margin="0 0 12px">
                              <Text color={"#1E1E1E"} size={"14px"} sizeMd={"18px"}>
                                기본 상품 단위
                              </Text>
                              <Text family="Montserrat" color={"#1E1E1E"} size={"14px"} sizeMd={"20px"}>
                                {data?.order_unit ? data.order_unit : "-"}
                                {data?.order_unit !== "개" && data?.product_quantity
                                  ? `(${data.product_quantity}개입)`
                                  : ""}
                              </Text>
                            </FlexBox>
                            <FlexBox justify={"space-between"} width="100%">
                              <Text color={"#1E1E1E"} size={"14px"} sizeMd={"18px"}>
                                기본 출하 수량
                              </Text>
                              <Text family="Montserrat" color={"#1E1E1E"} size={"14px"} sizeMd={"20px"}>
                                {data?.basic_shipment_quantity ? data.basic_shipment_quantity : 1}
                                {data?.order_unit ? data.order_unit : "-"}
                              </Text>
                            </FlexBox>
                          </FlexBox>
                          <FlexBox justify={"space-between"} style={{ margin: "24px 0 14px" }}>
                            <FlexBox
                              style={{
                                border: "1px solid #c0c0c0",
                                borderRadius: "6px",
                              }}
                            >
                              <FlexBox
                                style={{
                                  borderRight: "1px solid #c0c0c0",
                                  padding: isMobile ? "6px" : "8px",
                                }}
                              >
                                <img
                                  src={cnt - basicShipmentQuantity > 0 ? ico_minus : ico_minus_disable}
                                  onClick={cntDownClick}
                                />
                              </FlexBox>
                              <Text
                                padding="0 10px"
                                family="Montserrat"
                                weight="700"
                                color={"#1E1E1E"}
                                size={"14px"}
                                sizeMd={"18px"}
                              >
                                {cnt}
                              </Text>
                              <FlexBox
                                style={{
                                  borderLeft: "1px solid #c0c0c0",
                                  padding: isMobile ? "6px" : "8px",
                                }}
                              >
                                <img src={ico_plus} onClick={cntUpClick} />
                              </FlexBox>
                            </FlexBox>
                            <FlexBox justify={"space-between"} direction="column" align="end">
                              <Text color={"#666"}>총 상품 금액</Text>
                              <FlexBox gap={"20px"}>
                                <Text
                                  family="Montserrat"
                                  size={"16px"}
                                  sizeMd={"26px"}
                                  color={"#1E1E1E"}
                                  weight={"700"}
                                >
                                  {data ? AddComma(cnt * data.shipping_price) : 0}원
                                </Text>
                              </FlexBox>
                            </FlexBox>
                          </FlexBox>
                        </ContBox>
                        <div className="m_fixed_btn">
                          <FlexBox justify={"space-between"} className="d-flex d-md-none">
                            <FlexBox
                              style={{
                                border: "1px solid #c0c0c0",
                                borderRadius: "6px",
                              }}
                            >
                              <FlexBox
                                style={{
                                  borderRight: "1px solid #c0c0c0",
                                  padding: "6px",
                                }}
                              >
                                <img
                                  src={cnt - basicShipmentQuantity > 0 ? ico_minus : ico_minus_disable}
                                  onClick={cntDownClick}
                                />
                              </FlexBox>
                              <Text padding="0 10px" family="Montserrat" weight="700" color={"#1E1E1E"}>
                                {cnt}
                              </Text>
                              <FlexBox
                                style={{
                                  borderLeft: "1px solid #c0c0c0",
                                  padding: "6px",
                                }}
                              >
                                <img src={ico_plus} onClick={cntUpClick} />
                              </FlexBox>
                            </FlexBox>
                            <FlexBox gap={"8px"} direction="column">
                              <Text size={"12px"} color={"#666"}>
                                총 상품 금액
                              </Text>
                              <Text family="Montserrat" size={"18px"} color={"#FF4A4A"} weight={"700"}>
                                {data ? AddComma(cnt * data.shipping_price) : 0}원
                              </Text>
                            </FlexBox>
                          </FlexBox>
                          {data?.sale_status === "extinction" || data?.sale_status === "missing" ? (
                            <Btn
                              width={"100%"}
                              size={"18px"}
                              color={"#fff"}
                              weight={"700"}
                              background={"#C7C7C7"}
                              radius={"8px"}
                              margin={"1rem 0 0"}
                              marginMd={"30px 0 0"}
                              border="unset"
                              disabled
                            >
                              {data?.sale_status === "extinction" ? "단종" : "입고지연"}
                            </Btn>
                          ) : (
                            <FlexBox gap={"8px"} gapMd={"10px"} className="mt-4 md:mt-20">
                              <CartBtnWrap style={{ flex: 1 }} className={btnactive ? "active" : ""}>
                                <div className="btn_wrap">
                                  <Btn
                                    size={"14px"}
                                    sizeMd={"20px"}
                                    border="unset"
                                    hoverBackground="unset"
                                    hoverColor="#FF4A4A"
                                    width={"100%"}
                                    padding={"9px 0"}
                                    paddingMd={"16px 0"}
                                    onClick={putCartClick}
                                  >
                                    장바구니 담기
                                  </Btn>
                                  <div>
                                    <ul>
                                      <li>
                                        <Btn
                                          size={"14px"}
                                          sizeMd={"20px"}
                                          border="unset"
                                          hoverBackground="unset"
                                          hoverColor="#FF4A4A"
                                          width={"100%"}
                                          padding={"9px 0"}
                                          paddingMd={"16px 0"}
                                          onClick={goShopClick}
                                        >
                                          장바구니로 이동
                                        </Btn>
                                      </li>
                                      <li>
                                        <Btn
                                          size={"14px"}
                                          sizeMd={"20px"}
                                          border="unset"
                                          hoverBackground="unset"
                                          color="#1E1E1E"
                                          hoverColor="#1E1E1E"
                                          width={"100%"}
                                          padding={"9px 0"}
                                          paddingMd={"16px 0"}
                                          onClick={keepShppingClick}
                                        >
                                          계속 쇼핑하기
                                        </Btn>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </CartBtnWrap>
                              <Btn
                                size={"14px"}
                                sizeMd={"20px"}
                                padding={"9px 0"}
                                paddingMd={"16px 0"}
                                color={"#fff"}
                                weight={"700"}
                                background={"#FF4A4A"}
                                style={{ flex: 1 }}
                                onClick={onClickMoveToOrder}
                              >
                                주문하기
                              </Btn>
                            </FlexBox>
                          )}
                        </div>
                      </ContWrap>
                    </GoodsBox>
                  </WhiteBox>
                ) : (
                  <>
                    <SliderWrap>
                      <Slider space={10} perview={1} pagination={"pager"} contents={img} />
                    </SliderWrap>
                    <ContWrap style={{ margin: "20px 0" }}>
                      <ContBox>
                        <FlexBox width="100%" justify={"space-between"}>
                          <FlexBox direction="column" align={"start"}>
                            <Text color={"#666"} align={"left"} size="12px">
                              {data ? data.brand : ""}
                            </Text>
                            <FlexBox padding={"11px 0 0"}>
                              <Text color={"#1E1E1E"} align={"left"} weight={"700"} size={"16px"}>
                                {data ? data.product_name : ""}
                              </Text>
                            </FlexBox>
                          </FlexBox>
                          <FlexBox>
                            {data?.sale_status === "missing" && (
                              <Btn
                                size="14px"
                                color={"#fff"}
                                weight={"700"}
                                padding="9px 19px"
                                background="#FF4A4A"
                                onClick={() => setAlarmModal(true)}
                              >
                                재입고 알림
                              </Btn>
                            )}
                          </FlexBox>
                        </FlexBox>

                        <Text color={"#666"} align={"left"} padding={"11px 0 0"}>
                          바코드 번호: {data ? data.barcode_num : ""}
                        </Text>
                        {data?.sale_status === "missing" && (
                          <Btn
                            color={"#fff"}
                            background={"#C7C7C7"}
                            size={"14px"}
                            radius="unset"
                            padding={"4px 7px"}
                            border="unset"
                            margin="11px 0 0"
                          >
                            입고지연
                          </Btn>
                        )}
                        {data?.sale_status === "extinction" && (
                          <Btn
                            color={"#fff"}
                            background={"#C7C7C7"}
                            size={"14px"}
                            radius="unset"
                            padding={"4px 7px"}
                            border="unset"
                            margin="11px 0 0"
                          >
                            단종
                          </Btn>
                        )}
                        <FlexBox
                          direction="column"
                          width="100%"
                          padding="16px 0"
                          margin="20px 0"
                          style={{ borderTop: "1px solid #C7C7C7", borderBottom: "1px solid #C7C7C7" }}
                        >
                          <FlexBox justify={"space-between"} width="100%" margin="0 0 12px">
                            <Text color={"#1E1E1E"}>공급가</Text>
                            <Text family="Montserrat" color={"#1E1E1E"}>
                              {data ? AddComma(data.shipping_price) : 0}원
                            </Text>
                          </FlexBox>
                          <FlexBox justify={"space-between"} width="100%" margin="0 0 12px">
                            <Text color={"#1E1E1E"}>소비자가</Text>
                            <Text family="Montserrat" color={"#1E1E1E"}>
                              {data ? AddComma(data.consumer_price) : 0}원
                            </Text>
                          </FlexBox>
                          <FlexBox justify={"space-between"} width="100%" margin="0 0 12px">
                            <Text color={"#1E1E1E"}>마진율</Text>
                            <Text family="Montserrat" color={"#1E1E1E"}>
                              {data ? (1 - data.shipping_price / data.consumer_price).toFixed(2) * 100 + "%" : "0%"}
                            </Text>
                          </FlexBox>
                          <FlexBox justify={"space-between"} width="100%" margin="0 0 12px">
                            <Text color={"#1E1E1E"}>기본 상품 단위</Text>
                            <Text family="Montserrat" color={"#1E1E1E"}>
                              {data?.order_unit ? data.order_unit : "-"}
                              {data?.order_unit !== "개" && data?.product_quantity
                                ? `(${data.product_quantity}개입)`
                                : ""}
                            </Text>
                          </FlexBox>
                          <FlexBox justify={"space-between"} width="100%">
                            <Text color={"#1E1E1E"}>기본 출하 수량</Text>
                            <Text family="Montserrat" color={"#1E1E1E"}>
                              {data?.basic_shipment_quantity ? data.basic_shipment_quantity : 1}
                            </Text>
                          </FlexBox>
                        </FlexBox>
                      </ContBox>
                      <div className="m_fixed_btn">
                        <FlexBox justify={"space-between"} className="d-flex d-md-none">
                          <FlexBox
                            style={{
                              border: "1px solid #c0c0c0",
                              borderRadius: "6px",
                            }}
                          >
                            <FlexBox
                              style={{
                                borderRight: "1px solid #c0c0c0",
                                padding: "6px",
                              }}
                            >
                              <img
                                src={cnt - basicShipmentQuantity > 0 ? ico_minus : ico_minus_disable}
                                onClick={cntDownClick}
                              />
                            </FlexBox>
                            <Text padding="0 10px" family="Montserrat" weight="700" color={"#1E1E1E"}>
                              {cnt}
                            </Text>
                            <FlexBox
                              style={{
                                borderLeft: "1px solid #c0c0c0",
                                padding: "6px",
                              }}
                            >
                              <img src={ico_plus} onClick={cntUpClick} />
                            </FlexBox>
                          </FlexBox>
                          <FlexBox gap={"8px"} direction="column">
                            <Text size={"12px"} color={"#666"}>
                              총 상품 금액
                            </Text>
                            <Text family="Montserrat" size={"18px"} color={"#FF4A4A"} weight={"700"}>
                              {data ? AddComma(cnt * data.shipping_price) : 0}원
                            </Text>
                          </FlexBox>
                        </FlexBox>
                        {data?.sale_status === "extinction" || data?.sale_status === "missing" ? (
                          <Btn
                            width={"100%"}
                            size={"18px"}
                            color={"#fff"}
                            weight={"700"}
                            background={"#C7C7C7"}
                            radius={"8px"}
                            margin={"1rem 0 0"}
                            marginMd={"30px 0 0"}
                            border="unset"
                            disabled
                          >
                            {data?.sale_status === "extinction" ? "단종" : "입고지연"}
                          </Btn>
                        ) : (
                          <FlexBox gap={"8px"} gapMd={"10px"} className="mt-4 md:mt-20">
                            <CartBtnWrap style={{ flex: 1 }} className={btnactive ? "active" : ""}>
                              <div className="btn_wrap">
                                <Btn
                                  size={"14px"}
                                  sizeMd={"20px"}
                                  border="unset"
                                  hoverBackground="unset"
                                  hoverColor="#FF4A4A"
                                  width={"100%"}
                                  padding={"9px 0"}
                                  paddingMd={"16px 0"}
                                  onClick={putCartClick}
                                >
                                  장바구니 담기
                                </Btn>
                                <div>
                                  <ul>
                                    <li>
                                      <Btn
                                        size={"14px"}
                                        sizeMd={"20px"}
                                        border="unset"
                                        hoverBackground="unset"
                                        hoverColor="#FF4A4A"
                                        width={"100%"}
                                        padding={"9px 0"}
                                        paddingMd={"16px 0"}
                                        onClick={goShopClick}
                                      >
                                        장바구니로 이동
                                      </Btn>
                                    </li>
                                    <li>
                                      <Btn
                                        size={"14px"}
                                        sizeMd={"20px"}
                                        border="unset"
                                        hoverBackground="unset"
                                        color="#1E1E1E"
                                        hoverColor="#1E1E1E"
                                        width={"100%"}
                                        padding={"9px 0"}
                                        paddingMd={"16px 0"}
                                        onClick={keepShppingClick}
                                      >
                                        계속 쇼핑하기
                                      </Btn>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </CartBtnWrap>
                            <Btn
                              size={"14px"}
                              sizeMd={"20px"}
                              padding={"9px 0"}
                              paddingMd={"16px 0"}
                              color={"#fff"}
                              weight={"700"}
                              background={"#FF4A4A"}
                              style={{ flex: 1 }}
                              onClick={onClickMoveToOrder}
                            >
                              주문하기
                            </Btn>
                          </FlexBox>
                        )}
                      </div>
                    </ContWrap>
                  </>
                )}
                <Title size={"16px"} sizeMd={"26px"} color={"#1E1E1E"} weight={"700"} padding={"0 0 16px 0"}>
                  상품 설명
                </Title>
                <LineBox>{data ? data.details_description : ""}</LineBox>
              </Container>
            </Wrapper>
          </ContentContainer>

          <ConfirmModal
            modal={saveModal}
            setModal={setSaveModal}
            onSubmit={() => onSubmit(2)}
            description={"장바구니에 \n담겠습니까?"}
          />
          <ConfirmModal
            modal={soldOutCartModal}
            setModal={setSoldOutCartModal}
            onSubmit={() => onSubmit(2)}
            description={
              "주문 후 영업일 기준 5일 이상\n(주말 및 공휴일 제외) \n지연되는 상품입니다.\n장바구니에 담겠습니까?"
            }
          />
          <ConfirmModal
            modal={soldOutOrderModal}
            setModal={setSoldOutOrderModal}
            onSubmit={moveToOrder}
            description={
              "주문 후 영업일 기준 5일 이상\n(주말 및 공휴일 제외) \n지연되는 상품입니다.\n주문하시겠습니까?"
            }
          />
          <ConfirmModal
            modal={alarmModal}
            setModal={setAlarmModal}
            onSubmit={() => onSubmit(3)}
            description={"입고지연 중인 상품입니다.\n재입고 알림을 신청하시겠습니까?"}
          />
          <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
          <InfoModal
            modal={moveToBackModal}
            setModal={setMoveToBackModal}
            description={"상품정보를 가져오는데 문제가 발생했습니다.\n잠시 후 다시 시도해 주세요."}
            mode="goBack"
          />
        </>
      )}
    </>
  );
}

const GoodsBox = styled(FlexBox)`
  @media all and (max-width: 1201px) {
    flex-direction: column;
  }
`;

const ContWrap = styled.div`
  flex: 1;
  @media all and (max-width: 1201px) {
    width: 100%;
  }
  @media all and (max-width: 1024px) {
    flex: none;
    width: 100%;
  }
`;

const ContBox = styled.div`
  padding: 20px;

  @media all and (max-width: 1024px) {
    border: 0;
    padding: 0;
  }
`;

const CartBtnWrap = styled.div`
  position: relative;
  height: 60px;

  .btn_wrap {
    position: absolute;
    width: 100%;
    border: 1px solid #ff4a4a;
    border-radius: 6px;
    background-color: #fff;

    div {
      position: relative;
      overflow: hidden;
      height: 0;
      transition: height 0.5s;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 15px;
        width: calc(100% - 30px);
        height: 1px;
        background-color: #ff4a4a;
      }
    }
  }
  &.active {
    .btn_wrap div {
      height: 112px;
    }
  }

  @media all and (max-width: 1024px) {
    height: 37px;

    .btn_wrap {
      div {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s;

        &:before {
          display: none;
        }
        ul {
          position: relative;
          bottom: -100%;
          width: 100%;
          padding: 10px 16px 20px;
          border-radius: 8px 8px 0 0;
          background-color: #fff;
          transition: bottom 0.3s;

          li {
            border-bottom: 1px solid #eaeaea;
          }
        }
      }
    }

    &.active {
      .btn_wrap div {
        height: 100%;
        opacity: 1;
        visibility: visible;
        ul {
          bottom: 0;
        }
      }
    }
  }
`;

const LineBox = styled.div`
  padding: 60px 10px;
  border: 1px solid #f2f2f2;
  background: #f2f2f2;
  border-radius: 10px;
  overflow: hidden;

  @media all and (max-width: 1024px) {
    padding: 40px 5px;
  }
`;

const SliderWrap = styled.div`
  width: calc(48% - 10px);
  max-width: 520px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media all and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
  }
`;

export default GoodsView;
