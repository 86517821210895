import React, { useState } from "react";
import styled from "styled-components";
import close from "../../Assets/images/close.png";
import ProductSearchInputVer2 from "../SearchInput/ProductSearchInputVer2";
import { Text } from "../../Assets/css/common";
import { Btn, FlexBox } from "../../Assets/css/commonVer2";
import Cate2Select from "../Select/Cate2Select";
import ConfirmModal from "./ConfirmModalVer2";
import InfoModal from "./InfoModal";

export default function SelectCate2Modal(props) {
  const { modal, setModal, title, selectValue, setSelectValue, saveModal, setSaveModal, onSubmit = () => {} } = props;

  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");

  const handleModalSave = () => {
    if (selectValue && selectValue !== undefined) {
      setSaveModal(true);
    } else {
      setTextMsg("2차 분류를 선택해 주세요.");
      setTextModal(true);
    }
  };

  const onClose = () => {
    setModal(false);
  };

  return (
    <>
      {modal && !saveModal && !textModal ? (
        <ModalBg>
          <ModalConts>
            <CancelBtn onClick={onClose}>
              <img src={close} />
            </CancelBtn>
            <Text
              color={"#1E1E1E"}
              size={"16px"}
              weight={"500"}
              lineHeight={"22px"}
              style={{
                whiteSpace: "pre-wrap",
                marginBottom: "10px",
              }}
            >
              {title || "상품분류 수정"}
            </Text>

            <Cate2Select idx={1} width={"100%"} cate2Value={selectValue} setCate2Value={setSelectValue} />

            <FlexBox margin={"16px 0 0"} marginMd={"22px 0 0"}>
              <Btn
                background={"#f2f2f2"}
                color={"#666"}
                weight={"500"}
                margin={"0 10px 0 0"}
                border={"none"}
                size={"12px"}
                sizeMd={"14px"}
                padding={"9px 19px"}
                paddingMd={"9px 19px"}
                lineHeight={"22px"}
                onClick={onClose}
              >
                취소
              </Btn>
              <Btn
                background={"#FF4A4A"}
                color={"#fff"}
                weight={"700"}
                border={"none"}
                size={"12px"}
                sizeMd={"14px"}
                padding={"9px 19px"}
                paddingMd={"9px 19px"}
                lineHeight={"22px"}
                onClick={handleModalSave}
              >
                저장
              </Btn>
            </FlexBox>
          </ModalConts>
        </ModalBg>
      ) : (
        ""
      )}
      {!textModal && (
        <ConfirmModal
          modal={saveModal}
          setModal={setSaveModal}
          onSubmit={onSubmit}
          description={"상품분류를 수정하시겠습니까?"}
        />
      )}
      <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
    </>
  );
}

const ModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

const ModalConts = styled.div`
  background-color: #fff;
  max-width: 360px;
  width: 100%;
  padding: 44px 30px 26px;
  border-radius: 8px;
  position: relative;

  @media all and (max-width: 1024px) {
    padding: 40px 20px 30px;
  }
`;

const CancelBtn = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;

  @media all and (max-width: 1024px) {
    top: 12px;
    right: 12px;
  }
`;
