import React from "react";
import { Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./slideVer2.css";
import { Text, FlexBox } from "../../Assets/css/commonVer2";
import { AddComma } from "../../Utiles/Custom";

function InfoSlider(props) {
  const { space, perview, pagination, contents, lists, breaks } = props;

  return (
    <Swiper
      modules={[Pagination, A11y, Autoplay]}
      pagination={{
        clickable: true,
        el: `.${pagination}`,
        bulletClass: `swiper-pagination-bullet`,
      }}
      autoplay={false}
      loop={true}
      breakpoints={breaks}
      className="textSwiper"
    >
      <>
        {contents?.map((e, i) => (
          <SwiperSlide key={i}>
            <div
              style={{
                width: "calc(100% - 20px)",
                background: "#fff",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                margin: "10px",
                padding: e.exceptionTitle ? "19px 16px" : "31px 16px",
              }}s
            >
              <FlexBox justify={"space-between"} align={"center"}>
                {/* 가맹마스터 > 대쉬보드, 가맹점매출관리 페이지 > 총매출과 본사매출 타이틀 */}
                {e.exceptionTitle ? (
                  <FlexBox direction={"column"} align={"left"}>
                    <Text color={"#1E1E1E"} size={"12px"} weight={"400"} lineHeight={"20px"}>
                      {e.exceptionTitle[0]}
                    </Text>
                    <Text color={"#1E1E1E"} size={"12px"} weight={"400"} lineHeight={"20px"} align={"left"}>
                      {e.exceptionTitle[1]}
                    </Text>
                  </FlexBox>
                ) : (
                  // 원래 슬라이더 타이틀
                  <Text color={"#1E1E1E"} size={"12px"} weight={"400"} lineHeight={"20px"}>
                    {e.title}
                  </Text>
                )}
                {/* 가맹마스터 > 대쉬보드, 가맹점매출관리 페이지 > 총매출과 본사매출액(unit 없이 원 */}
                {e.exceptionPrice ? (
                  <FlexBox direction={"column"} align={"right"}>
                    <Text
                      size={"16px"}
                      color={"#FF4A4A"}
                      weight={"bold"}
                      lineHeight={"24px"}
                      align={"right"}
                      style={{
                        fontFamily: "Montserrat",
                      }}
                    >
                      {e.exceptionPrice[0]}원
                    </Text>
                    <Text
                      size={"16px"}
                      color={"#FF4A4A"}
                      weight={"bold"}
                      lineHeight={"24px"}
                      
                      style={{
                        fontFamily: "Montserrat",
                      }}
                    >
                      {e.exceptionPrice[1]}원
                    </Text>
                  </FlexBox>
                ) : (
                  <FlexBox>
                    <Text
                      size={"16px"}
                      color={"#FF4A4A"}
                      weight={"bold"}
                      lineHeight={"24px"}
                      style={{
                        fontFamily: "Montserrat",
                      }}
                    >
                      {e.price}
                    </Text>
                    <Text color={"#FF4A4A"} size={"16px"} weight={"400"}>
                      {e.unit}
                    </Text>
                  </FlexBox>
                )}
              </FlexBox>
            </div>
          </SwiperSlide>
        ))}
      </>
      <>
        {lists?.map((e, i) => (
          <SwiperSlide key={i}>
            {e?.map((item, idx) => (
              <div
                style={{
                  width: "calc(100% - 20px)",
                  background: "#fff",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                  margin: "10px",
                  padding: "12px",
                }}
              >
                <Text weight={"500"} color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"16px"} margin={"0 0 12px"}>
                  <span style={{ color: "#dd1212", paddingRight: "5px" }}>{idx + 1 + i * 2}.</span>
                  {item.product_name}
                </Text>
                <FlexBox justify={"space-between"} padding={"0 8px"}>
                  <Text size={"14px"} sizeMd={"16px"} color={"#1E1E1E"}>
                    신청 건수 :
                  </Text>
                  <Text size={"14px"} sizeMd={"16px"} color={"#FF4A4A"}>
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      {item.count}
                    </span>
                    건
                  </Text>
                </FlexBox>
                <FlexBox justify={"space-between"} margin={"8px 0 0"} padding={"0 8px"}>
                  <Text size={"14px"} sizeMd={"16px"} color={"#1E1E1E"}>
                    상품 금액 :
                  </Text>
                  <Text size={"14px"} sizeMd={"16px"} color={"#1E1E1E"}>
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      {AddComma(item.shipping_price)}
                    </span>
                    원
                  </Text>
                </FlexBox>
              </div>
            ))}
          </SwiperSlide>
        ))}
      </>
      <div className={`swiper-pagination ${pagination}`}></div>
    </Swiper>
  );
}

export default InfoSlider;
