// 외부 모듈
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

// 내부 모듈
import {
  Container,
  FlexBox,
  Title,
  Wrapper,
  Th,
  Td,
  Text,
  TableWrap,
  LeftArea,
  RightArea,
  Btn,
  ContentContainer,
  WhiteBox,
  OrderAddrBox,
} from "../../Assets/css/commonVer2";

import { DELIVERY_STATUS } from "../../Utiles/ListData";
import { changeDeliveryState } from "../../Utiles";
import "../../Assets/css/DatePicker.css";
import ico_close from "../../Assets/images/ico_close.png";
import HeaderSub from "../../Components/Header/HeaderSub";
import Authority from "../../Components/Modal/Authority";
import Loading from "../../Components/Loading";
import API from "../../Utiles/API";
import { AddComma } from "../../Utiles/Custom";
import InfoModal from "../../Components/Modal/InfoModal";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import AddProductModal from "../../Components/Modal/AddProductModal";
import InputModal from "../../Components/Modal/InputModal";

// 상품마스터 > 주문내역관리 > 주문관리 상세

export default function OrderManagementView() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const location = useLocation();

  const [franchise_id] = useState(location.state?.franchise_id);
  const [order_date] = useState(location.state?.order_date);

  const [originalList, setOriginalList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [deliveryInfo, setDeliveryInfo] = useState({});
  const [orderInfo, setOrderInfo] = useState({});

  // 안내모달창, 로딩중
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [loading, setLoading] = useState(false);

  //페이지 상태를 나타내는 값
  const [isBasicMode, setIsBasicMode] = useState(true); // 일반 상태
  const [isEditMode, setIsEditMode] = useState(false); // 수량 수정 상태
  const [isAddingProductMode, setIsisAddingProductMode] = useState(false); // 상품 추가 상태

  // 일반 상태 택배비
  const basicShippingFee = 5000; // 택배비 금액 상관없이 5,000원으로 통일

  // 상품 추가 시 사용되는 변수들
  const [addingProductmodal, setAddingProductModal] = useState(false);
  const [addingProductList, setAddingProductList] = useState([]);
  const [barcodeNum, setBarcodeNum] = useState("");
  const [saveAddingProductModal, setSaveAddingProductModal] = useState(false);

  // 수정 시에 총 결제 금액의 업데이트를 위한 변수
  const [totalPriceOnEdit, setTotalPriceOnEdit] = useState(0);
  const [totalPriceOnAddProduct, setTotalPriceOnAddProduct] = useState(0);
  const [editModal, setEditModal] = useState(false);

  // 주문 취소
  const [cancelOrderModal, setCancelOrderModal] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");

  // 배송 정보 변경
  const [tempDeliveryInfo, setTempDeliveryInfo] = useState([]);
  const [onDeliveryModal, setOnDeliveryModal] = useState(false);

  const [refreshModal, setRefreshModal] = useState(false);
  const [infoModalMode, setInfoModalMode] = useState("");

  // 주문내역 불러오는 API 호출
  const getData = async () => {
    try {
      const res = await API.get(`/admin/order-list/detail?franchise_id=${franchise_id}&order_date=${order_date}`);
      if (res.data.status) {
        const originalData = JSON.parse(JSON.stringify(res.data.results.table)); // 참조 끊어 내기 위해 deep clone
        setOrderList(res.data.results.table); // 업데이트할 리스트
        setOriginalList(originalData); // 원래 리스트 (변하지않음)
        setTempDeliveryInfo(res.data.results.delivery);
        setDeliveryInfo(res.data.results.delivery);
        setOrderStatus(res.data.results.delivery.status);
        setOrderInfo(res.data.results.order_info);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const filteredOrder = (idx) => {
    return [...originalList]?.filter((item) => item.order_history_id === idx);
  };
  // 주문 취소 api
  const cancelOrder = async () => {
    await API.delete(`/admin/order-list/detail?franchise_id=${franchise_id}&order_date=${order_date}`)
      .then((res) => {
        if (res.data.status) {
          setInfoModalMode("goBack");
          setTextMsg("주문이 정상적으로 취소되었습니다.");
          setTextModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  // 수량 수정 api
  function editOrder() {
    // 페이지 상태 원래대로 변경
    setIsBasicMode(true);
    setIsEditMode(false);
    setIsisAddingProductMode(false);

    // api 전송을 위해 구조 변경
    const data = orderList.map((item) => ({
      order_history_id: item.order_history_id,
      quantity: item.quantity,
    }));

    setLoading(true); // 로딩 시작
    // api 호출
    API.put(`/admin/order-list/detail`, {
      data: data,
    })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          window.location.reload();
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setLoading(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  }

  // 상품검색 후 임시 목록에 추가 api
  const addProductTempList = (item) => {
    const newItem = {
      quantity: 1,
      product_id: item.product_id,
      p_name: item.product_name,
      shipping_price: item.shipping_price,
      barcode_num: item.barcode_num,
    };

    const isDuplicate = addingProductList.some((item) => item.product_id == newItem.product_id);

    const isAlreadyExist = orderList.some((item) => item.product_id == newItem.product_id);
    if (isAlreadyExist) {
      setTextMsg("기존 주문내역에 있는 상품입니다.");
      setTextModal(true);
      setSaveAddingProductModal(false);
    } else if (isDuplicate) {
      setTextMsg("이미 추가된 상품입니다.");
      setTextModal(true);
      setSaveAddingProductModal(false);
    } else {
      setAddingProductList((items) => [...items, newItem]);
      setSaveAddingProductModal(false);
    }
    setBarcodeNum("");
  };

  // 상품 추가하기 API 호출
  const putAddingProduct = async () => {
    if (addingProductList.length > 0) {
      setLoading(true); // 로딩 시작
      try {
        const modifiedList = addingProductList.map((item) => {
          return {
            quantity: item.quantity,
            product_id: item.product_id,
          };
        });
        const res = await API.post(`/admin/order-list/detail/product`, {
          franchise_id: deliveryInfo.franchise_id,
          order_date: order_date,
          data: modifiedList,
        });
        setLoading(false);
        if (res.data.status) {
          window.location.reload();
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      } catch (error) {
        setLoading(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    } else {
      setTextMsg("입력된 상품이 없습니다.");
      setTextModal(true);
    }
  };

  const changeDeliveryInfo = async () => {
    if (
      tempDeliveryInfo &&
      tempDeliveryInfo.courier_company !== null &&
      tempDeliveryInfo.tracking_number !== null &&
      tempDeliveryInfo.courier_company.trim() !== "" &&
      tempDeliveryInfo.tracking_number.trim() !== ""
    ) {
      changeDeliveryState(order_date, franchise_id, tempDeliveryInfo.tracking_number, tempDeliveryInfo.courier_company)
        .then((res) => {
          if (res.status) {
            setTextMsg("배송정보가 수정되었습니다.");
            setRefreshModal(true);
          } else {
            setTextMsg(res.msg || "잠시후 다시 시도해주세요.");
            setTextModal(true);
          }
        })
        .catch(() => {
          setTextMsg("E01\n잠시후 다시 시도해주세요.");
          setTextModal(true);
        });
    } else {
      alert("내용을 입력해주세요.");
    }
  };

  // 여러 확인 버튼에 대한 이벤트
  const onSubmit = (e) => {
    if (e == 1) {
      // 주문 취소
      setCancelOrderModal(false);
      cancelOrder();
    } else if (e == 2) {
      // 배송 정보 수정
      changeDeliveryInfo();
    }
  };

  // 상품 수량 수정
  function onChangeValue(target) {
    const array = [...orderList];
    array.map((item) => {
      const originalQuantity = filteredOrder(item.order_history_id);
      if (item.order_history_id == target.id && target.value >= 0 && target.value <= originalQuantity[0].quantity) {
        item.quantity = parseInt(target.value);
      }
    });
    setOrderList([...array]);
  }

  // 상품 추가를 위한 아이템 수량 수정
  function onChangeAddingProductValue(target) {
    const array = [...addingProductList];
    array.map((item) => {
      if (item.product_id == target.id && target.value >= 0) {
        item.quantity = parseInt(target.value);
      }
    });
    setAddingProductList([...array]);
  }

  // 추가하려고 했던 상품 목록에서 삭제
  const removeAddingProduct = (id) => {
    const newItems = addingProductList.filter((item) => item.product_id !== id);
    setAddingProductList(newItems);
  };

  // 상품 검색 시 모달에서확인 버튼 클릭
  const addProduct = () => {
    if (barcodeNum === undefined || barcodeNum === "") {
      return false;
    } else {
      setSaveAddingProductModal(true);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    // orderList가 변경될 때마다 totalPriceOnEdit를 다시 계산
    let newPrice = 0;
    orderList.map((item) => {
      newPrice += item.shipping_price * item.quantity;
    });
    setTotalPriceOnEdit(newPrice);
  }, [orderList]);

  useEffect(() => {
    // orderList가 변경될 때마다 totalPriceOnEdit를 다시 계산
    let newPrice = orderInfo.product_amount;
    addingProductList.map((item) => {
      newPrice += item.shipping_price * item.quantity;
    });
    setTotalPriceOnAddProduct(newPrice);
  }, [addingProductList, orderInfo]);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {loading ? <Loading /> : null}
          <HeaderSub title={"주문 관리"} />
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                <FlexBox
                  align={"start"}
                  justify={"space-between"}
                  wrap={"wrap"}
                  gap={"10px"}
                  direction={"column"}
                  directionMd={"row"}
                >
                  <Title size={"16px"} sizeMd={"26px"} color={"#1E1E1E"} weight={"700"}>
                    주문일자 : {order_date ? order_date : ""}
                  </Title>
                </FlexBox>
                {/* 모바일 버전 */}
                {isMobile && (
                  <FlexBox margin={"10px 0 0"} gap={"10px"} direction={"column"} width={"100%"}>
                    <Text
                      width={"100%"}
                      widthMd={"unset"}
                      color={"#FF4A4A"}
                      size={"16px"}
                      padding={"10px 29px 10px 27px"}
                      background={"rgba(255, 116, 64, 0.10)"}
                      radius={"8px"}
                      letterSpacing={"-0.32px"}
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      품목 : <span style={{ fontWeight: "700" }}>{AddComma(orderList ? orderList?.length : "")}개</span>
                    </Text>
                    <Text
                      width={"100%"}
                      widthMd={"unset"}
                      color={"#FF4A4A"}
                      size={"16px"}
                      padding={"10px 29px 10px 27px"}
                      background={"rgba(255, 116, 64, 0.10)"}
                      radius={"8px"}
                      letterSpacing={"-0.32px"}
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      금액 :{" "}
                      <span style={{ fontWeight: "700" }}>
                        {AddComma(orderInfo ? orderInfo?.product_amount : "")}원
                      </span>
                    </Text>
                  </FlexBox>
                )}
                <WhiteBox>
                  <FlexBox
                    wrap={"wrap"}
                    justify={"space-between"}
                    margin={"0 0 10px"}
                    marginMd={"0 0 30px"}
                    gap={"10px"}
                  >
                    {/* 웹버전 */}
                    {!isMobile && (
                      <FlexBox gap={"10px"} justify={"start"} width={"100%"} widthMd={"auto"}>
                        <Text
                          width={"100%"}
                          widthMd={"unset"}
                          color={"#FF4A4A"}
                          size={"16px"}
                          padding={"10px 29px 10px 27px"}
                          background={"rgba(255, 116, 64, 0.10)"}
                          radius={"8px"}
                          letterSpacing={"-0.32px"}
                          style={{
                            flexShrink: 0,
                          }}
                        >
                          품목 :{" "}
                          <span style={{ fontWeight: "700" }}>{AddComma(orderList ? orderList?.length : "")}개</span>
                        </Text>
                        <Text
                          width={"100%"}
                          widthMd={"unset"}
                          color={"#FF4A4A"}
                          size={"16px"}
                          padding={"10px 29px 10px 27px"}
                          background={"rgba(255, 116, 64, 0.10)"}
                          radius={"8px"}
                          letterSpacing={"-0.32px"}
                          style={{
                            flexShrink: 0,
                          }}
                        >
                          금액 :{" "}
                          <span style={{ fontWeight: "700" }}>
                            {AddComma(orderInfo ? orderInfo?.product_amount : "")}원
                          </span>
                        </Text>
                      </FlexBox>
                    )}

                    <FlexBox
                      gap={"10px"}
                      justify={"flex-end"}
                      // width={"100%"}
                      widthMd={"auto"}
                      margin={"0 0 0 auto"}
                    >
                      {/* 일반 상태 일때 */}
                      {isBasicMode && (
                        <>
                          {orderStatus === "before-delivery" && (
                            <>
                              <Btn
                                margin={"0 auto"}
                                size={"14px"}
                                padding={"8px 18px"}
                                lineHeight={"22px"}
                                onClick={() => {
                                  setIsBasicMode(false);
                                  setIsEditMode(true);
                                  setIsisAddingProductMode(false);
                                }}
                              >
                                수량 수정
                              </Btn>
                              {/* <Btn
                                margin={"0 auto"}
                                size={"14px"}
                                padding={"8px 18px"}
                                lineHeight={"22px"}
                                onClick={() => {
                                  setIsBasicMode(false);
                                  setIsEditMode(false);
                                  setIsisAddingProductMode(true);
                                }}
                              >
                                상품 추가
                              </Btn> */}
                              <Btn
                                margin={"0 auto"}
                                size={"14px"}
                                padding={"8px 18px"}
                                lineHeight={"22px"}
                                onClick={() => setCancelOrderModal(true)}
                              >
                                주문 취소
                              </Btn>
                            </>
                          )}
                        </>
                      )}
                      {/* 수량 수정 상태 일때 */}
                      {isEditMode && (
                        <>
                          <Btn
                            size={"14px"}
                            padding={"8px 18px"}
                            border={"1px solid #666"}
                            color={"#666"}
                            hoverBackground={"#C7C7C7"}
                            hoverColor={"#fff"}
                            hoverBorder={"transparent"}
                            lineHeight={"22px"}
                            hoverWeight={"700"}
                            style={{ flexShrink: 0 }}
                            onClick={() => {
                              setIsBasicMode(true);
                              setIsEditMode(false);
                              setIsisAddingProductMode(false);
                              window.location.reload();
                            }}
                          >
                            취소
                          </Btn>
                          <Btn
                            color={"#fff"}
                            background={"#FF4A4A"}
                            size={"14px"}
                            weight={"700"}
                            width={"100%"}
                            widthMd={"unset"}
                            padding={"8px 18px"}
                            lineHeight={"22px"}
                            onClick={() => setEditModal(true)}
                          >
                            저장
                          </Btn>
                        </>
                      )}
                      {/* 상품 추가 상태 일때 */}
                      {isAddingProductMode && (
                        <>
                          <Btn
                            size={"14px"}
                            padding={"8px 18px"}
                            border={"1px solid #666"}
                            color={"#666"}
                            hoverBackground={"#C7C7C7"}
                            hoverColor={"#fff"}
                            hoverBorder={"transparent"}
                            lineHeight={"22px"}
                            hoverWeight={"700"}
                            style={{ flexShrink: 0 }}
                            onClick={() => {
                              setIsBasicMode(true);
                              setIsEditMode(false);
                              setIsisAddingProductMode(false);
                              setBarcodeNum("");
                              setAddingProductList([]);
                            }}
                          >
                            취소
                          </Btn>
                          <Btn
                            margin={"0 auto"}
                            size={"14px"}
                            padding={"8px 18px"}
                            lineHeight={"22px"}
                            style={{ flexShrink: 0 }}
                            onClick={() => setAddingProductModal(true)}
                          >
                            상품 검색
                          </Btn>
                          <Btn
                            color={"#fff"}
                            background={"#FF4A4A"}
                            size={"14px"}
                            weight={"700"}
                            width={"100%"}
                            widthMd={"unset"}
                            padding={"8px 18px"}
                            lineHeight={"22px"}
                            onClick={() => putAddingProduct()}
                          >
                            저장
                          </Btn>
                        </>
                      )}
                    </FlexBox>
                  </FlexBox>

                  <TableWrap>
                    <table style={{ width: "100%", minWidth: "1200px" }}>
                      <colgroup>
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "8.5%" }} />
                        <col style={{ width: "12%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "10.5%" }} />
                        <col style={{ width: "14%" }} />
                        <col style={{ width: "14%" }} />
                        <col style={{ width: "9.5%" }} />
                      </colgroup>
                      <thead>
                        <tr style={{ borderRight: "1px solid #c0c0c0" }}>
                          <Th>No</Th>
                          <Th>주문번호</Th>
                          <Th>바코드번호</Th>
                          <Th>상품명</Th>
                          <Th>수량</Th>
                          <Th>공급가</Th>
                          <Th>총 금액</Th>
                          <Th>비고</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderList?.length > 0 ? (
                          orderList?.map((item, key) => {
                            const originData = filteredOrder(item.order_history_id); // 수량을 바꾸면 orderList가 업데이트되기때문에 추가
                            return (
                              <tr key={item.order_history_id}>
                                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{key + 1}</Td>
                                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.order_id}</Td>
                                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.barcode_num}</Td>
                                <Td align={"left"} style={{ borderBottom: "1px solid #F2F2F2" }}>
                                  {item.p_name}
                                </Td>
                                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                  {isEditMode ? (
                                    <input
                                      type="number"
                                      align={"center"}
                                      id={item.order_history_id}
                                      onChange={(e) => {
                                        onChangeValue(e.target);
                                      }}
                                      max={originData[0]?.quantity}
                                      value={item.quantity || 0}
                                      style={{ background: "#F2F2F2", borderRadius: "8px", padding: "5px" }}
                                    />
                                  ) : item.quantity === 0 || item.quantity === null ? (
                                    "-"
                                  ) : (
                                    item.quantity
                                  )}
                                </Td>
                                <Td align={"right"} style={{ borderBottom: "1px solid #F2F2F2" }}>
                                  {AddComma(item.shipping_price)}원
                                </Td>
                                <Td align={"right"} style={{ borderBottom: "1px solid #F2F2F2" }}>
                                  {AddComma(item.shipping_price * item.quantity)}원
                                </Td>
                                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                  {item.status
                                    ? item.status == "add"
                                      ? "상품 추가"
                                      : item.status == "edit"
                                        ? "수량 수정"
                                        : "-"
                                    : "-"}
                                </Td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              colSpan={7}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                        {addingProductList.length > 0 &&
                          addingProductList?.map((item) => (
                            <tr key={item.product_id}>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                <Btn
                                  border={"none"}
                                  background={"transeparent"}
                                  style={{ margin: "0 auto" }}
                                  onClick={() => removeAddingProduct(item.product_id)}
                                >
                                  <DeleteImg src={ico_close} />
                                </Btn>
                              </Td>
                              <Td align={"left"} style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {item.p_name}
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.barcode_num}</Td>

                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {isAddingProductMode ? (
                                  <input
                                    type="number"
                                    align={"center"}
                                    id={item.product_id}
                                    onChange={(e) => onChangeAddingProductValue(e.target)}
                                    value={item.quantity}
                                    style={{ width: "40px" }}
                                  />
                                ) : (
                                  item.quantity
                                )}
                              </Td>
                              <Td align={"right"} style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {AddComma(item.shipping_price)}원
                              </Td>
                              <Td align={"right"} style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {AddComma(item.shipping_price * item.quantity)}원
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{"-"}</Td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </TableWrap>
                </WhiteBox>

                <FlexBox align={"flex-start"} wrap={"wrap"} wrapMd={"flex-wrap"} gapMd={"10px"} gapLg={"20px"}>
                  <LeftArea>
                    <WhiteBox margin={"0"} marginMd={"0"} paddingMd="28px 30px">
                      <Title
                        color={"#1E1E1E"}
                        weight={"700"}
                        size={"16px"}
                        sizeMd={"18px"}
                        style={{
                          paddingBottom: "10px",
                          borderBottom: "1px solid #666",
                        }}
                      >
                        배송지 정보
                      </Title>
                      <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          가맹점명
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          {deliveryInfo ? deliveryInfo.franchise_name : ""}
                        </Text>
                      </OrderAddrBox>
                      <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          휴대폰번호
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          {deliveryInfo ? deliveryInfo.tel : ""}
                        </Text>
                      </OrderAddrBox>
                      <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          주소
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          {deliveryInfo ? deliveryInfo.address : ""}
                        </Text>
                      </OrderAddrBox>
                    </WhiteBox>
                    {deliveryInfo ? (
                      <>
                        <WhiteBox paddingMd="28px 30px">
                          <FlexBox
                            style={{
                              paddingBottom: "10px",
                              borderBottom: "1px solid #666",
                            }}
                            justify={"space-between"}
                          >
                            <Title color={"#1E1E1E"} weight={"700"} size={"16px"} sizeMd={"18px"}>
                              배송 정보
                            </Title>
                            {orderStatus === "on-delivery" && (
                              <Btn
                                size={"14px"}
                                padding={"8px 18px"}
                                lineHeight={"22px"}
                                onClick={() => {
                                  setOnDeliveryModal(true);
                                }}
                              >
                                배송 정보 수정
                              </Btn>
                            )}
                          </FlexBox>

                          <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                            <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                              배송상태
                            </Text>
                            <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                              {orderStatus ? DELIVERY_STATUS.find((item) => item.value === orderStatus).text : "-"}
                            </Text>
                          </OrderAddrBox>
                          {orderStatus !== "before-delivery" && orderStatus !== "cancel-order" && (
                            <>
                              <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                                <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                                  택배사
                                </Text>
                                <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                                  {deliveryInfo.courier_company ? deliveryInfo.courier_company : ""}
                                </Text>
                              </OrderAddrBox>
                              <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                                <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                                  운송장번호
                                </Text>
                                <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                                  {deliveryInfo.tracking_number ? deliveryInfo.tracking_number : ""}
                                </Text>
                              </OrderAddrBox>
                            </>
                          )}
                        </WhiteBox>
                      </>
                    ) : (
                      ""
                    )}
                  </LeftArea>

                  <RightArea>
                    <WhiteBox margin={"0"} marginMd={"0"} paddingMd="28px 30px">
                      <Title
                        color={"#1E1E1E"}
                        weight={"700"}
                        size={"16px"}
                        sizeMd={"18px"}
                        style={{
                          paddingBottom: "10px",
                          borderBottom: "1px solid #666",
                        }}
                      >
                        결제 정보
                      </Title>
                      <Text
                        color={"#1E1E1E"}
                        weight={"500"}
                        align={"left"}
                        padding={"20px 0 0"}
                        size={"14px"}
                        sizeMd={"18px"}
                      >
                        총 결제 금액
                      </Text>
                      <WhiteBox marginMd={"20px 0 0"} paddingMd="20px">
                        <FlexBox justify={"space-between"}>
                          <Text color={"#1E1E1E"} weight={"500"} size={"14px"} sizeMd={"18px"}>
                            상품 가격
                          </Text>
                          <Text color={"#1E1E1E"} size={"14px"} sizeMd={"18px"}>
                            {isBasicMode
                              ? AddComma(orderInfo.product_amount)
                              : isEditMode
                                ? AddComma(totalPriceOnEdit)
                                : AddComma(totalPriceOnAddProduct)}
                            원
                          </Text>
                        </FlexBox>
                        <FlexBox justify={"space-between"} padding={"20px 0 0"}>
                          <Text color={"#1E1E1E"} weight={"500"} size={"14px"} sizeMd={"18px"}>
                            {orderStatus == "before-delivery" ? "예상 택배비" : "택배비"}
                          </Text>
                          <Text color={"#1E1E1E"} size={"14px"} sizeMd={"18px"}>
                            5,000 원
                          </Text>
                        </FlexBox>
                        <FlexBox
                          justify={"space-between"}
                          margin={"20px 0 0"}
                          padding={"20px 0 0"}
                          style={{
                            borderTop: "1px solid #969696",
                          }}
                        >
                          <Text color={"#1E1E1E"} weight={"500"} size={"14px"} sizeMd={"18px"}>
                            결제 금액
                          </Text>
                          <Text color={"#1E1E1E"} weight={"700"} size={"14px"} sizeMd={"18px"}>
                            {isBasicMode
                              ? AddComma(orderInfo.product_amount + basicShippingFee)
                              : isEditMode
                                ? AddComma(totalPriceOnEdit + 5000)
                                : AddComma(totalPriceOnAddProduct + 5000)}
                            원
                          </Text>
                        </FlexBox>
                      </WhiteBox>
                    </WhiteBox>
                  </RightArea>
                </FlexBox>
              </Container>

              <AddProductModal
                modal={addingProductmodal}
                setModal={setAddingProductModal}
                onSubmit={(item) => addProductTempList(item)}
              />
              <ConfirmModal
                modal={cancelOrderModal}
                setModal={setCancelOrderModal}
                onSubmit={() => onSubmit(1)}
                description={"주문을 취소하시겠습니까?\n취소된 주문은 복구할 수 없습니다."}
              />
              <ConfirmModal
                modal={editModal}
                setModal={setEditModal}
                onSubmit={() => editOrder()}
                description={"수량을 수정하시겠습니까?\n수정하면 이전 수량으로 \n되돌릴 수 없습니다."}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} mode={infoModalMode} />
              <InputModal
                modal={onDeliveryModal}
                setModal={setOnDeliveryModal}
                deliveryInfo={deliveryInfo}
                tempDeliveryInfo={tempDeliveryInfo}
                setTempDeliveryInfo={setTempDeliveryInfo}
                title={"배송 정보 수정"}
                mode={"delivery"}
                onSubmit={() => onSubmit(2)}
              />
              <InfoModal modal={refreshModal} setModal={setRefreshModal} description={textMsg} mode={"refresh"} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const DeleteImg = styled.img`
  width: 24px;

  @media all and (max-width: 1024px) {
    width: 16px;
  }
`;
