import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import {
  TableWrap,
  Th,
  Td,
  Btn,
  CheckLabel,
  DisableCheckIcon,
  CheckIcon,
  SearchInput,
  Wrapper,
  Container,
  ContentContainer,
  FlexBox,
  Text,
  WhiteBox,
  Title,
} from "../../Assets/css/commonVer2";
import Header from "../../Components/Header/HeaderVer2";
import { Xlsx_download, AddComma } from "../../Utiles/Custom";
import { DIRECT_SELFGOODS_STATUS_LIST, SEARCH_TYPE_LIST, UNIT_10_TO_30_LIST } from "../../Utiles/ListData";
import API from "../../Utiles/API";
import Authority from "../../Components/Modal/Authority";
import Pagination from "../../Components/Paging/Pagination";
import Cate2Select from "../../Components/Select/Cate2Select";
import BasicSelect from "../../Components/Select/BasicSelect";
import InfoModal from "../../Components/Modal/InfoModal";
import ProductSearchInputVer2 from "../../Components/SearchInput/ProductSearchInputVer2";
import HandleBack from "../../Components/HandleBack";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";

// 상품관리 > 자점매입 상품관리 (예외_상품 등록 즉시 판매)

function DirectSelfGoods() {
  const navigate = useNavigate();
  const [franchise_id] = useState(sessionStorage.getItem("franchise_id"));
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const { handlePopstate } = HandleBack();

  // 등록, 수정을 다녀온 경우 저장 해놓은 파라미터 정보
  const parsedDirectSelfGoodsPageState = sessionStorage.getItem("directSelfGoodsPageState")
    ? JSON.parse(sessionStorage.getItem("directSelfGoodsPageState"))
    : {};

  // 필터 관련
  const [cate2Value, setCate2Value] = useState(
    parsedDirectSelfGoodsPageState
      ? parsedDirectSelfGoodsPageState?.cate2Value
        ? parsedDirectSelfGoodsPageState?.cate2Value
        : ""
      : "",
  );
  const [statusValue, setStatusValue] = useState(
    parsedDirectSelfGoodsPageState
      ? parsedDirectSelfGoodsPageState?.statusValue
        ? parsedDirectSelfGoodsPageState?.statusValue
        : ""
      : "",
  );

  // 검색 관련
  const [filter, setFilter] = useState(
    parsedDirectSelfGoodsPageState
      ? parsedDirectSelfGoodsPageState?.filter
        ? parsedDirectSelfGoodsPageState?.filter
        : "product_name"
      : "product_name",
  );
  const [inputValue, setInputValue] = useState(
    parsedDirectSelfGoodsPageState
      ? parsedDirectSelfGoodsPageState?.inputValue
        ? parsedDirectSelfGoodsPageState?.inputValue
        : ""
      : "",
  );
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchList, setShowSearchList] = useState(false);

  // 페이징 관련
  const [pagination, setPagination] = useState("");
  const [pageSize, setPageSize] = useState(
    parsedDirectSelfGoodsPageState
      ? parsedDirectSelfGoodsPageState?.pageSize
        ? parsedDirectSelfGoodsPageState?.pageSize
        : 10
      : 10,
  );
  const [currentPage, setCurrentPage] = useState(
    parsedDirectSelfGoodsPageState
      ? parsedDirectSelfGoodsPageState?.currentPage
        ? parsedDirectSelfGoodsPageState?.currentPage
        : 1
      : 1,
  );

  // 데이터 관련
  const [data, setData] = useState([]);
  const [selfGoodsCnt, setSelfGoodsCnt] = useState(0);
  const [checkItems, setCheckItems] = useState([]);

  const [hoverRow, setHoverRow] = useState(null);

  // 모달들
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [refreshModal, setRefreshModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [reasonModal, setReasonModal] = useState(false);
  const [reasonModalTxts] = useState({
    title: "",
    text: "",
    reason: "",
  });

  // 등록, 수정 이동 시 저장되야 하는 현재 페이지에 대한 정보
  let directSelfGoodsPageState = {
    currentPage: currentPage,
    pageSize: pageSize,
    cate2Value: cate2Value,
    statusValue: statusValue,
    filter: filter,
    inputValue: inputValue,
  };

  // 자점매입 상품 조회
  const getDirectSelfGoodsList = async () => {
    let api_link = `/slot/user/product?page=${currentPage}&unit=${pageSize}&block=10&franchise_id=${franchise_id}&gubun=storeowner&type=pass`;
    if (cate2Value) api_link += `&category2nd_filter=${cate2Value}`;
    if (inputValue) api_link += `&search_type=${filter}&search_word=${inputValue}`;
    // if (statusValue) api_link += `&slot_status_filter=${statusValue}`;

    await API.get(api_link).then((res) => {
      if (res.data.status) {
        setSelfGoodsCnt(res.data.cntAll);
        setPagination(res.data.pagination);
        setData(res.data.results);
      }
    });
  };

  // 상품 삭제
  const deleteSelfGoods = async () => {
    let api_link = `/slot/product/exception?franchise_id=${franchise_id}&request_list=${checkItems}`;

    await API.delete(api_link).then((res) => {
      if (res.data.status) {
        setDeleteModal(false);
        setRefreshModal(true);
      } else {
        setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    });
  };

  // 판매중지 사유 확인
  const getReason = async (idx) => {
    await API.get(`/slot/refuse-reason?slot_id=${idx}`).then((res) => {
      if (res.data.status) {
        reasonModalTxts.reason = res.data.results.refuse_reason || "잠시후 다시 시도해주세요.";
        reasonModalTxts.title = "판매가 중지되었습니다.";
        reasonModalTxts.text = "판매 중지 사유는 아래와 같습니다. 상품 수정 후 다시 검수 요청을 해주세요.";
        setReasonModal(true);
      } else {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    });
  };

  const onExcelDown = async () => {
    // 데이터 유무
    if (data.length > 0) {
      // api 호출
      let api_link = `/slot/user/product?page=1&unit=99999999&block=10&franchise_id=${franchise_id}&gubun=storeowner&type=pass`;
      if (cate2Value) api_link += `&category2nd_filter=${cate2Value}`;
      if (inputValue) api_link += `&search_type=${filter}&search_word=${inputValue}`;
      // if (statusValue) api_link += `&slot_status_filter=${statusValue}`;

      const res = await API.get(api_link);
      if (res.data.status && res.data.results.length > 0) {
        // 엑셀 포맷으로 변경
        var xlsxTemp = res.data.results.map(function (item) {
          return {
            상품분류: item.category2nd_name,
            바코드번호: item.product_barcode_num,
            상품명: item.product_product_name,
            소비자가: item.product_consumer_price,
          };
        });
        // 다운로드 실행
        Xlsx_download(xlsxTemp, `자점매입상품`);
      } else {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    } else {
      setTextMsg("다운받을 데이터가 없습니다.");
      setTextModal(true);
    }
  };

  const moveToWrite = async () => {
    let jsonString = JSON.stringify(directSelfGoodsPageState);
    sessionStorage.setItem("directSelfGoodsPageState", jsonString);
    navigate("/goods/direct/selfgoods/write");
  };

  const moveToEdit = async () => {
    let jsonString = JSON.stringify(directSelfGoodsPageState);
    sessionStorage.setItem("directSelfGoodsPageState", jsonString);
    navigate("/goods/selfgoods/edit", {
      state: { slot_id: checkItems[0] },
    });
  };

  const movePage = (e) => {
    setCurrentPage(e);
    setCheckItems([]);
  };

  const onSearch = () => {
    setSearchResults([]);
    setShowSearchList(false);
    setCurrentPage(1);
    getDirectSelfGoodsList();
  };

  const onChangeChx = (checked, id) => {
    if (checked) {
      setCheckItems((prev) => [...prev, id]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  const onChangeAllChk = (checked) => {
    if (checked) {
      const chkArr = data.map((item) => item.idx);
      setCheckItems(chkArr);
    } else {
      setCheckItems([]);
    }
  };

  useEffect(() => {
    setInputValue("");
  }, [filter]);

  useEffect(() => {
    getDirectSelfGoodsList();
  }, [currentPage, pageSize]);

  useEffect(() => {
    sessionStorage.removeItem("directSelfGoodsPageState");
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          {isMobile && <Header title={"자점매입 상품관리"} />}
          <ContentContainer>
            <Wrapper>
              <Container>
                <FlexBox justify={"space-between"} style={{ flex: 1 }}>
                  {!isMobile && (
                    <>
                      <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                        자점매입 상품관리 📋
                      </Title>
                      <FlexBox />
                    </>
                  )}
                  <FlexBox justify={"end"} gap={"8px"} gapMd={"15px"} width={"100%"} widthMd={"unset"}>
                    <Btn
                      color={"#fff"}
                      weight={"700"}
                      radius={"6px"}
                      padding={"9px 19px"}
                      background={"#FF4A4A"}
                      lineHeight={"22px"}
                      size={"14px"}
                      width={"100%"}
                      widthMd={"unset"}
                      onClick={() => moveToWrite()}
                    >
                      상품 등록
                    </Btn>
                    <Btn
                      color={"#fff"}
                      weight={"700"}
                      radius={"6px"}
                      padding={"9px 19px"}
                      background={"#FF4A4A"}
                      lineHeight={"22px"}
                      size={"14px"}
                      width={"100%"}
                      widthMd={"unset"}
                      onClick={() => navigate("/goods/direct/selfgoods/bulk/write")}
                    >
                      대량 등록
                    </Btn>
                  </FlexBox>
                </FlexBox>
                <WhiteBox>
                  <FlexBox
                    justify={"end"}
                    gap={"10px"}
                    gapMd={"15px"}
                    margin={"0 0 "}
                    marginMd={"0 0 20px 0 "}
                    wrap={"wrap"}
                  >
                    <Cate2Select widthMd={"31%"} idx={1} cate2Value={cate2Value} setCate2Value={setCate2Value} />
                    {/* <BasicSelect
                      widthMd={"31%"}
                      data={DIRECT_SELFGOODS_STATUS_LIST}
                      selectedValue={statusValue}
                      setSelectedValue={setStatusValue}
                      placeholder={"상태 전체"}
                      canSelectAll={true}
                    /> */}
                    <BasicSelect
                      widthMd={"31%"}
                      data={SEARCH_TYPE_LIST}
                      selectedValue={filter}
                      setSelectedValue={setFilter}
                      canSelectAll={false}
                    />
                    {!isMobile && (
                      <FlexBox gap={"10px"}>
                        {filter === "barcode_num" ? (
                          <SearchInput
                            placeholder="바코드번호를 입력해주세요."
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                          />
                        ) : (
                          <ProductSearchInputVer2
                            showSearchList={showSearchList}
                            setShowSearchList={setShowSearchList}
                            searchResults={searchResults}
                            setSearchResults={setSearchResults}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            onSearch={onSearch}
                            cate1value={2}
                            franchiseId={franchise_id}
                          />
                        )}
                        <Btn
                          color={"#fff"}
                          background={"#FF4A4A"}
                          radius={"6px"}
                          size={"14px"}
                          weight={"700"}
                          padding={"9px 19px"}
                          maxHeight={"40px"}
                          style={{ lineHeight: "22px" }}
                          onClick={onSearch}
                        >
                          검 색
                        </Btn>
                      </FlexBox>
                    )}
                  </FlexBox>
                  {isMobile && (
                    <FlexBox gap={"10px"} margin={"10px 0"}>
                      {filter === "barcode_num" ? (
                        <SearchInput
                          placeholder="바코드번호를 입력해주세요."
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                        />
                      ) : (
                        <ProductSearchInputVer2
                          showSearchList={showSearchList}
                          setShowSearchList={setShowSearchList}
                          searchResults={searchResults}
                          setSearchResults={setSearchResults}
                          inputValue={inputValue}
                          setInputValue={setInputValue}
                          onSearch={onSearch}
                          cate1value={2}
                          franchiseId={franchise_id}
                        />
                      )}
                      <Btn
                        color={"#fff"}
                        background={"#FF4A4A"}
                        radius={"6px"}
                        size={"14px"}
                        weight={"700"}
                        width={"80px"}
                        padding={"9px 19px"}
                        lineHeight={"22px"}
                        onClick={onSearch}
                      >
                        검 색
                      </Btn>
                    </FlexBox>
                  )}

                  <FlexBox
                    justify={"space-between"}
                    margin={"0 0 15px"}
                    marginMd={"0 0 30px"}
                    gap={"10px"}
                    gapMd={"10px"}
                    wrap={"wrap"}
                  >
                    <FlexBox justify={"starts"}>
                      <Text
                        width={"100%"}
                        widthMd={"unset"}
                        color={"#FF4A4A"}
                        size={"16px"}
                        weight={"400"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        marginMd={"0 10px 0 0 "}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        lineHeight={"normal"}
                      >
                        상품 수량 :{" "}
                        <span style={{ fontWeight: "700" }}>{AddComma(selfGoodsCnt ? selfGoodsCnt : "")}개</span>
                      </Text>
                      {!isMobile && (
                        <BasicSelect
                          data={UNIT_10_TO_30_LIST}
                          selectedValue={pageSize}
                          setSelectedValue={setPageSize}
                          canSelectAll={false}
                          setCurrentPage={setCurrentPage}
                        />
                      )}
                    </FlexBox>
                    <FlexBox justify={"end"} gap={"15px"}>
                      <Btn
                        border={"1px solid #FF4A4A"}
                        color={"#FF4A4A"}
                        hoverColor={"#FFFFFF"}
                        hoverBackground={"#FF4A4A"}
                        hoverWeight={"700"}
                        padding={"9px 19px"}
                        size={"14px"}
                        radius={"6px"}
                        weight={"400"}
                        lineHeight={"22px"}
                        onClick={() => {
                          if (checkItems.length === 1) {
                            moveToEdit();
                          } else {
                            if (checkItems.length > 1) {
                              setTextMsg("하나의 상품만 선택해주세요.");
                              setTextModal(true);
                            } else {
                              setTextMsg("수정할 상품을 선택해주세요.");
                              setTextModal(true);
                            }
                          }
                        }}
                      >
                        상품 수정
                      </Btn>
                      {/* <Btn
                        border={"1px solid #FF4A4A"}
                        color={"#FF4A4A"}
                        hoverColor={"#FFFFFF"}
                        hoverBackground={"#FF4A4A"}
                        hoverWeight={"700"}
                        padding={"9px 19px"}
                        size={"14px"}
                        radius={"6px"}
                        weight={"400"}
                        lineHeight={"22px"}
                        onClick={() => {
                          if (checkItems.length === 0) {
                            setTextMsg("삭제할 상품을 선택해주세요.");
                            setTextModal(true);
                          } else {
                            setDeleteModal(true);
                          }
                        }}
                      >
                        상품 삭제
                      </Btn> */}
                      {!isMobile && (
                        <Btn
                          border={"1px solid #666666"}
                          hoverBorder={"1px solid #C7C7C7"}
                          hoverBackground={"#C7C7C7"}
                          color={"#666666"}
                          hoverColor={"#FFFFFF"}
                          weight={"500"}
                          hoverWeight={"700"}
                          padding={"9px 19px"}
                          size={"14px"}
                          radius={"6px"}
                          lineHeight={"22px"}
                          onClick={onExcelDown}
                        >
                          엑셀 다운
                        </Btn>
                      )}
                    </FlexBox>
                  </FlexBox>

                  <TableWrap>
                    <table style={{ width: "100%", minWidth: "600px" }}>
                      <colgroup>
                        <col style={{ width: "5%" }} />
                        <col />
                        <col />
                        <col />
                        <col />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>
                            <CheckLabel htmlFor="chkAll" style={{ justifyContent: "center" }}>
                              <input
                                type="checkbox"
                                id="chkAll"
                                onChange={(event) => onChangeAllChk(event.target.checked)}
                                checked={
                                  checkItems.length === data?.filter((item) => item.status !== "unRegistered").length
                                    ? true
                                    : false
                                }
                              />
                              <CheckIcon>
                                <svg viewBox="0 0 24 24">
                                  <polyline points="19 7 10 17 5 12" />
                                </svg>
                              </CheckIcon>
                            </CheckLabel>
                          </Th>
                          <Th>상품분류</Th>
                          <Th>바코드 번호</Th>
                          <Th>상품명</Th>
                          <Th>소비자가</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 ? (
                          data?.map((list, key) => (
                            <tr
                              style={{
                                cursor: "pointer",
                                backgroundColor:
                                  list?.status === "unRegistered" //hoverRow === key &&
                                    ? "rgba(255, 116, 64, 0.10)"
                                    : "transparent",
                              }}
                              key={key}
                              onMouseEnter={() => {
                                setHoverRow(key);
                              }}
                              onMouseLeave={() => {
                                setHoverRow(null);
                              }}
                              onClick={(event) => {
                                const targetElement = event.target;
                                // 체크박스 클릭 시 반복호출 방지
                                if (targetElement.nodeName !== "INPUT") {
                                  // 클릭한 요소의 내용 확인
                                  const dataType = targetElement.getAttribute("data-type");

                                  // 1. 클릭한 곳이 상태(status)인지 체크
                                  if (dataType === "status") {
                                    if (list.status === "stopSale") {
                                      getReason(list.idx);
                                      return;
                                    }
                                  }

                                  // 2. 클릭한 row가 미등록인지 체크
                                  if (list.status === "unRegistered") {
                                    // 미등록 상태면 상품 등록 페이지로 이동
                                    moveToWrite();
                                    return;
                                  }

                                  // 3. 그 외 체크박스 함수 실행
                                  const isChecked = document.getElementById(`prod${list.idx}`).checked;
                                  onChangeChx(!isChecked, list.idx);
                                }
                              }}
                            >
                              <Td
                                align={"center"}
                                style={{
                                  borderBottom: "1px solid #F2F2F2",
                                }}
                              >
                                <CheckLabel htmlFor={`prod${list.idx}`} style={{ justifyContent: "center" }}>
                                  <input
                                    type="checkbox"
                                    name="prod"
                                    id={`prod${list.idx}`}
                                    readOnly
                                    checked={checkItems.includes(list.idx) ? true : false}
                                  />
                                  {list?.status === "unRegistered" ? (
                                    <DisableCheckIcon>
                                      <svg viewBox="0 0 24 24">
                                        <polyline points="19 7 10 17 5 12" />
                                      </svg>
                                    </DisableCheckIcon>
                                  ) : (
                                    <CheckIcon>
                                      <svg viewBox="0 0 24 24">
                                        <polyline points="19 7 10 17 5 12" />
                                      </svg>
                                    </CheckIcon>
                                  )}
                                </CheckLabel>
                              </Td>
                              <Td
                                style={{
                                  borderBottom: "1px solid #F2F2F2",
                                }}
                              >
                                {list.category2nd_name ? list.category2nd_name : "-"}
                              </Td>
                              <Td
                                style={{
                                  borderBottom: "1px solid #F2F2F2",
                                }}
                              >
                                {list.product_barcode_num ? list.product_barcode_num : "-"}
                              </Td>
                              <Td
                                data-type="product_name"
                                style={{
                                  borderBottom: "1px solid #F2F2F2",
                                  // fontWeight:
                                  //   hoverRow === key &&
                                  //   list?.status === "unRegistered"
                                  //     ? "bold"
                                  //     : "normal",
                                }}
                                color={list?.status === "unRegistered" ? "#FF4A4A" : "#666666"}
                              >
                                {list.product_product_name ? list.product_product_name : "상품을 등록해주세요."}
                              </Td>
                              <Td
                                style={{
                                  borderBottom: "1px solid #F2F2F2",
                                }}
                              >
                                {list.product_consumer_price ? AddComma(list.product_consumer_price) : "-"}
                                {list.product_consumer_price ? "원" : ""}
                              </Td>
                              {/* <Td
                                data-type="status"
                                color={list.status === "reject" || list.status === "stopSale" ? "#F63958" : "#666666"}
                                style={{
                                  borderBottom: "1px solid #F2F2F2",
                                  textDecoration:
                                    list.status === "reject" || list.status === "stopSale" ? "underline" : "none",
                                  fontWeight:
                                    hoverRow === key && (list.status === "reject" || list.status === "stopSale")
                                      ? "bold"
                                      : "normal",
                                }}
                              >
                                {list.status
                                  ? DIRECT_SELFGOODS_STATUS_LIST.find((item) => item.value === list.status)?.text || "-"
                                  : "-"}
                              </Td> */}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={5}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "16px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  {pagination?.total_page > 0 ? (
                    <Pagination pagination={pagination} movePage={movePage} currentPage={currentPage} />
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <ConfirmModal
                modal={deleteModal}
                setModal={setDeleteModal}
                onSubmit={deleteSelfGoods}
                description={"선택된 상품을 삭제하시겠습니까?"}
              />
              <InfoModal
                modal={refreshModal}
                setModal={setRefreshModal}
                description={"상품이 정상적으로 삭제되었습니다."}
                mode={"refresh"}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
              <InfoModal
                modal={reasonModal}
                setModal={setReasonModal}
                title={reasonModalTxts.title}
                description={reasonModalTxts.text}
                detailText={reasonModalTxts.reason}
              />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

export default DirectSelfGoods;
