import React,{ memo } from "react";
import styled from 'styled-components';
import { Bar } from 'react-chartjs-2';
import {AddComma} from "../../Utiles/Custom";

/* code from sub_outsourcing */

function ExChart({graphItems}) {
  // 본사 매출
  const dataY1 = graphItems?.map(item => ({
    x: Object.keys(item)[0],
    y: parseInt(Object.values(item)[0].headquarter_amount),
  }));
  
  // 자점매입 매출
  const dataY2 = graphItems?.map(item => ({
    x: Object.keys(item)[0],
    y: parseInt(Object.values(item)[0].franchise_amount),
  }));
  
  // 자점매입 매출 없을 때 범례 숨김
  // y가 NaN이면 false(!NaN이면 true) && dataY2 배열 중 0이 아닌 값이 하나라도 있으면 true
  const hasDataForFranchise = dataY2?.some(item => !isNaN(item.y) && item.y !== 0);

  const selfPurchaseOptions = {
    spanGaps: true,
    maxBarThickness: 30,
    grouped: true,
    interaction: {
      mode: 'index',
    },
    plugins: {
      legend: {
        display: hasDataForFranchise,
      },
      tooltip: {
        titleColor: "#000",
        titleFont: { weight: "400", size: "10", family: "Montserrat" },
        bodyColor: "black",
        bodyFont: { weight: "bold", family: "Montserrat" },
        borderRadius: 10,
        backgroundColor: "#fff",
        borderRadius: 10,
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderWidth: 1,
        padding: 14,
        filter: (item) => item.parsed.y !== null,
        callbacks: {
          title: (context) => {
            const date = context[0].label;

            let sum = 0;
            let parsedY;
            context.forEach((item) => {
              if (item.dataset.label !== '결제건수') {
                parsedY = item.parsed.y;
                sum += item.parsed.y;
              }
            });
  
            if (parsedY !== 0) {
              return date + "\n매출 합계: " + AddComma(sum) + "원";
            } else {
              return date;
            }
          },
          label: (context) => {
            const value = context.parsed.y;

            // 자점매입 매출 없을 때 tooltip 0원 숨김
            if (value !== null && value !== 0) {
              return AddComma(value) + (context.dataset.label === '결제건수' ? "건" : "원");
            }
            return null;
          },
        },
      }
    },
    scales: {
      // x축: 날짜
      x: {
        afterTickToLabelConversion: function (scaleInstance) {
          const ticks = scaleInstance.ticks;
  
          const newTicks = ticks.map((tick) => {
            return {
              ...tick,
              label: tick.label
            };
          });
  
          scaleInstance.ticks = newTicks;
        },
        ticks: {
          font: {
            family: "Montserrat",
            size: 14,
            color: "#000000",
          },
        },
        grid: {
          display: false,
        },
        axis: 'x',
        position: 'bottom',
        stacked: true,
      },
      // y축: 매출
      y: {
        type: 'linear',
        ticks: {
          font: {
            family: "Montserrat",
            size: 14,
            color: "#000000",
          },
        },
        afterDataLimits: (scale) => {
          // scale.max = scale.max * 1.2;
        },
        grid: {
          display: false,
        },
        axis: 'y',
        display: true,
        position: 'left',
        stacked: true,
      },
      // y_sub축: 결제건수
      y_sub: { 
        type: 'linear',
        ticks: {
          font: {
            family: "Montserrat",
            size: 14,
            color: "#000000",
          },
        },
        grid: {
          display: false,
        },
        display: true,
        position: 'right',
        stacked: false,
      },
    }
  };

  const selfPurchaseData = {
    datasets: [
      {
        type: 'line',
        label: '결제건수',
        borderColor: '#FF4A4A',
        backgroundColor: '#FF7440',
        data:graphItems.map(item => { 
          return {x: Object.keys(item)[0], y: Object.values(item)[0].count}
        } ),
        yAxisID: 'y_sub',
      },
      {
        type: 'bar',
        label: '본사',
        backgroundColor: "rgba(255, 116, 64, 0.2)", // 피그마: 0.1(투명도 10%)
        hoverBackgroundColor: "#FF4A4A",
        borderRadius: 6,
        data: dataY1,
      },
      {
        type: 'bar',
        label: '자점매입',
        backgroundColor: "rgba(255, 235, 130, 0.2)", // 피그마: 0.1(투명도 10%)
        hoverBackgroundColor: "#FFEB82",
        borderRadius: 6,
        data: dataY2,
      },
    ]
  };
  

    return (
        <Container>
            <Bar type="bar" data={selfPurchaseData} options={selfPurchaseOptions} />
        </Container>
      );
    };
    
export default memo(ExChart);

const Container = styled.div`
  width: 100%;
  /* max-width: 900px; */
  text-align: center;
  margin: 0 auto;
  overflow: auto;

  @media (max-width: 1024px) {
    max-width: 100%;
    overflow: visible;
  }
`;