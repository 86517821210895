import React, { useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import {
  Btn,
  Container,
  TextInput,
  Wrapper,
  FlexBox,
  Text,
  Title,
  WhiteBox,
  ContentContainer,
  RadioLabel,
  RadioIcon,
  DisabledDiv,
} from "../../Assets/css/commonVer2";

import HeaderSub from "../../Components/Header/HeaderSub";
import SearchAddress from "../../Components/SearchAddress/SearchAddress";
import ico_close from "../../Assets/images/ico_close.png";
import API from "../../Utiles/API";
import Authority from "../../Components/Modal/Authority";
import Loading from "../../Components/Loading";
import FranSelect from "../../Components/Select/FranSelect";
import InfoModal from "../../Components/Modal/InfoModal";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";

// 가맹마스터 > 사용자관리 > 사용자 등록

function FranchiseeUserWrite() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();
  const [values, setValues] = useState({
    user_id: "",
    password: "",
    password2: "",
    name: "",
    tel: "",
    email: "",
    resident_registration_number: "",
    address: "",
    detail_address: "",
    bank: "",
    account_holder: "",
    account_number: "",
    incoming_route: "",
    franchise_id: "",
  });
  const [gubunValue, setGubunValue] = useState("storeonwer");
  const [addrValue, setAddrValue] = useState("");
  const [idDisabled, setIdDisabled] = useState(false);
  const [idMsg, setIdMsg] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [listModal, setListModal] = useState(false);
  const [addrModal, setAddrModal] = useState(false);

  const [searchFran, setSearchFran] = useState([]);
  const [franIdx, setFranIdx] = useState("");
  const [franText, setFranText] = useState("가맹점 전체");
  const [franShow, setFranShow] = useState(false);

  const [loading, setLoading] = useState(false);
  const [textModal, setTextModal] = useState(false); // 모달 창 표시 여부 상태
  const [textMsg, setTextMsg] = useState("");
  const [infoModalMode, setInfoModalMode] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  const postIdCheck = async () => {
    if (values.user_id) {
      await API.post("/admin/users/idcheck", {
        user_id: values.user_id,
      })
        .then((res) => {
          if (res.data.status) {
            setIdDisabled(true);
            setTextMsg(res.data.msg);
            setTextModal(true);
          } else {
            if (res.data.msg == "이미 사용중인 아이디입니다.") {
              setIdMsg(true);
            } else {
              setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
              setTextModal(true);
            }
          }
        })
        .catch(function (error) {
          setTextMsg("E01\n잠시후 다시 시도해주세요.");
          setTextModal(true);
        });
    } else {
      setTextMsg("아이디를 입력해주세요.");
      setTextModal(true);
    }
  };

  const postUsers = async () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("gubun", gubunValue);
    formData.append("user_id", values.user_id);
    formData.append("password", values.password);
    formData.append("name", values.name);
    formData.append("tel", values.tel);
    formData.append("email", values.email);
    formData.append("resident_registration_number", values.resident_registration_number);
    formData.append("address", addrValue);
    formData.append("detail_address", values.detail_address);
    formData.append("bank", values.bank);
    formData.append("account_holder", values.account_holder);
    formData.append("account_number", values.account_number);
    formData.append("incoming_route", values.incoming_route);
    formData.append("franchise_id", +franIdx);
    await API.post("/admin/users", formData)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setInfoModalMode("goBack");
          setTextMsg("등록이 완료되었습니다.");
          setTextModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(function (error) {
        setLoading(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const onGubunChange = (val) => {
    setGubunValue(val);
    if (val === "admin") {
      setFranText("가맹점 전체");
      setFranShow(false);
    }
  };

  const onAddrChange = (e) => {
    setAddrValue(e.target.value);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setIdMsg(false);
  };

  const onSubmit = () => {
    if (values.password !== values.password2) {
      setTextMsg("비밀번호가 일치하지 않습니다.");
      setTextModal(true);
      return;
    }
    setSaveModal(true);
  };

  const onModalSubmit = (e) => {
    if (e === 1) {
      setSaveModal(false);
      navigate(-1, { replace: true });
    } else {
      if (idDisabled) {
        postUsers();
      } else {
        setTextMsg("아이디 중복 확인을 해주세요.");
        setTextModal(true);
      }
      setSaveModal(false);
    }
  };

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {loading ? <Loading /> : null}
          <HeaderSub title={"사용자 정보 등록"} />
          <ContentContainer>
            <Wrapper>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    사용자 관리 📋
                  </Title>
                )}

                <WhiteBox margin={"0 0"}>
                  <Title align={"left"} color={"#1E1E1E"} weight={"700"} size={"16px"} sizeMd={"20px"}>
                    사용자 정보 등록
                  </Title>
                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    margin={"20px 0 20px"}
                    marginMd={"20px 0 30px"}
                    wrap={"wrap"}
                    wrapMd={"unset"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        사용자 구분
                      </Text>
                      <FlexBox justify={"start"} gap={"10px"}>
                        <RadioLabel>
                          <input
                            type="radio"
                            name="storeonwer"
                            value={"storeonwer"}
                            checked={gubunValue === "storeonwer"}
                            onChange={() => onGubunChange("storeonwer")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          가맹점주
                        </RadioLabel>

                        <RadioLabel>
                          <input
                            type="radio"
                            name="admin"
                            value={"admin"}
                            checked={gubunValue === "admin"}
                            onChange={() => onGubunChange("admin")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          관리자
                        </RadioLabel>
                      </FlexBox>
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        아이디
                      </Text>
                      <FlexBox>
                        <TextInput
                          style={{ flex: 1 }}
                          type={"text"}
                          placeholder={"아이디를 입력해주세요."}
                          name="user_id"
                          value={values.user_id}
                          onChange={handleChange}
                          disabled={idDisabled}
                        />
                        <Btn
                          color={"#fff"}
                          background={"#FF4A4A"}
                          size={"14px"}
                          weight={"700"}
                          padding={"8px 18px"}
                          margin={"0 0 0 10px"}
                          lineHeight={"22px"}
                          onClick={postIdCheck}
                          style={{ flexShrink: 0 }}
                        >
                          중복확인
                        </Btn>
                      </FlexBox>
                      {idMsg ? (
                        <Text align={"left"} color={"#FF4A4A"} size={"10px"} weight={"400"} margin={"10px 0 0"}>
                          *중복된 아이디입니다.
                        </Text>
                      ) : (
                        ""
                      )}
                    </Contents>
                  </FlexBox>
                  <FlexBox justify={"start"} align={"end"} wrap={"wrap"} wrapMd={"unset"} gap={"20px"} gapMd={"26px"}>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        비밀번호
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"password"}
                        placeholder={"비밀번호를 입력해주세요."}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                      />
                    </Contents>
                    <Contents>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"password"}
                        placeholder={"비밀번호를 재입력해주세요."}
                        name="password2"
                        value={values.password2}
                        onChange={handleChange}
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBox
                    justify={"start"}
                    align={"end"}
                    wrap={"wrap"}
                    wrapMd={"unset"}
                    margin={"20px 0 20px"}
                    marginMd={"30px 0 30px"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        이름
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"이름을 입력해주세요."}
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        주민등록번호
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"주민등록번호를 입력해주세요."}
                        name="resident_registration_number"
                        value={values.resident_registration_number}
                        onChange={handleChange}
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBox justify={"start"} align={"end"} wrap={"wrap"} wrapMd={"unset"} gap={"20px"} gapMd={"26px"}>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        전화번호
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"전화번호를 입력해주세요."}
                        name="tel"
                        value={values.tel}
                        onChange={handleChange}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        이메일
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"이메일을 입력해주세요."}
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBox
                    justify={"start"}
                    align={"end"}
                    wrap={"wrap"}
                    wrapMd={"unset"}
                    gap={"20px"}
                    gapMd={"26px"}
                    margin={"20px 0 20px"}
                    marginMd={"30px 0 30px"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        주소
                      </Text>
                      <FlexBox margin={"0 0 5px"} marginMd={"0 0 10px"} gap={"10px"} onClick={() => setAddrModal(true)}>
                        <TextInput
                          style={{ flex: 1 }}
                          type={"text"}
                          placeholder={"주소를 입력해주세요."}
                          value={addrValue}
                          onChange={onAddrChange}
                          readOnly
                        />
                        <Btn
                          color={"#fff"}
                          background={"#FF4A4A"}
                          size={"14px"}
                          weight={"700"}
                          padding={"8px 18px"}
                          lineHeight={"22px"}
                          style={{ flexShrink: 0 }}
                        >
                          주소찾기
                        </Btn>
                      </FlexBox>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"상세주소를 입력해주세요."}
                        name="detail_address"
                        value={values.detail_address}
                        onChange={handleChange}
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBox justify={"start"} align={"end"} wrap={"wrap"} wrapMd={"unset"} gap={"20px"} gapMd={"26px"}>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        은행
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"은행명을 입력해주세요."}
                        name="bank"
                        value={values.bank}
                        onChange={handleChange}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        예금주
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"예금주를 입력해주세요."}
                        name="account_holder"
                        value={values.account_holder}
                        onChange={handleChange}
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBox
                    justify={"start"}
                    align={"end"}
                    wrap={"wrap"}
                    wrapMd={"unset"}
                    margin={"20px 0 20px"}
                    marginMd={"30px 0 30px"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        계좌번호
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"계좌번호를 입력해주세요."}
                        name="account_number"
                        value={values.account_number}
                        onChange={handleChange}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        진입경로
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"진입경로를 입력해주세요."}
                        name="incoming_route"
                        value={values.incoming_route}
                        onChange={handleChange}
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBox justify={"start"} align={"end"} wrap={"wrap"} wrapMd={"unset"}>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        가맹점
                      </Text>
                      <div style={{ position: "relative" }}>
                        {gubunValue === "admin" && <DisabledDiv />}
                        <FranSelect
                          width={"100%"}
                          gubun={"fran"}
                          selectBtnText={franText}
                          setTextChange={setFranText}
                          setIdxChange={setFranIdx}
                          show={franShow}
                          setFranShow={setFranShow}
                          placeholder={"가맹점을"}
                        />
                      </div>
                      <div style={{ position: "relative" }}>
                        {franShow ? ( //searchFran.length > 0 &&
                          <>
                            <PopMobile className="d-block d-md-none">
                              <Btn
                                border={"none"}
                                margin={"0 0 80px auto"}
                                background={"none"}
                                onClick={() => setFranShow(false)}
                              >
                                <img src={ico_close} alt="팝업 닫기" style={{ width: "24px" }} />
                              </Btn>
                              <Text size={"16px"} color={"#1E1E1E"} weight={"500"} margin={"0 0 20px"}>
                                가맹점을 선택해주세요.
                              </Text>
                              <div style={{ position: "relative" }}>
                                <FranSelect
                                  width={"100%"}
                                  gubun={"fran"}
                                  selectBtnText={franText}
                                  setTextChange={setFranText}
                                  setIdxChange={setFranIdx}
                                  show={franShow}
                                  setFranShow={setFranShow}
                                  placeholder={"가맹점을"}
                                />
                              </div>
                              {/* <Btn
                                width={"100%"}
                                widthMd={"90px"}
                                color={"#fff"}
                                background={"#FF4A4A"}
                                size={"14px"}
                                weight={"700"}
                                padding={"8px 18px"}
                                lineHeight={"22px"}
                                className="select_btn"
                                onClick={() => {
                                  setFranText(franText);
                                  setFranIdx(franIdx);
                                  setFranShow(false);
                                }}
                              >
                                선 택
                              </Btn> */}
                            </PopMobile>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </Contents>
                    <Contents></Contents>
                  </FlexBox>
                </WhiteBox>
                <FlexBox justify={"end"} flex={"1 0 auto"} flexMd={"unset"} gap={"8px"} gapMd={"10px"}>
                  <Btn
                    width={"100%"}
                    widthMd={"90px"}
                    size={"14px"}
                    color={"#666"}
                    border={"1px solid #666"}
                    padding={"8px 18px"}
                    lineHeight={"22px"}
                    hoverWeight={"700"}
                    hoverColor={"#fff"}
                    hoverBackground={"#C7C7C7"}
                    hoverBorder={"1px solid transparent"}
                    onClick={() => setListModal(true)}
                  >
                    목록
                  </Btn>
                  <Btn
                    width={"100%"}
                    widthMd={"90px"}
                    color={"#fff"}
                    background={"#FF4A4A"}
                    size={"14px"}
                    weight={"700"}
                    padding={"8px 18px"}
                    lineHeight={"22px"}
                    onClick={onSubmit}
                  >
                    저장
                  </Btn>
                </FlexBox>
              </Container>
              {addrModal ? <SearchAddress setModal={setAddrModal} setValue={setAddrValue} setIsSearch={setIsSearch}/> : ""}
              <ConfirmModal
                modal={listModal}
                setModal={setListModal}
                onSubmit={() => onModalSubmit(1)}
                description={"사용자 등록을 취소하고\n목록화면으로 이동하시겠습니까?"}
              />
              <ConfirmModal
                modal={saveModal}
                setModal={setSaveModal}
                onSubmit={() => onModalSubmit(2)}
                description={"사용자 등록을 완료하시겠습니까?"}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} mode={infoModalMode} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const Contents = styled.div`
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  background-color: ${(props) => props.background || ""};
  width: ${(props) => props.width || "100%"};
  border-radius: ${(props) => props.radius || "8px"};
  display: ${(props) => props.display || "block"};
  justify-content: ${(props) => props.justify ?? props.justify};
  align-items: ${(props) => props.align ?? props.align};

  @media all and (min-width: 1024px) {
    margin: ${(props) => props.marginMd || "0"};
    padding: ${(props) => props.paddingMd || "0"};
  }
`;
const PopMobile = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100vh;
  padding: 16px;
  background-color: #fff;
  z-index: 1000;

  button.select_btn {
    position: absolute;
    width: calc(100% - 32px);
    left: 16px;
    bottom: 40px;
  }
`;

export default FranchiseeUserWrite;
