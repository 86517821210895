import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import Header from "../../Components/Header/HeaderVer2";
import {
  Btn,
  Container,
  PagerWrap,
  Wrapper,
  Td,
  Th,
  FlexBox,
  ContentContainer,
  CheckLabel,
  CheckIcon,
  TableWrap,
  Title,
  WhiteBox,
  SearchInput,
} from "../../Assets/css/commonVer2";
import API from "../../Utiles/API";
import { str, hypen, handleKeyDown } from "../../Utiles/Custom";
import Paging from "../../Components/Paging/Paging";
import Authority from "../../Components/Modal/Authority";
import BasicSelect from "../../Components/Select/BasicSelect";
import { UNIT_10_TO_30_LIST, USER_CATEGORY_LIST, USER_SEARCH_TYPE_LIST } from "../../Utiles/ListData";
import InfoModal from "../../Components/Modal/InfoModal";
import HandleBack from "../../Components/HandleBack";

// (관리자) 가맹마스터 > 사용자 관리

function FranchiseeUser() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();
  const { handlePopstate } = HandleBack();

  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState();

  const [checkItems, setCheckItems] = useState([]);
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");

  // 상품 상세를 다녀온 경우 저장 해놓은 파라미터 정보
  const parsedFranchiseeUserPageState = sessionStorage.getItem("franchiseeUserPageState")
    ? JSON.parse(sessionStorage.getItem("franchiseeUserPageState"))
    : {};

  const [currentPage, setCurrentPage] = useState(
    parsedFranchiseeUserPageState
      ? parsedFranchiseeUserPageState?.currentPage
        ? parsedFranchiseeUserPageState?.currentPage
        : 1
      : 1,
  );
  const [pageSize, setPageSize] = useState(
    parsedFranchiseeUserPageState
      ? parsedFranchiseeUserPageState?.pageSize
        ? parsedFranchiseeUserPageState?.pageSize
        : 10
      : 10,
  );
  const [name, setName] = useState(
    parsedFranchiseeUserPageState
      ? parsedFranchiseeUserPageState?.name
        ? parsedFranchiseeUserPageState?.name
        : ""
      : "",
  );
  const [filter, setFilter] = useState(
    parsedFranchiseeUserPageState
      ? parsedFranchiseeUserPageState?.filter
        ? parsedFranchiseeUserPageState?.filter
        : "user_id"
      : "user_id",
  );
  const [search, setSearch] = useState(
    parsedFranchiseeUserPageState
      ? parsedFranchiseeUserPageState?.search
        ? parsedFranchiseeUserPageState?.search
        : undefined
      : undefined,
  );

  // 점주 수정을 갈 때 sessionStorage에 저장할 객체
  let franchiseeUserPageState = {
    currentPage,
    pageSize,
    name,
    filter,
    search,
  };

  const Search = async () => {
    try {
      const res = await API.get(
        `/admin/users?curPage=${currentPage}&pageSize=${pageSize}&gubunVal=${name}&filterCol=${filter}&filterVal=${search}`,
      );
      if (res.data.status) {
        setData(res.data?.result);
        setTotalCount(res.data?.cntAll);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 아이템 체크
  const onChangeChx = (checked, id) => {
    if (checked) {
      setCheckItems((prev) => [...prev, id]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  // 아이템 전체 체크
  const onChangeAllChk = (checked) => {
    if (checked) {
      const chkArr = [];
      for (let i = 0; i < data.length; i++) {
        chkArr.push(data[i].idx);
      }
      setCheckItems(chkArr);
    } else {
      setCheckItems([]);
    }
  };

  // 아이템 수정 클릭
  const onClickEditUser = () => {
    if (checkItems.length === 0) {
      setTextMsg("수정할 사용자를 선택해주세요.");
      setTextModal(true);
      return;
    }
    if (checkItems.length > 1) {
      setTextMsg("하나만 선택해주세요.");
      setTextModal(true);
      return;
    }
    if (checkItems.length === 1) {
      const foundItem = data.find((item) => item.idx === checkItems[0]);
      navigate("Edit", { state: { idx: foundItem.idx } });
    }
    let jsonString = JSON.stringify(franchiseeUserPageState);
    sessionStorage.setItem("franchiseeUserPageState", jsonString);
  };

  useEffect(() => {
    // 현재 페이지에 도착하면 무조건 한 번 초기화
    sessionStorage.removeItem("franchiseeUserPageState");
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    Search();
    setCheckItems([]);
  }, [currentPage]);

  useEffect(() => {
    setTotalPage(Math.ceil(totalCount / pageSize));
  }, [totalCount, pageSize]);

  useEffect(() => {
    if (!parsedFranchiseeUserPageState?.currentPage && currentPage !== 1) {
      // 등록이나 수정 다녀왔을 때 sessionStorage가 초기화 되고 currentPage가 1이 아니었을 때
      // 평소 페이징에서 평소 currentPage가 1이 아니었을 때
      setCurrentPage(1);
    } else {
      // 등록이나 수정 다녀왔을 때 sessionStorage가 초기화 되기 전 실행
      // currentPage 1에서 유닛을 바꿀 때
      Search();
    }
  }, [pageSize]);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {isMobile && <Header title={"사용자 관리"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    사용자 관리 📋
                  </Title>
                )}
                <WhiteBox margin={"0 0"}>
                  <FlexBox justify={"end"} wrap={"wrap"} gap={"10px"}>
                    <BasicSelect
                      width={"140px"}
                      widthMd={"100%"}
                      data={USER_CATEGORY_LIST}
                      selectedValue={name}
                      setSelectedValue={setName}
                      canSelectAll={true}
                      placeholder={"사용자 구분 전체"}
                    />
                    <BasicSelect
                      width={"140px"}
                      widthMd={"100%"}
                      data={USER_SEARCH_TYPE_LIST}
                      selectedValue={filter}
                      setSelectedValue={setFilter}
                      canSelectAll={false}
                    />
                    <FlexBox
                      justify={"end"}
                      gap={"10px"}
                      width={"100%"}
                      widthMd={"unset"}
                      wrap={"wrap"}
                      wrapLg={"nowrap"}
                    >
                      <SearchInput
                        borderBottom={"1px solid #666"}
                        borderBottomColor={"#ff4a4a"}
                        placeholder={"검색어를 입력해주세요."}
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        onKeyDown={(event) => handleKeyDown(event, Search)}
                      />
                      <Btn
                        color={"#fff"}
                        background={"#FF4A4A"}
                        size={"14px"}
                        weight={"700"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        className={"d-none d-md-block"}
                        onClick={() => Search()}
                        style={{ flexShrink: 0 }}
                      >
                        검 색
                      </Btn>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox
                    wrap={"wrap"}
                    gap={"10px"}
                    wrapLg={"nowrap"}
                    justify={"space-between"}
                    margin={"20px 0 10px"}
                    marginMd={"20px 0 30px"}
                  >
                    {!isMobile && (
                      <BasicSelect
                        width={"130px"}
                        data={UNIT_10_TO_30_LIST}
                        selectedValue={pageSize}
                        setSelectedValue={setPageSize}
                        canSelectAll={false}
                        setCurrentPage={setCurrentPage}
                      />
                    )}
                    <FlexBox margin={"0"} marginLg={"0 0 0 auto"}>
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        onClick={(props) => {
                          navigate("write");
                          let jsonString = JSON.stringify(franchiseeUserPageState);
                          sessionStorage.setItem("franchiseeUserPageState", jsonString);
                        }}
                      >
                        등록
                      </Btn>
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        margin={"0 0 0 10px"}
                        hoverWeight={"700"}
                        onClick={onClickEditUser}
                      >
                        수정
                      </Btn>
                    </FlexBox>
                    <Btn
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      className={"d-block d-md-none"}
                      onClick={() => Search()}
                    >
                      검 색
                    </Btn>
                  </FlexBox>
                  <TableWrap>
                    <table style={{ width: "100%", minWidth: "1024px" }}>
                      <colgroup>
                        <col style={{ width: "4%" }} />
                        <col style={{ width: "6%" }} />
                        <col />
                        <col />
                        <col />
                        <col style={{ width: "20%" }} />
                        <col />
                        <col />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>
                            <CheckLabel htmlFor="chkAll" style={{ justifyContent: "center" }}>
                              <input
                                type="checkbox"
                                id="chkAll"
                                onChange={(event) => onChangeAllChk(event.target.checked)}
                                checked={checkItems.length === data.length ? true : false}
                              />
                              <CheckIcon>
                                <svg viewBox="0 0 24 24">
                                  <polyline points="19 7 10 17 5 12" />
                                </svg>
                              </CheckIcon>
                            </CheckLabel>
                          </Th>
                          <Th>No</Th>
                          <Th>사용자 구분</Th>
                          <Th>아이디</Th>
                          <Th>이름</Th>
                          <Th>이메일</Th>
                          <Th>전화번호</Th>
                          <Th>등록일</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 ? (
                          data.map((list, key) => (
                            <tr
                              style={{ cursor: "pointer" }}
                              key={key}
                              onClick={(event) => {
                                const targetElement = event.target;
                                if (targetElement.nodeName !== "INPUT") {
                                  // 체크박스를 클릭한 경우에는 이벤트를 처리하지 않음
                                  const isChecked = document.getElementById(`prod${list.idx}`).checked;
                                  onChangeChx(!isChecked, list.idx);
                                }
                              }}
                            >
                              <Td>
                                <CheckLabel htmlFor={`prod${list.idx}`} style={{ justifyContent: "center" }}>
                                  <input
                                    type="checkbox"
                                    name="prod"
                                    id={`prod${list.idx}`}
                                    readOnly
                                    checked={checkItems.includes(list.idx) ? true : false}
                                  />
                                  <CheckIcon>
                                    <svg viewBox="0 0 24 24">
                                      <polyline points="19 7 10 17 5 12" />
                                    </svg>
                                  </CheckIcon>
                                </CheckLabel>
                              </Td>
                              <Td>{(currentPage - 1) * pageSize + key + 1}</Td>
                              <Td color={"#ff4a4a"}>{list && list.gubun === "admin" ? "관리자" : "점주"}</Td>
                              <Td>{list ? list.user_id : ""}</Td>
                              <Td>{list?.name ? list.name : "-"}</Td>
                              <Td>{list?.email ? list.email : "-"}</Td>
                              <Td>{list?.tel ? hypen(list.tel) : "-"}</Td>
                              <Td>{list ? str(list.createdAt) : ""}</Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={8}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  {data.length > 0 ? (
                    <PagerWrap>
                      <Paging totalPage={totalPage} limit={pageSize} page={currentPage} setPage={setCurrentPage} />
                    </PagerWrap>
                  ) : (
                    ""
                  )}
                </WhiteBox>
                <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
              </Container>
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

export default FranchiseeUser;
