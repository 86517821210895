// 외부 모듈
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Xlsx_download } from "../../Utiles/Custom";
import { useMediaQuery } from "react-responsive";

// 내부 모듈
import Header from "../../Components/Header/HeaderVer2";
import {
  Wrapper,
  Container,
  TableWrap,
  FlexBox,
  SearchInput,
  Th,
  Td,
  Btn,
  ContentContainer,
  WhiteBox,
  Title,
} from "../../Assets/css/commonVer2";
import Authority from "../../Components/Modal/Authority";
import Pagination from "../../Components/Paging/Pagination";
import Loading from "../../Components/Loading";
import { AddComma, handleKeyDown } from "../../Utiles/Custom";
import API from "../../Utiles/API";
import BasicSelect from "../../Components/Select/BasicSelect";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import InfoModal from "../../Components/Modal/InfoModal";
import { UNIT_10_TO_30_LIST, SEARCH_TYPE_LIST } from "../../Utiles/ListData";
import Cate2Select from "../../Components/Select/Cate2Select";
import ProductSearchInputVer2 from "../../Components/SearchInput/ProductSearchInputVer2";

// 관리자_상품마스터_재입고 알림 상품 순위

export default function ProductRestock() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const [loading, setLoading] = useState(false);

  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [confirmExcelModal, setConfirmExcelModal] = useState(false);

  // 페이징 관련
  const [pagination, setPagination] = useState("");
  const [unit, setUnit] = useState(10);
  const [nowPage, setNowPage] = useState(1);

  // 재입고 알림 상품 순위 데이터
  const [data, setData] = useState([]);
  const [cate2Value, setCate2Value] = useState("");
  const [filter, setFilter] = useState("product_name");
  const [inputValue, setInputValue] = useState("");

  const [searchResults, setSearchResults] = useState([]);
  const [showSearchList, setShowSearchList] = useState(false);

  // API - 재입고 알림 내역 조회
  const getRestockList = async () => {
    setLoading(true);
    let api_link = `/admin/restock?page=${nowPage}&unit=${unit}&block=10`;
    if (cate2Value) {
      api_link += `&category2nd_id=${cate2Value}`;
    }
    if (inputValue) {
      api_link += `&filterType=${filter}&filterValue=${inputValue}`;
    }

    await API.get(api_link)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setData(res.data.results);
          setPagination(res.data.pagination);
        } else {
          setTextMsg("E01\n잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const onSearch = () => {
    setSearchResults([]);
    setShowSearchList(false);
    setNowPage(1);
    getRestockList();
  };

  function movePage(e) {
    setNowPage(e);
  }

  const onClickExcelDown = async () => {
    if (data.length > 0) {
      await API.get(`/admin/restock?page=${nowPage}&unit=99999999&block=10`).then((res) => {
        if (res.data.status && res.data.results.length > 0) {
          var xlsxTemp = res.data.results.map(function (item) {
            return {
              바코드번호: item.barcode_num,
              상품분류: item.category2nd_name,
              상품명: item.product_name,
              공급가: `${AddComma(item.shipping_price)}원`,
              소비자가: `${AddComma(item.consumer_price)}원`,
              신청건수: item.count,
            };
          });
          Xlsx_download(xlsxTemp, `재입고 알림 신청 상품 리스트`);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      });
    } else {
      setTextMsg("다운받을 데이터가 없습니다.");
      setTextModal(true);
    }
  };

  useEffect(() => {
    setInputValue("");
  }, [filter]);

  useEffect(() => {
    getRestockList();
  }, [nowPage, unit]);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {loading ? <Loading /> : null}
          {isMobile && <Header title={"재입고 알림 목록"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    재입고 알림 목록 📋
                  </Title>
                )}
                <WhiteBox margin={"0 0"}>
                  <FlexBox wrap={"wrap"} gap={"10px"} justify={"end"}>
                    <Cate2Select
                      idx={1}
                      cate2Value={cate2Value}
                      setCate2Value={setCate2Value}
                      placeholder={"상품 2차 분류"}
                    />
                    <BasicSelect
                      selectedValue={filter}
                      setSelectedValue={setFilter}
                      data={SEARCH_TYPE_LIST}
                      canSelectAll={false}
                    />
                    <FlexBox justify={"end"} width={"100%"} widthMd={"auto"} wrap={"wrap"} gap={"10px"}>
                      {filter == "barcode_num" ? (
                        <SearchInput
                          borderBottom={"1px solid #666"}
                          borderBottomColor={"#ff4a4a"}
                          transition={"border-color 0.3s ease-in-out"}
                          placeholder={"바코드 번호를 입력해주세요."}
                          value={inputValue}
                          onChange={(e) => {
                            setInputValue(e.target.value);
                          }}
                          onKeyDown={(event) => handleKeyDown(event, onSearch)}
                        />
                      ) : (
                        <SelectBox>
                          <ProductSearchInputVer2
                            showSearchList={showSearchList}
                            setShowSearchList={setShowSearchList}
                            searchResults={searchResults}
                            setSearchResults={setSearchResults}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            onSearch={onSearch}
                          />
                        </SelectBox>
                      )}
                      <Btn
                        color={"#fff"}
                        background={"#FF4A4A"}
                        size={"14px"}
                        weight={"700"}
                        width={"100%"}
                        widthMd={"unset"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        onClick={onSearch}
                        style={{ flexShrink: 0 }}
                      >
                        검 색
                      </Btn>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox justify={"space-between"} margin={"30px 0 10px 0"}>
                    {/* <Select
                      className="d-none d-md-block"
                      onChange={(e) => {
                        setUnit(e.target.value);
                        setNowPage(1);
                      }}
                      value={unit}
                    >
                      <option value={10}>10개씩 보기</option>
                      <option value={20}>20개씩 보기</option>
                      <option value={30}>30개씩 보기</option>
                    </Select> */}
                    {!isMobile && (
                      <BasicSelect
                        width={"130px"}
                        data={UNIT_10_TO_30_LIST}
                        selectedValue={unit}
                        setSelectedValue={setUnit}
                        canSelectAll={false}
                        setCurrentPage={setNowPage}
                      />
                    )}
                    {!isMobile && (
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        border={"1px solid #666"}
                        color={"#666"}
                        hoverBackground={"#C7C7C7"}
                        hoverColor={"#fff"}
                        hoverBorder={"transparent"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        margin={"0 0 0 10px"}
                        onClick={() => {
                          setConfirmExcelModal(true);
                        }}
                      >
                        엑셀 다운
                      </Btn>
                    )}
                  </FlexBox>
                  <TableWrap>
                    <table style={{ width: "100%", minWidth: "1024px" }}>
                      <colgroup>
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "20%" }} />
                        <col />
                        <col style={{ width: "25%" }} />
                        <col />
                        <col />
                        <col />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>No</Th>
                          <Th>바코드 번호</Th>
                          <Th>상품분류</Th>
                          <Th>상품명</Th>
                          <Th>공급가</Th>
                          <Th>소비자가</Th>
                          <Th>신청건수</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 ? (
                          data?.map((item, key) => (
                            <tr key={item.restock_id}>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{(nowPage - 1) * unit + key + 1}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.barcode_num}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.category2nd_name}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.product_name}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {`${AddComma(item.shipping_price)}원`}
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {`${AddComma(item.consumer_price)}원`}
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.count}</Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={7}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              재입고 알림 내역이 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  {pagination?.total_page > 0 ? (
                    <Pagination pagination={pagination} movePage={movePage} currentPage={nowPage} />
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <ConfirmModal
                modal={confirmExcelModal}
                setModal={setConfirmExcelModal}
                onSubmit={() => {
                  setConfirmExcelModal(false);
                  onClickExcelDown();
                }}
                description={`재입고 알림 신청 상품내역 전체를\n다운받으시겠습니까?`}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const SelectBox = styled.div`
  position: relative;
  flex: 1;

  @media all and (max-width: 1024px) {
    width: 100%;
    flex: unset;
  }
`;
