import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import styled from "styled-components";
import {AddComma} from "../../Utiles/Custom";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const ChartLine = (props) => {
    const {chart, check, setCheck} = props;

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                display: false,
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart',
            },
            tooltip: {
                titleColor: "#000",
                titleFont: { weight: "400", size: "10", family: "Montserrat" },
                bodyColor: "#1e1e1e",
                bodyFont: { weight: "bold", family: "Montserrat" },
                backgroundColor: "#fff",
                borderRadius: 10,
                borderColor: "rgba(0, 0, 0, 0.1)",
                borderWidth: 1,
                borderRadius: 10,
                padding: 14,
                filter: (item) => item.parsed.y !== null,
                callbacks: {
                  title: (context) => context[0].label,
                  label: (context) => {
                    return context.parsed.y !== null
                      ? AddComma(Math.round(context.parsed.y)) + "원"
                      : null;
                  },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                      family: "Montserrat",
                      size: 14,
                      color: "#000000",
                    },
                  },
                axis: 'x',
                position: 'bottom',
            },
            y: {
                axis: 'y',
                display: false,
                ticks: {
                    font: {
                      family: "Montserrat",
                      size: 14,
                      color: "#000000",
                    },
                  },
            },
        }
    };

    const labels = [

    ];

    // const ctx = canvas.getContext("2d");
    // const gradient = ctx.createLinearGradient(0, 0, 0, 100);
    // gradient.addColorStop(0, 'rgba(250,174,50,1)');   
    // gradient.addColorStop(1, 'rgba(250,174,50,0)');

    const data = {
        labels,
        datasets: [
            {
                // label: '시간당 평균(원) ',
                data: chart,
                borderColor: '#FF4A4A',
                backgroundColor: '#FF7440',
                borderWidth: 2,
                fill: {
                    target: 'origin',
                    above: 'rgba(255, 116, 64, 0.5)', 
                    below: 'blue',
                    // below: 'rgba(255, 116, 64, 0.1)',
                }
            },
        ],
    };

    
    return(

        <Container>
            <Line data={data} options={options}/>
        </Container>
    );
};

export default ChartLine;

const Container = styled.div`
  width: 100%;
  max-width: 900px;
  text-align: center;
  margin: 0 auto;
`;
