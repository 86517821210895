// 외부 모듈
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// 내부 모듈
import "./slideVer2.css";
import { Text } from "../../Assets/css/commonVer2";
import icoRight from "../../Assets/images/ico_arr06_right.png";
import icoDollar from "../../Assets/images/ico_dollar.png";

function TextSlider(props) {
  const navigate = useNavigate();
  const { space, perview, pagination, contents, styles, breaks, link } = props;
  // styles -> border, align

  return (
    <Swiper
      modules={[Pagination, A11y, Autoplay]}
      pagination={{
        clickable: true,
        el: `.${pagination}`,
        bulletClass: `swiper-pagination-bullet`,
      }}
      // autoplay={{ delay: 3000, disableOnInteraction: false }}
      autoplay={false}
      loop={true}
      breakpoints={breaks}
      className="textSwiper"
    >
      <>
        {contents?.map((e, i) => (
          <SwiperSlide key={i}>
            <div
              style={{
                width: "calc(100% - 10px)",
                background: "#fff",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                margin: "5px",
                padding: "31px 16px",
              }}
            >
              <SlideBox>
                <SlideText className={styles?.align}>
                  <Text
                    size={"16px"}
                    color={"#FF4A4A"}
                    weight={"bold"}
                    style={{
                      fontFamily: "Montserrat",
                      marginBottom: "10px",
                      textAlign: "left",
                    }}
                  >
                    {e.price}
                  </Text>
                  <Text color={"#1E1E1E"} size={"12px"} weight={"400"} align={"left"}>
                    {e.title}
                  </Text>
                </SlideText>
                <SlideLinkBtn
                  onClick={() => {
                    if (e.link) navigate(e.link);
                  }}
                >
                  {e.title === "잔여 예치금" ? (
                    <img
                      src={icoDollar}
                      style={{
                        position: "absolute",
                        top: "16px",
                        left: "20px",
                        height: "14px",
                      }}
                    />
                  ) : (
                    <img
                      src={icoRight}
                      style={{
                        position: "absolute",
                        top: "18px",
                        left: "18px",
                        width: "12px",
                        height: "12px",
                      }}
                    />
                  )}
                </SlideLinkBtn>
              </SlideBox>
            </div>
          </SwiperSlide>
        ))}
      </>
      <div className={`swiper-pagination ${pagination}`}></div>
    </Swiper>
  );
}

const SlideBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  /* overflow: hidden; */
`;

const SlideLinkBtn = styled.button`
  position: relative;
  width: 48px;
  height: 48px;
  background: rgb(255, 116, 64, 10%);
  border-radius: 50%;
`;

const SlideText = styled.div`
  padding-top: 10px;

  &.left * {
    text-align: left;
  }

  @media all and (max-width: 1024px) {
    padding-top: 5px;
  }
`;

export default TextSlider;
