import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { Btn, Container, Text, Title, ContentContainer, Wrapper } from "../../Assets/css/commonVer2";
import img_bear from "../../Assets/images/img_bear04.png";
import HeaderSub from "../../Components/Header/HeaderSub";
import Authority from "../../Components/Modal/Authority";

// 주문하기 > 완료

function OrderComplete() {
  const navigate = useNavigate();

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          <HeaderSub title={"주문완료"} />
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                <ContWrap>
                  <Title size={"26px"} color={"#1e1e1e"} weight={"700"} className="d-none d-md-block">
                    주문완료
                  </Title>
                  <img
                    src={img_bear}
                    className="w-30 md:w-auto mt-0 md:mt-5 mb-4 md:mb-1"
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                  <Text color={"#666"} weight={"500"}>
                    주문이 완료되었어요! <br />
                    덕구가 빠르게 배송해드릴게요!
                  </Text>
                  <HomeBtn
                    color={"#FF4A4A"}
                    background={"#fff"}
                    weight={"700"}
                    radius={"8px"}
                    border={"1px solid #FF4A4A"}
                    size={"14px"}
                    sizeMd={"18px"}
                    onClick={() => navigate("/")}
                  >
                    홈으로 돌아가기
                  </HomeBtn>
                </ContWrap>
              </Container>
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const ContWrap = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 30px 20px 50px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  background-color: #fff;
  text-align: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  // @media all and (max-width: 1024px) {
  //   padding: 0;
  //   border: 0;
  // }
`;
const HomeBtn = styled(Btn)`
  width: 100%;
  max-width: 320px;
  height: 60px;
  margin: 50px auto 0;

  @media all and (max-width: 1024px) {
    max-width: 156px;
    height: 40px;
    margin: 28px auto 0;
  }
`;

export default OrderComplete;
