import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import { Wrapper, Container, ContentContainer, FlexBox, Text, WhiteBox, Title, Btn } from "../../Assets/css/commonVer2";
import Header from "../../Components/Header/HeaderVer2";
import API from "../../Utiles/API";
import { AddComma, dateToString } from "../../Utiles/Custom";
import Authority from "../../Components/Modal/Authority";
import ico_pgprev from "../../Assets/images/ico_page_prev.svg";
import ico_pgnext from "../../Assets/images/ico_page_next.svg";
import img_pgnext_disable from "../../Assets/images/ico_page_next_disable.svg";
import { useMediaQuery } from "react-responsive";

function SalesCalendar() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const DAYS_OF_THE_WEEK = [
    { ko: "일", en: "SUN" },
    { ko: "월", en: "MON" },
    { ko: "화", en: "TUE" },
    { ko: "수", en: "WEN" },
    { ko: "목", en: "THU" },
    { ko: "금", en: "FRI" },
    { ko: "토", en: "SAT" },
  ];
  const MONTHS = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

  const today = new Date();
  const [date, setDate] = useState(today);
  const [day, setDay] = useState(date.getDate());
  const [month, setMonth] = useState(date.getMonth());
  const [year, setYear] = useState(date.getFullYear());
  const [startDay, setStartDay] = useState(getStartDayOfMonth(date));
  const [modal, setModal] = useState(true);
  const [Franchise_id] = useState(sessionStorage.getItem("franchise_id"));
  const [month_count, setMonth_Count] = useState([]);
  const [day_count, setDay_Count] = useState([]);
  const [list, setList] = useState([]);

  const getCalender = async () => {
    try {
      const res = await API.get(`/storeowner/sale-calender/${Franchise_id}?filtermonth=${year}-${MONTHS[month]}`);
      if (res.data.status) {
        if (res.data.result === undefined) {
          setMonth_Count("");
        } else {
          setMonth_Count(res.data.result.mount_total);
        }
        setDay_Count(res.data.result.day_history);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function getStartDayOfMonth(date) {
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    return startDate === 0 ? 7 : startDate;
  }

  function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  const days = isLeapYear(year) ? DAYS_LEAP : DAYS;

  const ClickModal = (date) => {
    var day_data = day_count.filter(function (person) {
      return person.date == dateToString(date);
    });

    var undate = [
      {
        date: dateToString(date),
        in_amount: "",
        out_amount: "",
        etc: "",
        deposit_balance: "",
      },
    ];
    setDate(date);
    setList(day_data.length > 0 ? day_data : undate);
  };

  var sliderTemp = [];
  for (var i = 0; i < day_count.length; i++) {
    var values = [day_count[i].in_amount, day_count[i].out_amount, day_count[i].deposit_balance, day_count[i].etc];
    sliderTemp[day_count[i].date] = values;
  }

  const todayData = sliderTemp[dateToString(today)]; // 처음 진입 시 보여줄 오늘 매출/매입/기타/예치금 데이터

  useEffect(() => {
    setDay(date.getDate());
    setMonth(date.getMonth());
    setYear(date.getFullYear());
    setStartDay(getStartDayOfMonth(date));
  }, [date]);

  useEffect(() => {
    getCalender();
  }, MONTHS[month]);

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          {isMobile && <Header title={"매출 캘린더"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"} style={{ margin: "0 0 10px 0" }}>
                    매출 캘린더 🗓
                  </Title>
                )}

                <CalFlexBox>
                  <LeftWhiteBox>
                    <Header1>
                      <CalBoxBtn onClick={() => setDate(new Date(year, month - 1, day))}>
                        {isMobile ? (
                          <img width={"8px"} src={ico_pgprev} alt="이전 달" />
                        ) : (
                          <img width={"10px"} src={ico_pgprev} alt="이전 달" />
                        )}
                      </CalBoxBtn>
                      <Text
                        sizeMd={"14px"}
                        size={"12px"}
                        weight={"700"}
                        color={"#1E1E1E"}
                        style={{ wordBreak: "keep-all" }}
                      >
                        {year}년 {MONTHS[month]}월
                      </Text>
                      <CalBoxBtn
                        onClick={() => setDate(new Date(year, month + 1, day))}
                        disabled={
                          year < today.getFullYear() ||
                          (year === today.getFullYear() && parseInt(MONTHS[month]) <= today.getMonth())
                            ? false
                            : true
                        }
                      >
                        {isMobile ? (
                          <img
                            width={"8px"}
                            src={
                              year < today.getFullYear() ||
                              (year === today.getFullYear() && parseInt(MONTHS[month]) <= today.getMonth())
                                ? ico_pgnext
                                : img_pgnext_disable
                            }
                            alt="다음 달"
                          />
                        ) : (
                          <img
                            width={"10px"}
                            src={
                              year < today.getFullYear() ||
                              (year === today.getFullYear() && parseInt(MONTHS[month]) <= today.getMonth())
                                ? ico_pgnext
                                : img_pgnext_disable
                            }
                            alt="다음 달"
                          />
                        )}
                      </CalBoxBtn>
                    </Header1>
                    <FlexBox>
                      <Frame style={{ width: "100%" }}>
                        <Body>
                          {DAYS_OF_THE_WEEK.map((d) => (
                            <Day key={d}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <strong>{d.ko}</strong>
                                <EnWeekName>{d.en}</EnWeekName>
                              </div>
                            </Day>
                          ))}
                          {Array(days[month] + startDay)
                            .fill(null)
                            .map((_, index) => {
                              const d = index - (startDay - 1);
                              const nowday = month + 1 + "." + d;
                              const checkday = today.getMonth() + 1 + "." + today.getDate();

                              var new_month = parseInt(month) + 1;
                              new_month = new_month < 10 ? "0" + new_month : new_month;

                              var new_d = d < 10 ? "0" + d : d;
                              const new_date = year + "-" + new_month + "-" + new_d;

                              return (
                                <Day2
                                  key={index}
                                  isSelected={d === day}
                                  onClick={(props) => ClickModal(new Date(year, month, d))}
                                >
                                  <FlexBox direction={"column"} width={"100%"}>
                                    <FlexBox style={{ justifyContent: "flex-end", marginLeft: "auto" }}>
                                      {nowday === checkday ? (
                                        <IsTodayCircle>
                                          <TodayText>{d > 0 ? d : ""}</TodayText>
                                        </IsTodayCircle>
                                      ) : d > 0 ? (
                                        d
                                      ) : (
                                        ""
                                      )}
                                    </FlexBox>
                                    <FlexBox
                                      direction={"row"}
                                      directionMd={"column"}
                                      margin={"0 0 0 auto"}
                                      marginMd={"0 auto 0 0"}
                                      style={{ justifyContent: "flex-start" }}
                                    >
                                      {/* 매출, 매입, 예치금, 기타 순서 */}
                                      {sliderTemp[new_date]?.[0] != undefined &&
                                      sliderTemp[new_date]?.[0] != null &&
                                      sliderTemp[new_date]?.[0] != 0 ? (
                                        <FlexBox padding={"0 0 5px 0"}>
                                          <ColorBox bgColor={"#FF4A4A"}></ColorBox>
                                          <Text size={"12px"} color={"#000"} className={"d-none d-md-flex"}>
                                            {AddComma(sliderTemp[new_date][0])}원
                                          </Text>
                                        </FlexBox>
                                      ) : (
                                        ""
                                      )}
                                      {sliderTemp[new_date]?.[1] != undefined &&
                                      sliderTemp[new_date]?.[1] != null &&
                                      sliderTemp[new_date]?.[1] != 0 ? (
                                        <FlexBox padding={"0 0 5px 0"}>
                                          <ColorBox bgColor={"#FFEB82"}></ColorBox>
                                          <Text size={"12px"} color={"#000"} className={"d-none d-md-flex"}>
                                            {AddComma(sliderTemp[new_date][1])}원
                                          </Text>
                                        </FlexBox>
                                      ) : (
                                        ""
                                      )}
                                      {sliderTemp[new_date]?.[2] != undefined &&
                                      sliderTemp[new_date]?.[2] != null &&
                                      sliderTemp[new_date]?.[2] != 0 ? (
                                        <FlexBox padding={"0 0 5px 0"}>
                                          <ColorBox bgColor={"#666"}></ColorBox>
                                          <Text size={"12px"} color={"#000"} className={"d-none d-md-flex"}>
                                            {AddComma(sliderTemp[new_date][2])}원
                                          </Text>
                                        </FlexBox>
                                      ) : (
                                        ""
                                      )}
                                      {sliderTemp[new_date]?.[3] != undefined &&
                                      sliderTemp[new_date]?.[3] != null &&
                                      sliderTemp[new_date]?.[3] != 0 ? (
                                        <FlexBox padding={"0 0 5px 0"}>
                                          <ColorBox bgColor={"#2DD057"}></ColorBox>
                                          <Text size={"12px"} color={"#000"} className={"d-none d-md-flex"}>
                                            {AddComma(sliderTemp[new_date][3])}원
                                          </Text>
                                        </FlexBox>
                                      ) : (
                                        ""
                                      )}
                                    </FlexBox>
                                  </FlexBox>
                                </Day2>
                              );
                            })}
                        </Body>
                      </Frame>
                    </FlexBox>
                  </LeftWhiteBox>
                  <RightWhiteBox>
                    <Text
                      size="14px"
                      sizeMd="20px"
                      weight="bold"
                      color="#1E1E1E"
                      style={{
                        fontFamily: "Montserrat",
                      }}
                    >
                      {list.length > 0 ? list[0].date : dateToString(today)}
                      <br />
                      매출 조회
                    </Text>
                    <Line />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        // gap: "326px",//수정
                      }}
                    >
                      <CardWrap>
                        <CardBox className="total">
                          <CardLine className="total" />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Text size={"14px"} sizeMd={"20px"} color={"#1E1E1E"}>
                              {parseInt(MONTHS[month])}월 총 매출
                            </Text>
                            <CardSpan>{AddComma(month_count.sale_sum)}원</CardSpan>
                          </div>
                        </CardBox>
                        <CardBox className="total">
                          <CardLine className="total" />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Text size={"14px"} sizeMd={"20px"} color={"#1E1E1E"}>
                              {parseInt(MONTHS[month])}월 총 매입
                            </Text>
                            <CardSpan>{AddComma(month_count.purchase_sum)}원</CardSpan>
                          </div>
                        </CardBox>
                      </CardWrap>
                      <div>
                        <CardBox>
                          <CardLine />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Text size={"14px"} sizeMd={"18px"} color={"#1E1E1E"}>
                              매출
                            </Text>
                            <CardSpan>
                              {list.length > 0 ? AddComma(list[0].in_amount) : todayData ? AddComma(todayData[0]) : 0}원
                            </CardSpan>
                          </div>
                        </CardBox>
                        <CardBox>
                          <CardLine style={{ background: "#FFEB82" }} />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Text size={"14px"} sizeMd={"18px"} color={"#1E1E1E"}>
                              매입
                            </Text>
                            <CardSpan>
                              {list.length > 0 ? AddComma(list[0].out_amount) : todayData ? AddComma(todayData[1]) : 0}
                              원
                            </CardSpan>
                          </div>
                        </CardBox>
                        <CardBox>
                          <CardLine style={{ background: "#2DD057" }} />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Text size={"14px"} sizeMd={"18px"} color={"#1E1E1E"}>
                              기타
                            </Text>
                            <CardSpan>
                              {list.length > 0 ? AddComma(list[0].etc) : todayData ? AddComma(todayData[3]) : 0}원
                            </CardSpan>
                          </div>
                        </CardBox>
                        <CardBox>
                          <CardLine style={{ background: "#666" }} />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Text size={"14px"} sizeMd={"18px"} color={"#1E1s1E"}>
                              예치금
                            </Text>
                            <CardSpan>
                              {list.length > 0
                                ? AddComma(list[0].deposit_balance)
                                : todayData
                                ? AddComma(todayData[2])
                                : 0}
                              원
                            </CardSpan>
                          </div>
                        </CardBox>
                      </div>
                    </div>
                  </RightWhiteBox>
                </CalFlexBox>
              </Container>
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const CalFlexBox = styled(FlexBox)`
  justify-content: space-between;
  align-items: start;
  flex-direction: row;

  @media all and (max-width: 1260px) {
    flex-direction: column;
  }
`;

const LeftWhiteBox = styled(WhiteBox)`
  width: 67%;
  margin: 0 0;
  @media all and (max-width: 1260px) {
    width: 100%;
    margin: 10px 0 30px;
  }
`;

const RightWhiteBox = styled(WhiteBox)`
  width: 32%;
  margin: 0 0;
  @media all and (max-width: 1260px) {
    width: 100%;
    // margin: 0 0 30px;
  }
`;

const Frame = styled.div`
  //width: 100%;
  width: 66%;
  /* border: 1px solid #c0c0c0; */
  min-height: 364px;
  background-color: #fff;

  @media all and (max-width: 1024px) {
    margin-bottom: 20px;
    width: 100%;
  }
  //box-shadow: 2px 2px 2px #eee;
`;

const Header1 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #f2f2f2;
  margin: 0 auto;
`;
const CalBoxBtn = styled(Btn)`
  padding: 8px 14px;
  border: 0;

  :hover {
    background: unset;
  }
`;
const Body = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  margin-top: 40px;
  // padding: 10px;
  /* border-radius: 0 0 8px 8px; */
  @media all and (max-width: 1024px) {
    margin-top: 10px;
  }
`;
const Day = styled.div`
  width: 14.2%;
  /* height: 40px; */
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  @media all and (max-width: 1024px) {
    height: 50px;
  }
`;
const Day2 = styled.div`
  width: 14.2%;
  height: 130px;
  display: flex;
  align-items: flex-start;
  // justify-content: right;
  cursor: pointer;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: bold;
  padding: 10px;

  border-top: 1px solid #c7c7c7;
  /* ${(props) =>
    props.isToday &&
    css`
      background-color: rgba(255, 116, 64, 0.1);
    `} */
  ${(props) =>
    props.isSelected &&
    css`
      background-color: rgba(255, 116, 64, 0.1);
    `}
  :hover {
    background-color: rgba(255, 116, 64, 0.1);
  }
  @media all and (max-width: 1024px) {
    height: 50px;
    padding: unset;
    padding: 4px;
  }
`;
const IsTodayCircle = styled.div`
  position: relative;
  background: #ff4a4a;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: #fff;
`;
const TodayText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const EnWeekName = styled.p`
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  color: "#1E1E1E";
  margin-top: 9px;

  @media all and (max-width: 1024px) {
    font-size: 10px;
    margin-top: 4px;
  }
`;
const CardWrap = styled(FlexBox)`
  flex-direction: column;
  gap: 10px;
  margin-bottom: 350px;
  @media all and (max-width: 1024px) {
    margin-bottom: 20px;
  }
`;
const CardBox = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 6px;
  border-radius: 6px;

  &.total {
    background: rgba(255, 116, 64, 0.1);
  }

  @media all and (max-width: 1024px) {
    padding: 5px 6px;
  }
`;

const CardLine = styled.div`
  width: 4px;
  height: 32px;
  border-radius: 4px;
  background: #ff4a4a;
  margin-right: 10px;

  &.total {
    height: 54px;
    background: #ff4a4a;
  }
`;
const ColorBox = styled.div`
  background-color: ${(props) => props.bgColor || ""};
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 5px;
`;
const CardSpan = styled.span`
  display: block;
  font-family: "Montserrat";
  font-size: 20px;
  color: #1e1e1e;
  font-weight: bold;

  @media all and (max-width: 1024px) {
    font-size: 14px;
  }
`;
const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #f2f2f2;
  margin: 20px 0;
`;

export default SalesCalendar;
