import React, { } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import "../../Assets/css/DatePicker.css"
import { dateToString } from '../../Utiles/dateToString';

import {
  Btn,
  FlexBox,
  DateInputBox,
  SearchInput
} from '../../Assets/css/commonVer2';
import DatePicker from "react-datepicker";
import FranSelect from '../Select/FranSelect';
import { handleKeyDown } from "../../Utiles/Custom";

/* code from sub_outsourcing */

export default function SearchBar({
  searchState, // 검색 객체
  dispatchSearchState,
  franShow,
  setFranShow,
  franText,
  setFranText,
  searchFunction, //dispatchSearchState 값 변경 함수
  setSearchFranchise, // 가맹점 검색 결과를 돌려 받을 state
  setInputNumber, // 반품 번호 입력 받을 state
  inputNumber,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {

  return (
    <>
      <FlexBox
        justify={"end"}
        gap={"10px"}
        wrap={"wrap"}
      >
        <FlexBox
          gap={"10px"}
        >
          <DateInputBox>
            <DatePicker
              dateFormatCalendar="yyyy년 MM월"
              selected={startDate}
              onChange={(date) => {
                setStartDate(date)
                dispatchSearchState({ type: "CHANGE_START_DATE", value: dateToString(date) + ' 00:00:00', })
              }}
              dateFormat="yyyy-MM-dd"
              maxDate={endDate}
              startDate={startDate}
              endDate={endDate}
            />
          </DateInputBox>

          <DateInputBox>
            <DatePicker
              dateFormatCalendar="yyyy년 MM월"
              selected={endDate}
              onChange={(date) => {
                setEndDate(date)
                dispatchSearchState({ type: "CHANGE_END_DATE", value: dateToString(date) + ' 23:59:59', })
              }}
              dateFormat="yyyy-MM-dd"
              minDate={startDate}
              startDate={startDate}
              endDate={endDate}
            />

          </DateInputBox>
        </FlexBox>

        <FranSelect
          width={"200px"}
          widthMd={"100%"}
          gubun={"fran"}
          placeholder={"가맹점을"}
          show={franShow}
          setFranShow={setFranShow}
          selectBtnText={franText}
          setTextChange={setFranText}
          setIdxChange={setSearchFranchise}
        />

        <FlexBox
          gap={"10px"}
          wrap={"wrap"}
          wrapMd={"noWrap"}
          width={"100%"}
          widthMd={"unset"}
        >
          <SearchInput
            width={"100%"}
            widthMd={"262px"}
            borderBottom={"1px solid #666"}
            borderBottomColor={"#ff4a4a"}
            transition={"border-color 0.3s ease-in-out"}
            placeholder={"반품 번호를 입력해주세요."}
            type="number"
            pattern="[0-9]+"
            value={inputNumber}
            onChange={(e) => {
              setInputNumber(parseInt(e.target.value));
            }}
            onKeyDown={(event) => handleKeyDown(event, searchFunction)}
          />
          <Btn
            color={"#fff"}
            background={"#FF4A4A"}
            size={"14px"}
            weight={"700"}
            width={"100%"}
            widthMd={"unset"}
            padding={"8px 18px"}
            lineHeight={"22px"}
            style={{ flexShrink: 0 }}
            onClick={searchFunction}
          >
            검 색
          </Btn>
        </FlexBox>
      </FlexBox>
    </>
  )
}
