// 외부 모듈
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// 내부 모듈
// import img_noimage470 from "../../Assets/images/no_image_470px.jpg";
import img_noimage470 from "../../Assets/images/notice_img01.jpg";
import "./slideVer2.css"

function EventSlider(props) {
  const navigate = useNavigate();
  const {
    space,
    perview,
    pagination,
    contents,
    styles,
    link,
  } = props;
  // styles -> border, align

  return (
    <Swiper
      modules={[Pagination, A11y, Autoplay]}
      pagination={{
        clickable: true,
        el: `.${pagination}`,
        bulletClass: `swiper-pagination-bullet`,
      }}
      // autoplay={{ delay: 2000, disableOnInteraction: false }}
      autoplay={false}
      loop={true}
      spaceBetween={space}
      slidesPerView={perview}
      className="eventSwiper"
    >
      <>
        {contents?.map((e, i) => (
          <SwiperSlide
            key={i}
            style={{ cursor: link ? "pointer" : "" }}
            onClick={() => {
              if (link) navigate(link, { state: { idx: e.idx } });
            }}
          >
            <SlideImg border={styles?.border}>
                <img
                  src={
                    e.image_path_1
                      ? `${process.env.REACT_APP_S3_KEY}${e.image_path_1}`
                      : img_noimage470
                  }
                />
            </SlideImg>
          </SwiperSlide>
        ))}
      </>
      <div className={`swiper-pagination ${pagination}`}></div>
    </Swiper>
  );
}

const SlideImg = styled.div`
  position: relative;
  padding-bottom: 50%;

  img {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
  }
`

export default EventSlider;