import React, { useState } from "react";
import styled from "styled-components";
import { Text } from "../../Assets/css/common";
import close from "../../Assets/images/close.png";
import { Btn, FlexBox, TextInput } from "../../Assets/css/commonVer2";
import SearchInputPop from "../SearchInput/SearchInput";
import { getProduct } from "../../Utiles";
import { SEARCH_TYPE_LIST } from "../../Utiles/ListData";
import BasicSelect from "../../Components/Select/BasicSelect";
import InfoModal from "../../Components/Modal/InfoModal";

// 리뉴얼 UI : 사용O

/**
 * 1. 상품 검색
 * 1-1. 상품명은 검색해서 넘겨줌
 * 1-2. 바코드 검색하면 있는지 체크하고 있으면 넘기고 없으면 안내문 띄워야함
 *
 */

export default function AddProductModal(props) {
  const { modal, setModal, onSubmit = () => {} } = props;

  const [barcodeNum, setBarcodeNum] = useState("");
  const [productItem, setproductItem] = useState({});
  const [filter, setFilter] = useState("product_name");

  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");

  const onClose = () => {
    setModal(false);
  };

  const handleSelect = (selectedValue) => {
    setproductItem(selectedValue);
  };

  const addProduct = () => {
    const selectedBarcodeNum = filter === "product_name" ? productItem.barcode_num : barcodeNum;

    getProduct(selectedBarcodeNum).then((res) => {
      if (res.status) {
        onSubmit(res.results);
      } else {
        setTextMsg("없는 상품입니다.");
        setTextModal(true);
      }
    });
  };

  return (
    <>
      {modal ? (
        <ModalBg>
          <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
          <ModalConts>
            <CancelBtn onClick={onClose}>
              <img src={close} />
            </CancelBtn>
            <Text
              color={"#1E1E1E"}
              size={"16px"}
              weight={"500"}
              lineHeight={"22px"}
              style={{
                whiteSpace: "pre-wrap",
                marginBottom: "10px",
              }}
            >
              상품 검색
            </Text>
            <FlexBox direction={"column"} gap={"10px"} width={"100%"}>
              <BasicSelect
                width={"100%"}
                data={SEARCH_TYPE_LIST}
                selectedValue={filter}
                setSelectedValue={setFilter}
                canSelectAll={false}
              />
              {filter == "barcode_num" && (
                <TextInput
                  color={"#2d2d2d"}
                  value={barcodeNum || ""}
                  weight={"230"}
                  align={"left"}
                  style={{ width: "100%" }}
                  type="number"
                  onInput={(e) => {
                    setBarcodeNum(e.target.value);
                  }}
                  placeholder="바코드 번호를 입력해 주세요"
                />
              )}
              {filter == "product_name" && (
                <div style={{ position: "relative", width: "100%" }}>
                  <SearchInputPop uiType={2} onSelect={handleSelect} />
                </div>
              )}
            </FlexBox>

            <FlexBox margin={"20px 0 0"} marginMd={"22px 0 0"}>
              {/* <Btn
                background={"#f2f2f2"}
                color={"#666"}
                border={"none"}
                weight={"500"}
                margin={"0 10px 0 0"}
                size={"12px"}
                sizeMd={"14px"}
                padding={"9px 19px"}
                paddingMd={"9px 19px"}
                onClick={onClose}
              >
                취소
              </Btn> */}
              <Btn
                background={"#FF4A4A"}
                color={"#fff"}
                border={"none"}
                weight={"700"}
                size={"12px"}
                sizeMd={"14px"}
                padding={"9px 19px"}
                paddingMd={"9px 19px"}
                onClick={addProduct}
              >
                추가
              </Btn>
            </FlexBox>
          </ModalConts>
        </ModalBg>
      ) : (
        ""
      )}
    </>
  );
}

const ModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

const ModalConts = styled.div`
  background-color: #fff;
  padding: 44px 30px 23px;
  max-width: 360px;
  width: 100%;
  max-height: 251px;
  border-radius: 10px;
  position: relative;

  @media all and (max-width: 1024px) {
    max-width: 280px;
    padding: 40px 20px 30px;
  }
`;

const CancelBtn = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;

  @media all and (max-width: 1024px) {
    top: 12px;
    right: 12px;
  }
`;
