import React from "react";
import styled from "styled-components";
import { FlexBox, Text, Title } from "../../Assets/css/commonVer2";
import Slider from "../../Components/Slider/Slider";
import SliderBtn from "../../Components/Slider/SliderBtn";

// 가장 많이 판매된 상품

function HomeSell(props) {
  const { shop } = props;

  var sliderTemp = shop.map(function (item) {
    return {
      idx: item?.product.idx, //item.product?.idx
      image_path_1: item ? item.product.image_path_1 : "",
      text: item ? item.product.product_name : "",
      sale_status: item ? item.product.sale_status : "sale",
    };
  });

  return (
    <>
      {/* 모바일 */}
      <div className={"d-block d-md-none"}>
        <Title size={"18px"} color={"#1E1E1E"} weight={"bold"}>
          가장 많이 판매된 상품이에요! 👍🏻
        </Title>
        <SellConts>
          <FlexBox justify={"space-between"}>
            <Text
              size={"14px"}
              weight={"bold"}
              color={"#666"}
              style={{
                fontFamily: "Montserrat",
              }}
            >
              BEST ITEM
            </Text>
            <SliderBtn prevClass={"bestPrev"} nextClass={"bestNext"} />
          </FlexBox>
          <div style={{ marginTop: "14px" }}>
            <Slider
              naviPrev={"bestPrev"}
              naviNext={"bestNext"}
              contents={sliderTemp}
              styles={{ border: true, align: "left" }}
              breaks={{
                0: {
                  slidesPerView: 2.5,
                  spaceBetween: 10,
                },
              }}
              link={"/goods/view"}
            />
          </div>
        </SellConts>
      </div>
      {/* 웹 */}
      <div className="d-none d-md-block">
        <Title
          size={"26px"}
          color={"#1E1E1E"}
          weight={"bold"}
          style={{
            marginTop: "30px",
          }}
        >
          가장 많이 판매된 상품이에요! 👍🏻
        </Title>
        <SellConts>
          <FlexBox justify={"space-between"}>
            <Text
              size={"16px"}
              weight={"bold"}
              color={"#666"}
              style={{
                fontFamily: "Montserrat",
              }}
            >
              BEST ITEM
            </Text>
            <SliderBtn prevClass={"bestPrev"} nextClass={"bestNext"} />
          </FlexBox>
          <div style={{ marginTop: "14px" }}>
            <Slider
              naviPrev={"bestPrev"}
              naviNext={"bestNext"}
              contents={sliderTemp}
              styles={{ border: true, align: "left" }}
              breaks={{
                0: {
                  slidesPerView: 3.6,
                  spaceBetween: 26,
                },
              }}
              link={"/goods/view"}
            />
          </div>
        </SellConts>
      </div>
    </>
  );
}

export default HomeSell;

const SellConts = styled.div`
  height: 100%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 32px 40px;
  margin-top: 10px;
  overflow: hidden;
  min-height: 397px;

  @media (max-width: 1024px) {
    padding: 15px 17px;
    min-height: 180px;
  }
`;
