import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { ContentContainer, Container, FlexBox, Text, Title, ToggleLabel } from "../../Assets/css/commonVer2";
import HeaderSub from "../../Components/Header/HeaderSub";
import API from "../../Utiles/API";
import InfoModal from "../../Components/Modal/InfoModal";
import ico_close from "../../Assets/images/ico_close.svg";

// 마이페이지 > 알림설정

function Alarm() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();
  const [checkItems, setCheckItems] = useState([]);
  const [Franchise_id] = useState(sessionStorage.getItem("user_id"));
  const [notice, setNotice] = useState();
  const [nine, setNine] = useState();
  const [etc, setEtc] = useState();
  const [all, setAll] = useState();

  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");

  // API
  const Myinfo = async () => {
    try {
      const res = await API.get(`/storeowner/mypage/alert/${Franchise_id}}`);
      if (res.data.status) {
        setNotice(res.data.result.notice_alert_enabled);
        setNine(res.data.result.nine_alert_enabled);
        setEtc(res.data.result.etc_alert_enabled);
        setAll(res.data.result.all_alert_enabled);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChk = (checked, id) => {
    let notice1 = notice;
    let nine1 = nine;
    let etc1 = etc;
    let all1 = all;

    if (checked) {
      if (id === 1) {
        notice1 = 1;
      } else if (id === 2) {
        nine1 = 1;
      } else if (id === 3) {
        etc1 = 1;
      } else if (id === 4) {
        all1 = 1;
        notice1 = 1;
        nine1 = 1;
        etc1 = 1;
      }
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));

      if (id === 1) {
        notice1 = 0;
      } else if (id === 2) {
        nine1 = 0;
      } else if (id === 3) {
        etc1 = 0;
      } else if (id === 4) {
        all1 = 0;
        notice1 = 0;
        nine1 = 0;
        etc1 = 0;
      }
    }

    if (notice1 === 1 && nine1 === 1 && etc1 === 1) {
      all1 = 1;
    } else {
      all1 = 0;
    }

    API.put(`/storeowner/mypage/alert/${Franchise_id}`, {
      all_alert_enabled: all1,
      notice_alert_enabled: notice1,
      nine_alert_enabled: nine1,
      etc_alert_enabled: etc1,
    })
      .then(function (res) {
        if (res.data.status) {
          Myinfo();
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(function (error) {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  useEffect(() => {
    Myinfo();
  }, []);

  return (
    <>
      <HeaderSub title={"알림설정"} />
      <ContentContainer>
        <Container>
          <ContWrap>
            <FlexBox justify={"end"} margin={"0 0 40px"}>
              <img src={ico_close} onClick={() => navigate(-1, { replace: true })} />
            </FlexBox>

            {!isMobile && (
              <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                알림설정 🔔
              </Title>
            )}

            <MypageBtn margin={"30px 0 0"}>
              <Text size={"14px"} sizeMd={"18px"} color={"#1E1E1E"} lineSpacing={"-0.36px"}>
                전체 알림 받기
              </Text>
              <ToggleLabel>
                <input
                  type="checkbox"
                  id="alarmAll"
                  onChange={(event) => handleChk(event.target.checked, 4)}
                  checked={all && all === 1 ? true : false}
                />
                <div className="bg">
                  <div className="circle"></div>
                </div>
              </ToggleLabel>
            </MypageBtn>

            <MypageBtn margin={"30px 0 0"}>
              <Text size={"14px"} sizeMd={"18px"} color={"#1E1E1E"} lineSpacing={"-0.36px"}>
                공지 알림 받기
              </Text>
              <ToggleLabel>
                <input
                  type="checkbox"
                  onChange={(event) => handleChk(event.target.checked, 1)}
                  checked={notice && notice === 1 ? true : false}
                />
                <div className="bg">
                  <div className="circle"></div>
                </div>
              </ToggleLabel>
            </MypageBtn>
            <MypageBtn margin={"20px 0 0"}>
              <Text size={"14px"} sizeMd={"18px"} color={"#1E1E1E"} lineSpacing={"-0.36px"}>
                매출 알림 받기
              </Text>
              <ToggleLabel>
                <input
                  type="checkbox"
                  onChange={(event) => handleChk(event.target.checked, 2)}
                  checked={nine && nine == 1 ? true : false}
                />
                <div className="bg">
                  <div className="circle"></div>
                </div>
              </ToggleLabel>
            </MypageBtn>
            <MypageBtn margin={"20px 0 0"}>
              <Text size={"14px"} sizeMd={"18px"} color={"#1E1E1E"} lineSpacing={"-0.36px"}>
                기타 알림 받기
              </Text>
              <ToggleLabel>
                <input
                  type="checkbox"
                  onChange={(event) => handleChk(event.target.checked, 3)}
                  checked={etc && etc === 1 ? true : false}
                />
                <div className="bg">
                  <div className="circle"></div>
                </div>
              </ToggleLabel>
            </MypageBtn>
          </ContWrap>
        </Container>
      </ContentContainer>
      <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
    </>
  );
}

const ContWrap = styled.div`
  max-width: 558px;
  margin: 0 auto;
  padding: 40px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
  text-align: center;

  @media all and (max-width: 1024px) {
    box-shadow: unset;
    border: unset;
    width: 100%;
    padding: 0;
    background-color: unset;
    margin: 20px 0;
  }
`;

const MypageBtn = styled(FlexBox)`
  justify-content: space-between;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  border-radius: 10px;

  @media all and (max-width: 1024px) {
  }
`;

export default Alarm;
