import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  Btn,
  Container,
  Contents,
  TextInput,
  Wrapper,
  FlexBox,
  Text,
  Title,
  TextInputArea,
  ContentContainer,
  WhiteBox,
  UploadFileBtn,
  FileImg,
  FileXBtn,
  RadioLabel,
  RadioIcon,
} from "../../Assets/css/commonVer2";

import ico_x from "../../Assets/images/ico_close.png";

import HeaderSub from "../../Components/Header/HeaderSub";
import API from "../../Utiles/API";
import { useCallback } from "react";
import formAPI from "../../Utiles/formAPI";
import Authority from "../../Components/Modal/Authority";
import { AddComma } from "../../Utiles/Custom";
import { useMediaQuery } from "react-responsive";
import Loading from "../../Components/Loading";
import InfoModal from "../../Components/Modal/InfoModal";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import BasicSelect from "../../Components/Select/BasicSelect";
import Cate1Select from "../../Components/Select/Cate1Select";
import Cate2Select from "../../Components/Select/Cate2Select";

// 상품마스터 > 전체상품관리 > 상품수정

function ProductEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const idx = location.state.idx;

  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const [values, setValues] = useState({
    barcode_num: "",
    category1st_id: "",
    category2nd_id: "",
    brand: "",
    product_name: "",
    purchase_price: "",
    purchase_quantity: "",
    purchase_cost: "",
    shipping_price: "",
    basic_shipment_quantity: "",
    shipping_cost: "",
    consumer_price: "",
    supplier: "",
    details_description: "",
    order_unit: "",
    product_quantity: "",
  });

  const [priceValues, setPriceValues] = useState({
    purchase_price: "",
    purchase_quantity: "",
    purchase_cost: "",
    shipping_price: "",
    basic_shipment_quantity: "",
    shipping_cost: "",
    consumer_price: "",
    product_quantity: "",
  });

  const imgRef = useRef();
  const [showFileBtn, setShowFileBtn] = useState(true);
  const [imgFile, setImgFile] = useState([null, null, null, null, null]);
  const [sendImg, setSendImg] = useState([null, null, null, null, null]);
  const [img, setImg] = useState([]);
  const [image_path_1, setImage_path_1] = useState(null);
  const [image_path_2, setImage_path_2] = useState(null);
  const [image_path_3, setImage_path_3] = useState(null);
  const [orderUnit, setOrderUnit] = useState([]);

  const [cate1Value, setCate1Value] = useState("");
  const [cate2Value, setCate2Value] = useState("");
  const [orderUnitValue, setOrderUnitValue] = useState("");
  const [status, setStatus] = useState("");
  const [saveModal, setSaveModal] = useState(false);
  const [listModal, setListModal] = useState(false);

  const [textModal, setTextModal] = useState(false); // 모달 창 표시 여부 상태
  const [textMsg, setTextMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [goBackModal, setGoBackModal] = useState(false);

  const getProduct = async () => {
    const { data } = await API.get(`/admin/product/${idx}`);
    if (data.status) {
      setValues(data.result);
      setStatus(data.result.sale_status);
      setCate1Value(data.result.category1st_id);
      setCate2Value(data.result.category2nd_id);

      setImg(Object.entries(data.result).filter((e, i) => i >= 0 && i <= 2));

      setImage_path_1(data.result.image_path_1);
      setImage_path_2(data.result.image_path_2);
      setImage_path_3(data.result.image_path_3);

      const keysToFormat = [
        "purchase_price",
        "purchase_quantity",
        "purchase_cost",
        "shipping_price",
        "basic_shipment_quantity",
        "shipping_cost",
        "consumer_price",
        "product_quantity",
      ];
      const formattedValues = {};
      keysToFormat.forEach((key) => {
        if (data.result[key]) {
          formattedValues[key] = AddComma(data.result[key]);
        }
      });
      setPriceValues(formattedValues);
    }
  };

  // 발주단위 데이터 가져오기
  const getOrderUnit = async () => {
    const { data } = await API.get(`/admin/product/order-unit`);
    if (data.status) {
      let tempOrderUnit = data.results.map((item, idx) => {
        return {
          value: idx,
          text: item,
        };
      });
      setOrderUnit(tempOrderUnit);
    }
  };

  const putProduct = useCallback(async () => {
    let formData = new FormData();
    if (!cate2Value) {
      alert("상품분류를 선택해주세요.");
      return;
    }
    if (!values.barcode_num) {
      alert("바코드번호를 입력해주세요.");
      return;
    }
    if (!values.product_name) {
      alert("상품명을 입력해주세요.");
      return;
    }
    if (!values.purchase_price) {
      alert("매입가를 입력해주세요.");
      return;
    }
    if (!values.purchase_quantity) {
      alert("매입수량을 입력해주세요.");
      return;
    }
    if (!values.shipping_price) {
      alert("출하가를 입력해주세요.");
      return;
    }
    if (!values.basic_shipment_quantity) {
      alert("기본 출하 수량을 입력해주세요.");
      return;
    }
    if (!values.shipping_cost) {
      alert("출하비용이 없습니다.");
      return;
    }
    if (!values.consumer_price) {
      alert("소비자가를 입력해주세요.");
      return;
    }
    if (values.consumer_price % 100 !== 0) {
      setTextMsg("소비자가는 100원 단위로 \n등록할 수 있습니다.");
      setTextModal(true);
      return;
    }
    if (!values.supplier) {
      alert("공급업체를 입력해주세요.");
      return;
    }
    if (!values.order_unit) {
      alert("발주단위를 입력해주세요.");
      return;
    }
    if (!values.product_quantity) {
      alert("상품갯수를 입력해주세요.");
      return;
    }
    setLoading(true);
    formData.append("franchise_id", 1);
    formData.append("sale_status", status);
    formData.append("category1st_id", 1);
    formData.append("category2nd_id", cate2Value);
    formData.append("barcode_num", values.barcode_num);
    formData.append("product_name", values.product_name);
    formData.append("purchase_price", values.purchase_price);
    formData.append("purchase_quantity", values.purchase_quantity);
    formData.append("purchase_cost", values.purchase_cost);
    formData.append("shipping_price", values.shipping_price);
    formData.append("basic_shipment_quantity", values.basic_shipment_quantity);
    formData.append("shipping_cost", values.shipping_cost);
    formData.append("consumer_price", values.consumer_price);
    formData.append("order_unit", values.order_unit);
    formData.append("supplier", values.supplier);
    formData.append("product_quantity", values.product_quantity);
    if (values.brand == null) {
      formData.append("brand", "");
    } else {
      formData.append("brand", values.brand);
    }
    if (values.details_description == null) {
      formData.append("details_description", "");
    } else {
      formData.append("details_description", values.details_description);
    }
    for (var i = 0; i < 3; i++) {
      formData.append("preimg", img[i][1]);
      formData.append("img", sendImg[i]);
    }

    await formAPI
      .put(`/admin/product/${idx}`, formData)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setTextMsg("수정이 완료되었습니다.");
          setGoBackModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setLoading(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  });

  const changeTag_sales = (e) => {
    if (e.target.value === "sale") {
      setStatus("sale");
    } else if (e.target.value === "missing") {
      setStatus("missing");
    } else if (e.target.value === "extinction") {
      setStatus("extinction");
    }
  };

  const handleImgAdd = (e) => {
    let nullnum = img.filter((e, i) => e[1] !== null);
    let imgFile_null = imgFile.filter((e) => e !== null);

    const nowImageURLList = [...imgFile];
    let nowSelectImageList = Object.entries(e.target.files).filter((e, i) => {
      return i < 3 - nullnum.length - imgFile_null.length;
    });
    nowSelectImageList = Object.fromEntries(nowSelectImageList);

    for (let i = 0; i < Object.keys(nowSelectImageList).length; i++) {
      const nowImageUrl = URL.createObjectURL(nowSelectImageList[i]);
      nowImageURLList.push(nowImageUrl);

      imgFile[nullnum.length + imgFile_null.length + i] = nowImageUrl;
      sendImg[nullnum.length + imgFile_null.length + i] = nowSelectImageList[i];

      switch (i + 1) {
        case 1:
          setImage_path_1(nowSelectImageList[i]);
          break;
        case 2:
          setImage_path_2(nowSelectImageList[i]);
          break;
        case 3:
          setImage_path_3(nowSelectImageList[i]);
          break;
      }
    }
  };

  const handleImgRemove = (idx) => {
    let copy = [...img];
    copy[idx][1] = null;

    let copy1 = [...imgFile];
    copy1[idx] = null;

    let copy2 = [...sendImg];
    copy2[idx] = null;

    for (var i = 0; i < copy.length; i++) {
      if (idx < 2 && idx + i + 1 <= 2) {
        copy[idx + i][1] = copy[idx + i + 1][1];
        copy1[idx + i] = copy1[idx + i + 1];
        copy2[idx + i] = copy2[idx + i + 1];
      }
    }

    copy[2][1] = null;
    copy1[2] = null;
    copy2[2] = null;

    setImg(copy);
    setImgFile(copy1);
    setSendImg(copy2);
  };

  const handleChange = (e, isPriceValue) => {
    // 금액 관련일때
    if (isPriceValue) {
      const updatedValues = {
        ...values,
        [e.target.name]: e.target.value.replaceAll(",", ""),
      };
      const updatedPriceValues = {
        ...priceValues,
        [e.target.name]: e.target.value ? AddComma(e.target.value.replaceAll(",", "")) : e.target.value,
      };
      // 매입가 관련 계산
      if (e.target.name === "purchase_price" || e.target.name === "purchase_quantity") {
        const price = +document.querySelector("input[name=purchase_price]").value.replaceAll(",", "") || 0;
        const count = +document.querySelector("input[name=purchase_quantity]").value.replaceAll(",", "") || 0;
        if (price && count) {
          const calPrice = price * count;
          updatedValues.purchase_cost = calPrice;
          updatedPriceValues.purchase_cost = AddComma(calPrice);
        } else {
          updatedValues.purchase_cost = "";
          updatedPriceValues.purchase_cost = "";
        }
      } else if (
        // 출하가 관련 계산
        e.target.name === "shipping_price" ||
        e.target.name === "basic_shipment_quantity"
      ) {
        const price = +document.querySelector("input[name=shipping_price]").value.replaceAll(",", "") || 0;
        const count = +document.querySelector("input[name=basic_shipment_quantity]").value.replaceAll(",", "") || 0;
        if (price && count) {
          const calPrice = price * count;
          updatedValues.shipping_cost = calPrice;
          updatedPriceValues.shipping_cost = AddComma(calPrice);
        } else {
          updatedValues.shipping_cost = "";
          updatedPriceValues.shipping_cost = "";
        }
      }
      setValues(updatedValues);
      setPriceValues(updatedPriceValues);
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    if (e === 1) {
      setSaveModal(false);
      navigate(-1, { replace: true });
    } else {
      setSaveModal(false);
      putProduct();
    }
  };

  window.addEventListener("message", function (e) {
    let data;
    try {
      data = JSON.parse(e.data);
      if (data && data.barcodeValue) {
        setValues({ ...values, barcode_num: data.barcodeValue });
      }
    } catch (err) {
      // 예외 처리 코드
      console.error(err);
      return;
    }
  });

  function onClickBarcodeBtn() {
    if (isMobile) {
      if (window.ReactNativeWebView != undefined) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ method: "barcodeScan" }));
      } else {
        alert("전용앱에서만 사용가능합니다.");
      }
    } else {
      alert("전용앱에서만 사용가능합니다.");
    }
  }

  useEffect(() => {
    setValues({ ...values, order_unit: orderUnitValue });
  }, [orderUnitValue]);

  useEffect(() => {
    let filebtn_num = img.filter((e) => e[1] !== null);
    let filebtn_num2 = imgFile.filter((e) => e !== null);
    if (filebtn_num.length + filebtn_num2.length > 2) {
      setShowFileBtn(false);
    } else {
      setShowFileBtn(true);
    }
  }, [img, imgFile]);

  useEffect(() => {
    getOrderUnit();
    getProduct();
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {loading ? <Loading /> : null}
          <HeaderSub title={"상품 수정"} />
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    전체상품 관리 📋
                  </Title>
                )}
                <WhiteBox>
                  <Title color={"#1E1E1E"} weight={"700"} size={"14px"} sizeMd={"20px"}>
                    상품 수정
                  </Title>
                  <Text size={"12px"} sizeMd={"16px"} align={"left"} margin={"6px 0"} marginMd={"10px 0 20px"}>
                    * 상품 이미지를 등록해주세요. 이미지는 최대 3개까지 가능합니다.
                  </Text>
                  <Contents>
                    {isMobile ? (
                      <FlexBox justify={"flex-start"} gap={"10px"} wrap={"wrap"}>
                        {showFileBtn && (
                          <UploadFileBtn>
                            <input
                              type="file"
                              multiple="multiple"
                              accept="image/*"
                              onChange={handleImgAdd}
                              ref={imgRef}
                            />
                            <Text size={"14px"} color={"#C7C7C7"}>
                              이미지 등록
                            </Text>
                            <span>파일 첨부</span>
                          </UploadFileBtn>
                        )}
                        {img?.map((e1, i1) => (
                          <>
                            {e1[1] !== null || imgFile[i1] !== null ? (
                              <FileImg
                                key={i1}
                                border={e1[1] !== null || imgFile[i1] !== null ? "1px solid #c7c7c7" : "unset"}
                              >
                                <FileXBtn onClick={() => handleImgRemove(i1)}>
                                  {(() => {
                                    if (e1[1] !== null) {
                                      return <img src={ico_x} />;
                                    } else {
                                      if (imgFile[i1] !== null) {
                                        return <img src={ico_x} />;
                                      }
                                    }
                                  })()}
                                </FileXBtn>
                                <FlexBox gap={"14px"} gapMd={"20px"}>
                                  {(() => {
                                    if (e1[1] !== null) {
                                      return <img src={`${process.env.REACT_APP_S3_KEY}` + e1[1]} />;
                                    } else {
                                      return <img src={imgFile[i1]} />;
                                    }
                                  })()}
                                </FlexBox>
                              </FileImg>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      </FlexBox>
                    ) : (
                      <WhiteBox>
                        <FlexBox justify={"flex-start"} gap={"10px"} wrap={"wrap"}>
                          {showFileBtn && (
                            <UploadFileBtn>
                              <input
                                type="file"
                                multiple="multiple"
                                accept="image/*"
                                onChange={handleImgAdd}
                                ref={imgRef}
                              />
                              <Text size={"14px"} color={"#C7C7C7"}>
                                이미지 등록
                              </Text>
                              <span>파일 첨부</span>
                            </UploadFileBtn>
                          )}
                          {img?.map((e1, i1) => (
                            <>
                              {e1[1] !== null || imgFile[i1] !== null ? (
                                <FileImg
                                  key={i1}
                                  border={e1[1] !== null || imgFile[i1] !== null ? "1px solid #c7c7c7" : "unset"}
                                >
                                  <FileXBtn onClick={() => handleImgRemove(i1)}>
                                    {(() => {
                                      if (e1[1] !== null) {
                                        return <img src={ico_x} />;
                                      } else {
                                        if (imgFile[i1] !== null) {
                                          return <img src={ico_x} />;
                                        }
                                      }
                                    })()}
                                  </FileXBtn>
                                  <FlexBox gap={"14px"} gapMd={"20px"}>
                                    {(() => {
                                      if (e1[1] !== null) {
                                        return <img src={`${process.env.REACT_APP_S3_KEY}` + e1[1]} />;
                                      } else {
                                        return <img src={imgFile[i1]} />;
                                      }
                                    })()}
                                  </FlexBox>
                                </FileImg>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </FlexBox>
                      </WhiteBox>
                    )}
                    {/* <FileContainer>
                      <FileImgWrap>
                        <FlexBox
                          direction={"column"}
                          directionMd={"row"}
                          justify={"flex-start"}
                          gap={"14px"}
                          gapMd={"20px"}
                        >
                          {showFileBtn && (
                            <UploadFileBtn>
                              <input
                                type="file"
                                multiple="multiple"
                                accept="image/*"
                                onChange={handleImgAdd}
                                ref={imgRef}
                              />
                              이미지 등록
                              <span>파일 첨부</span>
                            </UploadFileBtn>
                          )}

                          <FlexBox gap={"14px"} gapMd={"20px"}>
                            {img?.map((e1, i1) => (
                              <FileImg key={i1}>
                                <FileXBtn onClick={() => handleImgRemove(i1)}>
                                  {(() => {
                                    if (e1[1] !== null) {
                                      return <img src={ico_x} />;
                                    } else {
                                      if (imgFile[i1] !== null) {
                                        return <img src={ico_x} />;
                                      }
                                    }
                                  })()}
                                </FileXBtn>
                                <FlexBox gap={"14px"} gapMd={"20px"}>
                                  {(() => {
                                    if (e1[1] !== null) {
                                      return <img src={`${process.env.REACT_APP_S3_KEY}` + e1[1]} />;
                                    } else {
                                      return <img src={imgFile[i1]} />;
                                    }
                                  })()}
                                </FlexBox>
                              </FileImg>
                            ))}
                          </FlexBox>
                        </FlexBox>
                      </FileImgWrap>
                    </FileContainer> */}
                  </Contents>
                  <Contents flex={1}>
                    <Text
                      align={"left"}
                      weight={"700"}
                      color={"#1e1e1e"}
                      margin={"20px 0 8px"}
                      marginMd={"30px 0 10px"}
                      size={"14px"}
                      sizeMd={"16px"}
                      letterSpacing={"-0.32px"}
                    >
                      판매 여부
                    </Text>
                    <FlexBox justify={"start"} gap={"10px"}>
                      <RadioLabel>
                        <input
                          type="radio"
                          name="sale"
                          value="sale"
                          checked={status === "sale"}
                          onChange={changeTag_sales}
                        />
                        <RadioIcon>
                          <svg viewBox="0 0 18 18">
                            <circle cx="9" cy="9" r="8.5" />
                            <circle cx="9" cy="9" r="5" />
                          </svg>
                        </RadioIcon>
                        판매
                      </RadioLabel>

                      <RadioLabel>
                        <input
                          type="radio"
                          name="missing"
                          value="missing"
                          checked={status === "missing"}
                          onChange={changeTag_sales}
                        />
                        <RadioIcon>
                          <svg viewBox="0 0 18 18">
                            <circle cx="9" cy="9" r="8.5" />
                            <circle cx="9" cy="9" r="5" />
                          </svg>
                        </RadioIcon>
                        입고지연
                      </RadioLabel>

                      <RadioLabel>
                        <input
                          type="radio"
                          name="extinction"
                          value="extinction"
                          checked={status === "extinction"}
                          onChange={changeTag_sales}
                        />
                        <RadioIcon>
                          <svg viewBox="0 0 18 18">
                            <circle cx="9" cy="9" r="8.5" />
                            <circle cx="9" cy="9" r="5" />
                          </svg>
                        </RadioIcon>
                        단종
                      </RadioLabel>
                    </FlexBox>
                  </Contents>

                  {!isMobile && (
                    <Text
                      align={"left"}
                      weight={"700"}
                      color={"#1e1e1e"}
                      margin={"20px 0 8px"}
                      marginMd={"30px 0 10px"}
                      size={"14px"}
                      sizeMd={"16px"}
                      letterSpacing={"-0.32px"}
                    >
                      바코드 번호
                    </Text>
                  )}

                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    direction={"column"}
                    directionMd={"row"}
                    gap={"10px"}
                    gapMd={"30px"}
                  >
                    <ContentsHalf>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        margin={"20px 0 8px"}
                        marginMd={"30px 0 10px"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        className="d-block d-md-none"
                      >
                        바코드 번호
                      </Text>
                      <FlexBox justify={"flex-start"}>
                        <TextInput
                          style={{ width: "100%" }}
                          type={"text"}
                          placeholder={"바코드 번호를 입력해주세요."}
                          name="barcode_num"
                          value={values.barcode_num}
                          onChange={(e) => {
                            handleChange(e, false);
                          }}
                        />
                      </FlexBox>
                    </ContentsHalf>

                    <Btn
                      width={"100%"}
                      size={"14px"}
                      padding={"8px 10px"}
                      paddingMd={"8px 18px"}
                      lineHeight={"22px"}
                      hoverWeight={"700"}
                      className="d-block d-md-none"
                      onClick={() => onClickBarcodeBtn()}
                    >
                      바코드 스캔
                    </Btn>
                  </FlexBox>
                  <FlexBox
                    justify={"start"}
                    align={"end"}
                    margin={"20px 0 0"}
                    marginMd={"30px 0 0"}
                    direction={"column"}
                    directionMd={"row"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        분류
                      </Text>
                      <Cate2Select
                        width={"100%"}
                        idx={1}
                        cate2Value={cate2Value}
                        setCate2Value={setCate2Value}
                        placeholder={"상품 분류"}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        소비자가
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="consumer_price"
                          value={priceValues.consumer_price}
                          onChange={(e) => {
                            handleChange(e, true);
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "13px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                  </FlexBox>
                  <FlexBoxGrid3
                    justify={"start"}
                    align={"start"}
                    margin={"20px 0 0 0"}
                    marginMd={"30px 0 0 0"}
                    gap={"10px"}
                    gapMd={"25px"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        브랜드
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"브랜드명을 입력해주세요."}
                        name="brand"
                        value={values.brand}
                        onChange={(e) => {
                          handleChange(e, false);
                        }}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        상품명
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"상품명 입력해주세요."}
                        name="product_name"
                        value={values.product_name}
                        onChange={(e) => {
                          handleChange(e, false);
                        }}
                      />
                    </Contents>
                  </FlexBoxGrid3>
                  <FlexBoxGrid3
                    justify={"start"}
                    align={"start"}
                    margin={"20px 0 0 0"}
                    marginMd={"30px 0 0 0"}
                    gap={"10px"}
                    gapMd={"25px"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        매입가
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="purchase_price"
                          value={priceValues.purchase_price}
                          onChange={(e) => {
                            handleChange(e, true);
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "13px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        매입수량
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        align={"right"}
                        placeholder={"0"}
                        name="purchase_quantity"
                        value={priceValues.purchase_quantity}
                        onChange={(e) => {
                          handleChange(e, true);
                        }}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"10px 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        매입비용
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="purchase_cost"
                          value={priceValues.purchase_cost}
                          disabled
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "13px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                  </FlexBoxGrid3>
                  <FlexBoxGrid3
                    justify={"start"}
                    align={"start"}
                    margin={"20px 0 0 0"}
                    marginMd={"30px 0 0 0"}
                    gap={"10px"}
                    gapMd={"25px"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"10px 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        출하가
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="shipping_price"
                          value={priceValues.shipping_price}
                          onChange={(e) => {
                            handleChange(e, true);
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "12px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"10px 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        기본 출하 수량
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        align={"right"}
                        placeholder={"0"}
                        name="basic_shipment_quantity"
                        value={priceValues.basic_shipment_quantity}
                        onChange={(e) => {
                          handleChange(e, true);
                        }}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"10px 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        출하비용
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="shipping_cost"
                          value={priceValues.shipping_cost}
                          disabled
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "12px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                  </FlexBoxGrid3>
                  <FlexBox
                    justify={"start"}
                    align={"end"}
                    margin={"10px 0 0 0"}
                    marginMd={"20px 0 0 0"}
                    direction={"column"}
                    directionMd={"row"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents margin={"20px 0 0"}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        상품갯수
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "38px", width: "100%" }}
                          name="product_quantity"
                          value={priceValues.product_quantity}
                          onChange={(e) => {
                            handleChange(e, true);
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "12px",
                          }}
                        >
                          개입
                        </Text>
                      </div>
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        공급업체
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"공급업체를 입력해주세요."}
                        name="supplier"
                        value={values.supplier}
                        onChange={(e) => {
                          handleChange(e, false);
                        }}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        발주단위
                      </Text>
                      <BasicSelect
                        width={"100%"}
                        selectedValue={values.order_unit}
                        setSelectedValue={setOrderUnitValue}
                        data={orderUnit}
                        canSelectAll={false}
                        placeholder={"발주단위"}
                      />
                    </Contents>
                  </FlexBox>
                  <Contents margin={"20px 0 0 0"} marginMd={"30px 0 0 0"}>
                    <Text
                      align={"left"}
                      weight={"700"}
                      color={"#1e1e1e"}
                      size={"14px"}
                      sizeMd={"16px"}
                      letterSpacing={"-0.32px"}
                      margin={"0 0 8px"}
                      marginMd={"0 0 10px"}
                    >
                      상품설명
                    </Text>
                    <TextInputArea
                      color={"#2d2d2d"}
                      height={"320px"}
                      style={{ width: "100%" }}
                      type={"text"}
                      placeholder={"내용을 입력해주세요."}
                      name="details_description"
                      value={values.details_description}
                      onChange={(e) => {
                        handleChange(e, false);
                      }}
                    />
                  </Contents>
                </WhiteBox>
                <FlexBox justify={"end"}>
                  <FlexBox flex={"1 0 auto"} flexMd={"unset"} gap={"8px"} gapMd={"10px"}>
                    <Btn
                      width={"100%"}
                      widthMd={"90px"}
                      size={"14px"}
                      color={"#666"}
                      border={"1px solid #666"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      hoverWeight={"700"}
                      hoverColor={"#fff"}
                      hoverBackground={"#C7C7C7"}
                      hoverBorder={"1px solid transparent"}
                      onClick={() => setListModal(true)}
                    >
                      목 록
                    </Btn>
                    <Btn
                      width={"100%"}
                      widthMd={"90px"}
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      onClick={() => setSaveModal(true)}
                    >
                      수 정
                    </Btn>
                  </FlexBox>
                </FlexBox>
              </Container>
              <ConfirmModal
                modal={listModal}
                setModal={setListModal}
                onSubmit={() => onSubmit(1)}
                description={"상품 수정을 취소하고\n목록화면으로 이동하시겠습니까?"}
              />
              <ConfirmModal
                modal={saveModal}
                setModal={setSaveModal}
                onSubmit={() => onSubmit(2)}
                description={"상품 수정을 완료하시겠습니까?"}
              />

              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
              <InfoModal modal={goBackModal} setModal={setGoBackModal} description={textMsg} mode={"goBack"} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const ContentsHalf = styled(Contents)`
  max-width: calc(50% - 10px);

  @media all and (max-width: 1024px) {
    max-width: 100%;
  }
`;
const FileImgWrap = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  @media all and (max-width: 1024px) {
    overflow-x: hidden;
  }
`;

const FlexBoxGrid3 = styled(FlexBox)`
  @media all and (max-width: 1024px) {
    flex-wrap: wrap;

    & > div:nth-of-type(1),
    & > div:nth-of-type(2) {
      width: calc(50% - 5px);
    }
    & > div:nth-of-type(3) {
      width: 100%;
    }
  }
`;

export default ProductEdit;
