import React, { useEffect, useState } from "react";
import Authority from "../../Components/Modal/Authority";
import Loading from "../../Components/Loading";
import HeaderSub from "../../Components/Header/HeaderSub";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import {
  Btn,
  Container,
  FlexBox,
  Td,
  Text,
  TextInput,
  Th,
  Wrapper,
  ContentContainer,
  WhiteBox,
  Title,
  TableWrap,
} from "../../Assets/css/commonVer2";
import { AddComma } from "../../Utiles/Custom";
import ico_close from "../../Assets/images/ico_close.png"
import { useNavigate, useParams } from "react-router-dom";
import { getProduct } from "../../Utiles";
import API from "../../Utiles/API";
import InfoModal from "../../Components/Modal/InfoModal";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import BasicSelect from "../../Components/Select/BasicSelect";
import { SEARCH_TYPE_LIST } from "../../Utiles/ListData";
import SearchInputPop from "../../Components/SearchInput/SearchInput";

export default function BundleEdit() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();
  const { bundle_id } = useParams();

  const [bundleItems, setBundleItems] = useState([]);
  const [addingItems, setAddingItems] = useState([]); // 상품 추가 데이터
  const [deletedIds, setDeletedIds] = useState(undefined); // 기존 상품 삭제 
  const [filter, setFilter] = useState("product_name");
  const [barcodeNum, setBarcodeNum] = useState(""); // 검색 바코드 번호 담을 state
  const [productItem, setproductItem] = useState({}); // 검색 후 선택한 아이템을 담을 객체

  const [modal, setModal] = useState(false); // 수정 완료 모달
  const [textModal, setTextModal] = useState(false); // 텍스트 모달 표시 여부
  const [textMsg, setTextMsg] = useState("");
  const [goBackModal, setGoBackModal] = useState(false);

  const [loading, setLoading] = useState(false);

  // 묶음 상품 수정 완료
  const editBundleProducts = async () => {
    setLoading(true);
    try {
      const addProductIds = addingItems.map(item => item.product_id);

      const res = await API.put(`/admin/super-code`, {
        super_code_id: bundle_id,
        add_product_id: addProductIds,
        delete_product_id: deletedIds,
      });

      setLoading(false);
      if (res.data.status) {
        setTextMsg("수정이 완료되었습니다.");
        setGoBackModal(true);
      } else {
        setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
        setTextMsg("잠시후 다시 시도해주세요.");
        setTextModal(true);
      }

    } catch (error) {
      setLoading(false);
      setModal(false);
      setTextMsg(error.response.data.meta.description || "E01\n잠시후 다시 시도해주세요.");
      setTextModal(true);
    }
  }

  // 검색한 아이템 '상품 추가' 버튼 클릭해서 묶음 상품 추가
  const addBundleList = async () => {
    // 검색 or 선택한 바코드 번호
    const selectedBarcodeNum = filter === "product_name" ? productItem.barcode_num : barcodeNum;
    try {
      getProduct(selectedBarcodeNum).then(res => {
        if (res.status) {
          const newBundleItem = {
            product_id: res.results.product_id,
            product_name: res.results.product_name,
            shipping_price: res.results.shipping_price,
            consumer_price: res.results.consumer_price,
            barcode_num: res.results.barcode_num,
          };

          const isDuplicate = addingItems.some(item => item.product_id === newBundleItem.product_id);

          const isAlreadyExist = bundleItems.some(item => item.idx === newBundleItem.product_id);

          if (isDuplicate) {
            setTextMsg("이미 추가된 상품입니다.");
            setTextModal(true);
          } else if (isAlreadyExist) {
            setTextMsg("기존 묶음내역에 있는 상품입니다.");
            setTextModal(true);
          } else {
            setAddingItems(items => [...items, newBundleItem]);
          }
        } else {
          // setTextMsg(
          //   res.response.data.meta.description == "데이터가 존재하지 않음"
          //     ? "상품의 데이터가 존재하지 않습니다."
          //     : "잠시후 다시 시도해주세요."
          // );

          setTextMsg("상품의 데이터가 존재하지 않습니다.");
          setTextModal(true);
        }
      })
    } catch (error) {
      setLoading(false);
      setTextMsg("E01\n잠시후 다시 시도해주세요.");
      setTextModal(true);
    }
  }

  // 기존 묶음 상품 목록 불러오기
  const getExistBundleList = async () => {
    await API.get(`/admin/super-code/${bundle_id}`).then((res) => {
      if (res.data.status) {
        setBundleItems(res.data.results);
      }
    });
  }

  // 추가한 상품 삭제
  const removeAddingItem = (id) => {
    const newItems = addingItems.filter(item => item.product_id !== id);
    setAddingItems(newItems);
  }
  // 기존 상품 삭제
  const removeBundleItem = (id) => {
    if (!!deletedIds === false) {
      setDeletedIds([]);
      setDeletedIds((prev) => [...prev, id]);
    } else {
      setDeletedIds((prev) => [...prev, id]);
    }
    const newItems = bundleItems.filter(item => item.idx !== id);
    setBundleItems(newItems);
  }

  const handleSelect = (selectedValue) => {
    setproductItem(selectedValue);
  };

  useEffect(() => {
    setBarcodeNum("");
    setproductItem({});
  }, [filter]);

  useEffect(() => {
    getExistBundleList();
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {loading ? <Loading /> : null}
          <HeaderSub title={"묶음상품 수정"} />
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                   묶음상품 수정
                  </Title>
                )}
                <WhiteBox  margin={"0 0"}>
                  <FlexBox
                    wrap={"wrap"}
                    gap={"10px"}
                    justify={"end"}
                  >
                    <BasicSelect
                      selectedValue={filter}
                      setSelectedValue={setFilter}
                      data={SEARCH_TYPE_LIST}
                      canSelectAll={false}
                    />
                    <FlexBox
                      justify={"end"}
                      width={"100%"}
                      widthMd={"auto"}
                      wrap={"wrap"}
                      wrapMd={"nowrap"}
                      gap={"10px"}
                    >
                      {filter == "barcode_num" && (
                        <FlexBox
                          width={"100%"}
                          widthMd={"262px"}
                        >
                          <TextInput
                            width={"100%"}
                            color={"#2d2d2d"}
                            value={barcodeNum || ""}
                            weight={"230"}
                            align={"left"}
                            type="number"
                            onInput={(e) => {
                              setBarcodeNum(e.target.value);
                            }}
                            placeholder="바코드 번호를 입력해 주세요"
                          />
                        </FlexBox>
                      )}
                      {filter == "product_name" && (
                        <FlexBox
                          width={"100%"}
                          widthMd={"262px"}
                          style={{ position: "relative" }}>
                          <SearchInputPop
                            width={"100%"}
                            uiType={2}
                            onSelect={handleSelect}
                          />
                        </FlexBox>
                      )}
                      <Btn
                        color={"#fff"}
                        background={"#FF4A4A"}
                        size={"14px"}
                        weight={"700"}
                        width={"100%"}
                        widthMd={"unset"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        onClick={addBundleList}
                        style={{ flexShrink: 0 }}
                      >
                        상품 추가
                      </Btn>
                    </FlexBox>
                  </FlexBox>

                  <FlexBox
                    align={"start"}
                    justify={"space-between"}
                    wrap={"wrap"}
                    gap={"10px"}
                    direction={"column"}
                    directionMd={"row"}
                    margin={"10px 0 10px"}
                    marginMd={"20px 0 30px"}
                  >
                    <FlexBox
                      width={"100%"}
                      widthMd={"unset"}
                      gap={"10px"}
                      direction={"column"}
                      directionMd={"row"}
                    >
                      <Text
                        width={"100%"}
                        widthMd={"unset"}
                        color={"#FF4A4A"}
                        size={"16px"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        style={{
                          flexShrink: 0
                        }}
                      >
                        품목 : <span style={{ fontWeight: "700" }}>
                          {AddComma(bundleItems ? bundleItems?.length : "")}개
                        </span>
                      </Text>
                      <Text
                        width={"100%"}
                        widthMd={"unset"}
                        color={"#FF4A4A"}
                        size={"16px"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        style={{
                          flexShrink: 0
                        }}
                      >
                        묶음 번호 : <span style={{ fontWeight: "700" }}>
                          {bundle_id ? bundle_id : ""}
                        </span>
                      </Text>
                    </FlexBox>
                    <Btn
                      width={"100%"}
                      widthMd={"unset"}
                      size={"14px"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      style={{ flexShrink: 0 }}
                      onClick={() => setModal(true)}
                    >
                      수정 완료
                    </Btn>
                  </FlexBox>
                  <TableWrap>
                    <table style={{ width: "100%" }}>
                      <colgroup>
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "18.5%" }} />
                        <col />
                        <col style={{ width: "13%" }} />
                        <col style={{ width: "13%" }} />
                        <col style={{ width: "10.5%" }} />
                      </colgroup>

                      <thead>
                        <tr>
                          <Th>No</Th>
                          <Th>바코드 번호</Th>
                          <Th>상품명</Th>
                          <Th>출하가</Th>
                          <Th>소비자가</Th>
                          <Th>삭제</Th>
                        </tr>
                      </thead>

                      <tbody>
                        {bundleItems?.length !== 0 &&
                          bundleItems?.map((e, key) => (
                            <tr key={key}>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {key + 1}
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {e.barcode_num}
                              </Td>
                              <Td
                                align={"left"}
                                style={{ borderBottom: "1px solid #F2F2F2" }}
                              >
                                {e.product_name}
                              </Td>
                              <Td
                                align={"right"}
                                style={{ borderBottom: "1px solid #F2F2F2" }}
                              >
                                {AddComma(e.shipping_price)}원
                              </Td>
                              <Td
                                align={"right"}
                                style={{ borderBottom: "1px solid #F2F2F2" }}
                              >
                                {AddComma(e.consumer_price)}원
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                <Btn
                                  border={"none"}
                                  background={"transeparent"}
                                  style={{ margin: "0 auto" }}
                                  onClick={() => removeBundleItem(e.idx)}
                                >
                                  <DeleteImg src={ico_close} />
                                </Btn>
                              </Td>
                              {/* <Tdm className="d-block d-lg-none">
                              <div>
                                <FlexBox justify={"space-between"}>
                                  <FlexBox>
                                    <ShopText>
                                      <Text
                                        size={"12px"}
                                        weight={"500"}
                                        color={"#6c6c6c"}
                                        align={"left"}>
                                        {e.product_name}
                                      </Text>
                                      <FlexBox justify={"flex-start"} gap={"10px"}>
                                        <Text
                                          size={"10px"}
                                          color={"#6c6c6c"}
                                          align={"left"}>
                                          {e.barcode_num}
                                        </Text>
                                        <Text
                                          size={"10px"}
                                          color={"#6c6c6c"}
                                          align={"left"} >
                                          {e.unit}
                                        </Text>
                                      </FlexBox>
                                    </ShopText>
                                  </FlexBox>
                                  <FlexBox
                                    gap={"10px"}
                                  >
                                    <div>
                                      <Text size={"10px"} color={"#2d2d2d"} align={"right"}  >
                                        (공급가) {AddComma(e.shipping_price)}원
                                      </Text>
                                      <Text size={"10px"} color={"#6c6c6c"} align={"right"} padding={"2px 0 0"}  >
                                        (소비자가) {AddComma(e.consumer_price)}원
                                      </Text>
                                    </div>

                                    <Btn
                                      style={{ margin: "0 auto" }}
                                      onClick={() => removeBundleItem(e.idx)}
                                    >
                                      <img src={ico_trash} style={{ width: "28px" }} />
                                    </Btn>
                                  </FlexBox>
                                </FlexBox>
                              </div>
                            </Tdm> */}
                            </tr>
                          ))
                        }
                        {addingItems?.length !== 0 &&
                          addingItems?.map((e, key) => (
                            <tr key={key}>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {bundleItems?.length + 1}
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {e.barcode_num}
                              </Td>
                              <Td
                                align={"left"}
                                style={{ borderBottom: "1px solid #F2F2F2" }}
                              >
                                {e.product_name}
                              </Td>
                              <Td
                                align={"right"}
                                style={{ borderBottom: "1px solid #F2F2F2" }}
                              >
                                {AddComma(e.shipping_price)}원
                              </Td>
                              <Td

                                align={"right"}
                                style={{ borderBottom: "1px solid #F2F2F2" }}
                              >
                                {AddComma(e.consumer_price)}원
                              </Td>
                              <Td
                                style={{ borderBottom: "1px solid #F2F2F2" }}
                              >
                                <Btn
                                  border={"none"}
                                  background={"transeparent"}
                                  style={{ margin: "0 auto" }}
                                  onClick={() => removeAddingItem(e.product_id)}
                                >
                                  <DeleteImg src={ico_close} />
                                </Btn>
                              </Td>
                              {/* <Tdm className="d-block d-lg-none">
                              <div>
                                <FlexBox justify={"space-between"}>
                                  <FlexBox>
                                    <ShopText>
                                      <Text
                                        size={"12px"}
                                        weight={"500"}
                                        color={"#6c6c6c"}
                                        align={"left"}>
                                        {e.product_name}
                                      </Text>
                                      <FlexBox justify={"flex-start"} gap={"10px"}>
                                        <Text
                                          size={"10px"}
                                          color={"#6c6c6c"}
                                          align={"left"}>
                                          {e.barcode_num}
                                        </Text>
                                      </FlexBox>
                                    </ShopText>
                                  </FlexBox>
                                  <FlexBox
                                    gap={"10px"}
                                  >
                                    <div>
                                      <Text size={"10px"} color={"#2d2d2d"} align={"right"}  >
                                        (공급가) {AddComma(e.shipping_price)}원
                                      </Text>
                                      <Text size={"10px"} color={"#6c6c6c"} align={"right"} padding={"2px 0 0"}  >
                                        (소비자가) {AddComma(e.consumer_price)}원
                                      </Text>
                                    </div>

                                    <Btn
                                      style={{ margin: "0 auto" }}
                                      onClick={() => removeAddingItem(e.product_id)}
                                    >
                                      <img src={ico_trash} style={{ width: "28px" }} />
                                    </Btn>
                                  </FlexBox>
                                </FlexBox>
                              </div>
                            </Tdm> */}
                            </tr>
                          ))
                        }
                        {addingItems?.length > 0 || bundleItems?.length > 0 ? (
                          ""
                        ) : (
                          <tr>
                            <Td
                              colSpan={"6"}
                              size={"14px"}
                              color={"#6c6c6c"}
                              align={"center"}
                              padding={"20px 0"}>
                              추가된 상품이 없습니다.
                            </Td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                </WhiteBox>
              </Container>
              <ConfirmModal
                modal={modal}
                setModal={setModal}
                onSubmit={() => editBundleProducts()}
                description={"묶음 상품 수정을 완료 하시겠습니까?"}
              />
              <InfoModal
                modal={textModal}
                setModal={setTextModal}
                description={textMsg}
              />
            </Wrapper>
            <InfoModal modal={goBackModal} setModal={setGoBackModal} description={textMsg} mode={"goBack"} />
          </ContentContainer>
        </>
      )
      }
    </>
  )
}

const DeleteImg = styled.img`
  width: 24px;
  
  @media all and (max-width: 1024px) {
    width: 16px;
  }
`;