import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import API from "../../Utiles/API";
import {
  ContentContainer,
  Btn,
  Container,
  TextInput,
  Wrapper,
  FlexBox,
  Text,
  WhiteBox,
  Title,
  RadioIcon,
  UploadFileBtn,
  RadioLabel,
  Contents,
} from "../../Assets/css/commonVer2";
import ico_x from "../../Assets/images/ico_close.png";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import HeaderSub from "../../Components/Header/HeaderSub";
import formAPI from "../../Utiles/formAPI";
import InfoModal from "../../Components/Modal/InfoModal";
import ico_close_round from "../../Assets/images/ico_close_round.png";
import Loading from "../../Components/Loading";
import QuillContainer from "../../Components/QuillEditor/QuillContainer.js";

// 공지관리 > 수정

function NoticeEdit() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();
  const location = useLocation();
  const idx = location.state.idx;

  const imgRef = useRef();
  const noticeQuillRef = useRef(); // 내용 에디터 ref

  const [saveModal, setSaveModal] = useState(false);
  const [listModal, setListModal] = useState(false);

  const [previewNewImg, setPreviewNewImg] = useState([]); //추가된 이미지 목록(보여주기용) null, null, null, null, null
  const [newImg, setNewImg] = useState([]); //추가된 이미지 목록(서버로 보냄)
  const [existingImg, setExistingImg] = useState([]); //기존에 저장되어 있던 이미지 중 삭제되지 않은 이미지 목록

  const [existingFile, setExistingFile] = useState([]); //기존에 저장되어 있던 파일 중 삭제되지 않은 파일 url 목록
  const [previewFile, setPreviewFile] = useState([]);
  const [filePath, setFilePath] = useState([]); //추가된 파일 목록(서버로 보냄)

  const [gubun, setGubun] = useState("");
  const [type, setType] = useState("");
  const [top_fixed, settop_fixed] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const [loading, setLoading] = useState(false);
  const [textModal, setTextModal] = useState(false); // 모달 창 표시 여부 상태
  const [textMsg, setTextMsg] = useState("");
  const [goBackModal, setGoBackModal] = useState(false);

  const getNotice = () => {
    API.get(`/admin/notice/${idx}`).then((data) => {
      if (data.status) {
        setGubun(data.data.result.gubun);
        setType(data.data.result.type);
        settop_fixed(data.data.result.top_fixed);
        setTitle(data.data.result.title);
        setContent(data.data.result.content);
        setExistingImg(data.data.result.image_path);
        setExistingFile(data.data.result.file_path);
      }
    });
  };

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);

      let formData = new FormData();

      formData.append("gubun", gubun);
      formData.append("type", type);
      formData.append("top_fixed", top_fixed);
      formData.append("title", title);
      formData.append("content", content);

      existingImg.forEach((file) => {
        formData.append("preimg", file);
      });

      newImg.forEach((file) => {
        formData.append("img", file);
      });

      existingFile.forEach((file) => {
        formData.append("prefile", file);
      });

      filePath.forEach((file) => {
        formData.append("notice_file", file);
      });

      await formAPI.put(`/admin/notice/${idx}`, formData).then((res) => {
        setLoading(false);
        if (res.data.status) {
          setTextMsg("수정이 완료되었습니다.");
          setGoBackModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      });
    } catch (e) {
      setLoading(false);
      setTextMsg("E01\n잠시후 다시 시도해주세요.");
      setTextModal(true);
    }
  });

  const onSubmit = (e) => {
    if (e === 1) {
      setSaveModal(false);
      navigate(-1, { replace: true });
    } else {
      handleSubmit();
      setSaveModal(false);
    }
  };

  const changeTag_gubun = (gubun) => {
    if (gubun === "storeowner") {
      setGubun("storeowner");
    } else if (gubun === "user") {
      setGubun("user");
    }
  };

  const changeTag_type = (type) => {
    if (type === "event") {
      setType("event");
    } else if (type === "notification") {
      setType("notification");
    }
  };

  const changeTag_top_fixed = (gubun) => {
    if (gubun === "check") {
      settop_fixed(true);
    } else if (gubun === "uncheck") {
      settop_fixed(false);
    }
  };

  //이미지 등록
  const handleImgAdd = (e) => {
    const nowSelectImageList = e.target.files;
    const nowImageURLList = [...previewNewImg];

    for (let i = 0; i < nowSelectImageList.length; i++) {
      const nowImageUrl = URL.createObjectURL(nowSelectImageList[i]);
      nowImageURLList.push(nowImageUrl);
      newImg.push(nowSelectImageList[i]);
    }
    setPreviewNewImg(nowImageURLList);
  };

  // 이미지 삭제
  const handleImgRemove = (gubun, idx) => {
    if (gubun === "existing") {
      // 기존 이미지를 지울때
      let copy = [...existingImg];
      copy.splice(idx, 1);
      setExistingImg(copy);
    } else if (gubun === "new") {
      //방금 추가한 이미지를 지울때
      let copy1 = [...previewNewImg];
      let copy2 = [...newImg];

      copy1.splice(idx, 1);
      copy2.splice(idx, 1);

      setPreviewNewImg(copy1);
      setNewImg(copy2);
    }
  };

  const handleFileSelect = (event) => {
    const files = event.target.files;
    handleFiles(files);
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFiles(files);
  };

  const handleFiles = (files) => {
    // 선택된 파일들에 대한 처리 로직을 구현합니다.
    // 예를 들어, 파일을 첨부하거나 업로드하는 동작 등을 수행할 수 있습니다.
    const nowSelectFileList = files;
    const nowFileURLList = [...previewFile];

    for (let i = 0; i < nowSelectFileList.length; i++) {
      const nowFileUrl = URL.createObjectURL(nowSelectFileList[i]);
      nowFileURLList.push(nowFileUrl);
      filePath.push(nowSelectFileList[i]);
    }
    setPreviewFile(nowFileURLList);
  };

  const handleFileRemove = (gubun, idx) => {
    if (gubun === "existing") {
      setExistingFile(existingFile.filter((_, index) => index !== idx));
    } else if (gubun === "new") {
      setPreviewFile(previewFile.filter((_, index) => index !== idx));
      setFilePath(filePath.filter((_, index) => index !== idx));
    }
  };

  useEffect(() => {
    getNotice();
  }, []);

  return (
    <>
      {loading ? <Loading /> : null}
      <HeaderSub title={"공지사항 수정"} />
      <ContentContainer>
        <Wrapper type={2}>
          <Container>
            {!isMobile && (
              <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                공지사항 수정
              </Title>
            )}
            <WhiteBox>
              <Contents>
                <Text
                  align={"left"}
                  weight={"700"}
                  color={"#1e1e1e"}
                  size={"14px"}
                  sizeMd={"16px"}
                  letterSpacing={"-0.32px"}
                  margin={"0 0 8px"}
                  marginMd={"0 0 10px"}
                >
                  공지구분
                </Text>
                <FlexBox gap={"10px"} justify={"start"}>
                  <RadioLabel>
                    <input
                      type="radio"
                      name="gubun"
                      value={"gubun"}
                      checked={gubun === "storeowner"}
                      onChange={(props) => changeTag_gubun("storeowner")}
                    />
                    <RadioIcon>
                      <svg viewBox="0 0 18 18">
                        <circle cx="9" cy="9" r="8.5" />
                        <circle cx="9" cy="9" r="5" />
                      </svg>
                    </RadioIcon>
                    점주용
                  </RadioLabel>
                  <RadioLabel>
                    <input
                      type="radio"
                      name="gubun"
                      value={"gubun"}
                      checked={gubun === "user"}
                      onChange={(props) => changeTag_gubun("user")}
                    />
                    <RadioIcon>
                      <svg viewBox="0 0 18 18">
                        <circle cx="9" cy="9" r="8.5" />
                        <circle cx="9" cy="9" r="5" />
                      </svg>
                    </RadioIcon>
                    고객용
                  </RadioLabel>
                </FlexBox>
              </Contents>
              <Contents>
                <Text
                  align={"left"}
                  weight={"700"}
                  color={"#1e1e1e"}
                  size={"14px"}
                  sizeMd={"16px"}
                  letterSpacing={"-0.32px"}
                  margin={"20px 0 8px"}
                  marginMd={"30px 0 10px"}
                >
                  배너노출여부
                </Text>
                <FlexBox gap={"10px"} justify={"start"}>
                  <RadioLabel>
                    <input
                      type="radio"
                      name="type"
                      value={"type"}
                      checked={type === "event"}
                      onChange={(props) => changeTag_type("event")}
                    />
                    <RadioIcon>
                      <svg viewBox="0 0 18 18">
                        <circle cx="9" cy="9" r="8.5" />
                        <circle cx="9" cy="9" r="5" />
                      </svg>
                    </RadioIcon>
                    Yes(이벤트)
                  </RadioLabel>
                  <RadioLabel>
                    <input
                      type="radio"
                      name="type"
                      value={"type"}
                      checked={type === "notification"}
                      onChange={(props) => changeTag_type("notification")}
                    />
                    <RadioIcon>
                      <svg viewBox="0 0 18 18">
                        <circle cx="9" cy="9" r="8.5" />
                        <circle cx="9" cy="9" r="5" />
                      </svg>
                    </RadioIcon>
                    No(일반)
                  </RadioLabel>
                </FlexBox>
              </Contents>
              <Contents>
                <Text
                  align={"left"}
                  weight={"700"}
                  color={"#1e1e1e"}
                  size={"14px"}
                  sizeMd={"16px"}
                  letterSpacing={"-0.32px"}
                  margin={"20px 0 8px"}
                  marginMd={"30px 0 10px"}
                >
                  상단고정여부
                </Text>
                <FlexBox gap={"10px"} justify={"start"}>
                  <RadioLabel>
                    <input
                      type="radio"
                      name="top_fixed"
                      value={"top_fixed"}
                      checked={top_fixed}
                      onChange={(props) => changeTag_top_fixed("check")}
                    />
                    <RadioIcon>
                      <svg viewBox="0 0 18 18">
                        <circle cx="9" cy="9" r="8.5" />
                        <circle cx="9" cy="9" r="5" />
                      </svg>
                    </RadioIcon>
                    Yes
                  </RadioLabel>
                  <RadioLabel>
                    <input
                      type="radio"
                      name="top_fixed"
                      value={"top_fixed"}
                      checked={!top_fixed}
                      onChange={(props) => changeTag_top_fixed("uncheck")}
                    />
                    <RadioIcon>
                      <svg viewBox="0 0 18 18">
                        <circle cx="9" cy="9" r="8.5" />
                        <circle cx="9" cy="9" r="5" />
                      </svg>
                    </RadioIcon>
                    No
                  </RadioLabel>
                </FlexBox>
                <Text
                  align={"left"}
                  color={"#666666"}
                  size={"12px"}
                  sizeMd={"14px"}
                  letterSpacing={"-0.32px"}
                  margin={"5px 0 0"}
                  marginMd={"10px 0 0"}
                >
                  * Yes를 체크하시면 공지목록에서 상단고정 되며, 점주용 공지의 경우 홈화면 공지목록에 노출됩니다.
                </Text>
              </Contents>
              <Contents>
                <Text
                  align={"left"}
                  weight={"700"}
                  color={"#1e1e1e"}
                  size={"14px"}
                  sizeMd={"16px"}
                  letterSpacing={"-0.32px"}
                  margin={"20px 0 8px"}
                  marginMd={"30px 0 10px"}
                >
                  제목
                </Text>
                <TextInput
                  style={{ width: "100%" }}
                  placeholder={"제목을 입력해주세요."}
                  type={"text"}
                  name={"title"}
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </Contents>
              <Contents>
                <Text
                  align={"left"}
                  weight={"700"}
                  color={"#1e1e1e"}
                  size={"14px"}
                  sizeMd={"16px"}
                  letterSpacing={"-0.32px"}
                  margin={"20px 0 8px"}
                  marginMd={"30px 0 10px"}
                >
                  내용
                </Text>
                <QuillContainer quillRef={noticeQuillRef} value={content} setValue={setContent} />
              </Contents>
              {type == "event" && (
                <Contents>
                  <Text
                    align={"left"}
                    weight={"700"}
                    color={"#1e1e1e"}
                    size={"14px"}
                    sizeMd={"16px"}
                    letterSpacing={"-0.32px"}
                    margin={"20px 0 8px"}
                    marginMd={"30px 0 10px"}
                  >
                    썸네일 등록
                  </Text>
                  <Text
                    align={"left"}
                    color={"#666"}
                    size={"14px"}
                    letterSpacing={"-0.32px"}
                    margin={"0 0 8px"}
                    marginMd={"0 0 10px"}
                  >
                    *필수(배너 type {gubun == "storeowner" ? 3 : 1}개 이미지를 등록해주세요.)
                    <Text
                      align={"left"}
                      color={"#666"}
                      size={"14px"}
                      letterSpacing={"-0.32px"}
                      margin={"0 0 8px"}
                      marginMd={"0 0 10px"}
                    >
                      * 홈화면용 배너는 'type1', 웹용 배너는 'type2', 모바일용 배너는 'type3'라는 단어가 파일명에
                      포함되어 있어야 합니다.
                    </Text>
                  </Text>

                  {isMobile ? (
                    <FlexBox justify={"flex-start"} gap={"10px"} wrap={"wrap"}>
                      <UploadFileBtn>
                        <input
                          type="file"
                          multiple="multiple"
                          accept="image/*"
                          onChange={(e) => handleImgAdd(e)}
                          ref={imgRef}
                        />
                        <Text size={"14px"} color={"#C7C7C7"}>
                          썸네일 등록
                        </Text>
                        <span>파일 첨부</span>
                      </UploadFileBtn>
                      {existingImg?.map((e1, i1) => (
                        <FileImg key={i1}>
                          <FileXBtn onClick={() => handleImgRemove("existing", i1)}>
                            {(() => {
                              if (e1 !== null && e1 !== undefined) {
                                return <img src={ico_x} />;
                              }
                            })()}
                          </FileXBtn>
                          <div>
                            {(() => {
                              if (e1 !== null && e1 !== undefined) {
                                return <img src={`${process.env.REACT_APP_S3_KEY}` + e1} />;
                              }
                            })()}
                          </div>
                        </FileImg>
                      ))}
                      {previewNewImg?.map((e1, i1) => (
                        <FileImg key={i1}>
                          <FileXBtn onClick={() => handleImgRemove("new", i1)}>
                            {(() => {
                              return <img src={ico_x} />;
                            })()}
                          </FileXBtn>
                          <div>
                            {(() => {
                              return <img src={previewNewImg[i1]} />;
                            })()}
                          </div>
                        </FileImg>
                      ))}
                    </FlexBox>
                  ) : (
                    <WhiteBox>
                      <FlexBox justify={"flex-start"} gap={"10px"} wrap={"wrap"}>
                        <UploadFileBtn>
                          <input
                            type="file"
                            multiple="multiple"
                            accept="image/*"
                            onChange={(e) => handleImgAdd(e)}
                            ref={imgRef}
                          />
                          <Text size={"14px"} color={"#C7C7C7"}>
                            썸네일 등록
                          </Text>
                          <span>파일 첨부</span>
                        </UploadFileBtn>
                        {existingImg?.map((e1, i1) => (
                          <FileImg key={i1}>
                            <FileXBtn onClick={() => handleImgRemove("existing", i1)}>
                              {(() => {
                                if (e1 !== null && e1 !== undefined) {
                                  return <img src={ico_x} />;
                                }
                              })()}
                            </FileXBtn>
                            <div>
                              {(() => {
                                if (e1 !== null && e1 !== undefined) {
                                  return <img src={`${process.env.REACT_APP_S3_KEY}` + e1} />;
                                }
                              })()}
                            </div>
                          </FileImg>
                        ))}
                        {previewNewImg?.map((e1, i1) => (
                          <FileImg key={i1}>
                            <FileXBtn onClick={() => handleImgRemove("new", i1)}>
                              {(() => {
                                return <img src={ico_x} />;
                              })()}
                            </FileXBtn>
                            <div>
                              {(() => {
                                return <img src={previewNewImg[i1]} />;
                              })()}
                            </div>
                          </FileImg>
                        ))}
                      </FlexBox>
                    </WhiteBox>
                  )}
                </Contents>
              )}
              <Contents>
                <Text
                  align={"left"}
                  weight={"700"}
                  color={"#1e1e1e"}
                  size={"14px"}
                  sizeMd={"16px"}
                  letterSpacing={"-0.32px"}
                  margin={"20px 0 8px"}
                  marginMd={"30px 0 10px"}
                >
                  파일첨부
                </Text>
                <DropArea
                  onDragOver={(event) => event.preventDefault()}
                  onDragEnter={(event) => event.preventDefault()}
                  onDrop={handleFileDrop}
                >
                  <label htmlFor="fileInput">
                    <FileSelectText>파일 선택</FileSelectText>
                  </label>
                  <input id="fileInput" type="file" style={{ display: "none" }} onChange={handleFileSelect} multiple />
                  {!isMobile && <DragText>또는 여기로 파일을 끌고오세요</DragText>}
                </DropArea>
                <FlexBox justify={"flex-start"} gap={"10px"} wrap={"wrap"}>
                  {existingFile?.map((e, i) => (
                    <Btn
                      key={i}
                      size={"14px"}
                      color={"#2d2d2d"}
                      weight={"500"}
                      background={"#ffffff"}
                      border={"1px solid #c0c0c0"}
                      radius={"20px"}
                      padding={"7px 10px 7px 10px"}
                      margin={"10px 0 0 0"}
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      {existingFile[i].substring(existingFile[i].indexOf("_") + 1)}
                      <img
                        src={ico_close_round}
                        style={{ width: "24px", marginLeft: "5px" }}
                        onClick={() => handleFileRemove("existing", i)}
                      />
                    </Btn>
                  ))}
                  {previewFile?.map((e, i) => (
                    <Btn
                      key={i}
                      size={"14px"}
                      color={"#2d2d2d"}
                      weight={"500"}
                      background={"#ffffff"}
                      border={"1px solid #c0c0c0"}
                      radius={"20px"}
                      padding={"7px 10px 7px 10px"}
                      margin={"10px 0 0 0"}
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      {filePath[i].name}
                      <img
                        src={ico_close_round}
                        style={{ width: "24px", marginLeft: "5px" }}
                        onClick={() => handleFileRemove("new", i)}
                      />
                    </Btn>
                  ))}
                </FlexBox>
              </Contents>
            </WhiteBox>
            <FlexBox justify={"end"} style={{ marginTop: "20px" }}>
              <Btn
                size={"14px"}
                padding={"8px 18px"}
                lineHeight={"22px"}
                margin={"0 0 0 10px"}
                hoverWeight={"700"}
                background={"#C7C7C7"}
                border={"1px solid #C7C7C7"}
                color="#fff"
                width={"100%"}
                widthMd={"auto"}
                onClick={() => setListModal(true)}
              >
                목 록
              </Btn>
              <Btn
                color="#fff"
                background={"#FF4A4A"}
                size={"14px"}
                padding={"8px 18px"}
                lineHeight={"22px"}
                margin={"0 0 0 10px"}
                hoverWeight={"700"}
                width={"100%"}
                widthMd={"auto"}
                onClick={() => setSaveModal(true)}
              >
                저 장
              </Btn>
            </FlexBox>
          </Container>
          <ConfirmModal
            modal={listModal}
            setModal={setListModal}
            onSubmit={() => onSubmit(1)}
            description={"공지사항 수정을 취소하고\n목록화면으로 이동하시겠습니까?"}
          />
          <ConfirmModal
            modal={saveModal}
            setModal={setSaveModal}
            onSubmit={() => onSubmit(2)}
            description={"공지사항 수정을 완료하시겠습니까?"}
          />

          <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
          <InfoModal modal={goBackModal} setModal={setGoBackModal} description={textMsg} mode={"goBack"} />
        </Wrapper>
      </ContentContainer>
    </>
  );
}

const FileImg = styled.div`
  position: relative;
  max-width: 240px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #c7c7c7;

  div {
    position: relative;
    height: 240px;
    overflow: hidden;
    @media all and (max-width: 1024px) {
      max-height: 120px;
    }
    img {
      border-radius: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      height: 100%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
    }
  }
  @media all and (max-width: 1024px) {
    max-width: 120px;
  }
`;
const FileXBtn = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 16px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  z-index: 1;
`;

const DropArea = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px dashed #eaeaea;
  // padding: 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  min-height: 180px;
  width: 100%;
  @media all and (max-width: 1024px) {
    width: 100%;
    min-height: 100px;
  }
`;

const FileSelectText = styled.span`
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  color: #666;
`;

const DragText = styled.span`
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
  color: #c7c7c7;
  display: block;
`;

export default NoticeEdit;
