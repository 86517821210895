import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";

import { Title, Text } from "../../Assets/css/commonVer2";
import { str } from "../../Utiles/Custom";

// 공지사항

function HomeNotice(props) {
  const navigate = useNavigate();
  const { notice } = props;

  return (
    <>
      {/* 모바일 */}
      <div className="d-block d-md-none" style={{ marginTop: "20px" }}>
        <Title size={"18px"} color={"#1E1E1E"} weight={"bold"}>
          문구는 못말려 공지사항🔔
        </Title>
        <TableWrapper>
          <Title size={"14px"} weight={"bold"}>
            공지사항
          </Title>
          <Text size={"12px"} weight={"400"} align={"left"}>
            문구는 못말려의 업데이트 정보 등 다양한 소식을 알려드립니다.
          </Text>
          <MobileTableWrap>
            <Table>
              <colgroup>
                <col style={{ width: "11%" }} />
                <col />
                <col style={{ width: "16.5%" }} />
                <col style={{ width: "29.5%" }} />
              </colgroup>
              <thead>
                <TbodyTr>
                  <TheadTh padding={"10px 0"} borderBottom={"1px solid #666"}>
                    <Text size={"14px"} weight={"bold"}>
                      No
                    </Text>
                  </TheadTh>
                  <TheadTh padding={"10px 0"} borderBottom={"1px solid #666"}>
                    <Text size={"14px"} weight={"bold"}>
                      제목
                    </Text>
                  </TheadTh>
                  <TheadTh padding={"10px 0"} borderBottom={"1px solid #666"}>
                    <Text size={"14px"} weight={"bold"}>
                      작성자
                    </Text>
                  </TheadTh>
                  <TheadTh padding={"10px 0"} borderBottom={"1px solid #666"}>
                    <Text size={"14px"} weight={"bold"}>
                      등록일
                    </Text>
                  </TheadTh>
                </TbodyTr>
              </thead>
              <tbody>
                {notice?.map((list, key) => (
                  <TbodyTr
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/notice/View", { state: { idx: list.idx } });
                    }}
                  >
                    <TbodyTd padding={"13px 0"} borderBottom={"1px solid #C7C7C7"}>
                      <Text size={"12px"} weight={"400"}>
                        {key + 1}
                      </Text>
                    </TbodyTd>
                    <TbodyTd padding={"13px 0"} borderBottom={"1px solid #C7C7C7"}>
                      <Text size={"12px"} weight={"400"} className={"ellipsis"} align={"center"}>
                        {list.title}
                      </Text>
                    </TbodyTd>
                    <TbodyTd padding={"13px 0"} borderBottom={"1px solid #C7C7C7"}>
                      <Text size={"12px"} weight={"400"}>
                        운영자
                      </Text>
                    </TbodyTd>
                    <TbodyTd padding={"13px 0"} borderBottom={"1px solid #C7C7C7"}>
                      <Text size={"12px"} weight={"400"}>
                        {str(list.createdat)}
                      </Text>
                    </TbodyTd>
                  </TbodyTr>
                ))}
              </tbody>
            </Table>
          </MobileTableWrap>
        </TableWrapper>
      </div>
      {/* 웹 */}
      <div className="d-none d-md-block">
        <Title
          size={"26px"}
          color={"#1E1E1E"}
          weight={"bold"}
          style={{
            marginTop: "30px",
          }}
        >
          문구는 못말려 공지사항🔔
        </Title>
        <TableWrapper>
          <Title size={"16px"} weight={"bold"}>
            공지사항
          </Title>
          <Text size={"14px"} weight={"400"} align={"left"}>
            문구는 못말려의 업데이트 정보 등 다양한 소식을 알려드립니다.
          </Text>
          <Table style={{ marginTop: "19px" }}>
            <colgroup>
              <col style={{ width: "11%" }} />
              <col />
              <col style={{ width: "16.5%" }} />
              <col style={{ width: "29.5%" }} />
            </colgroup>
            <thead>
              <TbodyTr>
                <TheadTh padding={"10px 0"} borderBottom={"1px solid #666"}>
                  <Text size={"16px"} weight={"bold"}>
                    No
                  </Text>
                </TheadTh>
                <TheadTh padding={"10px 0"} borderBottom={"1px solid #666"}>
                  <Text size={"16px"} weight={"bold"}>
                    제목
                  </Text>
                </TheadTh>
                <TheadTh padding={"10px 0"} borderBottom={"1px solid #666"}>
                  <Text size={"16px"} weight={"bold"}>
                    작성자
                  </Text>
                </TheadTh>
                <TheadTh padding={"10px 0"} borderBottom={"1px solid #666"}>
                  <Text size={"16px"} weight={"bold"}>
                    등록일
                  </Text>
                </TheadTh>
              </TbodyTr>
            </thead>
            <tbody>
              {notice?.map((list, key) => (
                <TbodyTr
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/notice/View", { state: { idx: list.idx } });
                  }}
                >
                  <TbodyTd padding={"13px 0"} borderBottom={"1px solid #C7C7C7"}>
                    <Text size={"14px"} weight={"400"}>
                      {key + 1}
                    </Text>
                  </TbodyTd>
                  <TbodyTd padding={"13px 0"} borderBottom={"1px solid #C7C7C7"}>
                    <Text size={"12px"} weight={"400"} className={"ellipsis"} align={"center"}>
                      {list.title}
                    </Text>
                  </TbodyTd>
                  <TbodyTd padding={"13px 0"} borderBottom={"1px solid #C7C7C7"}>
                    <Text size={"14px"} weight={"400"}>
                      운영자
                    </Text>
                  </TbodyTd>
                  <TbodyTd padding={"13px 0"} borderBottom={"1px solid #C7C7C7"}>
                    <Text size={"14px"} weight={"400"}>
                      {str(list.createdat)}
                    </Text>
                  </TbodyTd>
                </TbodyTr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
      </div>
    </>
  );
}

const TableWrapper = styled.div`
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 10px;
  overflow: hidden;
  padding: 40px 34px;
  min-height: 397px;

  @media all and (max-width: 1024px) {
    padding: 16px 14px;
    min-height: 250px;
  }
`;

const Table = styled.table`
  width: 100%;
  overflow: hidden;
  table-layout: fixed;
`;

const TheadTh = styled.th`
  border-bottom: ${(props) => props?.borderBottom};
  padding: ${(props) => props.padding || "0"};
`;

const TbodyTr = styled.tr`
  border-bottom: ${(props) => props?.borderBottom};
`;

const TbodyTd = styled.td`
  border-bottom: ${(props) => props?.borderBottom};
  padding: ${(props) => props.padding || "0"};
`;

const MobileTableWrap = styled.div`
  overflow-y: auto;

  table {
    min-width: 412px;
  }
`

export default HomeNotice;
