// 외부 모듈
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// 내부 모듈
import { FlexBox, Title, Text, Btn } from "../../Assets/css/commonVer2";
import icoRight from "../../Assets/images/ico_right_arr.svg";
import icoDollar from "../../Assets/images/ico_dollar.svg";
import TextSlider from "../../Components/Slider/TextSlider";

// 오늘 매출 확인

function HomeToday(props) {
  const { sale, deposit } = props;
  const navigate = useNavigate();
  const AddComma = (num) => {
    const num2 = num ? num : 0;
    var regexp = /\B(?=(\d{3})+(?!\d))/g;
    return num2.toString().replace(regexp, ",");
  };
  // 모바일_오늘의 매출_슬라이더
  const myData = [
    { price: `${AddComma(sale.today)}원`, title: "오늘의 매출", link: "/sales" },
    { price: `${AddComma(sale.month)}원`, title: "이번달 매출", link: "/sales/reportsales" },
    { price: `${AddComma(deposit.deposit_num)}원`, title: "잔여 예치금", link: "/goods/deposit/view" },
  ];

  return (
    <>
      {/* 모바일 */}
      <div className={"d-block d-md-none"}>
        <Title size={"18px"} color={"#1E1E1E"} weight={"bold"}>
          오늘의 매출을 확인해보세요! 👀
        </Title>
        <FlexBox align={"center"} justify={"space-between"}>
          <FlexBox align={"center"}>
            <Text
              size={"14px"}
              weight={"bold"}
              color={"#dd1212"}
              style={{
                backgroundImage: "linear-gradient(90deg, #FF4A51, #FF7440)",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
            >
              전일 대비{" "}
              <span style={{ fontFamily: "Montserrat" }}>
                {sale.change_rate !== "NaN" && sale.change_rate !== "-Infinity" ? Math.abs(sale.change_rate) : 0}%{" "}
              </span>
              {sale.change_rate > 0 ? "증가했습니다." : "감소했습니다."}
            </Text>
          </FlexBox>
          <Btn
            size={"12px"}
            color={"#fff"}
            weight={"bold"}
            padding={"10px 6px"}
            background={"#FF4A4A"}
            radius={"6px"}
            onClick={() => navigate("/sales")}
          >
            매출보기 →
          </Btn>
        </FlexBox>
        <TextSlider
          space={10}
          contents={myData}
          slidesPerView={myData.length}
          styles={{ border: true }}
        />
      </div>
      {/* 웹 */}
      <div className="d-none d-md-block">
        <FlexBox align={"center"} justify={"space-between"} wrap={"wrap"}>
          <Title size={"26px"} color={"#1E1E1E"} weight={"bold"}>
            오늘의 매출을 확인해보세요! 👀
          </Title>
          <FlexBox align={"center"}>
            <FlexBox align={"center"} gap={"10px"}>
              <Text
                size={"26px"}
                weight={"bold"}
                style={{
                  backgroundImage: "linear-gradient(90deg, #FF4A51, #FF7440)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}
              >
                전일 대비{" "}
                <span style={{ fontFamily: "Montserrat" }}>
                  {sale.change_rate !== "NaN" && sale.change_rate !== "-Infinity" ? Math.abs(sale.change_rate) : 0}%{" "}
                </span>
                {sale.change_rate > 0 ? "증가했습니다." : "감소했습니다."}
              </Text>
            </FlexBox>
            <Btn
              size={"14px"}
              color={"#fff"}
              weight={"700"}
              padding={"9px 19px"}
              margin={"0 0 0 12px"}
              background={"#FF4A4A"}
              radius={"6px"}
              onClick={() => navigate("/sales")}
            >
              매출보기 →
            </Btn>
          </FlexBox>
        </FlexBox>
        <SearchResultFlexBox align={"center"}>
          <SearchResultBox onClick={() => navigate("/sales")} style={{ cursor: "pointer" }}>
            <div>
              <Text
                size={"26px"}
                color={"#FF4A4A"}
                weight={"bold"}
                style={{
                  fontFamily: "Montserrat",
                  marginBottom: "10px",
                  textAlign: "left",
                }}
              >
                {sale ? AddComma(sale.today) : 0}
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "400",
                  }}
                >
                  원
                </span>
              </Text>
              <Text size={"18px"} color={"#1E1E1E"} weight={"400"} align={"left"}>
                오늘의 매출
              </Text>
            </div>
            <img src={icoRight} />
          </SearchResultBox>
          <SearchResultBox onClick={() => navigate("/sales/reportsales")} style={{ cursor: "pointer" }}>
            <div>
              <Text
                size={"26px"}
                color={"#FF4A4A"}
                weight={"bold"}
                style={{
                  fontFamily: "Montserrat",
                  marginBottom: "10px",
                  textAlign: "left",
                }}
              >
                {sale ? AddComma(sale.month) : 0}
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "400",
                  }}
                >
                  원
                </span>
              </Text>
              <Text size={"18px"} color={"#1E1E1E"} weight={"400"} align={"left"}>
                이번달 매출
              </Text>
            </div>
            <img src={icoRight} />
          </SearchResultBox>
          <SearchResultBox onClick={() => navigate("/goods/deposit/view")} style={{ cursor: "pointer" }}>
            <div>
              <Text
                size={"26px"}
                color={"#FF4A4A"}
                weight={"bold"}
                style={{
                  fontFamily: "Montserrat",
                  marginBottom: "10px",
                  textAlign: "left",
                }}
              >
                {deposit ? AddComma(deposit.deposit_num) : 0}
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "400",
                  }}
                >
                  원
                </span>
              </Text>
              <Text size={"18px"} color={"#1E1E1E"} weight={"400"} align={"left"}>
                잔여 예치금
              </Text>
            </div>
            <img src={icoDollar} />
          </SearchResultBox>
        </SearchResultFlexBox>
      </div>
    </>
  );
}

const SearchResultFlexBox = styled(FlexBox)`
  gap: 26px;
  @media (max-width: 1201px) {
    gap: 13px;
    flex-direction: column;
  }
`;

const SearchResultBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 43px 40px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  overflow: hidden;
  @media (max-width: 1201px) {
    width: 100%;
    padding: 20px 40px;
    // margin-top: 5px;
  }
`;
export default HomeToday;
