import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import ico_back from "../../Assets/images/ico_back.png";

function HeaderSub(props) {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const { title, onClick = goBack } = props;

  return (
    <>
      <Wrapper>
        <BackBtn onClick={onClick}>
          <IconM src={ico_back} />
        </BackBtn>
        <HdTitle>{title}</HdTitle>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  padding: 0 12px;
  border-bottom: 1px solid #eaeaea;
  background-color: #fff;
  z-index: 250;

  @media all and (max-width: 1024px) {
    display: flex;
  }
`;
const BackBtn = styled.button`
  position: absolute;
  top: 18px;
  left: 12px;
  display: block;
`;
const IconM = styled.img`
  width: 24px;
`;
const HdTitle = styled.p`
  // font-family: binggrae;
  font-size: 18px;
  color: #000;
  font-weight: 700;
`;

export default HeaderSub;
