import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { Text } from "../../Assets/css/common";
import { Btn, FlexBox, TextInput, TextInputArea } from "../../Assets/css/commonVer2";
import close from "../../Assets/images/close.png";
import { AddComma } from "../../Utiles/Custom";

// 리뉴얼 UI : 사용O

export default function InputModal(props) {
  const {
    modal, // 필수
    setModal, // 필수
    mode, // 필수 (writeReason, delivery, setRangePoint)
    onSubmit = () => { }, // 필수
    title, // 필수
    placeholder, // 옵션 : writeReason 일때만
    inputValue, // 옵션 : writeReason 일때만
    setInputValue, // 옵션 : writeReason 일때만
    deliveryInfo, // 옵션 : delivery 일때만
    tempDeliveryInfo, // 옵션 : delivery 일때만
    setTempDeliveryInfo, // 옵션 : delivery 일때만
    tempRangeValue, // 옵션 : setRangePoint 일때만
    setTempRangeValue, // 옵션 : setRangePoint 일때만
  } = props;

  const scrollRef = useRef();
  // mode === delivery
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTempDeliveryInfo((prevDeliveryInfo) => ({
      ...prevDeliveryInfo,
      [name]: value,
    }));
  };

  // mode === setRangePoint
  const handleRangeInputChange = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, '');

    setTempRangeValue({
      ...tempRangeValue,
      [e.target.name]: parseInt(inputValue, 10),
    });
  };

  const onClose = () => {
    if (deliveryInfo) {
      setTempDeliveryInfo(deliveryInfo);
    }
    if (inputValue) {
      setInputValue("");
    }
    if (tempRangeValue) {
      setTempRangeValue({
        more: 0,
        less: 0,
        ratio: 0
      });
    }
    setModal(false);
  };

  useEffect(() => {
    if (deliveryInfo) setTempDeliveryInfo(tempDeliveryInfo);
  }, [tempDeliveryInfo]);

  return (
    <>
      {modal ? (
        <ModalBg>
          <ModalConts>
            <CancelBtn onClick={onClose}>
              <img src={close} />
            </CancelBtn>
            <Text
              color={"#1E1E1E"}
              size={"14px"}
              sizeMd={"16px"}
              weight={"500"}
              margin={"0 0 10px"}
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              {title}
            </Text>

            {mode === "writeReason" && (
              <ScrollBox ref={scrollRef}>
                <TextInputArea
                  value={inputValue}
                  minHeight={"100px"}
                  type={"text"}
                  placeholder={placeholder}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                  }}
                />
              </ScrollBox>
            )}

            {mode === "delivery" && (
              <>
                <TextInput
                  type={"text"}
                  name={"courier_company"}
                  value={tempDeliveryInfo.courier_company}
                  onChange={handleInputChange}
                  margin={"0 0 10px"}
                  placeholder="택배사 이름을 입력해주세요."
                />
                <TextInput
                  type={"text"}
                  name={"tracking_number"}
                  value={tempDeliveryInfo.tracking_number}
                  onChange={handleInputChange}
                  placeholder="운송장 번호를 입력해주세요."
                />
              </>
            )}

            {mode === "setRangePoint" && (
              <>
                <FlexBox
                  gap={"12px"}
                  margin={"0 0 10px"}
                >
                  <div style={{ position: "relative", width: "100%" }}>
                    <TextInput
                      type={"text"}
                      name={"more"}
                      color={"#2d2d2d"}
                      align={"right"}
                      value={AddComma(tempRangeValue.more)}
                      onChange={handleRangeInputChange}
                      style={{ paddingRight: "25px" }}
                    />
                    <Text
                      color={"#C7C7C7"}
                      style={{
                        position: "absolute",
                        right: "12px",
                        top: "13px",
                      }}
                    >
                      원
                    </Text>
                  </div>
                  <Text
                    color={"#1E1E1E"}
                    size={"16px"}
                    letterSpacing={"-0.32px"}
                  >&#126;</Text>
                  <div style={{ position: "relative", width: "100%" }}>
                    <TextInput
                      type={"text"}
                      name={"less"}
                      color={"#2d2d2d"}
                      align={"right"}
                      value={AddComma(tempRangeValue.less)}
                      onChange={handleRangeInputChange}
                      style={{ paddingRight: "25px" }}
                    />
                    <Text
                      color={"#C7C7C7"}
                      style={{
                        position: "absolute",
                        right: "12px",
                        top: "13px",
                      }}
                    >
                      원
                    </Text>
                  </div>
                </FlexBox>
                <div style={{ position: "relative", width: "100%" }}>
                  <TextInput
                    type={"text"}
                    name={"ratio"}
                    family={"Montserrat"}
                    weight={"700"}
                    size={"20px"}
                    sizeMd={"16px"}
                    align={"right"}
                    style={{ paddingRight: "25px" }}
                    value={AddComma(tempRangeValue.ratio)}
                    onChange={handleRangeInputChange}
                  />
                  <Text
                    color={"#C7C7C7"}
                    style={{
                      position: "absolute",
                      right: "12px",
                      top: "13px",
                    }}
                  >
                    %
                  </Text>
                </div>
              </>
            )}

            <FlexBox margin={"12px 0 0"} marginMd={"17px 0 0"}>
              <Btn
                background={"#f2f2f2"}
                color={"#666"}
                border={"none"}
                weight={"500"}
                margin={"0 10px 0 0"}
                size={"12px"}
                sizeMd={"14px"}
                padding={"9px 19px"}
                paddingMd={"9px 19px"}
                onClick={onClose}
              >
                취소
              </Btn>
              <Btn
                background={"#FF4A4A"}
                color={"#fff"}
                border={"none"}
                weight={"700"}
                size={"12px"}
                sizeMd={"14px"}
                padding={"9px 19px"}
                paddingMd={"9px 19px"}
                onClick={onSubmit}
              >
                저장
              </Btn>
            </FlexBox>
          </ModalConts>
        </ModalBg >
      ) : (
        ""
      )
      }
    </>
  );
}

const ModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 990;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

const ModalConts = styled.div`
  background-color: #fff;
  max-width: 360px;
  width: 100%;
  padding: 44px 30px 28px;
  border-radius: 8px;
  position: relative;

  @media all and (max-width: 1024px) {
    max-width: 280px;
    padding: 40px 20px 30px;
  }
`;

const ScrollBox = styled.div`
  max-height: 420px;
  overflow: auto;
  padding: 0 10px 0 10px;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: silver;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #6c6c6c;
  }
`;

const CancelBtn = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;

  @media all and (max-width: 1024px) {
    top: 12px;
    right: 12px;
  }
`;
