import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  TableWrap,
  Th,
  Td,
  Btn,
  CheckLabel,
  CheckIcon,
  SearchInput,
  HeaderContainer,
  Wrapper,
  Container,
  ContentContainer,
  FlexBox,
  WhiteBox,
  Title,
} from "../../Assets/css/commonVer2";
import { SEARCH_TYPE_LIST, UNIT_10_TO_30_LIST } from "../../Utiles/ListData";
import Header from "../../Components/Header/HeaderVer2";
import API from "../../Utiles/API";
import { str } from "../../Utiles/Custom";
import Authority from "../../Components/Modal/Authority";
import { AddComma } from "../../Utiles/Custom";
import Loading from "../../Components/Loading";
import FranSelect from "../../Components/Select/FranSelect";
import Pagination from "../../Components/Paging/Pagination";
import BasicSelect from "../../Components/Select/BasicSelect";
import ProductSearchInputVer2 from "../../Components/SearchInput/ProductSearchInputVer2";
import SelectStatusModal from "../../Components/Modal/SelectStatusModal";
import InfoModal from "../../Components/Modal/InfoModal";
import InputModal from "../../Components/Modal/InputModal";

// 자점매입 > 검수관리

function Inspection() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [franchise_id, setFranchiseId] = useState("");
  const [loading, setLoading] = useState(false);

  // 검색 관련
  const [filter, setFilter] = useState("product_name");
  const [inputValue, setInputValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchList, setShowSearchList] = useState(false);
  const [franShow, setFranShow] = useState(false);
  const [franText, setFranText] = useState("가맹점 전체");
  const [franSelect, setFranSelect] = useState(false);

  // 모달들
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [inspectionModal, setInspectionModal] = useState(false);
  const [textInputModal, setTextInputModal] = useState(false);
  const [inputReason, setInputReason] = useState("");
  const [refreshModal, setRefreshModal] = useState(false);

  // 데이터 관련
  const [data, setData] = useState([]);
  const [checkItems, setCheckItems] = useState([]);

  // 페이징 관련
  const [pagination, setPagination] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // 검수 관련
  const [slotStatus, setSlotStatus] = useState("onSale");

  const [hoverRow, setHoverRow] = useState(null);

  const getSlotList = async () => {
    let api_link = `/slot/user/product?page=${currentPage}&unit=${pageSize}&block=10&slot_status_filter=adminChecking&gubun=admin`;
    if (franchise_id) api_link += `&franchise_id=${franchise_id}`;
    if (inputValue) api_link += `&search_type=${filter}&search_word=${inputValue}`;
    await API.get(api_link).then((res) => {
      if (res.data.status) {
        setPagination(res.data.pagination);
        setData(res.data.results);
      }
    });
  };

  const changStatus = async () => {
    setLoading(true);
    await API.put(`/slot/admin-checking`, {
      change_status: slotStatus,
      return_reason: inputReason,
      request_list: checkItems,
    })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setTextMsg("요청이 처리되었습니다.");
          setRefreshModal(true);
        } else {
          setLoading(false);
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setLoading(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const onChangeChx = (checked, id) => {
    if (checked) {
      setCheckItems((prev) => [...prev, id]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  const onChangeAllChk = (checked) => {
    if (checked) {
      const chkArr = data.map((item) => item.idx);
      setCheckItems(chkArr);
    } else {
      setCheckItems([]);
    }
  };

  const movePage = (e) => {
    setCurrentPage(e);
    setCheckItems([]);
  };

  const onSearch = () => {
    setSearchResults([]);
    setShowSearchList(false);
    setCurrentPage(1);
    getSlotList();
    setCheckItems([]);
  };

  const onSubmit = (e) => {
    if (e === 1) {
      setInspectionModal(false);
      if (slotStatus === "onSale") {
        changStatus();
      } else {
        setTextInputModal(true);
      }
    } else if (e === 2) {
      if (inputReason) {
        setTextInputModal(false);
        changStatus();
      } else {
        alert("사유룰 입력해주세요.");
      }
    }
  };

  useEffect(() => {
    setInputValue("");
  }, [filter]);

  useEffect(() => {
    getSlotList();
  }, [currentPage, pageSize]);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {loading ? <Loading /> : null}
          <>
            {isMobile && <Header title={"검수 관리"} />}
            <ContentContainer>
              <Wrapper>
                <Container>
                  {!isMobile && (
                    <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                      검수관리 📋
                    </Title>
                  )}
                  {/* 공통 - 필터 시작 */}
                  <WhiteBox margin={"0 0"}>
                    <FlexBox
                      justify={"end"}
                      gap={"10px"}
                      gapMd={"15px"}
                      margin={"0 0 "}
                      marginMd={"28px 0 20px 0 "}
                      wrap={"wrap"}
                    >
                      <FranSelect
                        widthMd={"48%"}
                        gubun={"fran"}
                        selectBtnText={franText}
                        setTextChange={setFranText}
                        placeholder={"가맹점을"}
                        show={franShow}
                        setFranShow={setFranShow}
                        setFranSelect={setFranSelect}
                        setIdxChange={setFranchiseId}
                      />
                      <BasicSelect
                        widthMd={"48%"}
                        data={SEARCH_TYPE_LIST}
                        selectedValue={filter}
                        setSelectedValue={setFilter}
                        canSelectAll={false}
                      />
                      {!isMobile && (
                        <FlexBox gap={"10px"}>
                          {filter === "barcode_num" ? (
                            <SearchInput
                              placeholder="바코드번호를 입력해주세요."
                              value={inputValue}
                              onChange={(e) => setInputValue(e.target.value)}
                            />
                          ) : (
                            <ProductSearchInputVer2
                              showSearchList={showSearchList}
                              setShowSearchList={setShowSearchList}
                              searchResults={searchResults}
                              setSearchResults={setSearchResults}
                              inputValue={inputValue}
                              setInputValue={setInputValue}
                              onSearch={onSearch}
                              cate1value={2}
                              franchiseId={franchise_id}
                            />
                          )}
                          <Btn
                            color={"#fff"}
                            background={"#FF4A4A"}
                            radius={"6px"}
                            size={"14px"}
                            weight={"700"}
                            padding={"9px 19px"}
                            maxHeight={"40px"}
                            style={{ lineHeight: "22px" }}
                            onClick={onSearch}
                          >
                            검 색
                          </Btn>
                        </FlexBox>
                      )}
                    </FlexBox>
                    {isMobile && (
                      <FlexBox gap={"10px"} margin={"10px 0"}>
                        {filter === "barcode_num" ? (
                          <SearchInput
                            placeholder="바코드번호를 입력해주세요."
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                          />
                        ) : (
                          <ProductSearchInputVer2
                            showSearchList={showSearchList}
                            setShowSearchList={setShowSearchList}
                            searchResults={searchResults}
                            setSearchResults={setSearchResults}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            onSearch={onSearch}
                            cate1value={2}
                            franchiseId={franchise_id}
                          />
                        )}
                        <Btn
                          color={"#fff"}
                          background={"#FF4A4A"}
                          radius={"6px"}
                          size={"14px"}
                          weight={"700"}
                          width={"80px"}
                          padding={"9px 19px"}
                          lineHeight={"22px"}
                          onClick={onSearch}
                        >
                          검 색
                        </Btn>
                      </FlexBox>
                    )}
                    {/* 공통 - 기타 버튼들 시작 */}
                    <FlexBox
                      justify={"space-between"}
                      margin={"10px 0 10px"}
                      marginMd={"10px 0 30px"}
                      gap={"10px"}
                      wrap={"wrap"}
                    >
                      <FlexBox justify={"starts"}>
                        {!isMobile && (
                          <BasicSelect
                            data={UNIT_10_TO_30_LIST}
                            selectedValue={pageSize}
                            setSelectedValue={setPageSize}
                            canSelectAll={false}
                            setCurrentPage={setCurrentPage}
                          />
                        )}
                      </FlexBox>
                      <FlexBox justify={"end"} gap={"15px"}>
                        <Btn
                          border={"1px solid #FF4A4A"}
                          color={"#FF4A4A"}
                          hoverColor={"#FFFFFF"}
                          hoverBackground={"#FF4A4A"}
                          hoverWeight={"700"}
                          padding={"9px 19px"}
                          size={"14px"}
                          radius={"6px"}
                          weight={"400"}
                          lineHeight={"22px"}
                          onClick={() => {
                            if (checkItems.length !== 0) {
                              setInspectionModal(true);
                            } else {
                              setTextMsg("상품을 선택해주세요.");
                              setTextModal(true);
                            }
                          }}
                        >
                          검수
                        </Btn>
                      </FlexBox>
                    </FlexBox>
                    {/* 공통 - 데이터 시작 */}
                    <TableWrap>
                      <table style={{ width: "100%" }}>
                        <colgroup>
                          <col style={{ width: "4%" }} />
                          <col />
                          <col style={{ width: "7%" }} />
                          <col style={{ width: "7%" }} />
                          <col style={{ width: "10%" }} />
                          <col />
                          <col />
                          <col style={{ width: "10%" }} />
                          <col style={{ width: "10%" }} />
                          <col style={{ width: "7%" }} />
                        </colgroup>
                        <thead>
                          <tr style={{ borderRight: "1px solid #c0c0c0" }}>
                            <Th>
                              <CheckLabel htmlFor="chkAll" style={{ justifyContent: "center" }}>
                                <input
                                  type="checkbox"
                                  id="chkAll"
                                  onChange={(event) => onChangeAllChk(event.target.checked)}
                                  checked={checkItems.length === data.length ? true : false}
                                />
                                <CheckIcon>
                                  <svg viewBox="0 0 24 24">
                                    <polyline points="19 7 10 17 5 12" />
                                  </svg>
                                </CheckIcon>
                              </CheckLabel>
                            </Th>
                            <Th>가맹점명</Th>
                            <Th>주문번호</Th>
                            <Th>슬롯번호</Th>
                            <Th>상품분류</Th>
                            <Th>바코드 번호</Th>
                            <Th>상품명</Th>
                            <Th>소비자가</Th>
                            <Th>만료일</Th>
                            <Th>상태</Th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.length > 0 ? (
                            data.map((list, key) => (
                              <tr
                                style={{
                                  cursor: "pointer",
                                }}
                                key={key}
                                onMouseEnter={() => {
                                  setHoverRow(key);
                                }}
                                onMouseLeave={() => {
                                  setHoverRow(null);
                                }}
                                onClick={(event) => {
                                  const targetElement = event.target;
                                  // 체크박스 클릭 시 반복호출 방지
                                  if (targetElement.nodeName !== "INPUT") {
                                    const isChecked = document.getElementById(`prod${list.idx}`).checked;
                                    onChangeChx(!isChecked, list.idx);
                                  }
                                }}
                              >
                                <Td align={"center"} style={{ borderBottom: "1px solid #F2F2F2" }}>
                                  <CheckLabel htmlFor={`prod${list.idx}`} style={{ justifyContent: "center" }}>
                                    <input
                                      type="checkbox"
                                      name="prod"
                                      id={`prod${list.idx}`}
                                      readOnly
                                      checked={checkItems.includes(list.idx) ? true : false}
                                    />
                                    <CheckIcon>
                                      <svg viewBox="0 0 24 24">
                                        <polyline points="19 7 10 17 5 12" />
                                      </svg>
                                    </CheckIcon>
                                  </CheckLabel>
                                </Td>
                                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                  {list.franchise_name ? list.franchise_name : "덕은한강점"}
                                </Td>
                                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                  {list.purchase_slot_id ? list.purchase_slot_id : "-"}
                                </Td>
                                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{list.idx ? list.idx : "-"}</Td>
                                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                  {list.category2nd_name ? list.category2nd_name : "-"}
                                </Td>
                                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                  {list.product_barcode_num ? list.product_barcode_num : "-"}
                                </Td>
                                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                  {list.product_product_name ? list.product_product_name : "-"}
                                </Td>
                                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                  {list.product_consumer_price ? AddComma(list.product_consumer_price) : "-"}
                                  {list.product_consumer_price ? "원" : ""}
                                </Td>
                                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                  {list.expirationAt ? str(list.expirationAt) : ""}
                                </Td>
                                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>검수 요청</Td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan={10}
                                style={{
                                  borderTop: "1px solid #e1e1e1",
                                  textAlign: "center",
                                  padding: 10,
                                  fontSize: "14px",
                                }}
                              >
                                데이터가 없습니다.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </TableWrap>
                    {pagination?.total_page > 0 ? (
                      <Pagination pagination={pagination} movePage={movePage} currentPage={currentPage} />
                    ) : (
                      ""
                    )}
                  </WhiteBox>
                </Container>
                <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
                <InfoModal modal={refreshModal} setModal={setRefreshModal} description={textMsg} mode={"refresh"} />

                <SelectStatusModal
                  modal={inspectionModal}
                  setModal={setInspectionModal}
                  slotStatus={slotStatus}
                  setSlotStatus={setSlotStatus}
                  slotNum={checkItems}
                  onSubmit={() => onSubmit(1)}
                />
                <InputModal
                  modal={textInputModal}
                  setModal={setTextInputModal}
                  mode={"writeReason"}
                  title={"검수반려 사유"}
                  placeholder={"검수반려 사유를 작성해주세요."}
                  inputValue={inputReason}
                  setInputValue={setInputReason}
                  onSubmit={() => onSubmit(2)}
                />
              </Wrapper>
            </ContentContainer>
          </>
        </>
      )}
    </>
  );
}

export default Inspection;
