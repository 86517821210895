import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import styled from "styled-components";
import { PagerWrap } from "../../Assets/css/commonVer2";

/* code from sub_outsourcing */

export default function Pagination({ pagination, movePage, currentPage }) {
  const { has_next, has_result, total_page } = pagination;
  // totalPages는 이중 배열로 들어갑니다.
  // 총 페이지를 35를 받아 왔을 경우 10 페이지씩 묶어서 4개의 배열이 저장됩니다.
  // 0: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  // ......
  // 3: [31, 32, 33, 34, 35]
  // 와 같이 저장이 되고, 다음 버튼이나 이전 버튼을 누르면 블록이 이동되고
  // 블록의 숫자에 따라 배열이 변경되고 화면에는 각 블록의 숫자에 해당하는 페이지들이 출력됩니다.
  const [totalPages, setTotalPages] = useState([]);

  // 페이지 변경 시 다음 페이지로 이동되고 검색이 되야하니깐 검색 객체를 건드리게 된다.
  const [nowPage, setNowPage] = useState(1);

  // 현재 보고 있는 페이지의 블록 ex) 내가 1페이지 ~ 10페이지 사이에 있다면 1블록, 11~20이라면 2블록
  const [nowBlock, setNowBlock] = useState(0);

  // 서버로부터 받아온 전체페이지가 수정될 때마다 배열 수정 해준다.
  useEffect(() => {
    const array = Array(total_page)
      .fill()
      .map((array, i) => i + 1);
    setTotalPages(chunk(array, 10));
    setNowPage(currentPage ? currentPage : 1);
    if(currentPage){
      setNowBlock(Math.floor((currentPage - 1) / 10));
    }else{
      setNowBlock(0);
    }
 
  }, [total_page]);

  useEffect(() => {
    setNowPage(currentPage ? currentPage : 1);
  }, [currentPage]);

  return (
    <PagerWrap>
      {nowBlock !== 0 && (
        <a
        href="#"
        className="page_prev"
        onClick={() => {
          if(totalPages.length > 0){
            movePage(totalPages[nowBlock - 1][0]);
            setNowPage(totalPages[nowBlock - 1][0]);
            setNowBlock(nowBlock - 1);
          }
    
        }}
      >
        <i></i>
      </a>
      )}
      {totalPages?.[nowBlock]?.map((i) => {
        return (
          <a
            key={i}
            href="#"
            className={i == nowPage ? " active" : ""}
            data-value={i}
            onClick={(e) => {
              movePage(e.target.dataset.value);
              setNowPage(e.target.dataset.value);
            }}
          >
            {" "}
            {i}
          </a>
        );
      })}
      {nowBlock + 1 !== totalPages.length && (
        <a
        href="#"
        className="page_next"
        onClick={() => {
          setNowPage(totalPages[nowBlock + 1][0]);
          setNowBlock(nowBlock + 1);
          movePage(totalPages[nowBlock + 1][0]);
        }}
      >
       <i></i>
      </a>
      )}
    </PagerWrap>
  );
}

// 배열의 아이템을 n개 만큼 묶어주는 함수
function chunk(data = [], size = 1) {
  const arr = [];

  for (let i = 0; i < data.length; i += size) {
    arr.push(data.slice(i, i + size));
  }

  return arr;
}
