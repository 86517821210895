import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const baseURL = process.env.REACT_APP_BASEURL;
// const baseURL =  'https://bupne5clqb.execute-api.ap-northeast-2.amazonaws.com/staging/'

// form데이터 전송용
const formAPI = axios.create({
  baseURL: baseURL,
  timeout: 200000,
  data: {},
  headers: {
    "content-type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  },
});

formAPI.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("accessToken");
  config.headers.Authorization = `Bearer${accessToken}`;

  return config;
});

formAPI.interceptors.response.use(
  (response) => {
    // console.log("모든 response 기록 : ", response);
    // console.log("모든 response 기록 : ", response.data.status);
    // if(response.data.status){
    //     return response;
    // }else{
    //     if()
    //     alert('요청 처리 중 문제가 생겼습니다. 잠시 후 다시 시도해주세요.');
    // }
    return response;
  },
  async (error) => {
    const {
      config,
      response: { status },
    } = error;
    if (status === 401) {
      if (error.response.data.message === "jwt expired") {
        const originalRequest = config;
        const refreshToken = localStorage.getItem("refreshToken");
        const accessToken = localStorage.getItem("accessToken");

        // token refresh 요청
        try {
          // console.log("refresh111");
          const { data } = await axios.post(
            `${baseURL}/auth/refresh`, // token refresh api
            {},
            {
              headers: {
                authorization: `Bearer${accessToken}`,
                refresh: refreshToken,
              },
            },
          );
          // console.log("refresh222222");
          if (data) {
            // console.log("refresh3333333");
            // console.log("data", data);
            localStorage.setItem("refreshToken", data.data.refreshToken);
            localStorage.setItem("accessToken", data.data.accessToken);
            // originalRequest.headers.authorization = `Bearer${localStorage.getItem(
            //   "accessToken"
            // )}`;
            // console.log("방금 받아온 accessToken : ", data.data.accessToken);
            // console.log(
            //   "localStorage의 accessToken : ",
            //   localStorage.getItem("accessToken")
            // );
            originalRequest.headers.authorization = `Bearer${data.data.accessToken}`;
            return await formAPI.request(originalRequest);
          }
        } catch (error) {
          console.log("error", error);
          sessionStorage.removeItem("user_id");
          sessionStorage.removeItem("franchise_id");
          sessionStorage.removeItem("gubun");
          localStorage.removeItem("user_id");
          localStorage.removeItem("franchise_id");
          localStorage.removeItem("gubun");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("accessToken");
          window.location.replace("/");
          alert("토큰이 만료되었습니다. 다시 로그인 해주세요.");
        }
        return Promise.reject(error);
      }

      // if (error.response.data.message === "jwt expired") {
      //   const originalRequest = config;
      //   const refreshToken = localStorage.getItem("refreshToken");
      //   const accessToken = localStorage.getItem("accessToken");

      //   // token refresh 요청
      //   const { data } = await axios
      //     .post(
      //       `${baseURL}/auth/refresh`, // token refresh api
      //       {},
      //       {
      //         headers: {
      //           authorization: `Bearer${accessToken}`,
      //           refresh: refreshToken,
      //         },
      //       }
      //     )

      //     .then(async (res) => {
      //       // 새로운 토큰 저장
      //       localStorage.setItem("refreshToken", data.data.refreshToken);
      //       localStorage.setItem("accessToken", data.data.accessToken);

      //       //   axios.defaults.headers.common.Authorization = `Bearer${localStorage.getItem('accessToken') }`;

      //       // 요청 실패했던 요청 새로운 토큰으로 재요청
      //       originalRequest.headers.authorization = `Bearer${localStorage.getItem(
      //         "accessToken"
      //       )}`;
      //       return await axios(originalRequest);
      //     })
      //     .catch((err) => {
      //       if (err.response.data.message === "No authorized!") {
      //         sessionStorage.removeItem("user_id");
      //         sessionStorage.removeItem("franchise_id");
      //         sessionStorage.removeItem("gubun");
      //         localStorage.removeItem("user_id");
      //         localStorage.removeItem("franchise_id");
      //         localStorage.removeItem("gubun");
      //         sessionStorage.removeItem("refreshToken");
      //         localStorage.removeItem("accessToken");
      //         window.location.replace("/");
      //         alert("토큰이 만료되었습니다. 다시 로그인 해주세요.");
      //       }
      //     });
      // } else if (error.response.data.message === "jwt malformed") {
      //   sessionStorage.removeItem("user_id");
      //   sessionStorage.removeItem("franchise_id");
      //   sessionStorage.removeItem("gubun");
      //   localStorage.removeItem("user_id");
      //   localStorage.removeItem("franchise_id");
      //   localStorage.removeItem("gubun");
      //   sessionStorage.removeItem("refreshToken");
      //   localStorage.removeItem("accessToken");
      //   window.location.replace("/");
      //   alert("토큰이 만료되었습니다. 다시 로그인 해주세요.");
      // }
    } else if (status === 504) {
      alert("요청 처리가 완료되지 않았습니다. 잠시 후 다시 시도해주세요. ERR : 504");
    }

    return Promise.reject(error);
  },
);

export default formAPI;
