import React from "react";
import styled from "styled-components";
import { Text } from "../../Assets/css/common";
import { Btn, FlexBox, Select } from "../../Assets/css/commonVer2";
import close from "../../Assets/images/close.png";
import { INSPECTION_STATUS_LIST } from "../../Utiles/ListData";

// 리뉴얼 UI : 사용O

export default function SelectStatusModal(props) {
  const { modal, setModal, onSubmit = () => {}, slotNum, slotStatus, setSlotStatus } = props;

  const onClose = () => {
    setModal(false);
  };

  const onFilterChange = (e) => {
    setSlotStatus(e.target.value);
  };

  return (
    <>
      {modal ? (
        <ModalBg>
          <ModalConts>
            <CancelBtn onClick={onClose}>
              <img src={close} />
            </CancelBtn>
            <Text
              color={"#1E1E1E"}
              size={"14px"}
              sizeMd={"16px"}
              style={{
                whiteSpace: "pre-wrap",
                margin: "10px 0",
              }}
            >
              {slotNum.join(", ")} 슬롯의 상품을
            </Text>

            <FlexBox gap={"5px"}>
              <Select
                value={slotStatus}
                onChange={onFilterChange}
                size={"14px"}
                weight={"500"}
                style={{
                  width: "100px",
                }}
              >
                {INSPECTION_STATUS_LIST.map((list) => (
                  <option value={list.value}>{list.text}</option>
                ))}
              </Select>

              <Text
                color={"#1E1E1E"}
                size={"14px"}
                sizeMd={"16px"}
                style={{
                  whiteSpace: "pre-wrap",
                }}
              >
                상태로 변경하시겠습니까?
              </Text>
            </FlexBox>

            <FlexBox margin={"12px 0 0"} marginMd={"17px 0 0"}>
              <Btn
                background={"#f2f2f2"}
                color={"#666"}
                border={"none"}
                weight={"500"}
                margin={"0 10px 0 0"}
                size={"12px"}
                sizeMd={"14px"}
                padding={"9px 19px"}
                paddingMd={"9px 19px"}
                onClick={onClose}
              >
                취소
              </Btn>
              <Btn
                background={"#FF4A4A"}
                color={"#fff"}
                border={"none"}
                weight={"700"}
                size={"12px"}
                sizeMd={"14px"}
                padding={"9px 19px"}
                paddingMd={"9px 19px"}
                onClick={onSubmit}
              >
                확인
              </Btn>
            </FlexBox>
          </ModalConts>
        </ModalBg>
      ) : (
        ""
      )}
    </>
  );
}

const ModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

const ModalConts = styled.div`
  background-color: #fff;
  padding: 35px 25px 24px;
  border-radius: 8px;
  position: relative;

  @media all and (max-width: 1024px) {
    padding: 40px 20px 30px;
  }
`;

const CancelBtn = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;

  @media all and (max-width: 1024px) {
    top: 12px;
    right: 12px;
  }
`;
