import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import {
  ContentContainer,
  Wrapper,
  Container,
  WhiteBox,
  FlexBox,
  Title,
  Text,
  Btn,
  TableWrap,
  Th,
  Td,
} from "../../Assets/css/commonVer2";
import Header from "../../Components/Header/HeaderVer2";
import Authority from "../../Components/Modal/Authority";
import InfoModal from "../../Components/Modal/InfoModal";
import BasicSelect from "../../Components/Select/BasicSelect";
import CustomerSearchInput from "./Components/CustomerSearchInput";
import Pagination from "../../Components/Paging/Pagination";
import HandleBack from "../../Components/HandleBack";
import { Xlsx_download, AddComma, hypen } from "../../Utiles/Custom";
import { UNIT_10_TO_50_LIST, SHOP_SEARCH_TYPE_LIST } from "../../Utiles/ListData";
import API from "../../Utiles/API";

// 쇼핑몰 > 고객 리스트

function ShopCustomer() {
  const navigate = useNavigate();
  const { handlePopstate } = HandleBack();
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  // 모달
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");

  // 페이징
  const [pagination, setPagination] = useState("");

  // 고객 상세를 다녀온 경우 저장 해놓은 파라미터 정보
  const parsedCustomerPageState = sessionStorage.getItem("customerPageState")
    ? JSON.parse(sessionStorage.getItem("customerPageState"))
    : {};

  const [pageSize, setPageSize] = useState(
    parsedCustomerPageState ? (parsedCustomerPageState?.pageSize ? parsedCustomerPageState?.pageSize : 10) : 10
  );
  const [currentPage, setCurrentPage] = useState(
    parsedCustomerPageState ? (parsedCustomerPageState?.currentPage ? parsedCustomerPageState?.currentPage : 1) : 1
  );
  const [searchGubun, setSearchGubun] = useState(
    parsedCustomerPageState
      ? parsedCustomerPageState?.searchGubun
        ? parsedCustomerPageState?.searchGubun
        : "phone"
      : "phone"
  );
  const [inputValue, setInputValue] = useState(
    parsedCustomerPageState ? (parsedCustomerPageState?.inputValue ? parsedCustomerPageState?.inputValue : "") : ""
  );

  // 고객 상세를 갈 때 sessionStorage에 저장할 객체
  const customerPageState = {
    currentPage,
    pageSize,
    searchGubun,
    inputValue,
  };

  // 휴대폰번호/고객명 검색 결과
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchList, setShowSearchList] = useState(false);

  // 고객리스트 데이터
  const [customerCnt, setCustomerCnt] = useState(0);
  const [customerList, setCustomerList] = useState([]);

  // API 고객리스트 조회
  const getCustomerList = async () => {
    let api_link = `/shoppingmall/consumer-list?page=${currentPage}&unit=${pageSize}`;
    if (inputValue) {
      if (inputValue.includes("-")) {
        const newInputValue = inputValue.replaceAll("-", "");
        api_link += `&filter_type=${searchGubun}&filter_value=${newInputValue}`;
      } else {
        api_link += `&filter_type=${searchGubun}&filter_value=${inputValue}`;
      }
    }

    await API.get(api_link).then((res) => {
      if (res.data.status) {
        setCustomerCnt(res.data.cntAll);
        setPagination(res.data.pagination);
        setCustomerList(res.data.results);
      }
    });
  };

  // 휴대폰번호/고객명 검색
  const onSearch = () => {
    setSearchResults([]);
    setShowSearchList(false);
    setCurrentPage(1);
    getCustomerList();
  };

  // 고객 상세로 이동
  function onClickItem(idx) {
    navigate(`/shoppingmall/customer/${idx}`);
    let jsonString = JSON.stringify(customerPageState);
    sessionStorage.setItem("customerPageState", jsonString);
  }

  const movePage = (e) => {
    setCurrentPage(e);
  };

  const onExcelDown = async () => {
    // 데이터 유무
    if (customerList.length > 0) {
      // api 호출
      let api_link = `/shoppingmall/consumer-list?page=1&unit=99999999`;
      if (inputValue) {
        if (inputValue.includes("-")) {
          const newInputValue = inputValue.replaceAll("-", "");
          api_link += `&filter_type=${searchGubun}&filter_value=${newInputValue}`;
        } else {
          api_link += `&filter_type=${searchGubun}&filter_value=${inputValue}`;
        }
      }
      const res = await API.get(api_link);
      if (res.data.status && res.data.results.length > 0) {
        // 엑셀 포맷으로 변경
        var xlsxTemp = res.data.results.map(function (item) {
          return {
            고객번호: item.idx,
            고객명: item.name,
            휴대폰번호: item.phone,
            이메일: item.email,
            주소: item.address,
          };
        });
        // 다운로드 실행
        Xlsx_download(xlsxTemp, `고객리스트`);
      } else {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    } else {
      setTextMsg("다운받을 데이터가 없습니다.");
      setTextModal(true);
    }
  };

  useEffect(() => {
    getCustomerList();
  }, [currentPage, pageSize]);

  useEffect(() => {
    sessionStorage.removeItem("customerPageState");
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {isMobile && <Header title={"고객 리스트"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    고객 리스트 📋
                  </Title>
                )}
                <WhiteBox margin={"0 0"}>
                  <InputAndBtnBox>
                    <BasicSelect
                      width={"130px"}
                      data={SHOP_SEARCH_TYPE_LIST}
                      selectedValue={searchGubun}
                      setSelectedValue={setSearchGubun}
                      canSelectAll={false}
                    />
                    <CustomerSearchInput
                      showSearchList={showSearchList}
                      setShowSearchList={setShowSearchList}
                      searchGubun={searchGubun}
                      searchResults={searchResults}
                      inputValue={inputValue}
                      setInputValue={setInputValue}
                      onSearch={onSearch}
                    />
                    <Btn
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      width={"100%"}
                      widthMd={"unset"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      onClick={onSearch}
                      style={{ flexShrink: 0 }}
                    >
                      검 색
                    </Btn>
                  </InputAndBtnBox>
                  <FlexBox
                    justify={"space-between"}
                    gap={"10px"}
                    margin={"20px 0 10px"}
                    marginMd={"20px 0 30px"}
                    wrap={"wrap"}
                    wrapMd={"no-wrap"}
                  >
                    <FlexBox gap={"10px"} width={"100%"} widthMd={"auto"}>
                      <Text
                        width={"100%"}
                        widthMd={"unset"}
                        color={"#FF4A4A"}
                        size={"16px"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        style={{
                          flexShrink: 0,
                        }}
                      >
                        고객 수 : <span style={{ fontWeight: "700" }}>{AddComma(customerCnt ? customerCnt : 0)}명</span>
                      </Text>
                      {!isMobile && (
                        <BasicSelect
                          width={"130px"}
                          data={UNIT_10_TO_50_LIST}
                          selectedValue={pageSize}
                          setSelectedValue={setPageSize}
                          canSelectAll={false}
                          setCurrentPage={setCurrentPage}
                        />
                      )}
                    </FlexBox>
                    {!isMobile && (
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        border={"1px solid #666"}
                        color={"#666"}
                        hoverBackground={"#C7C7C7"}
                        hoverColor={"#fff"}
                        hoverBorder={"transparent"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        margin={"0 0 0 10px"}
                        onClick={onExcelDown}
                      >
                        엑셀 다운
                      </Btn>
                    )}
                  </FlexBox>
                  <TableWrap>
                    <table style={{ width: "100%" }}>
                      <colgroup>
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>고객번호</Th>
                          <Th>고객명</Th>
                          <Th>휴대폰 번호</Th>
                          <Th>이메일</Th>
                          <Th>주소</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {customerList?.length > 0 ? (
                          customerList?.map((item, key) => (
                            <tr key={key} style={{ cursor: "pointer" }} onClick={() => onClickItem(item.idx)}>
                              <Td>{item.idx}</Td>
                              <Td>{item.name ? item.name : "-"}</Td>
                              <Td>{item.phone ? hypen(item.phone) : "-"}</Td>
                              <Td>{item.email ? item.email : "-"}</Td>
                              <Td>{item.address ? item.address : "-"}</Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={5}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  {pagination?.total_page > 0 ? (
                    <Pagination pagination={pagination} movePage={movePage} currentPage={currentPage} />
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

export default ShopCustomer;

const InputAndBtnBox = styled.div`
  display: flex;
  gap: 10px;
  justify-content: end;

  @media all and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
  }
`;
