import styled from "styled-components";
import ico_search from "../images/ico_search.svg";
import ico_down from "../images/ico_select_down.svg";
import ico_up from "../images/ico_select_up.svg";
import ico_cal from "../images/ico_cal.svg";
import ico_dollar from "../images/ico_dollar.png";
import ico_arrow from "../images/ico_arr06_right.png";
import ico_pgprev from "../images/ico_page_prev.svg";
import ico_pgnext from "../images/ico_page_next.svg";

/* Containers */

export const HeaderContainer = styled.div`
  display: flex;
  background-color: #fafafb;
  min-height: ${(props) => props.viewportHeight || "unset"};
`;

export const ContentContainer = styled.div`
  width: calc(100% - 330px);

  // @media (min-width: 1024px) {
  //   width: calc(100% - 280px);
  // }

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  // padding-top: ${(props) => props.paddingTop || "77px"};
  padding-bottom: 70px;

  @media only screen and (max-width: 1024px) {
    padding-top: ${(props) => props.ptM || "80px"};
    padding-bottom: ${(props) => props.pbM || "60px"};
  }
`;

export const Container = styled.div`
  margin: 77px 52px;

  @media all and (max-width: 1024px) {
    margin: 0 18px;
  }
`;

export const Contents = styled.div`
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  background-color: ${(props) => props.background || ""};
  width: ${(props) => props.width || "100%"};
  border-radius: ${(props) => props.radius || "8px"};
  display: ${(props) => props.display || "block"};
  justify-content: ${(props) => props.justify ?? props.justify};
  align-items: ${(props) => props.align ?? props.align};

  @media all and (min-width: 1024px) {
    margin: ${(props) => props.marginMd || "0"};
    padding: ${(props) => props.paddingMd || "0"};
  }
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || "center"};
  align-items: ${(props) => props.align || "center"};
  flex-wrap: ${(props) => props.wrap || "nowrap"};
  gap: ${(props) => props.gap ?? props.gap};
  flex-direction: ${(props) => props.direction || "row"};
  flex: ${(props) => props.flex ?? props.flex};
  width: ${(props) => props.width ?? props.width};
  margin: ${(props) => props.margin ?? props.margin};
  padding: ${(props) => props.padding ?? props.padding};
  background-color: ${(props) => props.background ?? props.background};
  border-radius: ${(props) => props.radius ?? props.radius};
  border: ${(props) => props.border ?? props.border};

  @media only screen and (min-width: 1024px) {
    justify-content: ${(props) => props.justifyMd ?? props.justifyMd};
    align-items: ${(props) => props.alignMd ?? props.alignMd};
    flex-wrap: ${(props) => props.wrapMd ?? props.wrapMd};
    gap: ${(props) => props.gapMd ?? props.gapMd};
    flex-direction: ${(props) => props.directionMd ?? props.directionMd};
    flex: ${(props) => props.flexMd ?? props.flexMd};
    width: ${(props) => props.widthMd ?? props.widthMd};
    margin: ${(props) => props.marginMd ?? props.marginMd};
    padding: ${(props) => props.paddingMd ?? props.paddingMd};
  }
  @media (min-width: 992px) {
    justify-content: ${(props) => props.justifyLg ?? props.justifyLg};
    align-items: ${(props) => props.alignLg ?? props.alignLg};
    flex-wrap: ${(props) => props.wrapLg ?? props.wrapLg};
    gap: ${(props) => props.gapLg ?? props.gapLg};
    flex-direction: ${(props) => props.directionLg ?? props.directionLg};
    flex: ${(props) => props.flexLg ?? props.flexLg};
    width: ${(props) => props.widthLg ?? props.widthLg};
    margin: ${(props) => props.marginLg ?? props.marginLg};
    padding: ${(props) => props.paddingLg ?? props.paddingLg};
  }
  @media (min-width: 1201px) {
    justify-content: ${(props) => props.justifyXl ?? props.justifyXl};
    align-items: ${(props) => props.alignXl ?? props.alignXl};
    flex-wrap: ${(props) => props.wrapXl ?? props.wrapXl};
    gap: ${(props) => props.gapXl ?? props.gapXl};
    flex-direction: ${(props) => props.directionXl ?? props.directionXl};
    flex: ${(props) => props.flexXl ?? props.flexXl};
    width: ${(props) => props.widthXl ?? props.widthXl};
    margin: ${(props) => props.marginXl ?? props.marginXl};
    padding: ${(props) => props.paddingXl ?? props.paddingXl};
  }
`;

export const WhiteBox = styled.div`
  padding: ${(props) => props.padding || "16px 16px"};
  width: ${(props) => props.width || "100%"};
  flex-direction: ${(props) => props.direction || "column"};
  display: flex;
  margin: ${(props) => props.margin || "20px 0 "};
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  @media (min-width: 1024px) {
    padding: ${(props) => props.paddingMd || "28px 40px"};
    margin: ${(props) => props.marginMd || "30px 0"};
    flex-direction: ${(props) => props.directionMd || "column"};
    width: ${(props) => props.widthMd || "100%"};
  }
`;

export const FilterWrap = styled(FlexBox)`
  display: inline-flex;
  align-items: center;
  gap: 15px;
`;

export const LeftArea = styled.div`
  width: calc(100% - 38%);

  @media all and (max-width: 1201px) {
    width: 100%;
  }
  // @media all and (max-width: 991px) {
  //   width: 100%;
  // }
  // @media all and (max-width: 767px) {
  //   width: 100%;
  // }
`;

export const RightArea = styled.div`
  width: 35%;
  @media all and (max-width: 1201px) {
    width: 100%;
  }
  // @media all and (max-width: 991px) {
  //   width: 100%;
  // }
  // @media all and (max-width: 767px) {
  //   width: 100%;
  // }
`;

export const HalfDiv = styled.div`
  width: calc(50% - 13px);
  @media all and (max-width: 1060px) {
    width: 100%;
  }
`;

/* components */

export const Text = styled.p`
  font-family: ${(props) => props.family ?? props.family};
  font-size: ${(props) => props.size || "14px"};
  font-weight: ${(props) => props.weight || "400"};
  color: ${(props) => props.color || "#666666"};
  text-align: ${(props) => props.align || "center"};
  padding: ${(props) => props.padding || "0"};
  background-color: ${(props) => props.background || ""};
  margin: ${(props) => props.margin || "0"};
  border-radius: ${(props) => props.radius || "0"};
  width: ${(props) => props.width ?? props.width};
  opacity: ${(props) => props.opacity ?? "1"};
  line-height: ${(props) => props.lineHeight ?? "normal"};
  letter-spacing: ${(props) => props.letterSpacing ?? "normal"};

  &.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (min-width: 1024px) {
    font-size: ${(props) => props.sizeMd ?? props.sizeMd};
    font-weight: ${(props) => props.weightMd ?? props.weightMd};
    color: ${(props) => props.colorMd ?? props.colorMd};
    padding: ${(props) => props.paddingMd ?? props.paddingMd};
    text-align: ${(props) => props.alignMd ?? props.alignMd};
    margin: ${(props) => props.marginMd ?? props.marginMd};
    border-radius: ${(props) => props.radiusMd ?? props.radiusMd};
    width: ${(props) => props.widthMd ?? props.widthMd};
  }
  @media (min-width: 992px) {
    font-size: ${(props) => props.sizeLg ?? props.sizeLg};
    font-weight: ${(props) => props.weightLg ?? props.weightLg};
    color: ${(props) => props.colorLg ?? props.colorLg};
    padding: ${(props) => props.paddingLg ?? props.paddingLg};
    text-align: ${(props) => props.alignLg ?? props.alignLg};
    margin: ${(props) => props.marginLg ?? props.marginLg};
    border-radius: ${(props) => props.radiusLg ?? props.radiusLg};
  }
`;

export const Title = styled.h3`
  font-family: ${(props) => props.family ?? props.family};
  font-size: ${(props) => props.size || "14px"};
  font-weight: ${(props) => props.weight || "400"};
  color: ${(props) => props.color || "#6C6C6C"};
  padding: ${(props) => props.padding || "0"};

  @media (min-width: 1024px) {
    font-size: ${(props) => props.sizeMd ?? props.sizeMd};
    font-weight: ${(props) => props.weightMd ?? props.weightMd};
    color: ${(props) => props.colorMd ?? props.colorMd};
    padding: ${(props) => props.paddingMd ?? props.paddingMd};
  }
  @media (min-width: 992px) {
    font-size: ${(props) => props.sizeLg ?? props.sizeLg};
    font-weight: ${(props) => props.weightLg ?? props.weightLg};
    color: ${(props) => props.colorLg ?? props.colorLg};
    padding: ${(props) => props.paddingLg ?? props.paddingLg};
  }
`;

export const TextBar = styled(Text)`
  position: relative;
  padding-left: 10px;
  margin-top: 4px;
  word-break: keep-all;
  color: ${(props) => props.color || "#666"};
  font-weight: ${(props) => props.weight || "400"};
  line-height: ${(props) => props.lineHeight ?? "25px"};
  opacity: ${(props) => props.opacity ?? "unset"};

  &::before {
    content: "*";
    position: absolute;
    top: 0;
    left: 0;
  }

  @media all and (max-width: 1024px) {
    margin-top: 4px;
  }
`;

export const Btn = styled.button`
  font-family: ${(props) => props.family ?? props.family};
  font-size: ${(props) => props.size || "20px"};
  line-height: ${(props) => props.lineHeight || "22px"};
  font-weight: ${(props) => props.weight || "400"};
  color: ${(props) => props.color || "#FF4A4A"};
  text-align: ${(props) => props.align || "center"};
  padding: ${(props) => props.padding || "12px 30px"};
  border: ${(props) => props.border ?? "1px solid #FF4A4A"};
  border-radius: ${(props) => props.radius || "6px"};
  background: ${(props) => props.background || "transparent"};
  margin: ${(props) => props.margin || "0"};
  width: ${(props) => props.width ?? props.width};
  line-height: ${(props) => props.lineHeight ?? "normal"};

  /* Hover styles */
  &:hover {
    background-color: ${(props) =>
      props.hoverBackground || props.background || "#FF4A4A"}; /* Change the background color to #FF4A4A on hover */
    cursor: pointer;
    color: ${(props) => props.hoverColor || props.color || "#ffffff"};
    font-weight: ${(props) => props.hoverWeight || props.weight || "700"};
    border: ${(props) => props.hoverBorder ?? props.border};
  }

  @media (min-width: 1024px) {
    font-size: ${(props) => props.sizeMd ?? props.sizeMd};
    font-weight: ${(props) => props.weightMd ?? props.weightMd};
    color: ${(props) => props.colorMd ?? props.colorMd};
    text-align: ${(props) => props.alignMd ?? props.alignMd};
    padding: ${(props) => props.paddingMd ?? props.paddingMd};
    border: ${(props) => props.borderMd ?? props.borderMd};
    border-radius: ${(props) => props.radiusMd ?? props.radiusMd};
    margin: ${(props) => props.marginMd ?? props.marginMd};
    width: ${(props) => props.widthMd ?? props.widthMd};
  }
  @media (min-width: 992px) {
    font-size: ${(props) => props.sizeLg ?? props.sizeLg};
    font-weight: ${(props) => props.weightLg ?? props.weightLg};
    color: ${(props) => props.colorLg ?? props.colorLg};
    text-align: ${(props) => props.alignLg ?? props.alignLg};
    padding: ${(props) => props.paddingLg ?? props.paddingLg};
    margin: ${(props) => props.marginLg ?? props.marginLg};
    border: ${(props) => props.borderLg ?? props.borderLg};
    border-radius: ${(props) => props.radiusLg ?? props.radiusLg};
    width: ${(props) => props.widthLg ?? props.widthLg};
  }
`;

export const Select = styled.select`
  display: flex;
  width: 160px;
  height: 40px;
  justify-content: center;
  align-items: flex-start;
  gap: 62px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  cursor: pointer;
  background: #fff url(${(props) => (props.show ? ico_up : ico_down)}) no-repeat right 13px center;
  font-size: ${(props) => props.size || "16px"};
  font-weight: ${(props) => props.weight || "400"};
  color: ${(props) => props.color || "#666666"};
  padding: ${(props) => props.padding || "10px 9px 10px 10px"};
  border: ${(props) => props.border || "1px solid #F2F2F2"};
  border-radius: ${(props) => props.radius || "6px"};
  text-align: ${(props) => props.align || "left"};
  &:focus {
    border-color: #ff4a4a; /* Replace with your desired border color */
    outline: none; /* Remove the default focus outline, if needed */
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    color: #6c6c6c;
    background-color: #eaeaea;
  }
  &::-ms-expand {
    display: none;
  }

  @media all and (min-width: 767px) {
    height: 40px;
    font-size: ${(props) => props.sizeMd || "14px"};
    line-height: ${(props) => props.sizeMd || "14px"};
    padding: ${(props) => props.paddingMd || "10px 9px 10px 10px"};
  }
`;

export const DateInputBox = styled.div`
  position: relative;
  /* max-width: 100px; */
  height: 36px;
  /* padding-bottom: 30px; */
  padding-bottom: 36px;
  line-height: 1;
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 12px;
    display: block;
    width: 12px;
    height: 12px;
    z-index: 2;
    background: url(${ico_cal}) no-repeat center center / contain;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);

    @media (max-width: 300px) {
      left: 7px;
    }
  }

  input {
    width: 130px;
    height: 36px;
    /* padding: 0 9px 0 22px; */
    border: 1px solid #c7c7c7;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    color: #ff4a4a;
    text-align: center;
    @media all and (max-width: 1024px) {
      width: 100%;
    }
    @media (max-width: 300px) {
      font-size: 12px;
    }
  }

  /* Hover styles */
  input:hover {
    border: 1px solid #ff4a4a;
  }
  input:focus {
    border: 1px solid #ff4a4a;
  }
`;

/* Table */

export const TableWrap = styled.div`
  overflow-y: auto;
  table {
    min-width: 1024px;
  }
  @media all and (max-width: 1024px) {
    table {
      min-width: 450px;
    }
  }
`;
export const Th = styled.th`
  padding: ${(props) => props.padding || "14px"};
  background-color: ${(props) => props.bgColor || ""};
  color: ${(props) => props.color || "#1E1E1E"};
  font-size: ${(props) => props.size || "16px"};
  font-weight: ${(props) => props.weight || "700"};
  margin: ${(props) => props.margin || "0"};
  text-align: ${(props) => props.align || "center"};
  border-bottom: 1px solid ${(props) => props.borderBottom || "#666666"};

  @media all and (max-width: 1024px) {
    font-size: ${(props) => props.sizeMd || "14px"};
  }
`;

export const Td = styled.td`
  padding: ${(props) => props.padding || "14px"};
  background-color: ${(props) => props.bgColor || ""};
  color: ${(props) => props.color || "#666666"};
  font-size: ${(props) => props.size || "16px"};
  font-weight: ${(props) => props.weight || "400"};
  margin: ${(props) => props.margin || "0"};
  text-align: ${(props) => props.align || "center"};
  line-height: ${(props) => props.lineHeight || "22px"};
  border-bottom: ${(props) => props.borderBottom || "1px solid #F2F2F2"};

  @media all and (max-width: 1024px) {
    font-size: ${(props) => props.sizeMd || "12px"};
  }
`;

export const CheckIcon = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1px solid #c7c7c7;
  background-color: #fff;

  svg {
    fill: none;
    stroke: white;
    stroke-width: 3px;
    border-radius: 50%;
  }
`;

export const DisableCheckIcon = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1px solid #c7c7c7;
  // background-color: rgba(255, 116, 64, 0.1);

  svg {
    fill: none;
    stroke: transparent;
    stroke-width: 3px;
    border-radius: 50%;
  }
`;

export const CheckLabel = styled.label`
  width: 18px;
  display: flex;
  gap: ${(props) => props.gap || "12px"};
  align-items: center;
  cursor: pointer;

  input {
    position: absolute;
    display: none;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    opacity: 0;
    visibility: hidden;

    &:checked + ${CheckIcon} {
      border-color: #2dd057;
      background-color: #2dd057;
    }
  }

  @media all and (max-width: 1024px) {
    gap: 4px;
  }
`;

export const SearchInput = styled.input`
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  cursor: pointer;
  position: relative;
  border-radius: ${(props) => props.radius || "0"};
  height: ${(props) => props.height || "40px"};
  width: ${(props) => props.width || "262px"};
  font-size: ${(props) => props.size || "14px"};
  font-weight: ${(props) => props.weight || "400"};
  color: ${(props) => props.color || "#666666"};
  line-height: ${(props) => props.lineHeight ?? "normal"};
  letter-spacing: ${(props) => props.letterSpacing ?? "normal"};
  padding: ${(props) => (props.value ? "10px 10px" : "10px 10px 10px 35px")};
  border-bottom: ${(props) => props.borderBottom || ""};
  border: ${(props) => props.border || ""};
  box-shadow: ${(props) => props.boxShadow || ""};
  text-align: ${(props) => props.align || "left"};
  background-color: ${(props) => (props.bgColor ? (props.value ? props.bgColor : "#fff") : "transparent")};
  background-repeat: no-repeat;
  background-position: left 10px center;
  background-image: ${(props) => (props.value ? "none" : `url(${ico_search})`)};
  &::placeholder {
    color: #666666;
    position: relative;
    z-index: 1;
  }
  &::-ms-expand {
    display: none;
  }
  &:disabled {
    color: #6c6c6c;
    background-color: #eaeaea;
  }
  &:focus {
    border-bottom-color: ${(props) => props.borderBottomColor || ""}; //여기 #ff4a4a
    transition: ${(props) => props.transition || ""}; //여기 border-color 0.3s ease-in-out
  }

  @media all and (max-width: 1024px) {
    flex: ${(props) => props.flex || "unset"};
    height: 40px;
    width: ${(props) => props.width || "100%"};
  }
`;

/* Select */
export const SelectContainer = styled.div`
  position: relative;
  flex: ${(props) => props.flex ?? ""};
  width: ${(props) => props.width ?? "unset"};
  @media all and (max-width: 1024px) {
    flex: unset;
    width: ${(props) => props.widthMd ?? "100%"};
  }
`;

export const SelectBtn = styled.button`
  position: relative;
  width: ${(props) => props.width ?? "160px"};
  height: ${(props) => props.height ?? "40px"};
  font-size: ${(props) => props.size ?? "14px"};
  padding: ${(props) => props.padding ?? "10px 10px"};
  border: 1px solid ${(props) => (props.show ? props.borderColor ?? "#ff4a4a" : "#f2f2f2")};
  background: ${(props) => (props.disabled ? props.disabled ?? "#ff4a4a" : "#fff")}
    url(${(props) => (props.show ? ico_up : ico_down)}) no-repeat right 13px center;
  text-align: left;
  line-height: normal;
  letter-spacing: -0.32px;
  border-radius: 6px;
  box-shadow: ${(props) => (props.show ? "#0px 0px 4px 0px rgba(0, 0, 0, 0.10)" : "")};
  color: ${(props) => props.color || "#666666"};
  font-weight: ${(props) => (props.show ? "500" : "400")};

  @media all and (max-width: 1024px) {
    width: ${(props) => props.widthMd ?? "100%"};
    padding: ${(props) => props.paddingMd ?? "8px 8px;"};
  }
`;

export const SelectWrap = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  width: ${(props) => props.width ?? "160px"};
  max-height: 300px;
  overflow-y: auto;
  border-radius: 6px;
  background-color: #fff;
  z-index: 99;
  box-shadow: 0px 0px 10px 0px rgba(30, 30, 30, 0.1);
  @media all and (max-width: 1024px) {
    width: ${(props) => props.widthMd ?? "100%"};
    // height: 36px;
  }
`;

export const SearchList = styled.ul`
  overflow-y: auto;

  li {
    button {
      display: block;
      width: 100%;
      padding: 10px 10px;
      font-size: 14px;
      // font-weight: 500;
      text-align: left;
      line-height: normal;
      letter-spacing: -0.32px;
      &:hover {
        background-color: #ff7440;
      }
    }

    span {
      display: block;
      width: 100%;
      padding: 10px 10px;
      font-size: 14px;
      text-align: left;
      color: #6c6c6c;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.32px;
      &:hover {
        font-weight: 500;
        background-color: rgba(255, 116, 64, 0.1);
      }
    }
  }

  @media all and (max-width: 1024px) {
    max-height: 240px;

    li button {
      font-size: 14px;
      padding: 9px 10px;
    }
  }
`;
export const TextInput = styled.input`
  height: 40px;
  outline: none;
  margin: ${(props) => props.margin || "0"};
  width: ${(props) => props.width || "100%"};
  font-size: ${(props) => props.size || "14px"};
  line-height: ${(props) => props.lineHeight || "20px"};
  font-weight: ${(props) => props.weight || "400"};
  color: ${(props) => props.color || "#666666"};
  padding: ${(props) => props.padding || "10px 10px 10px 15px;"};
  border: ${(props) => props.border || "1px solid #F2F2F2"};
  border-radius: ${(props) => props.radius || "6px"};
  text-align: ${(props) => props.align || "left"};
  background: ${(props) => props.background || "#ffffff"};

  &::placeholder {
    color: #c7c7c7;
  }

  &:disabled {
    color: #6c6c6c;
    background-color: #eaeaea;
  }

  &:focus {
    border: ${(props) => props.focusBorder || "1px solid #666666"};
    color: ${(props) => props.color || "#666666"};
  }

  @media all and (max-width: 1024px) {
    height: 40px;
  }
`;

export const TextInputArea = styled.textarea`
  min-height: ${(props) => props.minHeight || "138px"};
  outline: none;
  width: ${(props) => props.width || "100%"};
  font-size: ${(props) => props.size || "14px"};
  line-height: ${(props) => props.lineHeight || "20px"};
  font-weight: ${(props) => props.weight || "400"};
  color: ${(props) => props.color || "#666666"};
  padding: ${(props) => props.padding || "12px;"};
  border: ${(props) => props.border || "1px solid #F2F2F2"};
  border-radius: ${(props) => props.radius || "6px"};
  text-align: ${(props) => props.align || "left"};
  background: ${(props) => props.background || "#ffffff"};
  resize: none;

  &::placeholder {
    color: #c7c7c7;
  }

  &:disabled {
    color: #6c6c6c;
    background-color: #eaeaea;
  }

  &:focus {
    border: ${(props) => props.border || "1px solid #666666"};
    color: ${(props) => props.color || "#666666"};
  }

  @media all and (max-width: 1024px) {
    min-height: ${(props) => props.minHeightMd || "100px"};
  }
`;

export const ToggleLabel = styled.label`
  cursor: pointer;

  & * {
    transition: all 0.3s ease-in;
  }

  .bg {
    position: relative;
    display: block;
    width: 32px;
    height: 18px;
    border-radius: 18px;
    background-color: #fff;
    border: 1px solid #c7c7c7;

    .circle {
      position: absolute;
      top: 1px;
      left: 3px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #c7c7c7;
      box-shadow: 1px 0 2px rgba(0, 0, 0, 0.1);
    }
  }
  input {
    position: absolute;
    display: none;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    opacity: 0;
    visibility: hidden;

    &:checked + .bg {
      border: 1px solid #2dd057;
      // background-color: #dd1212;
      .circle {
        left: calc(100% - 16px);
        background-color: #2dd057;
        box-shadow: -1px 0 2px rgba(0, 0, 0, 0.1);
      }
    }
  }
`;

export const RadioIcon = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 1px solid #fff;
  background-color: #fff;

  svg {
    fill: none;
    stroke-width: 1px;
    border-radius: 50%;
    circle {
      &:first-child {
        stroke: #c7c7c7;
      }
      &:last-child {
        fill: none;
      }
    }
  }
`;

export const RadioLabel = styled.label`
  display: flex;
  cursor: pointer;
  gap: ${(props) => props.gap || "5px"};
  justify-content: ${(props) => props.justify || "flex-start"};
  align-items: ${(props) => props.align || "flex-start"};
  color: ${(props) => props.color || "#666666"};
  font-size: ${(props) => props.size || "14px"};
  line-height: ${(props) => props.lineHeight || "18px"};

  input {
    position: absolute;
    display: none;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    opacity: 0;
    visibility: hidden;

    &:checked + ${RadioIcon} {
      circle {
        &:first-child {
          stroke: #2dd057;
        }
        &:last-child {
          fill: #2dd057;
        }

        gap: 4px;
      }
    }
  }
`;

export const FileContainer = styled.div`
  padding: 30px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;

  @media all and (max-width: 1024px) {
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
  }
`;

export const UploadFileBtn = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 240px;
  width: 100%;
  min-height: 240px;
  border-radius: 10px;
  border: 2px dashed #f2f2f2;
  background-color: #fff;
  cursor: pointer;
  font-size: 14px;
  color: #c7c7c7;
  flex: 1 0 auto;

  input {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    // padding-left: 250px;
    opacity: 0;
    cursor: pointer !important;
  }

  span {
    color: #666;
    font-weight: 500;
    text-decoration-line: underline;
    text-underline-offset: 3px;
  }

  @media all and (max-width: 1024px) {
    min-height: 110px;
    max-width: 100%;
    input[type="file"] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      cursor: pointer !important;
    }
  }
  // height: 110px;

  input {
    opacity: 0;
  }
`;

export const FileImg = styled.div`
  position: relative;
  max-width: 240px;
  width: 100%;
  border-radius: 10px;
  border: ${(props) => props.border || "1px solid #c7c7c7"};

  div {
    position: relative;
    height: 240px;
    overflow: hidden;
    @media all and (max-width: 1024px) {
      max-height: 120px;
    }
    img {
      border-radius: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      height: 100%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
    }
  }
  @media all and (max-width: 1024px) {
    max-width: 120px;
  }
`;

export const FileXBtn = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  z-index: 1;
`;

export const SearchResultItem = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  padding: ${(props) => props.padding || "40px"};
  cursor: ${(props) => props.cursor || "default"};

  div:first-of-type {
    align-self: center;

    p {
      text-align: left;
      font-size: 18px;
      color: #1e1e1e;
      opacity: 0.7;
    }
    p + p {
      margin-top: 10px;
    }

    p:first-of-type {
      color: #ff4a4a;
      font-size: 26px;
      font-weight: 700;

      opacity: 1;
      span {
        font-size: 20px;
        font-weight: 400;
      }
    }
  }
  div:last-of-type {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    background: ${(props) =>
        props.cursor === "pointer" ? `url(${ico_arrow}) center/18px` : `url(${ico_dollar}) center/12px`}
      no-repeat rgba(255, 116, 64, 0.1);
  }
`;

/* pager */
export const PagerWrap = styled.div`
  font-family: "Montserrat";
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.margin ?? "50px 0"};

  a {
    display: flex;
    padding: 8px 10px;
    font-size: 14px;
    line-height: 20px;
    color: #666;
    @media all and (max-width: 1024px) {
      padding: 8px 6px;
    }
    &.active {
      font-weight: 700;
      color: #1e1e1e;
    }

    &.page_prev {
      // padding: 0;
      i {
        display: block;
        width: 10px;
        height: 10px;
        background: url(${ico_pgprev}) no-repeat center center / contain;
      }
    }
    &.page_next {
      padding: 0;
      i {
        display: block;
        width: 10px;
        height: 10px;
        background: url(${ico_pgnext}) no-repeat center center / contain;
      }
    }
  }

  @media all and (max-width: 1024px) {
    margin: ${(props) => props.marginMd ?? "20px 26px"};
  }
`;

export const Line = styled.div`
  border-bottom: 1px solid #c7c7c7;
  width: 100%;
`;

/* Modal */
export const ModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

export const ModalConts = styled.div`
  background-color: #fff;
  padding: 44px 30px 26px;
  border-radius: 8px;
  position: relative;

  @media all and (max-width: 1024px) {
    padding: 40px 20px 30px;
  }
`;

export const CancelBtn = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;

  @media all and (max-width: 1024px) {
    top: 12px;
    right: 12px;
  }
`;

export const SquareImg = styled.div`
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background: #fff;

  div {
    position: relative;
    padding-bottom: 100%;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      display: block;
    }

    ${(props) =>
      props.stopSale &&
      `
    &::before {
      content: "";
      position: absolute;
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      background: linear-gradient(0deg, rgba(30, 30, 30, 0.6) 0%, rgba(30, 30, 30, 0.6) 100%), #1e1e1e;
      z-index: 10;
      border-radius: 10px;
      opacity: 0.6;
      pointer-events: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &::after {
      content: "${props.status === "missing" ? "입고지연" : "단종"}";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 14px;
      z-index: 11;
      background: #1e1e1e;
      padding: 6px 12px;
      font-weight: 700;

      @media (max-width: 1024px) {
        font-size: 8px;
        padding: 5px;
      }
    }
  `}
  }
`;

export const MinusBtn = styled(Btn)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #ef6f6f;

  &:disabled {
    background-color: #fcb1b1;
  }
  i {
    position: relative;
    display: block;
    width: 10px;
    height: 10px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background-color: #fff;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
    }
  }
`;

export const NumberInput = styled.input`
  width: 60px;
  border: none;
  outline: none;
  font-size: 12px;
  color: #2d2d2d;
  font-weight: 500;
  text-align: center;

  &:disabled {
    color: #c0c0c0;
  }
`;

export const PlusBtn = styled(MinusBtn)`
  i {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 1px;
      height: 100%;
      background-color: #fff;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
    }
  }
`;

export const OrderAddrBox = styled(FlexBox)`
  p {
    width: 135px;
  }
  p:nth-of-type(2) {
    width: calc(100% - 135px);
  }
  & > div {
    width: calc(100% - 135px);
    max-width: 525px;
  }

  @media all and (max-width: 1024px) {
    p {
      width: 90px;
    }
    p:nth-of-type(2) {
      width: calc(100% - 90px);
    }
    & > div {
      width: calc(100% - 90px);
      max-width: 525px;

      & > div:first-of-type input {
        max-width: calc(100% - 74px);
      }
    }
  }
`;
export const DisabledDiv = styled.div`
  background-color: #c7c7c7;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  border-radius: 6px;
  opacity: 0.4;
`;

export const TextArea = styled.textarea`
  resize: none;
  outline: none;
  font-size: ${(props) => props.size || "14px"};
  font-weight: ${(props) => props.weight || "400"};
  color: ${(props) => props.color || "#6c6c6c"};
  padding: ${(props) => props.padding || "9px 12px"};
  border: ${(props) => props.border || "1px solid #c0c0c0"};
  border-radius: ${(props) => props.radius || "6px"};
  text-align: ${(props) => props.align || "left"};
  height: ${(props) => props.height || "auto"};

  &::placeholder {
    color: #c0c0c0;
  }
  &:disabled {
    color: #6c6c6c;
    background-color: #eaeaea;
  }
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

/* 반품 정보 테이블 */
export const ReturnListMWrap = styled.div`
  margin: 30px 0 0;
  table {
    width: 100%;
  }
  @media all and (max-width: 991px) {
    margin: 20px 0 0;
    table {
      min-width: 100%;
    }
  }
`;

export const Tdm = styled(Td)`
  @media all and (max-width: 991px) {
    border: 1px solid #eaeaea;
    border-radius: 8px;
    background-color: #fff;
    margin: 10px 0;
  }
`;

export const ShopImg = styled.div`
  width: 48px;
  border: 1px solid #eaeaea;
`;

export const ShopText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media all and (max-width: 991px) {
    gap: 0;
  }
`;

export const TakeBackText = styled(Text)`
  color: #6c6c6c;
  font-size: 14px;
  background: rgba(255, 116, 64, 0.1);
  border-radius: 6px;
  padding: 10px 0;
  width: 100%;

  span {
    color: #2d2d2d;
    font-weight: 500;

    &.primary {
      color: #ff4a4a;
    }
  }

  @media (min-width: 1024px) {
    width: unset;
  }

  @media (min-width: 992px) {
    padding: 10px 10px;
  }
`;
