import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import Header from "../../Components/Header/HeaderVer2";
import {
  Btn,
  TableWrap,
  Td,
  Th,
  ContentContainer,
  Container,
  Wrapper,
  CheckLabel,
  CheckIcon,
  PagerWrap,
  FlexBox,
  WhiteBox,
  Text,
  DateInputBox,
  Title,
} from "../../Assets/css/commonVer2";
import { dateToString, AddComma, str, Xlsx_download } from "../../Utiles/Custom";
import { DEPOSIT_NOTE_LIST, UNIT_10_TO_30_LIST, DEPOSIT_SEARCH_STATUS_LIST } from "../../Utiles/ListData";
import DatePicker from "react-datepicker";
import API from "../../Utiles/API";
import Paging from "../../Components/Paging/Paging";
import Authority from "../../Components/Modal/Authority";
import FranSelect from "../../Components/Select/FranSelect";
import BasicSelect from "../../Components/Select/BasicSelect";
import HandleBack from "../../Components/HandleBack";
import InfoModal from "../../Components/Modal/InfoModal";
import DepositModal from "../../Components/Modal/DepositModal";

function FranchiseeDeposit() {
  const { handlePopstate } = HandleBack();
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const date = new Date();
  const year = date.getFullYear(); // 년
  const month = date.getMonth(); // 월
  const day = date.getDate(); // 일
  const [startDate, setStartDate] = useState(new Date(year, month, day - 7));
  const [endDate, setEndDate] = useState(new Date());
  const [modal, setModal] = useState(false);
  const [dateType, setDateType] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [data, setData] = useState([]);
  const [deposit, setDeposit] = useState();
  const [statusValue, setStatusValue] = useState("");
  const [noteValue, setNoteValue] = useState();
  const [franchise, setFranchise] = useState();
  const [content, setContent] = useState("");
  const [name, setName] = useState("");
  const [money, setMoney] = useState("");
  const [saveModal, setSaveModal] = useState(false);
  const [modalFranText, setModalFranText] = useState("가맹점 전체");
  const [modalFranShow, setModalFranShow] = useState(false);

  const [franText, setFranText] = useState("가맹점 전체");
  const [franIdx, setFranIdx] = useState("");
  const [franShow, setFranShow] = useState(false);

  const [textModal, setTextModal] = useState(false); // 모달 창 표시 여부 상태
  const [textMsg, setTextMsg] = useState("");

  const onStatusChange = (e) => {
    if (e.target.id === "radio0") {
      if (statusValue === "") {
        setStatusValue("1");
      } else if (statusValue === "1") {
        setStatusValue("");
      } else if (statusValue === "0") {
        setStatusValue("1");
      }
    } else if (e.target.id === "radio1") {
      if (statusValue === "") {
        setStatusValue("0");
      } else if (statusValue === "0") {
        setStatusValue("");
      } else if (statusValue === "1") {
        setStatusValue("0");
      }
    }
  };

  const onSearch = async () => {
    let api_link = `/admin/deposit?curPage=${page}&pageSize=${pageSize}`;
    if (franIdx) api_link += `&filterVal1=${franIdx}`;
    if (noteValue) api_link += `&filterVal2=${noteValue}`;
    if (statusValue) api_link += `&filterVal3=${statusValue}`;
    if (dateType) {
      api_link += `&dateType=${dateType}`;
      if (startDate) {
        api_link += `&filterDatemin=${dateToString(startDate)}`;
      }
      if (endDate) {
        api_link += `&filterDatemax=${dateToString(endDate)}`;
      }
    }

    try {
      const res = await API.get(api_link);
      if (res.data.status) {
        setDeposit(res.data.total);
        setData(res.data.result);
        setTotal(res.data.cntAll);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const putCartClick = () => {
    if (franchise === undefined || franchise === "") {
      return false;
    } else if (content === undefined || content === "") {
      return false;
    } else if (name === undefined || name === "") {
      return false;
    } else if (money === undefined || money === "") {
      return false;
    } else {
      setSaveModal(true);
    }
  };

  const onSubmit = () => {
    API.post(`/admin/deposit`, {
      franchise_id: parseInt(franchise),
      content: content,
      account_holder: name,
      amount: parseInt(money.replaceAll(",", "")),
    })
      .then(function (res) {
        setSaveModal(false);
        setModal(false);
        onSearch();
        setMoney("");
        setFranchise("");
        setModalFranText("가맹점 전체");
        if (res.data.status) {
          setTextMsg("등록이 완료되었습니다.");
          setTextModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(function (error) {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const onExcelDown = async () => {
    // 예치금 데이터 유무
    if (data.length > 0) {
      // api 호출
      let api_link = `/admin/deposit?curPage=1&pageSize=99999999`;
      if (franIdx) api_link += `&filterVal1=${franIdx}`;
      if (noteValue) api_link += `&filterVal2=${noteValue}`;
      if (statusValue) api_link += `&filterVal3=${statusValue}`;
      if (dateType !== "") {
        api_link += `&dateType=${dateType}`;
        if (startDate)
          dateType === 2
            ? (api_link += `&filterDatemin=${month(dateToString(startDate))}`)
            : (api_link += `&filterDatemin=${dateToString(startDate)}`);
        if (endDate) {
          if (dateType === 0) {
            api_link += `&filterDatemax=${dateToString(startDate)}`;
          } else if (dateType === 1) {
            api_link += `&filterDatemax=${dateToString(endDate)}`;
          } else if (dateType === 2) {
            api_link += `&filterDatemax=${month(dateToString(startDate))}`;
          }
        }
      }

      const res = await API.get(api_link);
      if (res.data.status && res.data.result.length > 0) {
        // 엑셀 포맷으로 변경
        var xlsxTemp = res.data.result.map(function (item) {
          const note = DEPOSIT_NOTE_LIST.find((i) => i.value === item.note)?.text || "-";
          return {
            상태: item.status === 0 ? "입금" : item.status !== 0 ? "출금" : "",
            가맹점: item?.franchise.franchise_name,
            내용: item.content,
            금액: item.amount,
            등록일: item.updatedAt,
            비고: note,
          };
        });
        // 다운로드 실행
        Xlsx_download(xlsxTemp, `예치금 리스트_${dateToString(startDate)}~${dateToString(endDate)}`);
      } else {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    } else {
      setTextMsg("다운받을 데이터가 없습니다.");
      setTextModal(true);
    }
  };

  useEffect(() => {
    onSearch();
  }, [page, pageSize]);

  useEffect(() => {
    setTotalPage(Math.ceil(total / pageSize));
  }, [total, pageSize]);

  useEffect(() => {
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {isMobile && <Header title={"예치금 관리"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    예치금 관리 📋
                  </Title>
                )}

                <WhiteBox margin={"0 0"}>
                  <FlexBox wrap={"wrap"} justify={"end"} gap={"10px"}>
                    <FlexBox gap={"10px"} flexMd={"unset"} flex={"1"}>
                      <DateInputBox style={{ width: "100%" }}>
                        <DatePicker
                          dateFormatCalendar="yyyy년 MM월"
                          dateFormat="yyyy-MM-dd"
                          selected={startDate}
                          maxDate={endDate}
                          onChange={(date) => setStartDate(date)}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </DateInputBox>
                      <DateInputBox style={{ width: "100%" }}>
                        <DatePicker
                          dateFormatCalendar="yyyy년 MM월"
                          dateFormat="yyyy-MM-dd"
                          selected={endDate}
                          minDate={startDate}
                          onChange={(date) => setEndDate(date)}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </DateInputBox>
                    </FlexBox>

                    <FlexBox gap={"10px"} flexMd={"unset"} flex={"1 0 auto"} width={"100%"} widthMd={"unset"}>
                      <FranSelect
                        widthMd={"100%"}
                        gubun={"fran"}
                        selectBtnText={franText}
                        setTextChange={setFranText}
                        placeholder={"가맹점을"}
                        show={franShow}
                        setFranShow={setFranShow}
                        setIdxChange={setFranIdx}
                      />
                      <BasicSelect
                        width={"140px"}
                        data={DEPOSIT_NOTE_LIST}
                        selectedValue={noteValue}
                        setSelectedValue={setNoteValue}
                        placeholder={"비고전체"}
                        canSelectAll={true}
                      />
                    </FlexBox>
                    <FlexBox gap={"10px"} wrapMd={"unset"} wrap={"wrap"} flexMd={"unset"} flex={"1 0 auto"}>
                      {!isMobile && (
                        <FlexBox gap={"10px"}>
                          {/* <CheckLabel htmlFor="radio1" gap={"5px"}>
                            <input
                              type="radio"
                              name="radio1"
                              id="radio1"
                              value=""
                              checked={statusValue === ""}
                              onClick={onStatusChange}
                            />
                            <CheckIcon>
                              <svg viewBox="0 0 24 24">
                                <polyline points="19 7 10 17 5 12" />
                              </svg>
                            </CheckIcon>
                          </CheckLabel>
                          <Text size={"14px"}>전체</Text> */}
                          <CheckLabel htmlFor="radio0" gap={"5px"}>
                            <input
                              type="radio"
                              name="radio0"
                              id="radio0"
                              value="0"
                              checked={statusValue === "0" || statusValue === ""}
                              onClick={onStatusChange}
                            />
                            <CheckIcon>
                              <svg viewBox="0 0 24 24">
                                <polyline points="19 7 10 17 5 12" />
                              </svg>
                            </CheckIcon>
                          </CheckLabel>
                          <Text size={"14px"}>입금</Text>
                          <CheckLabel htmlFor="radio1" gap={"5px"}>
                            <input
                              type="radio"
                              name="radio1"
                              id="radio1"
                              value="1"
                              checked={statusValue === "1" || statusValue === ""}
                              onClick={onStatusChange}
                            />
                            <CheckIcon>
                              <svg viewBox="0 0 24 24">
                                <polyline points="19 7 10 17 5 12" />
                              </svg>
                            </CheckIcon>
                          </CheckLabel>
                          <Text size={"14px"}>출금</Text>
                        </FlexBox>
                      )}
                      {isMobile && (
                        <BasicSelect
                          data={DEPOSIT_SEARCH_STATUS_LIST}
                          selectedValue={statusValue}
                          setSelectedValue={setStatusValue}
                          canSelectAll={true}
                          placeholder={"전체"}
                        />
                      )}
                      <Btn
                        color={"#fff"}
                        background={"#FF4A4A"}
                        size={"14px"}
                        weight={"700"}
                        width={"100%"}
                        widthMd={"unset"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        onClick={() => {
                          setPage(1);
                          onSearch();
                        }}
                        style={{ flexShrink: 0 }}
                      >
                        검 색
                      </Btn>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox
                    justify={"space-between"}
                    margin={"10px 0"}
                    marginMd={"20px 0 30px"}
                    gap={"10px"}
                    wrap={"wrap"}
                    directionMd={"row"}
                    direction={"column"}
                  >
                    <FlexBox justify={"starts"} wrap={"wrap"} gap={"10px"} width={"100%"} widthMd={"unset"}>
                      {!isMobile && (
                        <BasicSelect
                          width={"130px"}
                          data={UNIT_10_TO_30_LIST}
                          selectedValue={pageSize}
                          setSelectedValue={setPageSize}
                          canSelectAll={false}
                          setCurrentPage={setPage}
                        />
                      )}
                      <Text
                        width={"100%"}
                        widthMd={"unset"}
                        color={"#FF4A4A"}
                        size={"16px"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        style={{
                          flexShrink: 0,
                        }}
                      >
                        기간 내 예치금 : <span style={{ fontWeight: "700" }}>{AddComma(deposit ? deposit : "")}원</span>
                      </Text>
                    </FlexBox>
                    <FlexBox justify={"end"} style={{ marginLeft: "auto" }}>
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        onClick={(props) => setModal(true)}
                      >
                        예치금 등록
                      </Btn>
                      {!isMobile && (
                        <Btn
                          size={"14px"}
                          padding={"8px 18px"}
                          border={"1px solid #666"}
                          color={"#666"}
                          hoverBackground={"#C7C7C7"}
                          hoverColor={"#fff"}
                          hoverBorder={"transparent"}
                          lineHeight={"22px"}
                          hoverWeight={"700"}
                          margin={"0 0 0 10px"}
                          onClick={onExcelDown}
                        >
                          엑셀 다운
                        </Btn>
                      )}
                    </FlexBox>
                  </FlexBox>
                  <TableWrap>
                    <table style={{ width: "100%", minWidth: "1024px" }}>
                      <colgroup>
                        <col style={{ width: "6%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "10%" }} />
                      </colgroup>
                      <thead>
                        <tr style={{ borderRight: "1px solid #c0c0c0" }}>
                          <Th>No</Th>
                          <Th>상태</Th>
                          <Th>가맹점</Th>
                          <Th>내 용</Th>
                          <Th>금 액</Th>
                          <Th>등록일</Th>
                          <Th>비고</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 ? (
                          data.map((list, key) => (
                            <tr key={key}>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{(page - 1) * pageSize + key + 1}</Td>
                              <Td
                                color={list && list.status !== 0 ? "#F63958" : "#2C70D5"}
                                style={{ borderBottom: "1px solid #F2F2F2" }}
                              >
                                {list && list.status !== 0 ? "출금" : "입금"}
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {list ? list.franchise.franchise_name : ""}
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {list?.content ? list.content : "-"}
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {list ? AddComma(list.amount) : 0}원
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{list ? str(list.createdAt) : ""}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {list.note
                                  ? DEPOSIT_NOTE_LIST.find((item) => item.value === list.note)?.text || "-"
                                  : "-"}
                              </Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={7}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  {data?.length > 0 ? (
                    <PagerWrap>
                      <Paging totalPage={totalPage} limit={pageSize} page={page} setPage={setPage} />
                    </PagerWrap>
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <DepositModal
                modal={modal}
                setModal={setModal}
                saveModal={saveModal}
                setSaveModal={setSaveModal}
                setFranchise={setFranchise}
                setContent={setContent}
                setName={setName}
                money={money}
                setMoney={setMoney}
                modalFranText={modalFranText}
                setModalFranText={setModalFranText}
                modalFranShow={modalFranShow}
                setModalFranShow={setModalFranShow}
                onSubmit={onSubmit}
                putCartClick={putCartClick}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

export default FranchiseeDeposit;
