// 외부 모듈
import React, { useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import XLSX from "xlsx-js-style";

// 내부 모듈
import {
  ContentContainer,
  // HeaderContainer,
  Title,
  WhiteBox,
  Wrapper,
  Contents,
  Container,
  FlexBox,
  TextBar,
  Text,
  Btn,
} from "../../Assets/css/commonVer2";
// import Header from "../../Components/Header/HeaderVer2";
import HeaderSub from "../../Components/Header/HeaderSub";
import Authority from "../../Components/Modal/Authority";
import Loading from "../../Components/Loading";
import formAPI from "../../Utiles/formAPI";
import API from "../../Utiles/API";
// import FileUpload from "./Components/FileUpload";
import FileUpload from "../../Components/FileUpload/FileUploadVer2";
import InfoModal from "../../Components/Modal/InfoModal";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import { useMediaQuery } from "react-responsive";

function ProductBulkWrite() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();
  const [moveToBackModal, setMoveToBackModal] = useState(false);
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [saveModal, setSaveModal] = useState(false);
  const [listModal, setListModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isError, setIsError] = useState(false);
  const [fileData, setFileData] = useState([]);
  // const franName = [];
  // const franId = [];
  // const cate1Name = [];
  // const cate1Id = [];
  const cate2Name = [];
  const cate2Id = [];

  // API
  const postBulkProducts = async () => {
    const formData = new FormData();
    formData.append("file_name", fileData);
    setLoading(true);

    await formAPI
      .post("/admin/product/bulk", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setMoveToBackModal(true);
        } else {
          setLoading(false);
          setTextMsg(res.data.msg || "잠시 후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error) {
          setIsError(true);
          setTextMsg(error.response.data.msg || "E01\n잠시 후 다시 시도해주세요.");
          setTextModal(true);
        } else {
          setTextMsg("E01\n잠시 후 다시 시도해주세요.");
          setTextModal(true);
        }
      });
  };

  // 샘플 액셀 다운
  const onClickExcelDown = async () => {
    await API.get("/admin/product/bulk")
      .then((res) => {
        if (res.data.status) {
          if (cate2Name.length === 0 && cate2Id.length === 0) {
            res.data.results.category2nd.forEach((item) => {
              cate2Name.push(item.name);
              cate2Id.push(item.category2nd_id);
            });
          }
        } else {
          setTextMsg(res.data.msg || "잠시 후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch((error) => {
        setTextMsg(error.data.msg || "E01\n잠시 후 다시 시도해주세요.");
        setTextModal(true);
      });

    // STEP 1: 새로운 workbook을 만든다
    const wb = XLSX.utils.book_new();
    // sheet1
    const header1 = [
      {
        v: "바코드번호",
        t: "s",
        s: {
          fill: { fgColor: { rgb: "FFFF00" } },
          font: { bold: true, sz: "14" },
          alignment: { horizontal: "center", vertical: "center" },
        },
      },
      {
        v: "브랜드",
        t: "s",
        s: {
          fill: { fgColor: { rgb: "E9E9E9" } },
          font: { bold: true, sz: "14" },
          alignment: { horizontal: "center", vertical: "center" },
        },
      },
      {
        v: "상품명",
        t: "s",
        s: {
          fill: { fgColor: { rgb: "FFFF00" } },
          font: { bold: true, sz: "14" },
          alignment: { horizontal: "center", vertical: "center" },
        },
      },
      {
        v: "매입가",
        t: "s",
        s: {
          fill: { fgColor: { rgb: "FFFF00" } },
          font: { bold: true, sz: "14" },
          alignment: { horizontal: "center", vertical: "center" },
        },
      },
      {
        v: "매입수량",
        t: "s",
        s: {
          fill: { fgColor: { rgb: "FFFF00" } },
          font: { bold: true, sz: "14" },
          alignment: { horizontal: "center", vertical: "center" },
        },
      },
      {
        v: "매입비용",
        t: "s",
        s: {
          fill: { fgColor: { rgb: "FFFF00" } },
          font: { bold: true, sz: "14" },
          alignment: { horizontal: "center", vertical: "center" },
        },
      },
      {
        v: "출하가",
        t: "s",
        s: {
          fill: { fgColor: { rgb: "FFFF00" } },
          font: { bold: true, sz: "14" },
          alignment: { horizontal: "center", vertical: "center" },
        },
      },
      {
        v: "기본출하수량",
        t: "s",
        s: {
          fill: { fgColor: { rgb: "FFFF00" } },
          font: { bold: true, sz: "14" },
          alignment: { horizontal: "center", vertical: "center" },
        },
      },
      {
        v: "출하비용",
        t: "s",
        s: {
          fill: { fgColor: { rgb: "FFFF00" } },
          font: { bold: true, sz: "14" },
          alignment: { horizontal: "center", vertical: "center" },
        },
      },
      {
        v: "마진율",
        t: "s",
        s: {
          fill: { fgColor: { rgb: "E9E9E9" } },
          font: { bold: true, sz: "14" },
          alignment: { horizontal: "center", vertical: "center" },
        },
      },
      {
        v: "공급업체",
        t: "s",
        s: {
          fill: { fgColor: { rgb: "FFFF00" } },
          font: { bold: true, sz: "14" },
          alignment: { horizontal: "center", vertical: "center" },
        },
      },
      {
        v: "소비자가",
        t: "s",
        s: {
          fill: { fgColor: { rgb: "FFFF00" } },
          font: { bold: true, sz: "14" },
          alignment: { horizontal: "center", vertical: "center" },
        },
      },
      {
        v: "발주단위",
        t: "s",
        s: {
          fill: { fgColor: { rgb: "FFFF00" } },
          font: { bold: true, sz: "14" },
          alignment: { horizontal: "center", vertical: "center" },
        },
      },
      {
        v: "상품상세설명",
        t: "s",
        s: {
          fill: { fgColor: { rgb: "E9E9E9" } },
          font: { bold: true, sz: "14" },
          alignment: { horizontal: "center", vertical: "center" },
        },
      },

      {
        v: "상품카테고리코드",
        t: "s",
        s: {
          fill: { fgColor: { rgb: "FFFF00" } },
          font: { bold: true, sz: "14" },
          alignment: { horizontal: "center", vertical: "center" },
        },
      },
      {
        v: "재고수량",
        t: "s",
        s: {
          fill: { fgColor: { rgb: "FFFF00" } },
          font: { bold: true, sz: "14" },
          alignment: { horizontal: "center", vertical: "center" },
        },
      },
    ];

    // sheet2
    const header2 = [
      {
        v: "2차카테고리",
        t: "s",
        s: {
          fill: { fgColor: { rgb: "EED202" } },
          font: { bold: true, sz: "14" },
          alignment: { horizontal: "center", vertical: "center" },
        },
      },
      {
        v: "2차카테고리코드",
        t: "s",
        s: {
          fill: { fgColor: { rgb: "EED202" } },
          font: { bold: true, sz: "14" },
          alignment: { horizontal: "center", vertical: "center" },
        },
      },
    ];

    const body4 = [];
    for (let i = 0; i < cate2Name.length; i++) {
      const newBody = [
        {
          v: cate2Name[i],
          t: "s",
          s: { font: { color: { rgb: "333333" } } },
        },
        {
          v: cate2Id[i],
          t: "s",
          s: { font: { color: { rgb: "333333" } } },
        },
      ];
      body4.push(newBody);
    }

    // STEP 3: header와 body로 worksheet를 생성한다.
    const ws1 = XLSX.utils.aoa_to_sheet([header1]);
    const ws2 = XLSX.utils.aoa_to_sheet([header2]);

    // sheet2 각 테이블의 행과 열 지정
    XLSX.utils.sheet_add_aoa(ws2, [...body4], { origin: { c: 0, r: 1 } });

    // sheet1 셀 너비 지정
    ws1["!cols"] = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];

    // sheet1 셀 너비 지정
    ws2["!cols"] = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];

    // worksheet를 workbook에 추가한다.
    XLSX.utils.book_append_sheet(wb, ws1, "sample");
    XLSX.utils.book_append_sheet(wb, ws2, "ref");

    // STEP 4: 엑셀 파일 저장 (2번째 인자에는 파일명을 지정)
    XLSX.writeFile(wb, "대량 등록 샘플 양식.xlsx");
  };

  // 목록 or 저장
  const onSubmit = (e) => {
    if (e === 1) {
      setSaveModal(false);
      navigate(-1, { replace: true });
    } else {
      postBulkProducts();
      setSaveModal(false);
    }
  };

  // 저장 클릭 시
  const onClickSave = () => {
    if (!isSelected) {
      setTextMsg("선택된 파일이 없습니다.");
      setTextModal(true);
    } else {
      setSaveModal(true);
    }
  };

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {loading ? <Loading /> : null}
          <HeaderSub title={"대량 등록"} />
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    대량 등록
                  </Title>
                )}
                <WhiteBox>
                  <Title size={"18px"} color={"#2d2d2d"} weight={"700"}>
                    파일 첨부
                  </Title>

                  {/* <ContentsHalf> */}
                  <Contents marginMd={"20px 0 0"} margin={"8px 0 0"}>
                    <FlexBox direction={"column"} directionMd={"row"} flex={"1 0 auto"}>
                      <FileUpload
                        setIsSelected={setIsSelected}
                        setFileData={setFileData}
                        placeholder={"대량 등록 엑셀 파일을 선택해주세요."}
                      />
                      {!isMobile && (
                        <Btn
                          width={"100%"}
                          widthMd={"unset"}
                          size={"14px"}
                          padding={"8px 10px"}
                          paddingMd={"8px 18px"}
                          lineHeight={"22px"}
                          hoverWeight={"700"}
                          margin={"10px 0 0 0"}
                          marginMd={"0 0 0 10px"}
                          onClick={onClickExcelDown}
                          style={{ flexShrink: 0 }}
                        >
                          샘플 양식 다운
                        </Btn>
                      )}
                    </FlexBox>
                    {!isMobile && (
                      <div style={{ marginTop: "30px" }}>
                        <Text
                          color={"#666666"}
                          size={"14px"}
                          sizeMd={"18px"}
                          weight={"700"}
                          align={"left"}
                          margin={"0 0 10px 0"}
                          latterSpacing={"-0.28px"}
                          lineHeight={"normal"}
                        >
                          유의사항
                        </Text>
                        <TextBar color={"#6c6c6c"} align={"left"}>
                          첫 번째 시트의{" "}
                          <BoldText>노란색 배경 셀은 필수 등록 사항으로, 모두 기입하셔야 등록이 가능</BoldText>합니다.
                          그 외 빈 셀은 모두 삭제해 주시기 바랍니다.
                        </TextBar>
                        <TextBar color={"#6c6c6c"} align={"left"}>
                          필수 등록 사항 중{" "}
                          <BoldText>'바코드번호, 상품명, 공급업체, 발주단위' 셀의 데이터 포맷</BoldText>은 반드시{" "}
                          <BoldText>'텍스트'로 지정</BoldText>해 주시기 바랍니다.
                        </TextBar>
                        <TextBar color={"#6c6c6c"} align={"left"}>
                          매입카테고리코드, 상품카테고리코드, 가맹점고유번호는 두 번째 시트를 참고하여 기입해 주시기
                          바랍니다.
                        </TextBar>
                        <TextBar color={"#6c6c6c"} align={"left"}>
                          파일 규정 형식은 <BoldText>xlsx</BoldText>입니다.
                        </TextBar>
                      </div>
                    )}
                  </Contents>
                  {/* </ContentsHalf> */}
                </WhiteBox>
                {isMobile && (
                  <div
                    className="greyBox"
                    style={{
                      marginBottom: "20px",
                      padding: "20px 20px",
                    }}
                  >
                    <Text
                      color={"#666666"}
                      size={"14px"}
                      sizeMd={"18px"}
                      weight={"700"}
                      align={"left"}
                      margin={"0 0 10px 0"}
                      latterSpacing={"-0.28px"}
                      lineHeight={"normal"}
                    >
                      유의사항
                    </Text>
                    <TextBar color={"#6c6c6c"} align={"left"}>
                      첫 번째 시트의{" "}
                      <BoldText>노란색 배경 셀은 필수 등록 사항으로, 모두 기입하셔야 등록이 가능</BoldText>합니다. 그 외
                      빈 셀은 모두 삭제해 주시기 바랍니다.
                    </TextBar>
                    <TextBar color={"#6c6c6c"} align={"left"}>
                      필수 등록 사항 중 <BoldText>'바코드번호, 상품명, 공급업체, 발주단위' 셀의 데이터 포맷</BoldText>은
                      반드시 <BoldText>'텍스트'로 지정</BoldText>해 주시기 바랍니다.
                    </TextBar>
                    <TextBar color={"#6c6c6c"} align={"left"}>
                      매입카테고리코드, 상품카테고리코드, 가맹점고유번호는 두 번째 시트를 참고하여 기입해 주시기
                      바랍니다.
                    </TextBar>
                    <TextBar color={"#6c6c6c"} align={"left"}>
                      파일 규정 형식은 <BoldText>xlsx</BoldText>입니다.
                    </TextBar>
                  </div>
                )}
                <FlexBox justify={"end"}>
                  <FlexBox flex={"1 0 auto"} flexMd={"unset"} gap={"8px"} gapMd={"10px"}>
                    <Btn
                      width={"100%"}
                      widthMd={"90px"}
                      size={"14px"}
                      color={"#666"}
                      border={"1px solid #666"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      hoverWeight={"700"}
                      hoverColor={"#fff"}
                      hoverBackground={"#C7C7C7"}
                      hoverBorder={"1px solid transparent"}
                      onClick={() => setListModal(true)}
                    >
                      목 록
                    </Btn>
                    <Btn
                      width={"100%"}
                      widthMd={"90px"}
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      onClick={onClickSave}
                    >
                      저 장
                    </Btn>
                  </FlexBox>
                </FlexBox>
              </Container>
              <ConfirmModal
                modal={listModal}
                setModal={setListModal}
                onSubmit={() => onSubmit(1)}
                description={"대량 등록을 취소하고\n목록화면으로 이동하시겠습니까?"}
              />
              <ConfirmModal
                modal={saveModal}
                setModal={setSaveModal}
                onSubmit={() => onSubmit(2)}
                description={"대량 등록을 완료하시겠습니까?"}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} isLong={isError} />
              <InfoModal
                modal={moveToBackModal}
                setModal={setMoveToBackModal}
                description={"대량 등록이 완료되었습니다."}
                mode={"goBack"}
              />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const BoldText = styled.span`
  font-weight: bold;
`;

export default ProductBulkWrite;
