import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import {
  Btn,
  HeaderContainer,
  Wrapper,
  Container,
  ContentContainer,
  FlexBox,
  Text,
  WhiteBox,
  Title,
  Td,
  Th,
  CheckLabel,
  CheckIcon,
  SquareImg,
} from "../../Assets/css/commonVer2";
import Header from "../../Components/Header/HeaderVer2";
import { AddComma } from "../../Utiles/Custom";
import API from "../../Utiles/API";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import Authority from "../../Components/Modal/Authority";
import InfoModal from "../../Components/Modal/InfoModal";
import img_cart from "../../Assets/images/ico_shopping_cart.svg";
import img_noimage from "../../Assets/images/no_image.jpg";
import ico_minus from "../../Assets/images/ico_minus.svg";
import ico_minus_disable from "../../Assets/images/ico_minus_disable.svg";
import ico_plus from "../../Assets/images/ico_plus.svg";
import Loading from "../../Components/Loading";

// 상품관리 > 장바구니 관리

function Shopping() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const isResponsive = useMediaQuery({ maxWidth: 1200 });
  const navigate = useNavigate();
  const [idx] = useState(sessionStorage.getItem("user_id"));

  const [item, setItem] = useState([]);
  const [checkItems, setCheckItems] = useState([]);
  const [price, setPrice] = useState(0);
  const [cnt, setCnt] = useState(0);
  const [saveModal, setSaveModal] = useState(false);

  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");

  const [dataLoading, setDataLoading] = useState(false);

  // 서버에서 필요한 기본 정보 받아오는 api
  const getList = async () => {
    try {
      const res = await API.get(`/storeowner/shopping-basket/${idx}`);
      if (res.data.status) {
        setItem(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 장바구니 삭제 api
  const onSubmit = () => {
    API.delete(`/storeowner/shopping-basket?idx=${checkItems}`)
      .then(function (res) {
        if (res.data.status) {
          getList();
          setSaveModal(false);
          setPrice(0);
          setCheckItems([]);
          setCnt(0);
        } else {
          setSaveModal(false);
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(function (error) {
        setSaveModal(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  function moveToOrder() {
    if (checkItems.length > 0) {
      //체크된 아이템 목록으로 만들기
      const checkedItemList = checkItems.map((idx) => item.find((item) => item.idx === idx));

      // 주문하기에 필요한 객체로 만들기
      const newList = [];
      // 쇼핑 목록 idx 보내야함
      const idxList = [];
      for (let i = 0; i < checkItems.length; i++) {
        const currentItem = checkedItemList[i];
        newList.push({
          product_id: currentItem.product.idx,
          quantity: currentItem.quantity,
          product_name: currentItem.product.product_name,
        });
        idxList.push(checkedItemList[i].idx);
      }

      navigate("/goods/order", {
        state: {
          itemList: newList,
          price: price,
          idxList: idxList,
        },
      });
    } else {
      setTextMsg("주문할 상품을 선택해주세요.");
      setTextModal(true);
    }
  }

  // 수량 증가
  const quantityDownClick = async (idx, quantity, basic_shipment_quantity) => {
    try {
      setDataLoading(true);
      let updatedCount = quantity - basic_shipment_quantity;
      updatedCount = Math.max(updatedCount, basic_shipment_quantity); // item.product.basic_shipment_quantity보다 작을 수 없도록 제한
      const res = await API.put(`/storeowner/shopping-basket`, {
        shopping_basket_idx: idx,
        quantity: updatedCount,
      });
      if (res.status) {
        setDataLoading(false);
        getList();
      }
    } catch (error) {
      setDataLoading(false);
      console.log(error);
    }
  };

  // 수량 감소
  const quantityUpClick = async (idx, quantity, basic_shipment_quantity) => {
    try {
      setDataLoading(true);
      const res = await API.put(`/storeowner/shopping-basket`, {
        shopping_basket_idx: idx,
        quantity: quantity + basic_shipment_quantity,
      });
      if (res.status) {
        setDataLoading(false);
        getList();
      }
    } catch (error) {
      setDataLoading(false);
      console.log(error);
    }
  };

  const handleChk = (checked, id, price2) => {
    if (checked) {
      setCheckItems((prev) => [...prev, id]);
      setCnt(cnt + 1);
      setPrice(parseInt(price) + parseInt(price2));
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
      setCnt(cnt - 1);
      setPrice(parseInt(price) - parseInt(price2));
    }
  };

  const handleAllChk = (checked) => {
    if (checked) {
      const chkArr = [];
      const price2 = [];
      item.forEach((e) => price2.push(e.quantity * e.product.shipping_price));
      item.forEach((e) => chkArr.push(e.idx));
      var sum = price2.reduce(function (total, str) {
        return total + parseInt(str);
      }, 0);
      setPrice(sum);
      setCheckItems(chkArr);
      setCnt(item.length);
    } else {
      setPrice(0);
      setCheckItems([]);
      setCnt(0);
    }
  };

  const ListDel = () => {
    if (checkItems.length > 0) {
      setSaveModal(true);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  // 디폴트가 모든 아이템 선택되어 있도록
  useEffect(() => {
    handleAllChk(true);
  }, [item]);

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          {dataLoading ? <Loading /> : null}
          {isMobile && <Header title={"장바구니 관리"} />}
          <ContentContainer>
            <Wrapper type={2} pbM={"112px"}>
              <Container>
                {!isMobile && (
                  <FlexBox justify={"space-between"} style={{ flex: 1 }}>
                    <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                      장바구니 관리 🛒
                    </Title>
                    <FlexBox />
                  </FlexBox>
                )}

                <WhiteBox margin={"0 0"}>
                  {item.length > 0 ? (
                    <>
                      <FlexBox justify={"starts"}>
                        <Text size={"16px"} sizeMd={"18px"} color={"#1E1E1E"} weight={"700"}>
                          장바구니 목록
                        </Text>
                      </FlexBox>
                      <FlexBox justify={"space-between"} margin="20px 0 0">
                        <FlexBox>
                          <CheckLabel htmlFor="chkAll">
                            <input
                              type="checkbox"
                              id="chkAll"
                              onChange={(event) => handleAllChk(event.target.checked)}
                              checked={checkItems.length === item.length ? true : false}
                            />
                            <CheckIcon>
                              <svg viewBox="0 0 24 24">
                                <polyline points="19 7 10 17 5 12" />
                              </svg>
                            </CheckIcon>
                          </CheckLabel>
                          <Text color={"#2d2d2d"} weight={"500"} size={"12px"} sizeMd={"14px"} margin={"0 0 0 5px"}>
                            전체선택
                          </Text>
                          {isMobile && (
                            <>
                              <Text color={"#2d2d2d"} weight={"500"} size={"12px"} margin="0 8px">
                                |
                              </Text>
                              <Text
                                style={{ cursor: "pointer" }}
                                color={"#2d2d2d"}
                                weight={"500"}
                                size={"12px"}
                                onClick={() => ListDel()}
                              >
                                선택삭제
                              </Text>
                            </>
                          )}
                        </FlexBox>
                        {!isMobile && (
                          <Btn
                            border={"1px solid #FF4A4A"}
                            color={"#FF4A4A"}
                            hoverColor={"#FFFFFF"}
                            hoverBackground={"#FF4A4A"}
                            hoverWeight={"700"}
                            padding={"9px 19px"}
                            size={"12px"}
                            sizeMd={"14px"}
                            radius={"6px"}
                            weight={"400"}
                            lineHeight={"22px"}
                            onClick={() => ListDel()}
                          >
                            선택삭제
                          </Btn>
                        )}
                      </FlexBox>
                      <ShopTable>
                        <colgroup>
                          <col style={{ width: isMobile ? "15%" : "10%" }} />
                          <col />
                          <col className="d-none d-md-table-column" style={{ width: "16%" }} />
                          <col className="d-none d-md-table-column" style={{ width: "16%" }} />
                          <col className="d-none d-md-table-column" style={{ width: "16%" }} />
                        </colgroup>
                        <thead className="d-none d-md-table-head">
                          <tr>
                            <Th>선택</Th>
                            <Th>상품명</Th>
                            <Th>수량</Th>
                            <Th>상품 금액</Th>
                            <Th>총 금액</Th>
                          </tr>
                        </thead>
                        <tbody>
                          {item?.map((list, i) => (
                            <tr
                              style={{ cursor: "pointer" }}
                              key={i}
                              onClick={(event) => {
                                const targetElement = event.target;
                                if (targetElement.nodeName !== "INPUT") {
                                  // 체크박스를 클릭한 경우에는 이벤트를 처리하지 않음
                                  const isChecked = document.getElementById(`shop${list.idx}`).checked;
                                  handleChk(!isChecked, list.idx, list.quantity * list.product.shipping_price);
                                }
                              }}
                            >
                              <ShopTd align={"center"}>
                                <CheckLabel htmlFor={`shop${list.idx}`} style={{ justifyContent: "center" }}>
                                  <input
                                    type="checkbox"
                                    name="shop"
                                    id={`shop${list.idx}`}
                                    readOnly
                                    checked={checkItems.includes(list.idx) ? true : false}
                                  />
                                  <CheckIcon>
                                    <svg viewBox="0 0 24 24">
                                      <polyline points="19 7 10 17 5 12" />
                                    </svg>
                                  </CheckIcon>
                                </CheckLabel>
                              </ShopTd>
                              <ShopTd align={"left"} style={{ cursor: "pointer" }}>
                                <FlexBox justify={"flex-start"} gap={"10px"}>
                                  <ShopImg>
                                    <SquareImg>
                                      <div>
                                        <img
                                          src={
                                            list.product.image_path_1
                                              ? process.env.REACT_APP_S3_KEY + list.product.image_path_1
                                              : img_noimage
                                          }
                                        />
                                      </div>
                                    </SquareImg>
                                  </ShopImg>
                                  <ShopText>
                                    <Text size={"10px"} sizeMd={"14px"} color={"#666"} align={"left"}>
                                      {list ? list.product.brand : ""}
                                    </Text>
                                    <Text size={"14px"} sizeMd={"16px"} weight={"500"} color={"#000"} align={"left"}>
                                      {list ? list.product.product_name : ""}
                                    </Text>
                                    <FlexBox justify={"flex-start"} gap={"10px"} className="d-none d-md-flex">
                                      <Text size={"10px"} sizeMd={"14px"} color={"#666"} align={"left"}>
                                        {list ? list.product.barcode_num : ""}
                                      </Text>
                                      <Text size={"10px"} sizeMd={"14px"} color={"#666"} align={"left"}>
                                        {list.product.order_unit === "개" || list.product.order_unit === ""
                                          ? ""
                                          : `${list.product.order_unit} 당 :${list.product.purchase_quantity}개`}
                                      </Text>
                                    </FlexBox>
                                    <Text
                                      size={"10px"}
                                      color={"#2d2d2d"}
                                      align={"left"}
                                      padding={"4px 0 0"}
                                      className="d-block d-md-none"
                                    >
                                      {list.product.barcode_num}
                                      {list.product.order_unit === "개" || list.product.order_unit === ""
                                        ? ""
                                        : `${list.product.order_unit} 당 :${list.product.purchase_quantity}개`}
                                    </Text>
                                    <Text
                                      size={"10px"}
                                      color={"#2d2d2d"}
                                      align={"left"}
                                      padding={"4px 0 0"}
                                      className="d-block d-md-none"
                                    >
                                      {list.product.basic_shipment_quantity}
                                      {list.product.order_unit} | {list ? AddComma(list.product.shipping_price) : 0}원{" "}
                                      <br />
                                      <span style={{ fontWeight: "700", fontSize: "16px" }}>
                                        {" "}
                                        {AddComma(list.quantity * list.product.shipping_price)}원
                                      </span>
                                    </Text>
                                  </ShopText>
                                </FlexBox>
                                <QuantityCntBox className="d-block d-md-none">
                                  <FlexBox
                                    style={{
                                      border: "1px solid #c0c0c0",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    <FlexBox
                                      style={{
                                        borderRight: "1px solid #c0c0c0",
                                        padding: isResponsive ? "6px" : "8px",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        if (list.quantity - list.product.basic_shipment_quantity > 0) {
                                          quantityDownClick(
                                            list.idx,
                                            list.quantity,
                                            list.product.basic_shipment_quantity
                                          );
                                        }
                                      }}
                                    >
                                      <img
                                        src={
                                          list.quantity - list.product.basic_shipment_quantity > 0
                                            ? ico_minus
                                            : ico_minus_disable
                                        }
                                        style={{ width: "14px" }}
                                      />
                                    </FlexBox>
                                    <Text size={"12px"} weight={"400"} color={"#1E1E1E"} padding={"0 10px"}>
                                      {list.quantity ? list.quantity : 0}
                                    </Text>
                                    <FlexBox
                                      style={{
                                        borderLeft: "1px solid #c0c0c0",
                                        padding: isResponsive ? "6px" : "8px",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        quantityUpClick(list.idx, list.quantity, list.product.basic_shipment_quantity);
                                      }}
                                    >
                                      <img src={ico_plus} style={{ width: "14px" }} />
                                    </FlexBox>
                                  </FlexBox>
                                </QuantityCntBox>
                              </ShopTd>
                              <ShopTd className="d-none d-md-table-cell">
                                <QuantityCntBox>
                                  <FlexBox
                                    style={{
                                      border: "1px solid #c0c0c0",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    <FlexBox
                                      style={{
                                        borderRight: "1px solid #c0c0c0",
                                        padding: isResponsive ? "6px" : "8px",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        if (list.quantity - list.product.basic_shipment_quantity > 0) {
                                          quantityDownClick(
                                            list.idx,
                                            list.quantity,
                                            list.product.basic_shipment_quantity
                                          );
                                        }
                                      }}
                                    >
                                      <img
                                        src={
                                          list.quantity - list.product.basic_shipment_quantity > 0
                                            ? ico_minus
                                            : ico_minus_disable
                                        }
                                      />
                                    </FlexBox>
                                    <Text size={"12px"} weight={"400"} color={"#1E1E1E"} padding={"0 10px"}>
                                      {list.quantity ? list.quantity : 0}
                                    </Text>
                                    <FlexBox
                                      style={{
                                        borderLeft: "1px solid #c0c0c0",
                                        padding: isResponsive ? "6px" : "8px",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        quantityUpClick(list.idx, list.quantity, list.product.basic_shipment_quantity);
                                      }}
                                    >
                                      <img src={ico_plus} />
                                    </FlexBox>
                                  </FlexBox>
                                </QuantityCntBox>
                              </ShopTd>
                              <ShopTd
                                size={"16px"}
                                weight={"400"}
                                color={"#1E1E1E"}
                                align={"right"}
                                className="d-none d-md-table-cell"
                              >
                                {list ? AddComma(list.product.shipping_price) : 0}원
                              </ShopTd>
                              <ShopTd
                                size={"16px"}
                                weight={"700"}
                                color={"#1E1E1E"}
                                align={"right"}
                                className="d-none d-md-table-cell"
                              >
                                {list ? AddComma(list.quantity * list.product.shipping_price) : 0}원
                              </ShopTd>
                            </tr>
                          ))}
                        </tbody>
                      </ShopTable>
                      <FlexBox justify={"starts"} margin="30px 0 0">
                        <Text size={"16px"} sizeMd={"18px"} color={"#1E1E1E"} weight={"700"}>
                          최종 결제 금액
                        </Text>
                      </FlexBox>
                      {!isMobile ? (
                        <FlexBox justify={"space-evenly"} margin={"30px 0 0 "} gap="35px">
                          <WhiteBox width="100%">
                            <FlexBox justify={"space-between"} wrap={"wrap"}>
                              <Text size={"16px"} color={"#1E1E1E"}>
                                총 상품 개수
                              </Text>
                              <Text size={"22px"} color={"#666"} weight={"700"}>
                                {cnt ? cnt : 0}개
                              </Text>
                            </FlexBox>
                          </WhiteBox>

                          <WhiteBox width="100%">
                            <FlexBox justify={"space-between"} wrap={"wrap"}>
                              <Text size={"16px"} color={"#1E1E1E"}>
                                총 주문 금액
                              </Text>
                              <Text size={"22px"} color={"#666"} weight={"700"}>
                                {price ? AddComma(price) : 0}원
                              </Text>
                            </FlexBox>
                          </WhiteBox>
                          <WhiteBox width="100%">
                            <FlexBox justify={"space-between"} wrap={"wrap"}>
                              <Text size={"16px"} color={"#1E1E1E"}>
                                잔여 예치금
                              </Text>
                              <Text size={"22px"} color={"#666"} weight={"700"}>
                                {item[0] ? AddComma(item[0].user.holding_deposit) : 0}원
                              </Text>
                            </FlexBox>
                          </WhiteBox>
                        </FlexBox>
                      ) : (
                        <div>
                          <FlexBox justify={"space-between"} margin="10px 0 0">
                            <Text size={"14px"} color={"#1E1E1E"}>
                              총 상품 개수
                            </Text>
                            <Text size={"14px"} color={"#1E1E1E"} weight={"700"}>
                              {cnt ? cnt : 0}개
                            </Text>
                          </FlexBox>
                          <FlexBox justify={"space-between"} margin="10px 0 0">
                            <Text size={"14px"} color={"#1E1E1E"}>
                              총 주문 금액
                            </Text>
                            <Text size={"14px"} color={"#1E1E1E"} weight={"700"}>
                              {price ? AddComma(price) : 0}원
                            </Text>
                          </FlexBox>
                          <FlexBox justify={"space-between"} margin="10px 0 0">
                            <Text size={"14px"} color={"#1E1E1E"}>
                              잔여 예치금
                            </Text>
                            <Text size={"14px"} color={"#1E1E1E"} weight={"700"}>
                              {item[0] ? AddComma(item[0].user.holding_deposit) : 0}원
                            </Text>
                          </FlexBox>
                        </div>
                      )}
                      <div
                        className="m_fixed_btn"
                        style={{
                          width: "100%",
                          zIndex: "210",
                          display: "flex" /* 부모 요소를 flex 컨테이너로 설정 */,
                          justifyContent: "center" /* 가로 가운데 정렬 */,
                        }}
                      >
                        <FlexBox
                          gap={"10px"}
                          width="100%"
                          margin="20px 0 0 "
                          marginMd="50px 0"
                          style={{ maxWidth: "774px" }}
                        >
                          <Btn
                            weight={"400"}
                            background={"#fff"}
                            size={"14px"}
                            sizeMd={"20px"}
                            color={"#666"}
                            border={"1px solid #666"}
                            padding={"9px 19px"}
                            paddingMd={"16px 0"}
                            style={{ flex: 1 }}
                            onClick={() => navigate("/goods/all")}
                          >
                            계속 쇼핑하기
                          </Btn>
                          <Btn
                            weight={"700"}
                            size={"14px"}
                            sizeMd={"20px"}
                            color={"#fff"}
                            border={"1px solid #FF4A4A"}
                            background={"#FF4A4A"}
                            padding={"9px 19px"}
                            paddingMd={"16px 0"}
                            style={{ flex: 1 }}
                            onClick={() => {
                              moveToOrder();
                            }}
                          >
                            주문하기
                          </Btn>
                        </FlexBox>
                      </div>
                    </>
                  ) : (
                    <ContWrap>
                      {isMobile && (
                        <img
                          src={img_cart}
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        />
                      )}
                      <Title size={"18px"} sizeMd={"20px"} color={"#1E1E1E"} weight={"700"}>
                        장바구니 목록
                      </Title>
                      <Text
                        size={"14px"}
                        sizeMd={"18px"}
                        color={"#666"}
                        colorMd={"#1E1E1E"}
                        weight={"700"}
                        weightMd={"400"}
                        margin="20px 0 0"
                      >
                        장바구니에 담긴 상품이 없어요!
                      </Text>
                      {!isMobile && (
                        <img
                          src={img_cart}
                          className="w-30 md:w-auto mt-0 md:mt-5 mb-4 md:mb-1"
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        />
                      )}
                      {isMobile ? (
                        <ShopBtn
                          color={"#fff"}
                          background={"#FF4A4A"}
                          weight={"700"}
                          border={"1px solid #FF4A4A"}
                          size={"14px"}
                          onClick={() => navigate("/goods/all")}
                        >
                          상품 담으러 가기
                        </ShopBtn>
                      ) : (
                        <ShopBtn
                          color={"#666"}
                          weight={"400"}
                          border={"1px solid #666"}
                          size={"20px"}
                          padding="16px 30px"
                          onClick={() => navigate("/goods/all")}
                          hoverBackground="unset"
                        >
                          상품 담으러 가기
                        </ShopBtn>
                      )}
                    </ContWrap>
                  )}
                </WhiteBox>
              </Container>
            </Wrapper>
            <ConfirmModal
              modal={saveModal}
              setModal={setSaveModal}
              onSubmit={() => onSubmit()}
              description={"상품을 장바구니에서 빼겠습니까?"}
            />
            <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
          </ContentContainer>
        </>
      )}
    </>
  );
}

const ContWrap = styled.div`
  margin: 200px 0;
  max-width: 1486px;
  padding: 30px 20px 50px;
  border-radius: 8px;
  background-color: #fff;
  text-align: center;

  @media all and (max-width: 1024px) {
    margin: 150px 0;
    padding: 0;
    border: 0;
  }
`;

const ShopBtn = styled(Btn)`
  width: 100%;
  max-width: 373px;
  margin: 50px auto 0;

  @media all and (max-width: 1024px) {
    max-width: 156px;
  }
`;

const ShopTable = styled.table`
  width: 100%;
  overflow: hidden;

  @media all and (max-width: 1024px) {
    border: none;
  }
`;

const ShopTd = styled(Td)`
  border-bottom: 1px solid #f2f2f2;
`;

const ShopImg = styled.div`
  width: 180px;

  @media all and (max-width: 1200px) {
    width: 60px;
  }
`;
const ShopText = styled.div`
  width: calc(100% - 58px);
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media all and (max-width: 1024px) {
    width: calc(100% - 92px);
    gap: 0;
  }
`;

const QuantityCntBox = styled.div`
  width: 130px;
  margin: 0 auto;

  @media all and (max-width: 1200px) {
    width: 120px;
    margin: 0 0 0 -20px;
  }

  @media all and (max-width: 1050px) {
    width: 118px;
    margin: 0 0 0 -26px;
  }

  @media all and (max-width: 1024px) {
    margin: 0 auto;
  }

  @media all and (max-width: 767px) {
    margin: 10px 0 0 0;
    width: 100px;
  }
`;

export default Shopping;
