import API from "../../Utiles/API";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import {
  Btn,
  Container,
  TextInput,
  Wrapper,
  FlexBox,
  Text,
  Title,
  WhiteBox,
  ContentContainer,
  RadioLabel,
  RadioIcon,
  DisabledDiv,
} from "../../Assets/css/commonVer2";

import HeaderSub from "../../Components/Header/HeaderSub";
import SearchAddress from "../../Components/SearchAddress/SearchAddress";
import ico_close from "../../Assets/images/ico_close.png";
import Authority from "../../Components/Modal/Authority";
import Loading from "../../Components/Loading";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import InfoModal from "../../Components/Modal/InfoModal";
import FranSelect from "../../Components/Select/FranSelect";

// 가맹마스터 > 사용자관리 > 사용자 수정

function FranchiseeUserEdit() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();
  const location = useLocation();
  const idx = location.state.idx;
  const [values, setValues] = useState({
    user_id: "",
    name: "",
    tel: "",
    email: "",
    resident_registration_number: "",
    address: "",
    detail_address: "",
    bank: "",
    account_holder: "",
    account_number: "",
    holding_deposit: "",
    incoming_route: "",
    franchise_id: "",
  });
  const [gubunValue, setGubunValue] = useState("admin");
  const [addrValue, setAddrValue] = useState("");
  const [saveModal, setSaveModal] = useState(false);
  const [listModal, setListModal] = useState(false);
  const [addrModal, setAddrModal] = useState(false);

  const [franIdx, setFranIdx] = useState("");
  const [franText, setFranText] = useState("가맹점 전체");
  const [franShow, setFranShow] = useState(false);

  const [loading, setLoading] = useState(false);
  const [textModal, setTextModal] = useState(false); // 모달 창 표시 여부 상태
  const [textMsg, setTextMsg] = useState("");
  const [infoModalMode, setInfoModalMode] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  const getUsers = async () => {
    const { data } = await API.get(`/admin/users/${idx}`);
    return data;
  };

  const onGubunChange = (val) => {
    setGubunValue(val);
  };

  const onAddrChange = (e) => {
    setAddrValue(e.target.value);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    setSaveModal(true);
  };

  const onModalSubmit = (e) => {
    if (e === 1) {
      setSaveModal(false);
      navigate(-1, { replace: true });
    } else {
      handleSubmit();
      setSaveModal(false);
    }
  };

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("gubun", gubunValue);
    formData.append("user_id", values.user_id);
    formData.append("name", values.name);
    formData.append("tel", values.tel);
    formData.append("email", values.email);
    formData.append("resident_registration_number", values.resident_registration_number);
    formData.append("address", addrValue);
    formData.append("detail_address", values.detail_address);
    formData.append("bank", values.bank);
    formData.append("account_holder", values.account_holder);
    formData.append("account_number", values.account_number);
    formData.append("holding_deposit", +values.holding_deposit);
    formData.append("incoming_route", values.incoming_route);
    formData.append("franchise_id", +franIdx);

    await API.put(`/admin/users/${idx}`, formData)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setInfoModalMode("goBack");
          setTextMsg("수정이 완료되었습니다.");
          setTextModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(function (error) {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  });

  useEffect(() => {
    getUsers().then((data) => {
      if (data.status) {
        const resData = data.result;
        setValues(resData);
        setGubunValue(resData.gubun);
        setAddrValue(resData.address);
        setFranIdx(resData["franchise.franchise_id"]);
        setFranText(resData["franchise.franchise_name"]);
      }
    });
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {loading ? <Loading /> : null}
          <HeaderSub title={"사용자 정보 수정"} />
          <ContentContainer>
            <Wrapper>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    사용자 관리 📋
                  </Title>
                )}
                <WhiteBox>
                  <Title align={"left"} color={"#1E1E1E"} weight={"700"} size={"16px"} sizeMd={"20px"}>
                    사용자 정보 수정
                  </Title>
                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    margin={"20px 0 20px"}
                    marginMd={"20px 0 30px"}
                    wrap={"wrap"}
                    wrapMd={"unset"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        사용자 구분
                      </Text>
                      <FlexBox justify={"start"} gap={"10px"}>
                        <RadioLabel>
                          <input
                            type="radio"
                            name="admin"
                            value={"admin"}
                            checked={gubunValue === "admin"}
                            onChange={() => onGubunChange("admin")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          관리자
                        </RadioLabel>
                        <RadioLabel>
                          <input
                            type="radio"
                            name="storeonwer"
                            value={"storeonwer"}
                            checked={gubunValue === "storeonwer"}
                            onChange={() => onGubunChange("storeonwer")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          가맹점주
                        </RadioLabel>
                      </FlexBox>
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        아이디
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"아이디를 입력해주세요."}
                        name="user_id"
                        value={values.user_id}
                        onChange={handleChange}
                        disabled
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBox justify={"start"} align={"end"} wrap={"wrap"} wrapMd={"unset"} gap={"20px"} gapMd={"26px"}>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        이름
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"이름을 입력해주세요."}
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        주민등록번호
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"주민등록번호를 입력해주세요."}
                        name="resident_registration_number"
                        value={values.resident_registration_number}
                        onChange={handleChange}
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    margin={"20px 0 20px"}
                    marginMd={"30px 0 30px"}
                    wrap={"wrap"}
                    wrapMd={"unset"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        전화번호
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"전화번호를 입력해주세요."}
                        name="tel"
                        value={values.tel}
                        onChange={handleChange}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        이메일
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"이메일을 입력해주세요."}
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBox justify={"start"} align={"start"}>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        주소
                      </Text>
                      <FlexBox margin={"0 0 5px"} marginMd={"0 0 10px"} gap={"10px"} onClick={() => setAddrModal(true)}>
                        <TextInput
                          style={{ flex: 1 }}
                          type={"text"}
                          placeholder={"주소를 입력해주세요."}
                          value={addrValue}
                          onChange={onAddrChange}
                          readOnly
                        />
                        <Btn
                          color={"#fff"}
                          background={"#FF4A4A"}
                          size={"14px"}
                          weight={"700"}
                          padding={"8px 18px"}
                          lineHeight={"22px"}
                          style={{ flexShrink: 0 }}
                        >
                          주소찾기
                        </Btn>
                      </FlexBox>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"상세주소를 입력해주세요."}
                        name="detail_address"
                        value={values.detail_address}
                        onChange={handleChange}
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBox
                    justify={"start"}
                    align={"end"}
                    wrap={"wrap"}
                    wrapMd={"unset"}
                    gap={"20px"}
                    gapMd={"26px"}
                    margin={"20px 0 20px"}
                    marginMd={"30px 0 30px"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        은행
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"은행명을 입력해주세요."}
                        name="bank"
                        value={values.bank}
                        onChange={handleChange}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        예금주
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"예금주를 입력해주세요."}
                        name="account_holder"
                        value={values.account_holder}
                        onChange={handleChange}
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBox justify={"start"} align={"end"} gap={"20px"} gapMd={"26px"} wrap={"wrap"} wrapMd={"unset"}>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        계좌번호
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"계좌번호를 입력해주세요."}
                        name="account_number"
                        value={values.account_number}
                        onChange={handleChange}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        진입경로
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"진입경로를 입력해주세요."}
                        name="incoming_route"
                        value={values.incoming_route}
                        onChange={handleChange}
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBox
                    justify={"start"}
                    align={"end"}
                    wrap={"wrap"}
                    wrapMd={"unset"}
                    gap={"20px"}
                    gapMd={"26px"}
                    margin={"20px 0 0"}
                    marginMd={"30px 0 0"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        가맹점
                      </Text>
                      {!isMobile && (
                        <div style={{ position: "relative" }}>
                          {gubunValue === "admin" && <DisabledDiv />}
                          <FranSelect
                            width={"100%"}
                            gubun={"fran"}
                            selectBtnText={franText}
                            setTextChange={setFranText}
                            setIdxChange={setFranIdx}
                            show={franShow}
                            setFranShow={setFranShow}
                            placeholder={"가맹점을"}
                          />
                        </div>
                      )}

                      <div style={{ position: "relative" }}>
                        {franShow ? ( //searchFran.length > 0 &&
                          <>
                            <PopMobile className="d-block d-md-none">
                              <Btn
                                border={"none"}
                                margin={"0 0 80px auto"}
                                background={"none"}
                                onClick={() => setFranShow(false)}
                              >
                                <img src={ico_close} alt="팝업 닫기" style={{ width: "24px" }} />
                              </Btn>
                              <Text size={"16px"} color={"#1E1E1E"} weight={"500"} margin={"0 0 20px"}>
                                가맹점을 선택해주세요.
                              </Text>
                              <div style={{ position: "relative" }}>
                                <FranSelect
                                  width={"100%"}
                                  gubun={"fran"}
                                  selectBtnText={franText}
                                  setTextChange={setFranText}
                                  setIdxChange={setFranIdx}
                                  show={franShow}
                                  setFranShow={setFranShow}
                                  placeholder={"가맹점을"}
                                />
                              </div>
                            </PopMobile>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </Contents>
                    {/* <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        보유 예치금
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="holding_deposit"
                          value={
                            values.holding_deposit.length > 0
                              ? AddComma(values.holding_deposit.replaceAll(",", ""))
                              : AddComma(values.holding_deposit)
                          }
                          disabled
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "12px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents> */}
                  </FlexBox>
                </WhiteBox>
                <FlexBox justify={"end"} flex={"1 0 auto"} flexMd={"unset"} gap={"8px"} gapMd={"10px"}>
                  <Btn
                    width={"100%"}
                    widthMd={"90px"}
                    size={"14px"}
                    color={"#666"}
                    border={"1px solid #666"}
                    padding={"8px 18px"}
                    lineHeight={"22px"}
                    hoverWeight={"700"}
                    hoverColor={"#fff"}
                    hoverBackground={"#C7C7C7"}
                    hoverBorder={"1px solid transparent"}
                    onClick={() => setListModal(true)}
                  >
                    목록
                  </Btn>
                  <Btn
                    width={"100%"}
                    widthMd={"90px"}
                    color={"#fff"}
                    background={"#FF4A4A"}
                    size={"14px"}
                    weight={"700"}
                    padding={"8px 18px"}
                    lineHeight={"22px"}
                    onClick={onSubmit}
                  >
                    저장
                  </Btn>
                </FlexBox>
              </Container>
              {addrModal ? <SearchAddress setModal={setAddrModal} setValue={setAddrValue} setIsSearch={setIsSearch}/> : ""}
              <ConfirmModal
                modal={listModal}
                setModal={setListModal}
                onSubmit={() => onModalSubmit(1)}
                description={"사용자 수정을 취소하고\n목록화면으로 이동하시겠습니까?"}
              />
              <ConfirmModal
                modal={saveModal}
                setModal={setSaveModal}
                onSubmit={() => onModalSubmit(2)}
                description={"사용자 수정을 완료하시겠습니까?"}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} mode={infoModalMode} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const Contents = styled.div`
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  background-color: ${(props) => props.background || ""};
  width: ${(props) => props.width || "100%"};
  border-radius: ${(props) => props.radius || "8px"};
  display: ${(props) => props.display || "block"};
  justify-content: ${(props) => props.justify ?? props.justify};
  align-items: ${(props) => props.align ?? props.align};

  @media all and (min-width: 1024px) {
    margin: ${(props) => props.marginMd || "0"};
    padding: ${(props) => props.paddingMd || "0"};
  }
`;

const PopMobile = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100vh;
  padding: 16px;
  background-color: #fff;
  z-index: 1000;

  button.select_btn {
    position: absolute;
    width: calc(100% - 32px);
    left: 16px;
    bottom: 40px;
  }
`;

export default FranchiseeUserEdit;
