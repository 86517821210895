import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

import {
  Btn,
  Container,
  Contents,
  TextInput,
  Wrapper,
  FlexBox,
  Text,
  TextInputArea,
  ContentContainer,
  Title,
  WhiteBox,
  UploadFileBtn,
  FileImg,
  FileXBtn,
} from "../../Assets/css/commonVer2";
import ico_x from "../../Assets/images/ico_close.png";
import HeaderSub from "../../Components/Header/HeaderSub";
import API from "../../Utiles/API";
import formAPI from "../../Utiles/formAPI";
import Authority from "../../Components/Modal/Authority";
import { AddComma } from "../../Utiles/Custom";
import Loading from "../../Components/Loading";
import Cate2Select from "../../Components/Select/Cate2Select";
import InfoModal from "../../Components/Modal/InfoModal";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import BasicSelect from "../../Components/Select/BasicSelect";

// 상품마스터 > 전체상품관리 > 상품등록

function ProductWrite() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const [values, setValues] = useState({
    barcode_num: "",
    brand: "",
    product_name: "",
    purchase_cost: "",
    purchase_quantity: "",
    purchase_price: "",
    shipping_price: "",
    basic_shipment_quantity: "",
    shipping_cost: "",
    consumer_price: "",
    supplier: "",
    details_description: "",
    product_quantity: "1",
  });
  const [priceValues, setPriceValues] = useState({
    purchase_cost: "",
    purchase_quantity: "",
    purchase_price: "",
    shipping_price: "",
    basic_shipment_quantity: "",
    shipping_cost: "",
    consumer_price: "",
    product_quantity: "1", // 상품 갯수
  });

  const imgRef = useRef();
  const [showFileBtn, setShowFileBtn] = useState(true);
  const [imgFile, setImgFile] = useState([]);
  const [image_path, setImage_path] = useState([]);
  const [orderUnit, setOrderUnit] = useState([]);
  const [cate2Value, setCate2Value] = useState("");
  const [orderUnitValue, setOrderUnitValue] = useState("개");
  const [saveModal, setSaveModal] = useState(false);
  const [listModal, setListModal] = useState(false);

  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [moveToBackModal, setMoveToBackModal] = useState(false);

  // 발주단위 데이터 가져오기
  const getOrderUnit = async () => {
    const { data } = await API.get(`/admin/product/order-unit`);
    if (data.status) {
      let tempOrderUnit = data.results.map((item, idx) => {
        return {
          value: idx,
          text: item,
        };
      });
      setOrderUnit(tempOrderUnit);
    }
  };

  // 상품 등록 api
  const postProduct = async () => {
    let formData = new FormData();
    if (!cate2Value) {
      setTextMsg("상품분류를 선택해주세요.");
      setTextModal(true);
      return;
    }
    if (!values.barcode_num) {
      setTextMsg("바코드번호를 입력해주세요.");
      setTextModal(true);
      return;
    }
    if (!values.product_name) {
      setTextMsg("상품명을 입력해주세요.");
      setTextModal(true);
      return;
    }
    if (!values.purchase_price) {
      setTextMsg("매입가를 입력해주세요.");
      setTextModal(true);
      return;
    }
    if (!values.purchase_quantity) {
      setTextMsg("매입수량을 입력해주세요.");
      setTextModal(true);
      return;
    }
    if (!values.shipping_price) {
      setTextMsg("출하가를 입력해주세요.");
      setTextModal(true);
      return;
    }
    if (!values.basic_shipment_quantity) {
      setTextMsg("기본 출하 수량을 입력해주세요.");
      setTextModal(true);
      return;
    }
    if (!values.shipping_cost) {
      setTextMsg("출하비용이 없습니다.");
      setTextModal(true);
      return;
    }
    if (!values.consumer_price) {
      setTextMsg("소비자가를 입력해주세요.");
      setTextModal(true);
      return;
    }
    if (values.consumer_price % 100 !== 0) {
      setTextMsg("소비자가는 100원 단위로 \n등록할 수 있습니다.");
      setTextModal(true);
      return;
    }
    if (!values.supplier) {
      setTextMsg("공급업체를 입력해주세요.");
      setTextModal(true);
      return;
    }
    if (!orderUnitValue) {
      setTextMsg("발주단위를 입력해주세요.");
      setTextModal(true);
      return;
    }
    if (!values.product_quantity) {
      setTextMsg("상품갯수를 입력해주세요.");
      setTextModal(true);
      return;
    }
    setLoading(true);
    if (values.brand) formData.append("brand", values.brand);
    formData.append("franchise_id", 1);
    formData.append("category1st_id", 1);
    formData.append("category2nd_id", cate2Value);
    formData.append("barcode_num", values.barcode_num);
    formData.append("product_name", values.product_name);
    formData.append("purchase_cost", values.purchase_cost);
    formData.append("purchase_quantity", values.purchase_quantity);
    formData.append("purchase_price", values.purchase_price);
    formData.append("shipping_price", values.shipping_price);
    formData.append("basic_shipment_quantity", values.basic_shipment_quantity);
    formData.append("shipping_cost", values.shipping_cost);
    formData.append("consumer_price", values.consumer_price);
    formData.append("order_unit", orderUnitValue);
    formData.append("supplier", values.supplier);
    formData.append("product_quantity", values.product_quantity);
    if (values.details_description) formData.append("details_description", values.details_description);
    for (var i = 0; i < 5; i++) {
      if (image_path[i]) formData.append("img", image_path[i]);
    }
    await formAPI
      .post(`/admin/product`, formData, {
        header: { "content-type": "multipart/form-data" },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setMoveToBackModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setLoading(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  // 이미지 추가
  const handleImgAdd = (e) => {
    const nowSelectImageList = e.target.files;
    const nowImageURLList = [...imgFile];

    if (imgFile.length + nowSelectImageList.length > 3) return alert("이미지는 최대 3개까지 등록 가능합니다.");

    for (let i = 0; i < nowSelectImageList.length; i++) {
      const nowImageUrl = URL.createObjectURL(nowSelectImageList[i]);
      nowImageURLList.push(nowImageUrl);
      image_path.push(nowSelectImageList[i]);
    }
    setImgFile(nowImageURLList);
  };

  // 이미지 삭제
  const handleImgRemove = (idx) => {
    setImgFile(imgFile.filter((_, index) => index !== idx));
    setImage_path(image_path.filter((_, index) => index !== idx));
  };

  // 입력값 바뀔때
  const handleChange = (e, isPriceValue) => {
    // 금액 관련일때
    if (isPriceValue) {
      const updatedValues = {
        ...values,
        [e.target.name]: e.target.value.replaceAll(",", ""),
      };
      const updatedPriceValues = {
        ...priceValues,
        [e.target.name]: e.target.value ? AddComma(e.target.value.replaceAll(",", "")) : e.target.value,
      };
      // 매입가 관련 계산
      if (e.target.name === "purchase_price" || e.target.name === "purchase_quantity") {
        const price = +document.querySelector("input[name=purchase_price]").value.replaceAll(",", "") || 0;
        const count = +document.querySelector("input[name=purchase_quantity]").value.replaceAll(",", "") || 0;
        if (price && count) {
          const calPrice = price * count;
          updatedValues.purchase_cost = calPrice;
          updatedPriceValues.purchase_cost = AddComma(calPrice);
        } else {
          updatedValues.purchase_cost = "";
          updatedPriceValues.purchase_cost = "";
        }
      } else if (
        // 출하가 관련 계산
        e.target.name === "shipping_price" ||
        e.target.name === "basic_shipment_quantity"
      ) {
        const price = +document.querySelector("input[name=shipping_price]").value.replaceAll(",", "") || 0;
        const count = +document.querySelector("input[name=basic_shipment_quantity]").value.replaceAll(",", "") || 0;
        if (price && count) {
          const calPrice = price * count;
          updatedValues.shipping_cost = calPrice;
          updatedPriceValues.shipping_cost = AddComma(calPrice);
        } else {
          updatedValues.shipping_cost = "";
          updatedPriceValues.shipping_cost = "";
        }
      }
      setValues(updatedValues);
      setPriceValues(updatedPriceValues);
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    if (e === 1) {
      setSaveModal(false);
      navigate(-1, { replace: true });
    } else {
      postProduct();
      setSaveModal(false);
    }
  };

  function onClickBarcodeBtn() {
    if (isMobile) {
      if (window.ReactNativeWebView != undefined) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ method: "barcodeScan" }));
      } else {
        alert("전용앱에서만 사용가능합니다.");
      }
    } else {
      alert("전용앱에서만 사용가능합니다.");
    }
  }

  window.addEventListener("message", function (e) {
    let data;
    try {
      data = JSON.parse(e.data);
      if (data && data.barcodeValue) {
        setValues({ ...values, barcode_num: data.barcodeValue });
      }
    } catch (err) {
      // 예외 처리 코드
      console.error(err);
      return;
    }
  });

  useEffect(() => {
    if (imgFile.length > 2) {
      setShowFileBtn(false);
    } else {
      setShowFileBtn(true);
    }
  }, [imgFile]);

  useEffect(() => {
    getOrderUnit();
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {loading ? <Loading /> : null}
          <HeaderSub title={"상품 등록"} />
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    전체상품 관리 📋
                  </Title>
                )}
                <WhiteBox>
                  <Title color={"#1E1E1E"} weight={"700"} size={"14px"} sizeMd={"20px"}>
                    상품 등록
                  </Title>
                  <Text size={"12px"} sizeMd={"16px"} align={"left"} margin={"6px 0"} marginMd={"10px 0 20px"}>
                    * 상품 이미지를 등록해주세요. 이미지는 최대 3개까지 가능합니다.
                  </Text>
                  <Contents>
                    {isMobile ? (
                      <FlexBox justify={"flex-start"} gap={"10px"} wrap={"wrap"}>
                        {showFileBtn && (
                          <UploadFileBtn>
                            <input
                              type="file"
                              multiple="multiple"
                              accept="image/*"
                              onChange={handleImgAdd}
                              ref={imgRef}
                            />
                            <Text size={"14px"} color={"#C7C7C7"}>
                              이미지 등록
                            </Text>
                            <span>파일 첨부</span>
                          </UploadFileBtn>
                        )}
                        {imgFile?.map((e, i) => (
                          <FileImg key={i}>
                            <FileXBtn onClick={() => handleImgRemove(i)}>
                              <img src={ico_x} />
                            </FileXBtn>
                            <div>
                              <img src={e} />
                            </div>
                          </FileImg>
                        ))}
                      </FlexBox>
                    ) : (
                      <WhiteBox>
                        <FlexBox justify={"flex-start"} gap={"10px"} wrap={"wrap"}>
                          {showFileBtn && (
                            <UploadFileBtn>
                              <input
                                type="file"
                                multiple="multiple"
                                accept="image/*"
                                onChange={handleImgAdd}
                                ref={imgRef}
                              />
                              <Text size={"14px"} color={"#C7C7C7"}>
                                이미지 등록
                              </Text>
                              <span>파일 첨부</span>
                            </UploadFileBtn>
                          )}
                          {imgFile?.map((e, i) => (
                            <FileImg key={i}>
                              <FileXBtn onClick={() => handleImgRemove(i)}>
                                <img src={ico_x} />
                              </FileXBtn>
                              <div>
                                <img src={e} />
                              </div>
                            </FileImg>
                          ))}
                        </FlexBox>
                      </WhiteBox>
                    )}
                    {/* <FileContainer>
                      <FileImgWrap>
                        <FlexBox
                          direction={"column"}
                          directionMd={"row"}
                          justify={"flex-start"}
                          gap={"10px"}
                          gapMd={"20px"}
                        >
                          {showFileBtn && (
                            <UploadFileBtn>
                              <input
                                type="file"
                                multiple="multiple"
                                accept="image/*"
                                onChange={handleImgAdd}
                                ref={imgRef}
                              />
                              이미지 등록
                              <span>파일 첨부</span>
                            </UploadFileBtn>
                          )}
                          <FlexBox gap={"14px"} gapMd={"20px"}>
                            {imgFile?.map((e, i) => (
                              <FileImg key={i}>
                                <FileXBtn onClick={() => handleImgRemove(i)}>
                                  <img src={ico_x} />
                                </FileXBtn>
                                <div>
                                  <img src={e} />
                                </div>
                              </FileImg>
                            ))}
                          </FlexBox>
                        </FlexBox>
                      </FileImgWrap>
                    </FileContainer> */}
                  </Contents>

                  {!isMobile && (
                    <Text
                      align={"left"}
                      weight={"700"}
                      color={"#1e1e1e"}
                      margin={"20px 0 8px"}
                      marginMd={"30px 0 10px"}
                      size={"14px"}
                      sizeMd={"16px"}
                      letterSpacing={"-0.32px"}
                    >
                      바코드 번호
                    </Text>
                  )}

                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    direction={"column"}
                    directionMd={"row"}
                    gap={"10px"}
                    gapMd={"30px"}
                  >
                    <ContentsHalf>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        margin={"20px 0 8px"}
                        marginMd={"30px 0 10px"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        className="d-block d-md-none"
                      >
                        바코드 번호
                      </Text>
                      <FlexBox justify={"flex-start"}>
                        <TextInput
                          style={{ width: "100%" }}
                          type={"text"}
                          placeholder={"바코드 번호를 입력해주세요."}
                          name="barcode_num"
                          value={values.barcode_num}
                          onChange={(e) => {
                            handleChange(e, false);
                          }}
                        />
                      </FlexBox>
                    </ContentsHalf>

                    <Btn
                      width={"100%"}
                      size={"14px"}
                      padding={"8px 10px"}
                      paddingMd={"8px 18px"}
                      lineHeight={"22px"}
                      hoverWeight={"700"}
                      className="d-block d-md-none"
                      onClick={() => onClickBarcodeBtn()}
                    >
                      바코드 스캔
                    </Btn>
                  </FlexBox>

                  <FlexBox
                    justify={"start"}
                    align={"end"}
                    margin={"20px 0 0"}
                    marginMd={"30px 0 0"}
                    direction={"column"}
                    directionMd={"row"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        분류
                      </Text>
                      <Cate2Select
                        idx={1}
                        width={"100%"}
                        cate2Value={cate2Value}
                        setCate2Value={setCate2Value}
                        placeholder={"상품 분류"}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        소비자가
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="consumer_price"
                          value={priceValues.consumer_price}
                          onChange={(e) => {
                            handleChange(e, true);
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "13px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                  </FlexBox>
                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    margin={"20px 0 0 0"}
                    marginMd={"30px 0 0 0"}
                    direction={"column"}
                    directionMd={"row"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        브랜드
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"브랜드명을 입력해주세요."}
                        name="brand"
                        value={values.brand}
                        onChange={(e) => {
                          handleChange(e, false);
                        }}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        상품명
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"상품명 입력해주세요."}
                        name="product_name"
                        value={values.product_name}
                        onChange={(e) => {
                          handleChange(e, false);
                        }}
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBoxGrid3
                    justify={"start"}
                    align={"start"}
                    margin={"20px 0 0 0"}
                    marginMd={"30px 0 0 0"}
                    gap={"10px"}
                    gapMd={"25px"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        매입가
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="purchase_price"
                          value={priceValues.purchase_price}
                          onChange={(e) => {
                            handleChange(e, true);
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "13px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        매입수량
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        align={"right"}
                        placeholder={"0"}
                        name="purchase_quantity"
                        value={priceValues.purchase_quantity}
                        onChange={(e) => {
                          handleChange(e, true);
                        }}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"10px 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        매입비용
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="purchase_cost"
                          value={priceValues.purchase_cost}
                          disabled
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "13px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                  </FlexBoxGrid3>
                  <FlexBoxGrid3
                    justify={"start"}
                    align={"start"}
                    margin={"20px 0 0 0"}
                    marginMd={"30px 0 0 0"}
                    gap={"10px"}
                    gapMd={"25px"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"10px 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        출하가
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="shipping_price"
                          value={priceValues.shipping_price}
                          onChange={(e) => {
                            handleChange(e, true);
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "12px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"10px 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        기본 출하 수량
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        align={"right"}
                        placeholder={"0"}
                        name="basic_shipment_quantity"
                        value={priceValues.basic_shipment_quantity}
                        onChange={(e) => {
                          handleChange(e, true);
                        }}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"10px 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        출하비용
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="shipping_cost"
                          value={priceValues.shipping_cost}
                          disabled
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "12px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                  </FlexBoxGrid3>
                  <FlexBox
                    justify={"start"}
                    align={"end"}
                    margin={"10px 0 0 0"}
                    marginMd={"20px 0 0 0"}
                    direction={"column"}
                    directionMd={"row"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents margin={"20px 0 0"}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        상품갯수
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "38px", width: "100%" }}
                          name="product_quantity"
                          value={priceValues.product_quantity}
                          onChange={(e) => {
                            handleChange(e, true);
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "12px",
                          }}
                        >
                          개입
                        </Text>
                      </div>
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        공급업체
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"공급업체를 입력해주세요."}
                        name="supplier"
                        value={values.supplier}
                        onChange={(e) => {
                          handleChange(e, false);
                        }}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        발주단위
                      </Text>
                      <BasicSelect
                        width={"100%"}
                        selectedValue={orderUnitValue}
                        setSelectedValue={setOrderUnitValue}
                        data={orderUnit}
                        canSelectAll={false}
                        placeholder={"발주단위"}
                      />
                    </Contents>
                  </FlexBox>
                  <Contents margin={"20px 0 0 0"} marginMd={"30px 0 0 0"}>
                    <Text
                      align={"left"}
                      weight={"700"}
                      color={"#1e1e1e"}
                      size={"14px"}
                      sizeMd={"16px"}
                      letterSpacing={"-0.32px"}
                      margin={"0 0 8px"}
                      marginMd={"0 0 10px"}
                    >
                      상품설명
                    </Text>
                    <TextInputArea
                      color={"#2d2d2d"}
                      height={"320px"}
                      style={{ width: "100%" }}
                      type={"text"}
                      placeholder={"내용을 입력해주세요."}
                      name="details_description"
                      value={values.details_description}
                      onChange={(e) => {
                        handleChange(e, false);
                      }}
                    />
                  </Contents>
                </WhiteBox>
                <FlexBox justify={"end"}>
                  <FlexBox flex={"1 0 auto"} flexMd={"unset"} gap={"8px"} gapMd={"10px"}>
                    <Btn
                      width={"100%"}
                      widthMd={"90px"}
                      size={"14px"}
                      color={"#666"}
                      border={"1px solid #666"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      hoverWeight={"700"}
                      hoverColor={"#fff"}
                      hoverBackground={"#C7C7C7"}
                      hoverBorder={"1px solid transparent"}
                      onClick={() => setListModal(true)}
                    >
                      목록
                    </Btn>
                    <Btn
                      width={"100%"}
                      widthMd={"90px"}
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      onClick={() => setSaveModal(true)}
                    >
                      저장
                    </Btn>
                  </FlexBox>
                </FlexBox>
              </Container>
              <ConfirmModal
                modal={listModal}
                setModal={setListModal}
                onSubmit={() => onSubmit(1)}
                description={"상품 등록을 취소하고\n목록화면으로 이동하시겠습니까?"}
              />
              <ConfirmModal
                modal={saveModal}
                setModal={setSaveModal}
                onSubmit={() => onSubmit(2)}
                description={"상품 등록을 완료하시겠습니까?"}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
              <InfoModal
                modal={moveToBackModal}
                setModal={setMoveToBackModal}
                mode={"goBack"}
                description={"등록이 완료되었습니다."}
              />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const ContentsHalf = styled(Contents)`
  max-width: calc(50% - 10px);

  @media all and (max-width: 1024px) {
    max-width: 100%;
  }
`;

const FlexBoxGrid3 = styled(FlexBox)`
  @media all and (max-width: 1024px) {
    flex-wrap: wrap;

    & > div:nth-of-type(1),
    & > div:nth-of-type(2) {
      width: calc(50% - 5px);
    }
    & > div:nth-of-type(3) {
      width: 100%;
    }
  }
`;

export default ProductWrite;
