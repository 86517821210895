import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import XLSX from "xlsx-js-style";

import {
  Btn,
  Wrapper,
  Container,
  ContentContainer,
  FlexBox,
  Text,
  WhiteBox,
  TextBar,
  Title,
} from "../../Assets/css/commonVer2";
import HeaderSub from "../../Components/Header/HeaderSub";
import Authority from "../../Components/Modal/Authority";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import InfoModal from "../../Components/Modal/InfoModal";
import Loading from "../../Components/Loading";
import formAPI from "../../Utiles/formAPI";
import FileUpload from "../../Components/FileUpload/FileUploadVer2";
import API from "../../Utiles/API";

// 혜택관리 > 쿠폰관리 > 등록
/*
 * 쿠폰 1개, 대량 등록 모두 엑셀 파일 첨부로 가능
 * 쿠폰번호 셀 형식 웬만하면 텍스트로 지정해야 함
 * 쿠폰금액은 추후 추가될 수 있음 (추가된 경우 쿠폰관리 페이지 금액 셀렉박스 ListData 에 추가)
 */

export default function BenefitsCouponsWrite() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [loading, setLoading] = useState(false);
  const franName = [];
  const franId = [];

  // 모달들
  const [saveModal, setSaveModal] = useState(false);
  const [listModal, setListModal] = useState(false);
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [moveToBackModal, setMoveToBackModal] = useState(false);

  // 파일 업로드 관련
  const [isSelected, setIsSelected] = useState(false);
  const [fileData, setFileData] = useState([]);

  const [isError, setIsError] = useState(false);

  // API
  const postBulkSelfGoods = async () => {
    const formData = new FormData();
    formData.append("file_name", fileData);

    setLoading(true);

    await formAPI
      .post("/admin/coupon/point", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setMoveToBackModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시 후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error) {
          setIsError(true);
          // 이미 등록된 쿠폰이거나 빈 파일인 경우 등일 때 서버 에러메시지
          const errorMsg = error.response?.data.meta.description;
          // 에러메세지 영어 삭제한 형태로 보여줌 e.g) identifier 한글 에러 메시지 -> identifier 삭제, |를 ,로 변경
          setTextMsg(
            errorMsg?.substring(errorMsg.indexOf(" ") + 1).replaceAll("|", ",  ") || "E01\n잠시 후 다시 시도해주세요.",
          );
          setTextModal(true);
        } else {
          setTextMsg("E01\n잠시 후 다시 시도해주세요.");
          setTextModal(true);
        }
      });
  };

  // 샘플 액셀 다운
  const onClickExcelDown = async () => {
    const res = await API.get("/admin/search-franchise");
    try {
      res.data.result.forEach((item) => {
        franName.push(item.franchise_name);
        franId.push(item.idx);
      });
      // STEP 1: 새로운 workbook을 만든다
      const wb = XLSX.utils.book_new();
      // sheet1
      const header1 = [
        {
          v: "쿠폰번호",
          t: "s",
          s: {
            fill: { fgColor: { rgb: "FFFF00" } },
            font: { bold: true, sz: "14" },
            alignment: { horizontal: "center", vertical: "center" },
          },
        },
        {
          v: "금액",
          t: "s",
          s: {
            fill: { fgColor: { rgb: "FFFF00" } },
            font: { bold: true, sz: "14" },
            alignment: { horizontal: "center", vertical: "center" },
          },
        },
        {
          v: "가맹점명",
          t: "s",
          s: {
            fill: { fgColor: { rgb: "FFFF00" } },
            font: { bold: true, sz: "14" },
            alignment: { horizontal: "center", vertical: "center" },
          },
        },
        {
          v: "코드",
          t: "s",
          s: {
            fill: { fgColor: { rgb: "FFFF00" } },
            font: { bold: true, sz: "14" },
            alignment: { horizontal: "center", vertical: "center" },
          },
        },
      ];

      const header2 = [
        {
          v: "가맹점명",
          t: "s",
          s: {
            fill: { fgColor: { rgb: "00FFFF" } },
            font: { bold: true, sz: "14" },
            alignment: { horizontal: "center", vertical: "center" },
          },
        },
        {
          v: "코드",
          t: "s",
          s: {
            fill: { fgColor: { rgb: "00FFFF" } },
            font: { bold: true, sz: "14" },
            alignment: { horizontal: "center", vertical: "center" },
          },
        },
      ];
      const body2 = [];
      for (let i = 0; i < franName.length; i++) {
        const newBody = [
          {
            v: franName[i],
            t: "s",
            s: { font: { color: { rgb: "333333" } } },
          },
          {
            v: franId[i],
            t: "s",
            s: { font: { color: { rgb: "333333" } } },
          },
        ];
        body2.push(newBody);
      }

      // STEP 3: header와 body로 worksheet를 생성한다.
      const ws1 = XLSX.utils.aoa_to_sheet([header1]);
      const ws2 = XLSX.utils.aoa_to_sheet([header2]);

      // sheet1 셀 너비 지정
      ws1["!cols"] = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];

      // sheet2 각 테이블의 행과 열 지정
      XLSX.utils.sheet_add_aoa(ws2, [...body2], { origin: { c: 0, r: 1 } });

      // worksheet를 workbook에 추가한다.
      XLSX.utils.book_append_sheet(wb, ws1, "sample");
      XLSX.utils.book_append_sheet(wb, ws2, "ref");

      // STEP 4: 엑셀 파일 저장 (2번째 인자에는 파일명을 지정)
      XLSX.writeFile(wb, "쿠폰 등록 샘플 양식.xlsx");
    } catch (e) {
      console.log(e);
    }
  };

  // 목록 or 저장
  const onSubmit = (e) => {
    if (e === 1) {
      setSaveModal(false);
      navigate(-1, { replace: true });
    } else {
      postBulkSelfGoods();
      setSaveModal(false);
    }
  };

  // 저장 클릭 시
  const onClickSave = () => {
    if (!isSelected) {
      setTextMsg("선택된 파일이 없습니다.");
      setTextModal(true);
    } else {
      setSaveModal(true);
    }
  };

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {loading ? <Loading /> : null}
          <HeaderSub title={"쿠폰 등록"} />
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    쿠폰 등록
                  </Title>
                )}
                <WhiteBox>
                  <Title size={"18px"} color={"#2d2d2d"} weight={"700"}>
                    파일 첨부
                  </Title>
                  <Contents marginMd={"20px 0 0"} margin={"8px 0 0"}>
                    <FlexBox direction={"column"} directionMd={"row"} flex={"1 0 auto"}>
                      <FileUpload
                        setIsSelected={setIsSelected}
                        setFileData={setFileData}
                        placeholder={"쿠폰 등록 엑셀 파일을 선택해 주세요."}
                      />
                      {!isMobile && (
                        <Btn
                          width={"100%"}
                          widthMd={"unset"}
                          size={"14px"}
                          padding={"8px 10px"}
                          paddingMd={"8px 18px"}
                          lineHeight={"22px"}
                          hoverWeight={"700"}
                          margin={"10px 0 0 0"}
                          marginMd={"0 0 0 10px"}
                          onClick={onClickExcelDown}
                          style={{ flexShrink: 0 }}
                        >
                          샘플 양식 다운
                        </Btn>
                      )}
                    </FlexBox>
                    {!isMobile && (
                      <div style={{ marginTop: "30px" }}>
                        <Text
                          color={"#666666"}
                          size={"14px"}
                          sizeMd={"18px"}
                          weight={"700"}
                          align={"left"}
                          margin={"0 0 10px 0"}
                          latterSpacing={"-0.28px"}
                          lineHeight={"normal"}
                        >
                          유의사항
                        </Text>
                        <TextBar color={"#6c6c6c"} align={"left"}>
                          첫 번째 시트의{" "}
                          <BoldText>노란색 배경 셀은 필수 등록 사항으로, 모두 기입하셔야 등록이 가능</BoldText>합니다.
                          그 외 빈 셀은 모두 삭제해 주시기 바랍니다.
                        </TextBar>
                        <TextBar color={"#6c6c6c"} align={"left"}>
                          필수 등록 사항 중 <BoldText>'쿠폰번호' 셀의 데이터 포맷</BoldText>은{" "}
                          <BoldText>'텍스트'로 지정</BoldText>해 주시기 바랍니다.
                        </TextBar>
                        <TextBar color={"#6c6c6c"} align={"left"}>
                          파일 규정 형식은 <BoldText>xlsx</BoldText>입니다.
                        </TextBar>
                      </div>
                    )}
                  </Contents>
                </WhiteBox>
                {isMobile && (
                  <div
                    className="greyBox"
                    style={{
                      marginBottom: "20px",
                      padding: "20px 20px",
                    }}
                  >
                    <Text
                      color={"#666666"}
                      size={"14px"}
                      sizeMd={"18px"}
                      weight={"700"}
                      align={"left"}
                      margin={"0 0 10px 0"}
                      latterSpacing={"-0.28px"}
                      lineHeight={"normal"}
                    >
                      유의사항
                    </Text>
                    <TextBar color={"#6c6c6c"} align={"left"}>
                      첫 번째 시트의{" "}
                      <BoldText>노란색 배경 셀은 필수 등록 사항으로, 모두 기입하셔야 등록이 가능</BoldText>합니다. 그 외
                      빈 셀은 모두 삭제해 주시기 바랍니다.
                    </TextBar>
                    <TextBar color={"#6c6c6c"} align={"left"}>
                      필수 등록 사항 중 <BoldText>'쿠폰번호' 셀의 데이터 포맷</BoldText>은{" "}
                      <BoldText>'텍스트'로 지정</BoldText>해 주시기 바랍니다.
                    </TextBar>
                    <TextBar color={"#6c6c6c"} align={"left"}>
                      파일 규정 형식은 <BoldText>xlsx</BoldText>입니다.
                    </TextBar>
                  </div>
                )}
                <FlexBox justify={"end"}>
                  <FlexBox gap={"10px"}>
                    <Btn
                      background={"#C7C7C7"}
                      border={"#C7C7C7"}
                      color={"#fff"}
                      radius={"6px"}
                      padding={"9px 19px"}
                      weight={"700"}
                      size={"12px"}
                      sizeMd={"14px"}
                      lineHeight={"22px"}
                      onClick={() => setListModal(true)}
                    >
                      목 록
                    </Btn>
                    <Btn
                      background={"#FF4A4A"}
                      border={"#FF4A4A"}
                      color={"#fff"}
                      radius={"6px"}
                      weight={"700"}
                      size={"12px"}
                      sizeMd={"14px"}
                      padding={"9px 19px"}
                      lineHeight={"22px"}
                      onClick={onClickSave}
                    >
                      저 장
                    </Btn>
                  </FlexBox>
                </FlexBox>
              </Container>
              <ConfirmModal
                modal={listModal}
                setModal={setListModal}
                onSubmit={() => onSubmit(1)}
                description={"쿠폰 등록을 취소하고\n목록화면으로 이동하시겠습니까?"}
              />
              <ConfirmModal
                modal={saveModal}
                setModal={setSaveModal}
                onSubmit={() => onSubmit(2)}
                description={"쿠폰 등록을 완료하시겠습니까?"}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} isLong={isError} />
              <InfoModal
                modal={moveToBackModal}
                setModal={setMoveToBackModal}
                description={"쿠폰 등록이 완료되었습니다."}
                mode={"goBack"}
              />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const Contents = styled.div`
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  background-color: ${(props) => props.background || ""};
  width: ${(props) => props.width || "100%"};
  border-radius: ${(props) => props.radius || "8px"};
`;

const BoldText = styled.span`
  font-weight: bold;
`;
