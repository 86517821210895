import React, { useState } from "react";
import styled from "styled-components";
import close from "../../Assets/images/close.png";
import { FlexBox, Btn, Text } from "../../Assets/css/common";
import ConfirmModal from "./ConfirmModalVer2";
import { AddComma, str } from "../../Utiles/Custom";
import { Line } from "../../Assets/css/commonVer2";

// 리뉴얼 UI : 사용O

export default function RefundModal(props) {
  const { setModal, onSubmit = () => {}, refundData } = props;

  const [saveModal, setSaveModal] = useState(false);

  const onClose = () => {
    setModal(false);
  };

  return (
    <>
      {saveModal ? (
        <ConfirmModal
          modal={saveModal}
          setModal={setSaveModal}
          title={"해지 신청을 하시겠습니까?"}
          description={"해지 신청 즉시 등록된 상품은 판매중지\n되며 취소할 수 없습니다."}
          confirmData={`예상 환불금액 : ${AddComma(parseInt(refundData.refundAmount))}`}
          onSubmit={onSubmit}
        />
      ) : (
        <ModalBg>
          <ModalConts>
            <CancelBtn onClick={onClose}>
              <img src={close} />
            </CancelBtn>
            <Text
              color={"#2D2D2D"}
              size={"20px"}
              weight={"700"}
              lineHeight={"22px"}
              style={{
                whiteSpace: "pre-wrap",
                marginBottom: "16px",
              }}
            >
              해지신청
            </Text>
            <DataBox>
              <FlexBox justify={"space-between"}>
                <Text color={"#666"} size={"12px"} weight={"400"} lineHeight={"18px"}>
                  해지 요청일
                </Text>
                <DataText>{refundData.today}</DataText>
              </FlexBox>
              <FlexBox justify={"space-between"}>
                <Text color={"#666"} size={"12px"} weight={"400"} lineHeight={"18px"}>
                  결제 금액
                </Text>
                <DataText>{AddComma(refundData.payedPrice)}원</DataText>
              </FlexBox>
              <FlexBox justify={"space-between"}>
                <Text color={"#666"} size={"12px"} weight={"400"} lineHeight={"18px"}>
                  잔여 구독 일 수
                </Text>
                <DataText>{refundData.remainingDays}일</DataText>
              </FlexBox>

              {/* <FlexBox justify={"space-between"}>
                <Text
                  color={"#666"}
                  size={"12px"}
                  weight={"400"}
                  lineHeight={"18px"}
                >
                  결제 금액 / 30일
                </Text>
                <DataText>{AddComma(refundData.costPerDay)}원</DataText>
              </FlexBox> */}
              <FlexBox justify={"space-between"}>
                <Text color={"#666"} size={"12px"} weight={"400"} lineHeight={"18px"}>
                  해지 슬롯 수량
                </Text>
                <DataText>{refundData.refundSlotCount}개</DataText>
              </FlexBox>
              <Line />
              <FlexBox align={"flex-end"} justify={"space-between"}>
                <Text color={"#FF4A4A"} size={"12px"} weight={"400"} lineHeight={"18px"}>
                  총 환불 금액
                </Text>
                <ToTalText>
                  {AddComma(refundData.refundAmount)}
                  <span>원</span>
                </ToTalText>
              </FlexBox>
            </DataBox>
            <FlexBox margin={"20px 0 0"} marginMd={"15px 0 0"}>
              <Btn
                background={"#f2f2f2"}
                color={"#666"}
                radius={"6px"}
                weight={"500"}
                margin={"0 10px 0 0"}
                lineHeight={"22px"}
                size={"12px"}
                sizeMd={"14px"}
                padding={"9px 19px"}
                paddingMd={"9px 19px"}
                onClick={onClose}
              >
                취소
              </Btn>
              <Btn
                background={"#FF4A4A"}
                color={"#fff"}
                radius={"6px"}
                weight={"700"}
                lineHeight={"22px"}
                size={"12px"}
                sizeMd={"14px"}
                padding={"9px 19px"}
                paddingMd={"9px 19px"}
                onClick={() => setSaveModal(true)}
              >
                확 인
              </Btn>
            </FlexBox>
          </ModalConts>
        </ModalBg>
      )}
    </>
  );
}

const ModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

const ModalConts = styled.div`
  background-color: #fff;
  max-width: 360px;
  max-height: 600px;
  width: 100%;
  padding: 44px 30px 26px;
  border-radius: 8px;
  position: relative;

  @media all and (max-width: 1024px) {
    max-width: 280px; // 390
    padding: 40px 20px 30px;
    margin: 0 20px;
  }
`;

const DataBox = styled.div`
  background-color: #f2f2f2;
  border-radius: 6px;
  padding: 16px 16px 20px;
  font-size: 12px;
  line-height: 18px;

  div + div {
    margin-top: 10px;
  }
`;

const DataText = styled(Text)`
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`;
const ToTalText = styled(Text)`
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 18px;
  color: #ff4a4a;
  span {
    font-size: 14px;
  }
`;

const CancelBtn = styled.button`
  position: absolute;
  right: 17.5px;
  top: 17.5px;

  @media all and (max-width: 1024px) {
    top: 12px;
    right: 12px;
  }
`;
