import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { TextInput } from "../../Assets/css/common";
import { getProductName } from "../../Utiles";
import { SearchList, SelectWrap, SearchInput, SelectContainer } from "../../Assets/css/commonVer2";
import { handleKeyDown } from "../../Utiles/Custom";

// 상품명 검색
// 반품상품등록시, 주문내역 상품 추가 시

function SearchInputPop(props) {
  const { data, onSelect, uiType, width } = props;
  const searchRef = useRef(null);

  const [showSearchList, setShowSearchList] = useState(false);
  const [inputText, setInputText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchProductItems, setSearchProductItems] = useState([]); // 상품명 2글자 검색후 검색 결과 담을 리스트

  //상품 이름 검색하는 API
  let timer;
  function callGetProductName(parameter) {
    setSearchProductItems([]);
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      getProductName(parameter).then((res) => {
        if (res.length > 0) {
          // setSearchProductItems(res);
          setSearchResults(res);
          setShowSearchList(true);
        } else {
          setSearchResults([]);
          setShowSearchList(false);
        }
      });
    }, 300);
  }

  const handleItemClick = (item) => {
    setShowSearchList(false);
    setInputText(item.product_name);
    onSelect(item);
  };

  // 다른 곳 클릭 시 목록 닫히도록
  useEffect(() => {
    function handleOutside(e) {
      if (searchRef.current && !searchRef.current.contains(e.target)) {
        setShowSearchList(false);
      }
    }
    document.addEventListener("mousedown", handleOutside);
    return () => {
      document.removeEventListener("mousedown", handleOutside);
    };
  }, [searchRef]);

  return (
    <>
      <SelectContainer ref={searchRef} width={width || "unset"}>
        {uiType === 1 ? (
          <SearchInput
            borderBottom={"1px solid #666666"}
            borderBottomColor={"#ff4a4a"}
            transition={"border-color 0.3s ease-in-out"}
            placeholder="상품명을 검색해보세요."
            value={inputText}
            onChange={(e) => {
              setInputText(e.target.value);
              if (e.target.value.length > 1) {
                callGetProductName(e.target.value);
              }
            }}
          />
        ) : (
          <TextInput
            color={"#2d2d2d"}
            value={inputText}
            weight={"230"}
            align={"left"}
            style={{ width: "100%" }}
            onInput={(e) => {
              setInputText(e.target.value);
              if (e.target.value.length > 1) {
                callGetProductName(e.target.value);
              }
            }}
            placeholder="상품명을 검색해보세요."
          />
        )}

        {showSearchList && (
          <SelectWrap width={"100%"}>
            {searchResults?.map((item, idx) => (
              <SearchList key={idx} value={item.value}>
                <li
                  onClick={() => {
                    handleItemClick(item);
                  }}
                >
                  <span> {item.product_name}</span>
                </li>
              </SearchList>
            ))}
          </SelectWrap>
        )}
      </SelectContainer>
    </>
  );
}

// const SearchList = styled.ul`
//   position: absolute;
//   top: calc(100% - 5px);
//   width: 100%;
//   padding-top: 4px;
//   border: 1px solid #c0c0c0;
//   border-top: 0;
//   border-radius: 0 0 6px 6px;
//   background-color: #fff;
//   max-height: 400px;
//   overflow-y: auto;
//   z-index: 2;

//   li {
//     border-top: 1px solid #eaeaea;

//     button {
//       display: block;
//       width: 100%;
//       padding: 10px 12px;
//       font-size: 14px;
//       text-align: left;

//       &:hover {
//         color: #dd1212;
//         background-color: #fcb1b1;
//       }
//     }
//   }

//   @media all and (max-width: 767px) {
//     max-height: 240px;

//     li button {
//       font-size: 12px;
//       padding: 9px 10px;
//     }
//   }
// `;

export default SearchInputPop;
