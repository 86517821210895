import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { utils, writeFile } from "xlsx";

import {
  ContentContainer,
  Container,
  FlexBox,
  Title,
  Wrapper,
  Th,
  Td,
  Text,
  TableWrap,
  Btn,
  WhiteBox,
  OrderAddrBox,
  HalfDiv,
} from "../../Assets/css/commonVer2";
import "../../Assets/css/DatePicker.css";
import HeaderSub from "../../Components/Header/HeaderSub";
import Authority from "../../Components/Modal/Authority";
import InfoModal from "../../Components/Modal/InfoModal";
import { AddComma, hypen } from "../../Utiles/Custom";
import { SHOP_ORDER_STATUS_LIST } from "../../Utiles/ListData";
import { getShopOrderDetail } from "../../Utiles";
import ShopDeliveryModal from "./Components/ShopDeliveryModal";
import ShopOrderCancelModal from "./Components/ShopOrderCancelModal";

// 쇼핑몰 > 주문내역 조회 및 관리 > 주문 상세
/*
주문 건 상태별 조건부 렌더링
1. 주문취소 버튼
: [배송 전] 인 경우만
2. 배송정보수정 버튼
: [배송 중] 인 경우만
3. 배송정보 > 택배사, 운송장번호 노출
: [배송 전, 배송 중] 인 경우
*/

function ShopOrderView() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const { idx } = useParams();

  // 모달
  const [cancelInfoModal, setCancelInfoModal] = useState(false);
  const [onDeliveryModal, setOnDeliveryModal] = useState(false);

  // 주문 정보
  const [orderInfo, setOrderInfo] = useState({});

  // 상품 정보
  const [shopProductList, setShopProductList] = useState([]);

  // 배송 정보 수정 데이터
  const [editCourierCompany, setEditCourierCompany] = useState(""); // 택배사명
  const [editTrackingNumber, setEditTrackingNumber] = useState(""); // 운송장번호

  // 소비자ID(주문취소 시 보낼 데이터)
  const [consumerId, setConsumerId] = useState("");

  // API
  // 주문 상세 내역 조회
  const getData = async () => {
    getShopOrderDetail(idx).then((res) => {
      if (res.status) {
        setOrderInfo(res.results);
        setShopProductList(res.results.shoppingmall_order_histories);
        // 배송 정보 수정 시 필요한 데이터 저장
        setEditCourierCompany(res.results.courier_company);
        setEditTrackingNumber(res.results.tracking_number);
        // 주문 취소 시 필요한 소비자 id 저장
        setConsumerId(res.results.consumer_id);
      }
    });
  };

  function ExportHandler() {
    const data = [];
    const excelTableHead = [["No", "상품명", "바코드번호", "주문수량", "소비자가", "총금액"]];

    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);

    utils.sheet_add_aoa(ws, excelTableHead);

    shopProductList.forEach((item, idx) => {
      data.push({
        row_num: `${idx + 1}`,
        product_name: `${item.product_name}`,
        barcode_num: `${item.barcode_num !== null ? item.barcode_num : "-"}`,
        quantity: `${item.quantity}`,
        consumer_price: `${item.consumer_price}`,
        totalPrice: `${item.totalPrice}`,
      });
    });

    utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Report");

    writeFile(wb, `주문내역_${orderInfo.order_user_name}_${orderInfo.createdAt.slice(0, 10)}.xlsx`);
  }

  useEffect(() => {
    getData();
  }, []);
  
  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          <HeaderSub title={"주문내역 상세"} />
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                <Title
                  size={"14px"}
                  sizeMd={"26px"}
                  color={"#1E1E1E"}
                  weight={"700"}
                >
                  주문일자 : {(orderInfo.createdAt?.slice(0, 10))} | 주문번호 : {idx}
                </Title>
                <WhiteBox>
                  <FlexBox
                    justify={"space-between"}
                    align={"center"}
                    gap={"10px"}
                    margin={"0 0 20px"}
                  >
                    <Title
                      size={"14px"}
                      sizeMd={"18px"}
                      color={"#1E1E1E"}
                      weight={"700"}
                    >
                      상품 정보
                    </Title>
                    <FlexBox
                      gap={"10px"}
                      justify={"flex-end"}
                    >
                      {!isMobile && (
                        <Btn
                          size={"14px"}
                          color={"#666"}
                          border={"1px solid #666"}
                          padding={"8px 18px"}
                          lineHeight={"22px"}
                          hoverWeight={"700"}
                          hoverColor={"#fff"}
                          hoverBackground={"#C7C7C7"}
                          hoverBorder={"1px solid transparent"}
                          onClick={ExportHandler}
                        >
                          엑셀 다운
                        </Btn>
                      )}
                      {orderInfo.status === "before-delivery" && (
                        <Btn
                          size={"14px"}
                          paddingMd={"8px 18px"}
                          padding={"4px 10px"}
                          lineHeight={"22px"}
                          hoverWeight={"700"}
                          onClick={() => setCancelInfoModal(true)}
                          >
                          주문 취소
                        </Btn>
                      )}
                    </FlexBox>
                  </FlexBox>
                  {isMobile ? (
                    <FlexBox
                      margin={"0 0 10px"}
                      gap={"10px"}
                      direction={"column"}
                      width={"100%"}
                    >
                      <Text
                        width={"100%"}
                        color={"#FF4A4A"}
                        size={"14px"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        style={{
                          flexShrink: 0
                        }}
                      >
                        품목 : <span style={{ fontWeight: "700" }}>{AddComma(orderInfo.total_count)}개</span>
                      </Text>
                      <Text
                        width={"100%"}
                        color={"#FF4A4A"}
                        size={"14px"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        style={{
                          flexShrink: 0
                        }}
                      >
                        금액 합계: <span style={{ fontWeight: "700" }}>{AddComma(orderInfo.total_amount)}원</span>
                      </Text>
                    </FlexBox>
                  ) : (
                    <FlexBox
                      margin={"0 0 30px"}
                      gap={"10px"}
                      justify={"start"}
                    >
                      <Text
                        width={"unset"}
                        color={"#FF4A4A"}
                        size={"16px"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        style={{
                          flexShrink: 0
                        }}
                      >
                        품목 : <span style={{ fontWeight: "700" }}>{AddComma(orderInfo.total_count)}개</span>
                      </Text>
                      <Text
                        width={"unset"}
                        color={"#FF4A4A"}
                        size={"16px"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        style={{
                          flexShrink: 0
                        }}
                      >
                        금액 합계: <span style={{ fontWeight: "700" }}>{AddComma(orderInfo.total_amount)}원</span>
                      </Text>
                    </FlexBox>
                  )}
                  <TableWrap>
                    <table style={{ width: "100%" }}>
                      <colgroup>
                        <col style={{ width: "10%" }} />
                        <col />
                        <col style={{ width: "16%" }} />
                        <col style={{ width: "14%" }} />
                        <col style={{ width: "14%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>No</Th>
                          <Th>상품명</Th>
                          <Th>바코드번호</Th>
                          <Th>주문수량</Th>
                          <Th>소비자가</Th>
                          <Th>총 금액</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {shopProductList?.length > 0 ? (
                          shopProductList?.map((item, key) => (
                            <tr key={key}>
                              <Td>{key + 1}</Td>
                              <Td>{item.product_name}</Td>
                              <Td>{item.barcode_num ? item.barcode_num : "-"}</Td>
                              <Td>{item.quantity}</Td>                              
                              <Td>{AddComma(item.consumer_price)}원</Td>
                              <Td>{AddComma(item.totalPrice)}원</Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={6}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                </WhiteBox>
                <FlexBox justify={"space-between"} align={"flex-start"} wrap={"wrap"}>
                  <HalfDiv>
                    <WhiteBox margin={"0 0"} marginMd={"0 0"}>
                      <Title
                        color={"#1E1E1E"}
                        weight={"700"}
                        size={"16px"}
                        sizeMd={"18px"}
                        style={{
                          paddingBottom: "10px",
                          borderBottom: "1px solid #666",
                        }}
                      >
                        고객 정보
                      </Title>
                      <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          고객명
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          {orderInfo ? orderInfo.order_user_name: "-"}
                        </Text>
                      </OrderAddrBox>
                      <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          휴대폰번호
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          {orderInfo ? hypen(orderInfo.order_user_phone_num): "-"}
                        </Text>
                      </OrderAddrBox>
                      <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          주소
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          {orderInfo ? orderInfo.address: "-"}
                        </Text>
                      </OrderAddrBox>
                    </WhiteBox>
                    <WhiteBox margin={"20px 0 0"}>
                      <FlexBox
                        justify={"space-between"}
                        style={{
                          paddingBottom: "10px",
                          borderBottom: "1px solid #666",
                        }}
                      >
                        <Title
                          color={"#1E1E1E"}
                          weight={"700"}
                          size={"16px"}
                          sizeMd={"18px"}
                          >
                          배송 정보
                        </Title>
                        {orderInfo.status === "on-delivery" && (
                          <Btn
                          size={"14px"}
                          padding={"8px 18px"}
                          lineHeight={"22px"}
                          onClick={() => {
                            setOnDeliveryModal(true);
                          }}
                          >
                            배송 정보 수정
                          </Btn>
                        )}
                      </FlexBox>
                      <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          배송상태
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          {orderInfo.status
                            ? SHOP_ORDER_STATUS_LIST.find((i) => i.value === orderInfo.status)?.text || "-"
                            : "-"}
                        </Text>
                      </OrderAddrBox>
                      {(orderInfo.status === "on-delivery" || orderInfo.status === "over-delivery") && (
                        <>
                          <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                            <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                              택배사
                            </Text>
                            <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                              {orderInfo.courier_company ? orderInfo.courier_company : ""}
                            </Text>
                          </OrderAddrBox>
                          <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                            <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                              운송장번호
                            </Text>
                            <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                              {orderInfo.tracking_number ? orderInfo.tracking_number : ""}
                            </Text>
                          </OrderAddrBox>
                        </>
                      )}
                    </WhiteBox>
                  </HalfDiv>
                  <HalfDiv>
                    <WhiteBox margin={"20px 0 0"} marginMd={"0 0"}>
                      <Title
                        color={"#1E1E1E"}
                        weight={"700"}
                        size={"16px"}
                        sizeMd={"18px"}
                        style={{
                          paddingBottom: "10px",
                          borderBottom: "1px solid #666",
                        }}
                      >
                        결제 정보
                      </Title>
                      <Text
                        color={"#1E1E1E"}
                        weight={"500"}
                        align={"left"}
                        padding={"20px 0 0"}
                        size={"14px"}
                        sizeMd={"18px"}
                      >
                        총 결제 금액
                      </Text>
                      <WhiteBox marginMd={"20px 0 0"} paddingMd="20px">
                        <FlexBox justify={"space-between"}>
                          <Text color={"#1E1E1E"} weight={"500"} size={"14px"} sizeMd={"18px"}>
                            상품 가격
                          </Text>
                          <Text color={"#1E1E1E"} size={"14px"} sizeMd={"18px"}>
                            {AddComma(orderInfo.total_amount)}원
                          </Text>
                        </FlexBox>
                        <FlexBox justify={"space-between"} padding={"20px 0 0"}>
                          <Text color={"#1E1E1E"} weight={"500"} size={"14px"} sizeMd={"18px"}>
                            택배비
                          </Text>
                          <Text color={"#1E1E1E"} size={"14px"} sizeMd={"18px"}>
                            {AddComma(orderInfo.courier_charges)}원
                          </Text>
                        </FlexBox>
                        <FlexBox
                          justify={"space-between"}
                          margin={"20px 0 0"}
                          padding={"20px 0 0"}
                          style={{
                            borderTop: "1px solid #969696",
                          }}
                        >
                          <Text color={"#1E1E1E"} weight={"500"} size={"14px"} sizeMd={"18px"}>
                            결제 금액
                          </Text>
                          <Text color={"#1E1E1E"} weight={"700"} size={"14px"} sizeMd={"18px"}>
                            {AddComma(orderInfo.total_amount + orderInfo.courier_charges)}원
                          </Text>
                        </FlexBox>
                      </WhiteBox>
                    </WhiteBox>
                  </HalfDiv>
                </FlexBox>
              </Container>
              <ShopDeliveryModal
                gubun={"edit"}
                modal={onDeliveryModal}
                setModal={setOnDeliveryModal}
                title={"배송 정보 수정"}
                idx={idx}
                editCourierCompany={editCourierCompany}
                setEditCourierCompany={setEditCourierCompany}
                editTrackingNumber={editTrackingNumber}
                setEditTrackingNumber={setEditTrackingNumber}
              />
              <ShopOrderCancelModal
                modal={cancelInfoModal}
                setModal={setCancelInfoModal}
                title={"주문 취소"}
                idx={idx}
                consumerId={consumerId}
                setCancelInfoModal={setCancelInfoModal}
              />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

export default ShopOrderView;