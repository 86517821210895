import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { FlexBox, Btn, Text, TextInput } from "../../../Assets/css/commonVer2";
import close from "../../../Assets/images/close.png";
import InfoModal from "../../../Components/Modal/InfoModal";
import ConfirmModal from "../../../Components/Modal/ConfirmModalVer2";
import Loading from "../../../Components/Loading";
import { cancelShopOrder } from "../../../Utiles";

// 쇼핑몰 > 주문 > 상세 주문취소 모달

export default function ShopOrderCancelModal(props) {
  const {
    modal,
    setModal,
    title,
    idx,
    consumerId,
  } = props;

  const [loading, setLoading] = useState(false);

  // 모달
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [cancelOrderModal, setCancelOrderModal] = useState(false);

  const [reason, setReason] = useState(""); // 취소사유
  const [detailReason, setDetailReason] = useState(""); // 취소상세사유

  // API
  // 주문취소
  const onSubmit = async () => {
    setLoading(true);
    
    cancelShopOrder(
      idx,
      consumerId,
      reason,
      detailReason,
      )
      .then((res) => {
        setLoading(false);
        if (res.status) {
          setCancelOrderModal(false);
          setTextMsg("주문이 정상적으로 취소되었습니다.");
          setTextModal(true);
        } else {
          setCancelOrderModal(false);
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setLoading(false);
        setCancelOrderModal(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const onClose = () => {
    setModal(false);
    window.location.reload();
  };

  return (
    <>
      {loading ? <Loading /> : null}
      {modal ? (
        <ModalBg>
          <ModalConts>
            <CancelBtn onClick={onClose}>
              <img src={close} />
            </CancelBtn>
            <FlexBox
              direction={"column"}
              justify={"flex-start"}
              style={{ height: "220px" }}
            >
              <Text
                color={"#1E1E1E"}
                size={"20px"}
                weight={"700"}
                style={{
                  whiteSpace: "pre-wrap",
                  lineHeight: "22px",
                  marginBottom: "20px",
                }}
              >
                {title}
              </Text>
              <>
                <TextInput
                  type={"text"}
                  name={"reason"}
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                  margin={"0 0 10px"}
                  placeholder="취소 사유를 입력해주세요."
                />
                <TextInput
                  type={"text"}
                  name={"detailReason"}
                  value={detailReason}
                  onChange={(e) => {
                    setDetailReason(e.target.value);
                  }}
                  placeholder="취소 상세사유를 입력해주세요."
                />
              </>
            </FlexBox>
            <FlexBox margin={"20px 0 0"} marginMd={"15px 0 0"}>
              <Btn
                background={"#f2f2f2"}
                border={"unset"}
                color={"#666"}
                radius={"6px"}
                weight={"500"}
                margin={"0 10px 0 0"}
                lineHeight={"22px"}
                size={"12px"}
                sizeMd={"14px"}
                padding={"9px 19px"}
                paddingMd={"9px 19px"}
                onClick={onClose}
              >
                취소
              </Btn>
              <Btn
                background={"#FF4A4A"}
                color={"#fff"}
                radius={"8px"}
                weight={"700"}
                size={"14px"}
                padding={"9px 19px"}
                onClick={() => {
                  setCancelOrderModal(true);
                }}
              >
                확 인
              </Btn>
            </FlexBox>
            <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} mode={"refresh"}/>
            <ConfirmModal
              modal={cancelOrderModal}
              setModal={setCancelOrderModal}
              onSubmit={onSubmit}
              description={"주문을 취소하시겠습니까?\n취소된 주문은 복구할 수 없습니다."}
            />
          </ModalConts>
        </ModalBg>
      ) : (
        ""
      )}
    </>
  );
};

const ModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

const ModalConts = styled.div`
  background-color: #fff;
  max-width: 380px;
  width: 100%;
  padding: 60px 40px 40px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #f2f2f2;

  @media all and (max-width: 1024px) {
    max-width: 280px; // 390
    padding: 40px 20px 30px;
    margin: 0 20px;
  }
`;

const CancelBtn = styled.button`
  position: absolute;
  right: 17.5px;
  top: 17.5px;

  @media all and (max-width: 1024px) {
    top: 12px;
    right: 12px;
  }
`;
