// 외부 모듈
import React from "react";

// 내부 모듈
import { TableWrap, Td, Th } from "../../../Assets/css/commonVer2";
import { AddComma } from "../../../Utiles/Custom";

export default function SalesTable({ revenueItems }) {
  return (
    <TableWrap>
      <table style={{ width: "100%", minWidth: "1024px" }}>
        <thead>
          <tr style={{ borderRight: "1px solid #c0c0c0" }}>
            <Th>가맹점</Th>
            <Th>1차분류</Th>
            <Th>2차분류</Th>
            <Th>상품명</Th>
            <Th>바코드번호</Th>
            <Th>수량</Th>
            <Th>소비자가</Th>
            <Th>날짜</Th>
          </tr>
        </thead>
        <tbody>
          {revenueItems?.length > 0 ? (
            revenueItems?.map((item, key) => (
              <tr key={key}>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.franchise_name}</Td>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.category1st_name}</Td>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.category2nd_name}</Td>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.product_name}</Td>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.barcode_num}</Td>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{AddComma(item.quantity)}개</Td>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{AddComma(item.consumer_price)}원</Td>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.createdAt || "-"}</Td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={8}
                style={{ borderTop: "1px solid #e1e1e1", textAlign: "center", padding: 10, fontSize: "14px" }}
              >
                데이터가 없습니다.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </TableWrap>
  );
}
