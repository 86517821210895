import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {
  HeaderContainer,
  Title,
  WhiteBox,
  SearchInput,
  ContentContainer,
  Btn,
  CheckIcon,
  CheckLabel,
  Container,
  FlexBox,
  TableWrap,
  Td,
  Th,
  Wrapper,
} from "../../Assets/css/commonVer2";
import Authority from "../../Components/Modal/Authority";
import Pagination from "../../Components/Paging/Pagination";
import { AddComma, handleKeyDown } from "../../Utiles/Custom";
import HandleBack from "../../Components/HandleBack";
import API from "../../Utiles/API";
import Loading from "../../Components/Loading";
import { UNIT_10_TO_30_LIST, SEARCH_TYPE_LIST } from "../../Utiles/ListData";
import Header from "../../Components/Header/HeaderVer2";
import BasicSelect from "../../Components/Select/BasicSelect";
import ProductSearchInputVer2 from "../../Components/SearchInput/ProductSearchInputVer2";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import InfoModal from "../../Components/Modal/InfoModal";

export default function Bundle() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();
  const { handlePopstate } = HandleBack();
  const [checkItems, setCheckItems] = useState([]);

  const [bundleList, setBundleList] = useState([]);
  const [pagination, setPagination] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchList, setShowSearchList] = useState(false);

  const [modal, setModal] = useState(false); // 등록 해제 확인 모달
  const [textModal, setTextModal] = useState(false); // 텍스트 모달 스테이트
  const [refreshModal, setRefreshModal] = useState(false); // 텍스트 모달 스테이트
  const [textMsg, setTextMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const parsedBundlePageState = sessionStorage.getItem("bundlePageState")
    ? JSON.parse(sessionStorage.getItem("bundlePageState"))
    : {};

  const [inputValue, setInputValue] = useState(
    parsedBundlePageState ? (parsedBundlePageState?.inputValue ? parsedBundlePageState?.inputValue : "") : "",
  );

  const [filter, setFilter] = useState(
    parsedBundlePageState
      ? parsedBundlePageState?.filter
        ? parsedBundlePageState?.filter
        : "barcode_num"
      : "barcode_num",
  );

  const [currentPage, setCurrentPage] = useState(
    parsedBundlePageState ? (parsedBundlePageState?.currentPage ? parsedBundlePageState?.currentPage : 1) : 1,
  );

  const [pageSize, setPageSize] = useState(
    parsedBundlePageState ? (parsedBundlePageState?.pageSize ? parsedBundlePageState?.pageSize : 10) : 10,
  );

  // 수정, 등록을 갈 때 sessionStorage에 저장할 객체
  let bundlePageState = {
    currentPage,
    pageSize,
    inputValue,
    filter,
  };

  const start = (currentPage - 1) * pageSize;

  // 묶믕상품 목록 불러오기
  const getBundleList = async () => {
    let api_link = `/admin/super-code?page=${currentPage}&unit=${pageSize}&block=10`;
    if (inputValue) api_link += `&filter_type=${filter}&filter_value=${inputValue}`;

    await API.get(api_link)
      .then((res) => {
        if (res.data.status) {
          setPagination(res.data.pagination);
          setBundleList(res.data.results);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(function (error) {
        setTextMsg(error.response.data.meta.description || "E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  // 묶음 상품 해제
  const removeBundleProducts = async () => {
    if (checkItems.length === 1) {
      setLoading(true);
      try {
        const res = await API.delete(`/admin/super-code?super_code_id=${checkItems}`);
        setLoading(false);
        if (res.data.status) {
          setModal(false);
          setTextMsg("해제되었습니다.");
          setRefreshModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      } catch (error) {
        setLoading(false);
        setTextMsg(error.response.data.meta.description || "E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    }
  };

  // 아이템 체크
  const handleChangeChk = (checked, id) => {
    if (checked) {
      setCheckItems((prev) => [...prev, id]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  // 아이템 전체 체크
  const handleChangeAllChk = (checked) => {
    if (checked) {
      const idArray = [];
      bundleList.forEach((el) => idArray.push(el.super_code_id));
      setCheckItems(idArray);
    } else {
      setCheckItems([]);
    }
  };

  // 해제 클릭
  const handleClickRemoveBundle = () => {
    if (checkItems.length === 0) {
      setTextMsg("해제할 묶음 상품을 선택해주세요.");
      setTextModal(true);
    } else if (checkItems.length === 1) {
      setModal(true);
    } else {
      setTextMsg("하나만 선택해주세요.");
      setTextModal(true);
    }
  };

  // 수정 클릭
  const handleClickEditProduct = () => {
    if (checkItems.length === 0) {
      setTextMsg("수정할 묶음 상품을 선택해주세요.");
      setTextModal(true);
      return;
    }
    if (checkItems.length > 1) {
      setTextMsg("하나만 선택해주세요.");
      setTextModal(true);
      return;
    }
    if (checkItems.length === 1) {
      const foundItem = bundleList.find((item) => item.super_code_id === checkItems[0]);
      navigate(`/product/bundle/edit/${foundItem.super_code_id}`);
      let jsonString = JSON.stringify(bundlePageState);
      sessionStorage.setItem("bundlePageState", jsonString);
    }
  };

  // 등록 클릭
  const handleClickAddProduct = () => {
    navigate("/product/bundle/write");
    let jsonString = JSON.stringify(bundlePageState);
    sessionStorage.setItem("bundlePageState", jsonString);
  };

  const handleSearch = () => {
    setSearchResults([]);
    setShowSearchList(false);
    setCurrentPage(1);
    getBundleList();
  };

  // 페이지 이동
  const movePage = (e) => {
    setCurrentPage(e);
  };

  useEffect(() => {
    if (!parsedBundlePageState?.currentPage && currentPage !== 1) {
      setCurrentPage(1);
    } else {
      getBundleList();
      setCheckItems([]);
    }
  }, [pageSize]);

  useEffect(() => {
    getBundleList();
    setCheckItems([]);
  }, [currentPage]);

  useEffect(() => {
    setInputValue("");
  }, [filter]);

  useEffect(() => {
    sessionStorage.removeItem("bundlePageState");
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {loading ? <Loading /> : null}
          {isMobile && <Header title={"묶음상품 관리"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    묶음상품 관리 📋
                  </Title>
                )}
                <WhiteBox margin={"0 0"}>
                  <FlexBox wrap={"wrap"} wrapMd={"no-wrap"} justify={"end"} gap={"10px"}>
                    <BasicSelect
                      data={SEARCH_TYPE_LIST}
                      selectedValue={filter}
                      setSelectedValue={setFilter}
                      canSelectAll={false}
                    />
                    {filter == "barcode_num" ? (
                      <SearchInput
                        borderBottom={"1px solid #666"}
                        borderBottomColor={"#ff4a4a"}
                        transition={"border-color 0.3s ease-in-out"}
                        placeholder={"바코드 번호를 입력해주세요."}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={(event) => handleKeyDown(event, handleSearch)}
                      />
                    ) : (
                      <SelectBox>
                        <ProductSearchInputVer2
                          showSearchList={showSearchList}
                          setShowSearchList={setShowSearchList}
                          searchResults={searchResults}
                          setSearchResults={setSearchResults}
                          inputValue={inputValue}
                          setInputValue={setInputValue}
                          onSearch={handleSearch}
                        />
                      </SelectBox>
                    )}
                    <Btn
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      width={"100%"}
                      widthMd={"unset"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      onClick={handleSearch}
                      style={{ flexShrink: 0 }}
                    >
                      검 색
                    </Btn>
                  </FlexBox>
                  <FlexBox
                    justify={"space-between"}
                    gap={"10px"}
                    wrap={"wrap"}
                    margin={"20px 0 10px"}
                    marginMd={"20px 0 30px"}
                  >
                    {!isMobile && (
                      <BasicSelect
                        width={"130px"}
                        data={UNIT_10_TO_30_LIST}
                        selectedValue={pageSize}
                        setSelectedValue={setPageSize}
                        canSelectAll={false}
                        setCurrentPage={setCurrentPage}
                      />
                    )}
                    <FlexBox
                      gap={"10px"}
                      justify={"flex-end"}
                      width={"100%"}
                      widthMd={"auto"}
                      style={{ marginLeft: "auto" }}
                    >
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        onClick={handleClickRemoveBundle}
                      >
                        해제
                      </Btn>
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        onClick={handleClickEditProduct}
                      >
                        수정
                      </Btn>
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        onClick={handleClickAddProduct}
                      >
                        등록
                      </Btn>
                    </FlexBox>
                  </FlexBox>
                  <TableWrap>
                    <table style={{ width: "100%", minWidth: "1024px" }}>
                      <colgroup>
                        <col style={{ width: "4%" }} />
                        <col style={{ width: "6%" }} />
                        <col style={{ width: "9%" }} />
                        <col style={{ width: "14%" }} />
                        <col style={{ width: "12%" }} />
                        <col />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>
                            <CheckLabel htmlFor="chkAll" style={{ justifyContent: "center" }}>
                              <input
                                type="checkbox"
                                id="chkAll"
                                onChange={(event) => handleChangeAllChk(event.target.checked)}
                                checked={checkItems.length === bundleList.length ? true : false}
                              />
                              <CheckIcon>
                                <svg viewBox="0 0 24 24">
                                  <polyline points="19 7 10 17 5 12" />
                                </svg>
                              </CheckIcon>
                            </CheckLabel>
                          </Th>
                          <Th>No</Th>
                          <Th>묶음번호</Th>
                          <Th>바코드번호</Th>
                          <Th>상품분류</Th>
                          <Th>상품명</Th>
                          <Th>출하가</Th>
                          <Th>매입가</Th>
                          <Th>소비자가</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {bundleList?.length > 0 ? (
                          bundleList?.map((item, key) => (
                            <tr
                              style={{ cursor: "pointer" }}
                              key={key}
                              onClick={(event) => {
                                const targetElement = event.target;
                                if (targetElement.nodeName !== "INPUT") {
                                  // 체크박스를 클릭한 경우에는 이벤트를 처리하지 않음
                                  const isChecked = document.getElementById(`prod${item.super_code_id}`).checked;
                                  handleChangeChk(!isChecked, item.super_code_id);
                                }
                              }}
                            >
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                <CheckLabel htmlFor={`prod${item.super_code_id}`} style={{ justifyContent: "center" }}>
                                  <input
                                    type="checkbox"
                                    name="prod"
                                    id={`prod${item.super_code_id}`}
                                    readOnly
                                    checked={checkItems.includes(item.super_code_id) ? true : false}
                                  />
                                  <CheckIcon>
                                    <svg viewBox="0 0 24 24">
                                      <polyline points="19 7 10 17 5 12" />
                                    </svg>
                                  </CheckIcon>
                                </CheckLabel>
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{start + key + 1}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.super_code_id}</Td>
                              <TdItem align={"left"} style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {item.products.length > 0
                                  ? item.products.map((list, idx) => <div key={idx}>{list.barcode_num}</div>)
                                  : ""}
                              </TdItem>
                              <TdItem align={"left"} style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {item.products.length > 0
                                  ? item.products.map((list, idx) => <div key={idx}>{list.category2nd_name}</div>)
                                  : ""}
                              </TdItem>
                              <TdItem align={"left"} style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {item.products.length > 0
                                  ? item.products.map((list, idx) => <div key={idx}>{list.prodcut_name}</div>)
                                  : ""}
                              </TdItem>
                              <TdItem align={"right"} style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {item.products.length > 0
                                  ? item.products.map((list, idx) => (
                                      <div key={idx}>{AddComma(list ? list.shipping_price : 0)}원</div>
                                    ))
                                  : ""}
                              </TdItem>
                              <TdItem align={"right"} style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {item.products.length > 0
                                  ? item.products.map((list, idx) => (
                                      <div key={idx}>{AddComma(list ? list.purchase_price : 0)}원</div>
                                    ))
                                  : ""}
                              </TdItem>
                              <TdItem align={"right"} style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {item.products.length > 0
                                  ? item.products.map((list, idx) => (
                                      <div key={idx}>{AddComma(list ? list.consumer_price : 0)}원</div>
                                    ))
                                  : ""}
                              </TdItem>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={9}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>

                  {pagination?.total_page > 0 ? (
                    <Pagination pagination={pagination} movePage={movePage} currentPage={currentPage} />
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <ConfirmModal
                modal={modal}
                setModal={setModal}
                onSubmit={() => removeBundleProducts()}
                title={"묶음을 해제하시겠습니까?"}
                description={"선택한 모든 상품의 묶음이 즉시 해제됩니다."}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
              <InfoModal modal={refreshModal} setModal={setRefreshModal} description={textMsg} mode={"refresh"} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const SelectBox = styled.div`
  position: relative;

  @media all and (max-width: 1024px) {
    width: 100%;
    flex: unset;
  }
`;

const TdItem = styled(Td)`
  & > div + div {
    margin-top: 10px;
  }
`;
