import React, { useEffect, useState, useReducer } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { utils, writeFile } from "xlsx";

import {
  ContentContainer,
  Wrapper,
  Container,
  WhiteBox,
  FlexBox,
  Title,
  Text,
  Btn,
  TableWrap,
  Th,
  Td,
} from "../../Assets/css/commonVer2";
import Header from "../../Components/Header/HeaderVer2";
import Authority from "../../Components/Modal/Authority";
import InfoModal from "../../Components/Modal/InfoModal";
import BasicSelect from "../../Components/Select/BasicSelect";
import Pagination from "../../Components/Paging/Pagination";
import HandleBack from "../../Components/HandleBack";
import ShopOrderSearchBar from "./Components/ShopOrderSearchBar";
import { AddComma } from "../../Utiles/Custom";
import { UNIT_10_TO_50_LIST, SHOP_ORDER_STATUS_LIST } from "../../Utiles/ListData";
import { searchToObject } from "../../Utiles/searchToObject";
import { dateToString } from "../../Utiles/dateToString";
import { delayCall } from "../../Utiles/delayCall";
import { getShopOrders } from "../../Utiles";
import { SearchShopOrderReducer } from "../../Store";
import ShopDeliveryModal from "./Components/ShopDeliveryModal";

// 쇼핑몰 > 주문내역 조회 및 관리

function ShopOrder() {
  const location = useLocation();
  const navigate = useNavigate();
  const { handlePopstate } = HandleBack();
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  // 모달
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [onDeliveryModal, setOnDeliveryModal] = useState(false);
  const [refreshModal, setRefreshModal] = useState(false);

  // 날짜 제어 state
  const date = new Date();
  const year = date.getFullYear(); // 년
  const month = date.getMonth(); // 월
  const day = date.getDate(); // 일
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);

  // 페이징
  const [pagination, setPagination] = useState("");

  // 주문내역 데이터
  const [orderCnt, setOrderCnt] = useState(); // 총 주문 수
  const [orderList, setOrderList] = useState([]);

  // 주문 번호 입력 받을 state
  const [inputOrderId, setInputOrderId] = useState();

  // 클릭한 주문 건 주문번호
  const [clickedItemOrderNum, setClickedItemOrderNum] = useState("");

  // 주문 상세를 다녀온 경우 저장 해놓은 파라미터 정보
  const parsedShopOrderPageState = sessionStorage.getItem("shopOrderPageState")
    ? JSON.parse(sessionStorage.getItem("shopOrderPageState"))
    : {};

  // 검색 객체
  const [searchState, dispatchSearchState] = useReducer(SearchShopOrderReducer, {
    start_date: dateToString(new Date(year, month, day - 7)),
    end_date: dateToString(new Date()),
    unit: 10,
    page: parsedShopOrderPageState ? (parsedShopOrderPageState?.page ? parsedShopOrderPageState?.page : 1) : 1,
    search: true,
    ...searchToObject(),
  });

  const [unit, setUnit] = useState(
    parsedShopOrderPageState ? (parsedShopOrderPageState?.unit ? parsedShopOrderPageState?.unit : 10) : 10,
  );

  // 주문 상세를 갈 때 sessionStorage에 저장할 객체
  const shopOrderPageState = {
    unit: searchState.unit,
    page: searchState.page,
  };

  // 주문내역 데이터 조회
  function callGetShopOrders() {
    // 검색 객체는 자동으로 등록이 되지만 보여주기 위한 날짜 객체를 넣는다.
    // 날짜 선택 창에 검색 객체랑 동일한 날짜를 넣어준다.
    setStartDate(new Date(searchState.start_date));
    setEndDate(new Date(searchState.end_date));
    setInputOrderId(searchState.filter_value);

    getShopOrders(searchState)
      .then((res) => {
        if (res.status) {
          setPagination(res.pagination);
          setOrderList(res.results);
          setOrderCnt(res.cntAll);
          dispatchSearchState({ type: "CHANGE_SEARCH", value: false });
        }
      })
      .catch(() => {
        dispatchSearchState({ type: "CHANGE_SEARCH", value: false });
      });
  }

  // 검색 함수
  function searchOrders() {
    if (inputOrderId) {
      dispatchSearchState({ type: "SEARCH_BY_SHOP_ORDER_ID", value: inputOrderId });
    } else if (!inputOrderId) {
      dispatchSearchState({ type: "DELETE_IDS" });
    }
    dispatchSearchState({ type: "CHANGE_SEARCH", value: true });
    dispatchSearchState({ type: "CHANGE_NOW_PAGE", value: 1 });
  }

  // (배송전->배송중) 상태변경 버튼 클릭
  const onClickChangeStatusBtn = (idx) => {
    setClickedItemOrderNum(idx);
    setOnDeliveryModal(true);
  };

  // 주문 상세로 이동
  function onClickItem(idx) {
    navigate(`/shoppingmall/order/${idx}`);
    let jsonString = JSON.stringify(shopOrderPageState);
    sessionStorage.setItem("shopOrderPageState", jsonString);
  }

  const movePage = (e) => {
    dispatchSearchState({ type: "CHANGE_NOW_PAGE", value: parseInt(e) });
  };

  const onExcelDown = async () => {
    if (orderList?.length > 0) {
      getShopOrders({ ...searchState, unit: 99999999, page: 1 }).then((res) => {
        if (res.status) {
          const data = [];
          const excelTableHead = [["주문번호", "고객명", "품목수량", "총금액", "주문일", "상태"]];
          const wb = utils.book_new();
          const ws = utils.json_to_sheet([]);

          utils.sheet_add_aoa(ws, excelTableHead);

          res.results.forEach((item) => {
            data.push({
              idx: `${item.idx}`,
              name: `${item.name}`,
              product_count: `${item.product_count}`,
              total_price: `${item.total_price}`,
              order_date: `${item.order_date}`,
              status: SHOP_ORDER_STATUS_LIST.find((i) => i.value === item.status)?.text,
            });
          });

          utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
          utils.book_append_sheet(wb, ws, "Report");

          writeFile(wb, `주문내역 조회 및 관리_${dateToString(startDate)}~${dateToString(endDate)}.xlsx`);
        } else {
          setTextMsg(res.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      });
    } else {
      setTextMsg("다운받을 데이터가 없습니다.");
      setTextModal(true);
    }
  };

  useEffect(() => {
    dispatchSearchState({ type: "CHANGE_UNIT", value: unit });
  }, [unit]);

  useEffect(() => {
    dispatchSearchState({ type: "CHANGE_NOW_PAGE", value: searchState.page });
  }, [searchState.page]);

  // 현재 페이지에 왔을 때 searchState 에 맞는 데이터 가져옴
  useEffect(() => {
    if (searchState.search) {
      const params = {};
      Object.entries(searchState).forEach((item) => (params[item[0]] = item[1]));
      navigate(
        {
          pathname: "/shoppingmall/order",
          search: `?${createSearchParams(params)}`,
        },
        { replace: true },
      );
    }
  }, [searchState]);

  // 페이지 최초 진입 시 실행
  useEffect(() => {
    delayCall(callGetShopOrders);
  }, [location]);

  useEffect(() => {
    sessionStorage.removeItem("shopOrderPageState");
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {isMobile && <Header title={"주문내역 조회 및 관리"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    주문내역 조회 및 관리 👩🏻‍💻
                  </Title>
                )}
                <WhiteBox margin={"0 0"}>
                  <ShopOrderSearchBar
                    searchState={searchState}
                    dispatchSearchState={dispatchSearchState}
                    searchOrders={searchOrders}
                    inputNumber={inputOrderId}
                    setInputNumber={setInputOrderId}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    startDate={startDate}
                    endDate={endDate}
                  />
                  <FlexBox
                    justify={"space-between"}
                    gap={"10px"}
                    margin={"20px 0 10px"}
                    marginMd={"20px 0 30px"}
                    wrap={"wrap"}
                    wrapMd={"no-wrap"}
                  >
                    <FlexBox gap={"10px"} width={"100%"} widthMd={"auto"}>
                      <Text
                        width={"100%"}
                        widthMd={"unset"}
                        color={"#FF4A4A"}
                        size={"16px"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        style={{
                          flexShrink: 0,
                        }}
                      >
                        총 주문 수 <span style={{ fontWeight: "700" }}>{AddComma(orderCnt ? orderCnt : "")}건</span>
                      </Text>
                      {!isMobile && (
                        <BasicSelect
                          width={"130px"}
                          data={UNIT_10_TO_50_LIST}
                          selectedValue={unit}
                          setSelectedValue={setUnit}
                          canSelectAll={false}
                        />
                      )}
                    </FlexBox>
                    {!isMobile && (
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        border={"1px solid #666"}
                        color={"#666"}
                        hoverBackground={"#C7C7C7"}
                        hoverColor={"#fff"}
                        hoverBorder={"transparent"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        margin={"0 0 0 10px"}
                        onClick={onExcelDown}
                      >
                        엑셀 다운
                      </Btn>
                    )}
                  </FlexBox>
                  <TableWrap>
                    <table style={{ width: "100%", minWidth: "1024px" }}>
                      <colgroup>
                        <col style={{ width: "12%" }} />
                        <col style={{ width: "12%" }} />
                        <col style={{ width: "15%" }} />
                        <col />
                        <col />
                        <col style={{ width: "16%" }} />
                        <col style={{ width: "10%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>주문번호</Th>
                          <Th>고객명</Th>
                          <Th>품목 수량</Th>
                          <Th>총 금액</Th>
                          <Th>주문일시</Th>
                          <Th>상태</Th>
                          <Th>상태 변경</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderList.length > 0 ? (
                          orderList.map((list, key) => (
                            <tr key={key}>
                              <Td onClick={() => onClickItem(list.idx)} style={{ cursor: "pointer" }}>
                                {list.idx}
                              </Td>
                              <Td onClick={() => onClickItem(list.idx)} style={{ cursor: "pointer" }}>
                                {list.name}
                              </Td>
                              <Td onClick={() => onClickItem(list.idx)} style={{ cursor: "pointer" }}>
                                {list.product_count}개
                              </Td>
                              <Td onClick={() => onClickItem(list.idx)} weight={700} style={{ cursor: "pointer" }}>
                                {AddComma(list.total_price)}원
                              </Td>
                              <Td onClick={() => onClickItem(list.idx)} weight={700} style={{ cursor: "pointer" }}>
                                {list.order_date}
                              </Td>
                              <Td onClick={() => onClickItem(list.idx)} weight={700} style={{ cursor: "pointer" }}>
                                {list.status
                                  ? SHOP_ORDER_STATUS_LIST.find((i) => i.value === list.status)?.text || "-"
                                  : "-"}
                              </Td>
                              <Td>
                                {list.status === "before-delivery" ? (
                                  <Btn
                                    margin={"0 auto"}
                                    size={"14px"}
                                    padding={"8px 18px"}
                                    onClick={() => onClickChangeStatusBtn(list.idx, list.status)}
                                    cursor={"unset"}
                                  >
                                    변경
                                  </Btn>
                                ) : (
                                  "-"
                                )}
                              </Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={7}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  {pagination?.total_page > 0 ? (
                    <Pagination pagination={pagination} movePage={movePage} currentPage={searchState.page} />
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
              <InfoModal modal={refreshModal} setModal={setRefreshModal} description={textMsg} mode={"refresh"} />
              <ShopDeliveryModal
                gubun={"write"}
                modal={onDeliveryModal}
                setModal={setOnDeliveryModal}
                title={"배송중으로 상태 변경"}
                idx={clickedItemOrderNum}
              />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

export default ShopOrder;
