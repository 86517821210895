import React, { useCallback, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import HeaderSub from "../../Components/Header/HeaderSub";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";

import {
  Btn,
  Container,
  TextInput,
  Wrapper,
  FlexBox,
  Text,
  Title,
  WhiteBox,
  ContentContainer,
  RadioLabel,
  RadioIcon,
  UploadFileBtn,
  FileImg,
  FileXBtn,
} from "../../Assets/css/commonVer2";
import ico_close from "../../Assets/images/ico_close.png";
import API from "../../Utiles/API";
import formAPI from "../../Utiles/formAPI";
import SearchAddress from "../../Components/SearchAddress/SearchAddress";
import Authority from "../../Components/Modal/Authority";
import { AddComma } from "../../Utiles/Custom";
import Loading from "../../Components/Loading";
import InfoModal from "../../Components/Modal/InfoModal";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import BasicSelect from "../../Components/Select/BasicSelect";

// 가맹점 수정

function FranchiseeEdit() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();
  const location = useLocation();
  const idx = location.state.idx;
  const [loading, setLoading] = useState(false);

  // 카카오 지도 api 키(카카오 지도 SDK를 사용하기 위한 인증 키(앱 키))
  const apiKey = process.env.REACT_APP_KAKAOMAP_API_KEY;

  const [latitude, setLatitude] = useState(""); // 위도
  const [longitude, setLongitude] = useState(""); // 경도
  const [isSearch, setIsSearch] = useState(false); // 주소찾기로 주소 변경 시 true

  const [values, setValues] = useState([]);
  const [priceValues, setPriceValues] = useState([]);
  const [managerUser, setManagerUser] = useState([]);
  const [salesManagerId, setSalesManagerId] = useState("");
  const [logisticsManagerId, setLogisticsManagerId] = useState("");

  // 모달들
  const [addrModal, setAddrModal] = useState(false);
  const [addrValue, setAddrValue] = useState("");
  const [listModal, setListModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [goBackModal, setGoBackModal] = useState(false);
  const [textModal, setTextModal] = useState(false); // 모달 창 표시 여부 상태
  const [textMsg, setTextMsg] = useState("");

  // 가맹점 매장 이미지
  const imgRef = useRef();
  const [showFileBtn, setShowFileBtn] = useState(true);
  const [imageFile, setImageFile] = useState([]);
  const [imagePath, setImagePath] = useState([]);
  const [preImagePath, setPreImagePath] = useState([]);

  // API
  // 1. 가맹점 수정 페이지 데이터 조회
  useEffect(() => {
    const getFranchisee = async () => {
      const { data } = await API.get(`/admin/franchise/${idx}`);
      return data;
    };
    getFranchisee().then((data) => {
      if (data.status) {
        setValues(data.result);
        const keysToFormat = [
          "membership_fee",
          "royalty",
          "contract_performance_bond",
          "interior",
          "maintenance_cost",
          "capstack_cost",
          "educational_cost",
          "fi_logistics_setting_cost",
          "fi_product_cost",
        ];
        const formattedValues = {};
        keysToFormat.forEach((key) => {
          if (data.result[key]) {
            formattedValues[key] = AddComma(data.result[key]);
          }
        });
        setPriceValues(formattedValues);
        setAddrValue(data.result.franchise_address);
        setLatitude(data.result.lat);
        setLongitude(data.result.lng);
        setSalesManagerId(data.result.sales_manager_id);
        setLogisticsManagerId(data.result.logistics_manager_id);
        if (data.result.image_path) {
          setPreImagePath([data.result.image_path]);
        }
      }
    });

    getManagerUser();
  }, []);

  // 2. 영업, 물류 담당자 조회
  const getManagerUser = async () => {
    await API.get(`/admin/franchise-manager`).then((res) => {
      if (res.data.status) {
        const createListData = res.data.result.map((item) => ({
          value: item.idx,
          text: item.name,
        }));
        setManagerUser(createListData);
      }
    });
  };

  // 3. 가맹점 수정 PUT 요청
  const handleSubmit = async () => {
    let formData = new FormData();
    setLoading(true);

    formData.append("franchise_name", values.franchise_name);
    formData.append("business_num", values.business_num);
    formData.append("franchise_address", values.franchise_address);
    formData.append("detail_address", values.detail_address);
    formData.append("lat", latitude);
    formData.append("lng", longitude);
    formData.append("rental_information", values.rental_information);
    formData.append("type_of_business", values.type_of_business);
    formData.append("items_of_business", values.items_of_business);
    formData.append("membership_fee", values.membership_fee);
    formData.append("royalty", values.royalty);
    formData.append("contract_performance_bond", values.contract_performance_bond);
    formData.append("interior", values.interior);
    formData.append("maintenance_cost", values.maintenance_cost);
    formData.append("kiosk_buy", values.kiosk_buy);
    formData.append("individual_product_available", values.individual_product_available);
    formData.append("existing_stationery", values.existing_stationery);
    formData.append("capstack_cost", values.capstack_cost);
    formData.append("educational_cost", values.educational_cost);
    formData.append("fi_logistics_setting_cost", values.fi_logistics_setting_cost);
    formData.append("fi_product_cost", values.fi_product_cost);
    formData.append("sales_manager_id", salesManagerId);
    formData.append("logistics_manager_id", logisticsManagerId);
    formData.append("franchise_type", values.franchise_type);
    formData.append("cash_receipts", values.cash_receipts);
    formData.append("status", values.status); //슬롯 구분상태

    // 새로 추가한 이미지 file
    if (imageFile.length !== 0) {
      imageFile.forEach((file) => {
        formData.append("img", file);
      });
    }

    await formAPI
      .put(`/admin/franchise/${idx}`, formData)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          navigate("/franchisee/management", { replace: true });
          setTextMsg("수정이 완료되었습니다.");
          setGoBackModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setLoading(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const changeType = (gubun, type) => {
    if (type === "kisok") {
      if (gubun === 0) {
        setValues({
          ...values,
          kiosk_buy: 0,
        });
      } else {
        setValues({
          ...values,
          kiosk_buy: 1,
        });
      }
    } else if (type === "iProduct") {
      if (gubun === 0) {
        setValues({
          ...values,
          individual_product_available: 0,
        });
      } else {
        setValues({
          ...values,
          individual_product_available: 1,
        });
      }
    } else if (type === "franchise_type") {
      if (gubun === 0) {
        setValues({
          ...values,
          franchise_type: "direct",
        });
      } else {
        setValues({
          ...values,
          franchise_type: "franchise",
        });
      }
    } else if (type === "cash_receipts") {
      if (gubun === 0) {
        setValues({
          ...values,
          cash_receipts: false,
        });
      } else {
        setValues({
          ...values,
          cash_receipts: true,
        });
      }
    } else if (type === "eStationery") {
      if (gubun === 0) {
        setValues({
          ...values,
          existing_stationery: 0,
        });
      } else {
        setValues({
          ...values,
          existing_stationery: 1,
        });
      }
    } else if (type === "status") {
      if (gubun === 0) {
        setValues({
          ...values,
          status: "active",
        });
      }
      if (gubun === 1) {
        setValues({
          ...values,
          status: "terminated",
        });
      }
      if (gubun === 2) {
        setValues({
          ...values,
          status: "service_only",
        });
      }
      if (gubun === 3) {
        setValues({
          ...values,
          status: "paused",
        });
      }
    }
  };

  const handleChange = async (e, isPriceValue) => {
    const price = e.target.value.replaceAll(",", "");
    setValues({
      ...values,
      [e.target.name]: price,
    });
    if (isPriceValue && e.target.value) {
      setPriceValues({ ...priceValues, [e.target.name]: AddComma(price) });
    } else {
      setPriceValues({ ...priceValues, [e.target.name]: "" });
    }
  };

  // 모달 1 목록 2 저장
  const onModalSubmit = (e) => {
    if (e === 1) {
      setSaveModal(false);
      navigate(-1, { replace: true });
    } else {
      setSaveModal(false);
      handleSubmit();
    }
  };

  // 이미지 추가
  const handleImgAdd = (e) => {
    const nowSelectImageList = e.target.files;
    const nowImageURLList = [...imagePath];

    if (nowSelectImageList.length > 1) {
      setTextMsg("이미지는 1개만 등록 가능합니다.");
      setTextModal(true);
      return;
    }

    const nowImageUrl = URL.createObjectURL(nowSelectImageList[0]);
    nowImageURLList.push(nowImageUrl);

    imageFile.push(nowSelectImageList[0]);
    setImagePath(nowImageURLList);
  };

  // 이미지 삭제
  const handleImgRemove = (gubun, idx) => {
    if (gubun === "existing") {
      // 기존 이미지를 지울때
      let copy = [...preImagePath];
      copy.splice(idx, 1);
      setPreImagePath(copy);
    } else if (gubun === "new") {
      //방금 추가한 이미지를 지울때
      let copy1 = [...imagePath];
      let copy2 = [...imageFile];

      copy1.splice(idx, 1);
      copy2.splice(idx, 1);

      setImagePath(copy1);
      setImageFile(copy2);
    }
  };

  // 위도 경도 input 에서 변경 시
  const handleLatAndLng = (e, gubun) => {
    if (gubun === 1) {
      setLatitude(e.target.value);
    } else {
      setLongitude(e.target.value);
    }
  };

  // 가맹점 이미지 관련
  useEffect(() => {
    if (preImagePath?.length === 1 || imagePath?.length === 1) {
      setShowFileBtn(false);
    } else {
      setShowFileBtn(true);
    }
  }, [preImagePath, imagePath]);

  // 가맹점 주소찾기 결과 업데이트
  useEffect(() => {
    setValues({
      ...values,
      franchise_address: addrValue,
    });
  }, [addrValue]);

  // 가맹점 주소 -> 위경도 변환 로직
  useEffect(() => {
    // 가맹점 주소가 있고 주소찾기로 검색한 경우에만 실행시키기
    if (addrValue !== "" && isSearch) {
      const script = document.createElement("script"); // 새로운 script 요소를 생성
      script.async = true; // script 요소를 비동기 방식으로 로드하도록 설정
      // script 요소의 src 속성을 설정하여 카카오 지도 SDK의 스크립트를 로드함
      // autoload=false는 스크립트를 로드한 후에 수동으로 카카오 지도를 초기화하는 것
      // libraries=services는 geocoder (카카오맵 자체) 라이브러리를 사용할 수 있게 설정하는 것
      script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${apiKey}&libraries=services&autoload=false`;
      document.head.appendChild(script); // 생성한 script 요소를 head 요소에 추가

      // script 요소가 로드되면 이벤트 리스너를 추가함
      script.addEventListener("load", () => {
        // window.kakao.maps.load() 함수를 호출
        window.kakao?.maps.load(() => {
          // 주소-좌표 변환 객체를 생성합니다
          var geocoder = new window.kakao.maps.services.Geocoder();
          // 주소로 좌표를 검색합니다
          geocoder.addressSearch(addrValue, function (result, status) {
            // 정상적으로 검색이 완료됐으면
            if (status === window.kakao.maps.services.Status.OK) {
              // result[0] 의 x(coords의 La)에 경도, y(coords의 Ma)에 위도가 담김
              var coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);
              setLatitude(coords.Ma); // coords의 Ma라는 key의 value에 있는 위도값 담기
              setLongitude(coords.La); // coords의 La라는 key의 value에 있는 경도값 담기
            }
          });
        });
      });
    }
    setIsSearch(false);
  }, [addrValue, isSearch]);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {loading ? <Loading /> : null}
          <HeaderSub title={"가맹점 수정"} />
          <ContentContainer>
            <Wrapper>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    가맹점 수정 📋
                  </Title>
                )}

                <WhiteBox margin={"0 0"}>
                  <Text align={"left"} color={"#1E1E1E"} weight={"700"} size={"16px"} sizeMd={"20px"}>
                    가맹 정보
                  </Text>
                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    margin={"20px 0 20px"}
                    marginMd={"20px 0 30px"}
                    wrap={"wrap"}
                    wrapMd={"unset"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        가맹점명
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        name="franchise_name"
                        placeholder={"가맹점명을 입력해주세요."}
                        value={values.franchise_name}
                        onChange={(e) => {
                          handleChange(e, false);
                        }}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        사업자 등록번호
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"사업자 등록번호를 입력해주세요."}
                        name="business_num"
                        value={values.business_num}
                        onChange={(e) => {
                          handleChange(e, false);
                        }}
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBox justify={"start"} align={"start"}>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        가맹점 주소
                      </Text>
                      <FlexBox margin={"0 0 5px"} marginMd={"0 0 10px"} gap={"10px"} onClick={() => setAddrModal(true)}>
                        <TextInput
                          style={{ width: "calc(100% - 95px)" }}
                          type={"text"}
                          placeholder={"주소를 입력해주세요."}
                          value={values.franchise_address}
                        />
                        <Btn
                          color={"#fff"}
                          background={"#FF4A4A"}
                          size={"14px"}
                          weight={"700"}
                          padding={"8px 18px"}
                          lineHeight={"22px"}
                          style={{ flexShrink: 0 }}
                        >
                          주소찾기
                        </Btn>
                      </FlexBox>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"상세주소를 입력해주세요."}
                        name="detail_address"
                        value={values.detail_address}
                        onChange={(e) => {
                          handleChange(e, false);
                        }}
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    wrap={"wrap"}
                    wrapMd={"unset"}
                    gap={"20px"}
                    gapMd={"26px"}
                    margin={"30px 0 0 0"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        위도
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"위도를 입력해주세요."}
                        name="latitude"
                        value={latitude}
                        onChange={(e) => {
                          handleLatAndLng(e, 1);
                        }}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        경도
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"경도를 입력해주세요."}
                        name="longitude"
                        value={longitude}
                        onChange={(e) => {
                          handleLatAndLng(e, 2);
                        }}
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBox justify={"start"} align={"start"} margin={"20px 0 20px"} marginMd={"30px 0 30px"}>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        가맹점 임차정보
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"가맹점 임차정보를 입력해주세요."}
                        name="rental_information"
                        value={values.rental_information}
                        onChange={(e) => {
                          handleChange(e, false);
                        }}
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBox justify={"start"} align={"start"} wrap={"wrap"} wrapMd={"unset"} gap={"20px"} gapMd={"26px"}>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        업태
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"업태를 입력해주세요."}
                        name="type_of_business"
                        value={values.type_of_business}
                        onChange={(e) => {
                          handleChange(e, false);
                        }}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        종목
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"종목을 입력해주세요."}
                        name="items_of_business"
                        value={values.items_of_business}
                        onChange={(e) => {
                          handleChange(e, false);
                        }}
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBox justify={"start"} align={"start"} margin={"20px 0 20px"} marginMd={"30px 0 30px"}>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        가맹점 이미지
                      </Text>
                      {isMobile ? (
                        <FlexBox justify={"flex-start"} gap={"10px"} wrap={"wrap"}>
                          {showFileBtn && (
                            <UploadFileBtn>
                              <input
                                type="file"
                                multiple="multiple"
                                accept="image/*"
                                onChange={handleImgAdd}
                                ref={imgRef}
                              />
                              <Text size={"14px"} color={"#C7C7C7"}>
                                이미지 등록
                              </Text>
                              <span>파일 첨부</span>
                            </UploadFileBtn>
                          )}
                          {preImagePath?.map((e, i) => (
                            <FileImg key={i}>
                              <FileXBtn onClick={() => handleImgRemove("existing", i)}>
                                {(() => {
                                  if (e !== null && e !== undefined) {
                                    return <img src={ico_close} />;
                                  }
                                })()}
                              </FileXBtn>
                              <div>
                                {(() => {
                                  if (e !== null && e !== undefined) {
                                    return <img src={`${process.env.REACT_APP_S3_KEY}` + e} />;
                                  }
                                })()}
                              </div>
                            </FileImg>
                          ))}
                          {imagePath?.map((e, i) => (
                            <FileImg key={i}>
                              <FileXBtn onClick={() => handleImgRemove("new", i)}>
                                {(() => {
                                  if (e !== null && e !== undefined) {
                                    return <img src={ico_close} />;
                                  }
                                })()}
                              </FileXBtn>
                              <div>
                                {(() => {
                                  return <img src={imagePath[i]} />;
                                })()}
                              </div>
                            </FileImg>
                          ))}
                        </FlexBox>
                      ) : (
                        <WhiteBox>
                          <FlexBox justify={"flex-start"} gap={"10px"} wrap={"wrap"}>
                            {showFileBtn && (
                              <UploadFileBtn>
                                <input
                                  type="file"
                                  multiple="multiple"
                                  accept="image/*"
                                  onChange={handleImgAdd}
                                  ref={imgRef}
                                />
                                <Text size={"14px"} color={"#C7C7C7"}>
                                  이미지 등록
                                </Text>
                                <span>파일 첨부</span>
                              </UploadFileBtn>
                            )}
                            {preImagePath?.map((e, i) => (
                              <FileImg key={i}>
                                <FileXBtn onClick={() => handleImgRemove("existing", i)}>
                                  {(() => {
                                    if (e !== null && e !== undefined) {
                                      return <img src={ico_close} />;
                                    }
                                  })()}
                                </FileXBtn>
                                <div>
                                  {(() => {
                                    if (e !== null && e !== undefined) {
                                      return <img src={`${process.env.REACT_APP_S3_KEY}${e}`} />;
                                    }
                                  })()}
                                </div>
                              </FileImg>
                            ))}
                            {imagePath?.map((e, i) => (
                              <FileImg key={i}>
                                <FileXBtn onClick={() => handleImgRemove("new", i)}>
                                  {(() => {
                                    if (e !== null && e !== undefined) {
                                      return <img src={ico_close} />;
                                    }
                                  })()}
                                </FileXBtn>
                                <div>
                                  {(() => {
                                    return <img src={imagePath[i]} />;
                                  })()}
                                </div>
                              </FileImg>
                            ))}
                          </FlexBox>
                        </WhiteBox>
                      )}
                    </Contents>
                  </FlexBox>
                </WhiteBox>

                <WhiteBox>
                  <Text align={"left"} color={"#1E1E1E"} weight={"700"} size={"16px"} sizeMd={"20px"}>
                    가맹 점주 정보
                  </Text>

                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    margin={"20px 0 20px"}
                    marginMd={"20px 0 30px"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        이름
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"이름을 입력해주세요."}
                        value={values["users.name"]}
                        readOnly
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        주민등록번호
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"주민등록번호를 입력해주세요."}
                        value={values["users.resident_registration_number"]}
                        readOnly
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBox justify={"start"} align={"start"} gap={"20px"} gapMd={"26px"}>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        전화번호
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"전화번호 입력해주세요."}
                        value={values["users.tel"]}
                        readOnly
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        이메일
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"이메일를 입력해주세요."}
                        value={values["users.email"]}
                        readOnly
                      />
                    </Contents>
                  </FlexBox>
                </WhiteBox>

                <WhiteBox>
                  <Text align={"left"} color={"#1E1E1E"} weight={"700"} size={"16px"} sizeMd={"20px"}>
                    가맹점 계약 정보
                  </Text>
                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    margin={"20px 0 20px"}
                    marginMd={"20px 0 30px"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        가맹비
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="membership_fee"
                          value={priceValues.membership_fee}
                          onChange={(e) => {
                            const input = e.target.value.replaceAll(",", "");
                            if (/^\d*$/.test(input)) {
                              handleChange(e, true);
                            }
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "12px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        로열티
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="royalty"
                          value={priceValues.royalty}
                          onChange={(e) => {
                            const input = e.target.value.replaceAll(",", "");
                            if (/^\d*$/.test(input)) {
                              handleChange(e, true);
                            }
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "12px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                  </FlexBox>
                  <FlexBox justify={"start"} align={"start"} gap={"20px"} gapMd={"26px"}>
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        계약 이행 보증금
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="contract_performance_bond"
                          value={priceValues.contract_performance_bond}
                          onChange={(e) => {
                            const input = e.target.value.replaceAll(",", "");
                            if (/^\d*$/.test(input)) {
                              handleChange(e, true);
                            }
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "13px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        인테리어(평당)
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="interior"
                          value={priceValues.interior}
                          onChange={(e) => {
                            const input = e.target.value.replaceAll(",", "");
                            if (/^\d*$/.test(input)) {
                              handleChange(e, true);
                            }
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "13px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                  </FlexBox>
                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    margin={"20px 0 20px"}
                    marginMd={"30px 0 30px"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        키오스크 유지관리비
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="maintenance_cost"
                          value={priceValues.maintenance_cost}
                          onChange={(e) => {
                            const input = e.target.value.replaceAll(",", "");
                            if (/^\d*$/.test(input)) {
                              handleChange(e, true);
                            }
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "13px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        키오스크 구매 여부
                      </Text>
                      <FlexBox justify={"start"} gap={"10px"}>
                        <RadioLabel>
                          <input
                            type="radio"
                            value={values.kiosk_buy}
                            checked={values.kiosk_buy}
                            onChange={() => changeType(1, "kisok")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          구매
                        </RadioLabel>

                        <RadioLabel>
                          <input
                            type="radio"
                            value={!values.kiosk_buy}
                            checked={!values.kiosk_buy}
                            onChange={() => changeType(0, "kisok")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          미구매
                        </RadioLabel>
                      </FlexBox>
                    </Contents>
                  </FlexBox>

                  <FlexBox justify={"start"} align={"start"} gap={"20px"} gapMd={"26px"}>
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        개인 상품 가능 여부
                      </Text>
                      <FlexBox justify={"start"} gap={"10px"}>
                        <RadioLabel>
                          <input
                            type="radio"
                            value={values.individual_product_available}
                            checked={values.individual_product_available}
                            onChange={() => changeType(1, "iProduct")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          가능
                        </RadioLabel>

                        <RadioLabel>
                          <input
                            type="radio"
                            value={!values.individual_product_available}
                            checked={!values.individual_product_available}
                            onChange={() => changeType(0, "iProduct")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          불가능
                        </RadioLabel>
                      </FlexBox>
                    </Contents>
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        기존 문방구 여부
                      </Text>
                      <FlexBox justify={"start"} gap={"10px"}>
                        <RadioLabel>
                          <input
                            type="radio"
                            value={values.existing_stationery}
                            checked={values.existing_stationery}
                            onChange={() => changeType(1, "eStationery")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          Yes
                        </RadioLabel>

                        <RadioLabel>
                          <input
                            type="radio"
                            value={!values.existing_stationery}
                            checked={!values.existing_stationery}
                            onChange={() => changeType(0, "eStationery")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          No
                        </RadioLabel>
                      </FlexBox>
                    </Contents>
                  </FlexBox>
                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    margin={"20px 0 20px"}
                    marginMd={"30px 0 30px"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        캡스택 비용
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="capstack_cost"
                          value={priceValues.capstack_cost}
                          onChange={(e) => {
                            const input = e.target.value.replaceAll(",", "");
                            if (/^\d*$/.test(input)) {
                              handleChange(e, true);
                            }
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "13px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        교육비
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="educational_cost"
                          value={priceValues.educational_cost}
                          onChange={(e) => {
                            const input = e.target.value.replaceAll(",", "");
                            if (/^\d*$/.test(input)) {
                              handleChange(e, true);
                            }
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "13px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                  </FlexBox>
                  <FlexBox justify={"start"} align={"start"} gap={"20px"} gapMd={"26px"}>
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        초도 물류 세팅 비용
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="fi_logistics_setting_cost"
                          value={priceValues.fi_logistics_setting_cost}
                          onChange={(e) => {
                            const input = e.target.value.replaceAll(",", "");
                            if (/^\d*$/.test(input)) {
                              handleChange(e, true);
                            }
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "13px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        초도 상품 비용
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="fi_product_cost"
                          value={priceValues.fi_product_cost}
                          onChange={(e) => {
                            const input = e.target.value.replaceAll(",", "");
                            if (/^\d*$/.test(input)) {
                              handleChange(e, true);
                            }
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "13px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                  </FlexBox>
                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    margin={"20px 0 0"}
                    marginMd={"30px 0 0"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents flex={"1"}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        가맹 유형
                      </Text>
                      <FlexBox justify={"start"} gap={"10px"}>
                        <RadioLabel>
                          <input
                            type="radio"
                            name="direct"
                            value={values.franchise_type === "direct"}
                            checked={values.franchise_type === "direct"}
                            onChange={() => changeType(0, "franchise_type")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          직영점
                        </RadioLabel>

                        <RadioLabel>
                          <input
                            type="radio"
                            name="franchise"
                            value={values.franchise_type === "franchise"}
                            checked={values.franchise_type === "franchise"}
                            onChange={() => changeType(1, "franchise_type")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          가맹점
                        </RadioLabel>
                      </FlexBox>
                    </Contents>
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        가맹점 상태
                      </Text>
                      <FlexBox justify={"start"} gap={"10px"} wrap="wrap">
                        <RadioLabel>
                          <input
                            type="radio"
                            value="active"
                            checked={values.status === "active"}
                            onChange={() => changeType(0, "status")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          정상
                        </RadioLabel>

                        <RadioLabel>
                          <input
                            type="radio"
                            value="terminated"
                            checked={values.status === "terminated"}
                            onChange={() => changeType(1, "status")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          해지
                        </RadioLabel>
                        <RadioLabel>
                          <input
                            type="radio"
                            value="service_only"
                            checked={values.status === "service_only"}
                            onChange={() => changeType(2, "status")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          별도 계약(서비스만 이용)
                        </RadioLabel>
                        <RadioLabel>
                          <input
                            type="radio"
                            value="paused"
                            checked={values.status === "paused"}
                            onChange={() => changeType(3, "status")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          일시정지
                        </RadioLabel>
                      </FlexBox>
                    </Contents>
                  </FlexBox>
                </WhiteBox>
                {/* 수정일 경우 */}
                <WhiteBox>
                  <Text align={"left"} color={"#1E1E1E"} weight={"700"} size={"16px"} sizeMd={"20px"}>
                    담당자 정보
                  </Text>
                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    wrap={"wrap"}
                    wrapMd={"unset"}
                    gap={"20px"}
                    gapMd={"26px"}
                    margin={"20px 0 0"}
                  >
                    <Contents flex={"unset"} flexMd={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        영업 담당자
                      </Text>
                      <BasicSelect
                        width={"100%"}
                        data={managerUser}
                        selectedValue={salesManagerId}
                        setSelectedValue={setSalesManagerId}
                        placeholder={"영업 담당자를 선택해주세요."}
                        canSelectAll={true}
                      />
                    </Contents>
                    <Contents flex={"unset"} flexMd={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        물류 담당자
                      </Text>
                      <BasicSelect
                        width={"100%"}
                        data={managerUser}
                        selectedValue={logisticsManagerId}
                        setSelectedValue={setLogisticsManagerId}
                        placeholder={"물류 담당자를 선택해주세요."}
                        canSelectAll={true}
                      />
                    </Contents>
                  </FlexBox>
                </WhiteBox>
                <FlexBox justify={"end"}>
                  <FlexBox flex={"1 0 auto"} flexMd={"unset"} gap={"8px"} gapMd={"10px"}>
                    <Btn
                      width={"100%"}
                      widthMd={"90px"}
                      size={"14px"}
                      color={"#666"}
                      border={"1px solid #666"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      hoverWeight={"700"}
                      hoverColor={"#fff"}
                      hoverBackground={"#C7C7C7"}
                      hoverBorder={"1px solid transparent"}
                      onClick={() => setListModal(true)}
                    >
                      목록
                    </Btn>
                    <Btn
                      width={"100%"}
                      widthMd={"90px"}
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      onClick={() => setSaveModal(true)}
                    >
                      저장
                    </Btn>
                  </FlexBox>
                </FlexBox>
              </Container>
              {addrModal ? (
                <SearchAddress setModal={setAddrModal} setValue={setAddrValue} setIsSearch={setIsSearch} />
              ) : (
                ""
              )}
              <ConfirmModal
                modal={listModal}
                setModal={setListModal}
                onSubmit={() => onModalSubmit(1)}
                description={"가맹점 수정을 취소하고\n목록화면으로 이동하시겠습니까?"}
              />
              <ConfirmModal
                modal={saveModal}
                setModal={setSaveModal}
                onSubmit={() => onModalSubmit(2)}
                description={"가맹점 수정을 완료하시겠습니까?"}
              />
              <InfoModal modal={goBackModal} setModal={setGoBackModal} description={textMsg} mode={"goBack"} />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const Contents = styled.div`
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  background-color: ${(props) => props.background || ""};
  width: ${(props) => props.width || "100%"};
  border-radius: ${(props) => props.radius || "8px"};
  flex-wrap: ${(props) => props.wrap ?? props.wrap};
  flex: ${(props) => props.flex ?? props.flex};
  @media only screen and (min-width: 1024px) {
    flex-direction: ${(props) => props.directionMd ?? props.directionMd};
    flex: ${(props) => props.flexMd ?? props.flexMd};
    width: ${(props) => props.widthMd ?? props.widthMd};
    margin: ${(props) => props.marginMd ?? props.marginMd};
    padding: ${(props) => props.paddingMd ?? props.paddingMd};
    flex-wrap: ${(props) => props.wrapMd ?? props.wrapMd};
  }
`;

export default FranchiseeEdit;
