import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { ContentContainer, Wrapper, Container } from "../../Assets/css/commonVer2";
import HomeToday from "./HomeToday";
import HomeNotice from "./HomeNotice";
import HomeSell from "./HomeSell";
import HomeVideo from "./HomeVideo";
import HomeInsta from "./HomeInsta";
import HomeEvent from "./HomeEvent";
import Authority from "../../Components/Modal/Authority";
import API from "../../Utiles/API";

function Home() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [Franchise_Id] = useState(sessionStorage.getItem("franchise_id"));
  const [sale, setSale] = useState([]);
  const [deposit, setDeposit] = useState("");
  const [event, setEvent] = useState([]);
  const [notice, setNotice] = useState([]);
  const [shop, setShop] = useState([]);

  useEffect(() => {
    const MainList = async () => {
      try {
        const res = await API.get(`/storeowner/main/${Franchise_Id}`);
        if (res.data.status) {
          setSale(res.data.result?.sale_check_result[0]);
          setDeposit(res.data.result?.users_deposit[0]);
          setNotice(res.data.result.getnoitce_list);
          setEvent(res.data.result.getnoitce_event);
          setShop(res.data.result.product_sale_many);
        }
      } catch (error) {}
    };
    MainList();
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          <ContentContainer>
            <Wrapper type={1}>
              {isMobile ? (
                <Container>
                  <HomeToday sale={sale} deposit={deposit} />
                  <HomeSell shop={shop} />
                  <HomeVideo />
                  <HomeNotice notice={notice} />
                  <HomeEvent event={event} />
                  <HomeInsta />
                </Container>
              ) : (
                <Container>
                  <HomeToday sale={sale} deposit={deposit} />

                  <DivSection>
                    <DivTwoPerThree>
                      <HomeSell shop={shop} />
                    </DivTwoPerThree>

                    <DivOnePerThree>
                      <HomeNotice notice={notice} />
                    </DivOnePerThree>
                  </DivSection>

                  <DivSection>
                    <DivTwoPerThree>
                      <HomeVideo />
                    </DivTwoPerThree>

                    <DivOnePerThree>
                      <HomeEvent event={event} />
                    </DivOnePerThree>
                  </DivSection>
                  <HomeInsta />
                </Container>
              )}
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const DivSection = styled.div`
  display: flex;
  gap: 14px;
  maxwidth: 100%;
  @media (max-width: 1201px) {
    flex-direction: column;
  }
`;

const DivTwoPerThree = styled.div`
  width: calc((100% / 3) * 2);
  @media (max-width: 1201px) {
    width: 100%;
  }
`;

const DivOnePerThree = styled.div`
  width: calc(100% / 3);
  @media (max-width: 1201px) {
    width: 100%;
  }
`;

export default Home;
