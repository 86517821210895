import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  TableWrap,
  Th,
  Td,
  Btn,
  Wrapper,
  Container,
  ContentContainer,
  FlexBox,
  WhiteBox,
  Title,
  DateInputBox,
} from "../../Assets/css/commonVer2";
import HeaderSub from "../../Components/Header/HeaderSub";
import API from "../../Utiles/API";
import { AddComma, dateToString, Xlsx_download } from "../../Utiles/Custom";
import Authority from "../../Components/Modal/Authority";
import InfoModal from "../../Components/Modal/InfoModal";

function SalesItemRank() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const date = new Date();
  const year = date.getFullYear(); // 년
  const month = date.getMonth(); // 월
  const day = date.getDate(); // 일
  const [startDate, setStartDate] = useState(new Date(year, month, day - 1));
  const [endDate, setEndDate] = useState(new Date());
  const [Franchise_id] = useState(sessionStorage.getItem("franchise_id"));
  const [data, setData] = useState([]);

  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");

  const getSaleList = async () => {
    try {
      const res = await API.get(
        `/storeowner/sale-select/sale-rank/${Franchise_id}?filterDatemin=${dateToString(
          startDate
        )}&filterDatemax=${dateToString(endDate)}`
      );
      if (res.data.status) {
        setData(res.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onExcelDown = () => {
    if (data.length > 0) {
      var xlsxTemp = data.map(function (item) {
        return {
          No: item.idx,
          상품분류: item.name,
          상품명: item.product_name,
          판매수량: item.cnt,
          결제금액: item.payment_amount,
        };
      });
      // 다운로드 실행
      Xlsx_download(xlsxTemp, `판매상품순위_${dateToString(startDate)}~${dateToString(endDate)}`);
    } else {
      setTextMsg("다운받을 데이터가 없습니다.");
      setTextModal(true);
    }
  };

  useEffect(() => {
    getSaleList();
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          <HeaderSub title={"전체 판매 순위 조회"} />
          <ContentContainer>
            <Wrapper ptM="70px" style={{ paddingBottom: "150px" }}>
              <Container>
                {!isMobile && (
                  <FlexBox justify={"space-between"} style={{ flex: 1 }} margin={"0 0 10px"}>
                    <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                      전체 판매 순위 조회
                    </Title>
                  </FlexBox>
                )}
                {isMobile && (
                  <FlexBox margin="10px 0">
                    <FlexBox gap="8px" justify={"end"} width="100%">
                      <DateInputBox>
                        <DatePicker
                          dateFormatCalendar="yyyy년 MM월"
                          dateFormat="yyyy-MM-dd"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={endDate}
                        />
                      </DateInputBox>
                      <DateInputBox>
                        <DatePicker
                          dateFormatCalendar="yyyy년 MM월"
                          dateFormat="yyyy-MM-dd"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </DateInputBox>
                      <Btn
                        color={"#fff"}
                        background={"#FF4A4A"}
                        radius={"6px"}
                        size={"12px"}
                        sizeMd={"14px"}
                        weight={"700"}
                        padding={"9px 19px"}
                        border="unset"
                        margin="2px 0 0"
                        lineHeight={"22px"}
                        style={{ minWidth: "60px" }}
                        onClick={() => getSaleList()}
                      >
                        조회
                      </Btn>
                    </FlexBox>
                  </FlexBox>
                )}
                <WhiteBox margin={"17px 0 0 "} marginMd={"10px 0 0"}>
                  {!isMobile && (
                    <>
                      <FlexBox justify="end">
                        <FlexBox gap="6px" gapMd="13px" justify={"space-evenly"}>
                          <DateInputBox>
                            <DatePicker
                              dateFormatCalendar="yyyy년 MM월"
                              dateFormat="yyyy-MM-dd"
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              startDate={startDate}
                              endDate={endDate}
                              maxDate={endDate}
                            />
                          </DateInputBox>
                          <DateInputBox>
                            <DatePicker
                              dateFormatCalendar="yyyy년 MM월"
                              dateFormat="yyyy-MM-dd"
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              startDate={startDate}
                              endDate={endDate}
                              minDate={startDate}
                            />
                          </DateInputBox>
                          <Btn
                            color={"#fff"}
                            background={"#FF4A4A"}
                            radius={"6px"}
                            size={"14px"}
                            weight={"700"}
                            padding={"9px 19px"}
                            border="unset"
                            margin="2px 0 0"
                            lineHeight={"22px"}
                            width={"75px"}
                            onClick={() => getSaleList()}
                          >
                            조회
                          </Btn>
                        </FlexBox>
                      </FlexBox>
                      <FlexBox justify="end" margin="20px 0 30px">
                        <Btn
                          border={"1px solid #666666"}
                          hoverBorder={"1px solid #C7C7C7"}
                          hoverBackground={"#C7C7C7"}
                          color={"#666666"}
                          hoverColor={"#FFFFFF"}
                          weight={"500"}
                          hoverWeight={"700"}
                          padding={"9px 19px"}
                          size={"14px"}
                          radius={"6px"}
                          lineHeight={"22px"}
                          onClick={onExcelDown}
                        >
                          엑셀 다운
                        </Btn>
                      </FlexBox>
                    </>
                  )}
                  <TableWrap>
                    <table style={{ width: "100%" }}>
                      <colgroup>
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "12%" }} />
                        <col style={{ width: "15.5%" }} />
                        <col style={{ width: "15.5%" }} />
                        <col style={{ width: "16%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>No</Th>
                          <Th>상품분류</Th>
                          <Th>상품명</Th>
                          <Th>판매수량</Th>
                          <Th>결제금액</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 ? (
                          data.map((item, key) => (
                            <tr key={key}>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.idx}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.name}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.product_name}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.cnt}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{AddComma(item.payment_amount)}원</Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={5}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                </WhiteBox>
              </Container>
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

export default SalesItemRank;
