import React, { useEffect, useState, useReducer } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

import {
  ContentContainer,
  Wrapper,
  Container,
  WhiteBox,
  FlexBox,
  Title,
  Text,
  Btn,
  TableWrap,
  Th,
  Td,
} from "../../Assets/css/commonVer2";
import ico_dollar from "../../Assets/images/ico_dollar.svg";
import ico_shoppingmall_sales from "../../Assets/images/ico_shoppingmall_sales.svg";
import Header from "../../Components/Header/HeaderVer2";
import Authority from "../../Components/Modal/Authority";
import InfoModal from "../../Components/Modal/InfoModal";
import Pagination from "../../Components/Paging/Pagination";
import ExChartBar from "../../Components/Chart/ExChartBar";
import { searchToObject } from "../../Utiles/searchToObject";
import { Xlsx_download, AddComma } from "../../Utiles/Custom";
import { dateToString } from "../../Utiles/dateToString";
import { delayCall } from "../../Utiles/delayCall";
import { getLastDayOfCurrentMonth } from "../../Utiles/getLastDayOfCurMon";
import API from "../../Utiles/API";
import { getShopSales } from "../../Utiles";
import { SearchShopSalesReducer } from "../../Store";
import ShopSalesSearchBar from "./Components/ShopSalesSearchBar";

// 쇼핑몰 > 매출관리
/*
- 페이지 상태
1. 페이지 최초 진입 시, callGetShopSales() 와 getShopProductSales() 실행
2. 검색 시, 검색 객체 searchState 에서 일별/월별, 검색 시작일, 검색 종료일 상태값을 바꿈

- API
1. 매출 관리 GET
start_date 와 end_date 를 yyyy-mm-dd 형식으로 보내야 함
2. 상품별 매출 GET
start_date 는 yyyy-mm-dd 00:00:00 end_date 는 yyyy-mm-dd 23:59:59 형식으로 보내야 함
*/

function ShopSales() {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  
  // 모달
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");

  // 일별, 월별 토글 state
  const [dateOptionState, setDateOptionState] = useState(searchToObject().option || "day");
  // 날짜 제어 state
  const date = new Date();
  const year = date.getFullYear(); // 년
  const month = date.getMonth(); // 월
  const day = date.getDate(); // 일
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);

  // 매출 데이터
  const [totalAmount, setTotalAmount] = useState(0); // 총 매출
  const [totalOrderCount, setTotalOrderCount] = useState(0); // 총 주문건수
  const [chart, setChart] = useState([]); // 매출 그래프
  const [tableData, setTableData] = useState([]); // 상품별 매출 표

  // 상품별 매출 표_페이징
  const [pagination, setPagination] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  // 검색 객체
  const [searchState, dispatchSearchState] = useReducer(SearchShopSalesReducer, {
    start_date: dateToString(new Date(year, month, day - 7)),
    end_date: dateToString(new Date()),
    option: dateOptionState,
    search: true,
    ...searchToObject(),
  });

  // API
  // 1. 매출관리 데이터(총 매출, 총 주문건수, 매출 그래프) 조회
  function callGetShopSales() {
    // 검색 객체는 자동으로 등록이 되지만 보여주기 위한 날짜 객체를 넣는다.
    // 날짜 선택 창에 검색 객체랑 동일한 날짜를 넣어준다.
    setStartDate(new Date(searchState.start_date));
    setEndDate(new Date(searchState.end_date));

    getShopSales(searchState)
      .then((res) => {
        if (res.status) {
          setTotalAmount(res.results.total_amount);
          setTotalOrderCount(res.results.total_order_count);

          // ExChartBar 컴포넌트에 전달할 props 데이터
          // (매출일별 주문건수 필요 시 데이터 추가해야 함)
          let arr = [];
          res.results.graph.map((i) => {
            let obj = {};
            obj["y"] = i["total"];
            obj["x"] = i["time"];
            arr.push(obj);
          })
          setChart(arr);
          
          dispatchSearchState({ type: "CHANGE_SEARCH", value: false });
        }
      })
      .catch(() => {
        dispatchSearchState({ type: "CHANGE_SEARCH", value: false });
      });
  }

  // 2. 상품별 매출 표 조회
  const getShopProductSales = async () => {
    let paramStartDate = searchState.start_date + " 00:00:00";
    let paramEndDate = searchState.end_date + " 23:59:59";

    let api_link = `/shoppingmall/sale-product?&start_date=${paramStartDate}&end_date=${paramEndDate}&page=${currentPage}&unit=10`;

    await API.get(api_link).then((res) => {
      if (res.data.status) {
        setTableData(res.data.results);
        setPagination(res.data.pagination);
      }
    });
  }

  // 검색 버튼 클릭 함수
  function searchSales () {
    dispatchSearchState({ type: "CHANGE_SEARCH", value: true });
  }

  // 상품별 매출 표 페이지 이동
  const movePage = (e) => {
    setCurrentPage(e);
  };

  // 상품별 매출 엑셀
  const onExcelDown = async () => {
    // 데이터 유무
    if (tableData.length > 0) {
      // api 호출
      let api_link = `/shoppingmall/sale-product?&start_date=${startDate}&end_date=${endDate}&page=${currentPage}&unit=99999999`;

      const res = await API.get(api_link);
      if (res.data.status && res.data.results.length > 0) {
        // 엑셀 포맷으로 변경
        var xlsxTemp = res.data.results.map(function (item) {
          return {
            상품명: item.product_name,
            바코드번호: item.barcode_num,
            수량: item.count,
            금액: item.total_price,
          };
        });
        // 다운로드 실행
        Xlsx_download(xlsxTemp, `쇼핑몰 상품별 매출`);
      } else {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    } else {
      setTextMsg("다운받을 데이터가 없습니다.");
      setTextModal(true);
    }
  };

  // 일별, 월별 토글 state 변경
  useEffect(() => {
    if (dateOptionState === "month") {
      dispatchSearchState({ type: "CHANGE_START_DATE", value: dateToString(new Date(startDate.getFullYear(), startDate.getMonth(), 1)) });
      dispatchSearchState({ type: "CHANGE_END_DATE", value: dateToString(new Date(endDate.getFullYear(), endDate.getMonth(), getLastDayOfCurrentMonth(endDate))) });
    }

    dispatchSearchState({ type: "CHANGE_DATE_OPTION", value: dateOptionState });
  }, [dateOptionState]);

  // 페이지 최초 진입, 페이징 시 실행 (start_date 와 end_date 는 searchState 객체가 변경)
  useEffect(() => {
    getShopProductSales();
  }, [location, currentPage]);

  // 현재 페이지에 왔을 때 searchState 에 맞는 데이터 조회
  useEffect(() => {
    if (searchState.search) {
      const params = {};
      Object.entries(searchState).forEach((item) => (params[item[0]] = item[1]));
      navigate(
        {
          pathname: "/shoppingmall",
          search: `?${createSearchParams(params)}`,
        },
        { replace: true }
      );
    }
  }, [searchState]);

  // 페이지 최초 진입 시 실행
  useEffect(() => {
    // 1. searchState를 searchBar에서도 변경하기 때문에 초기에 두번 작동된다.
    // 2. 검색버튼을 여러번 누를때를 대비해서 방지 가능
    delayCall(callGetShopSales);
  }, [location]);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {isMobile && <Header title={"매출 관리"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    매출 관리 📋
                  </Title>
                )}
                <ShopSalesSearchBar
                  searchState={searchState}
                  dispatchSearchState={dispatchSearchState}
                  searchSales={searchSales}
                  dateOptionState={dateOptionState}
                  setDateOptionState={setDateOptionState}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  startDate={startDate}
                  endDate={endDate}
                />
                <FlexBox gapLg={"26px"} gap={"unset"} directionLg={"row"} direction={"column"}>
                  <WhiteFlexBox>
                    <div>
                      <Text
                        size={"18px"}
                        sizeLg={"26px"}
                        color={"#FF4A4A"}
                        weight={"700"}
                        style={{
                          fontFamily: "Montserrat",
                          marginBottom: "10px",
                        }}
                      >
                        {AddComma(totalAmount)}
                        <TextSpan>원</TextSpan>
                      </Text>
                      <Text size={"14px"} sizeLg={"18px"} color={"#1E1E1E"} weight={"400"} align={"left"}>
                        총 매출
                      </Text>
                    </div>
                    <ImgBox>
                      <img src={ico_dollar} />
                    </ImgBox>
                  </WhiteFlexBox>
                  <WhiteFlexBox>
                    <div>
                      <Text
                        size={"18px"}
                        sizeLg={"26px"}
                        color={"#FF4A4A"}
                        weight={"700"}
                        style={{
                          fontFamily: "Montserrat",
                          marginBottom: "10px",
                          textAlign: "left",
                        }}
                      >
                        {AddComma(totalOrderCount)}
                        <TextSpan>건</TextSpan>
                      </Text>
                      <Text size={"14px"} sizeLg={"18px"} color={"#1E1E1E"} weight={"400"} align={"left"}>
                        총 주문건수
                      </Text>
                    </div>
                    <ImgBox>
                      <img src={ico_shoppingmall_sales} />
                    </ImgBox>
                  </WhiteFlexBox>
                </FlexBox>
                <FlexBox
                  align={"start"}
                  direction={"column"}
                  directionLg={"row"}
                  width={"100%"}
                  gap={"26px"}
                >
                  {/* 매출 그래프 */}
                  <HalfDiv>
                    <WhiteBox style={{ margin: "0" }}>
                      <Text size={"16px"} align={"left"} weight={"700"}>
                        매출 그래프
                      </Text>
                      <div style={{ margin: "30px 0 0" }}>
                        <ExChartBar chart={chart} />
                      </div>
                    </WhiteBox>
                  </HalfDiv>
                  {/* 상품별 매출 표 */}
                  <HalfDiv>
                    <WhiteBox style={{ margin: "0" }}>
                      <FlexBox justify={"space-between"} margin={"0 0 30px"}>
                        <Text size={"16px"} align={"left"} weight={"700"}>
                          상품별 매출 표
                        </Text>
                        {!isMobile && (
                          <Btn
                            size={"14px"}
                            padding={"8px 18px"}
                            border={"1px solid #666"}
                            color={"#666"}
                            hoverBackground={"#C7C7C7"}
                            hoverColor={"#fff"}
                            hoverBorder={"transparent"}
                            lineHeight={"22px"}
                            hoverWeight={"700"}
                            margin={"0 0 0 10px"}
                            onClick={onExcelDown}
                          >
                            엑셀 다운
                          </Btn>
                        )}
                      </FlexBox>
                      <TableWrap>
                        <table>
                          <colgroup>
                            <col />
                            <col />
                            <col />
                            <col />
                          </colgroup>
                          <thead>
                            <tr>
                              <Th>상품명</Th>
                              <Th>바코드번호</Th>
                              <Th>수량</Th>
                              <Th>금액</Th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData.length > 0 ? (
                              tableData.map((item, key) => (
                                <tr key={key}>
                                  <Td>{item.product_name}</Td>
                                  {/* 프로모션 상품은 바코드번호 없음 */}
                                  <Td>{item.barcode_num ? item.barcode_num : "-"}</Td>
                                  <Td weight={700}>{item.count}개</Td>
                                  <Td weight={700}>{AddComma(item.total_price)}원</Td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan={3}
                                  style={{
                                    borderTop: "1px solid #e1e1e1",
                                    textAlign: "center",
                                    padding: 10,
                                    fontSize: "14px",
                                  }}
                                >
                                  데이터가 없습니다.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </TableWrap>
                      {pagination?.total_page > 0 ? (
                        <Pagination pagination={pagination} movePage={movePage} currentPage={currentPage} />
                      ) : (
                        ""
                      )}
                    </WhiteBox>
                  </HalfDiv>
                </FlexBox>
              </Container>
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const WhiteFlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 28px 40px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 20px 0;

  @media (max-width: 991px) {
    padding: 10px 20px;
    flex: 1;
  
    :nth-child(2) {
      margin: 0 0 20px;
    }
  }
`;

const TextSpan = styled.span`
  font-size: 20px;
  font-weight: 400;

  @media (max-width: 280px) {
    font-size: 14px;
  }
`

const ImgBox = styled.div`
  width: unset;

  @media (max-width: 280px) {
    width: 50px;
  }
`

const HalfDiv = styled.div`
  width: calc(50% - 13px);

  @media (max-width: 991px) {
    width: 100%;
  }
`

export default ShopSales;