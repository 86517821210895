import React from 'react';
import styled from 'styled-components';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import {
  Btn,
  FlexBox,
  DateInputBox,
  SearchInput,
} from '../../../Assets/css/commonVer2';
import "../../../Assets/css/DatePicker.css";
import { dateToString } from '../../../Utiles/dateToString';
import { handleKeyDown } from "../../../Utiles/Custom";

// 관리자 > 쇼핑몰 > 주문내역 조회 및 관리 검색바
export default function SearchBar({
  dispatchSearchState,
  searchOrders, //dispatchSearchState 값 변경 함수
  setInputNumber, // 주문 번호 입력 받을 state
  inputNumber,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {

  return (
    <>
      <SearchBarBox>
        <DateFlexBox>
          {/* startDate 달력 */}
          <DateInputBox>
            <DatePicker
              dateFormatCalendar="yyyy년 MM월"
              selected={startDate}
              onChange={(date) => {
                setStartDate(date)
                dispatchSearchState({ type: "CHANGE_START_DATE", value: dateToString(date) })
              }}
              dateFormat="yyyy-MM-dd"
              maxDate={endDate}
              startDate={startDate}
              endDate={endDate}
            />
          </DateInputBox>
          {/* endDate 달력 */}
          <DateInputBox>
            <DatePicker
              dateFormatCalendar="yyyy년 MM월"
              selected={endDate}
              onChange={(date) => {
                setEndDate(date)
                dispatchSearchState({ type: "CHANGE_END_DATE", value: dateToString(date) })
              }}
              dateFormat="yyyy-MM-dd"
              minDate={startDate}
              startDate={startDate}
              endDate={endDate}
            />
          </DateInputBox>
        </DateFlexBox>
        <InputAndBtnBox>
          <SearchInput
            width={"100%"}
            widthMd={"262px"}
            borderBottom={"1px solid #666"}
            borderBottomColor={"#ff4a4a"}
            transition={"border-color 0.3s ease-in-out"}
            placeholder={"주문 번호를 입력해주세요."}
            type="number"
            pattern="[0-9]+"
            value={inputNumber}
            onChange={(e) => {
              setInputNumber(e.target.value);
            }}
            onKeyDown={(event) => handleKeyDown(event, searchOrders)}
          />
          <Btn
            color={"#fff"}
            background={"#FF4A4A"}
            size={"14px"}
            weight={"700"}
            width={"100%"}
            widthMd={"unset"}
            padding={"8px 18px"}
            lineHeight={"22px"}
            style={{ flexShrink: 0 }}
            onClick={searchOrders}
          >
            검 색
          </Btn>
        </InputAndBtnBox>
      </SearchBarBox>
    </>
  )
}

const SearchBarBox = styled(FlexBox)`
  gap: 10px;
  flex-wrap: wrap;
  justify-content: end;
`

const DateFlexBox = styled(FlexBox)`
  gap: 10px;
  flex: unset;

  @media (max-width: 1024px) {
    flex: 1;
  }
`

const InputAndBtnBox = styled(FlexBox)`
  gap: 10px;
  flex-wrap: nowrap;
  
  @media (max-width: 1024px) {
    width: 100%;
  }
  
  @media (max-width: 1023px) {
    width: 100%;
    flex-wrap: wrap;
  }
`