import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import {
  ContentContainer,
  Wrapper,
  Container,
  WhiteBox,
  FlexBox,
  Title,
  Text,
  Btn,
  TableWrap,
  Th,
  Td,
  CheckLabel,
  CheckIcon,
  SearchInput,
} from "../../Assets/css/commonVer2";
import Header from "../../Components/Header/HeaderVer2";
import Authority from "../../Components/Modal/Authority";
import InfoModal from "../../Components/Modal/InfoModal";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import Pagination from "../../Components/Paging/Pagination";
import BasicSelect from "../../Components/Select/BasicSelect";
import Cate2Select from "../../Components/Select/Cate2Select";
import ProductSearchInputVer2 from "../../Components/SearchInput/ProductSearchInputVer2";
import HandleBack from "../../Components/HandleBack";
import { AddComma, handleKeyDown } from "../../Utiles/Custom";
import { UNIT_10_TO_50_LIST, SEARCH_TYPE_LIST } from "../../Utiles/ListData";
import API from "../../Utiles/API";

// 쇼핑몰 > 상품 리스트

function ShopProduct() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const { handlePopstate } = HandleBack();

  // 모달
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  // 상품 등록, 상품 수정 다녀왔을 때 state
  const parsedShopProductPageState = sessionStorage.getItem("shopProductPageState")
    ? JSON.parse(sessionStorage.getItem("shopProductPageState"))
    : {};

  // 페이징
  const [pagination, setPagination] = useState("");
  // 체크한 상품들
  const [checkItems, setCheckItems] = useState([]);

  // 상품 리스트 데이터
  const [shopProductCnt, setShopProductCnt] = useState(0);
  const [shopProductList, setShopProductList] = useState([]);

  // 본사상품 검색 데이터
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchList, setShowSearchList] = useState(false);

  const [pageSize, setPageSize] = useState(
    parsedShopProductPageState
      ? parsedShopProductPageState?.pageSize
        ? parsedShopProductPageState?.pageSize
        : 10
      : 10,
  );
  const [currentPage, setCurrentPage] = useState(
    parsedShopProductPageState
      ? parsedShopProductPageState?.currentPage
        ? parsedShopProductPageState?.currentPage
        : 1
      : 1,
  );
  const [cate2Value, setCate2Value] = useState(
    parsedShopProductPageState
      ? parsedShopProductPageState?.cate2Value
        ? parsedShopProductPageState?.cate2Value
        : ""
      : "",
  );
  const [filter, setFilter] = useState(
    parsedShopProductPageState
      ? parsedShopProductPageState?.filter
        ? parsedShopProductPageState?.filter
        : "barcode_num"
      : "barcode_num",
  );
  const [inputValue, setInputValue] = useState(
    parsedShopProductPageState
      ? parsedShopProductPageState?.inputValue
        ? parsedShopProductPageState?.inputValue
        : ""
      : "",
  );

  // 상품 등록, 상품 수정 갈 때 sessionStorage에 저장할 객체
  let shopProductPageState = {
    pageSize,
    currentPage,
    cate2Value,
    filter,
    inputValue,
  };

  // API
  // 1. 쇼핑몰 상품 조회
  const getShopProductList = async () => {
    let api_link = `/shoppingmall/product-list?page=${currentPage}&unit=${pageSize}`;
    if (cate2Value) api_link += `&category2nd_id=${cate2Value}`;
    if (inputValue) api_link += `&filter_type=${filter}&filter_value=${inputValue}`;

    await API.get(api_link).then((res) => {
      if (res.data.status) {
        setShopProductCnt(res.data.cntAll);
        setPagination(res.data.pagination);
        setShopProductList(res.data.results);
      }
    });
  };

  // 2. 쇼핑몰 상품 삭제
  const deleteShopProduct = async () => {
    await API.delete(`/shoppingmall/product?shoppingmall_product_ids=${checkItems}`).then((res) => {
      if (res.data.status) {
        setDeleteModal(false);
        window.location.replace("/shoppingmall/product"); // 새로고침
      } else {
        setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    });
  };

  // 아이템 수정 클릭
  const onClickEditProduct = () => {
    if (checkItems.length === 0) {
      setTextMsg("수정할 상품을 선택해주세요.");
      setTextModal(true);
      return;
    }
    if (checkItems.length > 1) {
      setTextMsg("하나만 선택해주세요.");
      setTextModal(true);
      return;
    }
    if (checkItems.length === 1) {
      const foundItem = shopProductList.find((item) => item.idx === checkItems[0]);
      navigate("/shoppingmall/product/edit", {
        state: { idx: foundItem.idx },
      });
      let jsonString = JSON.stringify(shopProductPageState);
      sessionStorage.setItem("shopProductPageState", jsonString);
    }
  };

  // 아이템 삭제 클릭
  const onClickDeleteProduct = () => {
    if (checkItems.length > 0) {
      setDeleteModal(true);
    } else {
      setTextMsg("삭제할 상품을 선택해주세요.");
      setTextModal(true);
    }
  };

  // 검색 함수
  const onSearch = () => {
    setSearchResults([]);
    setShowSearchList(false);
    setCurrentPage(1);
    getShopProductList();
  };

  // 체크박스 개별 체크
  const onChangeChx = (checked, id) => {
    if (checked) {
      setCheckItems((prev) => [...prev, id]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  // 체크박스 전체 체크
  const onChangeAllChk = (checked) => {
    if (checked) {
      const chkArr = shopProductList.map((item) => item.idx);
      setCheckItems(chkArr);
    } else {
      setCheckItems([]);
    }
  };

  const movePage = (e) => {
    setCurrentPage(e);
    setCheckItems([]);
  };

  useEffect(() => {
    getShopProductList();
  }, [currentPage, pageSize]);

  useEffect(() => {
    sessionStorage.removeItem("shopProductPageState");

    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {isMobile && <Header title={"상품 리스트"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    상품 리스트 🛒
                  </Title>
                )}
                <WhiteBox margin={"0 0"}>
                  <FlexBox wrap={"wrap"} gap={"10px"} justify={"end"}>
                    <Cate2Select
                      idx={1}
                      cate2Value={cate2Value}
                      setCate2Value={setCate2Value}
                      placeholder={"상품 분류"}
                    />
                    <BasicSelect
                      selectedValue={filter}
                      setSelectedValue={setFilter}
                      data={SEARCH_TYPE_LIST}
                      canSelectAll={false}
                    />
                    <InputAndBtnBox>
                      {filter == "barcode_num" ? (
                        <SearchInput
                          borderBottom={"1px solid #666"}
                          borderBottomColor={"#ff4a4a"}
                          transition={"border-color 0.3s ease-in-out"}
                          placeholder={"바코드 번호를 입력해주세요."}
                          value={inputValue}
                          onChange={(e) => {
                            setInputValue(e.target.value);
                          }}
                          onKeyDown={(event) => handleKeyDown(event, onSearch)}
                        />
                      ) : (
                        <ProductSearchInputVer2
                          showSearchList={showSearchList}
                          setShowSearchList={setShowSearchList}
                          searchResults={searchResults}
                          setSearchResults={setSearchResults}
                          inputValue={inputValue}
                          setInputValue={setInputValue}
                          onSearch={onSearch}
                        />
                      )}
                      <Btn
                        color={"#fff"}
                        background={"#FF4A4A"}
                        size={"14px"}
                        weight={"700"}
                        width={"100%"}
                        widthMd={"unset"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        onClick={onSearch}
                        setCate2Value
                        style={{ flexShrink: 0 }}
                      >
                        검 색
                      </Btn>
                    </InputAndBtnBox>
                  </FlexBox>
                  <FlexBox
                    justify={"space-between"}
                    gap={"10px"}
                    margin={"20px 0 10px"}
                    marginMd={"20px 0 30px"}
                    wrap={"wrap"}
                    wrapMd={"no-wrap"}
                  >
                    <FlexBox gap={"10px"} width={"100%"} widthMd={"auto"}>
                      <Text
                        width={"100%"}
                        widthMd={"unset"}
                        color={"#FF4A4A"}
                        size={"16px"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        style={{
                          flexShrink: 0,
                        }}
                      >
                        총 상품 수 :{" "}
                        <span style={{ fontWeight: "700" }}>{AddComma(shopProductCnt ? shopProductCnt : 0)}개</span>
                      </Text>
                      {!isMobile && (
                        <BasicSelect
                          width={"130px"}
                          data={UNIT_10_TO_50_LIST}
                          selectedValue={pageSize}
                          setSelectedValue={setPageSize}
                          canSelectAll={false}
                          setCurrentPage={setCurrentPage}
                        />
                      )}
                    </FlexBox>
                    <FlexBox gap={"10px"} justify={"flex-end"} width={"100%"} widthMd={"auto"}>
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        onClick={() => {
                          navigate("/shoppingmall/product/write");
                          let jsonString = JSON.stringify(shopProductPageState);
                          sessionStorage.setItem("shopProductPageState", jsonString);
                        }}
                      >
                        등록
                      </Btn>
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        onClick={onClickEditProduct}
                      >
                        수정
                      </Btn>
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        onClick={onClickDeleteProduct}
                      >
                        삭제
                      </Btn>
                    </FlexBox>
                  </FlexBox>
                  <TableWrap>
                    <table style={{ width: "100%", minWidth: "1024px" }}>
                      <colgroup>
                        <col style={{ width: "5.5%" }} />
                        <col style={{ width: "10.5%" }} />
                        <col style={{ width: "15.5%" }} />
                        <col />
                        <col style={{ width: "10.5%" }} />
                        <col style={{ width: "11%" }} />
                        <col style={{ width: "20%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>
                            <CheckLabel htmlFor="chkAll" style={{ justifyContent: "center" }}>
                              <input
                                type="checkbox"
                                id="chkAll"
                                onChange={(event) => onChangeAllChk(event.target.checked)}
                                checked={checkItems.length === shopProductList.length ? true : false}
                              />
                              <CheckIcon>
                                <svg viewBox="0 0 24 24">
                                  <polyline points="19 7 10 17 5 12" />
                                </svg>
                              </CheckIcon>
                            </CheckLabel>
                          </Th>
                          <Th>상품분류</Th>
                          <Th>바코드번호</Th>
                          <Th>상품명</Th>
                          <Th>매입가</Th>
                          <Th>최종소비자가</Th>
                          <Th>등록일시</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {shopProductList.length > 0 ? (
                          shopProductList.map((item, key) => (
                            <tr
                              style={{ cursor: "pointer" }}
                              key={item.idx}
                              onClick={(event) => {
                                const targetElement = event.target;
                                if (targetElement.nodeName !== "INPUT") {
                                  const isChecked = document.getElementById(`prod${item.idx}`).checked;
                                  onChangeChx(!isChecked, item.idx);
                                }
                              }}
                            >
                              <Td align={"center"}>
                                <CheckLabel htmlFor={`prod${item.idx}`} style={{ justifyContent: "center" }}>
                                  <input
                                    type="checkbox"
                                    name="prod"
                                    id={`prod${item.idx}`}
                                    readOnly
                                    checked={checkItems.includes(item.idx) ? true : false}
                                  />
                                  <CheckIcon>
                                    <svg viewBox="0 0 24 24">
                                      <polyline points="19 7 10 17 5 12" />
                                    </svg>
                                  </CheckIcon>
                                </CheckLabel>
                              </Td>
                              <Td>{item ? item.category2nd_name : "-"}</Td>
                              <Td>{item ? item.barcode_num : "-"}</Td>
                              <Td>{item ? item.product_name : "-"}</Td>
                              <Td weight={700}>{item ? AddComma(item.purchase_price) : 0}원</Td>
                              <Td weight={700}>{item ? AddComma(item.consumer_price) : 0}원</Td>
                              <Td>{item ? item.created_at : "-"}</Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={7}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  {pagination?.total_page > 0 ? (
                    <Pagination pagination={pagination} movePage={movePage} currentPage={currentPage} />
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <ConfirmModal
                modal={deleteModal}
                setModal={setDeleteModal}
                onSubmit={deleteShopProduct}
                description={"선택된 상품을 삭제하시겠습니까?"}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const InputAndBtnBox = styled.div`
  display: flex;
  gap: 10px;

  @media all and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: end;
  }
`;

export default ShopProduct;
