import React from "react";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { putInventory } from "../../Utiles";
import { utils, writeFile } from "xlsx";
import Header from "../../Components/Header/HeaderVer2";
import {
  TextBar,
  WhiteBox,
  Title,
  ContentContainer,
  Btn,
  Container,
  FlexBox,
  Wrapper,
  Th,
  Td,
  Text,
  CheckLabel,
  CheckIcon,
  TableWrap,
  SearchInput,
  DisabledDiv,
} from "../../Assets/css/commonVer2";
import { UNIT_10_TO_40_LIST, SEARCH_TYPE_LIST, INVENTORY_STATUS } from "../../Utiles/ListData";
import ProductSearchInputVer2 from "../../Components/SearchInput/ProductSearchInputVer2";
import API from "../../Utiles/API";
import Authority from "../../Components/Modal/Authority";
import { AddComma, handleKeyDown } from "../../Utiles/Custom";
import { dateToString } from "../../Utiles/dateToString";
import Loading from "../../Components/Loading";
import Pagination from "../../Components/Paging/Pagination";
import HandleBack from "../../Components/HandleBack";
import BundleProductModal from "./Components/BundleProductModal";
import FranSelect from "../../Components/Select/FranSelect";
import BasicSelect from "../../Components/Select/BasicSelect";
import InfoModal from "../../Components/Modal/InfoModal";

/* code from sub_outsourcing */

// 상품마스터 > 재고관리

export default function Inventory() {
  const navigate = useNavigate();
  const { handlePopstate } = HandleBack();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const user_id = sessionStorage.getItem("user_id");
  const location = useLocation();

  const [inventoryItems, setInventoryItems] = useState([]);
  const [tempInventoryItems, setTempInventoryItems] = useState([]); // 재고 수정 시 임시 데이터를 담을 state
  const [checkItems, setCheckItems] = useState(["warehousing", "take_back", "disposal", "lost"]); // 체크된 아이템을 담을 배열
  const [pagination, setPagination] = useState(""); // 페이징에 사용할 스테이트
  const [totalAmount, setTotalAmount] = useState(0); // 총 액
  const [isEditInventory, setIsEditInventory] = useState(false); // 재고 수정 boolean 값
  const [searchFranchise, setSearchFranchise] = useState({}); // 가맹점 검색 결과를 돌려 받을 state
  const [franchiseId, setFranchiseId] = useState(1); // url에서 가맹점 id를 가지고 오고, 가맹점 선택 텍스트에 가맹점 이름을 찾아주기 위한 state

  const [unit, setUnit] = useState(10);
  const [nowPage, setNowPage] = useState(1);

  const [franText, setFranText] = useState("본사");
  const [franShow, setFranShow] = useState(false);

  const [noDataMsg, setNoDataMsg] = useState("가맹점을 선택해주세요.");

  const [filter, setFilter] = useState("barcode_num");
  const [inputValue, setInputValue] = useState("");
  const [showSearchList, setShowSearchList] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const [textModal, setTextModal] = useState(false); // 모달 창 표시 여부 상태
  const [textMsg, setTextMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const [modal, setModal] = useState(false); // 묶음상품 모달 창 표시 여부 상태
  const [bundleData, setBundleData] = useState([]);

  // 수정  API 통신
  function editInventory() {
    setLoading(true);
    const mergedData = {
      user_id: user_id,
      inventoryInfo: tempInventoryItems,
    };

    putInventory(mergedData)
      .then((res) => {
        setLoading(false);
        if (res.data.stauts) {
          setIsEditInventory(!isEditInventory);
          const options = checkItems.length > 3 ? "entire" : checkItems;
          const params = {
            franchise_id: searchFranchise.franchise_id,
            page: nowPage,
            options: options,
            unit: unit,
            inputValue: inputValue,
            filter: filter,
          };
          navigate(
            {
              pathname: "/product/inventory",
              search: `?${createSearchParams(params)}`,
            },
            { replace: true },
          );
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setLoading(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  }

  const getSupercodeProduct = async (barcode_num) => {
    await API.get(`/admin/super-code?page=1&unit=10&block=10&filter_type=barcode_num&filter_value=${barcode_num}`).then(
      (res) => {
        if (res.data.status) {
          const nameList = res.data.results[0].products?.map((item) => ({
            product_name: item.product_name,
          }));
          setBundleData(nameList);
          setModal(true);
        }
      },
    );
  };

  function searchInventory() {
    const options = checkItems.length > 3 ? "entire" : checkItems;
    const params = {
      franchise_id: franchiseId,
      page: nowPage,
      options: options,
      unit: unit,
      inputValue: inputValue,
      filter: filter,
    };
    navigate(
      {
        pathname: "/product/inventory",
        search: `?${createSearchParams(params)}`,
      },
      { replace: true },
    );
  }

  // 아이템 재고량 수정 메서드
  function onChangeValue(target, type) {
    let value = target.value;
    if (!target.value) {
      value = 0;
    }
    const array = [...tempInventoryItems].map((item) => {
      const updatedItem = { ...item };
      if (updatedItem.inventory_id == target.id) {
        if (type === "warehousing_count") {
          updatedItem.warehousing_count = parseInt(value);
        } else if (type === "take_back_count") {
          updatedItem.take_back_count = parseInt(value);
        } else if (type === "disposal_count") {
          updatedItem.disposal_count = parseInt(value);
        } else if (type === "lost_count") {
          updatedItem.lost_count = parseInt(value);
        }
        return updatedItem;
      }
      return item;
    });
    setTempInventoryItems([...array]);
  }

  // tempInventoryItems 입고,반품.폐기,분실 0으로 변경, 필요 데이터만 남김
  function updateCountsToZero(tempInventoryItems) {
    return tempInventoryItems.map((item) => ({
      // ...item,
      inventory_id: item.inventory_id,
      product_id: item.product_id,
      warehousing_count: 0,
      take_back_count: 0,
      disposal_count: 0,
      lost_count: 0,
    }));
  }

  // 체크박스 단일 선택
  const handleSingleCheck = (checked, id) => {
    if (checked) {
      // 단일 선택 시 체크된 아이템을 배열에 추가
      setCheckItems((prev) => [...prev, id]);
    } else {
      // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  // 체크박스 전체 선택
  const handleAllCheck = (checked) => {
    if (checked) {
      // 전체 선택 클릭 시 데이터의 모든 아이템(id)를 담은 배열로 checkItems 상태 업데이트
      const idArray = [];
      INVENTORY_STATUS.forEach((el) => idArray.push(el.value));

      setCheckItems(idArray);
    } else {
      // 전체 선택 해제 시 checkItems 를 빈 배열로 상태 업데이트
      setCheckItems([]);
    }
  };

  function movePage(e) {
    setNowPage(e);
    // 이 부분은 임시로 짜둔 코드입니다. FranchiseRevenue의 검색 방식과 동일하게 짤 것.
    const options = checkItems.length > 3 ? "entire" : checkItems;
    const params = {
      franchise_id: searchFranchise.franchise_id,
      // page: e.target.dataset.value,
      page: e,
      options: options,
      unit: unit,
      inputValue: inputValue,
      filter: filter,
    };
    navigate(
      {
        pathname: "/product/inventory",
        search: `?${createSearchParams(params)}`,
      },
      { replace: true },
    );
  }

  function ExportHandler() {
    if (inventoryItems.length > 0) {
      const options = checkItems.length > 3 ? "entire" : checkItems;
      let api_link = `/admin/inventory?franchise_id=${franchiseId}&page=1&options=${options}&unit=99999999&block=10`;
      if (inputValue !== "") api_link += `&search_word=${inputValue}&product_search_type=${filter}`;
      API.get(api_link).then((res) => {
        if (res.data.status && res.data.results.table.length > 0) {
          const data = [];
          const excelTableHead = [
            [
              "No",
              "상품분류",
              "바코드",
              "상품명",
              "공급가",
              "매입가",
              "판매가",
              "현재고",
              "입고",
              "반품",
              "폐기",
              "분실",
            ],
          ];

          // excel 문서 만들기
          const wb = utils.book_new();
          // 열 순서를 sheet화
          const ws = utils.json_to_sheet([]);
          // ws에 excelTableHead를 추가
          utils.sheet_add_aoa(ws, excelTableHead);
          res.data.results.table.forEach((item) => {
            data.push({
              row_num: `${item.row_num}`,
              category2nd_name: `${item.category2nd_name}`,
              barcode_num: `${item.barcode_num}`,
              product_name: `${item.product_name}`,
              shipping_price: `${item.shipping_price}`,
              purchase_price: `${item.purchase_price}`,
              consumer_price: `${item.consumer_price}`,
              current_count: `${item.warehousing_count + item.take_back_count - item.disposal_count - item.lost_count}`,
              warehousing_count: `${item.warehousing_count}`,
              take_back_count: `${item.take_back_count}`,
              disposal_count: `${item.disposal_count}`,
              lost_count: `${item.lost_count}`,
            });
          });

          utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
          utils.book_append_sheet(wb, ws, "Report");
          // 두 번째 arg에는 export 될 파일의 이름을 넣어주면 된다.
          writeFile(wb, `재고관리_${dateToString(new Date())}.xlsx`);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      });
    } else {
      setTextMsg("다운받을 데이터가 없습니다.");
      setTextModal(true);
    }
  }

  useEffect(() => {
    //이 때는 검색하면 안 된다.
    const params = new URLSearchParams(location.search);
    let franchise_id = params.get("franchise_id");
    let page = params.get("page");
    let options = params.getAll("options").join();
    let unit = params.get("unit");
    let inputValue = params.get("inputValue");
    let filter = params.get("filter");

    // 재고관리 페이지 default 본사
    if (location.search === "") {
      franchise_id = franchiseId;
      page = 1;
      options = "entire";
      unit = 10;
      inputValue = "";
      filter = "barcode_num";
    }

    if (franchise_id && page && options && unit) {
      let api_link = `/admin/inventory?franchise_id=${franchise_id}&page=${page}&options=${options}&unit=${unit}&block=10`;
      if (inputValue !== "") api_link += `&filter_type=${filter}&filter_value=${inputValue}`;
      API.get(api_link).then((res) => {
        if (res.data.status) {
          setTotalAmount(res.data.results.entire_price);
          setInventoryItems(res.data.results.table);
          setTempInventoryItems(updateCountsToZero(res.data.results.table));
          setPagination(res.data.pagination);
        }
      });

      // 체크한 아이템들 추가
      if (options.includes("entire")) {
        // 전체 체크 되어 있을 경우
        handleAllCheck(true);
      } else {
        setCheckItems(options.split(","));
      }

      // 프렌차이즈 id 수정
      setFranchiseId(franchise_id);
      setSearchFranchise((item) => {
        return { ...item, franchise_id: franchise_id };
      });

      setUnit(unit);
      setFilter(filter);
      setInputValue(inputValue);
      if (noDataMsg === "가맹점을 선택해주세요.") {
        setNoDataMsg("데이터가 없습니다.");
      }
    }
  }, [location]);

  useEffect(() => {
    searchInventory();
  }, [unit]);

  useEffect(() => {
    setInputValue("");
  }, [filter]);

  useEffect(() => {
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {loading ? <Loading /> : null}
          <>
            {isMobile && <Header title={"재고 관리"} />}
            <ContentContainer>
              <Wrapper type={2}>
                <Container>
                  {!isMobile && (
                    <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                      재고 관리 📋
                    </Title>
                  )}
                  {/* 웹 - 안내사항 시작 */}
                  <div className="greyBox d-none d-md-block" style={{ marginTop: "24px", padding: "28px 40px" }}>
                    <Text
                      color={"#666666"}
                      size={"18px"}
                      weight={"700"}
                      align={"left"}
                      margin={"0 0 10px 0"}
                      style={{
                        latterSpacing: "-0.36px",
                        lineHeight: "normal",
                      }}
                    >
                      유의사항
                    </Text>
                    <TextBar
                      color={"#666666"}
                      align={"left"}
                      style={{
                        latterSpacing: "-0.28px",
                        lineHeight: "25px",
                      }}
                    >
                      모든 상품의 재고 단위는 <BoldText>”개”</BoldText> 입니다.
                    </TextBar>
                    <TextBar
                      align={"left"}
                      style={{
                        latterSpacing: "-0.28px",
                        lineHeight: "25px",
                      }}
                    >
                      표의 묶음번호를 클릭하면 <BoldText>”묶음상품”</BoldText> 확인이 가능합니다.
                    </TextBar>
                  </div>
                  <WhiteBox margin={"0 0"}>
                    <FlexBox wrap={"wrap"} justify={"end"} direction={"column"} directionMd={"row"} gap={"10px"}>
                      <FlexBox width={"100%"} widthMd={"unset"} style={{ position: "relative" }}>
                        {isEditInventory && <DisabledDiv />}
                        <FranSelect
                          width={"200px"}
                          widthMd={"100%"}
                          gubun={"fran"}
                          selectBtnText={franText}
                          setTextChange={setFranText}
                          placeholder={"가맹점을"}
                          show={franShow}
                          setFranShow={setFranShow}
                          isInventory={true}
                          // setFranSelect={setFranSelect}
                          setIdxChange={setFranchiseId}
                        />
                      </FlexBox>

                      {/*  체크박스 선택 - 공통  */}
                      <FlexBox justify={"flex-start"} gap={"10px"} wrap={"wrap"}>
                        <CheckLabel htmlFor="checkAll" gap={"5px"}>
                          <input
                            disabled={isEditInventory}
                            type="checkbox"
                            name="test"
                            id="checkAll"
                            onChange={(e) => handleAllCheck(e.target.checked)}
                            checked={checkItems.length === INVENTORY_STATUS.length ? true : false}
                          />
                          <CheckIcon>
                            <svg viewBox="0 0 24 24">
                              <polyline points="19 7 10 17 5 12" />
                            </svg>
                          </CheckIcon>
                        </CheckLabel>
                        <Text size={"14px"}>전체</Text>
                        {INVENTORY_STATUS?.map((data, idx) => (
                          <>
                            <CheckLabel htmlFor={data.value} key={idx} gap={"5px"}>
                              <input
                                disabled={isEditInventory}
                                type="checkbox"
                                name="test"
                                id={data.value}
                                onChange={(e) => handleSingleCheck(e.target.checked, data.value)}
                                checked={checkItems.includes(data.value) ? true : false}
                              />
                              <CheckIcon>
                                <svg viewBox="0 0 24 24">
                                  <polyline points="19 7 10 17 5 12" />
                                </svg>
                              </CheckIcon>
                            </CheckLabel>
                            <Text size={"14px"}>{data.text}</Text>
                          </>
                        ))}
                      </FlexBox>
                      <FlexBox
                        justify={"end"}
                        gap={"10px"}
                        wrap={"wrap"}
                        wrapLg={"nowrap"}
                        width={"100%"}
                        widthMd={"unset"}
                      >
                        <FlexBox width={"100%"} widthMd={"unset"} style={{ position: "relative" }}>
                          {isEditInventory && <DisabledDiv />}
                          <BasicSelect
                            disabled={isEditInventory}
                            selectedValue={filter}
                            setSelectedValue={setFilter}
                            data={SEARCH_TYPE_LIST}
                            canSelectAll={false}
                          />
                        </FlexBox>

                        {/*  상품 검색창 - 공통 */}
                        {filter == "barcode_num" ? (
                          <SearchInput
                            disabled={isEditInventory}
                            borderBottom={"1px solid #666"}
                            borderBottomColor={"#ff4a4a"}
                            transition={"border-color 0.3s ease-in-out"}
                            placeholder={"바코드 번호를 입력해주세요."}
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={(event) => handleKeyDown(event, searchInventory)}
                          />
                        ) : (
                          <SelectBox>
                            <ProductSearchInputVer2
                              disabled={isEditInventory}
                              showSearchList={showSearchList}
                              setShowSearchList={setShowSearchList}
                              searchResults={searchResults}
                              setSearchResults={setSearchResults}
                              inputValue={inputValue}
                              setInputValue={setInputValue}
                              searchInventory={searchInventory}
                              onSearch={searchInventory}
                            />
                          </SelectBox>
                        )}
                        {/*  검색 버튼 - 공통 */}
                        <Btn
                          color={"#fff"}
                          background={"#FF4A4A"}
                          size={"14px"}
                          weight={"700"}
                          width={"100%"}
                          widthMd={"unset"}
                          padding={"8px 18px"}
                          lineHeight={"22px"}
                          disabled={isEditInventory}
                          onClick={searchInventory}
                          style={{ flexShrink: 0 }}
                        >
                          검 색
                        </Btn>
                      </FlexBox>
                    </FlexBox>
                    <FlexBox
                      justify={"space-between"}
                      gap={"10px"}
                      margin={"20px 0 30px"}
                      wrap={"wrap"}
                      wrapMd={"no-wrap"}
                    >
                      <FlexBox gap={"10px"} width={"100%"} widthMd={"auto"}>
                        <Text
                          width={"100%"}
                          widthMd={"unset"}
                          color={"#FF4A4A"}
                          size={"16px"}
                          padding={"10px 29px 10px 27px"}
                          background={"rgba(255, 116, 64, 0.10)"}
                          radius={"8px"}
                          letterSpacing={"-0.32px"}
                          style={{
                            flexShrink: 0,
                          }}
                        >
                          금액 합계 :{" "}
                          <span style={{ fontWeight: "700" }}>{AddComma(totalAmount ? totalAmount : "")}원</span>
                        </Text>
                        {!isMobile && (
                          <div style={{ position: "relative" }}>
                            {isEditInventory && <DisabledDiv />}
                            <BasicSelect
                              id="number"
                              width={"130px"}
                              data={UNIT_10_TO_40_LIST}
                              selectedValue={unit}
                              setSelectedValue={setUnit}
                              canSelectAll={false}
                              setCurrentPage={setNowPage}
                            />
                          </div>
                        )}
                      </FlexBox>

                      <FlexBox
                        gap={"10px"}
                        justify={"flex-end"}
                        width={"100%"}
                        widthMd={"auto"}
                        style={{ marginLeft: "auto" }}
                      >
                        {isEditInventory ? (
                          <Btn
                            size={"14px"}
                            padding={"8px 18px"}
                            border={"1px solid #666"}
                            color={"#666"}
                            hoverBackground={"#C7C7C7"}
                            hoverColor={"#fff"}
                            hoverBorder={"transparent"}
                            lineHeight={"22px"}
                            hoverWeight={"700"}
                            onClick={() => {
                              setIsEditInventory(false);
                              window.location.reload();
                            }}
                          >
                            수정 취소
                          </Btn>
                        ) : (
                          <>
                            {!isMobile && (
                              <Btn
                                size={"14px"}
                                padding={"8px 18px"}
                                border={"1px solid #666"}
                                color={"#666"}
                                hoverBackground={"#C7C7C7"}
                                hoverColor={"#fff"}
                                hoverBorder={"transparent"}
                                lineHeight={"22px"}
                                hoverWeight={"700"}
                                onClick={ExportHandler}
                              >
                                엑셀 다운
                              </Btn>
                            )}
                          </>
                        )}

                        {isEditInventory ? (
                          <Btn
                            size={"14px"}
                            padding={"8px 18px"}
                            lineHeight={"22px"}
                            hoverWeight={"700"}
                            onClick={editInventory}
                          >
                            저장
                          </Btn>
                        ) : (
                          // 특정한 아이디만 수정,등록 가능함
                          user_id === "8" && (
                            <>
                              <Btn
                                size={"14px"}
                                padding={"8px 18px"}
                                lineHeight={"22px"}
                                hoverWeight={"700"}
                                onClick={() => {
                                  if (inventoryItems.length > 0) {
                                    setIsEditInventory(!isEditInventory);
                                    setFranShow(false);
                                    // setFranShow2(false);
                                    setShowSearchList(false);
                                  } else {
                                    setTextMsg("수정될 데이터가 없습니다.");
                                    setTextModal(true);
                                  }
                                }}
                              >
                                재고 수정
                              </Btn>
                              <Btn
                                size={"14px"}
                                padding={"8px 18px"}
                                lineHeight={"22px"}
                                hoverWeight={"700"}
                                onClick={() => {
                                  navigate("/product/inventory/bulk/write");
                                }}
                              >
                                대량 등록
                              </Btn>
                            </>
                          )
                        )}
                      </FlexBox>
                    </FlexBox>

                    <TableWrap>
                      <table style={{ width: "100%", minWidth: "1300px" }}>
                        <colgroup>
                          <col style={{ width: "4%" }} />
                          <col style={{ width: "8%" }} />
                          <col style={{ width: "10%" }} />
                          <col style={{ width: "11.5%" }} />
                          <col />
                          <col style={{ width: "7%" }} />
                          <col style={{ width: "7%" }} />
                          <col style={{ width: "7%" }} />
                          <col style={{ width: "7%" }} />
                          <col style={{ width: "5.5%" }} />
                          <col style={{ width: "5.5%" }} />
                          <col style={{ width: "5.5%" }} />
                          <col style={{ width: "5.5%" }} />
                        </colgroup>
                        <thead>
                          <tr>
                            <Th>No</Th>
                            <Th>상품분류</Th>
                            <Th>묶음번호</Th>
                            <Th>바코드번호</Th>
                            <Th>상품명</Th>
                            <Th>공급가</Th>
                            <Th>매입가</Th>
                            <Th>판매가</Th>
                            <Th>현재고</Th>
                            <Th>입고</Th>
                            <Th>반품</Th>
                            <Th>폐기</Th>
                            <Th>분실</Th>
                          </tr>
                        </thead>

                        <tbody>
                          {inventoryItems.length > 0 ? (
                            inventoryItems?.map((item) => (
                              <tr key={item.inventory_id}>
                                <Td>{(nowPage - 1) * unit + item.row_num}</Td>
                                <Td>{item.category2nd_name}</Td>
                                <Td
                                  onClick={() => {
                                    if (item.product_super_id) {
                                      getSupercodeProduct(item.barcode_num);
                                    }
                                  }}
                                  style={{ cursor: item.product_super_id ? "pointer" : "" }}
                                >
                                  {item.product_super_id ? item.product_super_id : "-"}
                                </Td>
                                <Td align={"center"}>{item.barcode_num}</Td>
                                <Td align={"center"}>{item.product_name}</Td>
                                <Td align={"right"}>{AddComma(item.shipping_price)}원</Td>
                                <Td align={"right"}>{AddComma(item.purchase_price)}원</Td>
                                <Td align={"right"}>{AddComma(item.consumer_price)}원</Td>
                                <Td>
                                  {item.warehousing_count +
                                    item.take_back_count -
                                    item.disposal_count -
                                    item.lost_count}
                                </Td>
                                <Td>
                                  {isEditInventory ? (
                                    <input
                                      type="number"
                                      id={item.inventory_id}
                                      onChange={(e) => onChangeValue(e.target, "warehousing_count")}
                                      defaultValue={""}
                                      placeholder="0"
                                      style={{ width: "50px" }}
                                    />
                                  ) : (
                                    item.warehousing_count
                                  )}
                                </Td>

                                <Td>
                                  {isEditInventory ? (
                                    <input
                                      type="number"
                                      id={item.inventory_id}
                                      onChange={(e) => onChangeValue(e.target, "take_back_count")}
                                      defaultValue={""}
                                      placeholder="0"
                                      style={{ width: "50px" }}
                                    />
                                  ) : (
                                    item.take_back_count
                                  )}
                                </Td>
                                <Td>
                                  {isEditInventory ? (
                                    <input
                                      type="number"
                                      id={item.inventory_id}
                                      onChange={(e) => onChangeValue(e.target, "disposal_count")}
                                      defaultValue={""}
                                      placeholder="0"
                                      style={{ width: "50px" }}
                                    />
                                  ) : (
                                    item.disposal_count
                                  )}
                                </Td>
                                <Td>
                                  {isEditInventory ? (
                                    <input
                                      type="number"
                                      id={item.inventory_id}
                                      onChange={(e) => onChangeValue(e.target, "lost_count")}
                                      defaultValue={""}
                                      placeholder="0"
                                      style={{ width: "50px" }}
                                    />
                                  ) : (
                                    item.lost_count
                                  )}
                                </Td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan={12}
                                style={{
                                  borderTop: "1px solid #e1e1e1",
                                  textAlign: "center",
                                  padding: 10,
                                  fontSize: "14px",
                                }}
                              >
                                {noDataMsg}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </TableWrap>
                    {pagination?.total_page > 0 && !isEditInventory ? (
                      <Pagination pagination={pagination} currentPage={nowPage} movePage={movePage} />
                    ) : (
                      ""
                    )}
                  </WhiteBox>
                </Container>
                <BundleProductModal modal={modal} setModal={setModal} data={bundleData} setData={setBundleData} />
                <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
              </Wrapper>
            </ContentContainer>
          </>
        </>
      )}
    </>
  );
}

const SelectBox = styled.div`
  position: relative;

  @media all and (max-width: 1024px) {
    width: 100%;
    flex: unset;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
`;
