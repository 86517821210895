import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { deleteTakeBack, getTackBackDetail, reviewTakeback, editTakeback } from "../../Utiles";
import { useMediaQuery } from "react-responsive";
import { useMemo } from "react";
import HeaderSub from "../../Components/Header/HeaderSub";
import { AddComma, Xlsx_download } from "../../Utiles/Custom";
import img_noimage from "../../Assets/images/no_image.jpg";
import {
  ContentContainer,
  Btn,
  Container,
  FlexBox,
  SquareImg,
  Td,
  Text,
  Th,
  Wrapper,
  Title,
  WhiteBox,
  ReturnListMWrap,
  Tdm,
  ShopImg,
  ShopText,
} from "../../Assets/css/commonVer2";
import { TAKEBACK_REASON_LIST, TAKEBACK_ITEM_STATUS_LIST } from "../../Utiles/ListData";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import Loading from "../../Components/Loading";
import InfoModal from "../../Components/Modal/InfoModal";
import InputTakebackModal from "./Components/InputTakebackModal";
/* code from sub_outsourcing */

// 상품마스터 > 반품관리 > 반품상세

export default function DetailTakeBack() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const isAdmin = sessionStorage.getItem("gubun") === "admin";
  const { take_back_id } = useParams();

  // 데이터
  const [takeBackList, setTakeBackList] = useState([]);
  const [reviewList, setReviewList] = useState([]);
  const [tempPiecesList, setTempPiecesList] = useState([]);
  const [takeBackInfo, setTakeBackInfo] = useState({});

  // 페이지 상태를 나타내는 값
  const [isBasicMode, setIsBasicMode] = useState(true); // 일반 상태
  const [isEditMode, setIsEditMode] = useState(false); // 수량 수정 상태
  const [isReviewMode, setIsReviewMode] = useState(false); // 검수 상태

  // 반품 취소
  const [cancelRefundModal, setCancelRefundModal] = useState(false);

  const [hoverRow, setHoverRow] = useState(null);
  const [reasonModal, setReasonModal] = useState(false);
  const [reasonModalTxts] = useState({
    title: "",
    text: "",
    reason: "",
  });

  const [clickedIdx, setClickedIdx] = useState({});
  const [clickedReview, setClickedReview] = useState({
    allowPieces: "",
    reason: "",
  });

  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [goBackModal, setGoBackModal] = useState(false);
  const [refreshModal, setRefreshModal] = useState(false);
  const [inputTakebackModal, setInputTakebackModal] = useState(false);

  // 데이터 불러오기
  const getData = () => {
    getTackBackDetail(take_back_id).then((res) => {
      if (res.status) {
        setTakeBackList(res.results.table);
        setTakeBackInfo(res.results.take_back_info);
        // 리뷰하기 전 && 관리자라면 리뷰할 준비
        if (res.results.take_back_info.status === "review" && isAdmin) {
          const data = res.results.table.map((item) => ({
            take_back_detail_id: item.take_back_detail_id,
            allow_pieces: item.pieces,
            review_status: "approval",
            not_approval_reason: "",
          }));
          setReviewList(data);
          const data2 = res.results.table.map((item) => ({
            take_back_detail_id: item.take_back_detail_id,
            modify_pieces: item.pieces,
          }));
          setTempPiecesList(data2);
        }
      }
    });
  };

  // 반품 상세 엑셀 다운로드
  const onExcelDown = () => {
    const xlsxTemp = takeBackList?.map((item, i) => {
      const reason = TAKEBACK_REASON_LIST.find((list) => list.value === item.reason)?.text;
      const reviewStatus = TAKEBACK_ITEM_STATUS_LIST.find((list) => list.value === item.review_status)?.text;
      return {
        No: i + 1,
        바코드: item.barcode_num,
        상품명: item.product_name,
        공급가: item.shipping_price,
        소비자가: item.consumer_price,
        반품신청수량: item.pieces,
        최종반품수량: item.allow_pieces,
        반품사유: reason,
        반품거절사유: item.not_approval_reason,
        총반품금액: item.total_price,
        상태: reviewStatus,
      };
    });
    Xlsx_download(xlsxTemp, `반품상세내역_${takeBackInfo.franchise_name}_${takeBackInfo.take_back_id}`);
  };

  // 반품 취소
  const onCancelTakeBack = () => {
    deleteTakeBack([take_back_id])
      .then((res) => {
        if (res) {
          setTextMsg("반품이 취소되었습니다.");
          setGoBackModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  // 수량 수정 api
  const editQuantity = () => {
    setLoading(true);
    editTakeback(take_back_id, tempPiecesList)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setTextMsg("수량이 수정되었습니다.");
          setRefreshModal(true);
        } else {
          setIsBasicMode(true);
          setIsEditMode(false);
          setIsReviewMode(false);
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setLoading(false);
        setIsBasicMode(true);
        setIsEditMode(false);
        setIsReviewMode(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  // 검수 api
  const review = () => {
    setLoading(true);
    const hasMismatch = reviewList.some((item) => {
      const correspondingTakeBackItem = takeBackList.find(
        (takeBackItem) => takeBackItem.take_back_detail_id === item.take_back_detail_id,
      );

      // 조건 1: allow_pieces가 pieces보다 적거나 같은지
      if (correspondingTakeBackItem && item.allow_pieces > correspondingTakeBackItem.pieces) {
        setTextMsg("반품인정수량은 반품신청수량보다 작거나 같아야합니다.");
        return true;
      }

      // 조건 2: allow_pieces랑 pieces가 다른데 not_approval_reason가 공백인지
      if (
        correspondingTakeBackItem &&
        item.allow_pieces !== correspondingTakeBackItem.pieces &&
        !item.not_approval_reason
      ) {
        setTextMsg("1개라도 반품을 인정하지 않는 경우 반품거절사유를 입력해야합니다.");
        return true;
      }

      return false; // 모든 조건 만족
    });

    if (hasMismatch) {
      // 조건을 만족하지 않는 경우 처리
      setLoading(false);
      setTextModal(true);
      return;
    }

    //api 호출
    const updatedReviewList = reviewList.map((item) => {
      const correspondingTakeBackItem = takeBackList.find(
        (takeBackItem) => takeBackItem.take_back_detail_id === item.take_back_detail_id,
      );

      // 조건 1: allow_pieces와 pieces가 같은 경우
      if (correspondingTakeBackItem && item.allow_pieces === correspondingTakeBackItem.pieces) {
        return {
          ...item,
          review_status: "approval",
        };
      }

      // 조건 2: allow_pieces가 0보다 큰 경우
      if (correspondingTakeBackItem && item.allow_pieces > 0) {
        return {
          ...item,
          review_status: "part_approval",
        };
      }

      // 조건 3: allow_pieces가 0인 경우
      if (correspondingTakeBackItem && item.allow_pieces === 0) {
        return {
          ...item,
          review_status: "reject",
        };
      }

      return item; // 조건을 만족하지 않는 항목은 그대로 유지
    });

    reviewTakeback(take_back_id, takeBackInfo.franchise_id, updatedReviewList)
      .then((res) => {
        setLoading(false);
        if (res.status) {
          setTextMsg("검수가 완료되었습니다.");
          setRefreshModal(true);
        } else {
          setTextMsg(res.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setLoading(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const handleSubmit = (e) => {
    if (e === 1) {
    } else if (e === 2) {
      setCancelRefundModal(false);
      onCancelTakeBack();
    } else if (e === 3) {
    } else if (e === 4) {
      setConfirmModal(false);
      review();
    }
  };

  const setReview = (updatedClickedReview) => {
    const updatedReviewList = reviewList.map((item, index) => {
      if (index === clickedIdx) {
        return updatedClickedReview; // clickedIdx 위치의 항목을 clickedReview로 대체
      }
      return item; // 나머지 항목은 그대로 유지
    });
    setReviewList(updatedReviewList);
  };

  // 검수 정보 입력
  function onChangeReviewValue(gubun, index, value) {
    setReviewList((prevList) => {
      const newList = [...prevList];
      const itemToUpdate = newList[index];

      if (itemToUpdate) {
        if (gubun === "allow_pieces") {
          itemToUpdate.allow_pieces = parseInt(value);
        } else {
          itemToUpdate.not_approval_reason = value;
        }
      }
      return newList;
    });
  }

  // 상품 수량 수정
  function onChangeValue(index, target) {
    setTempPiecesList((prevList) => {
      const newList = [...prevList];
      const itemToUpdate = newList[index];
      itemToUpdate.modify_pieces = parseInt(target.value);
      return newList;
    });
  }

  // 총 반품 금액
  const totalAmount = useMemo(() => {
    let total_price = 0;
    if (takeBackInfo.status === "review") {
      takeBackList.forEach((item) => (total_price += item.pieces * item.shipping_price));
    } else {
      takeBackList.forEach((item) => (total_price += item.total_price));
    }
    return total_price;
  }, [takeBackList]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loading ? <Loading /> : null}
      <>
        <HeaderSub title={"반품 상세"} />
        <ContentContainer>
          <Wrapper type={2} pbM={"112px"}>
            <Container>
              {!isMobile && (
                <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                  반품상세
                </Title>
              )}

              {isMobile && takeBackInfo.status === "review" && (
                <FlexBox gap={"10px"} margin={"0 0 10px"}>
                  <>
                    {!isAdmin ? (
                      <Btn
                        border={"1px solid #FF4A4A"}
                        color={"#FF4A4A"}
                        background={"#fff"}
                        padding={"9px 19px"}
                        size={"14px"}
                        radius={"6px"}
                        weight={"400"}
                        lineHeight={"22px"}
                        width={"100%"}
                        onClick={() => setCancelRefundModal(true)}
                      >
                        반품 취소
                      </Btn>
                    ) : (
                      <>
                        {/* 일반 상태 일때 */}
                        {isBasicMode ? (
                          <>
                            <Btn
                              border={"1px solid #FF4A4A"}
                              color={"#FF4A4A"}
                              background={"#fff"}
                              padding={"9px 19px"}
                              size={"14px"}
                              radius={"6px"}
                              weight={"400"}
                              lineHeight={"22px"}
                              width={"100%"}
                              onClick={() => {
                                setIsBasicMode(false);
                                setIsEditMode(true);
                                setIsReviewMode(false);
                              }}
                            >
                              수량 수정
                            </Btn>
                            <Btn
                              border={"1px solid #FF4A4A"}
                              color={"#FF4A4A"}
                              background={"#fff"}
                              hoverWeight={"700"}
                              padding={"9px 19px"}
                              size={"14px"}
                              radius={"6px"}
                              weight={"400"}
                              lineHeight={"22px"}
                              width={"100%"}
                              onClick={() => {
                                setIsBasicMode(false);
                                setIsEditMode(false);
                                setIsReviewMode(true);
                              }}
                            >
                              검수 시작
                            </Btn>
                          </>
                        ) : (
                          <>
                            <Btn
                              size={"14px"}
                              padding={"8px 18px"}
                              lineHeight={"22px"}
                              hoverWeight={"700"}
                              background={"#C7C7C7"}
                              border={"1px solid #C7C7C7"}
                              color="#fff"
                              width={"100%"}
                              widthMd={"auto"}
                              onClick={() => {
                                if (isEditMode) {
                                  setIsBasicMode(true);
                                  setIsEditMode(false);
                                  setIsReviewMode(false);
                                  const data2 = takeBackList.map((item) => ({
                                    take_back_detail_id: item.take_back_detail_id,
                                    modify_pieces: item.pieces,
                                  }));
                                  setTempPiecesList(data2);
                                } else {
                                  setIsBasicMode(true);
                                  setIsEditMode(false);
                                  setIsReviewMode(false);
                                  const data = takeBackList.map((item) => ({
                                    take_back_detail_id: item.take_back_detail_id, //TODO 뭘까?
                                    allow_pieces: item.pieces,
                                    review_status: "approval",
                                    not_approval_reason: "",
                                  }));
                                  setReviewList(data);
                                }
                              }}
                            >
                              취소
                            </Btn>
                            <Btn
                              color="#fff"
                              background={"#FF4A4A"}
                              size={"14px"}
                              padding={"8px 18px"}
                              lineHeight={"22px"}
                              hoverWeight={"700"}
                              width={"100%"}
                              onClick={() => {
                                if (isEditMode) {
                                  editQuantity();
                                } else {
                                  setConfirmModal(true);
                                }
                              }}
                            >
                              저장
                            </Btn>
                          </>
                        )}
                      </>
                    )}
                  </>
                </FlexBox>
              )}
              <WhiteBox margin={"0 0"}>
                {!isMobile && takeBackInfo.status === "review" && (
                  <FlexBox
                    style={{
                      marginLeft: "auto",
                    }}
                    gap={"10px"}
                  >
                    <>
                      {!isAdmin ? (
                        <Btn
                          border={"1px solid #FF4A4A"}
                          color={"#FF4A4A"}
                          hoverColor={"#FFFFFF"}
                          hoverBackground={"#FF4A4A"}
                          hoverWeight={"700"}
                          padding={"9px 19px"}
                          size={"14px"}
                          radius={"6px"}
                          weight={"400"}
                          lineHeight={"22px"}
                          onClick={() => setCancelRefundModal(true)}
                        >
                          반품 취소
                        </Btn>
                      ) : (
                        <>
                          {/* 일반 상태 일때 */}
                          {isBasicMode ? (
                            <>
                              <Btn
                                border={"1px solid #FF4A4A"}
                                color={"#FF4A4A"}
                                hoverColor={"#FFFFFF"}
                                hoverBackground={"#FF4A4A"}
                                hoverWeight={"700"}
                                padding={"9px 19px"}
                                size={"14px"}
                                radius={"6px"}
                                weight={"400"}
                                lineHeight={"22px"}
                                onClick={() => {
                                  setIsBasicMode(false);
                                  setIsEditMode(true);
                                  setIsReviewMode(false);
                                }}
                              >
                                수량 수정
                              </Btn>
                              <Btn
                                border={"1px solid #FF4A4A"}
                                color={"#FF4A4A"}
                                hoverColor={"#FFFFFF"}
                                hoverBackground={"#FF4A4A"}
                                hoverWeight={"700"}
                                padding={"9px 19px"}
                                size={"14px"}
                                radius={"6px"}
                                weight={"400"}
                                lineHeight={"22px"}
                                onClick={() => {
                                  setIsBasicMode(false);
                                  setIsEditMode(false);
                                  setIsReviewMode(true);
                                }}
                              >
                                검수 시작
                              </Btn>
                            </>
                          ) : (
                            <>
                              <Btn
                                size={"14px"}
                                padding={"8px 18px"}
                                lineHeight={"22px"}
                                margin={"0 0 0 10px"}
                                hoverWeight={"700"}
                                background={"#C7C7C7"}
                                border={"1px solid #C7C7C7"}
                                color="#fff"
                                width={"100%"}
                                widthMd={"auto"}
                                onClick={() => {
                                  if (isEditMode) {
                                    setIsBasicMode(true);
                                    setIsEditMode(false);
                                    setIsReviewMode(false);
                                    const data2 = takeBackList.map((item) => ({
                                      take_back_detail_id: item.take_back_detail_id,
                                      modify_pieces: item.pieces,
                                    }));
                                    setTempPiecesList(data2);
                                  } else {
                                    setIsBasicMode(true);
                                    setIsEditMode(false);
                                    setIsReviewMode(false);
                                    const data = takeBackList.map((item) => ({
                                      take_back_detail_id: item.take_back_detail_id,
                                      allow_pieces: item.pieces,
                                      review_status: "approval",
                                      not_approval_reason: "",
                                    }));
                                    setReviewList(data);
                                  }
                                }}
                              >
                                취소
                              </Btn>
                              <Btn
                                color="#fff"
                                background={"#FF4A4A"}
                                size={"14px"}
                                padding={"8px 18px"}
                                lineHeight={"22px"}
                                margin={"0 0 0 10px"}
                                hoverWeight={"700"}
                                width={"100%"}
                                widthMd={"auto"}
                                onClick={() => {
                                  if (isEditMode) {
                                    editQuantity();
                                  } else {
                                    setConfirmModal(true);
                                  }
                                }}
                              >
                                저장
                              </Btn>
                            </>
                          )}
                        </>
                      )}
                    </>
                  </FlexBox>
                )}
                {!isMobile && isAdmin && takeBackInfo.status === "complete" && (
                  <FlexBox justify="end">
                    <Btn
                      size={"14px"}
                      padding={"8px 18px"}
                      border={"1px solid #666"}
                      color={"#666"}
                      hoverBackground={"#C7C7C7"}
                      hoverColor={"#fff"}
                      hoverBorder={"transparent"}
                      lineHeight={"22px"}
                      hoverWeight={"700"}
                      margin={"0 0 0 10px"}
                      onClick={onExcelDown}
                    >
                      엑셀 다운
                    </Btn>
                  </FlexBox>
                )}
                <FlexBox
                  gap={"10px"}
                  justify={"start"}
                  margin={"0 0"}
                  marginMd={"20px 0 0"}
                  direction={"column"}
                  directionMd={"row"}
                  wrap={"wrap"}
                >
                  {isAdmin && (
                    <Text
                      color={"#1E1E1E"}
                      size={"16px"}
                      background={"rgba(255, 116, 64, 0.10)"}
                      radius={"6px"}
                      padding={"10px 0"}
                      paddingLg={"10px 10px"}
                      width={"100%"}
                      widthMd={"unset"}
                      weight={"500"}
                    >
                      {takeBackInfo?.franchise_name || ""}
                    </Text>
                  )}
                  <Text
                    color={"#6c6c6c"}
                    size={"16px"}
                    background={"rgba(255, 116, 64, 0.10)"}
                    radius={"6px"}
                    padding={"10px 0"}
                    paddingLg={"10px 10px"}
                    width={"100%"}
                    widthMd={"unset"}
                  >
                    반품번호 : <span style={{ color: "#2d2d2d", fontWeight: "500" }}>{take_back_id}</span>
                  </Text>
                  <Text
                    color={"#6c6c6c"}
                    size={"16px"}
                    background={"rgba(255, 116, 64, 0.10)"}
                    radius={"6px"}
                    padding={"10px 0"}
                    paddingLg={"10px 10px"}
                    width={"100%"}
                    widthMd={"unset"}
                  >
                    반품신청일 : <span style={{ color: "#2d2d2d", fontWeight: "500" }}>{takeBackInfo?.createdAt}</span>
                  </Text>
                  <Text
                    color={"#6c6c6c"}
                    background={"rgba(255, 116, 64, 0.10)"}
                    radius={"6px"}
                    size={"16px"}
                    padding={"10px 0"}
                    paddingLg={"10px 10px"}
                    width={"100%"}
                    widthMd={"unset"}
                  >
                    품목 : <span style={{ color: "#2d2d2d", fontWeight: "500" }}>{takeBackList.length} 개</span>
                  </Text>
                  <Text
                    color={"#6c6c6c"}
                    size={"16px"}
                    background={"rgba(255, 116, 64, 0.10)"}
                    radius={"6px"}
                    padding={"10px 0"}
                    paddingLg={"10px 10px"}
                    width={"100%"}
                    widthMd={"unset"}
                  >
                    총 반품 금액 :{" "}
                    <span style={{ color: "#FF4A4A", fontWeight: "500" }}>{AddComma(totalAmount)}원</span>
                  </Text>
                </FlexBox>{" "}
                <ReturnListMWrap>
                  <table
                    style={{
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <colgroup className="d-none d-lg-table-column-group">
                      {(isBasicMode || isEditMode) && (
                        <>
                          <col style={{ width: "5%" }} />
                          <col />
                          <col style={{ width: "15%" }} />
                          {takeBackInfo.status === "review" ? (
                            <col style={{ width: "10%" }} />
                          ) : (
                            <>
                              <col style={{ width: "11%" }} />
                              <col style={{ width: "11%" }} />
                            </>
                          )}
                          <col style={{ width: "13%" }} />
                          <col style={{ width: "13%" }} />
                          <col style={{ width: "10.5%" }} />
                        </>
                      )}
                      {isReviewMode && (
                        <>
                          <col style={{ width: "5%" }} />
                          <col />
                          <col style={{ width: "13%" }} />
                          <col style={{ width: "13%" }} />
                          <col style={{ width: "13%" }} />
                          <col style={{ width: "13%" }} />
                          <col />
                        </>
                      )}
                    </colgroup>
                    <thead className="d-none d-lg-table-head">
                      {(isBasicMode || isEditMode) && (
                        <>
                          <Th>No</Th>
                          <Th>상품명</Th>
                          <Th>상품 금액</Th>
                          {takeBackInfo.status === "review" ? (
                            <Th>반품신청수량</Th>
                          ) : (
                            <>
                              <Th>반품신청수량</Th>
                              <Th>최종반품수량</Th>
                            </>
                          )}
                          <Th>반품 사유</Th>
                          <Th>총 반품 금액</Th>
                          <Th>상태</Th>
                        </>
                      )}
                      {isReviewMode && (
                        <>
                          <Th>No</Th>
                          <Th>상품명</Th>
                          <Th>반품신청수량</Th>
                          <Th>반품 사유</Th>
                          <Th>총 반품금액</Th>
                          <Th>반품인정수량</Th>
                          <Th>거절사유</Th>
                        </>
                      )}
                    </thead>
                    <tbody className="d-block d-lg-table-row-group">
                      {takeBackList.length !== 0 ? (
                        takeBackList.map((e, i, arr) => (
                          <tr
                            key={i}
                            className="d-block d-lg-table-row"
                            onMouseEnter={() => {
                              setHoverRow(i);
                            }}
                            onMouseLeave={() => {
                              setHoverRow(null);
                            }}
                            onClick={(event) => {
                              const targetElement = event.target;
                              const dataType = targetElement.getAttribute("data-type");
                              if (dataType === "status") {
                                if (e.review_status === "part_approval" || e.review_status === "reject") {
                                  reasonModalTxts.title = "반품반려사유";
                                  reasonModalTxts.text = "아래와 같은 사유로 반품이 반려되었습니다.";
                                  reasonModalTxts.reason = e?.not_approval_reason;
                                  setReasonModal(true);
                                  return;
                                }
                              }
                            }}
                          >
                            {(isBasicMode || isEditMode) && (
                              <>
                                <Td
                                  className="d-none d-lg-table-cell"
                                  style={{
                                    borderBottom: "1px solid #F2F2F2",
                                  }}
                                >
                                  {e.row_num}
                                </Td>
                                <Td
                                  className="d-none d-lg-table-cell"
                                  align={"left"}
                                  style={{
                                    borderBottom: "1px solid #F2F2F2",
                                  }}
                                >
                                  <FlexBox justify={"flex-start"} gap={"10px"}>
                                    <ShopImg>
                                      <SquareImg>
                                        <div>
                                          <img
                                            src={
                                              e.image_path
                                                ? `${process.env.REACT_APP_S3_KEY}${e.image_path}`
                                                : img_noimage
                                            }
                                            alt=""
                                          />
                                        </div>
                                      </SquareImg>
                                    </ShopImg>
                                    <ShopText>
                                      <Text size={"12px"} weight={"500"} color={"#2d2d2d"} align={"left"}>
                                        {e.product_name}
                                      </Text>
                                      <FlexBox justify={"flex-start"} gap={"10px"}>
                                        <Text size={"14px"} color={"#666"} align={"left"}>
                                          {e.barcode_num}
                                        </Text>
                                        <Text size={"14px"} color={"#666"} align={"left"}>
                                          {e.unit}
                                        </Text>
                                      </FlexBox>
                                    </ShopText>
                                  </FlexBox>
                                </Td>
                                <Td
                                  align={"right"}
                                  className="d-none d-lg-table-cell"
                                  style={{
                                    borderBottom: "1px solid #F2F2F2",
                                  }}
                                >
                                  <Text size={"12px"} color={"#1E1E1E"} align={"right"}>
                                    (공급가) {AddComma(e.shipping_price)}원
                                  </Text>
                                  <Text size={"12px"} color={"#1E1E1E"} align={"right"} padding={"8px 0 0"}>
                                    (소비자가) {AddComma(e.consumer_price)}원
                                  </Text>
                                </Td>
                                {takeBackInfo.status === "review" ? (
                                  <Td
                                    align={"center"}
                                    className="d-none d-lg-table-cell"
                                    style={{
                                      borderBottom: "1px solid #F2F2F2",
                                    }}
                                  >
                                    {isEditMode ? (
                                      <input
                                        type="number"
                                        id={e.barcode_num}
                                        onChange={(e) => {
                                          onChangeValue(i, e.target);
                                        }}
                                        value={tempPiecesList[i].modify_pieces}
                                        style={{
                                          fontSize: "12px",
                                          color: "#6c6c6c",
                                          width: "40px",
                                          textAlign: "center",
                                          border: "1px solid #c0c0c0",
                                          padding: "2px",
                                          borderRadius: "6px",
                                        }}
                                      />
                                    ) : (
                                      e.pieces
                                    )}
                                  </Td>
                                ) : (
                                  <>
                                    <Td
                                      align={"center"}
                                      className="d-none d-lg-table-cell"
                                      style={{
                                        borderBottom: "1px solid #F2F2F2",
                                      }}
                                    >
                                      {e.pieces}
                                    </Td>
                                    <Td
                                      align={"center"}
                                      className="d-none d-lg-table-cell"
                                      style={{
                                        borderBottom: "1px solid #F2F2F2",
                                      }}
                                    >
                                      {e.allow_pieces}
                                    </Td>
                                  </>
                                )}
                                <Td
                                  align={"center"}
                                  className="d-none d-lg-table-cell"
                                  style={{
                                    borderBottom: "1px solid #F2F2F2",
                                  }}
                                >
                                  {e.reason
                                    ? TAKEBACK_REASON_LIST.find((item) => item.value === e.reason)?.text || "-"
                                    : "-"}
                                </Td>
                                <Td
                                  align={"right"}
                                  className="d-none d-lg-table-cell"
                                  style={{
                                    borderBottom: "1px solid #F2F2F2",
                                  }}
                                >
                                  {takeBackInfo.status === "review"
                                    ? AddComma(e.pieces * e.shipping_price)
                                    : AddComma(e.total_price)}
                                  원
                                </Td>
                                <Td
                                  data-type="status"
                                  align={"center"}
                                  className="d-none d-lg-table-cell"
                                  color={
                                    e.review_status === "part_approval" || e.review_status === "reject"
                                      ? "#F63958"
                                      : "#666666"
                                  }
                                  style={{
                                    cursor:
                                      e.review_status === "part_approval" || e.review_status === "reject"
                                        ? "pointer"
                                        : "",
                                    borderBottom: "1px solid #F2F2F2",
                                    textDecoration:
                                      e.review_status === "part_approval" || e.review_status === "reject"
                                        ? "underline"
                                        : "none",
                                    fontWeight:
                                      hoverRow === i &&
                                      (e.review_status === "part_approval" || e.review_status === "reject")
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {e.review_status
                                    ? TAKEBACK_ITEM_STATUS_LIST.find((item) => item.value === e.review_status)?.text ||
                                      "-"
                                    : "미검수"}
                                </Td>
                              </>
                            )}
                            {isReviewMode && (
                              <>
                                <Td
                                  className="d-none d-lg-table-cell"
                                  style={{
                                    borderBottom: "1px solid #F2F2F2",
                                  }}
                                >
                                  {e.row_num}
                                </Td>
                                <Td
                                  className="d-none d-lg-table-cell"
                                  align={"left"}
                                  style={{
                                    borderBottom: "1px solid #F2F2F2",
                                  }}
                                >
                                  <FlexBox justify={"flex-start"} gap={"10px"}>
                                    <ShopImg>
                                      <SquareImg>
                                        <div>
                                          <img
                                            src={
                                              e.image_path
                                                ? `${process.env.REACT_APP_S3_KEY}${e.image_path}`
                                                : img_noimage
                                            }
                                            alt=""
                                          />
                                        </div>
                                      </SquareImg>
                                    </ShopImg>
                                    <ShopText>
                                      <Text size={"12px"} weight={"500"} color={"#2d2d2d"} align={"left"}>
                                        {e.product_name}
                                      </Text>
                                      <FlexBox justify={"flex-start"} gap={"10px"}>
                                        <Text size={"14px"} color={"#666"} align={"left"}>
                                          {e.barcode_num}
                                        </Text>
                                        <Text size={"14px"} color={"#666"} align={"left"}>
                                          {e.unit}
                                        </Text>
                                      </FlexBox>
                                    </ShopText>
                                  </FlexBox>
                                </Td>

                                <Td
                                  align={"center"}
                                  className="d-none d-lg-table-cell"
                                  style={{
                                    borderBottom: "1px solid #F2F2F2",
                                  }}
                                >
                                  {e.pieces}
                                </Td>
                                <Td
                                  align={"left"}
                                  className="d-none d-lg-table-cell"
                                  style={{
                                    borderBottom: "1px solid #F2F2F2",
                                  }}
                                >
                                  {e.reason
                                    ? TAKEBACK_REASON_LIST.find((item) => item.value === e.reason)?.text || "-"
                                    : "-"}
                                </Td>
                                <Td
                                  align={"right"}
                                  className="d-none d-lg-table-cell"
                                  style={{
                                    borderBottom: "1px solid #F2F2F2",
                                  }}
                                >
                                  {AddComma(e.shipping_price * reviewList[i].allow_pieces)}원
                                </Td>
                                <Td
                                  align={"center"}
                                  className="d-none d-lg-table-cell"
                                  style={{
                                    borderBottom: "1px solid #F2F2F2",
                                  }}
                                >
                                  <input
                                    type="number"
                                    id={e.barcode_num}
                                    onChange={(e) => {
                                      onChangeReviewValue("allow_pieces", i, e.target.value);
                                    }}
                                    value={reviewList[i].allow_pieces}
                                    style={{
                                      fontSize: "12px",
                                      color: "#6c6c6c",
                                      width: "40px",
                                      textAlign: "center",
                                      border: "1px solid #c0c0c0",
                                      padding: "2px",
                                      borderRadius: "6px",
                                    }}
                                  />
                                </Td>

                                <Td
                                  align={"center"}
                                  className="d-none d-lg-table-cell"
                                  style={{
                                    borderBottom: "1px solid #F2F2F2",
                                  }}
                                >
                                  <input
                                    id={e.barcode_num}
                                    onChange={(e) => {
                                      onChangeReviewValue("not_approval_reason", i, e.target.value);
                                    }}
                                    value={reviewList[i].not_approval_reason}
                                    style={{
                                      fontSize: "12px",
                                      color: "#6c6c6c",
                                      width: "100%",
                                      textAlign: "left",
                                      border: "1px solid #c0c0c0",
                                      padding: "2px",
                                      borderRadius: "6px",
                                    }}
                                  />
                                </Td>
                              </>
                            )}
                            <Tdm className="d-block d-lg-none">
                              <FlexBox justify={"flex-start"} gap={"10px"} style={{ position: "relative" }}>
                                <ShopImg>
                                  <SquareImg>
                                    <div>
                                      <img
                                        src={
                                          e.image_path ? `${process.env.REACT_APP_S3_KEY}${e.image_path}` : img_noimage
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </SquareImg>
                                </ShopImg>
                                <ShopText>
                                  <Text size={"12px"} weight={"500"} color={"#6c6c6c"} align={"left"}>
                                    {e.product_name}
                                  </Text>
                                  <FlexBox justify={"flex-start"} gap={"10px"}>
                                    <Text size={"12px"} color={"#6c6c6c"} align={"left"}>
                                      {e.barcode_num}
                                    </Text>
                                  </FlexBox>
                                </ShopText>
                              </FlexBox>
                              <FlexBox align={"flex-end"} justify={"space-between"} margin={"10px 0"}>
                                {takeBackInfo.status === "review" ? (
                                  <>
                                    {isEditMode && (
                                      <input
                                        type="number"
                                        id={e.barcode_num}
                                        onChange={(e) => {
                                          onChangeValue(i, e.target);
                                        }}
                                        value={tempPiecesList[i].modify_pieces}
                                        style={{
                                          fontSize: "12px",
                                          color: "#6c6c6c",
                                          width: "40px",
                                          textAlign: "center",
                                          border: "1px solid #c0c0c0",
                                          padding: "2px",
                                          borderRadius: "6px",
                                        }}
                                      />
                                    )}
                                    {isBasicMode && (
                                      <Text size={"12px"} color={"#6c6c6c"}>
                                        신청반품수량: {e.pieces}
                                      </Text>
                                    )}
                                    {isReviewMode && (
                                      <Text size={"12px"} color={"#6c6c6c"}>
                                        신청반품수량: {e.pieces} <br /> 인정반품수량: {reviewList[i].allow_pieces}
                                      </Text>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Text size={"12px"} color={"#6c6c6c"}>
                                      신청반품수량: {e.pieces} <br /> 최종반품수량: {e.allow_pieces}
                                    </Text>
                                  </>
                                )}

                                <div>
                                  <Text size={"10px"} color={"#2d2d2d"} align={"right"}>
                                    (공급가) {AddComma(e.shipping_price)}원
                                  </Text>
                                  <Text size={"10px"} color={"#6c6c6c"} align={"right"} padding={"2px 0 0"}>
                                    (소비자가) {AddComma(e.consumer_price)}원
                                  </Text>
                                </div>
                              </FlexBox>
                              <FlexBox
                                justify={"space-between"}
                                padding={"10px 0"}
                                style={{ borderTop: "1px solid #eaeaea" }}
                              >
                                <Text color={"#2d2d2d"} size={"12px"}>
                                  총 반품 금액
                                </Text>
                                <Text color={"#F63958"} size={"14px"} weight={"500"}>
                                  {takeBackInfo.status === "review"
                                    ? AddComma(e.pieces * e.shipping_price)
                                    : AddComma(e.total_price)}
                                  원
                                </Text>
                              </FlexBox>
                              <FlexBox justify={"space-between"} padding={"0 0 10px"}>
                                <Text color={"#2d2d2d"} size={"12px"}>
                                  상태
                                </Text>
                                <Text
                                  size={"14px"}
                                  onClick={() => {
                                    if (e.review_status === "part_approval" || e.review_status === "reject") {
                                      reasonModalTxts.title = "반품반려사유";
                                      reasonModalTxts.text = "아래와 같은 사유로 반품이 반려되었습니다.";
                                      reasonModalTxts.reason = e?.not_approval_reason;
                                      setReasonModal(true);
                                      return;
                                    }
                                  }}
                                  color={
                                    e.review_status === "part_approval" || e.review_status === "reject"
                                      ? "#F63958"
                                      : "#666666"
                                  }
                                  style={{
                                    textDecoration:
                                      e.review_status === "part_approval" || e.review_status === "reject"
                                        ? "underline"
                                        : "none",
                                    fontWeight:
                                      e.review_status === "part_approval" || e.review_status === "reject"
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {e.review_status
                                    ? TAKEBACK_ITEM_STATUS_LIST.find((item) => item.value === e.review_status)?.text ||
                                      "-"
                                    : "미검수"}
                                </Text>
                              </FlexBox>
                              <Text
                                size={"12px"}
                                color={"#6c6c6c"}
                                padding={"9px 10px"}
                                radius={"6px"}
                                background={"#eaeaea"}
                                align={"left"}
                                className="d-block d-lg-none"
                              >
                                {e.reason
                                  ? TAKEBACK_REASON_LIST.find((item) => item.value === e.reason)?.text || "-"
                                  : "-"}
                              </Text>
                              {isReviewMode && (
                                <Btn
                                  width={"100%"}
                                  margin={"10px 0"}
                                  size={"14px"}
                                  padding={"9px 19px"}
                                  onClick={() => {
                                    setClickedIdx(i);
                                    setClickedReview(reviewList[i]);
                                    setInputTakebackModal(true);
                                  }}
                                >
                                  반품인정수량 변경
                                </Btn>
                              )}
                            </Tdm>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <Td colSpan={"7"} size={"14px"} color={"#6c6c6c"} align={"center"} padding={"20px 0"}>
                            반품된 상품이 없습니다.
                          </Td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </ReturnListMWrap>
              </WhiteBox>
            </Container>
            <ConfirmModal
              modal={confirmModal}
              setModal={setConfirmModal}
              onSubmit={() => handleSubmit(4)}
              description={"검수를 완료하시겠습니까? 완료 시 취소가 불가합니다."}
            />
            <ConfirmModal
              modal={cancelRefundModal}
              setModal={setCancelRefundModal}
              onSubmit={() => handleSubmit(2)}
              description={"반품을 취소하시겠습니까?"}
            />
            <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            <InfoModal modal={refreshModal} setModal={setRefreshModal} description={textMsg} mode={"refresh"} />
            <InfoModal modal={goBackModal} setModal={setGoBackModal} description={textMsg} mode={"goBack"} />
            <InfoModal
              modal={reasonModal}
              setModal={setReasonModal}
              title={reasonModalTxts.title}
              description={reasonModalTxts.text}
              detailText={reasonModalTxts.reason}
            />
            <InputTakebackModal
              modal={inputTakebackModal}
              setModal={setInputTakebackModal}
              clickedReview={clickedReview}
              setClickedReview={setClickedReview}
              onSubmit={(item) => setReview(item)}
            />
          </Wrapper>
        </ContentContainer>
      </>
    </>
  );
}
