import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  ContentContainer,
  Btn,
  Title,
  Container,
  DateInputBox,
  FlexBox,
  TableWrap,
  Td,
  Th,
  Wrapper,
  WhiteBox,
  SearchInput,
} from "../../Assets/css/commonVer2";
import { SEARCH_TYPE_LIST, UNIT_10_TO_40_LIST } from "../../Utiles/ListData";
import ProductSearchInputVer2 from "../../Components/SearchInput/ProductSearchInputVer2";

import { Xlsx_download, handleKeyDown } from "../../Utiles/Custom";
import DatePicker from "react-datepicker";
import Authority from "../../Components/Modal/Authority";
import HandleBack from "../../Components/HandleBack";
import Pagination from "../../Components/Paging/Pagination";
import HeaderSub from "../../Components/Header/HeaderSub";
import { dateToString } from "../../Utiles/Custom";
import API from "../../Utiles/API";
import BasicSelect from "../../Components/Select/BasicSelect";
import FranSelect from "../../Components/Select/FranSelect";
import InfoModal from "../../Components/Modal/InfoModal";

export default function InventoryView() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const user_id = sessionStorage.getItem("user_id");

  const date = new Date();
  const year = date.getFullYear(); // 년
  const month = date.getMonth(); // 월
  const day = date.getDate(); // 일

  // 날짜 제어 state
  const [startDate, setStartDate] = useState(new Date(year, month, day - 7));
  const [endDate, setEndDate] = useState(new Date());

  // 페이징에 사용할 스테이트
  const [pagination, setPagination] = useState("");

  const [inventoryEditData, setInventoryEditData] = useState([]);
  const [userId, setUserId] = useState(user_id);
  const [adminUserText, setAdminUserText] = useState("");
  const [searchAdmin, setSearchAdmin] = useState([]);
  const [adminShow, setAdminShow] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [inputValue, setInputValue] = useState("");
  const [filter, setFilter] = useState("barcode_num");
  const [showSearchList, setShowSearchList] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const [textModal, setTextModal] = useState(false); // 모달 창 표시 여부 상태
  const [textMsg, setTextMsg] = useState("");

  const start = (currentPage - 1) * pageSize;

  const { handlePopstate } = HandleBack();

  // 데이터 가져오는 API
  const searchInventoryEditData = async () => {
    let api_link = `/admin/inventory-history?start_date=${dateToString(startDate)}&end_date=${dateToString(
      endDate,
    )}&page=${currentPage}&unit=${pageSize}&block=10`;
    if (userId) api_link += `&user_id=${userId}`;
    if (inputValue) api_link += `&filter_type=${filter}&filter_value=${inputValue}`;

    try {
      const res = await API.get(api_link);
      if (res.data.stauts) {
        setInventoryEditData(res.data.results);
        setPagination(res.data.pagination);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 관리자 정보 가져오는 API
  const adminSearch = async () => {
    try {
      const res = await API.get(`/admin/users?curPage=1&pageSize=99999&gubunVal=admin`);
      if (res.data.status) {
        setSearchAdmin(changeAdminData(res.data?.result));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // setSearchAdmin 데이터 바꾸는 함수
  const changeAdminData = (data) => {
    return data.map((item) => {
      return {
        idx: item.idx,
        admin_name: `${item.name}(${item.user_id})`,
      };
    });
  };

  const onExcelDown = async () => {
    if (inventoryEditData?.length > 0) {
      let api_link = `/admin/inventory-history?start_date=${dateToString(startDate)}&end_date=${dateToString(
        endDate,
      )}&page=${currentPage}&unit=99999999&block=10`;
      if (userId) api_link += `&user_id=${userId}`;
      if (inputValue) api_link += `&filter_type=${filter}&filter_value=${inputValue}`;

      const res = await API.get(api_link);
      if (res?.data?.stauts && res?.data?.results?.length > 0) {
        const xlsxTemp = res?.data?.results?.map((item, i) => {
          return {
            No: i + 1,
            "이름(아이디)": item.user_id,
            바코드: item.barcode_num,
            상품명: item.product_name,
            "변경 전 재고": item.before_count,
            "변경 후 재고": item.after_count,
            변경일: item.createdAt,
            비고: item.etc,
          };
        });
        Xlsx_download(xlsxTemp, `재고관리내역_${dateToString(startDate)}~${dateToString(endDate)}`);
      } else {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    } else {
      setTextMsg("다운받을 데이터가 없습니다.");
      setTextModal(true);
    }
  };

  function movePage(e) {
    setCurrentPage(e);
  }

  const onSearch = () => {
    setCurrentPage(1);
    searchInventoryEditData();
  };

  useEffect(() => {
    const initUserText = searchAdmin?.find((item) => item.idx == userId);
    setAdminUserText(initUserText?.admin_name);
  }, [searchAdmin]);

  useEffect(() => {
    searchInventoryEditData();
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === 1) {
      searchInventoryEditData();
    } else {
      setCurrentPage(1);
    }
  }, [pageSize]);

  useEffect(() => {
    adminSearch();
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          <HeaderSub title={"재고관리 내역"} />
          <ContentContainer>
            <Wrapper type={2} pbM={"112px"}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    재고관리 내역
                  </Title>
                )}
                {isMobile && (
                  <>
                    <FlexBox wrap={"wrap"} justify={"end"} gap={"10px"} margin={"0 0 10px"}>
                      <FlexBox gap={"10px"} flexMd={"unset"} flex={"1"}>
                        <DateInputBox style={{ width: "100%" }}>
                          <DatePicker
                            dateFormatCalendar="yyyy년 MM월"
                            dateFormat="yyyy-MM-dd"
                            selected={startDate}
                            maxDate={endDate}
                            onChange={(date) => setStartDate(date)}
                          />
                        </DateInputBox>
                        <DateInputBox style={{ width: "100%" }}>
                          <DatePicker
                            dateFormatCalendar="yyyy년 MM월"
                            dateFormat="yyyy-MM-dd"
                            selected={endDate}
                            minDate={startDate}
                            onChange={(date) => setEndDate(date)}
                          />
                        </DateInputBox>
                      </FlexBox>
                      <FranSelect
                        gubun={"admin"}
                        selectBtnText={adminUserText}
                        setTextChange={setAdminUserText}
                        placeholder={"관리자를"}
                        setFranShow={setAdminShow}
                        setIdxChange={setUserId}
                        widthMd={"100%"}
                        show={adminShow}
                      />
                      <BasicSelect
                        data={SEARCH_TYPE_LIST}
                        selectedValue={filter}
                        setSelectedValue={setFilter}
                        canSelectAll={false}
                      />
                      <FlexBox />
                      {filter === "barcode_num" ? (
                        <SearchInput
                          borderBottom={"1px solid #666666"}
                          borderBottomColor={"#ff4a4a"}
                          transition={"border-color 0.3s ease-in-out"}
                          flex={1}
                          placeholder="바코드번호를 입력해주세요."
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                          onKeyDown={(event) => handleKeyDown(event, onSearch)}
                        />
                      ) : (
                        <ProductSearchInputVer2
                          showSearchList={showSearchList}
                          setShowSearchList={setShowSearchList}
                          searchResults={searchResults}
                          setSearchResults={setSearchResults}
                          inputValue={inputValue}
                          setInputValue={setInputValue}
                          onSearch={onSearch}
                          cate1value={2}
                          franchiseId={sessionStorage.getItem("franchise_id")}
                        />
                      )}
                      <Btn
                        color={"#fff"}
                        background={"#FF4A4A"}
                        size={"14px"}
                        weight={"700"}
                        width={"100%"}
                        widthMd={"unset"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        onClick={onSearch}
                        style={{ flexShrink: 0 }}
                      >
                        검 색
                      </Btn>
                    </FlexBox>
                  </>
                )}
                <WhiteBox margin={"0 0"}>
                  {!isMobile && (
                    <>
                      <FlexBox wrap={"wrap"} justify={"end"} gap={"10px"} margin={"0 0 10px"}>
                        <FlexBox gap={"10px"} flexMd={"unset"} flex={"1"}>
                          <DateInputBox style={{ width: "100%" }}>
                            <DatePicker
                              dateFormatCalendar="yyyy년 MM월"
                              dateFormat="yyyy-MM-dd"
                              selected={startDate}
                              maxDate={endDate}
                              onChange={(date) => setStartDate(date)}
                            />
                          </DateInputBox>
                          <DateInputBox style={{ width: "100%" }}>
                            <DatePicker
                              dateFormatCalendar="yyyy년 MM월"
                              dateFormat="yyyy-MM-dd"
                              selected={endDate}
                              minDate={startDate}
                              onChange={(date) => setEndDate(date)}
                            />
                          </DateInputBox>
                        </FlexBox>
                        <FranSelect
                          gubun={"admin"}
                          selectBtnText={adminUserText}
                          setTextChange={setAdminUserText}
                          placeholder={"관리자를"}
                          setFranShow={setAdminShow}
                          setIdxChange={setUserId}
                          widthMd={"100%"}
                          show={adminShow}
                        />
                        <BasicSelect
                          data={SEARCH_TYPE_LIST}
                          selectedValue={filter}
                          setSelectedValue={setFilter}
                          canSelectAll={false}
                        />
                        <FlexBox />
                        <FlexBox gap={"10px"} wrapMd={"unset"} wrap={"wrap"} flexMd={"unset"} flex={"1 0 auto"}>
                          {filter === "barcode_num" ? (
                            <SearchInput
                              borderBottom={"1px solid #666666"}
                              borderBottomColor={"#ff4a4a"}
                              transition={"border-color 0.3s ease-in-out"}
                              flex={1}
                              placeholder="바코드번호를 입력해주세요."
                              value={inputValue}
                              onChange={(e) => setInputValue(e.target.value)}
                              onKeyDown={(event) => handleKeyDown(event, onSearch)}
                            />
                          ) : (
                            <ProductSearchInputVer2
                              showSearchList={showSearchList}
                              setShowSearchList={setShowSearchList}
                              searchResults={searchResults}
                              setSearchResults={setSearchResults}
                              inputValue={inputValue}
                              setInputValue={setInputValue}
                              onSearch={onSearch}
                              cate1value={2}
                              franchiseId={sessionStorage.getItem("franchise_id")}
                            />
                          )}
                          <Btn
                            color={"#fff"}
                            background={"#FF4A4A"}
                            size={"14px"}
                            weight={"700"}
                            width={"100%"}
                            widthMd={"unset"}
                            padding={"8px 18px"}
                            lineHeight={"22px"}
                            onClick={onSearch}
                            style={{ flexShrink: 0 }}
                          >
                            검 색
                          </Btn>
                        </FlexBox>
                      </FlexBox>
                      <FlexBox justify="space-between" margin={"20px 0 30px"}>
                        <BasicSelect
                          data={UNIT_10_TO_40_LIST}
                          selectedValue={pageSize}
                          setSelectedValue={setPageSize}
                          canSelectAll={false}
                          setCurrentPage={setCurrentPage}
                        />
                        {!isMobile && (
                          <Btn
                            size={"14px"}
                            padding={"8px 18px"}
                            border={"1px solid #666"}
                            color={"#666"}
                            hoverBackground={"#C7C7C7"}
                            hoverColor={"#fff"}
                            hoverBorder={"transparent"}
                            lineHeight={"22px"}
                            hoverWeight={"700"}
                            margin={"0 0 0 10px"}
                            onClick={onExcelDown}
                          >
                            엑셀 다운
                          </Btn>
                        )}
                      </FlexBox>
                    </>
                  )}

                  <TableWrap>
                    <table style={{ width: "100%", minWidth: "1024px" }}>
                      <colgroup>
                        <col style={{ width: "6%" }} />
                        <col style={{ width: "10.5%" }} />
                        <col />
                        <col style={{ width: "15.5%" }} />
                        <col style={{ width: "10.5%" }} />
                        <col style={{ width: "10.5%" }} />
                        <col style={{ width: "12.5%" }} />
                        <col style={{ width: "16.5%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>No</Th>
                          <Th>
                            이름
                            <br />
                            (아이디)
                          </Th>
                          <Th>상품명</Th>
                          <Th>바코드</Th>
                          <Th>변경 전 재고</Th>
                          <Th>변경 후 재고</Th>
                          <Th>변경일</Th>
                          <Th>비고</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {inventoryEditData?.length > 0 ? (
                          inventoryEditData?.map((list, idx) => (
                            <tr key={idx}>
                              <Td>{start + idx + 1}</Td>
                              <Td>{list.user_id}</Td>
                              <Td>{list.product_name}</Td>
                              <Td>{list.barcode_num}</Td>
                              <Td>{list.before_count}</Td>
                              <Td>{list.after_count}</Td>
                              <Td>{list.createdAt}</Td>
                              <Td>{list.etc}</Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={8}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  {pagination?.total_page > 0 ? (
                    <Pagination pagination={pagination} movePage={movePage} currentPage={currentPage} />
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
            </Wrapper>
            <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
          </ContentContainer>
        </>
      )}
    </>
  );
}
