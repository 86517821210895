// 외부 모듈
import React from "react";
import styled from "styled-components";
import { Navigation, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// 내부 모듈
import "./slideVer2.css"

function InstaSlider(props) {
  const {
    space,
    perview,
    naviPrev,
    naviNext,
    contents,
  } = props;
  // styles -> border, align

  return (
    <Swiper
      modules={[Navigation, A11y, Autoplay]}
      navigation={
        naviPrev && naviNext
          ? {
              prevEl: `.${naviPrev}`,
              nextEl: `.${naviNext}`,
            }
          : false
      }s
      // autoplay={{ delay: 2000, disableOnInteraction: false }}
      autoplay={false}
      loop={true}
      spaceBetween={space}
      slidesPerView={perview}
      className="instaSwiper"
    >
      <>
        {contents?.map((e, i) => (
          <SwiperSlide>
            <InstaItem
              key={i}
              style={{ cursor: e.permalink ? "pointer" : "" }}
            >
              <a href={e?.permalink} target={"_blank"}>
                <img src={e.media_url}/>
              </a>
            </InstaItem>
          </SwiperSlide>
        ))}
      </>
    </Swiper>
  );
}

const InstaItem = styled.div`
  a {
    position: relative;
    display: block;
    padding-bottom: 100%;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
    }
  }
`;

export default InstaSlider;