import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useMediaQuery } from "react-responsive";
import Dompurify from "dompurify"

import { ContentContainer, Btn, Container, FlexBox, Text, Title, Wrapper, WhiteBox } from "../../Assets/css/commonVer2";
import HeaderSub from "../../Components/Header/HeaderSub";
import API from "../../Utiles/API";
import ico_file from "../../Assets/images/ico_file.png";
import ico_download from "../../Assets/images/ico_download.png";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import InfoModal from "../../Components/Modal/InfoModal";

// 공지관리 > 상세

function NoticeView() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();
  const location = useLocation();
  const idx = location.state.idx;

  const [modalOpen, setModalOpen] = useState(false);
  const [textModal, setTextModal] = useState(false);
  const [isAdmin] = useState(sessionStorage.getItem("gubun") === "admin");
  const [data, setData] = useState([]);
  const [file, setFile] = useState([]);

  async function getNoticeView() {
    API.get(`/admin/notice/${idx}`).then((res) => {
      if (res.data.status) {
        setData(res.data.result);
        setFile(res.data.result.file_path);
      }
    });
  }

  async function deleteNotice() {
    await API.delete(`/admin/notice/${idx}`).then((res) => {
      if (res.data.status) {
        setTextModal(true);
      }
    });
  }

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const check = () => {
    setModalOpen(false);
    deleteNotice();
  };

  // 파일 다운로드
  const handleImgRemove = async (item) => {
    const response = await fetch(`${process.env.REACT_APP_S3_KEY}${item}`, {
      mode: "cors",
    });
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = item.substring(item.indexOf("_") + 1);
    document.body.appendChild(a);
    a.click();
    setTimeout((_) => {
      window.URL.revokeObjectURL(url);
    }, 60000);
    a.remove();
  };

  useEffect(() => {
    getNoticeView();
  }, []);

  return (
    <>
      <HeaderSub title={"공지사항"} />
      <ContentContainer>
        <Wrapper type={2}>
          <Container>
            {!isMobile && (
              <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                공지사항 🔔
              </Title>
            )}
            <WhiteBox margin={"0 0"}>
              {isAdmin ?  (
                <FlexBox justify={"left"}>
                  <Text size={"18px"} sizeMd={"20px"} weight={"700"} color={"#1E1E1E"} margin={"0 10px 0 0"}>
                    {data.gubun === "storeowner" ? "[점주용]" : "[고객용]"}
                  </Text>
                  <Text size={"18px"} sizeMd={"20px"} weight={"700"} color={"#1E1E1E"} align={"left"}>
                    {data.title}
                  </Text>
                </FlexBox>
              ) : (
                <Text size={"18px"} sizeMd={"20px"} weight={"700"} color={"#1E1E1E"} align={"left"}>
                  {data.title}
                </Text>
              )}
              <Text size={"16px"} sizeMd={"18px"} color={"#1E1E1E"} align={"left"} margin={"20px 0 0"}>
                {data.createdAt}
              </Text>
              <div style={{ width: "100%", borderTop: "1px solid #666", margin: "20px 0"}} />
              {/* react-quill 에디터 영역 */}
              <div className="content">
                <Text
                  size={"unset"}
                  color={"unset"}
                  weight={"unset"}
                  align={"unset"}
                  className="ql-editor"
                  dangerouslySetInnerHTML={{__html : Dompurify.sanitize(data?.content)}}
                />
              </div>
              {/* {data.image_path?.length > 0 && (
                <WhiteBox style={{ minHeight: "200px" }}>
                  {data.image_path?.map((e, i) =>
                    e.includes("type2") || e.includes("type3") ? (
                      // <img src={""} style={{ width: "100%" }} />
                      <>
                      {e ? (
                        <img src={`${process.env.REACT_APP_S3_KEY}${e}`} style={{ maxWidth: "100%" }} />
                      ) : (
                        <img src={""} style={{ width: "100%" }} />
                      )}
                    </>
                    ) : (
                      <>
                        {e ? (
                          <img src={`${process.env.REACT_APP_S3_KEY}${e}`} style={{ maxWidth: "100%" }} />
                        ) : (
                          <img src={""} style={{ width: "100%" }} />
                        )}
                      </>
                    )
                  )}
                </WhiteBox>
              )} */}
              <FlexBox justify={"end"} style={{ marginTop: "20px" }}>
                <Btn
                  size={"14px"}
                  padding={"8px 18px"}
                  lineHeight={"22px"}
                  margin={"0 0 0 10px"}
                  hoverWeight={"700"}
                  background={"#C7C7C7"}
                  border={"1px solid #C7C7C7"}
                  color="#fff"
                  onClick={() => navigate("/notice")}
                >
                  목 록
                </Btn>
                {isAdmin && (
                  <Btn
                    size={"14px"}
                    padding={"8px 18px"}
                    lineHeight={"22px"}
                    margin={"0 0 0 10px"}
                    hoverWeight={"700"}
                    onClick={() => navigate("/notice/Edit", { state: { idx: data.idx } })}
                  >
                    수 정
                  </Btn>
                )}
                {isAdmin && (
                  <Btn
                    size={"14px"}
                    padding={"8px 18px"}
                    lineHeight={"22px"}
                    margin={"0 0 0 10px"}
                    hoverWeight={"700"}
                    onClick={openModal}
                  >
                    삭 제
                  </Btn>
                )}
              </FlexBox>
            </WhiteBox>

            {file?.map((e, i) => (
              <div
                style={{
                  padding: "20px ",
                  marginTop: "10px",
                  borderRadius: "8px",
                  background: "#fff",
                  boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
                }}
                onClick={() => handleImgRemove(e)}
              >
                <FlexBox justify={"space-between"}>
                  <FlexBox justify={"flex-start"} gap={"15px"}>
                    <img src={ico_file} style={{ width: "20px", marginLeft: "5px" }} />
                    <Text>{e.substring(e.indexOf("_") + 1)}</Text>
                  </FlexBox>
                  <img src={ico_download} style={{ width: "20px", marginLeft: "5px" }} />
                </FlexBox>
              </div>
            ))}
          </Container>
          <ConfirmModal
            modal={modalOpen}
            setModal={closeModal}
            onSubmit={() => check()}
            description={"공지사항을 삭제하시겠습니까?"}
          />
          <InfoModal modal={textModal} setModal={setTextModal} description={"삭제되었습니다."} mode={"goBack"} />
        </Wrapper>
      </ContentContainer>
    </>
  );
}

export default NoticeView;
