import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import close from "../../Assets/images/close.png";
import { FlexBox, Btn, Text } from "../../Assets/css/common";

// 리뉴얼 UI : 사용O

const InfoModal = (props) => {
  const {
    modal, // 필수
    setModal, // 필수
    isLong, // 옵션 : 들어갈 내용이 긴지, 아닌지 (긴 경우 : 상품대량등록 시 오는 긴 에러 메세지)
    mode, // 옵션 : 확인 클릭 시 '뒤로가기' 인지 '새로고침'인지 등
    title, // 옵션 : 제목이 필요한 경우 사용
    description, // 필수 : 일반 문장(예. 상품을 선택해주세요.)
    detailText, // 옵션 : 긴 내용(예.반려 사유 등)
  } = props;

  const navigate = useNavigate();

  const onClose = () => {
    setModal(false);
    if (mode === "goBack") {
      navigate(-1, { replace: true });
    } else if (mode === "refresh") {
      window.location.reload();
    }
  };

  const scrollRef = useRef();

  return (
    <>
      {modal ? (
        <ModalBg>
          <ModalConts style={isLong ? { maxWidth: "420px" } : { maxWidth: "380px" }}>
            <CancelBtn onClick={onClose}>
              <img src={close} />
            </CancelBtn>
            <ScrollBox ref={scrollRef}>
              <Text
                color={"#1E1E1E"}
                size={"20px"}
                weight={"700"}
                style={{
                  whiteSpace: "pre-wrap",
                  lineHeight: "22px",
                  // marginBottom: "5px",
                }}
              >
                {title}
              </Text>
              {description && (
                <Text
                  color={"#1E1E1E"}
                  size={"16px"}
                  weight={"400"}
                  style={{
                    // textAlign: "left",
                    whiteSpace: "pre-wrap",
                    lineHeight: 1.5,
                    marginTop: "10px",
                    // marginBottom: "15px",
                  }}
                >
                  {description}
                </Text>
              )}
            </ScrollBox>
            {detailText && (
              <ScrollBox
                ref={scrollRef}
                style={{
                  minHeight: "100px",
                  maxHeight: "200px",
                  overflowY: "auto",
                  backgroundColor: "#F2F2F2",
                  padding: "12px",
                  borderRadius: "6px",
                  marginTop: "16px",
                }}
              >
                <Text
                  color={"#2D2D2D"}
                  size={"14px"}
                  weight={"400"}
                  style={{ textAlign: "left", whiteSpace: "pre-wrap", lineHeight: 1.5 }}
                >
                  {detailText}
                </Text>
              </ScrollBox>
            )}

            <FlexBox margin={"20px 0 0"} marginMd={"24px 0 0"}>
              <Btn
                background={"#FF4A4A"}
                color={"#fff"}
                radius={"8px"}
                weight={"700"}
                size={"14px"}
                // sizeMd={"14px"}
                padding={"9px 19px"}
                // paddingMd={"10px 30px"}
                onClick={onClose}
              >
                확 인
              </Btn>
            </FlexBox>
          </ModalConts>
        </ModalBg>
      ) : (
        ""
      )}
    </>
  );
};

const ModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

const ModalConts = styled.div`
  background-color: #fff;
  max-width: 380px;
  max-height: 600px;
  width: 100%;
  padding: 60px 40px 40px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #f2f2f2;

  @media all and (max-width: 1024px) {
    max-width: 280px; // 390
    padding: 40px 20px 30px;
    margin: 0 20px;
  }
`;

const ScrollBox = styled.div`
  max-height: 420px;
  overflow: auto;
  padding: 0 10px 0 10px;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: silver;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #6c6c6c;
  }
`;

const CancelBtn = styled.button`
  position: absolute;
  right: 17.5px;
  top: 17.5px;

  @media all and (max-width: 1024px) {
    top: 12px;
    right: 12px;
  }
`;
export default InfoModal;
