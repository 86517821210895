import { useState, useEffect } from "react";
import styled from "styled-components";
import { FlexBox, Text } from "../../Assets/css/commonVer2";
import ico_sunny from "../../Assets/images/ico_sunny.svg";
import ico_suncloud from "../../Assets/images/ico_suncloud.svg";
import ico_cloudy from "../../Assets/images/ico_cloudy.svg";
import ico_rain from "../../Assets/images/ico_rain.svg";
import ico_snowrain from "../../Assets/images/ico_snowrain.svg";
import ico_snow from "../../Assets/images/ico_snow.svg";
import ico_shower from "../../Assets/images/ico_shower.svg";
import ico_moon from "../../Assets/images/ico_moon.svg";
import ico_location from "../../Assets/images/ico_location.svg";
import axios from "axios";

// TODO: 어제 기온, 금주 기온 및 날씨, 미세먼지 및 초미세먼지 오픈 API

const URL = "https://apis.data.go.kr/1360000/VilageFcstInfoService_2.0";

function HomeWeather() {
  const today = new Date();
  const datestring = today.getFullYear() + ("0" + (today.getMonth() + 1)).slice(-2) + ("0" + today.getDate()).slice(-2);

  const [data, setData] = useState(null);
  const [location, setlocation] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tmp, setTmp] = useState("");
  const [pop, setPop] = useState("");
  const [sky, setSky] = useState("");
  const [img, setImg] = useState("");
  const [pos, setPos] = useState(null);
  const [weatherData, setWeatherData] = useState([]);
  // 금주 일기예보 데이터 -test
  const thisWeekData = [
    { day: "MON", img: img, temp: 32 },
    { day: "TUE", img: img, temp: 32 },
    { day: "WEN", img: img, temp: 33 },
    { day: "THU", img: img, temp: 34 },
    { day: "FRI", img: img, temp: 35 },
    { day: "SAT", img: img, temp: 35 },
    { day: "SUN", img: img, temp: 35 },
  ];

  // TMP	1시간 기온	℃	10
  // UUU	풍속(동서성분)	m/s	12
  // VVV	풍속(남북성분)	m/s	12
  // VEC	풍향	deg	10
  // WSD	풍속	m/s	10
  // SKY	하늘상태	코드값	4
  // - 하늘상태(SKY) 코드 : 맑음(1), 구름많음(3), 흐림(4)
  // PTY	강수형태	코드값	4
  // (단기) 없음(0), 비(1), 비/눈(2), 눈(3), 소나기(4)
  // POP	강수확률	%	8
  // WAV	파고	M	8
  // PCP	1시간 강수량	범주 (1 mm)	8
  // REH	습도	%	8
  // SNO	1시간 신적설	범주(1 cm)	8

  // TMN	일 최저기온	℃	10
  // TMX	일 최고기온	℃	10

  // API
  // 현재 위치 지역명 가져오기
  const mapApi = async (lat, lon) => {
    await axios
      .get(`https://dapi.kakao.com/v2/local/geo/coord2address.json?x=${lon}&y=${lat}&input_coord=WGS84`, {
        headers: { Authorization: process.env.REACT_APP_KAKAO_REST_API_KEY },
      })
      .then(function (res) {
        setlocation(res?.data?.documents[0].address);
      })
      .catch(function (error) {
        // console.log("33", error);
      });
  };

  // 권한 없는 경우 디폴트 위치의 날씨정보 가져오기
  function geolocationError(err) {
    setPos({
      lat: "37",
      lon: "126",
    });
    mapApi(37.5744, 126.9768);
  }

  const success = ({ coords, timestamp }) => {
    const latitude = coords.latitude;
    const longitude = coords.longitude;
    const lat = latitude.toFixed(0);
    const lon = longitude.toFixed(0);
    setPos({ ...pos, lat, lon });
    mapApi(latitude, longitude);
  };

  // 현재 위치의 오늘 날씨
  const fetchData = async () => {
    try {
      setError(null);
      setData(null);
      setLoading(true);
      const response = await axios.get(URL + "/getVilageFcst", {
        params: {
          serviceKey: process.env.REACT_APP_DATA_SERVICE_KEY,
          numOfRows: 12,
          pageNo: 1,
          dataType: "json",
          base_date: datestring,
          base_time: "0500",
          nx: pos.lat,
          ny: pos.lon,
        },
        headers: {
          Accept: "application/json",
          "content-Type": "application/json",
        },
      });

      if (response?.data.response) {
        const item = response?.data.response.body.items.item;
        // console.log(item)
        if (item[6]["fcstValue"] === "0") {
          if (item[5]["fcstValue"] === "1") {
            setSky("맑음");
            setImg(ico_sunny);
          } else if (item[5]["fcstValue"] === "3") {
            setSky("구름많음");
            setImg(ico_suncloud);
          } else if (item[5]["fcstValue"] === "4") {
            setSky("흐림");
            setImg(ico_cloudy);
          }
        } else if (item[6]["fcstValue"] === "1") {
          setSky("비");
          setImg(ico_rain);
        } else if (item[6]["fcstValue"] === "2") {
          setSky("비/눈");
          setImg(ico_snowrain);
        } else if (item[6]["fcstValue"] === "3") {
          setSky("눈");
          setImg(ico_snow);
        } else if (item[6]["fcstValue"] === "4") {
          setSky("소나기");
          setImg(ico_shower);
        } else {
          setSky("");
          setImg(ico_moon);
        }
        setTmp(item[0]["fcstValue"]);
        setPop(item[7]["fcstValue"]);
      } else {
        setError(response.data);
      }
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  const tempData = async () => {
    try {
      const apiKey = process.env.REACT_APP_KAKAO_REST_API_KEY;
      const city = "Seoul"; // 원하는 도시명으로 변경
      const apiUrl = `https://apis.data.go.kr/1360000/MidFcstInfoService/getMidTa`;

      axios
        .get(apiUrl, {
          params: {
            serviceKey: process.env.REACT_APP_DATA_SERVICE_KEY,
            numOfRows: 12,
            pageNo: 1,
            dataType: "json",
            regId: "11B10101",
            tmFc: "0500",
          },
        })
        .then((response) => {
          // console.log(response, 'res')
          setWeatherData(response.data.list);
        })
        .catch((error) => {
          console.error("Error fetching weather data:", error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getUserLocation = () => {
    if (!navigator.geolocation) {
      throw "위치 정보가 지원되지 않습니다.";
    }
    navigator.geolocation.getCurrentPosition(success, geolocationError);
  };

  // 페이지 로드 시 사용자의 위치정보 가져오기
  useEffect(() => {
    getUserLocation();
  }, []);

  // 위치정보 업데이트 시 날씨 정보 가져오기
  useEffect(() => {
    if (pos) {
      fetchData();
      tempData();
    }
  }, [pos]);

  if (loading) return <WeatherEmpty>Loading...</WeatherEmpty>;
  if (error) return <WeatherEmpty>Error...</WeatherEmpty>;

  return (
    <>
      <WeatherWrap>
        <FlexBox justify={"start"}>
          <img src={ico_location} />
          <Text size={"16px"} sizeMd={"20px"} color={"#1e1e1e"} weight={"700"}>
            {location ? location.region_1depth_name : "서울"}, {location ? location.region_2depth_name : "종로구"}
          </Text>
        </FlexBox>
        <FlexBox margin={"14px 0 0"}>
          <WeekCardWrapper>
            <Icon src={img} alt="날씨 아이콘" />
          </WeekCardWrapper>
        </FlexBox>
        <Text size={"34px"} sizeMd={"46px"} color={"#1e1e1e"} weight={"700"}>
          {tmp}°
        </Text>
        <FlexBox width={"100%"} justify={"space-between"} margin={"30px 0 0"} marginMd={"50px 0 0"}>
          <Text width={"100%"} size={"14px"} sizeMd={"20px"} color={"#1e1e1e"} weight={"700"}>
            {sky}
          </Text>
          <Text width={"100%"} size={"14px"} sizeMd={"20px"} color={"#1e1e1e"} weight={"700"}>
            습도 {pop} %
          </Text>
        </FlexBox>
      </WeatherWrap>
    </>
  );
}

const WeatherEmpty = styled.div`
  min-height: 180px;

  @media all and (max-width: 1024px) {
    min-height: 128px;
  }
`;

const WeatherWrap = styled.div`
  padding: 20px 40px 30px;
  // height: 397px;
  border-radius: 10px;
  background-color: #fcc5c5;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 20px 0;

  @media all and (max-width: 1024px) {
    padding: 16px;
    // height: 287px;
  }
`;

const Icon = styled.img`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media all and (max-width: 1024px) {
    width: 120px;
  }
`;

const WeekCardWrapper = styled.div`
  // width: 110px;
  /* height: 144px; */
  background: rgb(255, 255, 255, 0.2);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: Montserrat;
  padding: 8px;

  @media all and (max-width: 1024px) {
    width: 70px;
    // margin: 20px;
    /* height: 100px; */
  }
`;

export default HomeWeather;
