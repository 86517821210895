import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import close from "../../Assets/images/close.png";
import { FlexBox, Btn, Text } from "../../Assets/css/common";
import { AddComma } from "../../Utiles/Custom";
import ico_error from "../../Assets/images/ico_error.png";

// 리뉴얼 UI : 사용O

function ConfirmModal(props) {
  const {
    modal, // 필수
    setModal, // 필수
    onSubmit = () => {}, // 필수
    title, // 옵션 : 제목 필요한 경우
    description, // 필수 : 확인하고자 하는 내용(예. 완료하시겠습니까?)
    confirmData, // 옵션 : 환불 확인창으로 쓰이는 경우 사용
  } = props;

  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const onClose = () => {
    setModal(false);
  };

  return (
    <>
      {modal ? (
        <ModalBg>
          <ModalConts
            style={
              confirmData
                ? {
                    maxWidth: "480px",
                    padding: isMobile ? "35px 50px 30px" : "35px 109px 30px",
                  }
                : {
                    maxWidth: "360px",
                    padding: "40px 55px 26px",
                  }
            }
          >
            <CancelBtn onClick={onClose}>
              <img src={close} />
            </CancelBtn>
            {confirmData && (
              <img
                src={ico_error}
                style={{
                  width: "52px",
                  margin: "0 auto 20px",
                }}
              />
            )}

            <Text
              color={"#2D2D2D"}
              size={"16px"}
              sizeMd={"20px"}
              weight={"700"}
              lineHeight={"22px"}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {title}
            </Text>
            {description && (
              <Text
                color={"#2D2D2D"}
                size={"14px"}
                sizeMd={"16px"}
                weight={"400"}
                lineHeight={"22px"}
                margin={"10px 0"}
                style={{ whiteSpace: "pre-wrap" }}
              >
                {description}
              </Text>
            )}
            {confirmData && (
              <Text
                color={"#F63958"}
                size={"12px"}
                weight={"400"}
                lineHeight={"18px"}
                style={{ whiteSpace: "pre-wrap" }}
              >
                &#40;{confirmData}&#41;
              </Text>
            )}
            <BtnConts>
              <Btn
                background={"#f2f2f2"}
                color={"#666"}
                radius={"6px"}
                weight={"500"}
                margin={"0 10px 0 0"}
                size={"12px"}
                sizeMd={"14px"}
                padding={"9px 19px"}
                paddingMd={"9px 19px"}
                onClick={onClose}
              >
                취소
              </Btn>
              <Btn
                background={"#FF4A4A"}
                color={"#fff"}
                radius={"6px"}
                weight={"700"}
                size={"12px"}
                sizeMd={"14px"}
                padding={"9px 19px"}
                paddingMd={"9px 19px"}
                onClick={onSubmit}
              >
                확인
              </Btn>
            </BtnConts>
          </ModalConts>
        </ModalBg>
      ) : (
        ""
      )}
    </>
  );
}

const ModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

const ModalConts = styled.div`
  background-color: #fff;
  // max-height: 310px;
  width: 100%;
  border-radius: 10px;
  position: relative;
  // padding: 60px 40px 40px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #f2f2f2;

  @media all and (max-width: 1024px) {
    max-width: 280px;
    padding: 40px 20px 30px;
    margin: 0 20px;
    // min-width: unset;
  }
`;

const BtnConts = styled(FlexBox)`
  margin: 24px 0 0;
`;

const CancelBtn = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;

  @media all and (max-width: 1024px) {
    top: 12px;
    right: 12px;
  }
`;

export default ConfirmModal;
