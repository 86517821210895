import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import { useMediaQuery } from "react-responsive";

import { Text } from "../../Assets/css/common";
import { ContentContainer, Wrapper, Container, WhiteBox, Btn, FlexBox } from "../../Assets/css/commonVer2";
import Header from "../../Components/Header/HeaderVer2";
import ico_arr_left from "../../Assets/images/ico_arr_prev.svg";
import ico_arr_right from "../../Assets/images/ico_arr_next.svg";
import ExChartBar from "../../Components/Chart/ExChartBar";
import ChartLine from "../../Components/Chart/ChartLine";
import { AddComma } from "../../Utiles/Custom";
import API from "../../Utiles/API";
import Authority from "../../Components/Modal/Authority";
import DataLoading from "../../Components/DataLoading";

// 월간 리포트

function ReportSales() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [today] = useState(new Date());
  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth() + 1);

  const Franchise_id = sessionStorage.getItem("franchise_id");
  const [data, setData] = useState([""]);
  const [chart, setChart] = useState([""]);
  const [other, setOther] = useState([""]);
  const [week, setWeek] = useState([""]);
  const [hour, setHour] = useState([""]);
  const [day, setDay] = useState("");
  const [check, setCheck] = useState("");
  const [check2, setCheck2] = useState("");
  const [phrase, setPhrase] = useState([]);

  const cardRef = useRef();

  const [dataLoading, setDataLoading] = useState(false);

  const onDownloadBtn = () => {
    const card = cardRef.current;
    const originalStyles = {
      backgroundColor: card.style.backgroundColor,
    };

    domtoimage.toBlob(card, { quality: 1 }).then((blob) => {
      card.style.backgroundColor = originalStyles.backgroundColor;
      saveAs(blob, year + "-" + month + ".png");
    });
  };

  const formatMonth = (month) => {
    return month < 10 ? `0${month}` : month.toString();
  };

  const SaleList = async () => {
    try {
      setDataLoading(true);
      const formattedMonth = formatMonth(month);

      const res = await API.get(`/storeowner/month-report/${Franchise_id}?filtermonth=${year}-${formattedMonth}`);

      if (res.data.status) {
        setDataLoading(false);
        setData(res.data.result);
        setChart(res.data.result.month_sale);
        setOther(res.data.result.store_comparison_avg);
        setWeek(res.data.result?.week_buy_pattern);
        setHour(res.data.result?.hour_sale_avg);

        if (
          res.data.result.month_sale?.length > 0 &&
          res.data.result.month_sale[res.data.result.month_sale.length - 1].x
        ) {
          if (res.data.result.month_sale[res.data.result.month_sale.length - 1]?.x == `${year}-${formattedMonth}`) {
            setCheck(res.data.result.month_sale[res.data.result.month_sale.length - 1]);
          } else {
            setCheck("");
          }
        } else {
          setCheck("");
        }

        if (res.data.result?.week_buy_patterns_phrase[0]?.day_week == 2) {
          setDay("월요일");
        } else if (res.data.result?.week_buy_patterns_phrase[0]?.day_week == 3) {
          setDay("화요일");
        } else if (res.data.result?.week_buy_patterns_phrase[0]?.day_week == 4) {
          setDay("수요일");
        } else if (res.data.result?.week_buy_patterns_phrase[0]?.day_week == 5) {
          setDay("목요일");
        } else if (res.data.result?.week_buy_patterns_phrase[0]?.day_week == 6) {
          setDay("금요일");
        } else if (res.data.result?.week_buy_patterns_phrase[0]?.day_week == 7) {
          setDay("토요일");
        } else if (res.data.result?.week_buy_patterns_phrase[0]?.day_week == 1) {
          setDay("일요일");
        } else {
          // setData('');
        }

        setPhrase(res.data.result?.week_buy_patterns_phrase[0]);
      } else {
        setDataLoading(false);
      }
    } catch (error) {
      console.log(error);
      setDataLoading(false);
    }
  };

  const Minus = () => {
    if (month === 1) {
      setMonth(12);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const Plus = () => {
    if (month === 12) {
      setMonth(1);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  useEffect(() => {
    SaleList();
  }, [month, year]);

  const other1 = other.map((value) => {
    const name = value.x == "my_store" ? "내 매장" : "매장 평균";

    return { x: name, y: value.y };
  });

  const week1 = week.map((value) => {
    const day =
      value.x == 2
        ? "월요일"
        : value.x == 3
        ? "화요일"
        : value.x == 4
        ? "수요일"
        : value.x == 5
        ? "목요일"
        : value.x == 6
        ? "금요일"
        : value.x == 7
        ? "토요일"
        : value.x == 1
        ? "일요일"
        : "";

    return { x: day, y: value.y };
  });

  const hour1 = hour.map((value) => {
    return { x: value.x + "시", y: value.y };
  });
  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          {dataLoading ? <DataLoading /> : null}
          {isMobile && <Header title={"월간 리포트"} />}
          <ContentContainer>
            <Wrapper>
              <Container>
                <FlexBox justify={"space-between"} className="mb-5 md:mb-4">
                  {!isMobile && (
                    <Text size={"26px"} color={"#1E1E1E"} weight={"bold"}>
                      월간 리포트📈
                    </Text>
                  )}

                  {!isMobile ? (
                    <FlexBox>
                      <Btn
                        background={"#FF4A4A"}
                        color={"#fff"}
                        weight={"bold"}
                        size={"14px"}
                        padding={"12px 20px"}
                        margin={"0 15px 0"}
                        onClick={onDownloadBtn}
                      >
                        리포트 저장
                      </Btn>
                      <CalBox>
                        <CalBoxBtn onClick={Minus}>
                          <img src={ico_arr_left} alt="이전 달" />
                        </CalBoxBtn>
                        <Text color={"#FF4A4A"} weight={"700"} size={"12px"} sizeMd={"14px"}>
                          {month < 10 ? year + "년 " + "0" + month + "월" : year + "년 " + month + "월"}
                        </Text>
                        <CalBoxBtn onClick={Plus}>
                          <img src={ico_arr_right} alt="다음 달" />
                        </CalBoxBtn>
                      </CalBox>
                    </FlexBox>
                  ) : (
                    <CalBox>
                      <CalBoxBtn onClick={Minus}>
                        <img src={ico_arr_left} alt="다음 달" />
                      </CalBoxBtn>
                      <Text color={"#FF4A4A"} weight={"700"} size={"12px"} sizeMd={"14px"}>
                        {month < 10 ? year + "년 " + "0" + month + "월" : year + "년 " + month + "월"}
                      </Text>
                      <CalBoxBtn onClick={Plus}>
                        <img src={ico_arr_right} alt="다음 달" />
                      </CalBoxBtn>
                    </CalBox>
                  )}
                </FlexBox>
                {/* 웹 */}
                <div ref={cardRef} className="d-none d-md-block">
                  <Contents className="1">
                    <WhiteBox style={{ margin: "0" }}>
                      <div>
                        <BnTextSmall>
                          <span style={{ color: "#FF4A4A", fontWeight: "bold" }}>
                            {month < 10 ? year + "년 " + "0" + month + "월" : year + "년 " + month + "월"}
                          </span>{" "}
                          매출 조회 결과
                        </BnTextSmall>
                        <BnTextBig>
                          총 매출{" "}
                          <BnSpan style={{ color: "#FF4A4A" }}>
                            {data ? AddComma(data.current_month_sale) : "0"}원
                          </BnSpan>{" "}
                          으로 지난달 보다 <br className="block sm:hidden" />
                          <BnSpan style={{ color: "#FF4A4A" }}>
                            {data.change_rate !== "NaN" && data.change_rate !== "-Infinity" ? data.change_rate : "00.0"}{" "}
                            % {data.change_rate < 0 ? "감소" : "증가"}
                          </BnSpan>{" "}
                          했어요!
                        </BnTextBig>
                      </div>
                    </WhiteBox>
                  </Contents>
                  {/* <Contents>
                    <WhiteBox style={{ margin: "0 0" }}>
                      <div>
                        <BnTextSmall>
                          타 매장은{" "}
                          <span style={{ color: "#FF4A4A", fontWeight: "bold" }}>
                            {month < 10 ? "0" + month + "월" : month + "월"}
                          </span>
                          에 어땠을까요?
                        </BnTextSmall>
                        <BnTextBig>
                          일 평균 매출{" "}
                          <BnSpan style={{ color: "#FF4A4A" }}>
                            {data ? AddComma(Math.round(data.my_store)) : "0"}원
                          </BnSpan>{" "}
                          으로 <br className="block sm:hidden" />
                          평균과{" "}
                          <BnSpan style={{ color: "#FF4A4A" }}>
                            {data.avg_comparison < 0 ? "-" : "+"}
                            {data.avg_comparison !== "NaN" ? data.avg_comparison : "00"}%
                          </BnSpan>{" "}
                          차이가 있어요!
                        </BnTextBig>
                      </div>
                    </WhiteBox>
                  </Contents> */}
                  <Contents className="1">
                    <WhiteBox>
                      <Text
                        weight={"700"}
                        color={"#1E1E1E"}
                        size={"14px"}
                        sizeMd={"16px"}
                        align={"left"}
                        margin={"0 0 30px"}
                      >
                        월간 매출
                      </Text>
                      <ExChartBar chart={chart} />
                      <CardWrap className="d-none d-md-block" style={{ marginTop: "30px" }}>
                        <CardBox className="total">
                          <CardLine className="total" />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Text size={"14px"} color={"#000"}>
                              총 매출
                            </Text>
                            <span
                              style={{
                                color: "#000",
                                fontFamily: "Montserrat",
                                fontWeight: "400",
                                fontSize: "14px",
                                display: "block",
                              }}
                            >
                              {check ? AddComma(check.y) : 0}원
                            </span>
                          </div>
                        </CardBox>
                      </CardWrap>
                      <SubCardWrap>
                        <CardBox>
                          <CardLine />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Text size={"14px"} color={"#000"}>
                              카드
                            </Text>
                            <span
                              style={{
                                color: "#000",
                                fontFamily: "Montserrat",
                                fontWeight: "400",
                                fontSize: "14px",
                                display: "block",
                              }}
                            >
                              {check ? AddComma(check.card) : 0}원
                            </span>
                          </div>
                        </CardBox>
                        <CardBox>
                          <CardLine />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Text size={"14px"} color={"#000"}>
                              현금
                            </Text>
                            <span
                              style={{
                                color: "#000",
                                fontFamily: "Montserrat",
                                fontWeight: "400",
                                fontSize: "14px",
                                display: "block",
                              }}
                            >
                              {check ? AddComma(check.cash) : 0}원
                            </span>
                          </div>
                        </CardBox>
                        <CardBox>
                          <CardLine />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Text size={"14px"} color={"#000"}>
                              포인트
                            </Text>
                            <span
                              style={{
                                color: "#000",
                                fontFamily: "Montserrat",
                                fontWeight: "400",
                                fontSize: "14px",
                                display: "block",
                              }}
                            >
                              {check ? AddComma(check.point) : 0}원
                            </span>
                          </div>
                        </CardBox>
                      </SubCardWrap>
                      <SubCardWrap>
                        <CardBox>
                          <CardLine />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Text size={"14px"} color={"#000"}>
                              카카오페이
                            </Text>
                            <span
                              style={{
                                color: "#000",
                                fontFamily: "Montserrat",
                                fontWeight: "400",
                                fontSize: "14px",
                                display: "block",
                              }}
                            >
                              {check ? AddComma(check.kakao) : 0}원
                            </span>
                          </div>
                        </CardBox>
                        <CardBox>
                          <CardLine />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Text size={"14px"} color={"#000"}>
                              제로페이
                            </Text>
                            <span
                              style={{
                                color: "#000",
                                fontFamily: "Montserrat",
                                fontWeight: "400",
                                fontSize: "14px",
                                display: "block",
                              }}
                            >
                              {check ? AddComma(check.zero) : 0}원
                            </span>
                          </div>
                        </CardBox>
                        <CardBox>
                          <CardLine />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Text size={"14px"} color={"#000"}>
                              네이버페이
                            </Text>
                            <span
                              style={{
                                color: "#000",
                                fontFamily: "Montserrat",
                                fontWeight: "400",
                                fontSize: "14px",
                                display: "block",
                              }}
                            >
                              {check ? AddComma(check.naver) : 0}원
                            </span>
                          </div>
                        </CardBox>
                      </SubCardWrap>
                    </WhiteBox>
                  </Contents>
                  {/* <Contents>
                    <WhiteBox>
                      <Text weight={"700"} color={"#1E1E1E"} size={"14px"} sizeMd={"16px"} align={"left"}>
                        평균 매출 비교
                      </Text>
                      <div style={{ padding: "40px 0 54px 0", width: "100%" }}>
                        <ExChartBar chart={other1} />
                      </div>
                    </WhiteBox>
                  </Contents> */}
                  <FlexBox justify={"space-between"} align={"flex-start"} wrap={"wrap"}>
                    <Contents width={"calc(50% - 13px)"}>
                      <WhiteBox style={{ margin: "0" }}>
                        <div>
                          <BnTextSmall>
                            {month < 10 ? "0" + month + "월 중" : month + "월 중"}{" "}
                            <span style={{ color: "#FF4A4A", fontWeight: "bold" }}> {day ? day : "--"}</span> 매출이
                            가장 높았네요!
                          </BnTextSmall>
                          <BnTextBig>
                            <BnSpan style={{ lineHeight: "3.5rem", color: "#FF4A4A" }}>{day ? day : "--"}</BnSpan> 에
                            가장 많이 팔린 상품은 {/* <br className="block sm:hidden" /> */}
                            <br />
                            <BnSpan style={{ lineHeight: "3.5rem", color: "#FF4A4A" }}>
                              {phrase ? phrase.product_name : "--"}
                            </BnSpan>{" "}
                            이에요!
                          </BnTextBig>
                        </div>
                      </WhiteBox>
                    </Contents>
                    <Contents width={"calc(50% - 13px)"}>
                      <WhiteBox style={{ margin: "0", height: "100%" }}>
                        <div>
                          {isMobile ? (
                            <BnTextSmall
                              style={{
                                color: "#FF4A4A",
                              }}
                            >
                              *해당 시간에 이슈가 발생할 수 있으니 각별한 관리를 해주세요!
                            </BnTextSmall>
                          ) : (
                            <BnTextSmall BnTextSmall>
                              *해당 시간에 이슈가 발생할 수 있으니 각별한 관리를 해주세요!
                            </BnTextSmall>
                          )}

                          <BnTextBig style={{ lineHeight: "3rem" }}>
                            하루 24시간 중{" "}
                            <BnSpan style={{ color: "#FF4A4A" }}>
                              {data.hour_sale_avg_top ? data.hour_sale_avg_top["x"] : "--"}시
                            </BnSpan>{" "}
                            에 <br className="block sm:hidden" />
                            매출이 높은 걸 보니 방문자가 많아요!
                          </BnTextBig>
                        </div>
                      </WhiteBox>
                    </Contents>
                    <Contents width={"calc(50% - 13px)"}>
                      <WhiteBox style={{ margin: "0" }}>
                        <Text
                          weight={"700"}
                          color={"#1E1E1E"}
                          size={"14px"}
                          sizeMd={"16px"}
                          align={"left"}
                          margin={"0 0 20px"}
                        >
                          일주일 구매 패턴
                        </Text>
                        <ExChartBar chart={week1} />
                      </WhiteBox>
                    </Contents>
                    <Contents width={"calc(50% - 13px)"}>
                      <WhiteBox style={{ margin: "0" }}>
                        <Text
                          weight={"700"}
                          color={"#1E1E1E"}
                          size={"14px"}
                          sizeMd={"16px"}
                          align={"left"}
                          margin={"0 0 20px"}
                        >
                          시간대별 매출
                        </Text>
                        <ChartLine setCheck={setCheck2} chart={hour1} />
                      </WhiteBox>
                    </Contents>
                  </FlexBox>
                </div>
                {/* 모바일 */}
                <div className="d-block d-md-none">
                  <Contents>
                    <WhiteBox>
                      <div>
                        <BnTextSmall>
                          <span style={{ color: "#FF4A4A", weight: "bold" }}>
                            {month < 10 ? year + "년 " + "0" + month + "월" : year + "년 " + month + "월"}
                          </span>{" "}
                          매출 조회 결과
                        </BnTextSmall>
                        <BnTextBig>
                          총 매출{" "}
                          <BnSpan style={{ color: "#FF4A4A" }}>
                            {data ? AddComma(data.current_month_sale) : "0"}원
                          </BnSpan>{" "}
                          으로 지난달 보다 <br className="block sm:hidden" />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "right",
                              alignItems: "center",
                              margin: "10px 0 0",
                            }}
                          >
                            <BnSpan style={{ color: "#FF4A4A" }}>
                              {data.change_rate !== "NaN" && data.change_rate !== "-Infinity"
                                ? data.change_rate
                                : "00.0"}{" "}
                              % {data.change_rate < 0 ? "감소" : "증가"}
                            </BnSpan>{" "}
                            했어요!
                          </div>
                        </BnTextBig>
                      </div>
                    </WhiteBox>
                  </Contents>
                  <Contents>
                    <WhiteBox>
                      <Text weight={"700"} color={"#1E1E1E"} size={"14px"} sizeMd={"16px"} align={"left"}>
                        월간 매출
                      </Text>
                      <ChartCardWrap>
                        <div
                          style={{
                            overflowX: "scroll",
                            flex: 1,
                            padding: "10px",
                          }}
                        >
                          <div style={{ minWidth: "300px" }}>
                            <ExChartBar chart={chart} />
                          </div>
                        </div>
                        <CardWrap className="d-block d-md-none">
                          <CardBox className="total">
                            <CardLine className="total" />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Text size={"14px"} color={"#000"}>
                                총 매출
                              </Text>
                              <span
                                style={{
                                  color: "#000",
                                  fontFamily: "Montserrat",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  display: "block",
                                }}
                              >
                                {check ? AddComma(check.y) : 0}원
                              </span>
                            </div>
                          </CardBox>
                        </CardWrap>
                        <SubCardWrap>
                          <CardBox>
                            <CardLine />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Text size={"14px"} color={"#000"}>
                                카드
                              </Text>
                              <span
                                style={{
                                  color: "#000",
                                  fontFamily: "Montserrat",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  display: "block",
                                }}
                              >
                                {check ? AddComma(check.card) : 0}원
                              </span>
                            </div>
                          </CardBox>
                          <CardBox>
                            <CardLine />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Text size={"14px"} color={"#000"}>
                                현금
                              </Text>
                              <span
                                style={{
                                  color: "#000",
                                  fontFamily: "Montserrat",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  display: "block",
                                }}
                              >
                                {check ? AddComma(check.cash) : 0}원
                              </span>
                            </div>
                          </CardBox>
                          <CardBox>
                            <CardLine />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Text size={"14px"} color={"#000"}>
                                포인트
                              </Text>
                              <span
                                style={{
                                  color: "#000",
                                  fontFamily: "Montserrat",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  display: "block",
                                }}
                              >
                                {check ? AddComma(check.point) : 0}원
                              </span>
                            </div>
                          </CardBox>
                        </SubCardWrap>
                        <SubCardWrap>
                          <CardBox>
                            <CardLine />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Text size={"14px"} color={"#000"}>
                                카카오페이
                              </Text>
                              <span
                                style={{
                                  color: "#000",
                                  fontFamily: "Montserrat",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  display: "block",
                                }}
                              >
                                {check ? AddComma(check.kakao) : 0}원
                              </span>
                            </div>
                          </CardBox>
                          <CardBox>
                            <CardLine />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Text size={"14px"} color={"#000"}>
                                제로페이
                              </Text>
                              <span
                                style={{
                                  color: "#000",
                                  fontFamily: "Montserrat",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  display: "block",
                                }}
                              >
                                {check ? AddComma(check.zero) : 0}원
                              </span>
                            </div>
                          </CardBox>
                          <CardBox>
                            <CardLine />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Text size={"14px"} color={"#000"}>
                                네이버페이
                              </Text>
                              <span
                                style={{
                                  color: "#000",
                                  fontFamily: "Montserrat",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  display: "block",
                                }}
                              >
                                {check ? AddComma(check.naver) : 0}원
                              </span>
                            </div>
                          </CardBox>
                        </SubCardWrap>
                      </ChartCardWrap>
                    </WhiteBox>
                  </Contents>
                  {/* <Contents>
                    <WhiteBox>
                      <div>
                        <BnTextSmall>
                          타 매장은{" "}
                          <span style={{ color: "#FF4A4A", fontWeight: "bold" }}>
                            {month < 10 ? "0" + month + "월" : month + "월"}
                          </span>
                          에 어땠을까요?
                        </BnTextSmall>
                        <BnTextBig>
                          일 평균 매출{" "}
                          <BnSpan style={{ color: "#FF4A4A" }}>
                            {data ? AddComma(Math.round(data.my_store)) : "0"}원
                          </BnSpan>{" "}
                          으로 평균과
                          <br className="block sm:hidden" />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "right",
                              alignItems: "center",
                              margin: "10px 0 0",
                            }}
                          >
                            <BnSpan style={{ color: "#FF4A4A" }}>
                              {data.avg_comparison < 0 ? "-" : "+"}
                              {data.avg_comparison !== "NaN" ? data.avg_comparison : "00"}%
                            </BnSpan>{" "}
                            차이가 있어요!
                          </div>
                        </BnTextBig>
                      </div>
                    </WhiteBox>
                  </Contents>
                  <Contents>
                    <WhiteBox>
                      <Text weight={"700"} color={"#1E1E1E"} size={"14px"} sizeMd={"16px"} align={"left"}>
                        평균 매출 비교
                      </Text>
                      <div className="py-2 px-1.5 md:p-5 rounded md:rounded-lg">
                        <ExChartBar chart={other1} />
                      </div>
                    </WhiteBox>
                  </Contents> */}
                  <Contents>
                    <WhiteBox>
                      <div>
                        <BnTextSmall>
                          {month < 10 ? "0" + month + "월 중" : month + "월 중"}{" "}
                          <span style={{ color: "#FF4A4A", fontWeight: "bold" }}> {day ? day : "--"}</span> 매출이 가장
                          높았네요!
                        </BnTextSmall>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <BnTextBig>
                            <BnSpan style={{ color: "#FF4A4A" }}>{day ? day : "--"}</BnSpan> 에 가장 많이 팔린 상품은{" "}
                            <div style={{ margin: "27px 0 10px" }}>
                              <BnSpan style={{ color: "#FF4A4A" }}>{phrase ? phrase.product_name : "--"}</BnSpan>
                            </div>{" "}
                            이에요!
                          </BnTextBig>
                        </div>
                      </div>
                    </WhiteBox>
                  </Contents>
                  <Contents>
                    <WhiteBox>
                      <Text weight={"700"} color={"#1E1E1E"} size={"14px"} sizeMd={"16px"} align={"left"}>
                        일주일 구매 패턴
                      </Text>
                      <div
                        style={{
                          overflowX: "scroll",
                          flex: 1,
                          padding: "10px",
                        }}
                      >
                        <div style={{ minWidth: "300px" }}>
                          <ExChartBar chart={week1} />
                        </div>
                      </div>
                    </WhiteBox>
                  </Contents>
                  <Contents>
                    <WhiteBox>
                      <div>
                        <BnTextSmall className="hidden md:block">
                          *해당 시간에 이슈가 발생할 수 있으니 각별한 관리를 해주세요!
                        </BnTextSmall>
                        <BnTextSmall className="block md:hidden small">
                          *해당 시간에 이슈가 발생할 수 있으니 각별한 관리를 해주세요!
                        </BnTextSmall>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <BnTextBig>
                            하루 24시간 중{" "}
                            <BnSpan style={{ color: "#FF4A4A" }}>
                              {data.hour_sale_avg_top ? data.hour_sale_avg_top["x"] : "--"}시
                            </BnSpan>{" "}
                            에 매출이 높은 걸 보니
                            <br className="block sm:hidden" />
                            <div style={{ margin: "20px 0 0" }}>방문자가 많아요!</div>
                          </BnTextBig>
                        </div>
                      </div>
                    </WhiteBox>
                  </Contents>
                  <Contents>
                    <WhiteBox>
                      <Text weight={"700"} color={"#1E1E1E"} size={"14px"} sizeMd={"16px"} align={"left"}>
                        시간대별 매출
                      </Text>
                      <div
                        style={{
                          overflowX: "scroll",
                          flex: 1,
                          padding: "10px",
                        }}
                      >
                        <div style={{ minWidth: "300px" }}>
                          <ChartLine setCheck={setCheck2} chart={hour1} />
                        </div>
                      </div>
                    </WhiteBox>
                  </Contents>
                </div>
              </Container>
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const Contents = styled.div`
  width: ${(props) => props.width && props.width};
  // height: ${(props) => props.width && props.width};
  margin: 0 0 30px;

  @media all and (max-width: 1060px) {
    width: 100%;
  }
  // @media all and (max-width: 1024px) {
  //   width: unset;
  // }
`;
const CalBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ff4a4a;
  border-radius: 6px;
  /* gap: 20px; */
  padding: 8px 0;
  width: 190px;

  @media all and (max-width: 1024px) {
    padding: 7px 0;
    width: 100%;
    /* margin: 0 auto; */
  }
`;
const CalBoxBtn = styled(Btn)`
  padding: 6px 14px;
  border: 0;

  :hover {
    background: unset;
  }
`;
const BnTextSmall = styled.p`
  font-size: 18px;
  color: #1e1e1e;
  word-break: keep-all;

  &.small {
    font-size: 10px;
  }

  @media all and (max-width: 1024px) {
    font-size: 12px;
  }
`;
const BnTextBig = styled.p`
  font-size: 20px;
  color: #1e1e1e;
  font-weight: 400;
  word-break: keep-all;
  margin: 27px 0;

  @media all and (max-width: 1024px) {
    font-size: 12px;
    font-weight: 400;
  }

  @media all and (max-width: 767px) {
    margin: 17px 0 0;
  }

  @media all and (max-width: 285px) {
    font-size: 11px;
  }
`;
const BnSpan = styled.span`
  font-size: 20px;
  color: #ff4a4a;
  font-weight: bold;
  background: rgba(255, 116, 64, 0.1);
  box-shadow: 0px 0px 10px rgba(255, 116, 64, 0.1);
  border-radius: 6px;
  padding: 9px 16px;

  @media all and (max-width: 1024px) {
    font-size: 14px;
    padding: 11px 12px;
  }

  @media all and (max-width: 430px) {
    margin: 10px 0;
  }
`;
const ChartCardWrap = styled.div`
  padding: 20px;

  @media all and (max-width: 1024px) {
    border: 0;
    padding: 0;
  }
`;

const CardWrap = styled(FlexBox)`
  margin-top: 20px;
  gap: 15px;

  /* &.web{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  } */

  @media all and (max-width: 1060px) {
    flex-direction: column;
  }
`;

const SubCardWrap = styled(FlexBox)`
  justify-content: left;
  @media all and (max-width: 1060px) {
    flex-direction: column;
  }
`;

const CardBox = styled.div`
  width: calc((100% - 45px) / 4);
  /* max-width: 173px; */
  /* height: 34px; */
  display: flex;
  align-items: center;
  padding: 5px 6px;

  &.total {
    background: rgba(255, 116, 64, 0.1);
    border-radius: 6px;
    align-items: center;
  }

  @media all and (max-width: 1060px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 0;
  }
`;

const CardLine = styled.div`
  width: 4px;
  height: 24px;
  border-radius: 4px;
  background: #ff7440;
  margin-right: 10px;

  &.total {
    background: #ff4a4a;
  }
`;

export default ReportSales;
