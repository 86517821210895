// 외부 모듈
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

// 내부 모듈
import {
  HeaderContainer,
  ContentContainer,
  Wrapper,
  Container,
  FlexBox,
  TableWrap,
  Th,
  Td,
  Btn,
  Title,
  Text,
  SquareImg,
  WhiteBox,
} from "../../Assets/css/commonVer2";
import img_noimage from "../../Assets/images/no_image.jpg";
import Header from "../../Components/Header/HeaderVer2";
import HeaderSub from "../../Components/Header/HeaderSub";
import Authority from "../../Components/Modal/Authority";
import InfoModal from "../../Components/Modal/InfoModal";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import Pagination from "../../Components/Paging/Pagination";
import HandleBack from "../../Components/HandleBack";
import Loading from "../../Components/Loading";
import { AddComma } from "../../Utiles/Custom";
import API from "../../Utiles/API";
import BasicSelect from "../../Components/Select/BasicSelect";
import { UNIT_10_TO_30_LIST } from "../../Utiles/ListData";

// 점주_마이페이지_재입고 알림 내역

export default function RestockStoreOwner() {
  const navigate = useNavigate();
  const { handlePopstate } = HandleBack();
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const [franchise_id] = useState(sessionStorage.getItem("franchise_id"));
  const [productId, setProductId] = useState(0);

  const [saveModal, setSaveModal] = useState(false);
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");

  const [pagination, setPagination] = useState("");

  // 상품 상세를 다녀온 경우 저장 해놓은 파라미터 정보
  const parsedRestockPageState = sessionStorage.getItem("restockPageState")
    ? JSON.parse(sessionStorage.getItem("restockPageState"))
    : {};

  const [unit, setUnit] = useState(
    parsedRestockPageState ? (parsedRestockPageState?.unit ? parsedRestockPageState?.unit : 10) : 10
  );

  const [nowPage, setNowPage] = useState(
    parsedRestockPageState ? (parsedRestockPageState?.page ? parsedRestockPageState?.page : 1) : 1
  );

  const start = (nowPage - 1) * unit;

  // 상품 상세를 갈 때 sessionStorage에 저장할 객체
  const restockPageState = {
    unit: unit,
    page: nowPage,
  };

  // 재입고 알림 상품 데이터
  const [data, setData] = useState([]);

  // API - 재입고 알림 내역 조회
  const getMyRestockList = async () => {
    setLoading(true);
    await API.get(`/storeowner/restock?franchise_id=${franchise_id}&page=${nowPage}&unit=${unit}&block=10`)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setData(res.data.results);
          setPagination(res.data.pagination);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  // API - 재입고 알림 해제
  const deleteRestockAlarm = async () => {
    setLoading(true);
    await API.delete(`/storeowner/restock?franchise_id=${franchise_id}&product_id=${productId}`)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          getMyRestockList();
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(function (error) {
        setLoading(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  function onClickProduct(product_id) {
    navigate("/goods/view", { state: { idx: product_id } });
    let jsonString = JSON.stringify(restockPageState);
    sessionStorage.setItem("restockPageState", jsonString);
  }

  function movePage(e) {
    setNowPage(e);
  }

  const onSubmit = () => {
    deleteRestockAlarm();
    setSaveModal(false);
  };

  useEffect(() => {
    if (!parsedRestockPageState?.page && nowPage !== 1) {
      setNowPage(1);
    } else {
      getMyRestockList();
    }
  }, [unit]);

  useEffect(() => {
    getMyRestockList();
  }, [nowPage]);

  useEffect(() => {
    sessionStorage.removeItem("restockPageState");
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          {loading ? <Loading /> : null}

          <HeaderSub title={"재입고 알림 내역"} />
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                      재입고 알림 내역 🔔
                  </Title>
                )}

                <WhiteBox margin={"0 0"}>
                  {!isMobile && (
                    <FlexBox margin={"0 0 30px"} justify={"start"}>
                      <BasicSelect
                        width={"130px"}
                        data={UNIT_10_TO_30_LIST}
                        selectedValue={unit}
                        setSelectedValue={setUnit}
                        canSelectAll={false}
                        setCurrentPage={setNowPage}
                      />
                    </FlexBox>
                  )}
                  <TableWrap>
                    <table style={{ width: "100%" }}>
                      <colgroup>
                        <col className="d-none d-md-table-column" style={{ width: "10%" }} />
                        <col style={{ width: "40%" }} />
                        <col className="d-none d-md-table-column" style={{ width: "20%" }} />
                        <col className="d-none d-md-table-column" style={{ width: "15%" }} />
                        <col className="d-none d-md-table-column" style={{ width: "15%" }} />
                      </colgroup>
                      <thead className="d-none d-md-table-head">
                        <tr>
                          <Th>No</Th>
                          <Th> 상품명</Th>
                          <Th> 상품금액</Th>
                          <Th> 신청일</Th>
                          <Th> 알림 관리</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 ? (
                          data?.map((item, key) => (
                            <tr key={item.product_id}>
                              <ProductTd
                                className="d-none d-md-table-cell"
                                onClick={() => onClickProduct(item.product_id)}
                              >
                                {start + key + 1}
                              </ProductTd>
                              {/* 웹 */}
                              <ProductTd
                                className="d-none d-md-table-cell"
                                onClick={() => onClickProduct(item.product_id)}
                              >
                                <FlexBox justify={"flex-start"} gap={"10px"}>
                                  <ProductImg>
                                    <SquareImg>
                                      <div>
                                        <img
                                          src={
                                            item.image_path_1
                                              ? process.env.REACT_APP_S3_KEY + item.image_path_1
                                              : img_noimage
                                          }
                                        />
                                      </div>
                                    </SquareImg>
                                  </ProductImg>
                                  <ProductText>
                                    <FlexBox justify={"left"}>
                                      <Text size={"10px"} color={"#ef6f6f"} align={"left"}>
                                        {item.brand ? item.brand : ""}
                                      </Text>
                                    </FlexBox>
                                    <Text size={"12px"} weight={"500"} color={"#2d2d2d"} align={"left"}>
                                      {item.product_name}
                                    </Text>
                                    <FlexBox justify={"left"}>
                                      <Text size={"12px"} color={"#969696"} align={"left"}>
                                        {item.barcode_num}&nbsp;
                                      </Text>
                                      <Text
                                        className="d-none d-md-table-cell"
                                        size={"12px"}
                                        color={"#969696"}
                                        align={"left"}
                                      >
                                        {item.order_unit === "개"
                                          ? "*개"
                                          : `${item.order_unit}(${item.product_quantity}개입)`}
                                      </Text>
                                    </FlexBox>
                                  </ProductText>
                                </FlexBox>
                              </ProductTd>
                              {/* 모바일 */}
                              <ProductTd className="d-block d-md-none">
                                <FlexBox justify={"flex-start"} gap={"10px"}>
                                  <ProductImg onClick={() => onClickProduct(item.product_id)}>
                                    <SquareImg>
                                      <div>
                                        <img
                                          src={
                                            item.image_path_1
                                              ? process.env.REACT_APP_S3_KEY + item.image_path_1
                                              : img_noimage
                                          }
                                        />
                                      </div>
                                    </SquareImg>
                                  </ProductImg>
                                  <ProductText>
                                    <FlexBox justify={"left"}>
                                      {/* {item?.brand && (
                                          <Text size={"10px"} color={"#6c6c6c"} margin={"0 4px"}>
                                            {"|"}
                                          </Text>
                                        )} */}
                                      <Text size={"10px"} color={"#ef6f6f"} align={"left"}>
                                        {item.brand ? item.brand : ""}
                                      </Text>
                                    </FlexBox>
                                    <Text size={"12px"} weight={"500"} color={"#2d2d2d"} align={"left"}>
                                      {item.product_name}
                                    </Text>
                                    <FlexBox justify={"left"}>
                                      <Text size={"12px"} color={"#969696"} align={"left"}>
                                        {item.barcode_num}&nbsp;
                                      </Text>
                                      <Text
                                        // className="d-none d-md-table-cell"
                                        size={"12px"}
                                        color={"#969696"}
                                        align={"left"}
                                      >
                                        {item.order_unit === "개"
                                          ? "*개"
                                          : `${item.order_unit}(${item.product_quantity}개입)`}
                                      </Text>
                                    </FlexBox>
                                    <FlexBox>
                                      <FlexBox flex={1} />
                                      <Btn
                                        className="btn"
                                        background={"#FF4A4A"}
                                        radius={"6px"}
                                        padding={"4px 12px"}
                                        margin={"0 auto 0 0"}
                                        color={"#fff"}
                                        size={"12px"}
                                        onClick={() => {
                                          setSaveModal(true);
                                          setProductId(item.product_id);
                                        }}
                                      >
                                        해제
                                      </Btn>
                                    </FlexBox>
                                  </ProductText>
                                </FlexBox>
                              </ProductTd>
                              <ProductTd
                                align="end"
                                className="d-none d-md-table-cell"
                                onClick={() => onClickProduct(item.product_id)}
                              >
                                <Text size={"12px"} color="666666">
                                  {`(공급가) ${AddComma(item.shipping_price)}원`}
                                  <br />
                                  {`(소비자가) ${AddComma(item.consumer_price)}원`}
                                </Text>
                              </ProductTd>
                              <ProductTd
                                className="d-none d-md-table-cell"
                                onClick={() => onClickProduct(item.product_id)}
                              >
                                <Text size={"12px"} color={"#666666"}>
                                  {item.createdAt.slice(0, 10)}
                                </Text>
                              </ProductTd>
                              <ProductTd className="d-none d-md-table-cell">
                                <Btn
                                  background={"#FF4A4A"}
                                  radius={"6px"}
                                  padding={"5px 16px"}
                                  margin={"0 auto"}
                                  color={"#fff"}
                                  size={"14px"}
                                  onClick={() => {
                                    setSaveModal(true);
                                    setProductId(item.product_id);
                                  }}
                                >
                                  해제
                                </Btn>
                              </ProductTd>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={5}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              재입고 알림 내역이 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  {pagination?.total_page > 0 ? (
                    <Pagination pagination={pagination} movePage={movePage} currentPage={nowPage} />
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
            </Wrapper>
          </ContentContainer>

          <ConfirmModal
            modal={saveModal}
            setModal={setSaveModal}
            onSubmit={() => onSubmit()}
            description={"알림을 해제하시겠습니까?"}
          />
          <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
        </>
      )}
    </>
  );
}

const ProductTd = styled(Td)`
  // font-size: 12px;
  // font-weight: 400;
  // color: #6c6c6c;
  // border-right: 1px solid #c0c0c0;
  border-bottom: 1px solid #f2f2f2;

  &:last-of-type {
    border-right: 0;
  }

  @media all and (max-width: 1024px) {
    border-right: 0;
  }
`;

const ProductImg = styled.div`
  border-radius: 10px;
  width: 48px;
  border: 1px solid #eaeaea;

  @media all and (max-width: 1024px) {
    width: 82px;
  }
`;

const ProductText = styled.div`
  width: calc(100% - 58px);
  display: flex;
  flex-direction: column;
  gap: 5px;

  .btn {
    display: none;
  }

  @media all and (max-width: 1024px) {
    width: calc(100% - 92px);
    gap: 0;

    .btn {
      display: block;
    }
  }
`;
