import React, { } from 'react'
import styled from 'styled-components';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import {
  Btn,
  FlexBox,
  DateInputBox,
  SearchInput
} from "../../../Assets/css/commonVer2";
import "../../../Assets/css/DatePicker.css"
import ShopTakeBackStatusSelect from './ShopTakeBackStatusSelect';
import { dateToString } from '../../../Utiles/dateToString';
import { handleKeyDown } from "../../../Utiles/Custom";

// 관리자>쇼핑몰>반품 관리 검색바
export default function ShopTakeBackSearchBar({
  dispatchSearchState,
  searchTakeBack, //dispatchSearchState 값 변경 함수
  selectedText, // 반품상태값
  setSelectedText, // 반품상태 변경 함수
  setSearchReview, // 반품 상태 돌려 받을 state
  inputTakeBackId,
  setInputTakeBackId,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {

  return (
    <>
      <FlexBox justify={"right"} gap={"10px"} wrap={"wrap"}>
        <DateFlexBox>
          {/* startDate 달력 */}
          <DateInputBox>
            <DatePicker
              dateFormatCalendar="yyyy년 MM월"
              selected={startDate}
              onChange={(date) => {
                setStartDate(date)
                dispatchSearchState({ type: "CHANGE_START_DATE", value: dateToString(date) })
              }}
              dateFormat="yyyy-MM-dd"
              maxDate={endDate}
              startDate={startDate}
              endDate={endDate}
            />
          </DateInputBox>
          {/* endDate 달력 */}
          <DateInputBox>
            <DatePicker
              dateFormatCalendar="yyyy년 MM월"
              selected={endDate}
              onChange={(date) => {
                setEndDate(date)
                dispatchSearchState({ type: "CHANGE_END_DATE", value: dateToString(date) })
              }}
              dateFormat="yyyy-MM-dd"
              minDate={startDate}
              startDate={startDate}
              endDate={endDate}
            />
          </DateInputBox>
        </DateFlexBox>
        <ShopTakeBackStatusSelect
          selectedText={selectedText}
          setSelectedText={setSelectedText}
          setSearchReview={setSearchReview}
          width={"200px"}
          widthMd={"100%"}
        />
        <InputAndBtnBox>
          <SearchInput
            width={"100%"}
            widthMd={"262px"}
            borderBottom={"1px solid #666"}
            borderBottomColor={"#ff4a4a"}
            transition={"border-color 0.3s ease-in-out"}
            placeholder={"반품 번호를 입력해주세요."}
            type="number"
            pattern="[0-9]+"
            value={inputTakeBackId}
            onChange={(e) => {
              setInputTakeBackId(e.target.value);
            }}
            onKeyDown={(event) => handleKeyDown(event, searchTakeBack)}
            />
          <Btn
            color={"#fff"}
            background={"#FF4A4A"}
            size={"14px"}
            weight={"700"}
            width={"100%"}
            widthMd={"unset"}
            padding={"8px 18px"}
            lineHeight={"22px"}
            style={{ flexShrink: 0 }}
            onClick={searchTakeBack}
            >
            검 색
          </Btn>
        </InputAndBtnBox>
      </FlexBox>
    </>
  )
}

const DateFlexBox = styled(FlexBox)`
  gap: 10px;
  flex: unset;

  @media (max-width: 1024px) {
    flex: 1;
  }
`

const InputAndBtnBox = styled(FlexBox)`
  gap: 10px;
  flex-wrap: nowrap;
  
  @media (max-width: 1024px) {
    width: 100%;
  }
  
  @media (max-width: 1023px) {
    width: 100%;
    flex-wrap: wrap;
  }
`