// 외부 모듈
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";

// 내부 모듈
import {
  ContentContainer,
  Container,
  Wrapper,
  WhiteBox,
  FlexBox,
  // TableWrap,
  Th,
  Td,
  Title,
  DateInputBox,
  Btn,
  PagerWrap,
} from "../../Assets/css/commonVer2";
import HeaderSub from "../../Components/Header/HeaderSub";
import Paging from "../../Components/Paging/Paging";
import Authority from "../../Components/Modal/Authority";
import InfoModal from "../../Components/Modal/InfoModal";
import HandleBack from "../../Components/HandleBack";
import API from "../../Utiles/API";
import { dateToString, AddComma, Xlsx_download } from "../../Utiles/Custom";

function SalesView() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const date = new Date();
  const year = date.getFullYear(); // 년
  const month = date.getMonth(); // 월
  const day = date.getDate(); // 일

  const { handlePopstate } = HandleBack();

  const [startDate, setStartDate] = useState(new Date(year, month, day - 1));
  const [endDate, setEndDate] = useState(new Date());
  const [Franchise_id] = useState(sessionStorage.getItem("franchise_id"));
  const [data, setDate] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [totalPage, setTotalPage] = useState();

  const [textModal, setTextModal] = useState(false); // 모달 창 표시 여부 상태
  const [textMsg, setTextMsg] = useState("");

  // API
  const search = async () => {
    try {
      const res = await API.get(
        `/storeowner/sale-select/sale-history/${Franchise_id}?curPage=${currentPage}&pageSize=10&filterDatemin=${dateToString(
          startDate,
        )}&filterDatemax=${dateToString(endDate)}`,
      );
      if (res.data.status) {
        setDate(res.data.result);
        setTotalData(res.data.total);
        setTotalCount(res.data.total[0].cntAll);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onExcelDown = async () => {
    if (totalData[0]?.cntAll > 0) {
      // api 호출
      const res = await API.get(
        `/storeowner/sale-select/sale-history/${Franchise_id}?curPage=1&pageSize=9999999&filterDatemin=${dateToString(
          startDate,
        )}&filterDatemax=${dateToString(endDate)}`,
      );
      if (res.data.status && res.data.result?.length > 0) {
        // 엑셀 포맷으로 변경
        var xlsxTemp = res.data.result?.map(function (item) {
          return {
            일시: item.createdat,
            총매출: item.total ?? 0,
            "자점매입 총매출": item.self ?? 0,
            현금결제: item.cash ?? 0,
            카드결제: item.card ?? 0,
            포인트결제: item.point ?? 0,
            카카오페이결제: item.kakao ?? 0,
            제로페이결제: item.zero ?? 0,
            네이버페이결제: item.naver ?? 0,
            "총 할인": item.total_discount_price ?? 0,
            "총 매입가": item.total_purchase_price ?? 0,
            "총 순이익": item.total - item.total_discount_price - item.total_purchase_price,
            승인: item.approval,
            취소: item.cancel,
            // 매출: item.sale,
          };
        });
        // 다운로드 실행
        Xlsx_download(xlsxTemp, `전체매출조회_${dateToString(startDate)}~${dateToString(endDate)}`);
      } else {
        setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    } else {
      setTextMsg("다운받을 데이터가 없습니다.");
      setTextModal(true);
    }
  };

  useEffect(() => {
    const SaleList = async () => {
      try {
        const res = await API.get(
          `/storeowner/sale-select/sale-history/${Franchise_id}?curPage=${currentPage}&pageSize=10&filterDatemin=${dateToString(
            startDate,
          )}&filterDatemax=${dateToString(endDate)}`,
        );
        if (res.data.status) {
          setDate(res.data?.result);
          setTotalData(res.data?.total);
          setTotalCount(res.data.total[0]?.cntAll);
        }
      } catch (error) {
        console.log(error);
      }
    };
    SaleList();
  }, [currentPage]);

  useEffect(() => {
    setTotalPage(Math.ceil(totalCount / pageSize));
  }, [totalCount, pageSize]);

  useEffect(() => {
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          <HeaderSub title={"전체 매출 조회"} />
          <ContentContainer>
            <Wrapper ptM="70px" style={{ paddingBottom: "150px" }}>
              <Container>
                {!isMobile && (
                  <FlexBox justify={"space-between"} style={{ flex: 1 }} margin={"0 0 10px"}>
                    <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                      전체 매출 조회📁
                    </Title>
                  </FlexBox>
                )}
                {isMobile && (
                  <FlexBox margin="0 0 10px">
                    <FlexBox gap="8px" justify={"end"} width="100%">
                      <DateInputBox>
                        <DatePicker
                          locale="ko"
                          dateFormatCalendar="yyyy년 MM월"
                          dateFormat="yyyy-MM-dd"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={endDate}
                        />
                      </DateInputBox>
                      <DateInputBox>
                        <DatePicker
                          locale="ko"
                          dateFormatCalendar="yyyy년 MM월"
                          dateFormat="yyyy-MM-dd"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </DateInputBox>
                      <Btn
                        color={"#fff"}
                        background={"#FF4A4A"}
                        radius={"6px"}
                        size={"12px"}
                        sizeMd={"14px"}
                        weight={"700"}
                        padding={"9px 19px"}
                        border="unset"
                        margin="2px 0 0"
                        lineHeight={"22px"}
                        style={{ minWidth: "60px" }}
                        onClick={() => search()}
                      >
                        조회
                      </Btn>
                    </FlexBox>
                  </FlexBox>
                )}
                <WhiteBox margin={"17px 0 0 "} marginMd={"10px 0 0"}>
                  {!isMobile && (
                    <>
                      <FlexBox justify="end">
                        <FlexBox gap="6px" gapMd="13px" justify={"space-evenly"}>
                          <DateInputBox>
                            <DatePicker
                              locale="ko"
                              dateFormatCalendar="yyyy년 MM월"
                              dateFormat="yyyy-MM-dd"
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              startDate={startDate}
                              endDate={endDate}
                              maxDate={endDate}
                            />
                          </DateInputBox>
                          <DateInputBox>
                            <DatePicker
                              locale="ko"
                              dateFormatCalendar="yyyy년 MM월"
                              dateFormat="yyyy-MM-dd"
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              startDate={startDate}
                              endDate={endDate}
                              minDate={startDate}
                            />
                          </DateInputBox>
                          <Btn
                            color={"#fff"}
                            background={"#FF4A4A"}
                            radius={"6px"}
                            size={"14px"}
                            weight={"700"}
                            padding={"9px 19px"}
                            border="unset"
                            margin="2px 0 0"
                            lineHeight={"22px"}
                            width={"75px"}
                            onClick={() => search()}
                          >
                            조회
                          </Btn>
                        </FlexBox>
                      </FlexBox>
                      <FlexBox justify="end" margin="20px 0 30px">
                        <Btn
                          border={"1px solid #666666"}
                          hoverBorder={"1px solid #C7C7C7"}
                          hoverBackground={"#C7C7C7"}
                          color={"#666666"}
                          hoverColor={"#FFFFFF"}
                          weight={"500"}
                          hoverWeight={"700"}
                          padding={"9px 19px"}
                          size={"14px"}
                          radius={"6px"}
                          lineHeight={"22px"}
                          width={"92px"}
                          onClick={onExcelDown}
                        >
                          엑셀 다운
                        </Btn>
                      </FlexBox>
                    </>
                  )}
                  <TableWrap>
                    <table style={{ width: "100%", minWidth: "1200px" }}>
                      <colgroup colgroup>
                        <col style={{ width: "12.1%" }} />
                        <col style={{ width: "12.1%" }} />
                        <col style={{ width: "12.1%" }} />
                        <col style={{ width: "11.1%" }} />
                        <col style={{ width: "11.1%" }} />
                        <col style={{ width: "11.1%" }} />
                        <col style={{ width: "11.1%" }} />
                        <col style={{ width: "11.1%" }} />
                        <col style={{ width: "11.1%" }} />
                        <col style={{ width: "6.7%" }} />
                        <col style={{ width: "11.1%" }} />
                        <col style={{ width: "11.1%" }} />
                        <col style={{ width: "4%" }} />
                        <col style={{ width: "4%" }} />
                        {/* <col style={{ width: "6%" }} /> */}
                      </colgroup>
                      <thead>
                        <tr>
                          <Th rowSpan={2} style={{ borderBottom: "1px solid #666" }}>
                            일시
                          </Th>
                          <Th rowSpan={2} style={{ borderBottom: "1px solid #666" }}>
                            총매출
                          </Th>
                          <Th rowSpan={2} style={{ borderBottom: "1px solid #666" }}>
                            자점매입
                            <br />
                            총매출
                          </Th>
                          <Th
                            colSpan={6}
                            padding={"6px"}
                            style={{
                              width: "420px",
                              borderBottom: "1px solid #c7c7c7",
                            }}
                          >
                            결제 수단별
                          </Th>
                          <Th rowSpan={2} style={{ borderBottom: "1px solid #666" }}>
                            총<br />
                            할인
                          </Th>
                          <Th rowSpan={2} style={{ borderBottom: "1px solid #666" }}>
                            총 매입가
                          </Th>
                          <Th rowSpan={2} style={{ borderBottom: "1px solid #666" }}>
                            총 순이익
                          </Th>
                          <Th
                            colSpan={2}
                            padding={"6px"}
                            style={{
                              // width: "300px",
                              borderBottom: "1px solid #c7c7c7",
                            }}
                          >
                            결제 건수
                          </Th>
                        </tr>
                        <tr>
                          <Th
                            bgColor={"rgba(255, 116, 64, 0.1)"}
                            padding={"6px"}
                            style={{ borderBottom: "1px solid #666" }}
                          >
                            현금
                          </Th>
                          <Th
                            bgColor={"rgba(255, 116, 64, 0.1)"}
                            padding={"6px"}
                            style={{ borderBottom: "1px solid #666" }}
                          >
                            카드
                          </Th>
                          <Th
                            bgColor={"rgba(255, 116, 64, 0.1)"}
                            padding={"6px"}
                            style={{ borderBottom: "1px solid #666" }}
                          >
                            포인트
                          </Th>
                          <Th
                            bgColor={"rgba(255, 116, 64, 0.1)"}
                            padding={"6px"}
                            style={{ borderBottom: "1px solid #666" }}
                          >
                            카카오페이
                          </Th>
                          <Th
                            bgColor={"rgba(255, 116, 64, 0.1)"}
                            padding={"6px"}
                            style={{ borderBottom: "1px solid #666" }}
                          >
                            제로페이
                          </Th>
                          <Th
                            bgColor={"rgba(255, 116, 64, 0.1)"}
                            padding={"6px"}
                            style={{ borderBottom: "1px solid #666" }}
                          >
                            네이버페이
                          </Th>
                          <Th
                            bgColor={"rgba(255, 116, 64, 0.1)"}
                            padding={"6px"}
                            style={{ borderBottom: "1px solid #666" }}
                          >
                            승인
                          </Th>
                          <Th
                            bgColor={"rgba(255, 116, 64, 0.1)"}
                            padding={"6px"}
                            style={{ borderBottom: "1px solid #666" }}
                          >
                            취소
                          </Th>
                          {/* <Th
                            bgColor={"rgba(255, 116, 64, 0.1)"}
                            padding={"6px"}
                            style={{ borderBottom: "1px solid #666" }}
                          >
                            매출
                          </Th> */}
                        </tr>

                        {totalData[0]?.cntAll > 0
                          ? totalData?.map((list, key) => (
                              <tr key={key}>
                                <Td color={"#1e1e1e"} bgColor={"rgba(255, 116, 64, 0.1)"}>
                                  총합계
                                </Td>
                                <Td color={"#1e1e1e"} bgColor={"rgba(255, 116, 64, 0.1)"}>
                                  {AddComma(list.total)}원
                                </Td>
                                <Td color={"#1e1e1e"} bgColor={"rgba(255, 116, 64, 0.1)"}>
                                  {AddComma(list.self)}원
                                </Td>
                                <Td color={"#1e1e1e"} bgColor={"rgba(255, 116, 64, 0.1)"}>
                                  {AddComma(list.cash)}원(
                                  {list.cash ? Math.round((list.cash / list.total) * 100) : 0}% )
                                </Td>
                                <Td color={"#1e1e1e"} bgColor={"rgba(255, 116, 64, 0.1)"}>
                                  {AddComma(list.card)}원(
                                  {list.card ? Math.round((list.card / list.total) * 100) : 0}%)
                                </Td>
                                <Td color={"#1e1e1e"} bgColor={"rgba(255, 116, 64, 0.1)"}>
                                  {AddComma(list.point)}원(
                                  {list.point ? Math.round((list.point / list.total) * 100) : 0}%)
                                </Td>
                                <Td color={"#1e1e1e"} bgColor={"rgba(255, 116, 64, 0.1)"}>
                                  {AddComma(list.kakao)}원(
                                  {list.kakao ? Math.round((list.kakao / list.total) * 100) : 0}%)
                                </Td>
                                <Td color={"#1e1e1e"} bgColor={"rgba(255, 116, 64, 0.1)"}>
                                  {AddComma(list.zero)}원(
                                  {list.zero ? Math.round((list.zero / list.total) * 100) : 0}%)
                                </Td>
                                <Td color={"#1e1e1e"} bgColor={"rgba(255, 116, 64, 0.1)"}>
                                  {AddComma(list.naver)}원(
                                  {list.naver ? Math.round((list.naver / list.total) * 100) : 0}%)
                                </Td>
                                <Td color={"#1e1e1e"} bgColor={"rgba(255, 116, 64, 0.1)"}>
                                  {AddComma(list.total_discount_price)}원
                                </Td>
                                <Td color={"#1e1e1e"} bgColor={"rgba(255, 116, 64, 0.1)"}>
                                  {AddComma(list.total_purchase_price)}원
                                </Td>
                                <Td color={"#1e1e1e"} bgColor={"rgba(255, 116, 64, 0.1)"}>
                                  {AddComma(list.total - list.total_discount_price - list.total_purchase_price)}원
                                </Td>
                                <Td color={"#1e1e1e"} bgColor={"rgba(255, 116, 64, 0.1)"}>
                                  {list.approval}
                                </Td>
                                <Td color={"#1e1e1e"} bgColor={"rgba(255, 116, 64, 0.1)"}>
                                  {list.cancel}
                                </Td>
                                {/* <Td color={"#1e1e1e"} bgColor={"rgba(255, 116, 64, 0.1)"}>
                                  {list.sale}
                                </Td> */}
                              </tr>
                            ))
                          : ""}
                      </thead>
                      <tbody>
                        {data?.length > 0 ? (
                          data?.map((list, key) =>
                            list.createdat !== "total_sum" ? (
                              <tr key={key}>
                                <Td>{list.createdat}</Td>
                                <Td color={"#DD1212"}>{AddComma(list.total)}원</Td>
                                <Td color={"#DD1212"}>{AddComma(list.self)}원</Td>
                                <Td>
                                  {AddComma(list.cash)}원(
                                  {list.cash ? Math.round((list.cash / list.total) * 100) : 0}% )
                                </Td>
                                <Td>
                                  {AddComma(list.card)}원(
                                  {list.card ? Math.round((list.card / list.total) * 100) : 0}%)
                                </Td>
                                <Td>
                                  {AddComma(list.point)}원(
                                  {list.point ? Math.round((list.point / list.total) * 100) : 0}%)
                                </Td>
                                <Td>
                                  {AddComma(list.kakao)}원(
                                  {list.kakao ? Math.round((list.kakao / list.total) * 100) : 0}%)
                                </Td>
                                <Td>
                                  {AddComma(list.zero)}원(
                                  {list.zero ? Math.round((list.zero / list.total) * 100) : 0}%)
                                </Td>
                                <Td>
                                  {AddComma(list.naver)}원(
                                  {list.naver ? Math.round((list.naver / list.total) * 100) : 0}%)
                                </Td>

                                <Td>{AddComma(list.total_discount_price)}원</Td>
                                <Td>{AddComma(list.total_purchase_price)}원</Td>
                                <Td color={"#DD1212"}>
                                  {AddComma(list.total - list.total_discount_price - list.total_purchase_price)}원
                                </Td>
                                <Td>{list.approval}</Td>
                                <Td>{list.cancel}</Td>
                                {/* <Td>{list.sale}</Td> */}
                              </tr>
                            ) : (
                              ""
                            ),
                          )
                        ) : (
                          <tr>
                            <td
                              colSpan={14}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  {data?.length > 0 ? (
                    <PagerWrap margin={"40px 0 0 0"}>
                      <Paging totalPage={totalPage} limit={pageSize} page={currentPage} setPage={setCurrentPage} />
                    </PagerWrap>
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

export const TableWrap = styled.div`
  overflow-y: auto;
  table {
    min-width: 1300px;
  }
`;

export default SalesView;
