import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";

import { Text, Btn, FlexBox, TextInput, TextInputArea } from "../../../Assets/css/commonVer2";
import close from "../../../Assets/images/close.png";

// 쇼핑몰 > 반품관리 > 상세_모바일

export default function ShopInputTakeBackModal(props) {
  const {
    modal, // 필수
    setModal, // 필수
    clickedReview, // 반품인정수량, 거절사유 state
    setClickedReview, // 반품인정수량, 거절사유 변경 함수
    clickedQuantity, // 선택 상품의 반품신청수량
    onSubmit = () => {}, // 필수
  } = props;

  const scrollRef = useRef();

  const [tempAllowPieces, setTempAllowPieces] = useState(clickedReview.shoppingmall_take_back_allow_quantity);
  const [tempReason, setTempReason] = useState(clickedReview.shoppingmall_take_back_not_approval_reason);

  const onClose = () => {
    setModal(false);
  };

  const check = () => {
    // 반품인정수량이 반품신청수량보다 많은 경우
    if (
      tempAllowPieces > clickedQuantity
    ) {
      alert("반품인정수량은 반품신청수량보다 작거나 같아야합니다.");
    } else if (
      tempAllowPieces < 0
    ) {
      alert("반품인정수량은 0 또는 양수입니다.");
    } else if (
      // NaN 인 경우
      isNaN(tempAllowPieces)
    ) {
      alert("반품인정수량을 입력해주세요.");
    } else if (
      tempAllowPieces < clickedQuantity &&
      tempReason === ""
    ) {
      alert("반려사유를 입력해주세요.");
    } else {
      const updatedClickedReview = {
        ...clickedReview, // 기존 clickedReview 속성들 복사
        shoppingmall_take_back_allow_quantity: parseInt(tempAllowPieces), // review_status 업데이트
        shoppingmall_take_back_not_approval_reason: tempReason, // shoppingmall_take_back_not_approval_reason 업데이트
      };
      setClickedReview(updatedClickedReview);
      onSubmit(updatedClickedReview);
      setModal(false);
    }
  };

  useEffect(() => {
    setTempAllowPieces(clickedReview.shoppingmall_take_back_allow_quantity);
    setTempReason(clickedReview.shoppingmall_take_back_not_approval_reason);
  }, [clickedReview]);

  return (
    <>
      {modal ? (
        <ModalBg>
          <ModalConts>
            <CancelBtn onClick={onClose}>
              <img src={close} />
            </CancelBtn>
            <Text
              color={"#1E1E1E"}
              size={"16px"}
              weight={"500"}
              margin={"0 0 10px"}
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              반품 인정 수량 수정
            </Text>
            <Text
              color={"#1E1E1E"}
              size={"14px"}
              weight={"400"}
              margin={"0 0 10px"}
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              일부라도 반려하는 경우 사유를 입력하셔야합니다.
            </Text>
            <TextInput
              type={"text"}
              name={"allow_pieces"}
              value={tempAllowPieces}
              onChange={(e) => {
                setTempAllowPieces(e.target.value);
              }}
              margin={"0 0 10px"}
              placeholder="반품인정수량을 입력해주세요."
            />
            <ScrollBox ref={scrollRef} style={{ padding: "0" }}>
              <TextInputArea
                value={tempReason}
                minHeight={"100px"}
                type={"text"}
                placeholder={"반품반려를 하시는 경우 사유를 입력해주세요."}
                onChange={(e) => {
                  setTempReason(e.target.value);
                }}
              />
            </ScrollBox>

            <FlexBox margin={"12px 0 0"} marginMd={"17px 0 0"}>
              <Btn
                background={"#f2f2f2"}
                color={"#666"}
                border={"none"}
                weight={"500"}
                margin={"0 10px 0 0"}
                size={"12px"}
                sizeMd={"14px"}
                padding={"9px 19px"}
                paddingMd={"9px 19px"}
                onClick={onClose}
              >
                취소
              </Btn>
              <Btn
                background={"#FF4A4A"}
                color={"#fff"}
                border={"none"}
                weight={"700"}
                size={"12px"}
                sizeMd={"14px"}
                padding={"9px 19px"}
                paddingMd={"9px 19px"}
                onClick={check}
              >
                저장
              </Btn>
            </FlexBox>
          </ModalConts>
        </ModalBg>
      ) : (
        ""
      )}
    </>
  );
}

const ModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

const ModalConts = styled.div`
  background-color: #fff;
  max-width: 360px;
  width: 100%;
  padding: 44px 30px 28px;
  border-radius: 8px;
  position: relative;

  @media all and (max-width: 1024px) {
    max-width: 280px;
    padding: 40px 20px 30px;
  }
`;

const ScrollBox = styled.div`
  max-height: 420px;
  overflow: auto;
  padding: 0 10px 0 10px;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: silver;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #6c6c6c;
  }
`;

const CancelBtn = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;

  @media all and (max-width: 1024px) {
    top: 12px;
    right: 12px;
  }
`;
