

let timer;  

/* code from sub_outsourcing */

// 지연 검색을 위한 메서드
export const delayCall = (__callback) => {
 
 if (timer) {
   clearTimeout(timer);
 }
 timer = setTimeout(function() {
  __callback()
 }, 200);
} 