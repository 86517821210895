/* code from sub_outsourcing */

export const searchToObject = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const paramsObject = {};

    for (let [key, value] of searchParams.entries()) {
        paramsObject[key] = value;
    }
    return paramsObject
}