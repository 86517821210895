import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Pie, Doughnut } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { FlexBox, Text } from "../../Assets/css/common";

const ChartPie = (props) => {
  const [pic, setPic] = useState(null);
  const [slotPie, setSlotPie] = useState(null);

  // 판매상품
  var sliderTemp = pic?.map(function (item) {
    return item.percent;
  });

  var sliderName = pic?.map(function (item) {
    return item.name;
  });

  const colors1 = [
    "#FF4A4A",
    "rgba(255, 74, 74, 0.7)",
    "rgba(255, 74, 74, 0.5)",
    "rgba(255, 74, 74, 0.3)",
    "rgba(255, 74, 74, 0.15)",
    "#FF7440",
    "rgba(255, 116, 64, 0.7)",
    "rgba(255, 116, 64, 0.5)",
    "rgba(255, 116, 64, 0.3)",
    "rgba(255, 116, 64, 0.15)",
  ];

  const COLORS1 = sliderName?.map((value, index) => {
    return { color: colors1[index], value: value };
  });

  const data1 = {
    labels: sliderName,
    datasets: [
      {
        data: sliderTemp,
        backgroundColor: [
          "#FF4A4A",
          "rgba(255, 74, 74, 0.7)",
          "rgba(255, 74, 74, 0.5)",
          "rgba(255, 74, 74, 0.3)",
          "rgba(255, 74, 74, 0.15)",
          "#FF7440",
          "rgba(255, 116, 64, 0.7)",
          "rgba(255, 116, 64, 0.5)",
          "rgba(255, 116, 64, 0.3)",
          "rgba(255, 116, 64, 0.15)",
        ],
        borderColor: [
          "#FF4A4A",
          "rgba(255, 74, 74, 0.7)",
          "rgba(255, 74, 74, 0.5)",
          "rgba(255, 74, 74, 0.3)",
          "rgba(255, 74, 74, 0.15)",
          "#FF7440",
          "rgba(255, 116, 64, 0.7)",
          "rgba(255, 116, 64, 0.5)",
          "rgba(255, 116, 64, 0.3)",
          "rgba(255, 116, 64, 0.15)",
        ],
      },
    ],
  };

  // 본사/자점매입
  var sliderTemp2 = slotPie?.map(function (item) {
    return item.percentage;
  });

  const sliderName2 = ["본사", "자점매입"];

  const colors2 = [
    "#FF4A4A", // 본사
    "rgba(255, 116, 64, 0.1)", // 자점매입
  ];

  const COLORS2 = sliderName2?.map((value, index) => {
    return { color: colors2[index], value: value };
  });

  const data2 = {
    labels: ["본사", "자점매입"],
    datasets: [
      {
        data: sliderTemp2,
        backgroundColor: ["#FF4A4A", "rgba(255, 116, 64, 0.1)"],
        borderColor: ["#FF4A4A", "rgba(255, 116, 64, 0.1)"],
      },
    ],
  };

  // 도넛 그래프 options
  const doughnutOptions = {
    cutout: "30%",
    spanGaps: true,
    interaction: {
      mode: "index",
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#fff",
        titleColor: "#000",
        titleFont: { weight: "400", size: "10", family: "Montserrat" },
        bodyColor: "#FF4A4A",
        bodyFont: { weight: "bold", family: "Montserrat" },
        borderRadius: 10,
        usePointStyle: true,
        filter: (item) => item.parsed.y !== null,
        callbacks: {
          title: (context) => context[0].label,
          label: (context) => {
            const value = context.parsed;
            return value + "%";
          },
        },
      },
    },
  };

  // 파이 그래프 options
  const options = {
    spanGaps: true,
    interaction: {
      mode: "index",
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#fff",
        titleColor: "#000",
        titleFont: { weight: "400", size: "10", family: "Montserrat" },
        bodyColor: "#FF4A4A",
        bodyFont: { weight: "bold", family: "Montserrat" },
        usePointStyle: true,
        filter: (item) => item.parsed.y !== null,
        callbacks: {
          title: (context) => context[0].label,
          label: (context) => {
            const value = context.parsed;
            return value + "%";
          },
        },
      },
    },
  };

  useEffect(() => {
    setPic(props.pic);
    setSlotPie(props.slotPie);
  }, [props.pic, props.slotPie]);

  return (
    <>
      {slotPie ? (
        <SlotPieBox>
          <FlexBox direction={"column"}>
            <PieContainer>
              <Doughnut type="doughnut" data={data1} options={doughnutOptions} />
            </PieContainer>
            <CategoryContainer>
              {COLORS1 &&
                COLORS1.map((entry, index) => (
                  <FlexBox marginMd={"0 0 10px"} margin={"0 0 10px 10px"} justify={"start"} width={"20%"} key={index}>
                    <ColorBox bgColor={entry.color}></ColorBox>
                    <Text size={"12px"} weight={"400"} color={"#666"} style={{ wordBreak: "keep-all" }}>
                      {entry.value}
                    </Text>
                  </FlexBox>
                ))}
            </CategoryContainer>
          </FlexBox>
          <FlexBox direction={"column"} flex={1} margin={"30px 0 0"}>
            <PieContainer>
              <Pie type="pie" data={data2} options={options} />
            </PieContainer>
            <CategoryContainer style={{ padding: "13px 0" }}>
              {COLORS2 &&
                COLORS2.map((entry, index) => (
                  <FlexBox marginMd={"18px 10px"} margin={"0 0 10px 10px"} justify={"start"} key={index}>
                    <ColorBox bgColor={entry.color}></ColorBox>
                    <Text size={"12px"} weight={"400"} color={"#666"} style={{ wordBreak: "keep-all" }}>
                      {entry.value}
                    </Text>
                  </FlexBox>
                ))}
            </CategoryContainer>
          </FlexBox>
        </SlotPieBox>
      ) : (
        <FlexBox direction={"column"}>
          <DoughnutBox>
            <Doughnut type="Doughnut" data={data1} options={doughnutOptions} />
          </DoughnutBox>
          <CategoryContainer>
            {COLORS1 &&
              COLORS1.map((entry, index) => (
                <CategoryBox key={index}>
                  <ColorBox bgColor={entry.color}></ColorBox>
                  <Text size={"12px"} sizeMd={"16px"} weight={"400"} color={"#666"} style={{ wordBreak: "keep-all" }}>
                    {entry.value}
                  </Text>
                </CategoryBox>
              ))}
          </CategoryContainer>
        </FlexBox>
      )}
    </>
  );
};

export default ChartPie;

const SlotPieBox = styled.div`
  display: flex;

  @media all and (max-width: 1800px) {
    display: unset;
  }
`;

const PieContainer = styled.div`
  @media all and (max-width: 1024px) {
    max-width: 60%;
  }
`;

const CategoryContainer = styled.div`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;

  @media all and (max-width: 1024px) {
    width: 80%;
  }
`;

const ColorBox = styled.div`
  background-color: ${(props) => props.bgColor || ""};
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
`;

const DoughnutBox = styled.div`
  @media all and (max-width: 340px) {
    width: 200px;
  }
`
const CategoryBox = styled(FlexBox)`
  justify-content: start;
  width: 50%;
  margin: 4px 0;
`