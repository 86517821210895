import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

import Header from "../../Components/Header/HeaderVer2";
import {
  Btn,
  Container,
  FlexBox,
  PagerWrap,
  Wrapper,
  Th,
  Td,
  TableWrap,
  CheckLabel,
  CheckIcon,
  ContentContainer,
  Title,
  WhiteBox,
  SearchInput,
} from "../../Assets/css/commonVer2";

import API from "../../Utiles/API";
import Paging from "../../Components/Paging/Paging";
import { Xlsx_download, str } from "../../Utiles/Custom";
import Authority from "../../Components/Modal/Authority";
import { AddComma, handleKeyDown } from "../../Utiles/Custom";
import ProductSearchInputVer2 from "../../Components/SearchInput/ProductSearchInputVer2";
import HandleBack from "../../Components/HandleBack";
import Loading from "../../Components/Loading";
import { UNIT_10_TO_50_LIST, SEARCH_TYPE_LIST, PRODUCT_SALE_STATUS_LIST } from "../../Utiles/ListData";
import BasicSelect from "../../Components/Select/BasicSelect";
import Cate1Select from "../../Components/Select/Cate1Select";
import Cate2Select from "../../Components/Select/Cate2Select";
import InfoModal from "../../Components/Modal/InfoModal";
import SelectCate2Modal from "../../Components/Modal/SelectCate2Modal";

// 상품마스터 > 전체상품관리

function ProductAll() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const navigate = useNavigate();
  const { handlePopstate } = HandleBack();

  const [checkItems, setCheckItems] = useState([]);
  const [textModal, setTextModal] = useState(false); // 텍스트 모달 창 표시 여부 상태
  const [textMsg, setTextMsg] = useState("");
  const [categoryModal, setCategoryModal] = useState(false); // 카테고리 일괄수정 모달 표시 여부
  const [saveModal, setSaveModal] = useState(false);
  const [selectValue, setSelectValue] = useState("");
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  // const [cate2Data, setCate2Data] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchList, setShowSearchList] = useState(false);

  const parsedProductPageState = sessionStorage.getItem("productPageState")
    ? JSON.parse(sessionStorage.getItem("productPageState"))
    : {};
  const [limit, setLimit] = useState(
    parsedProductPageState ? (parsedProductPageState?.limit ? parsedProductPageState?.limit : 10) : 10,
  );
  const [currentPage, setCurrentPage] = useState(
    parsedProductPageState ? (parsedProductPageState?.currentPage ? parsedProductPageState?.currentPage : 1) : 1,
  );
  const start = (currentPage - 1) * limit;
  const [cate1Value, setCate1Value] = useState(
    parsedProductPageState ? (parsedProductPageState?.cate1Value ? parsedProductPageState?.cate1Value : "") : "",
  );
  const [cate2Value, setCate2Value] = useState(
    parsedProductPageState ? (parsedProductPageState?.cate2Value ? parsedProductPageState?.cate2Value : "") : "",
  );
  const [inputValue, setInputValue] = useState(
    parsedProductPageState ? (parsedProductPageState?.inputValue ? parsedProductPageState?.inputValue : "") : "",
  );
  const [filter, setFilter] = useState(
    parsedProductPageState
      ? parsedProductPageState?.filter
        ? parsedProductPageState?.filter
        : "barcode_num"
      : "barcode_num",
  );

  const [status, setStatus] = useState(
    parsedProductPageState ? (parsedProductPageState?.status ? parsedProductPageState?.status : "") : "",
  );

  // 상품 수정을 갈 때 sessionStorage에 저장할 객체
  let productPageState = {
    currentPage,
    limit,
    cate1Value,
    cate2Value,
    inputValue,
    filter,
    status,
  };

  // 아이템 목록 불러오기
  const getProductList = async () => {
    let api_link = `/admin/product?curPage=${currentPage}&pageSize=${limit}`;
    if (cate2Value) api_link += `&filterVal=${cate2Value}`;
    if (inputValue) api_link += `&filterVal2=${inputValue}`;
    if (status) api_link += `&status=${status}`;
    api_link += `&productSearchType=${filter}`;
    API.get(api_link).then(function (res) {
      if (res.data.status) {
        setData(res.data.result);
        setTotalCount(res.data?.cntAll);
      }
    });
  };

  // 상품 2차분류 일괄 수정
  const handleModalSubmit = useCallback(async () => {
    setLoading(true);
    await API.put(`/admin/product/bulk`, {
      product_id: checkItems,
      column: "category2nd_id",
      value: selectValue,
    }).then((res) => {
      setLoading(false);
      setCategoryModal(false);
      setSaveModal(false);
      if (res.data.status) {
        setTextMsg("수정되었습니다.");
        setTextModal(true);
        setCheckItems([]);
        getProductList();
      } else {
        setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    });
  });

  const onExcelDown = async () => {
    if (data?.length > 0) {
      let api_link = `/admin/product?curPage=1&pageSize=99999999`;
      if (cate2Value) api_link += `&filterVal=${cate2Value}`;
      if (inputValue) api_link += `&filterVal2=${inputValue}`;
      api_link += `&productSearchType=${filter}`;
      API.get(api_link).then(function (res) {
        if (res.data.status && res.data.result.length > 0) {
          var xlsxTemp = res.data.result.map(function (item) {
            const state = item.sale_status === "sale" ? "판매" : "missing" ? "결품" : "단종";
            return {
              분류: item?.["category2nd.name"],
              바코드번호: item.barcode_num,
              상품명: item.product_name,
              공급업체: item.supplier,
              출하가: item.shipping_price,
              매입가: item.purchase_price,
              소비자가: item.consumer_price,
              판매여부: state,
            };
          });
          Xlsx_download(xlsxTemp, `전체상품 리스트`);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      });
    } else {
      setTextMsg("다운받을 데이터가 없습니다.");
      setTextModal(true);
    }
  };

  const onSearch = () => {
    if (cate1Value) {
      setSearchResults([]);
      setShowSearchList(false);
      setCurrentPage(1);
      getProductList();
    } else {
      setSearchResults([]);
      setShowSearchList(false);
      setCurrentPage(1);
      getProductList();
    }
  };

  // 아이템 체크
  const onChangeChx = (checked, id) => {
    if (checked) {
      setCheckItems((prev) => [...prev, id]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  // 아이템 전체 체크
  const onChangeAllChk = (checked) => {
    if (checked) {
      const chkArr = [];
      for (let i = 0; i < data.length; i++) {
        chkArr.push(data[i].idx);
      }
      setCheckItems(chkArr);
    } else {
      setCheckItems([]);
    }
  };

  // 아이템 수정 클릭
  const onClickEditProduct = () => {
    if (checkItems.length === 0) {
      setTextMsg("수정할 상품을 선택해주세요.");
      setTextModal(true);
      return;
    }
    if (checkItems.length > 1) {
      setTextMsg("하나만 선택해주세요.");
      setTextModal(true);
      return;
    }
    if (checkItems.length === 1) {
      const foundItem = data.find((item) => item.idx === checkItems[0]);
      navigate("/product/edit", {
        state: { idx: foundItem.idx },
      });
      let jsonString = JSON.stringify(productPageState);
      sessionStorage.setItem("productPageState", jsonString);
    }
  };

  // 상품 분류 클릭
  const handleEditCategory = () => {
    if (checkItems.length === 0) {
      setTextMsg("상품분류를 변경할 상품을 선택해주세요.");
      setTextModal(true);
    } else {
      setCategoryModal(true);
    }
  };

  useEffect(() => {
    // 현재 페이지에 도착하면 무조건 한 번 초기화
    sessionStorage.removeItem("productPageState");
  }, []);

  useEffect(() => {
    setTotalPage(Math.ceil(totalCount / limit));
  }, [totalCount, limit]);

  useEffect(() => {
    getProductList();
    setCheckItems([]);
  }, [totalPage, currentPage, limit]);

  useEffect(() => {
    setInputValue("");
  }, [filter]);

  useEffect(() => {
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {loading ? <Loading /> : null}
          {isMobile && <Header title={"본사상품 관리"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    본사상품 관리 📋
                  </Title>
                )}
                <WhiteBox margin={"0 0"}>
                  <FlexBox wrap={"wrap"} gap={"10px"} justify={"end"}>
                    {/* <Cate1Select cate1Value={cate1Value} setCate1Value={setCate1Value} setCate2Value={setCate2Value} /> */}

                    <Cate2Select
                      idx={1}
                      cate2Value={cate2Value}
                      setCate2Value={setCate2Value}
                      placeholder={"상품 분류"}
                    />
                    <BasicSelect
                      selectedValue={status}
                      setSelectedValue={setStatus}
                      data={PRODUCT_SALE_STATUS_LIST}
                      canSelectAll={false}
                      placeholder="판매상태 전체"
                    />
                    <BasicSelect
                      selectedValue={filter}
                      setSelectedValue={setFilter}
                      data={SEARCH_TYPE_LIST}
                      canSelectAll={false}
                    />
                    <FlexBox justify={"end"} width={"100%"} widthMd={"auto"} wrap={"wrap"} gap={"10px"}>
                      {filter == "barcode_num" ? (
                        <SearchInput
                          borderBottom={"1px solid #666"}
                          borderBottomColor={"#ff4a4a"}
                          transition={"border-color 0.3s ease-in-out"}
                          placeholder={"바코드 번호를 입력해주세요."}
                          value={inputValue}
                          onChange={(e) => {
                            setInputValue(e.target.value);
                          }}
                          onKeyDown={(event) => handleKeyDown(event, onSearch)}
                        />
                      ) : (
                        <SelectBox>
                          <ProductSearchInputVer2
                            showSearchList={showSearchList}
                            setShowSearchList={setShowSearchList}
                            searchResults={searchResults}
                            setSearchResults={setSearchResults}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            onSearch={onSearch}
                          />
                        </SelectBox>
                      )}
                      <Btn
                        color={"#fff"}
                        background={"#FF4A4A"}
                        size={"14px"}
                        weight={"700"}
                        width={"100%"}
                        widthMd={"unset"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        onClick={onSearch}
                        style={{ flexShrink: 0 }}
                      >
                        검 색
                      </Btn>
                    </FlexBox>
                  </FlexBox>
                  <FlexBox
                    justify={"space-between"}
                    wrap={"wrap"}
                    gap={"10px"}
                    margin={"20px 0 10px"}
                    marginMd={"20px 0 30px"}
                  >
                    {!isMobile && (
                      <BasicSelect
                        width={"130px"}
                        data={UNIT_10_TO_50_LIST}
                        selectedValue={limit}
                        setSelectedValue={setLimit}
                        canSelectAll={false}
                        setCurrentPage={setCurrentPage}
                      />
                    )}
                    <FlexBox
                      gap={"10px"}
                      justify={"flex-end"}
                      width={"100%"}
                      widthMd={"auto"}
                      style={{ marginLeft: "auto" }}
                    >
                      <Btn
                        size={"14px"}
                        padding={"8px 10px"}
                        paddingMd={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        onClick={() => {
                          navigate("/product/write");
                          let jsonString = JSON.stringify(productPageState);
                          sessionStorage.setItem("productPageState", jsonString);
                        }}
                      >
                        등록
                      </Btn>
                      <Btn
                        size={"14px"}
                        padding={"8px 10px"}
                        paddingMd={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        onClick={onClickEditProduct}
                      >
                        수정
                      </Btn>
                      <Btn
                        size={"14px"}
                        padding={"8px 10px"}
                        paddingMd={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        onClick={handleEditCategory}
                      >
                        상품분류 변경
                      </Btn>

                      {!isMobile && (
                        <>
                          <Btn
                            size={"14px"}
                            padding={"8px 10px"}
                            paddingMd={"8px 18px"}
                            lineHeight={"22px"}
                            hoverWeight={"700"}
                            onClick={() => {
                              navigate("/product/bulk/write");
                            }}
                          >
                            대량 등록
                          </Btn>
                          <Btn
                            size={"14px"}
                            color={"#666"}
                            border={"1px solid #666"}
                            padding={"8px 18px"}
                            lineHeight={"22px"}
                            hoverWeight={"700"}
                            hoverColor={"#fff"}
                            hoverBackground={"#C7C7C7"}
                            hoverBorder={"1px solid transparent"}
                            onClick={onExcelDown}
                          >
                            엑셀 다운
                          </Btn>
                        </>
                      )}
                    </FlexBox>
                  </FlexBox>
                  <TableWrap>
                    <table style={{ width: "100%", minWidth: "1024px" }}>
                      <colgroup>
                        <col style={{ width: "4%" }} />
                        <col style={{ width: "6%" }} />
                        <col style={{ width: "16%" }} />
                        <col style={{ width: "10%" }} />
                        <col />
                        <col style={{ width: "9%" }} />
                        <col style={{ width: "9%" }} />
                        <col style={{ width: "9%" }} />
                        <col style={{ width: "12%" }} />
                        <col style={{ width: "8%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>
                            <CheckLabel htmlFor="chkAll" style={{ justifyContent: "center" }}>
                              <input
                                type="checkbox"
                                id="chkAll"
                                onChange={(event) => onChangeAllChk(event.target.checked)}
                                checked={checkItems.length === data.length ? true : false}
                              />
                              <CheckIcon>
                                <svg viewBox="0 0 24 24">
                                  <polyline points="19 7 10 17 5 12" />
                                </svg>
                              </CheckIcon>
                            </CheckLabel>
                          </Th>
                          <Th>NO</Th>
                          <Th>바코드번호</Th>
                          <Th>상품분류</Th>
                          <Th>상품명</Th>
                          <Th>출하가</Th>
                          <Th>매입가</Th>
                          <Th>소비자가</Th>
                          <Th>등록일</Th>
                          <Th>판매여부</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 ? (
                          ""
                        ) : (
                          <tr>
                            <td
                              colSpan={8}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                        {data?.map((e, i) => {
                          const isSales = PRODUCT_SALE_STATUS_LIST.find((item) => item.value === e.sale_status)?.text;

                          return (
                            <tr
                              style={{ cursor: "pointer" }}
                              key={e.idx}
                              onClick={(event) => {
                                const targetElement = event.target;
                                if (targetElement.nodeName !== "INPUT") {
                                  // 체크박스를 클릭한 경우에는 이벤트를 처리하지 않음
                                  const isChecked = document.getElementById(`prod${e.idx}`).checked;
                                  onChangeChx(!isChecked, e.idx);
                                }
                              }}
                            >
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                <CheckLabel htmlFor={`prod${e.idx}`} style={{ justifyContent: "center" }}>
                                  <input
                                    type="checkbox"
                                    name="prod"
                                    id={`prod${e.idx}`}
                                    readOnly
                                    checked={checkItems.includes(e.idx) ? true : false}
                                  />
                                  <CheckIcon>
                                    <svg viewBox="0 0 24 24">
                                      <polyline points="19 7 10 17 5 12" />
                                    </svg>
                                  </CheckIcon>
                                </CheckLabel>
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{start + i + 1}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{e.barcode_num}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{e["category2nd.name"]}</Td>
                              <Td align={"left"} style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {e.product_name}
                              </Td>
                              <Td align={"right"} style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {AddComma(e ? e.shipping_price : 0)}원
                              </Td>
                              <Td align={"right"} style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {AddComma(e ? e.purchase_price : 0)}원
                              </Td>
                              <Td align={"right"} style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {AddComma(e ? e.consumer_price : 0)}원
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {e.createdAt ? str(e.createdAt) : "-"}
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{isSales}</Td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </TableWrap>
                  {data?.length > 0 ? (
                    <Paging totalPage={totalPage} limit={limit} page={currentPage} setPage={setCurrentPage} />
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
              <SelectCate2Modal
                modal={categoryModal}
                setModal={setCategoryModal}
                selectValue={selectValue}
                setSelectValue={setSelectValue}
                saveModal={saveModal}
                setSaveModal={setSaveModal}
                onSubmit={handleModalSubmit}
              />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const SelectBox = styled.div`
  position: relative;

  @media all and (max-width: 1024px) {
    width: 100%;
    flex: unset;
  }
`;

export default ProductAll;
