import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import {
  HeaderContainer,
  ContentContainer,
  Wrapper,
  Container,
  FlexBox,
  Btn,
  Text,
  SquareImg,
  PagerWrap,
  SearchInput,
} from "../../Assets/css/commonVer2";
import { SALE_STATUS_LIST, SEARCH_TYPE_LIST } from "../../Utiles/ListData";
import Header from "../../Components/Header/HeaderVer2";
import img_noimage from "../../Assets/images/no_image.jpg";
import API from "../../Utiles/API";
import Paging from "../../Components/Paging/Paging";
import img_banner from "../../Assets/images/banner_img.jpg";
import Authority from "../../Components/Modal/Authority";
import { AddComma, handleKeyDown } from "../../Utiles/Custom";
import ProductSearchInputVer2 from "../../Components/SearchInput/ProductSearchInputVer2";
import HandleBack from "../../Components/HandleBack";
import Cate2Select from "../../Components/Select/Cate2Select";
import BasicSelect from "../../Components/Select/BasicSelect";
import DataLoading from "../../Components/DataLoading";

// 상품관리 > 전체상품

function Goods() {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const { handlePopstate } = HandleBack();
  const limit = isMobile ? 18 : 20;

  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [banner, setBanner] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchList, setShowSearchList] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  // 상품 상세를 다녀온 경우 저장 해놓은 파라미터 정보
  const parsedGoodsAllPageState = sessionStorage.getItem("goodsAllPageState")
    ? JSON.parse(sessionStorage.getItem("goodsAllPageState"))
    : {};

  const [currentPage, setCurrentPage] = useState(
    parsedGoodsAllPageState ? (parsedGoodsAllPageState?.currentPage ? parsedGoodsAllPageState?.currentPage : 1) : 1,
  );
  const [saleStatus, setSaleStatus] = useState(
    parsedGoodsAllPageState
      ? parsedGoodsAllPageState?.saleStatus
        ? parsedGoodsAllPageState?.saleStatus
        : "on_sale"
      : "on_sale",
  );
  const [filter, setFilter] = useState(
    parsedGoodsAllPageState
      ? parsedGoodsAllPageState?.filter
        ? parsedGoodsAllPageState?.filter
        : "product_name"
      : "product_name",
  );
  const [inputValue, setInputValue] = useState(
    parsedGoodsAllPageState ? (parsedGoodsAllPageState?.inputValue ? parsedGoodsAllPageState?.inputValue : "") : "",
  );

  const [cate2Value, setCate2Value] = useState(
    location.state !== null
      ? location.state.idx
      : parsedGoodsAllPageState
        ? parsedGoodsAllPageState?.cate2Value
          ? parsedGoodsAllPageState?.cate2Value
          : ""
        : "",
  );

  // count 판매순 amount 매출순
  const [sort, setSort] = useState(
    parsedGoodsAllPageState ? (parsedGoodsAllPageState?.sort ? parsedGoodsAllPageState?.sort : "count") : "count",
  );

  // 상품 상세를 갈 때 sessionStorage에 저장할 객체
  let goodsAllPageState = {
    currentPage,
    saleStatus,
    filter,
    cate2Value,
    inputValue,
    sort,
  };

  // API
  const getProductList = async () => {
    try {
      setDataLoading(true);
      let api_link = `/storeowner/product-management?page=${currentPage}&unit=${limit}&block=10&order_type=${sort}`;
      if (saleStatus === "on_sale") {
        api_link += `&is_sale=true`;
      }
      if (cate2Value) {
        api_link += `&category2nd_id=${cate2Value}`;
      }
      if (inputValue) {
        api_link += `&filter_type=${filter}&filter_value=${inputValue}`;
      }

      await API.get(api_link).then((res) => {
        if (res.data.status) {
          setDataLoading(false);
          setTotalCount(res.data.results?.product_cnt);
          setData(res.data.results?.products);
        } else {
          setDataLoading(false);
        }
      });
    } catch (error) {
      setDataLoading(false);
    }
  };

  const notice = async () => {
    await API.get(`/admin/notice-event`).then((res) => {
      if (res.data.status) {
        let bannerData = [];
        for (var i = 0; i < res.data.result.image_path.length; i++) {
          if (res.data.result.image_path[i]?.includes(isMobile ? "type3" : "type2")) {
            bannerData = {
              idx: res.data.result.idx,
              image_path_1: res.data.result.image_path[i],
            };
          }
        }
        setBanner(bannerData);
      }
    });
  };

  const onSearch = () => {
    setSearchResults([]);
    setShowSearchList(false);
    if (currentPage == 1) {
      getProductList();
    } else {
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    sessionStorage.removeItem("goodsAllPageState"); // 현재 페이지에 도착하면 무조건 한 번 초기화
    notice();
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    getProductList();
  }, [totalPage, currentPage, sort]);

  useEffect(() => {
    setTotalPage(Math.ceil(totalCount / limit));
  }, [totalCount]);

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          {dataLoading ? <DataLoading /> : null}
          {isMobile && <Header title={"본사 상품"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {/* 웹 - 공지사항 배너 */}
                {!isMobile && (
                  <BannerWrap
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (banner.idx) {
                        let jsonString = JSON.stringify(goodsAllPageState);
                        sessionStorage.setItem("goodsAllPageState", jsonString);
                        navigate("/notice/View", { state: { idx: banner.idx } });
                      }
                    }}
                  >
                    <img
                      src={banner ? `${process.env.REACT_APP_S3_KEY}${banner.image_path_1}` : img_banner}
                      alt="공지사항 배너"
                    />
                  </BannerWrap>
                )}

                <FlexBox justify={"end"} wrap={"wrap"}>
                  <FlexBox gap={"10px"} width={"100%"} widthMd={"unset"}>
                    <BasicSelect
                      widthMd={"31%"}
                      data={SALE_STATUS_LIST}
                      selectedValue={saleStatus}
                      setSelectedValue={setSaleStatus}
                      canSelectAll={false}
                    />
                    <Cate2Select widthMd={"31%"} idx={1} cate2Value={cate2Value} setCate2Value={setCate2Value} />
                    <BasicSelect
                      widthMd={"31%"}
                      data={SEARCH_TYPE_LIST}
                      selectedValue={filter}
                      setSelectedValue={setFilter}
                      canSelectAll={false}
                    />
                  </FlexBox>
                  {!isMobile && (
                    <FlexBox justify={"end"} gap={"10px"} width={"100%"} margin={"10px 0 0"}>
                      {filter === "barcode_num" ? (
                        <SearchInput
                          borderBottom={"1px solid #666666"}
                          borderBottomColor={"#ff4a4a"}
                          transition={"border-color 0.3s ease-in-out"}
                          flex={1}
                          placeholder="바코드번호를 입력해주세요."
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                          onKeyDown={(event) => handleKeyDown(event, onSearch)}
                        />
                      ) : (
                        <ProductSearchInputVer2
                          showSearchList={showSearchList}
                          setShowSearchList={setShowSearchList}
                          searchResults={searchResults}
                          setSearchResults={setSearchResults}
                          inputValue={inputValue}
                          setInputValue={setInputValue}
                          onSearch={onSearch}
                          cate1value={1}
                          franchiseId={sessionStorage.getItem("franchise_id")}
                        />
                      )}
                      <Btn
                        color={"#fff"}
                        background={"#FF4A4A"}
                        radius={"6px"}
                        size={"14px"}
                        weight={"700"}
                        lineHeight={"22px"}
                        padding={"9px 19px"}
                        onClick={onSearch}
                      >
                        검 색
                      </Btn>
                    </FlexBox>
                  )}
                </FlexBox>
                {isMobile && (
                  <FlexBox gap={"10px"}>
                    {filter === "barcode_num" ? (
                      <SearchInput
                        borderBottom={"1px solid #666666"}
                        borderBottomColor={"#ff4a4a"}
                        transition={"border-color 0.3s ease-in-out"}
                        flex={1}
                        placeholder="바코드번호를 입력해주세요."
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={(event) => handleKeyDown(event, onSearch)}
                      />
                    ) : (
                      <ProductSearchInputVer2
                        showSearchList={showSearchList}
                        setShowSearchList={setShowSearchList}
                        searchResults={searchResults}
                        setSearchResults={setSearchResults}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        onSearch={onSearch}
                        cate1value={1}
                        franchiseId={sessionStorage.getItem("franchise_id")}
                      />
                    )}
                    <Btn
                      width={"30%"}
                      color={"#fff"}
                      background={"#FF4A4A"}
                      radius={"6px"}
                      size={"14px"}
                      weight={"700"}
                      lineHeight={"22px"}
                      padding={"9px 19px"}
                      onClick={onSearch}
                      margin={"10px 0 0"}
                      style={{ minWidth: "68px" }}
                    >
                      검 색
                    </Btn>
                  </FlexBox>
                )}

                <FlexBox justify={"space-between"}>
                  <FlexBox gap={"6px"} gapMd={"10px"} align={"flex-end"}>
                    <Text color={"#1e1e1e"} weight={"700"} size={"18px"} sizeMd={"26px"}>
                      전체
                    </Text>
                    <Text color={"#FF4A4A"} size={"12px"} sizeMd={"16px"}>
                      {totalCount}개
                    </Text>
                  </FlexBox>

                  <FlexBox margin={"20px 0"} justify={"end"} gap={"8px"} gapMd={"10px"}>
                    <Btn
                      radius={"20px"}
                      padding={"8px 14px"}
                      paddingMd={"8px 16px"}
                      size={"12px"}
                      sizeMd={"14px"}
                      weight={sort === "count" ? "700" : "400"}
                      hoverBackground={sort === "count" ? "#FF4A4A" : "#fff"}
                      border={sort === "count" ? "1px solid #fff" : "1px solid #C7C7C7"}
                      color={sort === "count" ? "#fff" : "#C7C7C7"}
                      background={sort === "count" ? "#FF4A4A" : "#fff"}
                      onClick={() => {
                        setSort("count");
                        setCurrentPage(1);
                      }}
                    >
                      판매순
                    </Btn>
                    <Btn
                      radius={"20px"}
                      padding={"8px 14px"}
                      paddingMd={"8px 16px"}
                      size={"12px"}
                      sizeMd={"14px"}
                      weight={sort === "amount" ? "700" : "400"}
                      hoverBackground={sort === "amount" ? "#FF4A4A" : "#fff"}
                      border={sort === "amount" ? "1px solid #fff" : "1px solid #C7C7C7"}
                      color={sort === "amount" ? "#fff" : "#C7C7C7"}
                      background={sort === "amount" ? "#FF4A4A" : "#fff"}
                      onClick={() => {
                        setSort("amount");
                        setCurrentPage(1);
                      }}
                    >
                      매출순
                    </Btn>
                  </FlexBox>
                </FlexBox>

                <FlexBox
                  align={"flex-start"}
                  justify={"flex-start"}
                  wrap={"wrap"}
                  gap={"20px 10px"}
                  gapMd={"40px 10px"}
                >
                  {data?.length === 0 ? (
                    <Text
                      colSpan={6}
                      size={"12px"}
                      weight={"400"}
                      color={"#6c6c6c"}
                      align={"center"}
                      style={{ width: "100%" }}
                    >
                      데이터가 없습니다.
                    </Text>
                  ) : (
                    ""
                  )}
                  {data?.map((e) => (
                    <ProductItem
                      key={e.idx}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/goods/view", { state: { idx: e.idx } });
                        let jsonString = JSON.stringify(goodsAllPageState);
                        sessionStorage.setItem("goodsAllPageState", jsonString);
                      }}
                    >
                      <SquareImg
                        stopSale={e?.sale_status === "extinction" || e?.sale_status === "missing"}
                        status={e?.sale_status}
                        style={{ margin: isMobile ? "0 0 6px" : "0 0 16px" }}
                      >
                        <div>
                          <img
                            src={e.image_path_1 ? `${process.env.REACT_APP_S3_KEY}${e.image_path_1}` : img_noimage}
                          />
                        </div>
                      </SquareImg>
                      {e.brand && (
                        <Text
                          color={"#EF6F6F"}
                          weight={"400"}
                          align={"left"}
                          size={"10px"}
                          sizeMd={"14px"}
                          padding={" 0 0 6px 0"}
                          paddingMd={" 0 0 12px 0"}
                        >
                          {e.brand}
                        </Text>
                      )}

                      <Text
                        color={"#2d2d2d"}
                        weight={"500"}
                        align={"left"}
                        size={"12px"}
                        sizeMd={"16px"}
                        className="ellipsis"
                      >
                        {e.product_name}
                      </Text>
                      {e.shipping_price != 0 && (
                        <Text
                          color={"#2d2d2d"}
                          weight={"500"}
                          align={"left"}
                          size={"10px"}
                          sizeMd={"14px"}
                          padding={"5px 0 0 0"}
                          paddingMd={"10px 0 0 0"}
                        >
                          공급가: {AddComma(e.shipping_price)}원
                        </Text>
                      )}

                      {e.consumer_price && (
                        <Text
                          color={"#2d2d2d"}
                          weight={"500"}
                          align={"left"}
                          size={"10px"}
                          sizeMd={"14px"}
                          padding={"3px 0 0 0"}
                          paddingMd={"5px 0 0 0"}
                        >
                          소비자가: {AddComma(e.consumer_price)}원
                        </Text>
                      )}
                      {e.sale_status === "missing" && (
                        <Btn
                          color={"#fff"}
                          background={"#C7C7C7"}
                          border={"1px solid #C7C7C7"}
                          weight={"500"}
                          size={"10px"}
                          sizeMd={"12px"}
                          padding={"2px 4px"}
                          paddingMd={"4px 7px"}
                          margin={"4px 0  0  0"}
                          marginMd={"10px 0  0  0"}
                          radius={"0"}
                        >
                          입고지연
                        </Btn>
                      )}
                      {e.sale_status === "extinction" && (
                        <Btn
                          color={"#fff"}
                          background={"#C7C7C7"}
                          border={"1px solid #C7C7C7"}
                          weight={"500"}
                          size={"10px"}
                          sizeMd={"12px"}
                          padding={"2px 4px"}
                          paddingMd={"4px 7px"}
                          margin={"4px 0  0  0"}
                          marginMd={"10px 0  0  0"}
                          radius={"0"}
                        >
                          단종
                        </Btn>
                      )}
                    </ProductItem>
                  ))}
                </FlexBox>

                {data?.length > 0 ? (
                  <Paging totalPage={totalPage} limit={limit} page={currentPage} setPage={setCurrentPage} />
                ) : (
                  ""
                )}
                {isMobile && (
                  <BannerWrap
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (banner.idx) {
                        let jsonString = JSON.stringify(goodsAllPageState);
                        sessionStorage.setItem("goodsAllPageState", jsonString);
                        navigate("/notice/View", { state: { idx: banner.idx } });
                      }
                    }}
                  >
                    <img
                      src={banner ? `${process.env.REACT_APP_S3_KEY}${banner.image_path_1}` : img_banner}
                      alt="공지사항 배너"
                    />
                  </BannerWrap>
                )}
              </Container>
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const BannerWrap = styled.div`
  position: relative;
  padding-bottom: 30px;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  @media all and (max-width: 1024px) {
    padding-top: 20px;
    padding-bottom: 0;
  }
`;

const ProductItem = styled.div`
  width: calc(25% - 8px);

  @media all and (max-width: 1024px) {
    width: calc(50% - 6.67px);
  }
`;

export default Goods;
