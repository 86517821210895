import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import {
  Btn,
  Wrapper,
  Container,
  ContentContainer,
  FlexBox,
  Text,
  TextBar,
  Title,
  TextInput,
  Contents,
} from "../../Assets/css/commonVer2";
import { AddComma } from "../../Utiles/Custom";
import API from "../../Utiles/API";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import Authority from "../../Components/Modal/Authority";
import Loading from "../../Components/Loading";
import checkboxDefault from "../../Assets/images/checkbox_32btn_default.svg";
import checkboxSelect from "../../Assets/images/checkbox_32btn_select.svg";
import InfoModal from "../../Components/Modal/InfoModal";
import HeaderSub from "../../Components/Header/HeaderSub";

// 상품관리 > 자점매입 상품관리 > 슬롯 구매

function BuySlot() {
  const navigate = useNavigate();
  const [franchiseId] = useState(sessionStorage.getItem("franchise_id"));
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  // 다루는 데이터
  const [canBuy, setCanBuy] = useState(false); // 구매하기 버튼 활성화 컨트롤
  const [priceData, setPriceData] = useState([]); // 유형별 가격정보
  const [values, setValues] = useState({
    cnt: 1,
    type: null,
  });

  // 모달들
  const [buyConfirmModal, setBuyConfirmModal] = useState(false);
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [moveToBackModal, setMoveToBackModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const getPriceData = async () => {
    try {
      const res = await API.get(`/slot/cost`);
      if (res.data.status) {
        setPriceData(res.data.results);
      } else {
        setTextMsg("E01\n서버에서 정보를 불러오는데 문제가 생겼습니다. 잠시후 다시 시도해주세요.");
        setMoveToBackModal(true);
      }
    } catch (error) {
      setTextMsg("E01\n서버에서 정보를 불러오는데 문제가 생겼습니다. 잠시후 다시 시도해주세요.");
      setMoveToBackModal(true);
    }
  };

  const buySlot = async () => {
    setLoading(true);
    const selectedPrice = priceData.find((item) => item.purchase_type === values.type);

    API.post(`/slot`, {
      franchise_id: parseInt(franchiseId),
      slot_count: parseInt(values.cnt),
      purchase_type: values.type,
      purchase_price: selectedPrice.discount_price * values.cnt,
    })
      .then(function (res) {
        setLoading(false);
        if (res.data.status) {
          setTextMsg("구매가 완료되었습니다.");
          setMoveToBackModal(true);
        } else {
          setLoading(false);
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(function (error) {
        setLoading(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const handleChange = (e) => {
    // 음수 값을 방지하기 위해 입력값을 검증하고 조정합니다.
    if (e.target.name === "cnt" && Number(e.target.value) < 0) {
      setValues({ ...values, [e.target.name]: 0 });
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };

  const addCnt = (amount) => {
    setValues((prevValues) => ({
      ...prevValues,
      cnt: Number(prevValues.cnt) + amount,
    }));
  };

  const onSubmit = (e) => {
    if (e === 1) {
      setBuyConfirmModal(false);
      buySlot();
    }
  };

  useEffect(() => {
    if (values.cnt > 0 && values.type !== null) {
      setCanBuy(true);
    } else {
      setCanBuy(false);
    }
  }, [values]);

  useEffect(() => {
    getPriceData();
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          {loading ? <Loading /> : null}
          <HeaderSub title={"자점매입 슬롯 구매하기"} />
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                <FlexBox>
                  <FlexBox
                    direction={"column"}
                    align={"start"}
                    style={{ flex: 1 }}
                    gap={"6px"}
                    gapMd={"10px"}
                    padding={"0 0 20px 0"}
                    paddingMd={"0 0 17px 0"}
                  >
                    {!isMobile && (
                      <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                        자점 매입 슬롯 구매하기 🛒
                      </Title>
                    )}

                    <Text align={"left"} size={"14px"} sizeMd={"16px"} weight={"400"} color={"#1E1E1E"}>
                      구매 수량과 구매 방식을 선택해주세요.
                    </Text>
                  </FlexBox>
                </FlexBox>
                {/* 수량 & 구매방식 선택 */}
                <Contents
                  margin={"0 0 20px"}
                  padding={"20px 16px"}
                  paddingMd={"20px 40px"}
                  style={{
                    borderRadius: "10px",
                    background: "#FFF",
                    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
                  }}
                >
                  <FlexBox justify={"start"} align={"end"} wrap={"wrap"} wrapMd={"unset"}>
                    <Contents margin={"0 0 0 0"} marginMd={"0 20px 0 0"}>
                      <Text
                        align={"left"}
                        padding={"0 0 10px 0"}
                        paddingMd={"0 0 20px 0"}
                        size={"14px"}
                        sizeMd={"16px"}
                        weight={"700"}
                        color={"#1E1E1E"}
                      >
                        구매수량
                      </Text>
                      <TextInput
                        border={"1px solid #C7C7C7"}
                        style={{ width: isMobile ? "50%" : "100%" }}
                        padding={"11.5px 15px"}
                        type={"number"}
                        placeholder={"구매할 수량을 입력해주세요."}
                        name="cnt"
                        value={values.cnt}
                        onChange={handleChange}
                      />
                    </Contents>

                    <Contents margin={"10px 0 0 "} marginMd={"0 0 "}>
                      <FlexBox justify={"start"} gap={"8px"} gapMd={"10px"}>
                        <Btn
                          border={"1px solid #FF4A4A"}
                          color={"#FF4A4A"}
                          background={"#ffffff"}
                          weight={"500"}
                          hoverBorder={"1px solid #FF4A4A"}
                          hoverColor={"#FFFFFF"}
                          hoverBackground={"#FF4A4A"}
                          hoverWeight={"700"}
                          size={"14px"}
                          padding={"5px 14px"}
                          paddingMd={"9px 19px"}
                          radius={"6px"}
                          margin={"0 0"}
                          marginMd={"5px 0 0 0"}
                          onClick={() => addCnt(1)}
                        >
                          + 1개
                        </Btn>
                        <Btn
                          border={"1px solid #FF4A4A"}
                          color={"#FF4A4A"}
                          background={"#ffffff"}
                          weight={"500"}
                          hoverBorder={"1px solid #FF4A4A"}
                          hoverColor={"#FFFFFF"}
                          hoverBackground={"#FF4A4A"}
                          hoverWeight={"700"}
                          size={"14px"}
                          padding={"5px 14px"}
                          paddingMd={"9px 19px"}
                          radius={"6px"}
                          margin={"0 0"}
                          marginMd={"5px 0 0 0"}
                          onClick={() => addCnt(10)}
                        >
                          + 10개
                        </Btn>
                        <Btn
                          border={"1px solid #FF4A4A"}
                          color={"#FF4A4A"}
                          background={"#ffffff"}
                          weight={"500"}
                          hoverBorder={"1px solid #FF4A4A"}
                          hoverColor={"#FFFFFF"}
                          hoverBackground={"#FF4A4A"}
                          hoverWeight={"700"}
                          size={"14px"}
                          padding={"5px 14px"}
                          paddingMd={"9px 19px"}
                          radius={"6px"}
                          margin={"0 0"}
                          marginMd={"5px 0 0 0"}
                          onClick={() => addCnt(30)}
                        >
                          + 30개
                        </Btn>
                      </FlexBox>
                    </Contents>
                  </FlexBox>

                  <FlexBox
                    justify={"start"}
                    align={"end"}
                    margin={"24px 0 0 0"}
                    marginMd={"30px 0 0 0"}
                    wrap={"wrap"}
                    wrapMd={"unset"}
                  >
                    <Contents margin={"0 0 0 0"} marginMd={"0 20px 0 0"}>
                      <Text
                        align={"left"}
                        padding={"0 0 10px 0"}
                        paddingMd={"0 0 20px 0"}
                        size={"14px"}
                        sizeMd={"16px"}
                        weight={"700"}
                        color={"#1E1E1E"}
                      >
                        구매유형
                      </Text>
                    </Contents>
                  </FlexBox>
                  <FlexBox
                    justify={"space-evenly"}
                    margin={"0 0 20px"}
                    marginMd={"0 0 30px"}
                    gap="10px"
                    style={{ flexDirection: isMobile ? "column" : "row" }}
                  >
                    {priceData?.map((data, index) => (
                      <CardBox
                        key={index}
                        className={values.type === data.purchase_type ? "selected" : ""}
                        onClick={() => {
                          setValues({
                            ...values,
                            type: values.type === data.purchase_type ? null : data.purchase_type,
                          });
                        }}
                      >
                        <FlexBox justify={"start"} align={"center"} direction={"row"}>
                          <div>
                            <img
                              style={{ width: isMobile ? "24px" : "32px" }}
                              src={values.type === data.purchase_type ? checkboxSelect : checkboxDefault}
                            />
                          </div>
                          <FlexBox direction={"column"} align={"left"} margin={"0 0 0 20px"} gap={"10px"}>
                            <Text color="1E1E1E" size="19px" sizeMd="26px" weight="700" align={"left"}>
                              {data.purchase_type === "one_month"
                                ? "1개월"
                                : data.purchase_type === "six_month"
                                ? "6개월"
                                : "정기결제"}
                            </Text>
                            <Text color="1E1E1E" size="14px" sizeMd="18px" weight="400" align={"left"}>
                              {data.purchase_type === "one_month" ? (
                                <Text color="#1E1E1E">
                                  총{" "}
                                  {values.cnt != null && values.cnt > 0
                                    ? AddComma(data.purchase_price * values.cnt)
                                    : AddComma(data.purchase_price)}{" "}
                                  원
                                </Text>
                              ) : data.purchase_type === "six_month" ? (
                                <FlexBox justify="start" wrap="wrap" gap="5px">
                                  <Text color="#C7C7C7" style={{ textDecoration: "line-through" }}>
                                    총{" "}
                                    {values.cnt != null && values.cnt > 0
                                      ? AddComma(data.purchase_price * values.cnt)
                                      : AddComma(data.purchase_price)}{" "}
                                    원
                                  </Text>
                                  <Text color="#1E1E1E">
                                    → 총{" "}
                                    {values.cnt != null && values.cnt > 0
                                      ? AddComma(data.discount_price * values.cnt)
                                      : AddComma(data.discount_price)}{" "}
                                    원
                                  </Text>
                                  <Text color="#FF4A4A" weight="700">
                                    {" "}
                                    {data.discount_rate}% 할인!
                                  </Text>
                                </FlexBox>
                              ) : (
                                <FlexBox justify="start" wrap="wrap" gap="5px">
                                  <Text color="#C7C7C7" style={{ textDecoration: "line-through" }}>
                                    월{" "}
                                    {values.cnt != null && values.cnt > 0
                                      ? AddComma(data.purchase_price * values.cnt)
                                      : AddComma(data.purchase_price)}{" "}
                                    원
                                  </Text>
                                  <Text color="#1E1E1E">
                                    → 월{" "}
                                    {values.cnt != null && values.cnt > 0
                                      ? AddComma(data.discount_price * values.cnt)
                                      : AddComma(data.discount_price)}{" "}
                                    원
                                  </Text>
                                  <Text color="#FF4A4A" weight="700">
                                    {" "}
                                    {data.discount_rate}% 할인!
                                  </Text>
                                </FlexBox>
                              )}
                            </Text>
                          </FlexBox>
                        </FlexBox>
                      </CardBox>
                    ))}
                  </FlexBox>
                  <FlexBox justify={"end"} gap={"10px"}>
                    <Btn
                      background={"#C7C7C7"}
                      border={"#C7C7C7"}
                      color={"#fff"}
                      radius={"6px"}
                      padding={"9px 40px"}
                      paddingMd={"9px 19px"}
                      width={"50%"}
                      widthMd={"unset"}
                      weight={"700"}
                      size={"14px"}
                      lineHeight={"22px"}
                      onClick={() => navigate(-1)}
                    >
                      취소하기
                    </Btn>
                    <Btn
                      style={{ border: "1px solid #FF4A4A" }}
                      background={canBuy ? "#FF4A4A" : "#fff"}
                      radius={"6px"}
                      padding={"9px 22px"}
                      width={"50%"}
                      widthMd={"unset"}
                      color={canBuy ? "#fff" : "#FF4A4A"}
                      weight={"700"}
                      size={"14px"}
                      lineHeight={"22px"}
                      onClick={() => {
                        if (canBuy) {
                          setBuyConfirmModal(true);
                        } else {
                          if (values.cnt < 1) {
                            setTextMsg("구매수량을 입력해주세요.");
                          } else if (values.type === null) {
                            setTextMsg("구매유형을 선택해주세요.");
                          }
                          setTextModal(true);
                        }
                      }}
                    >
                      구매하기
                    </Btn>
                  </FlexBox>
                </Contents>
                {/* 안내사항 */}
                <div className="greyBox" style={{ marginTop: "24px", padding: isMobile ? "20px 20px" : "28px 40px" }}>
                  <Text
                    color={"#666666"}
                    size={"14px"}
                    sizeMd={"18px"}
                    weight={"700"}
                    align={"left"}
                    margin={"0 0 10px 0"}
                    style={{
                      latterSpacing: "-0.36px",
                      lineHeight: "normal",
                    }}
                  >
                    이용안내
                  </Text>
                  <TextBar
                    align={"left"}
                    size={"14px"}
                    style={{
                      latterSpacing: "-0.28px",
                      lineHeight: "25px",
                    }}
                  >
                    슬롯에 상품을 등록 후 검수 요청을 꼭 해주셔야 하며, 관리자의 검수 승인이 되어야 해당 상품을 판매할
                    수 있습니다.
                  </TextBar>
                  <TextBar
                    align={"left"}
                    size={"14px"}
                    style={{
                      latterSpacing: "-0.28px",
                      lineHeight: "25px",
                    }}
                  >
                    모든 구매 슬롯은 중복적용 되지 않습니다. (ex. 1개월 10개 + 6개월 1개 = 11개의 슬롯)
                  </TextBar>
                  <TextBar
                    align={"left"}
                    size={"14px"}
                    style={{
                      latterSpacing: "-0.28px",
                      lineHeight: "25px",
                    }}
                  >
                    구매 시 예치금에서 차감되며 상품을 등록 할 수 있도록 활성화 됩니다.
                  </TextBar>
                  <Text
                    color={"#666666"}
                    size={"14px"}
                    weight={"700"}
                    align={"left"}
                    margin={"10px 0 10px 0"}
                    style={{
                      latterSpacing: "-0.36px",
                      lineHeight: "normal",
                    }}
                  >
                    1개월 이용권
                  </Text>
                  <TextBar
                    color={"#666666"}
                    align={"left"}
                    size={"14px"}
                    style={{
                      latterSpacing: "-0.28px",
                      lineHeight: "25px",
                    }}
                  >
                    신청 및 구매 시 보유하신 ‘예치금'에서 차감되어 적용됩니다. 30일 동안 사용 가능하며, 30일 이후 자동
                    해지됩니다.
                  </TextBar>
                  <Text
                    color={"#666666"}
                    size={"14px"}
                    weight={"700"}
                    align={"left"}
                    margin={"10px 0 10px 0"}
                    style={{
                      latterSpacing: "-0.36px",
                      lineHeight: "normal",
                    }}
                  >
                    6개월 이용권
                  </Text>
                  <TextBar
                    color={"#666666"}
                    align={"left"}
                    size={"14px"}
                    style={{
                      latterSpacing: "-0.28px",
                      lineHeight: "25px",
                    }}
                  >
                    신청 및 구매 시 보유하신 ‘예치금'에서 차감되어 적용 됩니다. 총 180일동안 사용 가능하며, 180일 경과
                    후 자동 해지 됩니다.
                  </TextBar>
                  <Text
                    color={"#666666"}
                    size={"14px"}
                    weight={"700"}
                    align={"left"}
                    margin={"10px 0 10px 0"}
                    style={{
                      latterSpacing: "-0.36px",
                      lineHeight: "normal",
                    }}
                  >
                    정기 결제 이용권
                  </Text>
                  <TextBar
                    color={"#666666"}
                    align={"left"}
                    size={"14px"}
                    style={{
                      latterSpacing: "-0.28px",
                      lineHeight: "25px",
                    }}
                  >
                    정기 결제 첫 달은 신청 후 즉시 ‘예치금'에서 차감되며 적용됩니다. 두 번째 달부터는 마지막
                    결제일로부터 30일 후에 자동 결제되며 3개월 이후부터 해지 가능합니다.
                  </TextBar>
                  <TextBar
                    color={"#666666"}
                    align={"left"}
                    size={"14px"}
                    style={{
                      latterSpacing: "-0.28px",
                      lineHeight: "25px",
                    }}
                  >
                    정기 결제일에 예치금이 부족한 경우 슬롯은 자동해지됩니다.
                  </TextBar>
                  <Text
                    color={"#666666"}
                    align={"left"}
                    size={"14px"}
                    margin={"10px 0 0  0"}
                    weight={"600"}
                    style={{
                      latterSpacing: "-0.28px",
                      lineHeight: "25px",
                    }}
                  >
                    슬롯 해지 신청은 슬롯 구매 상세 페이지에서 가능합니다.
                  </Text>
                  <Text
                    color={"#666666"}
                    align={"left"}
                    size={"14px"}
                    weight={"600"}
                    style={{
                      latterSpacing: "-0.28px",
                      lineHeight: "25px",
                    }}
                  >
                    환불액은 (총 결제금액 / 슬롯 개수) / 30 * 잔여 구독일 수 * 환불 슬롯 수량 입니다.
                  </Text>
                  <Text
                    color={"#666666"}
                    align={"left"}
                    size={"14px"}
                    margin={" 0 0 30px 0"}
                    weight={"600"}
                    style={{
                      latterSpacing: "-0.28px",
                      lineHeight: "25px",
                    }}
                  >
                    환불 신청 즉시 등록된 상품은 판매중지되며 취소할 수 없습니다. 환불액은 예치금으로 반환됩니다.
                  </Text>
                  <Text
                    color={"#666666"}
                    size={"14px"}
                    sizeMd={"18px"}
                    weight={"700"}
                    align={"left"}
                    margin={"0 0 10px 0"}
                    style={{
                      latterSpacing: "-0.36px",
                      lineHeight: "normal",
                    }}
                  >
                    주의 사항
                  </Text>
                  <TextBar
                    align={"left"}
                    size={"14px"}
                    style={{
                      latterSpacing: "-0.28px",
                      lineHeight: "25px",
                    }}
                  >
                    자점 매입으로 구매 시 인증받지 않은 제품으로 인한 피해는 본사가 책임지지 않습니다.
                  </TextBar>
                  <TextBar
                    align={"left"}
                    size={"14px"}
                    style={{
                      latterSpacing: "-0.28px",
                      lineHeight: "25px",
                    }}
                  >
                    구매하신 상품 중 일부 상품의 경우 검토를 통해 지원되지 않을 수 있습니다. (유통기한이 있는 상품,
                    출처가 불분명한 상품 등)
                  </TextBar>
                </div>
              </Container>
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
              <ConfirmModal
                modal={buyConfirmModal}
                setModal={setBuyConfirmModal}
                onSubmit={() => onSubmit(1)}
                description={"선택하신 내용으로 구매하시겠습니까?"}
              />
              <InfoModal modal={moveToBackModal} setModal={setMoveToBackModal} description={textMsg} mode={"goBack"} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const CardBox = styled.div`
  /* Default styles */
  display: flex;
  background-color: #fff;
  border: 1px solid #c7c7c7;
  border-radius: 10px;
  text-align: center;
  width: calc((100% - 40px) / 3);
  min-height: 146px;
  margin-right: 15px;
  padding: 30px 20px;
  cursor: pointer;

  /* Selected styles */
  &.selected {
    border-radius: 10px;
    border: 1px solid var(--primary, #ff4a4a);
    background: linear-gradient(0deg, rgba(255, 116, 64, 0.1) 0%, rgba(255, 116, 64, 0.1) 100%), #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  /* Hover styles */
  &:hover:not(.selected) {
    border-radius: 10px;
    border: 1px solid var(--black-gray-01, #666);
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 126px;
  }
`;

export default BuySlot;
