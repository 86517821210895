import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  TableWrap,
  Th,
  Td,
  Btn,
  HeaderContainer,
  Wrapper,
  Container,
  ContentContainer,
  FlexBox,
  WhiteBox,
  Title,
  PagerWrap,
} from "../../Assets/css/commonVer2";
import { CATEGORY_1ST, UNIT_10_TO_30_LIST } from "../../Utiles/ListData";
import BasicSelect from "../../Components/Select/BasicSelect";
import Header from "../../Components/Header/HeaderVer2";
import Paging from "../../Components/Paging/Paging";
import API from "../../Utiles/API";
import CategoryModal from "./Components/CategoryModal";
import { str } from "../../Utiles/Custom";
import Authority from "../../Components/Modal/Authority";
import HandleBack from "../../Components/HandleBack";
import Cate2Select from "../../Components/Select/Cate2Select";
import InfoModal from "../../Components/Modal/InfoModal";

// 상품마스터 > 상품분류관리

function ProductCategory() {
  const { handlePopstate } = HandleBack();
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [cate1Value, setCate1Value] = useState(1);
  const [cate2Value, setCate2Value] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectValue, setSelectValue] = useState("");

  const [cateDepth, setCateDepth] = useState(1);
  const [modal, setModal] = useState(false);
  const start = (currentPage - 1) * limit;

  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [refreshModal, setRefreshModal] = useState(false);

  // API
  const getCategoryList = async () => {
    let api_link = `/admin/productCatrgoryList?curPage=${currentPage}&pageSize=${limit}`;
    if (cate1Value) api_link += `&filterVal1=${cate1Value}`;
    if (cate2Value) {
      if (cate1Value) {
        api_link += `&filterVal2=${cate2Value}`;
      } else {
        setTextMsg("1차 분류를 선택해주세요.");
        setTextModal(true);
        return;
      }
    }
    API.get(api_link).then(function (res) {
      if (res.data.status) {
        const filterData = res.data.result.map((e, i) => {
          const no = totalCount - (currentPage - 1) * limit - i;
          return { ...e, no };
        });
        setData(filterData);
        setTotalCount(res.data?.cntAll);
      }
    });
  };

  const postCate1Item = async () => {
    await API.post("/admin/productCatrgory1st", {
      name: inputValue,
    })
      .then(async (res) => {
        if (res.data.status) {
          getCategoryList();
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const postCate2Item = async () => {
    await API.post("/admin/productCatrgory2nd", {
      category1st_id: 1,
      name: inputValue,
    })
      .then(async (res) => {
        if (res.data.status) {
          setTextMsg("등록이 완료되었습니다.");
          setRefreshModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const onSearch = () => {
    setCurrentPage(1);
    getCategoryList();
  };

  const handleModalSubmit = () => {
    if (inputValue !== "") {
      if (cateDepth === 1) {
        postCate1Item();
        setModal(false);
      } else {
        postCate2Item();
        setModal(false);
      }
    } else {
      alert(`${cateDepth}차 분류명을 입력해주세요.`);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    if (Math.ceil(totalCount / limit) == 0) {
      setTotalPage(1);
    } else {
      setTotalPage(Math.ceil(totalCount / limit));
    }
  }, [totalCount, limit]);

  useEffect(() => {
    getCategoryList();
  }, [limit, currentPage]);

  useEffect(() => {
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          <>
            {isMobile && <Header title={"상품분류 관리"} />}
            <ContentContainer>
              <Wrapper type={2} pbM={"112px"}>
                <Container>
                  {!isMobile && (
                    <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                      상품분류 관리
                    </Title>
                  )}
                  <WhiteBox margin={"0 0"}>
                    <FlexBox justify={"end"} gap={"10px"} gapMd={"15px"} margin={"10px 0 0"} marginMd={"0 0"}>
                      {/* <BasicSelect
                        widthMd={"100%"}
                        data={CATEGORY_1ST}
                        selectedValue={cate1Value}
                        setSelectedValue={setCate1Value}
                        placeholder={"상품 1차 분류"}
                        canSelectAll={true}
                      /> */}
                      <Cate2Select
                        placeholder="상품 2차 분류"
                        widthMd={"100%"}
                        idx={1}
                        cate2Value={cate2Value}
                        setCate2Value={setCate2Value}
                      />

                      <Btn
                        color={"#fff"}
                        background={"#FF4A4A"}
                        radius={"6px"}
                        size={"14px"}
                        weight={"700"}
                        width={"120px"}
                        widthMd={"unset"}
                        padding={"9px 19px"}
                        lineHeight={"22px"}
                        onClick={onSearch}
                      >
                        검 색
                      </Btn>
                    </FlexBox>
                    <FlexBox
                      justify={"space-between"}
                      margin={"10px 0 15px"}
                      marginMd={"20px 0 30px"}
                      gap={"10px"}
                      gapMd={"10px"}
                      wrap={"wrap"}
                    >
                      <FlexBox justify={"starts"}>
                        {!isMobile && (
                          <BasicSelect
                            data={UNIT_10_TO_30_LIST}
                            selectedValue={limit}
                            setSelectedValue={setLimit}
                            canSelectAll={false}
                            setCurrentPage={setCurrentPage}
                            width={"130px"}
                          />
                        )}
                      </FlexBox>
                      <FlexBox justify={"end"} gap={"15px"}>
                        <Btn
                          border={"1px solid #FF4A4A"}
                          color={"#FF4A4A"}
                          hoverColor={"#FFFFFF"}
                          hoverBackground={"#FF4A4A"}
                          hoverWeight={"700"}
                          padding={"9px 19px"}
                          size={"14px"}
                          radius={"6px"}
                          weight={"400"}
                          lineHeight={"22px"}
                          onClick={() => {
                            setInputValue("");
                            setModal(true);
                            setCateDepth(2);
                          }}
                        >
                          2차 등록
                        </Btn>
                      </FlexBox>
                    </FlexBox>{" "}
                    <TableWrap>
                      <table style={{ width: "100%" }}>
                        <colgroup>
                          <col style={{ width: "10.5%" }} />
                          <col />
                          <col />
                          <col style={{ width: "24.5%" }} />
                          <col style={{ width: "16%" }} />
                        </colgroup>
                        <thead>
                          <tr>
                            <Th>No</Th>
                            <Th>1차 분류</Th>
                            <Th>2차 분류</Th>
                            <Th>등록상품 개수</Th>
                            <Th>등록일</Th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.length === 0 ? (
                            <tr>
                              <Td colSpan={5} size={"12px"} weight={"400"} color={"#6c6c6c"} align={"center"}>
                                데이터가 없습니다.
                              </Td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {data?.map((e, i) => {
                            return (
                              <tr key={i}>
                                <Td
                                  style={{
                                    borderBottom: "1px solid #F2F2F2",
                                  }}
                                >
                                  {start + i + 1}
                                </Td>
                                <Td
                                  style={{
                                    borderBottom: "1px solid #F2F2F2",
                                  }}
                                >
                                  {e.name || "-"}
                                </Td>
                                <Td
                                  style={{
                                    borderBottom: "1px solid #F2F2F2",
                                  }}
                                >
                                  {e["category2nds.name"] || "-"}
                                </Td>
                                <Td
                                  style={{
                                    borderBottom: "1px solid #F2F2F2",
                                  }}
                                >
                                  {e.product_create_cnt}
                                </Td>
                                <Td
                                  style={{
                                    borderBottom: "1px solid #F2F2F2",
                                  }}
                                >
                                  {e.createdAt ? str(e.createdAt) : "-"}
                                </Td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </TableWrap>
                    {data?.length > 0 ? (
                      <PagerWrap margin={"30px 0 0 0"}>
                        <Paging totalPage={totalPage} limit={limit} page={currentPage} setPage={setCurrentPage} />
                      </PagerWrap>
                    ) : (
                      ""
                    )}
                  </WhiteBox>
                </Container>
                {modal ? (
                  <CategoryModal
                    setModal={setModal}
                    onSubmit={handleModalSubmit}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    setSelectValue={setSelectValue}
                    type={cateDepth}
                  />
                ) : (
                  ""
                )}

                <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
                <InfoModal modal={refreshModal} setModal={setRefreshModal} description={textMsg} mode={"refresh"} />
              </Wrapper>
            </ContentContainer>
          </>
        </>
      )}
    </>
  );
}

export default ProductCategory;
