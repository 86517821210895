import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";

import {
  TableWrap,
  Th,
  Td,
  Btn,
  Wrapper,
  Container,
  ContentContainer,
  FlexBox,
  Text,
  WhiteBox,
  Title,
} from "../../Assets/css/commonVer2";
import { WHOLE_SLOT_STATUS_LIST, PURCHASE_TYPE_LIST, UNIT_10_TO_40_LIST } from "../../Utiles/ListData";
import { AddComma, str } from "../../Utiles/Custom";
import API from "../../Utiles/API";
import Pagination from "../../Components/Paging/Pagination";
import BasicSelect from "../../Components/Select/BasicSelect";
import HandleBack from "../../Components/HandleBack";
import FranSelect from "../../Components/Select/FranSelect";
import HeaderSub from "../../Components/Header/HeaderSub";

// (점주) 상품관리 > 자점매입 상품관리 > 슬롯구매내역
// (관리자) 자점매입 > 슬롯구매내역

function SlotHistory() {
  const navigate = useNavigate();
  const { handlePopstate } = HandleBack();
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  // 상세 다녀온 경우 저장 해놓은 파라미터 정보
  const parsedSlotHistoryPageState = sessionStorage.getItem("slotHistoryPageState")
    ? JSON.parse(sessionStorage.getItem("slotHistoryPageState"))
    : {};

  const isAdmin = sessionStorage.getItem("gubun") === "admin";

  const [franchise_id, setFranchiseId] = useState(
    isAdmin
      ? null
      : parsedSlotHistoryPageState
        ? parsedSlotHistoryPageState?.franchise_id
          ? parsedSlotHistoryPageState?.franchise_id
          : sessionStorage.getItem("franchise_id")
        : sessionStorage.getItem("franchise_id"),
  );

  // 필터 관련
  const [slotStatus, setSlotStatus] = useState(
    parsedSlotHistoryPageState
      ? parsedSlotHistoryPageState?.slotStatus
        ? parsedSlotHistoryPageState?.slotStatus
        : ""
      : "",
  );
  const [purchaseType, setPurchaseType] = useState(
    parsedSlotHistoryPageState
      ? parsedSlotHistoryPageState?.purchaseType
        ? parsedSlotHistoryPageState?.purchaseType
        : ""
      : "",
  );
  // 페이징 관련
  const [pagination, setPagination] = useState("");
  const [pageSize, setPageSize] = useState(
    parsedSlotHistoryPageState
      ? parsedSlotHistoryPageState?.pageSize
        ? parsedSlotHistoryPageState?.pageSize
        : 10
      : 10,
  );
  const [currentPage, setCurrentPage] = useState(
    parsedSlotHistoryPageState
      ? parsedSlotHistoryPageState?.currentPage
        ? parsedSlotHistoryPageState?.currentPage
        : 1
      : 1,
  );

  const [franShow, setFranShow] = useState(false);
  const [franText, setFranText] = useState("가맹점 전체");

  const [data, setData] = useState([]);
  const [slotCnt, setSlotCnt] = useState(0);

  // 등록, 수정 이동 시 저장되야 하는 현재 페이지에 대한 정보
  let slotHistoryPageState = {
    currentPage: currentPage,
    pageSize: pageSize,
    franchise_id: franchise_id,
    slotStatus: slotStatus,
    purchaseType: purchaseType,
  };

  const getSlotHistoryList = async () => {
    let api_link = `/slot/purchase?page=${currentPage}&unit=${pageSize}&block=10`;
    if (isAdmin) {
      if (franchise_id) {
        api_link += `&franchise_id=${franchise_id}`;
      }
    } else {
      api_link += `&franchise_id=${franchise_id}`;
    }
    if (slotStatus) {
      api_link += `&slot_status=${slotStatus}`;
    }
    if (purchaseType) {
      api_link += `&purchase_type=${purchaseType}`;
    }

    await API.get(api_link).then((res) => {
      if (res.data.status) {
        setData(res.data.results);
        setSlotCnt(res.data.cntAll);
        setPagination(res.data.pagination);
      }
    });
  };

  const moveToDetail = (idx) => {
    let jsonString = JSON.stringify(slotHistoryPageState);
    sessionStorage.setItem("slotHistoryPageState", jsonString);
    if (isAdmin) {
      navigate("/ownpurchase/slothistory/view", {
        state: { idx: idx },
      });
    } else {
      navigate("/goods/selfgoods/slothistory/view", {
        state: { idx: idx },
      });
    }
  };

  const movePage = (e) => {
    setCurrentPage(e);
  };

  const onSearch = () => {
    setCurrentPage(1);
    getSlotHistoryList();
  };

  useEffect(() => {
    getSlotHistoryList();
  }, [currentPage, pageSize]);

  useEffect(() => {
    sessionStorage.removeItem("slotHistoryPageState");
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      <HeaderSub title={"슬롯구매내역"} />
      <ContentContainer>
        <Wrapper>
          <Container>
            {!isMobile && (
              <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                슬롯구매내역 📋
              </Title>
            )}
            <WhiteBox margin={"0 0"}>
              {isAdmin && isMobile && (
                <FranSelect
                  gubun={"fran"}
                  selectBtnText={franText}
                  setTextChange={setFranText}
                  placeholder={"가맹점을"}
                  show={franShow}
                  setFranShow={setFranShow}
                  setIdxChange={setFranchiseId}
                  widthMd={"100%"}
                />
              )}
              <FlexBox justify={"end"} gap={"10px"} gapMd={"15px"} margin={"10px 0 0"} marginMd={"0 0"}>
                {isAdmin && !isMobile && (
                  <FranSelect
                    gubun={"fran"}
                    selectBtnText={franText}
                    setTextChange={setFranText}
                    placeholder={"가맹점을"}
                    show={franShow}
                    setFranShow={setFranShow}
                    setIdxChange={setFranchiseId}
                    widthMd={"100%"}
                  />
                )}
                <BasicSelect
                  data={PURCHASE_TYPE_LIST}
                  selectedValue={purchaseType}
                  setSelectedValue={setPurchaseType}
                  placeholder={"구매유형 전체"}
                  canSelectAll={true}
                />
                <BasicSelect
                  data={WHOLE_SLOT_STATUS_LIST}
                  selectedValue={slotStatus}
                  setSelectedValue={setSlotStatus}
                  placeholder={"상태 전체"}
                  canSelectAll={true}
                />
                <Btn
                  color={"#fff"}
                  background={"#FF4A4A"}
                  radius={"6px"}
                  size={"14px"}
                  weight={"700"}
                  width={"120px"}
                  widthMd={"unset"}
                  padding={"9px 19px"}
                  lineHeight={"22px"}
                  onClick={onSearch}
                >
                  검 색
                </Btn>
              </FlexBox>

              {/* 공통 - 기타 버튼들 시작 */}
              <FlexBox
                justify={"space-between"}
                margin={"10px 0 16px"}
                marginMd={"10px 0 16px"}
                wrap={"wrap"}
                wrapMd={"unset"}
              >
                <FlexBox justify={"starts"}>
                  <Text
                    width={"100%"}
                    widthMd={"unset"}
                    color={"#FF4A4A"}
                    size={"16px"}
                    weight={"400"}
                    padding={"10px 29px 10px 27px"}
                    background={"rgba(255, 116, 64, 0.10)"}
                    marginMd={"0 10px 0 0 "}
                    radius={"8px"}
                    letterSpacing={"-0.32px"}
                    lineHeight={"normal"}
                  >
                    슬롯 수량 : <span style={{ fontWeight: "700" }}>{AddComma(slotCnt ? slotCnt : "")}개</span>
                  </Text>
                  {!isMobile && (
                    <BasicSelect
                      data={UNIT_10_TO_40_LIST}
                      selectedValue={pageSize}
                      setSelectedValue={setPageSize}
                      canSelectAll={false}
                      setCurrentPage={setCurrentPage}
                    />
                  )}
                </FlexBox>
              </FlexBox>
              {/* 공통 - 데이터 시작 */}
              <TableWrap>
                <table style={{ width: "100%", minWidth: "1024px" }}>
                  <colgroup>
                    <col style={{ width: "4%" }} />
                    {isAdmin && <col />}
                    <col style={{ width: "9%" }} />
                    <col />
                    <col style={{ width: "9%" }} />
                    <col />
                    <col />
                    <col />
                    <col style={{ width: "10%" }} />
                  </colgroup>
                  <thead>
                    <tr style={{ borderRight: "1px solid #c0c0c0" }}>
                      <Th style={{ borderBottom: "1px solid #666666" }}>No</Th>
                      {isAdmin && <Th style={{ borderBottom: "1px solid #666666" }}>가맹점명</Th>}
                      <Th style={{ borderBottom: "1px solid #666666" }}>주문 번호</Th>
                      <Th style={{ borderBottom: "1px solid #666666" }}>구매유형</Th>
                      <Th style={{ borderBottom: "1px solid #666666" }}>구매수량</Th>
                      <Th style={{ borderBottom: "1px solid #666666" }}>결제금액</Th>
                      <Th style={{ borderBottom: "1px solid #666666" }}>구매일</Th>
                      <Th style={{ borderBottom: "1px solid #666666" }}>만료일</Th>
                      <Th style={{ borderBottom: "1px solid #666666" }}>상태</Th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      data.map((list, key) => (
                        <tr
                          style={{ cursor: "pointer" }}
                          key={key}
                          onClick={() => {
                            moveToDetail(list.idx);
                          }}
                        >
                          <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                            {pageSize * (currentPage - 1) + key + 1}
                          </Td>
                          {isAdmin && (
                            <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                              {list.franchise_name ? list.franchise_name : "-"}
                            </Td>
                          )}
                          <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{list.idx ? list.idx : "-"}</Td>
                          <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                            {list.purchase_type
                              ? PURCHASE_TYPE_LIST.find((item) => item.value === list.purchase_type).text
                              : "-"}
                          </Td>
                          <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                            {list.purchase_count ? list.purchase_count : "-"}
                          </Td>
                          <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                            {list.purchase_price ? AddComma(list.purchase_price) : "-"}
                            {list.purchase_price ? "원" : ""}
                          </Td>
                          <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                            {list.createdAt ? str(list.createdAt) : ""}
                          </Td>
                          <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                            {list.expirationAt ? str(list.expirationAt) : ""}
                          </Td>
                          <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                            {list.status
                              ? WHOLE_SLOT_STATUS_LIST.find((item) => item.value === list.status)?.text || "-"
                              : "-"}
                          </Td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={isAdmin ? 9 : 8}
                          style={{
                            borderTop: "1px solid #e1e1e1",
                            textAlign: "center",
                            padding: 10,
                            fontSize: "16px",
                          }}
                        >
                          데이터가 없습니다.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </TableWrap>

              {pagination?.total_page > 0 ? (
                <Pagination pagination={pagination} movePage={movePage} currentPage={currentPage} />
              ) : (
                ""
              )}
            </WhiteBox>
          </Container>
        </Wrapper>
      </ContentContainer>
    </>
  );
}

export default SlotHistory;
