import React, { useEffect, useState, useRef } from "react";

import { SelectBtn, SearchList, SelectWrap, SelectContainer } from "../../Assets/css/commonVer2";

// 택배사 선택 셀렉 박스

export default function CourierCompanySelect(props) {
  let {
    data, // 목록에 위치시킬 데이터
    gubun, // 주문내역 메인 <-> 상세 페이지 구분값
    setCourierCompany, // 주문 메인 택배사명 변경 함수
    editCourierCompany, // 주문 상세 택배사명
    setEditCourierCompany, // 주문 상세 택배사명 변경 함수
    setCourierCode, // (주문 메인, 상세 공통) 운송장번호 변경 함수
    widthMd,
    width,
  } = props;

  const [selectedText, setSelectedText] = useState("택배사 전체");// 선택된 텍스트 (버튼에 보여주기 위해)
  const [show, setShow] = useState(false); // 목록 visible
  const selectBoxRef = useRef(); // 외부 클릭 컨트롤

  // 목록에서 택배사 클릭 시
  const itemClick = (item) => {
    setShow(false);
    if (gubun === "write") {
      setCourierCompany(item.name);
    } else {
      setEditCourierCompany(item.name);
    }
    setCourierCode(item.id);
    setSelectedText(item.name);
  }

  // 디폴트 문자 추가
  useEffect(() => {
    const obj = {
      id: 0, name: "택배사 전체",
    }
    data.unshift(obj);
  }, [data]);

  // 외부 클릭 컨트롤
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (selectBoxRef.current && !selectBoxRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <SelectContainer width={width} widthMd={widthMd} ref={selectBoxRef}>
        <SelectBtn
          width={"100%"}
          show={show}
          onClick={() => {
            setShow((state) => !state);
          }}
        >
          {editCourierCompany ? editCourierCompany : selectedText}
        </SelectBtn>
        {show && (
          <SelectWrap width={"100%"}>
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              {data?.map((item, idx) => (
                <SearchList key={idx} value={item.id} onClick={() => itemClick(item)}>
                  <li>
                    <span>{item.name}</span>
                  </li>
                </SearchList>
              ))}
            </div>
          </SelectWrap>
        )}
      </SelectContainer>
    </>
  );
}
