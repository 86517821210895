import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Btn, Wrapper, Container, ContentContainer, FlexBox, Text, WhiteBox, Title } from "../../Assets/css/commonVer2";
import Header from "../../Components/Header/HeaderVer2";
import Slider from "../../Components/Slider/Slider";
import SliderBtn from "../../Components/Slider/SliderBtn";
import img_cate1 from "../../Assets/images/img_cate01.svg";
import img_cate2 from "../../Assets/images/img_cate02.svg";
import img_cate3 from "../../Assets/images/img_cate03.svg";
import img_cate4 from "../../Assets/images/img_cate04.svg";
import img_cate5 from "../../Assets/images/img_cate05.svg";
import img_cate6 from "../../Assets/images/img_cate06.svg";
import img_cate7 from "../../Assets/images/img_cate07.svg";
import img_cate8 from "../../Assets/images/img_cate08.svg";
import img_cate9 from "../../Assets/images/img_cate09.svg";
import img_cate10 from "../../Assets/images/img_cate10.svg";
import img_cate11 from "../../Assets/images/img_cate11.svg";
import img_cate12 from "../../Assets/images/img_cate12.svg";
import img_cate13 from "../../Assets/images/img_cate13.svg";
import img_cate14 from "../../Assets/images/img_cate14.svg";
import img_cate15 from "../../Assets/images/img_cate15.svg";
import img_cate16 from "../../Assets/images/img_cate16.svg";
import img_cate17 from "../../Assets/images/img_cate17.svg";
import img_cate18 from "../../Assets/images/img_cate18.svg";
import img_cate19 from "../../Assets/images/img_cate19.svg";
import img_cate20 from "../../Assets/images/img_cate20.svg";
import img_cate21 from "../../Assets/images/img_cate21.svg";
import img_cate22 from "../../Assets/images/img_cate22.svg";
import img_cate23 from "../../Assets/images/img_cate23.svg";
import img_cate24 from "../../Assets/images/img_cate24.svg";
import img_cate25 from "../../Assets/images/img_cate25.svg";
import img_cate26 from "../../Assets/images/img_cate26.svg";
import img_cate27 from "../../Assets/images/img_cate27.svg";
import img_cate28 from "../../Assets/images/img_cate28.svg";
import img_next from "../../Assets/images/ico_next_24.svg";
import API from "../../Utiles/API";
import { AddComma } from "../../Utiles/Custom";
import Authority from "../../Components/Modal/Authority";
import DataLoading from "../../Components/DataLoading";

// 상품관리 > 상품 메인

function Deposit() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const franchise_id = sessionStorage.getItem("franchise_id");
  const [deposit, setDeposit] = useState({ name: "", value: "" });
  const [cateData, setCateData] = useState([]);
  const [proposalData, setProposalData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [dataLoading, setDataLoading] = useState(false);
  const [imageDataLoading, setImageDataLoading] = useState(false);

  // 덕구 추천상품 API
  const getProductList = async () => {
    try {
      setImageDataLoading(true);
      let api_link = `/storeowner/product-management?page=1&unit=10&block=10&order_type=amount&proposal=true`;
      await API.get(api_link).then((res) => {
        if (res.data.status) {
          setImageDataLoading(false);
          setProposalData(res.data.results.products);
        } else {
          setImageDataLoading(false);
        }
      });
    } catch (error) {
      setImageDataLoading(false);
    }
  };

  // API
  const getDepositStatus = async () => {
    try {
      setDataLoading(true);
      await API.get(`/storeowner/deposit-status/${franchise_id}`).then((res) => {
        if (res.data.status) {
          setDataLoading(false);
          const frcs = res.data.result?.deposit_status[0];
          const frcsName = frcs?.franchise_name;
          const frcsPrice = frcs["users.holding_deposit"];
          const cateList = res.data.result?.main_sales_product_category;
          const cateFilterList = cateList?.filter((e, i) => i < 10);
          setDeposit({ ...deposit, name: frcsName, value: frcsPrice });
          setCateData(cateFilterList);
        } else {
          setDataLoading(false);
        }
      });
    } catch (error) {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    const winW = window.innerWidth;
    winW > 1024 ? setLimit(10) : setLimit(6);
  }, []);

  useEffect(() => {
    getDepositStatus();
    getProductList();
  }, [limit]);
  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          {dataLoading || imageDataLoading ? <DataLoading /> : null}
          {isMobile && <Header title={"상품 메인"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {/* 웹 - 타이틀, 예치금 */}
                {!isMobile && (
                  <FlexBox justify={"starts"} style={{ flex: 1 }}>
                    <Title size={"26px"} color={"#1E1E1E"} weight={"700"} style={{ letterSpacing: "-0.52px" }}>
                      {deposit.name}의 예치금
                    </Title>
                    <Text
                      color={"#FF4A4A"}
                      size={"16px"}
                      weight={"700"}
                      padding={"10px 29px 10px 27px"}
                      background={"rgba(255, 116, 64, 0.10)"}
                      marginMd={"0 0 0 10px"}
                      radius={"6px"}
                      letterSpacing={"-0.32px"}
                      lineHeight={"normal"}
                    >
                      {AddComma(deposit.value)}원
                    </Text>
                    <FlexBox style={{ flex: 1 }} />
                    <Btn
                      color={"#fff"}
                      weight={"700"}
                      size="14px"
                      radius={"6px"}
                      padding={"9px 19px"}
                      border={"1px solid #FF4A4A"}
                      background={"#FF4A4A"}
                      className={"d-none d-md-block"}
                      onClick={() =>
                        navigate("/goods/deposit/view", {
                          state: { fid: franchise_id, fname: deposit.name },
                        })
                      }
                    >
                      예치금 내역보기 →
                    </Btn>
                  </FlexBox>
                )}
                {/* 모바일 - 타이틀, 예치금 */}
                {isMobile && (
                  <WhiteBox
                    margin="0 0"
                    onClick={() =>
                      navigate("/goods/deposit/view", {
                        state: { fid: franchise_id, fname: deposit.name },
                      })
                    }
                  >
                    <FlexBox justify={"starts"} style={{ flex: 1 }}>
                      <Title size={"14px"} color={"#1E1E1E"} weight={"700"} style={{ letterSpacing: "-0.28px" }}>
                        {deposit.name}의 예치금
                      </Title>
                      <FlexBox style={{ flex: 1 }} />
                      <img src={img_next}></img>
                    </FlexBox>
                    <Text
                      color={"#FF4A4A"}
                      size={"16px"}
                      weight={"700"}
                      padding={"10px 29px 10px 27px"}
                      background={"rgba(255, 116, 64, 0.10)"}
                      margin={"11px 0 0 0 "}
                      radius={"6px"}
                      letterSpacing={"-0.32px"}
                      lineHeight={"normal"}
                    >
                      {AddComma(deposit.value)}원
                    </Text>
                  </WhiteBox>
                )}
                {/* 공통 - 카테고리 */}
                <WhiteBox padding="12px 16px" paddingMd="36px 30px">
                  <Title size={"14px"} color={"#1E1E1E"} weight={"700"} sizeMd={"18px"}>
                    주요 판매 상품 카테고리
                  </Title>
                  <CateList
                    justify={"flex-start"}
                    gap={"12px 10px"}
                    gapMd={"20px"}
                    wrap={"wrap"}
                    wrapMd={"flex-wrap"}
                    align={"flex-start"}
                    style={{ marginTop: "16px" }}
                  >
                    <button
                      onClick={() =>
                        navigate("/goods/all", {
                          state: { idx: 100 }, // 신상품 idx 100
                        })
                      }
                    >
                      <img src={img_cate28} />
                      <Text
                        color={"#2d2d2d"}
                        padding={"6px 0 0"}
                        paddingMd={"10px 0 0"}
                        size={"10px"}
                        sizeMd={"14px"}
                        weight={"700"}
                        weightMd={"700"}
                      >
                        신상품
                      </Text>
                    </button>
                    {cateData?.map((e, i) => {
                      const imgSrc =
                        e.idx === 1
                          ? img_cate1
                          : e.idx === 2
                            ? img_cate2
                            : e.idx === 3
                              ? img_cate3
                              : e.idx === 4
                                ? img_cate4
                                : e.idx === 5
                                  ? img_cate5
                                  : e.idx === 6
                                    ? img_cate6
                                    : e.idx === 7
                                      ? img_cate7
                                      : e.idx === 8
                                        ? img_cate8
                                        : e.idx === 9 //완구일때도 놀이용품 아이콘으로 뜨도록
                                          ? img_cate9
                                          : e.idx === 10
                                            ? img_cate10
                                            : e.idx === 11
                                              ? img_cate11
                                              : e.idx === 12
                                                ? img_cate12
                                                : e.idx === 13
                                                  ? img_cate13
                                                  : e.idx === 14
                                                    ? img_cate14
                                                    : e.idx === 15
                                                      ? img_cate15
                                                      : e.idx === 16
                                                        ? img_cate16
                                                        : e.idx === 17
                                                          ? img_cate17
                                                          : e.idx === 18
                                                            ? img_cate18
                                                            : e.idx === 19
                                                              ? img_cate19
                                                              : e.idx === 20
                                                                ? img_cate20
                                                                : e.idx === 21
                                                                  ? img_cate21
                                                                  : e.idx === 22
                                                                    ? img_cate22
                                                                    : e.idx === 23
                                                                      ? img_cate23
                                                                      : e.idx === 24
                                                                        ? img_cate24
                                                                        : e.idx === 25
                                                                          ? img_cate25
                                                                          : e.idx === 26
                                                                            ? img_cate26
                                                                            : e.idx === 27
                                                                              ? img_cate27
                                                                              : img_cate16;
                      return (
                        <button
                          key={i}
                          onClick={
                            () =>
                              navigate("/goods/all", {
                                state: { idx: e.idx, category1st_id: 1 },
                              }) // idx가 2차, category1st_id가 1차 카테고리
                          }
                        >
                          <img src={imgSrc} />
                          <Text
                            color={"#2d2d2d"}
                            padding={"6px 0 0"}
                            paddingMd={"10px 0 0"}
                            size={"10px"}
                            sizeMd={"14px"}
                            weight={"700"}
                            weightMd={"700"}
                          >
                            {e.name}
                          </Text>
                        </button>
                      );
                    })}
                  </CateList>
                </WhiteBox>
                {/* 공통 - 덕구 추천 상품 */}
                <FlexBox justify={"starts"} style={{ flex: 1 }} marginMd="33px 0 20px" margin="23px 0 6px">
                  <Title
                    size={"18px"}
                    sizeMd={"26px"}
                    color={"#1E1E1E"}
                    weight={"700"}
                    style={{ letterSpacing: "-0.52px" }}
                  >
                    덕구가 추천하는 상품 😎
                  </Title>

                  <FlexBox style={{ flex: 1 }} />
                  <SliderBtn prevClass={"sellPrev2"} nextClass={"sellNext2"} />
                </FlexBox>
                <Slider
                  naviPrev={"sellPrev2"}
                  naviNext={"sellNext2"}
                  contents={proposalData}
                  styles={{ border: true, align: "left" }}
                  breaks={{
                    0: {
                      slidesPerView: isMobile ? 2.5 : 4,
                      spaceBetween: isMobile ? 10 : 26,
                    },
                  }}
                  link={"/goods/view"}
                />
              </Container>
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const CateList = styled(FlexBox)`
  a,
  button {
    max-width: 160px;
    flex: 1;
    img {
      width: 100%;
    }
  }
  @media all and (max-width: 1024px) {
    a,
    button {
      flex: auto;
      max-width: calc(24% - 5.6px);
      width: calc(24% - 5.6px);
    }
  }
`;

export default Deposit;
