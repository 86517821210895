import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FindPw from "./FindPwVer2";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/images/img_logo.svg";
import API from "../../Utiles/API";
import InfoModal from "../../Components/Modal/InfoModal";
import { Btn, FlexBox } from "../../Assets/css/commonVer2";
import { Text } from "../../Assets/css/common";
import ico_error from "../../Assets/images/ico_error.png";
import { useMediaQuery } from "react-responsive";

function Login() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const [check, setCheck] = useState("");
  const [modal, setModal] = useState(false);
  const [w, setW] = useState("");
  const [values, setValues] = useState({
    user_id: "",
    password: "",
  });

  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");

  const onLogin = async (e) => {
    e.preventDefault();

    try {
      const res = await API.post(`/users/signin`, {
        user_id: values.user_id,
        password: values.password,
      });

      // if (res.data.msg === "비밀번호가 일치하지 않습니다") {
      //   alert(res.data.msg);
      //   return false;
      // }

      if (res.data.status) {
        sessionStorage.setItem("gubun", res.data.gubun);
        sessionStorage.setItem("user_id", res.data.idx);
        sessionStorage.setItem("franchise_id", res.data.franchise_id);

        localStorage.setItem("accessToken", res.data.accessToken);
        localStorage.setItem("refreshToken", res.data.refreshToken);

        if (check === true) {
          localStorage.setItem("user_id", res.data.idx);
          localStorage.setItem("franchise_id", res.data.franchise_id);
          localStorage.setItem("gubun", res.data.gubun);
        }
        // setModal(true);
        // setW("u");
        if (res.data.first_login_check === 0) {
          setModal(true);
          setW("u");
        } else {
          if (res.data.gubun === "storeonwer") {
            if (isMobile) {
              navigate("/sales", {}, { replace: true });
            } else {
              navigate("/main", {}, { replace: true });
            }
          } else if (res.data.gubun === "admin") {
            navigate("/franchisee", {}, { replace: true });
          } else {
            setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
            setTextModal(true);
          }
        }
      } else {
        setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
        // setTextModal(true);
      }
    } catch (error) {
      setTextMsg("E01\n잠시후 다시 시도해주세요.");
      setTextModal(true);
    }
  };

  const onCheck = () => {
    if (check === "") {
      setCheck(true);
    } else {
      setCheck("");
    }
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const onModal = () => {
    return <FindPw w={w} setW={setW} setModal={setModal} userId={values.user_id}></FindPw>;
  };

  const RNListener = () => {
    /** react native 환경에서만 가능 */
    const listener = (event) => {
      const { data, type } = JSON.parse(event.data);
      if (type === "TOKEN") {
        // type이 TOKEN이기 때문에 이곳에 콘솔이 찍히게 됩니다.
      } else if (type === "NOTIFICATION") {
      }
    };

    if (window.ReactNativeWebView) {
      /** android */
      document.addEventListener("message", listener);
      /** ios */
      window.addEventListener("message", listener);
    } else {
      // 모바일이 아니라면 모바일 아님을 alert로 띄웁니다.
      // alert({ message: ERROR_TYPES.notMobile });
    }
  };

  useEffect(() => {
    RNListener();

    if (localStorage.getItem("gubun") === "storeonwer" || sessionStorage.getItem("gubun") === "storeonwer") {
      navigate("/main", {}, { replace: true });
    } else if (localStorage.getItem("gubun") === "admin" || sessionStorage.getItem("gubun") === "admin") {
      navigate("/franchisee", {}, { replace: true });
    }
  }, []);

  return (
    <Background>
      <Box>
        <LoginInner>
          <form onSubmit={onLogin}>
            <LogoBox>
              <Logo src={logo} />
            </LogoBox>
            <Text size={"20px"} sizeMd={"26px"} margin={"0 0 8px"} marginMd={"0 0 10px"} weight={"700"} color={"#000"}>
              로그인
            </Text>
            <Text family={"Montserrat"} color={"#000"} size={"12px"} sizeMd={"14px"}>
              Log in
            </Text>

            <FlexBox direction={"column"} align={"flex-start"} margin={"0 0 10px"} marginMd={"0 0 16px"}>
              <Text
                family={"Montserrat"}
                size={"12px"}
                sizeMd={"14px"}
                margin={"0 0 8px"}
                marginMd={"0 0 10px"}
                weight={"700"}
                color={"#000"}
              >
                ID
              </Text>
              <InputBox
                type={"text"}
                name={"user_id"}
                value={values.user_id}
                onChange={handleChange}
                placeholder={"아이디 입력"}
                isError={textMsg === "존재하지 않는 아이디입니다"}
              />
              {textMsg === "존재하지 않는 아이디입니다" && (
                <FlexBox margin={"8px 0 0"} marginMd={"8px 0 0"} gap={"4px"}>
                  <ErrorImg src={ico_error} />
                  <Text size={"12px"} color={"#F63958"}>
                    아이디를 다시 입력해주세요.
                  </Text>
                </FlexBox>
              )}
            </FlexBox>
            <FlexBox direction={"column"} align={"flex-start"} margin={"0 0 10px"} marginMd={"0 0 16px"}>
              <Text
                family={"Montserrat"}
                size={"12px"}
                sizeMd={"14px"}
                margin={"0 0 8px"}
                marginMd={"0 0 10px"}
                weight={"700"}
                color={"#000"}
              >
                Password
              </Text>
              <InputBox
                type={"password"}
                name={"password"}
                value={values.password}
                onChange={handleChange}
                placeholder={"비밀번호 입력"}
                isError={textMsg === "비밀번호가 일치하지 않습니다"}
              />
              {textMsg === "비밀번호가 일치하지 않습니다" && (
                <FlexBox margin={"8px 0 0"} marginMd={"8px 0 0"} gap={"4px"}>
                  <ErrorImg src={ico_error} />
                  <Text size={"12px"} color={"#F63958"}>
                    비밀번호를 다시 입력해주세요.
                  </Text>
                </FlexBox>
              )}
            </FlexBox>
            <FlexBox justify={"space-between"} align={"center"} margin={"0 0 40px"} marginMd={"0 0 60px"}>
              <FlexBox justify={"start"} align={"center"}>
                <CheckBox type={"checkbox"} checked={check} onClick={(props) => onCheck()}>
                  <Icon viewBox="0 0 24 24">
                    <polyline points="19 7 10 17 5 12" />
                  </Icon>
                </CheckBox>
                <Text padding={"0 0 0 10px"} onClick={(props) => onCheck()} style={{ cursor: "pointer" }}>
                  자동 로그인
                </Text>
              </FlexBox>
              <Text align={"right"} onClick={(props) => setModal(true)} style={{ cursor: "pointer" }}>
                비밀번호 찾기
              </Text>
            </FlexBox>

            <Btn
              type={"submit"}
              color={"#fff"}
              background={"#ff4a4a"}
              radius={"40px"}
              size={"18px"}
              sizeMd={"20px"}
              padding={"13px"}
              paddingMd={"18px"}
              width={"100%"}
              weight={"700"}
            >
              로그인
            </Btn>
          </form>
        </LoginInner>
      </Box>
      {modal ? onModal() : ""}
      <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
    </Background>
  );
}

const CheckBox = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1px solid ${(props) => (props.checked ? "#2dd057" : "#c7c7c7")};
  background-color: ${(props) => (props.checked ? "#2dd057" : "#fff")};
  cursor: pointer;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
  border-radius: 50%;
`;
// 로그인
const Background = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 1024px) {
    // min-width: 360px;
    padding: 0 18px;
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 65px 109px 108px;
  border-radius: 10px;
  width: 558px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.12);
  @media all and (max-width: 1024px) {
    width: 100%;
    padding: 36px 20px 30px;
  }
`;

const LoginInner = styled.div`
  width: 100%;
`;

const LogoBox = styled.div`
  width: 175px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto auto 40px;
`;

const Logo = styled.img`
  width: 100%;
`;

const ErrorImg = styled.img`
  width: 12px;
  height: 12px;
`;

const InputBox = styled.input.attrs({
  placeholderColor: "#C7C7C7",
})`
  width: 100%;
  height: 60px;
  border-radius: 6px;
  border: ${(props) => (props.isError ? "1px solid #F63958" : "1px solid #C7C7C7")};
  outline: none;
  padding: 0 16px;
  font-size: 16px;
  color: 11eE1E;

  &:focus {
    border: ${(props) => (props.isError ? "1px solid #F63958" : props.border || "1px solid #666666")};
  }
  @media all and (max-width: 1024px) {
    height: 48px;
    font-size: 14px;
  }
`;

export default Login;
