import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import {
  Btn,
  Container,
  TextInput,
  Wrapper,
  FlexBox,
  Text,
  Title,
  WhiteBox,
  HeaderContainer,
  ContentContainer,
} from "../../Assets/css/commonVer2";
import HeaderSub from "../../Components/Header/HeaderSub";
import API from "../../Utiles/API";
import Header from "../../Components/Header/HeaderVer2";
import InfoModal from "../../Components/Modal/InfoModal";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";

// 마이페이지 > 내정보수정

function MypageEdit() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [Franchise_id] = useState(sessionStorage.getItem("user_id")); //user_idx
  const [data, setData] = useState();
  const [saveModal, setSaveModal] = useState(false);
  const [moveToBackModal, setMoveToBackModal] = useState(false);
  const [values, setValues] = useState({
    password: "",
    repassword: "",
  });

  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");

  const Myinfo = async () => {
    try {
      const res = await API.get(`/storeowner/mypage/${Franchise_id}}`);
      if (res.data.status) {
        setData(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = (e) => {
    API.put(
      `/users/password`,
      {
        user_id: Franchise_id || "",
        password: values.password,
      },
      { header: { "content-type": "multipart/form-data" } },
    )
      .then(function (res) {
        if (res.data.status) {
          setTextMsg("등록이 완료되었습니다.");
          setMoveToBackModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(function (error) {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const PasswordCheck = () => {
    if (values.password !== values.repassword) {
      setTextMsg("변경할 비밀번호를 확인해주세요.");
      setTextModal(true);
    } else if (values.password === "" || values.repassword === "") {
      setTextMsg("변경할 비밀번호를 확인해주세요.");
      setTextModal(true);
    } else {
      setSaveModal(true);
    }
  };

  useEffect(() => {
    Myinfo();
  }, []);

  return (
    <>
      <HeaderSub title={"내 정보"} />
      <ContentContainer>
        <Wrapper type={1}>
          <Container>
            {!isMobile && (
              <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                내 정보
              </Title>
            )}
            <WhiteBox margin={"0 0"}>
              <FlexBox
                justify={"start"}
                align={"start"}
                margin={"0 0 20px"}
                marginMd={"0 0 30px"}
                wrap={"wrap"}
                wrapMd={"unset"}
                gap={"20px"}
                gapMd={"26px"}
              >
                <Contents>
                  <Text
                    align={"left"}
                    weight={"700"}
                    color={"#1e1e1e"}
                    size={"14px"}
                    sizeMd={"16px"}
                    letterSpacing={"-0.32px"}
                    margin={"0 0 8px"}
                    marginMd={"0 0 10px"}
                  >
                    가맹점
                  </Text>
                  <TextInput
                    style={{ width: "100%" }}
                    type={"text"}
                    disabled
                    value={data?.franchise.franchise_address}
                  />
                </Contents>
                <Contents></Contents>
              </FlexBox>
              <FlexBox
                justify={"start"}
                align={"start"}
                margin={"0 0 20px"}
                marginMd={"0 0 30px"}
                wrap={"wrap"}
                wrapMd={"unset"}
                gap={"20px"}
                gapMd={"26px"}
              >
                <Contents>
                  <Text
                    align={"left"}
                    weight={"700"}
                    color={"#1e1e1e"}
                    size={"14px"}
                    sizeMd={"16px"}
                    letterSpacing={"-0.32px"}
                    margin={"0 0 8px"}
                    marginMd={"0 0 10px"}
                  >
                    아이디
                  </Text>
                  <TextInput style={{ width: "100%" }} type={"text"} value={data ? data.user_id : ""} disabled />
                </Contents>

                <Contents></Contents>
              </FlexBox>
              <FlexBox
                justify={"start"}
                align={"start"}
                margin={"0 0 20px"}
                marginMd={"0 0 30px"}
                wrap={"wrap"}
                wrapMd={"unset"}
                gap={"20px"}
                gapMd={"26px"}
              >
                <Contents>
                  <Text
                    align={"left"}
                    weight={"700"}
                    color={"#1e1e1e"}
                    size={"14px"}
                    sizeMd={"16px"}
                    letterSpacing={"-0.32px"}
                    margin={"0 0 8px"}
                    marginMd={"0 0 10px"}
                  >
                    주소
                  </Text>
                  <TextInput
                    style={{ width: "100%" }}
                    type={"text"}
                    value={data?.franchise.franchise_address}
                    disabled
                  />
                </Contents>

                <Contents></Contents>
              </FlexBox>

              <FlexBox
                justify={"start"}
                align={"end"}
                margin={"0 0 20px"}
                marginMd={"0 0 30px"}
                wrap={"wrap"}
                wrapMd={"unset"}
                gap={"20px"}
                gapMd={"26px"}
              >
                <Contents>
                  <Text
                    align={"left"}
                    weight={"700"}
                    color={"#1e1e1e"}
                    size={"14px"}
                    sizeMd={"16px"}
                    letterSpacing={"-0.32px"}
                    margin={"0 0 8px"}
                    marginMd={"0 0 10px"}
                  >
                    비밀번호 변경
                  </Text>
                  <TextInput
                    style={{ width: "100%" }}
                    type={"password"}
                    placeholder={"비밀번호를 입력해주세요."}
                    name={"password"}
                    onChange={handleChange}
                    value={values.password}
                  />
                </Contents>
                <Contents>
                  <TextInput
                    style={{ width: "100%" }}
                    type={"password"}
                    placeholder={"비밀번호를 재입력해주세요."}
                    name={"repassword"}
                    onChange={handleChange}
                    value={values.repassword}
                  />
                </Contents>
              </FlexBox>
            </WhiteBox>

            <FlexBox justify={"end"} margin={"20px 0 0"}>
              <Btn
                width={"100%"}
                widthMd={"90px"}
                color={"#fff"}
                background={"#FF4A4A"}
                size={"14px"}
                weight={"700"}
                padding={"8px 18px"}
                lineHeight={"22px"}
                onClick={() => PasswordCheck()}
              >
                수 정
              </Btn>
            </FlexBox>
          </Container>
        </Wrapper>
      </ContentContainer>

      <ConfirmModal
        modal={saveModal}
        setModal={setSaveModal}
        onSubmit={() => onSubmit()}
        description={"비밀번호를 변경하시겠습니까?"}
      />
      <InfoModal modal={moveToBackModal} setModal={setMoveToBackModal} description={textMsg} mode={"goBack"} />
      <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
    </>
  );
}

const Contents = styled.div`
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  background-color: ${(props) => props.background || ""};
  width: ${(props) => props.width || "100%"};
  border-radius: ${(props) => props.radius || "8px"};
`;

export default MypageEdit;
