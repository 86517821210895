import React, { useEffect, useState, useReducer } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { utils, writeFile } from "xlsx";

import {
  ContentContainer,
  Wrapper,
  Container,
  WhiteBox,
  FlexBox,
  Title,
  Text,
  Btn,
  TableWrap,
  Th,
  Td,
} from "../../Assets/css/commonVer2";
import Header from "../../Components/Header/HeaderVer2";
import Authority from "../../Components/Modal/Authority";
import InfoModal from "../../Components/Modal/InfoModal";
import ShopDepositModal from "./Components/ShopDepositModal";
import HandleBack from "../../Components/HandleBack";
import BasicSelect from "../../Components/Select/BasicSelect";
import Pagination from "../../Components/Paging/Pagination";
import { AddComma } from "../../Utiles/Custom";
import { UNIT_10_TO_50_LIST, SHOP_TAKEBACK_STATUS_LIST } from "../../Utiles/ListData";
import { searchToObject } from "../../Utiles/searchToObject";
import { dateToString } from "../../Utiles/dateToString";
import { delayCall } from "../../Utiles/delayCall";
import { getShopTakeBackProducts, depositShopTakeback } from "../../Utiles";
import { SearchShopTakeBackReducer } from "../../Store";
import ShopTakeBackSearchBar from "./Components/ShopTakeBackSearchBar";

// 쇼핑몰 > 반품 관리

function ShopTakeBack() {
  const location = useLocation();
  const navigate = useNavigate();
  const { handlePopstate } = HandleBack();
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  // 모달
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [depositInfoModal, setDepositInfoModal] = useState(false);

  // 입금처리 시 필요한 데이터(검수완료 -> 입금완료)
  const [putDepositData, setPutDepositData] = useState({});
  // 현금 환불 안내 모달창에서 보여줄 데이터
  const [depositInfoData, setDepositInfoData] = useState({});

  // 페이징
  const [pagination, setPagination] = useState("");

  // 날짜 제어 state
  const date = new Date();
  const year = date.getFullYear(); // 년
  const month = date.getMonth(); // 월
  const day = date.getDate(); // 일
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);

  // 검수 상태 state
  const [searchReview, setSearchReview] = useState("");

  // 반품 번호 입력 받을 state
  const [inputTakeBackId, setInputTakeBackId] = useState("");

  // 반품 데이터
  const [totalTakeBackPrice, setTotalTakeBackPrice] = useState(0);
  const [takeBackList, setTakeBackList] = useState([]);

  // 반품 상세를 다녀온 경우 저장 해놓은 파라미터 정보
  const parsedShopTakeBackPageState = sessionStorage.getItem("shopTakeBackPageState")
    ? JSON.parse(sessionStorage.getItem("shopTakeBackPageState"))
    : {};

  // 검색 객체
  const [searchState, dispatchSearchState] = useReducer(SearchShopTakeBackReducer, {
    start_date: dateToString(new Date(year, month, day - 7)),
    end_date: dateToString(new Date()),
    unit: 10,
    page: parsedShopTakeBackPageState ? (parsedShopTakeBackPageState?.page ? parsedShopTakeBackPageState?.page : 1) : 1,
    search: true,
    ...searchToObject(),
  });

  const [pageSize, setPageSize] = useState(
    parsedShopTakeBackPageState ? (parsedShopTakeBackPageState?.unit ? parsedShopTakeBackPageState?.unit : 10) : 10,
  );

  // 반품상태값 선택된 텍스트 (셀렉박스에 보여주기 위해)
  const [selectedText, setSelectedText] = useState(
    parsedShopTakeBackPageState
      ? parsedShopTakeBackPageState?.selectedText
        ? parsedShopTakeBackPageState?.selectedText
        : "상태전체"
      : "상태전체",
  );

  // 반품 상세를 갈 때 sessionStorage에 저장할 객체
  const shopTakeBackPageState = {
    unit: searchState.unit,
    page: searchState.page,
    selectedText: selectedText,
  };

  // API
  // 1. 반품 리스트 조회
  function callGetProducts() {
    setStartDate(new Date(searchState.start_date));
    setEndDate(new Date(searchState.end_date));

    getShopTakeBackProducts(searchState)
      .then((res) => {
        if (res.status) {
          setPagination(res.pagination);
          setTakeBackList(res.results);
          setTotalTakeBackPrice(res.total_price);
          dispatchSearchState({ type: "CHANGE_SEARCH", value: false });
        }
      })
      .catch(() => {
        dispatchSearchState({ type: "CHANGE_SEARCH", value: false });
      });
  }

  // 2. 입금처리 (검수완료 -> 입금완료)
  function putDeposit() {
    depositShopTakeback(putDepositData.idx, putDepositData.cash, putDepositData.point)
      .then((res) => {
        if (res.status) {
          setTextMsg("입금완료 상태로 변경되었습니다.");
          window.location.replace("/shoppingmall/takeback"); // 새로고침
        } else {
          setTextMsg(res.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  }

  // 검색 함수
  function searchTakeBack() {
    if (inputTakeBackId && searchReview) {
      dispatchSearchState({
        type: "SEARCH_BY_TAKEBACK_ID_AND_REVIEW",
        idValue: inputTakeBackId,
        reviewValue: searchReview,
      });
    } else if (inputTakeBackId) {
      dispatchSearchState({ type: "SEARCH_BY_TAKEBACK_ID", value: inputTakeBackId });
    } else if (searchReview) {
      dispatchSearchState({ type: "SEARCH_BY_REVIEW", value: searchReview });
    } else if (!inputTakeBackId && !searchReview) {
      dispatchSearchState({ type: "DELETE_IDS" });
    }
    dispatchSearchState({ type: "CHANGE_SEARCH", value: true });
    dispatchSearchState({ type: "CHANGE_NOW_PAGE", value: 1 });
  }

  // 입금대기 버튼 클릭
  const onClickChangeStatusBtn = (clickedItem) => {
    // 1. 클릭한 반품 건의 반품번호, 현금, 포인트 저장(서버로 보낼 데이터)
    setPutDepositData({
      idx: clickedItem.idx,
      cash: clickedItem.cash_point_final_amount.cash,
      point: clickedItem.cash_point_final_amount.point,
    });

    // 2. 클릭한 반품 건의 은행명, 예금주, 계좌번호, 현금 저장(모달에서 보여줄 데이터)
    setDepositInfoData({
      bank: clickedItem.bank,
      account_holder: clickedItem.account_holder,
      account_number: clickedItem.account_number,
      cash: clickedItem.cash_point_final_amount.cash,
    });

    // 3. 현금 환불 안내 모달 노출
    setDepositInfoModal(true);
  };

  // 입금처리 최종 확인
  const onSubmit = () => {
    setDepositInfoModal(false);
    putDeposit();
  };

  // 반품 상세로 이동
  function onClickItem(idx) {
    navigate(`/shoppingmall/takeback/${idx}`);
    let jsonString = JSON.stringify(shopTakeBackPageState);
    sessionStorage.setItem("shopTakeBackPageState", jsonString);
  }

  const movePage = (e) => {
    dispatchSearchState({ type: "CHANGE_NOW_PAGE", value: parseInt(e) });
  };

  const onExcelDown = async () => {
    if (takeBackList?.length > 0) {
      getShopTakeBackProducts({ ...searchState, unit: 99999999, page: 1 }).then((res) => {
        if (res.status) {
          const data = [];
          const excelTableHead = [
            ["반품번호", "고객명", "품목수량", "총금액", "최종반품금액", "현금", "포인트", "주문일", "반품상태"],
          ];
          const wb = utils.book_new();
          const ws = utils.json_to_sheet([]);

          utils.sheet_add_aoa(ws, excelTableHead);

          res.results.forEach((item) => {
            data.push({
              idx: `${item.idx}`,
              order_user_name: `${item.order_user_name}`,
              take_back_count: `${item.take_back_count}`,
              expected_take_back_amount: `${item.expected_take_back_amount}원`,
              final_take_back_amount: `${item.final_take_back_amount}원`,
              cash_point_final_amount_cash: `${item.cash_point_final_amount.cash}원`,
              cash_point_final_amount_point: `${item.cash_point_final_amount.point}원`,
              createdAt: `${item.createdAt}`,
              status: `${item.deletedAt !== null ? "반품취소" : SHOP_TAKEBACK_STATUS_LIST.find((i) => i.value === item.status)?.text || "-"}`,
            });
          });

          utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
          utils.book_append_sheet(wb, ws, "Report");

          writeFile(wb, `반품관리_${dateToString(startDate)}~${dateToString(endDate)}.xlsx`);
        } else {
          setTextMsg(res.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      });
    } else {
      setTextMsg("다운받을 데이터가 없습니다.");
      setTextModal(true);
    }
  };

  useEffect(() => {
    dispatchSearchState({ type: "CHANGE_UNIT", value: pageSize });
  }, [pageSize]);

  useEffect(() => {
    dispatchSearchState({ type: "CHANGE_NOW_PAGE", value: searchState.page });
  }, [searchState.page]);

  // 현재 페이지에 왔을 때 searchState 에 맞는 데이터 가져옴
  useEffect(() => {
    if (searchState.search) {
      const params = {};
      Object.entries(searchState).forEach((item) => (params[item[0]] = item[1]));
      navigate({ pathname: "/shoppingmall/takeback", search: `?${createSearchParams(params)}` }, { replace: true });
    }
  }, [searchState]);

  // 페이지 최초 진입 시 실행
  useEffect(() => {
    delayCall(callGetProducts);
  }, [location]);

  useEffect(() => {
    sessionStorage.removeItem("shopTakeBackPageState");
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {isMobile && <Header title={"반품 관리"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    반품 관리 👩🏻‍💻
                  </Title>
                )}
                <WhiteBox margin={"0 0"}>
                  <ShopTakeBackSearchBar
                    dispatchSearchState={dispatchSearchState}
                    searchTakeBack={searchTakeBack}
                    selectedText={selectedText}
                    setSelectedText={setSelectedText}
                    setSearchReview={setSearchReview}
                    inputTakeBackId={inputTakeBackId}
                    setInputTakeBackId={setInputTakeBackId}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    startDate={startDate}
                    endDate={endDate}
                    inputPlaceholder="반품 번호를 입력해 주세요"
                  />
                  <FlexBox
                    justify={"space-between"}
                    gap={"10px"}
                    margin={"20px 0 10px"}
                    marginMd={"20px 0 30px"}
                    wrap={"wrap"}
                    wrapMd={"no-wrap"}
                  >
                    <FlexBox gap={"10px"} width={"100%"} widthMd={"auto"}>
                      <Text
                        width={"100%"}
                        widthMd={"unset"}
                        color={"#FF4A4A"}
                        size={"16px"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        style={{
                          flexShrink: 0,
                        }}
                      >
                        최종 반품 금액 합계 :{" "}
                        <span style={{ fontWeight: "700" }}>{AddComma(totalTakeBackPrice)}원</span>
                      </Text>
                      {!isMobile && (
                        <BasicSelect
                          width={"130px"}
                          data={UNIT_10_TO_50_LIST}
                          selectedValue={pageSize}
                          setSelectedValue={setPageSize}
                          canSelectAll={false}
                        />
                      )}
                    </FlexBox>
                    {!isMobile && (
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        border={"1px solid #666"}
                        color={"#666"}
                        hoverBackground={"#C7C7C7"}
                        hoverColor={"#fff"}
                        hoverBorder={"transparent"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        margin={"0 0 0 10px"}
                        onClick={onExcelDown}
                      >
                        엑셀 다운
                      </Btn>
                    )}
                  </FlexBox>
                  <TableWrap>
                    <table style={{ width: "100%", minWidth: "1024px" }}>
                      <colgroup>
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "12%" }} />
                        <col />
                        <col style={{ width: "14%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>반품번호</Th>
                          <Th>고객명</Th>
                          <Th>품목 수량</Th>
                          <Th>총 금액</Th>
                          <Th>최종 반품 금액</Th>
                          <Th>반품 금액 상세</Th>
                          <Th>반품신청일시</Th>
                          <Th>상 태</Th>
                          <Th>상태 변경</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {takeBackList?.length > 0 ? (
                          takeBackList?.map((item, key) => (
                            <tr key={key} style={{ cursor: "pointer" }}>
                              <Td onClick={() => onClickItem(item.idx)}>{item.idx}</Td>
                              <Td onClick={() => onClickItem(item.idx)}>{item.order_user_name}</Td>
                              <Td onClick={() => onClickItem(item.idx)}>{item.take_back_count}</Td>
                              <Td onClick={() => onClickItem(item.idx)}>
                                {AddComma(item.expected_take_back_amount)}원
                              </Td>
                              <Td onClick={() => onClickItem(item.idx)}>{AddComma(item.final_take_back_amount)}원</Td>
                              <Td align={"left"} onClick={() => onClickItem(item.idx)}>
                                <div>현금: {AddComma(item.cash_point_final_amount.cash)}원</div>
                                <div>포인트: {AddComma(item.cash_point_final_amount.point)}p</div>
                              </Td>
                              <Td onClick={() => onClickItem(item.idx)}>{item.createdAt}</Td>
                              <Td onClick={() => onClickItem(item.idx)}>
                                {item.deletedAt !== null
                                  ? "반품취소"
                                  : item.status
                                    ? SHOP_TAKEBACK_STATUS_LIST.find((i) => i.value === item.status)?.text || "-"
                                    : "-"}
                              </Td>
                              <Td>
                                {item.status === "deposit_wait" ? (
                                  <Btn
                                    margin={"0 auto"}
                                    size={"12px"}
                                    sizeMd={"14px"}
                                    padding={"6px 10px"}
                                    paddingMd={"8px 18px"}
                                    onClick={() => onClickChangeStatusBtn(item)}
                                  >
                                    입금 처리
                                  </Btn>
                                ) : (
                                  "-"
                                )}
                              </Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={9}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  {pagination?.total_page > 0 ? (
                    <Pagination pagination={pagination} movePage={movePage} currentPage={searchState.page} />
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
              {depositInfoModal && (
                <ShopDepositModal
                  setModal={setDepositInfoModal}
                  onSubmit={onSubmit}
                  depositInfoData={depositInfoData}
                />
              )}
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

export default ShopTakeBack;
