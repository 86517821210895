  // 해당 월의 마지막 날짜를 반환하는 함수
  export const getLastDayOfCurrentMonth = (date) => {
    // Get the current date
    const currentDate = new Date(date);

    // Set the date to the first day of next month
    currentDate.setMonth(currentDate.getMonth() + 1, 1);

    // Subtract one day to get the last day of the current month
    currentDate.setDate(currentDate.getDate() - 1);

    // Return the last day of the current month
    return currentDate.getDate();
  }
