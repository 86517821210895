import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import img_logo from "../../Assets/images/logo.png";
import ico_menu from "../../Assets/images/ico_menu.svg";
import ico_close from "../../Assets/images/ico_close.png";
import ico_dropdown from "../../Assets/images/ico_menu_dropdown.svg";
import ico_dropdown_white from "../../Assets/images/ico_menu_dropdown_white.png";
import ico_up from "../../Assets/images/ico_menu_up.svg";
import ico_up_white from "../../Assets/images/ico_menu_up_white.svg";
import ico_home from "../../Assets/images/ico_home.svg";
import ico_sales from "../../Assets/images/ico_sales.svg";
import ico_profile from "../../Assets/images/ico_profile.svg";
import ico_product from "../../Assets/images/ico_product.svg";
import ico_shoppingmall from "../../Assets/images/ico_shoppingmall.svg";
import ico_home_selected from "../../Assets/images/ico_home_white.svg";
import ico_sales_selected from "../../Assets/images/ico_sales_white.svg";
import ico_profile_selected from "../../Assets/images/ico_profile_white.svg";
import ico_product_selected from "../../Assets/images/ico_product_white.svg";
import ico_shoppingmall_selected from "../../Assets/images/ico_shoppingmall_white.svg";
import ico_benefits from "../../Assets/images/ico_benefits.svg";
import ico_benefits_selected from "../../Assets/images/ico_benefits_white.svg";
import ChannelService from "../../Utiles/ChannelService";
import { Text, FlexBox } from "../../Assets/css/commonVer2";
import { DIRECT_SELFGOODS_FRANCHISE } from "../../Utiles/ListData";

function Header(props) {
  const isAdmin2 = sessionStorage.getItem("user_id") === "10";
  const parsedHeaderIdxState = sessionStorage.getItem("headerIdxState")
    ? JSON.parse(sessionStorage.getItem("headerIdxState"))
    : {};

  const [showSubmenuIdx, setShowSubmenuIdx] = useState(
    parsedHeaderIdxState ? (parsedHeaderIdxState?.showSubmenuIdx ? parsedHeaderIdxState?.showSubmenuIdx : 0) : null,
  );

  const headerIdxState = {
    showSubmenuIdx,
  };

  const [isLoad, setIsLoad] = useState(false);

  let isDeveloper = sessionStorage.getItem("user_id") === "46";

  // 자점매입 상품관리 페이지_상품 즉시 등록 가능한 가맹점 구분값
  const isExceptFran = DIRECT_SELFGOODS_FRANCHISE.find((item) => item.value == sessionStorage.getItem("franchise_id"))
    ? true
    : false;

  var hdMenu = [];
  if (sessionStorage.getItem("gubun") === "storeonwer") {
    hdMenu = [
      {
        name: "홈",
        link: "/main",
        icon: ico_home,
        selectedIcon: ico_home_selected,
      },
      {
        name: "매출",
        link: "/sales",
        icon: ico_sales,
        selectedIcon: ico_sales_selected,
        dep2: [
          {
            name: "매출 조회",
            link: "/sales",
          },
          {
            name: "월간 리포트",
            link: "/sales/reportsales",
          },
          {
            name: "상품별 매출",
            link: "/sales/item",
          },
          {
            name: "매출 캘린더",
            link: "/sales/calendar",
          },
          {
            name: "결제 내역",
            link: "/sales/salespayment",
          },
        ],
      },
      {
        name: "상품관리",
        link: "/goods",
        icon: ico_product,
        selectedIcon: ico_product_selected,
        dep2: [
          {
            name: "상품 메인",
            link: "/goods",
          },
          {
            name: "본사 상품",
            link: "/goods/all",
          },
          {
            name: "장바구니 관리",
            link: "/goods/shopping",
          },
          {
            name: "주문내역 관리",
            link: "/goods/orderhistory",
          },
          {
            name: "자점매입 상품관리",
            link: "/goods/direct/selfgoods",
          },
          {
            name: "예치금 현황",
            link: "/goods/deposit/view",
          },
          {
            name: "반품 관리",
            link: "/goods/takeback",
          },
        ],
      },
      {
        name: "마이페이지",
        link: "/mypage",
        icon: ico_profile,
        selectedIcon: ico_profile_selected,
      },
    ];
  } else if (isAdmin2) {
    hdMenu = [
      {
        name: "상품마스터",
        link: "/product",
        icon: ico_product,
        selectedIcon: ico_product_selected,
        dep2: [
          {
            name: "대쉬보드",
            link: "/product",
          },
          {
            name: "상품분류 관리",
            link: "/product/category",
          },
          {
            name: "본사상품 관리",
            link: "/product/all",
          },
          {
            name: "주문내역 조회",
            link: "/product/order",
          },
          {
            name: "주문내역 관리",
            link: "/product/order/management",
          },
          {
            name: "재고 관리",
            link: "/product/inventory",
          },
          {
            name: "재고관리 내역",
            link: "/product/inventory/view",
          },
          {
            name: "반품 관리",
            link: "/product/takeback",
          },
          {
            name: "묶음상품 관리",
            link: "/product/bundle",
          },
        ],
      },
      {
        name: "자점매입",
        link: "/ownpurchase",
        icon: ico_home,
        selectedIcon: ico_home_selected,
        dep2: [
          {
            name: "자점매입상품 관리",
            link: "/ownpurchase",
          },
        ],
      },
      {
        name: "쇼핑몰",
        link: "/shoppingmall",
        icon: ico_shoppingmall,
        selectedIcon: ico_shoppingmall_selected,
        dep2: [
          {
            name: "매출관리",
            link: "/shoppingmall",
          },
          {
            name: "상품 리스트",
            link: "/shoppingmall/product",
          },
          {
            name: "프로모션 리스트",
            link: "/shoppingmall/promotion",
          },
          {
            name: "주문내역 조회 및 관리",
            link: "/shoppingmall/order",
          },
          {
            name: "반품 관리",
            link: "/shoppingmall/takeback",
          },
          {
            name: "고객리스트",
            link: "/shoppingmall/customer",
          },
        ],
      },
      {
        name: "마이페이지",
        link: "/mypage",
        icon: ico_profile,
        selectedIcon: ico_profile_selected,
      },
    ];
  } else if (!isAdmin2 && sessionStorage.getItem("gubun") === "admin") {
    hdMenu = [
      {
        name: "가맹마스터",
        link: "/franchisee",
        icon: ico_sales,
        selectedIcon: ico_sales_selected,
        dep2: [
          {
            name: "대쉬보드",
            link: "/franchisee",
          },
          {
            name: "가맹점 매출관리",
            link: "/franchisee/revenue",
          },
          {
            name: "가맹점 관리",
            link: "/franchisee/management",
          },
          {
            name: "키오스크 관리",
            link: "/franchisee/kiosk",
          },
          {
            name: "사용자 관리",
            link: "/franchisee/user",
          },
          {
            name: "예치금 관리",
            link: "/franchisee/deposit",
          },
          {
            name: "가맹문의 조회",
            link: "/franchisee/inquiry",
          },
        ],
      },
      {
        name: "상품마스터",
        link: "/product",
        icon: ico_product,
        selectedIcon: ico_product_selected,
        dep2: [
          {
            name: "대쉬보드",
            link: "/product",
          },
          {
            name: "상품분류 관리",
            link: "/product/category",
          },
          {
            name: "본사상품 관리",
            link: "/product/all",
          },
          {
            name: "주문내역 조회",
            link: "/product/order",
          },
          {
            name: "주문내역 관리",
            link: "/product/order/management",
          },
          {
            name: "재고 관리",
            link: "/product/inventory",
          },
          {
            name: "재고관리 내역",
            link: "/product/inventory/view",
          },
          {
            name: "반품 관리",
            link: "/product/takeback",
          },
          {
            name: "묶음상품 관리",
            link: "/product/bundle",
          },
        ],
      },
      {
        name: "자점매입",
        link: "/ownpurchase",
        icon: ico_home,
        selectedIcon: ico_home_selected,
        dep2: [
          {
            name: "자점매입상품 관리",
            link: "/ownpurchase",
          },
        ],
      },
      {
        name: "쇼핑몰",
        link: "/shoppingmall",
        icon: ico_shoppingmall,
        selectedIcon: ico_shoppingmall_selected,
        dep2: [
          {
            name: "매출관리",
            link: "/shoppingmall",
          },
          {
            name: "상품 리스트",
            link: "/shoppingmall/product",
          },
          {
            name: "프로모션 리스트",
            link: "/shoppingmall/promotion",
          },
          {
            name: "주문내역 조회 및 관리",
            link: "/shoppingmall/order",
          },
          {
            name: "반품 관리",
            link: "/shoppingmall/takeback",
          },
          {
            name: "고객리스트",
            link: "/shoppingmall/customer",
          },
        ],
      },
      {
        name: "혜택관리",
        link: "/benefits",
        icon: ico_benefits,
        selectedIcon: ico_benefits_selected,
        dep2: isDeveloper
          ? [
              {
                name: "쿠폰관리",
                link: "/benefits",
              },
              {
                name: "추가 포인트 관리",
                link: "/benefits/point",
              },
              {
                name: "포인트 충전",
                link: "/benefits/charge",
              },
            ]
          : [
              {
                name: "쿠폰관리",
                link: "/benefits",
              },
              {
                name: "추가 포인트 관리",
                link: "/benefits/point",
              },
            ],
      },
      {
        name: "마이페이지",
        link: "/mypage",
        icon: ico_profile,
        selectedIcon: ico_profile_selected,
      },
      {
        name: "공지관리",
        link: "/notice",
        icon: ico_profile,
        selectedIcon: ico_profile_selected,
      },
    ];
  }
  const { title } = props;
  const [menuShow, setMenuShow] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;
  const path2 = location.pathname.split("/")[1];
  const onMenuOpen = () => setMenuShow(true);
  const onMenuClose = () => setMenuShow(false);
  const isLoginPage = window.location.pathname == "/";

  const saveSubmenuIdx = () => {
    let jsonString = JSON.stringify(headerIdxState);
    sessionStorage.setItem("headerIdxState", jsonString);
  };

  useEffect(() => {
    if (localStorage.getItem("gubun") !== null) {
      if (
        sessionStorage.getItem("gubun") === null ||
        sessionStorage.getItem("gubun") === "null" ||
        sessionStorage.getItem("gubun") === "" ||
        sessionStorage.getItem("gubun") === undefined
      ) {
        sessionStorage.setItem("user_id", localStorage.getItem("user_id"));
        sessionStorage.setItem("gubun", localStorage.getItem("gubun"));
        sessionStorage.setItem("franchise_id", localStorage.getItem("franchise_id"));
      }
    } else {
      if (
        sessionStorage.getItem("gubun") === null ||
        sessionStorage.getItem("gubun") === "null" ||
        sessionStorage.getItem("gubun") === "" ||
        sessionStorage.getItem("gubun") === undefined
      ) {
        if (!isLoginPage) {
          alert("로그인 후 이용 가능합니다.");
          navigate("/");
        }
      }
    }

    if (sessionStorage.getItem("gubun") === "storeonwer") {
      ChannelService.loadScript();

      ChannelService.boot({
        pluginKey: process.env.REACT_APP_CHANNEL_TALK_KEY,
        mobileMessengerMode: "iframe",
        zIndex: 200,
      });
    } else {
      ChannelService.shutdown();
    }
  }, []);

  useEffect(() => {
    const matchingIndex = hdMenu.findIndex((menu) => "/" + path2 === menu.link);
    setShowSubmenuIdx(matchingIndex !== -1 ? matchingIndex : null);
    saveSubmenuIdx();
  }, [path2]);

  useEffect(() => {
    sessionStorage.removeItem("headerIdxState");
    const timer = setTimeout(() => {
      setIsLoad(true);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {/* Header - PC */}
      {!isLoginPage && (
        <Wrapper>
          <Container>
            <LogoWrap>
              <Link to={sessionStorage.getItem("gubun") === "admin" ? "/franchisee" : "/main"}>
                <Logo src={img_logo} />
              </Link>
            </LogoWrap>
            <FlexBox
              style={{
                paddingBottom: "20px",
                borderBottom: "1px solid #eaeaea",
                marginBottom: "20px",
              }}
            >
              <Text color="#C7C7C7" margin={"16px 0 0 0"} size={"16px"} weight={"700"}>
                DashBoard
              </Text>
            </FlexBox>
            <MenuWrap>
              {hdMenu.map((e, idx) => (
                <FlexBox key={idx} direction={"column"} width={"100%"}>
                  <FlexBox
                    padding={"12px 20px 12px 20px"}
                    radius={"10px"}
                    background={"/" + path2 === e.link ? "#FF4A4A" : "#fff"}
                    style={{ width: "100%", cursor: "pointer" }}
                    onClick={() => {
                      if (e.dep2) {
                        setShowSubmenuIdx(showSubmenuIdx === idx ? null : idx);
                      } else {
                        navigate(e.link);
                      }
                      saveSubmenuIdx();
                    }}
                  >
                    <img src={"/" + path2 === e.link ? e.selectedIcon : e.icon} />
                    <Text
                      margin={"0 0 0 10px"}
                      size={"16px"}
                      weight={"/" + path2 === e.link ? "700" : "500"}
                      color={"/" + path2 === e.link ? "#fff" : "#666666"}
                    >
                      {e.name}
                    </Text>
                    {e.dep2 ? (
                      <img
                        style={{ marginLeft: "auto" }}
                        src={
                          "/" + path2 === e.link
                            ? showSubmenuIdx === idx
                              ? ico_dropdown_white
                              : ico_up_white
                            : showSubmenuIdx === idx
                              ? ico_dropdown
                              : ico_up
                        }
                      />
                    ) : (
                      <img style={{ marginLeft: "auto" }} />
                    )}
                  </FlexBox>
                  <SubMenuContainer isOpen={showSubmenuIdx === idx} isLoad={isLoad}>
                    {e.dep2?.map((e2, idx2) => (
                      <FlexBox
                        width={"100%"}
                        justify={"start"}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowSubmenuIdx(idx);
                          navigate(e2.link);
                          saveSubmenuIdx();
                        }}
                      >
                        <FlexBox justify={"start"} width={"100%"} padding={"15px 0 15px 48px"}>
                          <Text align={"start"} weight={path === e2.link ? "700" : "500"}>
                            {e2.name}
                          </Text>
                        </FlexBox>
                        {path === e2.link && (
                          <div
                            style={{
                              width: "4px",
                              height: "17px",
                              backgroundColor: "#666666",
                              borderRadius: "6px",
                            }}
                          />
                        )}
                      </FlexBox>
                    ))}
                  </SubMenuContainer>
                </FlexBox>
              ))}
            </MenuWrap>
          </Container>
        </Wrapper>
      )}

      {/* Header - Mobile */}
      {!isLoginPage && (
        <>
          <WrapperM>
            <ContainerM>
              <MenuBtn onClick={onMenuOpen}>
                <IconM src={ico_menu} />
              </MenuBtn>
              {title ? <HdMTitle>{title}</HdMTitle> : <Logo src={img_logo} />}
              <Link to={sessionStorage.getItem("gubun") === "admin" ? "/franchisee" : "/main"}>
                <IconM src={ico_home} />
              </Link>
            </ContainerM>
          </WrapperM>
          <MenuWrapM className={menuShow && "active"}>
            <MenuTopM>
              <MenuText size={"18px"} color={"#2d2d2d"} weight={"700"}>
                메뉴
              </MenuText>
              <CloseBtn onClick={onMenuClose}>
                <IconM src={ico_close} />
              </CloseBtn>
            </MenuTopM>
            <MenuBox>
              {hdMenu.map((e, idx) => (
                <div key={idx}>
                  {e && (
                    <FlexBox
                      padding={"18px 20px 18px 18px"}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (e.dep2) {
                          if (e.name === "마이페이지") {
                            navigate(e.link);
                            setMenuShow(false);
                          } else {
                            setShowSubmenuIdx(showSubmenuIdx === idx ? null : idx);
                          }
                        } else {
                          if (e.name === "마이페이지") {
                            navigate(e.link);
                            setMenuShow(false);
                          }
                          navigate(e.link);
                        }
                        saveSubmenuIdx();
                      }}
                    >
                      <Text color="#2D2D2D" size={"18px"}>
                        {e.name}
                      </Text>
                      {e.dep2 ? (
                        <img
                          style={{ marginLeft: "auto" }}
                          src={
                            "/" + path2 === e.link
                              ? showSubmenuIdx === idx
                                ? ico_dropdown
                                : ico_up
                              : showSubmenuIdx === idx
                                ? ico_dropdown
                                : ico_up
                          }
                        />
                      ) : (
                        <img style={{ marginLeft: "auto" }} />
                      )}
                    </FlexBox>
                  )}
                  <SubMenuContainer isOpen={showSubmenuIdx === idx} isLoad={isLoad}>
                    {e.dep2?.map((e2, idx2) => (
                      <Text
                        key={idx2}
                        size={"14px"}
                        color={"#666"}
                        weight={path === e2.link ? "700" : "500"}
                        background={path === e2.link ? "#f2f2f2" : "#fff"}
                        style={{ paddingLeft: "15px" }}
                      >
                        <Link to={e2.link} state={{ link: e.link }} onClick={onMenuClose}>
                          {e2.name}
                        </Link>
                      </Text>
                    ))}
                  </SubMenuContainer>
                </div>
              ))}
            </MenuBox>
          </MenuWrapM>
          <HdBlank />
        </>
      )}
    </>
  );
}

// Header - PC
const Wrapper = styled.div`
  display: flex;
  min-width: 330px;
  height: 1080px;
  padding: 0px 30px 0px 52px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0px 10px 10px 0px;
  background: #fff;
  box-shadow: 2px 2px 30px 0px rgba(0, 0, 0, 0.05);

  @media (min-width: 1024px) {
    padding: 0px 30px;
    min-width: 280px;
  }

  @media all and (max-width: 1024px) {
    display: none;
  }
`;
const Container = styled.div`
  width: 100%;
  height: 1080px;
  display: flex;
  padding: 50px 0px 0px 0px;
  flex-direction: column;
  gap: 20px;

  @media all and (max-width: 1024px) {
    justify-content: space-between;
    max-width: 100%;
    padding: 10px 12px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    align-items: center;
    // padding: 20px 12px 0px;
  }
`;
const LogoWrap = styled.div`
  // margin-right: 42px;
  width: 100%;
  // height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 991px) {
    // margin-right: 20px;
  }
`;
const Logo = styled.img`
  width: 144px;
  flex-shrink: 0;
`;
const MenuWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  @media all and (max-width: 1200px) {
    gap: 20px;
  }
`;
const MenuText = styled.p`
  text-align: ${(props) => props.align || "center"};
  font-size: ${(props) => props.size || "14px"};
  color: ${(props) => props.color || "#ffffff"};
  font-weight: ${(props) => props.weight || "500"};
  font-family: ${(props) => props.family || "Pretendard"};
  lint-height: ${(props) => props.lineHeight || "normal"};

  // &:hover,
  // &.active {
  //   color: #ffffff;
  //   font-weight: 700;
  //   background-color: #ff4a4a;
  // }
`;

const MenuDepth2 = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  align-items: center;
  gap: 46px;
  height: 48px;
  padding-left: 214px;
  z-index: 1;

  ::before {
    content: "";
    position: absolute;
    top: 1px;
    left: 50%;
    display: block;
    width: 1000%;
    height: 48px;
    border-bottom: 1px solid #eaeaea;
    background-color: #fff;
    transform: translateX(-50%);
  }
  ${MenuText} {
    position: relative;
  }

  @media all and (max-width: 1200px) {
    gap: 20px;
  }
  @media all and (max-width: 991px) {
    padding-left: 40px;
  }
`;

const SubMenuContainer = styled.div`
  width: 100%;
  overflow: hidden;
  max-height: ${(props) => (props.isOpen ? "1000px" : "0")};
  transition: ${(props) =>
    props.isLoad ? "all 0.3s ease-in-out" : "none"}; /* Adjust the transition duration as needed */
`;

// Header - Mob
const WrapperM = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #eaeaea;
  background-color: #fff;
  z-index: 200;
  justify-content: center;
  align-items: center;
  a {
    padding: 6px;
  }

  @media all and (max-width: 1024px) {
    display: block;
  }
`;

const ContainerM = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
`;

const MenuBtn = styled.a`
  display: block;
`;

const CloseBtn = styled.a`
  position: absolute;
  top: 50%;
  right: 12px;
  display: block;
  padding: 6px;
  transform: translateY(-50%);
`;

const HdBlank = styled.div``;

const IconM = styled.img`
  width: 20px;
`;

const HdMTitle = styled.p`
  font-size: 18px;
  color: #000;
  font-weight: 700;
`;

const MenuWrapM = styled.div`
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  z-index: 220;
  background-color: #fff;
  transition: all 0.5s;

  &.active {
    left: 0;
  }
`;

const MenuTopM = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  border-bottom: 1px solid #eaeaea;
`;

const MenuBox = styled.div`
  height: calc(100% - 60px);
  overflow-y: auto;

  a {
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 30px;

    i {
      position: absolute;
      top: 50%;
      right: 30px;
      display: block;
      transform: translateY(-50%);
      img {
        width: 12px;
      }
    }
  }
`;
export default Header;
