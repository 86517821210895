import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  TableWrap,
  Th,
  Td,
  Btn,
  Wrapper,
  Container,
  ContentContainer,
  FlexBox,
  WhiteBox,
  Title,
  DateInputBox,
  PagerWrap,
} from "../../Assets/css/commonVer2";
import HeaderSub from "../../Components/Header/HeaderSub";
import API from "../../Utiles/API";
import { AddComma, dateToString, Xlsx_download } from "../../Utiles/Custom";
import Paging from "../../Components/Paging/Paging";
import Authority from "../../Components/Modal/Authority";
import InfoModal from "../../Components/Modal/InfoModal";
import HandleBack from "../../Components/HandleBack";

function SalesItemView() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const { handlePopstate } = HandleBack();
  const [Franchise_id] = useState(sessionStorage.getItem("franchise_id"));
  const date = new Date();
  const year = date.getFullYear(); // 년
  const month = date.getMonth(); // 월
  const day = date.getDate(); // 일
  const [startDate, setStartDate] = useState(new Date(year, month, day - 1));
  const [endDate, setEndDate] = useState(new Date());
  const [data, setData] = useState([]);

  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();

  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");

  const getSaleList = async () => {
    try {
      const res = await API.get(
        `/storeowner/sale-select/product-history/${Franchise_id}?pageSize=10&curPage=${currentPage}&filterDatemin=${dateToString(
          startDate
        )}&filterDatemax=${dateToString(endDate)}`
      );
      if (res.data?.status) {
        setTotalCount(res.data.cntAll[0].cnt);
        setData(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onExcelDown = async () => {
    if (data.length > 0) {
      const res = await API.get(
        `/storeowner/sale-select/product-history/${Franchise_id}?pageSize=99999999&curPage=${currentPage}&filterDatemin=${dateToString(
          startDate
        )}&filterDatemax=${dateToString(endDate)}`
      );
      if (res.data.status && res.data.result.length > 0) {
        var xlsxTemp = res.data.result.map(function (item) {
          return {
            거래일시: item.createdat,
            "결제 수단": item.type == "cash" ? "현금" : "카드",
            "상품 분류": item.name,
            상품명: item.product_name,
            결제금액: item.payment_amount,
          };
        });
        // 다운로드 실행
        Xlsx_download(xlsxTemp, `판매상품내역_${dateToString(startDate)}~${dateToString(endDate)}`);
      } else {
        setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    } else {
      setTextMsg("다운받을 데이터가 없습니다.");
      setTextModal(true);
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    getSaleList();
  }, [currentPage]);

  useEffect(() => {
    setTotalPage(Math.ceil(totalCount / 10));
  }, [totalCount]);

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          <HeaderSub title={"전체 판매 내역 조회"} />
          <ContentContainer>
            <Wrapper type={2} ptM="60px" style={{ paddingBottom: "150px" }}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    전체 판매 내역 조회
                  </Title>
                )}
                {isMobile && (
                  <FlexBox margin="10px 0">
                    <FlexBox gap="8px" justify={"end"} width="100%">
                      <DateInputBox>
                        <DatePicker
                          dateFormatCalendar="yyyy년 MM월"
                          dateFormat="yyyy-MM-dd"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={endDate}
                        />
                      </DateInputBox>
                      <DateInputBox>
                        <DatePicker
                          dateFormatCalendar="yyyy년 MM월"
                          dateFormat="yyyy-MM-dd"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </DateInputBox>
                      <Btn
                        color={"#fff"}
                        background={"#FF4A4A"}
                        radius={"6px"}
                        size={"12px"}
                        sizeMd={"14px"}
                        weight={"700"}
                        padding={"9px 19px"}
                        border="unset"
                        margin="2px 0 0"
                        lineHeight={"22px"}
                        style={{ minWidth: "60px" }}
                        onClick={() => getSaleList()}
                      >
                        조회
                      </Btn>
                    </FlexBox>
                  </FlexBox>
                )}
                <WhiteBox margin={"17px 0 0 "} marginMd={"10px 0 0"}>
                  {!isMobile && (
                    <>
                      <FlexBox justify="end">
                        <FlexBox gap="6px" gapMd="13px" justify={"space-evenly"}>
                          <DateInputBox>
                            <DatePicker
                              dateFormatCalendar="yyyy년 MM월"
                              dateFormat="yyyy-MM-dd"
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              startDate={startDate}
                              endDate={endDate}
                              maxDate={endDate}
                            />
                          </DateInputBox>
                          <DateInputBox>
                            <DatePicker
                              dateFormatCalendar="yyyy년 MM월"
                              dateFormat="yyyy-MM-dd"
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              startDate={startDate}
                              endDate={endDate}
                              minDate={startDate}
                            />
                          </DateInputBox>
                          <Btn
                            color={"#fff"}
                            background={"#FF4A4A"}
                            radius={"6px"}
                            size={"14px"}
                            weight={"700"}
                            padding={"9px 19px"}
                            border="unset"
                            margin="2px 0 0"
                            lineHeight={"22px"}
                            width={"75px"}
                            onClick={() => getSaleList()}
                          >
                            조회
                          </Btn>
                        </FlexBox>
                      </FlexBox>
                      <FlexBox justify="end" margin="20px 0 30px">
                        <Btn
                          border={"1px solid #666666"}
                          hoverBorder={"1px solid #C7C7C7"}
                          hoverBackground={"#C7C7C7"}
                          color={"#666666"}
                          hoverColor={"#FFFFFF"}
                          weight={"500"}
                          hoverWeight={"700"}
                          padding={"9px 19px"}
                          size={"14px"}
                          radius={"6px"}
                          lineHeight={"22px"}
                          width={"92px"}
                          onClick={onExcelDown}
                        >
                          엑셀 다운
                        </Btn>
                      </FlexBox>
                    </>
                  )}

                  <TableWrap>
                    <table style={{ width: "100%" }}>
                      <colgroup>
                        <col style={{ width: "15.5%" }} />
                        <col style={{ width: "12%" }} />
                        <col style={{ width: "15.5%" }} />
                        <col />
                        <col style={{ width: "15.5%" }} />
                        <col style={{ width: "16%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>거래일시</Th>
                          <Th>결제수단</Th>
                          <Th>상품분류</Th>
                          <Th>상품명</Th>
                          <Th>판매수량</Th>
                          <Th>결제금액</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 ? (
                          data.map((item, key) => (
                            <tr key={key}>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.createdat}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {item.type === "cash" ? "현금" : item.type === "card" ? "카드" : "기타"}
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.name}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.product_name}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.quantity}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{AddComma(item.payment_amount)}원</Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={6}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  {data.length > 0 ? (
                    <PagerWrap margin={"30px 0 0 0"}>
                      <Paging totalPage={totalPage} limit={10} page={currentPage} setPage={setCurrentPage} />
                    </PagerWrap>
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

export default SalesItemView;
