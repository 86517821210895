import React, { useEffect, useRef } from "react";

import { handleKeyDown } from "../../../Utiles/Custom";
import { SearchList, SelectWrap, SearchInput, SelectContainer } from "../../../Assets/css/commonVer2";

/**
 * 고객명 검색
 * 1. 검색어 입력 시 하단에 고객명 목록 노출
 * 2. 고객명 목록에서 이름 클릭 시 해당 텍스트를 검색창에 위치
 */

function CustomerSearchInput(props) {
  let {
    inputValue,
    setInputValue,
    searchGubun,
    searchResults,
    showSearchList,
    setShowSearchList,
    onSearch,
    disabled,
  } = props;

  const searchRef = useRef(null);

  // 검색 결과 목록에서 아이템을 클릭함
  const handleItemClick = (itemName) => {
    setShowSearchList(false);
    setInputValue(itemName);
  };

  // 다른 곳 클릭 시 목록 닫히도록
  useEffect(() => {
    function handleOutside(e) {
      if (searchRef.current && !searchRef.current.contains(e.target)) {
        setShowSearchList(false);
      }
    }
    document.addEventListener("mousedown", handleOutside);
    return () => {
      document.removeEventListener("mousedown", handleOutside);
    };
  }, [searchRef]);

  return (
    <>
      <SelectContainer ref={searchRef}>
        <SearchInput
          borderBottom={"1px solid #666666"}
          borderBottomColor={"#ff4a4a"}
          transition={"border-color 0.3s ease-in-out"}
          disabled={disabled}
          placeholder={searchGubun === "phone" ?  "전화번호를 검색해보세요." : "고객명을 검색해보세요."}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          onKeyDown={(event) => handleKeyDown(event, onSearch)}
        />
        {showSearchList && (
          <SelectWrap width={"100%"}>
            {searchResults?.map((item, idx) => (
              <SearchList key={idx} value={item.value}>
                <li
                  onClick={() => {
                    handleItemClick(item.customer_name);
                  }}
                >
                  <span> {item.customer_name}</span>
                </li>
              </SearchList>
            ))}
          </SelectWrap>
        )}
      </SelectContainer>
    </>
  );
}

export default CustomerSearchInput;
