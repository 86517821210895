// 외부 모듈
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import { utils, writeFile } from "xlsx";
import XLSX from "xlsx-js-style";

// 내부 모듈
import {
  ContentContainer,
  Container,
  FlexBox,
  Title,
  Wrapper,
  Th,
  Td,
  Text,
  TableWrap,
  LeftArea,
  RightArea,
  Btn,
  WhiteBox,
  OrderAddrBox,
} from "../../Assets/css/commonVer2";
import "../../Assets/css/DatePicker.css";
import HeaderSub from "../../Components/Header/HeaderSub";
import Authority from "../../Components/Modal/Authority";
import Loading from "../../Components/Loading";
import { AddComma } from "../../Utiles/Custom";
import API from "../../Utiles/API";
import { useMediaQuery } from "react-responsive";
import InfoModal from "../../Components/Modal/InfoModal";

// 상품마스터 > 주문내역조회 > 주문조회 상세

export default function ProductOrderView() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [franchise_id] = useState(location.state?.franchise_id);
  const [order_date] = useState(location.state?.order_date);

  const [textModal, setTextModal] = useState(false); // 모달 창 표시 여부 상태
  const [textMsg, setTextMsg] = useState("");

  const [orderList, setOrderList] = useState([]);
  const [deliveryInfo, setDeliveryInfo] = useState({});
  const [orderStatus, setOrderStatus] = useState("");
  const [orderInfo, setOrderInfo] = useState({});

  const shippingFee = 5000;

  const [resData, setResData] = useState([]); // 거래명세서 데이터

  // 주문내역 불러오는 API 호출
  const getData = async () => {
    try {
      setLoading(true);
      const res = await API.get(`/admin/order-list/detail?franchise_id=${franchise_id}&order_date=${order_date}`);
      if (res.data.status) {
        setLoading(false);
        setOrderList(res.data.results.table);
        setDeliveryInfo(res.data.results.delivery);
        setOrderStatus(res.data.results.delivery.status);
        setOrderInfo(res.data.results.order_info);
      }
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };

  // 엑셀 다운로드
  function ExportHandler() {
    const data = [];
    const excelTableHead = [["No", "주문번호", "바코드", "상품명", "수량", "공급가", "총금액", "비고"]];

    // excel 문서 만들기
    const wb = utils.book_new();
    // 열 순서를 sheet화
    const ws = utils.json_to_sheet([]);
    // ws에 excelTableHead를 추가
    utils.sheet_add_aoa(ws, excelTableHead);

    orderList.forEach((item, idx) => {
      data.push({
        row_num: `${idx + 1}`,
        order_id: `${item.order_id}`,
        barcode_num: `${item.barcode_num}`,
        p_name: `${item.p_name}`,
        quantity: `${item.quantity}`,
        shipping_price: `${item.shipping_price}`,
        amount: `${item.shipping_price * item.quantity}`,
        status: `${
          item.status ? (item.status == "add" ? "상품 추가" : item.status == "edit" ? "수량 수정" : "-") : "-"
        }`,
      });
    });

    utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Report");
    // 두 번째 arg에는 export 될 파일의 이름을 넣어주면 된다.
    writeFile(wb, `주문내역_${deliveryInfo.franchise_name}_${deliveryInfo.order_date}.xlsx`);
  }

  // 거래명세서 API 호출
  const onClickTradingStatementBtn = async () => {
    await API.get(`/admin/order-list/detail/product-description?franchise_id=${franchise_id}&order_date=${order_date}`)
      .then((res) => {
        if (res.data.status) {
          setResData(res.data.results);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch((error) => {
        setTextMsg("E01\n잠시 후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  // 거래명세서 다운로드
  function DownTradingStatement() {
    // workbook 만들기
    const wb = utils.book_new();

    // 합계금액 숫자 한글로 바꾸는 함수
    function convertToKoreanNumber(num) {
      var result = "";
      var digits = ["영", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구"];
      var units = ["", "십", "백", "천", "만", "십", "백", "천", "억"];

      var numStr = num.toString(); // 문자열로 변환
      var numLen = numStr.length; // 문자열의 길이

      for (var i = 0; i < numLen; i++) {
        var digit = parseInt(numStr.charAt(i)); // i번째 자릿수 숫자
        var unit = units[numLen - i - 1]; // i번째 자릿수 단위

        // 일의 자리인 경우에는 숫자를 그대로 한글로 변환
        if (i === numLen - 1 && digit === 1 && numLen !== 1) {
          result += "일";
        } else if (digit !== 0) {
          // 일의 자리가 아니거나 숫자가 0이 아닐 경우
          result += digits[digit] + unit;
        } else if (i === numLen - 5) {
          // 십만 단위에서는 '만'을 붙이지 않습니다.
          result += "만";
        }
      }

      return result;
    }

    // 공통 데이터 - origin 위치의 r(row) 와 c(column)은 0부터 시작
    const commonData = [
      {
        data: [
          [
            {
              v: "거래명세서",
              s: {
                font: { underline: true, bold: true, sz: "30" },
                alignment: { horizontal: "center", vertical: "center" },
              },
            },
          ],
        ],
      },
      {
        data: [
          [
            {
              v: `주문일자: ${deliveryInfo.order_date}`,
              s: { font: { sz: "16" }, alignment: { horizontal: "left", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 1, c: 0 },
      },
      {
        data: [
          [{ v: "공급받는자", s: { font: { sz: "12" }, alignment: { horizontal: "center", vertical: "center" } } }],
        ],
        origin: { r: 2, c: 0 },
      },
      {
        data: [[{ v: "공급자", s: { font: { sz: "12" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 2, c: 10 },
      },
      {
        data: [
          [{ v: "사업자 번호", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }],
        ],
        origin: { r: 2, c: 1 },
      },
      {
        data: [
          [{ v: "사업자 번호", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }],
        ],
        origin: { r: 2, c: 11 },
      },
      {
        data: [[{ v: "상호", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 4, c: 1 },
      },
      {
        data: [[{ v: "상호", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 4, c: 11 },
      },
      {
        data: [[{ v: "주소", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 5, c: 1 },
      },
      {
        data: [[{ v: "주소", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 5, c: 11 },
      },
      {
        data: [[{ v: "업태", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 6, c: 1 },
      },
      {
        data: [[{ v: "업태", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 6, c: 11 },
      },
      {
        data: [[{ v: "성명", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 4, c: 7 },
      },
      {
        data: [[{ v: "성명", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 4, c: 17 },
      },
      {
        data: [[{ v: "종목", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 6, c: 5 },
      },
      {
        data: [[{ v: "종목", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 6, c: 15 },
      },
      {
        data: [
          [
            {
              v: `${resData.franchise?.business_num}`,
              s: { font: { sz: "14" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 2, c: 2 },
      },
      {
        data: [
          [
            {
              v: `Tel. ${resData.franchise?.tel}`,
              s: { font: { sz: "12" }, alignment: { horizontal: "left", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 2, c: 6 },
      },
      {
        data: [
          [
            {
              v: `Fax. ${resData.franchise?.fax || "-"}`,
              s: { font: { sz: "12" }, alignment: { horizontal: "left", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 3, c: 6 },
      },
      {
        data: [
          [
            {
              v: `${resData.franchise?.franchise_name}`,
              s: { font: { sz: "14" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 4, c: 2 },
      },
      {
        data: [
          [
            {
              v: `${resData.franchise?.name}`,
              s: { font: { sz: "12" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 4, c: 8 },
      },
      {
        data: [
          [
            {
              v: `${resData.franchise?.franchise_address}`,
              s: { font: { sz: "11" }, alignment: { horizontal: "left", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 5, c: 2 },
      },
      {
        data: [
          [
            {
              v: `${resData.franchise?.type_of_business || "도매 및 소매업"}`,
              s: { font: { sz: "11" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 6, c: 2 },
      },
      {
        data: [
          [
            {
              v: `${resData.franchise?.items_of_business || "문구용품, 완구용품, 식음료품"}`,
              s: { font: { sz: "11" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 6, c: 6 },
      },
      {
        data: [
          [
            {
              v: `${resData.head?.head_business_num}`,
              s: { font: { sz: "14" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 2, c: 12 },
      },
      {
        data: [
          [
            {
              v: `Tel. ${resData.head?.tel}`,
              s: { font: { sz: "12" }, alignment: { horizontal: "left", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 2, c: 16 },
      },
      {
        data: [
          [
            {
              v: `Fax. ${resData.head?.fax || "-"}`,
              s: { font: { sz: "12" }, alignment: { horizontal: "left", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 3, c: 16 },
      },
      {
        data: [
          [
            {
              v: `${resData.head?.corp_name}`,
              s: { font: { sz: "14" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 4, c: 12 },
      },
      {
        data: [
          [
            {
              v: `${resData.head?.ceo}`,
              s: { font: { sz: "12" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 4, c: 18 },
      },
      {
        data: [
          [
            {
              v: `${resData.head?.address}`,
              s: { font: { sz: "11" }, alignment: { horizontal: "left", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 5, c: 12 },
      },
      {
        data: [
          [
            {
              v: `${resData.head?.head_type_of_business}`,
              s: { font: { sz: "11" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 6, c: 12 },
      },
      {
        data: [
          [
            {
              v: `${resData.head?.head_items_of_business}`,
              s: { font: { sz: "11" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 6, c: 16 },
      },
      {
        data: [
          [
            {
              v: `합계금액: 一金 ${convertToKoreanNumber(shippingFee + orderInfo.product_amount)}원整 (\ ${AddComma(
                shippingFee + orderInfo.product_amount,
              )})[VAT 포함]`,
              s: { font: { bold: true, sz: "18" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 7, c: 0 },
      },
      {
        data: [[{ v: "No", s: { font: { sz: "11" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 8, c: 0 },
      },
      {
        data: [
          [
            {
              v: "상품명 및 바코드 번호",
              s: { font: { sz: "11" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 8, c: 1 },
      },
      {
        data: [[{ v: "단위", s: { font: { sz: "11" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 8, c: 10 },
      },
      {
        data: [[{ v: "수량", s: { font: { sz: "11" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 8, c: 12 },
      },
      {
        data: [[{ v: "단가", s: { font: { sz: "11" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 8, c: 14 },
      },
      {
        data: [[{ v: "총 금액", s: { font: { sz: "11" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 8, c: 16 },
      },
      {
        data: [
          [
            {
              v: `인수자            (인)`,
              s: { font: { sz: "12" }, alignment: { horizontal: "right", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 30, c: 0 },
      },
      {
        data: [[{ v: "총 계", s: { font: { sz: "12" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 31, c: 14 },
      },
      {
        data: [
          [
            {
              v: `${AddComma(shippingFee + orderInfo.product_amount)}원`,
              s: { font: { sz: "13", bold: true }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 31, c: 16 },
      },
    ];

    // 각 시트에 넣을 상품 데이터
    const productData = {
      NoBody: [],
      productBody: [],
      unitBody: [],
      cntBody: [],
      unitPriceBody: [],
      priceBody: [],
    };

    for (let i = 0; i < resData?.products.length; i++) {
      productData.NoBody.push([
        { v: i + 1, s: { font: { sz: "13", bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      ]);
      productData.productBody.push([
        {
          v: `${resData.products[i].product_name}_${resData.products[i].barcode_num}`,
          s: { font: { sz: "12", bold: true }, alignment: { horizontal: "left", vertical: "center" } },
        },
      ]);
      productData.unitBody.push([
        {
          v: `${resData.products[i].order_unit}(${resData.products[i].product_quantity}개)`,
          s: { font: { sz: "14", bold: true }, alignment: { horizontal: "center", vertical: "center" } },
        },
      ]); // 임시
      productData.cntBody.push([
        {
          v: `${resData.products[i].quantity}`,
          s: { font: { sz: "14", bold: true }, alignment: { horizontal: "right", vertical: "center" } },
        },
      ]); //* resData.products[i].product_quantity
      productData.unitPriceBody.push([
        {
          v: `${resData.products[i].shipping_price}`,
          s: { font: { sz: "14", bold: true }, alignment: { horizontal: "right", vertical: "center" } },
        },
      ]);
      productData.priceBody.push([
        {
          v: `${AddComma(resData.products[i].totalPrice)}원`,
          s: { font: { sz: "14", bold: true }, alignment: { horizontal: "right", vertical: "center" } },
        },
      ]);
    }

    // 한 시트에 20개 상품
    const maxItemsPerSheet = 20;

    // productData를 시트별로 나누는 함수
    function splitDataIntoSheets(data) {
      const sheetDataArray = [];

      for (let i = 0; i < data.NoBody.length; i += maxItemsPerSheet) {
        const sheetData = {
          NoBody: data.NoBody.slice(i, i + maxItemsPerSheet),
          productBody: data.productBody.slice(i, i + maxItemsPerSheet),
          unitBody: data.unitBody.slice(i, i + maxItemsPerSheet),
          cntBody: data.cntBody.slice(i, i + maxItemsPerSheet),
          unitPriceBody: data.unitPriceBody.slice(i, i + maxItemsPerSheet),
          priceBody: data.priceBody.slice(i, i + maxItemsPerSheet),
        };
        sheetDataArray.push(sheetData);
      }

      return sheetDataArray;
    }

    // 모든 시트 데이터 배열
    const sheetDataArray = splitDataIntoSheets(productData);

    // 상품 데이터의 열 지정하는 함수
    function getColumnIndex(key) {
      const columns = {
        NoBody: 0,
        productBody: 1,
        unitBody: 10,
        cntBody: 12,
        unitPriceBody: 14,
        priceBody: 16,
      };
      return columns[key];
    }

    // 상품 데이터 worksheet에 추가하는 함수
    function addDataToWorksheet(ws, data, startRow) {
      Object.keys(data).forEach((key) => {
        XLSX.utils.sheet_add_aoa(ws, data[key], { origin: { r: startRow, c: getColumnIndex(key) } });
      });
    }

    for (let i = 0; i < sheetDataArray.length; i++) {
      const sheetData = sheetDataArray[i];
      const ws = XLSX.utils.aoa_to_sheet([]);
      const wsName = `거래명세서 ${i + 1}p`;

      // 택배비 추가
      const shippingFeeTit = [
        [{ v: "택배비", s: { font: { sz: "14" }, alignment: { horizontal: "center", vertical: "center" } } }],
      ];
      const shippingFeeData = [
        [
          {
            v: `${AddComma(shippingFee)}원`,
            s: { font: { sz: "14" }, alignment: { horizontal: "center", vertical: "center" } },
          },
        ],
      ];

      if (sheetDataArray?.length === 1) {
        XLSX.utils.sheet_add_aoa(ws, shippingFeeTit, { origin: { r: 9 + productData?.NoBody.length, c: 1 } });
        XLSX.utils.sheet_add_aoa(ws, shippingFeeData, { origin: { r: 9 + productData?.NoBody.length, c: 16 } });
      } else {
        XLSX.utils.sheet_add_aoa(ws, shippingFeeTit, { origin: { r: productData?.NoBody.length - 11, c: 1 } });
        XLSX.utils.sheet_add_aoa(ws, shippingFeeData, { origin: { r: productData?.NoBody.length - 11, c: 16 } });
      }

      // 공통 데이터 각 origin에 추가
      commonData.forEach(({ data, origin }) => {
        XLSX.utils.sheet_add_aoa(ws, data, { origin });
      });

      // 상품 데이터 10행에 추가
      addDataToWorksheet(ws, sheetData, 9);

      // 페이지 및 소계 데이터 추가
      const pageAndTotalSum = [
        [
          {
            v: `${i + 1} 페이지(${i + 1}/${sheetDataArray.length}), 소계: ${AddComma(
              shippingFee + orderInfo.product_amount,
            )}원`,
            s: { font: { sz: "12" }, alignment: { horizontal: "right", vertical: "center" } },
          },
        ],
      ];
      XLSX.utils.sheet_add_aoa(ws, pageAndTotalSum, { origin: { r: 29, c: 0 } });

      // 셀 병합
      const merges = [
        "A1:T1", // 거래명세서
        "A2:T2", // 주문일자
        "A3:A7",
        "B3:B4",
        "C3:F4",
        "G3:J3",
        "G4:J4",
        "C5:G5",
        "I5:J5",
        "C6:J6",
        "C7:E7",
        "G7:J7", // 공급받는자
        "K3:K7",
        "L3:L4",
        "M3:P4",
        "Q3:T3",
        "Q4:T4",
        "M5:Q5",
        "S5:T5",
        "M6:T6",
        "M7:O7",
        "Q7:T7", // 공급자
        "A8:T8", // 합계금액
        "B9:J9",
        "B10:J10",
        "B11:J11",
        "B12:J12",
        "B13:J13",
        "B14:J14",
        "B15:J15",
        "B16:J16",
        "B17:J17",
        "B18:J18",
        "B19:J19",
        "B20:J20",
        "B21:J21",
        "B22:J22",
        "B23:J23",
        "B24:J24",
        "B25:J25",
        "B26:J26",
        "B27:J27",
        "B28:J28",
        "B29:J29", // 상품명 및 바코드번호
        "K9:L9",
        "K10:L10",
        "K11:L11",
        "K12:L12",
        "K13:L13",
        "K14:L14",
        "K15:L15",
        "K16:L16",
        "K17:L17",
        "K18:L18",
        "K19:L19",
        "K20:L20",
        "K21:L21",
        "K22:L22",
        "K23:L23",
        "K24:L24",
        "K25:L25",
        "K26:L26",
        "K27:L27",
        "K28:L28",
        "K29:L29", // 단위
        "M9:N9",
        "M10:N10",
        "M11:N11",
        "M12:N12",
        "M13:N13",
        "M14:N14",
        "M15:N15",
        "M16:N16",
        "M17:N17",
        "M18:N18",
        "M19:N19",
        "M20:N20",
        "M21:N21",
        "M22:N22",
        "M23:N23",
        "M24:N24",
        "M25:N25",
        "M26:N26",
        "M27:N27",
        "M28:N28",
        "M29:N29", // 수량
        "O9:P9",
        "O10:P10",
        "O11:P11",
        "O12:P12",
        "O13:P13",
        "O14:P14",
        "O15:P15",
        "O16:P16",
        "O17:P17",
        "O18:P18",
        "O19:P19",
        "O20:P20",
        "O21:P21",
        "O22:P22",
        "O23:P23",
        "O24:P24",
        "O25:P25",
        "O26:P26",
        "O27:P27",
        "O28:P28",
        "O29:P29", // 단가
        "Q9:T9",
        "Q10:T10",
        "Q11:T11",
        "Q12:T12",
        "Q13:T13",
        "Q14:T14",
        "Q15:T15",
        "Q16:T16",
        "Q17:T17",
        "Q18:T18",
        "Q19:T19",
        "Q20:T20",
        "Q21:T21",
        "Q22:T22",
        "Q23:T23",
        "Q24:T24",
        "Q25:T25",
        "Q26:T26",
        "Q27:T27",
        "Q28:T28",
        "Q29:T29", // 총 금액
        "A30:T30",
        "A31:T31",
        "O32:P32",
        "Q32:T32",
      ];

      if (!ws["!merges"]) ws["!merges"] = [];

      merges.forEach((range) => ws["!merges"].push(XLSX.utils.decode_range(range)));

      ws["!merges"].push({
        // 거래명세서
        s: { r: 0, c: 0 },
        e: { r: 0, c: 19 },
        // 주문일자
        s: { r: 1, c: 0 },
        e: { r: 1, c: 9 },
        // 주문번호
        s: { r: 1, c: 10 },
        e: { r: 1, c: 19 },
        // 가맹점
        s: { r: 2, c: 0 },
        e: { r: 6, c: 0 },
        // 가맹점_사업자번호_제목
        s: { r: 2, c: 1 },
        e: { r: 3, c: 1 },
        // 본사
        s: { r: 2, c: 10 },
        e: { r: 6, c: 10 },
        // 본사_사업자번호_제목
        s: { r: 2, c: 11 },
        e: { r: 3, c: 11 },
        // 가맹점_사업자번호
        s: { r: 2, c: 2 },
        e: { r: 3, c: 5 },
        // 가맹점_전화번호
        s: { r: 2, c: 6 },
        e: { r: 2, c: 9 },
        // 가맹점_팩스
        s: { r: 3, c: 6 },
        e: { r: 3, c: 9 },
        // 가맹점_상호
        s: { r: 4, c: 2 },
        e: { r: 4, c: 6 },
        // 가맹점_성명
        s: { r: 4, c: 8 },
        e: { r: 4, c: 9 },
        // 가맹점_주소
        s: { r: 5, c: 2 },
        e: { r: 5, c: 9 },
        // 가맹점_업태
        s: { r: 6, c: 2 },
        e: { r: 6, c: 4 },
        // 가맹점_종목
        s: { r: 6, c: 6 },
        e: { r: 6, c: 9 },
        // 본사_사업자번호
        s: { r: 2, c: 12 },
        e: { r: 3, c: 15 },
        // 본사_전화번호
        s: { r: 2, c: 16 },
        e: { r: 2, c: 19 },
        // 본사_팩스
        s: { r: 3, c: 16 },
        e: { r: 3, c: 19 },
        // 본사_상호
        s: { r: 4, c: 12 },
        e: { r: 4, c: 16 },
        // 본사_성명
        s: { r: 4, c: 18 },
        e: { r: 4, c: 19 },
        // 본사_주소
        s: { r: 4, c: 12 },
        e: { r: 4, c: 19 },
        // 본사_업태
        s: { r: 5, c: 12 },
        e: { r: 5, c: 14 },
        // 본사_종목
        s: { r: 5, c: 16 },
        e: { r: 5, c: 19 },
        // 합계금액 표 상단 제목
        s: { r: 7, c: 0 },
        e: { r: 7, c: 19 },
        // 상품명 및 바코드번호_제목
        s: { r: 8, c: 1 },
        e: { r: 8, c: 10 },
        // 상품명 및 바코드번호
        s: { r: 9, c: 1 },
        e: { r: 9, c: 9 },
        s: { r: 10, c: 1 },
        e: { r: 10, c: 9 },
        s: { r: 11, c: 1 },
        e: { r: 11, c: 9 },
        s: { r: 12, c: 1 },
        e: { r: 12, c: 9 },
        s: { r: 13, c: 1 },
        e: { r: 13, c: 9 },
        s: { r: 14, c: 1 },
        e: { r: 14, c: 9 },
        s: { r: 15, c: 1 },
        e: { r: 15, c: 9 },
        s: { r: 16, c: 1 },
        e: { r: 16, c: 9 },
        s: { r: 17, c: 1 },
        e: { r: 17, c: 9 },
        s: { r: 18, c: 1 },
        e: { r: 18, c: 9 },
        s: { r: 19, c: 1 },
        e: { r: 19, c: 9 },
        s: { r: 20, c: 1 },
        e: { r: 20, c: 9 },
        s: { r: 21, c: 1 },
        e: { r: 21, c: 9 },
        s: { r: 22, c: 1 },
        e: { r: 22, c: 9 },
        s: { r: 23, c: 1 },
        e: { r: 23, c: 9 },
        s: { r: 24, c: 1 },
        e: { r: 24, c: 9 },
        s: { r: 25, c: 1 },
        e: { r: 25, c: 9 },
        s: { r: 26, c: 1 },
        e: { r: 26, c: 9 },
        s: { r: 27, c: 1 },
        e: { r: 27, c: 9 },
        s: { r: 28, c: 1 },
        e: { r: 28, c: 9 },
        // 단위
        s: { r: 9, c: 10 },
        e: { r: 9, c: 11 },
        s: { r: 10, c: 10 },
        e: { r: 10, c: 11 },
        s: { r: 11, c: 10 },
        e: { r: 11, c: 11 },
        s: { r: 12, c: 10 },
        e: { r: 12, c: 11 },
        s: { r: 13, c: 10 },
        e: { r: 13, c: 11 },
        s: { r: 14, c: 10 },
        e: { r: 14, c: 11 },
        s: { r: 15, c: 10 },
        e: { r: 15, c: 11 },
        s: { r: 16, c: 10 },
        e: { r: 16, c: 11 },
        s: { r: 17, c: 10 },
        e: { r: 17, c: 11 },
        s: { r: 18, c: 10 },
        e: { r: 18, c: 11 },
        s: { r: 19, c: 10 },
        e: { r: 19, c: 11 },
        s: { r: 20, c: 10 },
        e: { r: 20, c: 11 },
        s: { r: 21, c: 10 },
        e: { r: 21, c: 11 },
        s: { r: 22, c: 10 },
        e: { r: 22, c: 11 },
        s: { r: 23, c: 10 },
        e: { r: 23, c: 11 },
        s: { r: 24, c: 10 },
        e: { r: 24, c: 11 },
        s: { r: 25, c: 10 },
        e: { r: 25, c: 11 },
        s: { r: 26, c: 10 },
        e: { r: 26, c: 11 },
        s: { r: 27, c: 10 },
        e: { r: 27, c: 11 },
        s: { r: 28, c: 10 },
        e: { r: 28, c: 11 },
        // 수량
        s: { r: 9, c: 12 },
        e: { r: 9, c: 13 },
        s: { r: 10, c: 12 },
        e: { r: 10, c: 13 },
        s: { r: 11, c: 12 },
        e: { r: 11, c: 13 },
        s: { r: 12, c: 12 },
        e: { r: 12, c: 13 },
        s: { r: 13, c: 12 },
        e: { r: 13, c: 13 },
        s: { r: 14, c: 12 },
        e: { r: 14, c: 13 },
        s: { r: 15, c: 12 },
        e: { r: 15, c: 13 },
        s: { r: 16, c: 12 },
        e: { r: 16, c: 13 },
        s: { r: 17, c: 12 },
        e: { r: 17, c: 13 },
        s: { r: 18, c: 12 },
        e: { r: 18, c: 13 },
        s: { r: 19, c: 12 },
        e: { r: 19, c: 13 },
        s: { r: 20, c: 12 },
        e: { r: 20, c: 13 },
        s: { r: 21, c: 12 },
        e: { r: 21, c: 13 },
        s: { r: 22, c: 12 },
        e: { r: 22, c: 13 },
        s: { r: 23, c: 12 },
        e: { r: 23, c: 13 },
        s: { r: 24, c: 12 },
        e: { r: 24, c: 13 },
        s: { r: 25, c: 12 },
        e: { r: 25, c: 13 },
        s: { r: 26, c: 12 },
        e: { r: 26, c: 13 },
        s: { r: 27, c: 12 },
        e: { r: 27, c: 13 },
        s: { r: 28, c: 12 },
        e: { r: 28, c: 13 },
        // 단가
        s: { r: 9, c: 14 },
        e: { r: 9, c: 15 },
        s: { r: 10, c: 14 },
        e: { r: 10, c: 15 },
        s: { r: 11, c: 14 },
        e: { r: 11, c: 15 },
        s: { r: 12, c: 14 },
        e: { r: 12, c: 15 },
        s: { r: 13, c: 14 },
        e: { r: 13, c: 15 },
        s: { r: 14, c: 14 },
        e: { r: 14, c: 15 },
        s: { r: 15, c: 14 },
        e: { r: 15, c: 15 },
        s: { r: 16, c: 14 },
        e: { r: 16, c: 15 },
        s: { r: 17, c: 14 },
        e: { r: 17, c: 15 },
        s: { r: 18, c: 14 },
        e: { r: 18, c: 15 },
        s: { r: 19, c: 14 },
        e: { r: 19, c: 15 },
        s: { r: 20, c: 14 },
        e: { r: 20, c: 15 },
        s: { r: 21, c: 14 },
        e: { r: 21, c: 15 },
        s: { r: 22, c: 14 },
        e: { r: 22, c: 15 },
        s: { r: 23, c: 14 },
        e: { r: 23, c: 15 },
        s: { r: 24, c: 14 },
        e: { r: 24, c: 15 },
        s: { r: 25, c: 14 },
        e: { r: 25, c: 15 },
        s: { r: 26, c: 14 },
        e: { r: 26, c: 15 },
        s: { r: 27, c: 14 },
        e: { r: 27, c: 15 },
        s: { r: 28, c: 14 },
        e: { r: 28, c: 15 },
        // 총 금액
        s: { r: 9, c: 16 },
        e: { r: 9, c: 19 },
        s: { r: 10, c: 16 },
        e: { r: 10, c: 19 },
        s: { r: 11, c: 16 },
        e: { r: 11, c: 19 },
        s: { r: 12, c: 16 },
        e: { r: 12, c: 19 },
        s: { r: 13, c: 16 },
        e: { r: 13, c: 19 },
        s: { r: 14, c: 16 },
        e: { r: 14, c: 19 },
        s: { r: 15, c: 16 },
        e: { r: 15, c: 19 },
        s: { r: 16, c: 16 },
        e: { r: 16, c: 19 },
        s: { r: 17, c: 16 },
        e: { r: 17, c: 19 },
        s: { r: 18, c: 16 },
        e: { r: 18, c: 19 },
        s: { r: 19, c: 16 },
        e: { r: 19, c: 19 },
        s: { r: 20, c: 16 },
        e: { r: 20, c: 19 },
        s: { r: 21, c: 16 },
        e: { r: 21, c: 19 },
        s: { r: 22, c: 16 },
        e: { r: 22, c: 19 },
        s: { r: 23, c: 16 },
        e: { r: 23, c: 19 },
        s: { r: 24, c: 16 },
        e: { r: 24, c: 19 },
        s: { r: 25, c: 16 },
        e: { r: 25, c: 19 },
        s: { r: 26, c: 16 },
        e: { r: 26, c: 19 },
        s: { r: 27, c: 16 },
        e: { r: 27, c: 19 },
        s: { r: 28, c: 16 },
        e: { r: 28, c: 19 },
        // 페이지 및 소계
        s: { r: 29, c: 0 },
        e: { r: 29, c: 19 },
        // 인수자
        s: { r: 30, c: 0 },
        e: { r: 30, c: 19 },
        // 총계
        s: { r: 31, c: 14 },
        e: { r: 31, c: 15 },
        s: { r: 31, c: 16 },
        e: { r: 31, c: 19 },
        // 빈행
        s: { r: 31, c: 0 },
        e: { r: 31, c: 13 },
      });

      // 셀 높이 지정
      ws["!rows"] = [
        { hpt: 100 },
        { hpt: 40 },
        { hpt: 20 },
        { hpt: 20 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
      ];

      // 셀 너비 지정
      ws["!cols"] = [
        { wch: 10 },
        { wch: "unset" },
        { wch: "unset" },
        { wch: "unset" },
        { wch: "unset" },
        { wch: "unset" },
        { wch: "unset" },
        { wch: "unset" },
        { wch: "unset" },
        { wch: 10 },
      ];

      // 워크북에 워크시트 추가
      XLSX.utils.book_append_sheet(wb, ws, wsName);
    }

    XLSX.writeFile(wb, `거래명세서_${deliveryInfo.franchise_name}_${deliveryInfo.order_date}.xlsx`);
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (resData?.length !== 0) {
      DownTradingStatement();
    }
  }, [resData]);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {loading ? <Loading /> : null}
          <HeaderSub title={"주문 조회"} />
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                <FlexBox
                  align={"start"}
                  justify={"space-between"}
                  wrap={"wrap"}
                  gap={"10px"}
                  direction={"column"}
                  directionMd={"row"}
                >
                  <Title size={"16px"} sizeMd={"26px"} color={"#1E1E1E"} weight={"700"}>
                    주문일자 : {deliveryInfo ? deliveryInfo.order_date : ""}
                  </Title>
                  {!isMobile && (
                    <FlexBox gap={"10px"} justify={"end"} width={"100%"} widthMd={"unset"}>
                      <Btn
                        width={"100%"}
                        widthMd={"unset"}
                        size={"14px"}
                        color={"#666"}
                        border={"1px solid #666"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        hoverColor={"#fff"}
                        hoverBackground={"#C7C7C7"}
                        hoverBorder={"1px solid transparent"}
                        onClick={onClickTradingStatementBtn}
                      >
                        거래명세서 다운
                      </Btn>
                      <Btn
                        size={"14px"}
                        color={"#666"}
                        border={"1px solid #666"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        hoverColor={"#fff"}
                        hoverBackground={"#C7C7C7"}
                        hoverBorder={"1px solid transparent"}
                        onClick={ExportHandler}
                      >
                        엑셀 다운
                      </Btn>
                    </FlexBox>
                  )}
                </FlexBox>
                {/* 모바일 버전 */}
                {isMobile && (
                  <FlexBox margin={"10px 0 0"} gap={"10px"} direction={"column"} width={"100%"}>
                    <Text
                      width={"100%"}
                      widthMd={"unset"}
                      color={"#FF4A4A"}
                      size={"16px"}
                      padding={"10px 29px 10px 27px"}
                      background={"rgba(255, 116, 64, 0.10)"}
                      radius={"8px"}
                      letterSpacing={"-0.32px"}
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      품목 : <span style={{ fontWeight: "700" }}>{AddComma(orderList ? orderList?.length : "")}개</span>
                    </Text>
                    <Text
                      width={"100%"}
                      widthMd={"unset"}
                      color={"#FF4A4A"}
                      size={"16px"}
                      padding={"10px 29px 10px 27px"}
                      background={"rgba(255, 116, 64, 0.10)"}
                      radius={"8px"}
                      letterSpacing={"-0.32px"}
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      금액 :{" "}
                      <span style={{ fontWeight: "700" }}>
                        {AddComma(orderInfo ? orderInfo?.product_amount : "")}원
                      </span>
                    </Text>
                  </FlexBox>
                )}
                <WhiteBox>
                  {/* 웹버전 */}
                  {!isMobile && (
                    <FlexBox gap={"10px"} justify={"start"} width={"100%"} widthMd={"auto"}>
                      <Text
                        width={"100%"}
                        widthMd={"unset"}
                        color={"#FF4A4A"}
                        size={"16px"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        style={{
                          flexShrink: 0,
                        }}
                      >
                        품목 :{" "}
                        <span style={{ fontWeight: "700" }}>{AddComma(orderList ? orderList?.length : "")}개</span>
                      </Text>
                      <Text
                        width={"100%"}
                        widthMd={"unset"}
                        color={"#FF4A4A"}
                        size={"16px"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        style={{
                          flexShrink: 0,
                        }}
                      >
                        금액 :{" "}
                        <span style={{ fontWeight: "700" }}>
                          {AddComma(orderInfo ? orderInfo?.product_amount : "")}원
                        </span>
                      </Text>
                    </FlexBox>
                  )}
                  <TableWrap>
                    <table style={{ width: "100%" }}>
                      <colgroup>
                        <col style={{ width: "9.5%" }} />
                        <col style={{ width: "10.5%" }} />
                        <col style={{ width: "15.5%" }} />
                        <col />
                        <col style={{ width: "10.5%" }} />
                        <col style={{ width: "14%" }} />
                        <col style={{ width: "14%" }} />
                        <col style={{ width: "9.5%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>No</Th>
                          <Th>주문번호</Th>
                          <Th>바코드 번호</Th>
                          <Th>상품명</Th>
                          <Th>수량</Th>
                          <Th>공급가</Th>
                          <Th>총 금액</Th>
                          <Th>비고</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderList.length > 0 ? (
                          orderList?.map((item, key) => (
                            <tr key={item?.order_id}>
                              <Td>{key + 1}</Td>
                              <Td>{item.order_id}</Td>
                              <Td>{item.barcode_num}</Td>
                              <Td align={"left"} style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {item.p_name}
                              </Td>
                              <Td>{item.quantity === 0 || item.quantity === null ? "-" : item.quantity}</Td>
                              <Td>{AddComma(item.shipping_price)}원</Td>
                              <Td>{AddComma(item.shipping_price * item.quantity)}원</Td>
                              <Td>
                                {item.status
                                  ? item.status == "add"
                                    ? "상품 추가"
                                    : item.status == "edit"
                                      ? "수량 수정"
                                      : "-"
                                  : "-"}
                              </Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={7}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                </WhiteBox>

                <FlexBox align={"flex-start"} wrap={"wrap"} wrapMd={"flex-wrap"} gapMd={"10px"} gapLg={"20px"}>
                  <LeftArea>
                    <WhiteBox margin={"0"} marginMd={"0"} paddingMd="28px 30px">
                      <Title
                        color={"#1E1E1E"}
                        weight={"700"}
                        size={"16px"}
                        sizeMd={"18px"}
                        style={{
                          paddingBottom: "10px",
                          borderBottom: "1px solid #666",
                        }}
                      >
                        배송지 정보
                      </Title>
                      <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          가맹점명
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          {deliveryInfo ? deliveryInfo.franchise_name : ""}
                        </Text>
                      </OrderAddrBox>
                      <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          휴대폰번호
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          {deliveryInfo ? deliveryInfo.tel : ""}
                        </Text>
                      </OrderAddrBox>
                      <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          주소
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          {deliveryInfo ? deliveryInfo.address : ""}
                        </Text>
                      </OrderAddrBox>
                    </WhiteBox>
                    {deliveryInfo ? (
                      <>
                        <WhiteBox paddingMd="28px 30px">
                          <Title
                            color={"#1E1E1E"}
                            weight={"700"}
                            size={"16px"}
                            sizeMd={"18px"}
                            style={{
                              paddingBottom: "10px",
                              borderBottom: "1px solid #666",
                            }}
                          >
                            배송 정보
                          </Title>

                          <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                            <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                              배송상태
                            </Text>
                            <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                              {orderStatus == "before-delivery"
                                ? "배송전"
                                : orderStatus == "on-delivery"
                                  ? "배송중"
                                  : orderStatus == "over-delivery"
                                    ? "배송완료"
                                    : orderStatus == "cancel-order"
                                      ? "주문취소"
                                      : ""}
                            </Text>
                          </OrderAddrBox>
                          {orderStatus !== "before-delivery" && orderStatus !== "cancel-order" && (
                            <>
                              <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                                <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                                  택배사
                                </Text>
                                <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                                  {deliveryInfo.courier_company ? deliveryInfo.courier_company : ""}
                                </Text>
                              </OrderAddrBox>
                              <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                                <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                                  운송장번호
                                </Text>
                                <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                                  {deliveryInfo.tracking_number ? deliveryInfo.tracking_number : ""}
                                </Text>
                              </OrderAddrBox>
                            </>
                          )}
                        </WhiteBox>
                      </>
                    ) : (
                      ""
                    )}
                  </LeftArea>

                  <RightArea>
                    <WhiteBox margin={"0"} marginMd={"0"} paddingMd="28px 30px">
                      <Title
                        color={"#1E1E1E"}
                        weight={"700"}
                        size={"16px"}
                        sizeMd={"18px"}
                        style={{
                          paddingBottom: "10px",
                          borderBottom: "1px solid #666",
                        }}
                      >
                        결제 정보
                      </Title>
                      <Text
                        color={"#1E1E1E"}
                        weight={"500"}
                        align={"left"}
                        padding={"20px 0 0"}
                        size={"14px"}
                        sizeMd={"18px"}
                      >
                        총 결제 금액
                      </Text>
                      <WhiteBox marginMd={"20px 0 0"} paddingMd="20px">
                        <FlexBox justify={"space-between"}>
                          <Text color={"#1E1E1E"} weight={"500"} size={"14px"} sizeMd={"18px"}>
                            상품 가격
                          </Text>
                          <Text color={"#1E1E1E"} size={"14px"} sizeMd={"18px"}>
                            {AddComma(orderInfo.product_amount)}원
                          </Text>
                        </FlexBox>
                        <FlexBox justify={"space-between"} padding={"20px 0 0"}>
                          <Text color={"#1E1E1E"} weight={"500"} size={"14px"} sizeMd={"18px"}>
                            {orderStatus == "before-delivery" ? "예상 택배비" : "택배비"}
                          </Text>
                          <Text color={"#1E1E1E"} size={"14px"} sizeMd={"18px"}>
                            {AddComma(shippingFee)}원
                          </Text>
                        </FlexBox>
                        <FlexBox
                          justify={"space-between"}
                          margin={"20px 0 0"}
                          padding={"20px 0 0"}
                          style={{
                            borderTop: "1px solid #969696",
                          }}
                        >
                          <Text color={"#1E1E1E"} weight={"500"} size={"14px"} sizeMd={"18px"}>
                            결제 금액
                          </Text>
                          <Text color={"#1E1E1E"} weight={"700"} size={"14px"} sizeMd={"18px"}>
                            {AddComma(orderInfo.product_amount + shippingFee)}원
                          </Text>
                        </FlexBox>
                      </WhiteBox>
                    </WhiteBox>
                  </RightArea>
                </FlexBox>
              </Container>
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}
