// 외부 모듈
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

// 내부 모듈
import {
  ContentContainer,
  Btn,
  Container,
  FlexBox,
  SquareImg,
  Text,
  Title,
  Wrapper,
  WhiteBox,
} from "../../Assets/css/commonVer2";
import { DELIVERY_STATUS } from "../../Utiles/ListData";
import img_arr from "../../Assets/images/ico_page_next.svg";
import img_order1 from "../../Assets/images/img_order1.svg";
import img_order2 from "../../Assets/images/img_order2.svg";
import img_order3 from "../../Assets/images/img_order3.svg";
import img_noimage from "../../Assets/images/no_image.jpg";
import Header from "../../Components/Header/HeaderVer2";
import Authority from "../../Components/Modal/Authority";
import API from "../../Utiles/API";
import { AddComma } from "../../Utiles/Custom";
import img_pgprev from "../../Assets/images/ico_page_prev.svg";
import img_pgnext from "../../Assets/images/ico_page_next.svg";
import img_pgnext_disable from "../../Assets/images/ico_page_next_disable.svg";
import InfoModal from "../../Components/Modal/InfoModal";

// 상품관리 > 주문내역 관리

function OrderHistory() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [franchise_id] = useState(sessionStorage.getItem("franchise_id"));

  const [today, setToday] = useState(new Date());
  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(("0" + (today.getMonth() + 1)).slice(-2));

  const [saveModal, setSaveModal] = useState(false);
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");

  // API
  const OrderHistory = async () => {
    await API.get(`/storeowner/order-list?select_date=${year}-${month}&franchise_id=${franchise_id}`)
      .then((res) => {
        if (res.data.status) {
          setData(res.data.results);
        }
      })
      .catch((err) => {});
  };

  const shopping = async (cnt, e) => {
    API.post(
      `/storeowner/shopping-basket`,
      {
        user_id: sessionStorage.getItem("user_id"),
        product_id: e,
        quantity: cnt,
      },
      { header: { "content-type": "multipart/form-data" } }
    )
      .then(function (res) {
        if (res.data.status) {
          setSaveModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(function (error) {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const Minus = () => {
    // 현재 월(month)이 1월(1)인 경우 전년도로 업데이트하고 12월로 설정합니다.
    if (month === "01") {
      setYear(year - 1);
      setMonth("12");
    } else {
      // 그 외의 경우에는 현재 월에서 1을 빼고 값을 업데이트합니다.
      setMonth(("0" + (parseInt(month) - 1)).slice(-2));
    }
  };

  const Plus = () => {
    if (year < today.getFullYear() || (year === today.getFullYear() && parseInt(month) <= today.getMonth())) {
      // 현재 월(month)이 12월(12)인 경우 다음년도로 업데이트하고 1월로 설정합니다.
      if (month === "12") {
        setYear(year + 1);
        setMonth("01");
      } else {
        // 그 외의 경우에는 현재 월에 1을 더하고 값을 업데이트합니다.
        setMonth(("0" + (parseInt(month) + 1)).slice(-2));
      }
    }
  };

  useEffect(() => {
    OrderHistory();
  }, [month]);

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          {isMobile && <Header title={"주문내역"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <FlexBox justify={"space-between"} style={{ flex: 1 }}>
                    <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                      주문 목록 | {year}년 {month}월
                    </Title>
                    <FlexBox />
                    {!isMobile && (
                      <FlexBox gap={"25px"}>
                        <img width={"15px"} src={img_pgprev} onClick={Minus} />
                        <img
                          width={"15px"}
                          src={
                            year < today.getFullYear() ||
                            (year === today.getFullYear() && parseInt(month) <= today.getMonth())
                              ? img_pgnext
                              : img_pgnext_disable
                          }
                          onClick={Plus}
                        />
                      </FlexBox>
                    )}
                  </FlexBox>
                )}

                {isMobile && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FlexBox
                      justify={"center"}
                      style={{ display: "inline-flex" }}
                      border="1px solid #F2F2F2"
                      radius={"6px"}
                      background="#fff"
                      gap={"20px"}
                      padding={"8px"}
                    >
                      <img width={"10px"} src={img_pgprev} onClick={Minus} style={{ marginLeft: "10px" }} />
                      <Title size={"18px"} color={"#2d2d2d"} weight={"700"}>
                        {year}년 {month}월
                      </Title>
                      <img
                        width={"10px"}
                        src={
                          year < today.getFullYear() ||
                          (year === today.getFullYear() && parseInt(month) <= today.getMonth())
                            ? img_pgnext
                            : img_pgnext_disable
                        }
                        onClick={Plus}
                        style={{ marginRight: "10px" }}
                      />
                    </FlexBox>
                  </div>
                )}
                {data?.map((data, i) => {
                  const nowDate = Object.keys(data);
                  const orderData = data[nowDate];
                  return (
                    <WhiteBox paddingMd="28px 30px" key={i}>
                      <FlexBox justify={"space-between"} marginMd={"0 0 10px 0"} margin={"0 0 15px 0"}>
                        <Text color={"#2d2d2d"} weight={"700"} size={"16px"} sizeMd={"18px"}>
                          주문일자: {nowDate}{" "}
                        </Text>
                        <Btn
                          color={"#666"}
                          size="14px"
                          border={"unset"}
                          borderMd={"1px solid #666"}
                          hoverBackground={"#fff"}
                          hoverBorder={"1px solid #666"}
                          padding={"9px 19px"}
                          onClick={() =>
                            navigate("/goods/orderhistory/view", {
                              state: { order_date: nowDate },
                            })
                          }
                        >
                          상세보기 →
                        </Btn>
                      </FlexBox>
                      <FlexBox
                        justify={"flex-start"}
                        margin={"0 0 10px 0"}
                        padding={"0 0 20px"}
                        style={{ borderBottom: "1px solid #666" }}
                        gap="10px"
                      >
                        <Text
                          width={"auto"}
                          widthMd={"unset"}
                          color={"#FF4A4A"}
                          size={"16px"}
                          weight={"400"}
                          padding={"10px 29px 10px 27px"}
                          background={"rgba(255, 116, 64, 0.10)"}
                          marginMd={"0 10px 0 0 "}
                          radius={"8px"}
                          letterSpacing={"-0.32px"}
                          lineHeight={"normal"}
                        >
                          주문 총 <span style={{ fontWeight: "700" }}>{orderData.order_cnt}건</span>
                        </Text>
                        <Text color={"#1E1E1E"} size={"16px"} sizeMd={"18px"}>
                          {orderData.order_products[0].status
                            ? DELIVERY_STATUS.find((item) => item.value === orderData.order_products[0].status).text
                            : "-"}
                        </Text>
                      </FlexBox>
                      <FlexBox justify={"flex-start"} wrap={"wrap"} gap={"10px"} gapMd={"20px"}>
                        {orderData.order_products?.map((perProducts, key) => {
                          return (
                            <OrderlineBox
                              style={{
                                width: "100%",
                                // backgroundColor: "#FAFAFA",
                              }}
                              key={key}
                            >
                              <FlexBox justify={"space-between"} style={{ marginBottom: "10px" }}>
                                <Text color={"#1E1E1E"} size={"14px"} sizeMd={"18px"}>
                                  주문 번호 {perProducts.order_id}
                                  <span style={{ color: "#1E1E1E", fontWeight: "700" }}>
                                    {"  "}총 {perProducts.order_products_cnt}개
                                  </span>{" "}
                                </Text>
                              </FlexBox>
                              <FlexBox justify={"flex-start"} wrap={"wrap"} gap={"10px"} gapMd={"20px"}>
                                {perProducts.products?.map((item, idx) => {
                                  if (idx < 4) {
                                    return (
                                      <OrderItem gap={"10px"} gapMd={"20px"}>
                                        <ShopImg
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            navigate("/goods/view", {
                                              state: { idx: item.product_id },
                                            })
                                          }
                                        >
                                          <SquareImg>
                                            <div>
                                              <img
                                                src={
                                                  item.image_path_1
                                                    ? process.env.REACT_APP_S3_KEY + item.image_path_1
                                                    : img_noimage
                                                }
                                              />
                                            </div>
                                          </SquareImg>
                                        </ShopImg>
                                        <ShopText>
                                          <Text color={"#FF4A4A"} align={"left"} size={"10px"} sizeMd={"12px"}>
                                            {item.brand}
                                          </Text>
                                          <TextTit
                                            weight={"500"}
                                            color={"#1E1E1E"}
                                            align={"left"}
                                            size={"14px"}
                                            sizeMd={"18px"}
                                          >
                                            {item.p_name}
                                          </TextTit>
                                          <FlexBox justify={"space-between"}>
                                            <Text
                                              family="Montserrat"
                                              color={"#666"}
                                              align={"left"}
                                              size={"12px"}
                                              sizeMd={"16px"}
                                            >
                                              {item.quantity ? item.quantity : 0}개 |{" "}
                                              {item.shipping_price ? AddComma(item.shipping_price) : 0}원
                                            </Text>
                                          </FlexBox>
                                          <Text
                                            family="Montserrat"
                                            color={"#FF4A4A"}
                                            weight={"700"}
                                            align={"left"}
                                            size={"14px"}
                                            className="d-block d-md-none"
                                          >
                                            {item.quantity || item.shipping_price
                                              ? AddComma(item.shipping_price * item.quantity)
                                              : 0}
                                            원
                                          </Text>
                                          <Btn
                                            size={"12px"}
                                            color={"#FF4A4A"}
                                            border={"1px solid #FF4A4A"}
                                            padding={"4px 12px"}
                                            background={"#fff"}
                                            width="auto"
                                            className="d-block d-md-none"
                                            style={{ marginLeft: "auto" }}
                                            onClick={() => {
                                              if (item.quantity > 0) {
                                                shopping(item.quantity, item.product_id);
                                              } else {
                                                alert("주문수량이 0개인 상품이라 장바구니에 담을 수 없습니다.");
                                              }
                                            }}
                                          >
                                            장바구니 담기
                                          </Btn>
                                        </ShopText>
                                        <FlexBox
                                          direction={"column"}
                                          justify="space-between"
                                          style={{ alignItems: "flex-end", minHeight: "70px" }}
                                        >
                                          <Btn
                                            size={"14px"}
                                            width={"120px"}
                                            padding={"9px 19px"}
                                            color={"#FF4A4A"}
                                            background={"#FFf"}
                                            className="d-none d-md-block"
                                            onClick={() => {
                                              if (item["quantity"] > 0) {
                                                shopping(item["quantity"], item["product_id"]);
                                              } else {
                                                alert("주문수량이 0개인 상품이라 장바구니에 담을 수 없습니다.");
                                              }
                                            }}
                                          >
                                            장바구니 담기
                                          </Btn>
                                          <Text
                                            family="Montserrat"
                                            color={"#FF4A4A"}
                                            weight={"700"}
                                            align={"left"}
                                            size={"14px"}
                                            sizeMd={"16px"}
                                            className="d-none d-md-block"
                                            style={{ marginTop: "auto" }}
                                          >
                                            {item.quantity || item.shipping_price
                                              ? AddComma(item.shipping_price * item.quantity)
                                              : 0}
                                            원
                                          </Text>
                                        </FlexBox>
                                      </OrderItem>
                                    );
                                  }
                                })}
                              </FlexBox>
                            </OrderlineBox>
                          );
                        })}
                      </FlexBox>
                    </WhiteBox>
                  );
                })}
                {data.length < 1 ? (
                  <WhiteBox style={{ marginBottom: "20px" }}>
                    <FlexBox justify={"center"} margin="20px">
                      <Text color={"#2d2d2d"} weight={"700"} size={"14px"} sizeMd={"16px"}>
                        이달의 주문 내역이 없습니다.
                      </Text>
                    </FlexBox>
                  </WhiteBox>
                ) : (
                  ""
                )}
                <WhiteBox padding="12px 16px" paddingMd="28px 30px">
                  <Title size={"18px"} color={"#1E1E1E"} weight={"700"}>
                    주문 상품 안내
                  </Title>
                  {isMobile ? (
                    <div style={{ paddingBottom: "30px" }}>
                      <FlexBox justify="start" padding="20px 0">
                        <img style={{ maxWidth: "70px" }} src={img_order1} />
                        <Text size={"10px"} color={"#6c6c6c"} align={"left"} padding={"0 0 0 10px"}>
                          <span style={{ color: "#1E1E1E", fontWeight: "700", fontSize: "16px" }}>결제완료 </span>
                          <br />
                          주문, 결제 확인이 완료되었습니다.
                        </Text>
                      </FlexBox>
                      <FlexBox justify="start" padding="0 0 20px">
                        <img style={{ maxWidth: "70px" }} src={img_order2} />
                        <Text size={"10px"} color={"#6c6c6c"} align={"left"} padding={"0 0 0 10px"}>
                          <span style={{ color: "#1E1E1E", fontWeight: "700", fontSize: "16px" }}>상품준비중 </span>
                          <br />
                          결제 확인 후 영업일 3일 안에 상품을 준비합니다.
                        </Text>
                      </FlexBox>
                      <FlexBox justify="start">
                        <img style={{ maxWidth: "70px" }} src={img_order3} />
                        <Text size={"10px"} color={"#6c6c6c"} align={"left"} padding={"0 0 0 10px"}>
                          <span style={{ color: "#1E1E1E", fontWeight: "700", fontSize: "16px" }}>배송시작 </span>
                          <br />
                          상품 준비 완료 후 배송이 시작됩니다.
                        </Text>
                      </FlexBox>
                    </div>
                  ) : (
                    <FlexBox margin="20px 0 40px" gap="20px" align="flex-start">
                      <FlexBox direction="column">
                        <img style={{ maxWidth: "280px" }} src={img_order1} />
                        <Text margin="10px 0" color={"#1E1E1E"} weight={"700"} size={"12px"} sizeMd={"20px"}>
                          결제완료
                        </Text>
                        <Text color={"#1E1E1E"} className="d-none d-md-block">
                          주문, 결제 확인이 <br />
                          완료되었습니다.
                        </Text>
                      </FlexBox>

                      <img width={"15px"} src={img_arr} style={{ paddingTop: "70px" }} />

                      <FlexBox direction="column">
                        <img style={{ maxWidth: "280px" }} src={img_order2} />
                        <Text margin="10px 0 " color={"#1E1E1E"} weight={"700"} size={"12px"} sizeMd={"20px"}>
                          상품 준비중
                        </Text>
                        <Text color={"#1E1E1E"} className="d-none d-md-block">
                          확인 후 영업일 3일 안에 <br />
                          상품을 준비합니다.
                        </Text>
                      </FlexBox>

                      <img width={"15px"} src={img_arr} style={{ paddingTop: "70px" }} />

                      <FlexBox direction="column">
                        <img style={{ maxWidth: "280px" }} src={img_order3} />
                        <Text margin="10px 0" color={"#1E1E1E"} weight={"700"} size={"12px"} sizeMd={"20px"}>
                          배송시작
                        </Text>
                        <Text color={"#1E1E1E"} className="d-none d-md-block">
                          상품 준비 완료 후 <br />
                          배송이 시작됩니다.
                        </Text>
                      </FlexBox>
                    </FlexBox>
                  )}

                  <Text color={"#dd1212"} weight={"700"} align={"left"} size={"14px"} sizeMd={"18px"}>
                    취소/반품/교환 신청 전 확인해주세요!
                  </Text>
                  <div className="mt-2 md:mt-6">
                    <Text color={"#2d2d2d"} weight={"700"} align={"left"} size={"12px"} sizeMd={"16px"}>
                      취소
                    </Text>
                    <TextBar color={"#6c6c6c"} align={"left"} size={"12px"} sizeMd={"14px"}>
                      주문 후 영업일 기준 1일 이내 고객센터를 통해 접수해주세요.
                    </TextBar>
                    <TextBar color={"#6c6c6c"} align={"left"} size={"12px"} sizeMd={"14px"}>
                      배송이 시작된 경우 취소가 불가합니다.
                    </TextBar>
                  </div>
                  <div className="mt-2 md:mt-6">
                    <Text color={"#2d2d2d"} weight={"700"} align={"left"} size={"12px"} sizeMd={"16px"}>
                      반품
                    </Text>
                    <TextBar color={"#6c6c6c"} align={"left"} size={"12px"} sizeMd={"14px"}>
                      상품 수령 후 30일 이내 신청해주세요.
                    </TextBar>
                    <TextBar color={"#6c6c6c"} align={"left"} size={"12px"} sizeMd={"14px"}>
                      상품의 포장이 훼손된 경우 반품이 불가합니다.
                    </TextBar>
                    <TextBar color={"#6c6c6c"} align={"left"} size={"12px"} sizeMd={"14px"}>
                      초도 상품의 경우 무료 반품이 가능하며, 이후 직접 구매하신 건에 대해서는 택배비가 청구됩니다.
                    </TextBar>
                  </div>
                  <div className="mt-2 md:mt-6">
                    <Text color={"#2d2d2d"} weight={"700"} align={"left"} size={"12px"} sizeMd={"16px"}>
                      교환
                    </Text>
                    <TextBar color={"#6c6c6c"} align={"left"} size={"12px"} sizeMd={"14px"}>
                      상품의 하자가 있는 경우 고객센터를 통해 접수해주세요.
                    </TextBar>
                  </div>
                </WhiteBox>
              </Container>
              <InfoModal modal={saveModal} setModal={setSaveModal} description={"장바구니에 담겼습니다."} />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const OrderlineBox = styled.div`
  padding: 30px 0 0;
  // border: 1px solid #eaeaea;
  // border-radius: 8px;

  @media all and (max-width: 1024px) {
    padding: 15px 0;
    border: 0;
    // background-color: #fffae6;
  }
`;

const OrderItem = styled(FlexBox)`
  // position: relative;
  width: calc(50% - 10px);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
  // background-color: #fffae6;
  border-radius: 10px;
  justify-content: flex-start;
  gap: 20px;
  @media all and (max-width: 1080px) {
    width: 100%;
    padding: 12px;
    gap: 10px;
  }
  @media all and (max-width: 1024px) {
    padding: 10px;
    // border: 1px solid #eaeaea;
    // background-color: #fff;
  }
`;
const ShopImg = styled.div`
  width: 90px;
  @media all and (max-width: 1024px) {
    width: 82px;
  }
`;
const ShopText = styled.div`
  // width: calc(100% - 110px);
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media all and (max-width: 1024px) {
    // width: calc(100% - 92px);
    gap: 0;

    div {
      margin-top: 8px;

      button {
        position: absolute;
        bottom: 12px;
        right: 14px;
      }
    }
  }
`;

const TextTit = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media all and (max-width: 1024px) {
    white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.4em;
    // height: 2.8em;
  }
`;

const TextBar = styled(Text)`
  position: relative;
  padding-left: 10px;
  margin-top: 8px;
  word-break: keep-all;

  &::before {
    content: "-";
    position: absolute;
    top: 0;
    left: 0;
  }

  @media all and (max-width: 1024px) {
    margin-top: 4px;
  }
`;

const ModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FindBox = styled.div`
  background-color: #fff;
  max-width: 380px;
  max-height: 600px;
  width: 90%;
  padding: 60px 40px 40px;
  border-radius: 8px;
  position: relative;
`;

const CancleBtn = styled.button`
  background-color: #dd1212;
  height: 40px;
  width: 84px;
  left: 0px;
  top: 0px;
  border-radius: 8px;
  outline: none;
  border: 1px solid #dd1212;
  margin: 7px;
`;

export default OrderHistory;
