import React, { useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";

import API from "../../Utiles/API";
import Authority from "../../Components/Modal/Authority";

import {
  Btn,
  Container,
  Wrapper,
  FlexBox,
  Text,
  Title,
  WhiteBox,
  ContentContainer,
  RadioLabel,
  RadioIcon,
} from "../../Assets/css/commonVer2";
import HeaderSub from "../../Components/Header/HeaderSub";
import FranSelect from "../../Components/Select/FranSelect";
import InfoModal from "../../Components/Modal/InfoModal";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";

function FranchiseeKioskWrite() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const location = useLocation();
  const navigate = useNavigate();

  const [isCreate] = useState(location.state?.isCreate);
  const [franchiseIdx, setFranchiseIdx] = useState(location.state?.franchiseIdx);
  const [kioskIdx] = useState(location.state?.kioskIdx);
  const [isUseCashReceipt, setIsUseCashReceipt] = useState(location.state?.isUseCashReceipt);

  const [franShow, setFranShow] = useState(false);
  const [franText, setFranText] = useState(location.state?.franchiseName || "가맹점 전체");

  const [saveModal, setSaveModal] = useState(false);
  const [listModal, setListModal] = useState(false);
  const [infoModalMode, setInfoModalMode] = useState("");

  const [textModal, setTextModal] = useState(false); // 모달 창 표시 여부 상태
  const [textMsg, setTextMsg] = useState("");

  const postKiosk = async () => {
    await API.post("/kiosk", {
      franchise_id: franchiseIdx,
      cash_receipts: isUseCashReceipt,
    })
      .then((res) => {
        if (res.data.status) {
          setInfoModalMode("goBack");
          setTextMsg("등록이 완료되었습니다.");
          setTextModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(function (error) {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const updateKiosk = async () => {
    if (kioskIdx !== -1) {
      await API.put("/kiosk", {
        kiosk_id: kioskIdx,
        franchise_id: franchiseIdx,
        cash_receipts: isUseCashReceipt,
      })
        .then((res) => {
          if (res.data.status) {
            setInfoModalMode("goBack");
            setTextMsg("수정이 완료되었습니다.");
            setTextModal(true);
          } else {
            setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
            setTextModal(true);
          }
        })
        .catch(function (error) {
          setTextMsg("E01\n잠시후 다시 시도해주세요.");
          setTextModal(true);
        });
    }
  };

  const onSubmit = (e) => {
    switch (e) {
      case 1: // 뒤로 가기
        setSaveModal(false);
        navigate(-1);
        break;
      case 2: // 저장
        postKiosk();
        setSaveModal(false);
        break;
      case 3: // 수정
        updateKiosk();
        setSaveModal(false);
        break;
    }
  };

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          <HeaderSub title={isCreate ? "키오스크 등록" : "키오스크 수정"} />
          <ContentContainer>
            <Wrapper>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    키오스크 관리 📋
                  </Title>
                )}
                <WhiteBox>
                  <Text align={"left"} color={"#1E1E1E"} weight={"700"} size={"16px"} sizeMd={"20px"}>
                    {isCreate ? "키오스크 등록" : "키오스크 수정"}
                  </Text>

                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    margin={"20px 0 20px"}
                    marginMd={"20px 0 30px"}
                    wrap={"wrap"}
                    wrapMd={"unset"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        가맹점
                      </Text>
                      <FranSelect
                        width={"100%"}
                        gubun={"fran"}
                        selectBtnText={franText}
                        setTextChange={setFranText}
                        setIdxChange={setFranchiseIdx}
                        show={franShow}
                        setFranShow={setFranShow}
                        placeholder={"가맹점을"}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        현금영수증 등록 여부
                      </Text>
                      <FlexBox justify={"start"} gap={"10px"}>
                        <RadioLabel>
                          <input
                            type="radio"
                            value={isUseCashReceipt}
                            checked={isUseCashReceipt}
                            onChange={() => setIsUseCashReceipt(true)}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          Yes
                        </RadioLabel>

                        <RadioLabel>
                          <input
                            type="radio"
                            value={!isUseCashReceipt}
                            checked={!isUseCashReceipt}
                            onChange={() => setIsUseCashReceipt(false)}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          No
                        </RadioLabel>
                      </FlexBox>
                    </Contents>
                  </FlexBox>
                  <FlexBox justify={"end"}>
                    <FlexBox flex={"1 0 auto"} flexMd={"unset"} gap={"8px"} gapMd={"10px"}>
                      <Btn
                        width={"100%"}
                        widthMd={"90px"}
                        size={"14px"}
                        color={"#666"}
                        border={"1px solid #666"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        hoverColor={"#fff"}
                        hoverBackground={"#C7C7C7"}
                        hoverBorder={"1px solid transparent"}
                        onClick={() => setListModal(true)}
                      >
                        목록
                      </Btn>
                      <Btn
                        width={"100%"}
                        widthMd={"90px"}
                        color={"#fff"}
                        background={"#FF4A4A"}
                        size={"14px"}
                        weight={"700"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        onClick={() => {
                          if (franchiseIdx >= 0) {
                            setSaveModal(true);
                          } else {
                            setTextMsg("가맹점을 선택해주세요.");
                            setTextModal(true);
                          }
                        }}
                      >
                        {isCreate ? "저장" : "수정"}
                      </Btn>
                    </FlexBox>
                  </FlexBox>
                </WhiteBox>
              </Container>
              <ConfirmModal
                modal={listModal}
                setModal={setListModal}
                onSubmit={() => onSubmit(1)}
                description={
                  isCreate
                    ? "키오스크 등록을 취소하고\n목록화면으로 이동하시겠습니까?"
                    : "키오스크 수정을 취소하고\n목록화면으로 이동하시겠습니까?"
                }
              />
              <ConfirmModal
                modal={saveModal}
                setModal={setSaveModal}
                onSubmit={() => {
                  if (isCreate) {
                    onSubmit(2);
                  } else {
                    onSubmit(3);
                  }
                }}
                description={isCreate ? "키오스크 등록을 완료하시겠습니까?" : "키오스크 수정을 완료하시겠습니까?"}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} mode={infoModalMode} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const Contents = styled.div`
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  background-color: ${(props) => props.background || ""};
  width: ${(props) => props.width || "100%"};
  border-radius: ${(props) => props.radius || "8px"};
  display: ${(props) => props.display || "block"};
  justify-content: ${(props) => props.justify ?? props.justify};
  align-items: ${(props) => props.align ?? props.align};

  @media all and (min-width: 1024px) {
    margin: ${(props) => props.marginMd || "0"};
    padding: ${(props) => props.paddingMd || "0"};
  }
`;

export default FranchiseeKioskWrite;
