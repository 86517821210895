import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";

import {
  ContentContainer,
  Btn,
  Container,
  FlexBox,
  PagerWrap,
  Td,
  Th,
  Wrapper,
  TableWrap,
  Title,
  WhiteBox,
} from "../../Assets/css/commonVer2";
import { UNIT_10_TO_30_LIST, NOTICE_STATUS } from "../../Utiles/ListData";
import Paging from "../../Components/Paging/Paging";
import API from "../../Utiles/API";
import HeaderSub from "../../Components/Header/HeaderSub";
import HandleBack from "../../Components/HandleBack";
import BasicSelect from "../../Components/Select/BasicSelect";

// 공지관리

function Notice() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const { handlePopstate } = HandleBack();

  const [isAdmin] = useState(sessionStorage.getItem("gubun") === "admin");
  const [isStoreowner] = useState(sessionStorage.getItem("gubun") === "storeonwer");

  const [data, setData] = useState([]); // 관리자
  const [storeownerData, setStoreownerData] = useState([]); // 점주용 공지글
  const [userData, setUserData] = useState([]); // 고객용 공지글

  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const parsedNoticePageState = sessionStorage.getItem("noticePageState")
    ? JSON.parse(sessionStorage.getItem("noticePageState"))
    : {};

  const [currentPage, setCurrentPage] = useState(
    parsedNoticePageState ? (parsedNoticePageState?.currentPage ? parsedNoticePageState?.currentPage : 1) : 1,
  );

  const [pageSize, setPageSize] = useState(
    parsedNoticePageState ? (parsedNoticePageState?.pageSize ? parsedNoticePageState?.pageSize : 10) : 10,
  );

  const [noticeGubun, setNoticeGubun] = useState(
    parsedNoticePageState ? (parsedNoticePageState?.noticeGubun ? parsedNoticePageState?.noticeGubun : "all") : "all",
  );

  const start = (currentPage - 1) * pageSize;

  // 공지 view를 갈 때 sessionStorage에 저장할 객체
  let noticePageState = {
    currentPage,
    pageSize,
    noticeGubun,
  };

  // API
  const getNoticeList = async () => {
    let api_link = `/admin/notice?curPage=${currentPage}&pageSize=${pageSize}`;
    if (noticeGubun !== "all") {
      api_link += `&gubun=${noticeGubun}`;
    }
    API.get(api_link).then((res) => {
      if (res.data.status) {
        setTotalCount(res.data?.cntAll);
        setData(res.data?.result);
      }
    });
  };

  // 현재 페이지, 페이지 사이즈, 점주/고객용에 해당하는 게시물로 상태 변경하기
  useEffect(() => {
    getNoticeList();
  }, [currentPage, pageSize, noticeGubun]);

  useEffect(() => {
    setTotalPage(Math.ceil(totalCount / pageSize));
  }, [totalCount, pageSize]);

  // 점주/고객용 공지글 데이터
  useEffect(() => {
    const storeownerTempData = data.filter((item) => item.gubun === "storeowner");
    const userTempData = data.filter((item) => item.gubun !== "storeowner");

    setStoreownerData(storeownerTempData);
    setUserData(userTempData);
    if (isStoreowner) {
      setTotalCount(storeownerTempData.length);
    }
  }, [data]);

  useEffect(() => {
    sessionStorage.removeItem("noticePageState");
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      <HeaderSub title={"공지사항"} />
      <ContentContainer>
        <Wrapper type={1}>
          <Container>
            {!isMobile && (
              <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                공지사항 🔔
              </Title>
            )}
            {isMobile && isAdmin && (
              <>
                <BasicSelect
                  width={"130px"}
                  data={NOTICE_STATUS}
                  selectedValue={noticeGubun}
                  setSelectedValue={setNoticeGubun}
                  canSelectAll={false}
                  placeholder={"전체"}
                />
                <Btn
                  color={"#fff"}
                  background={"#FF4A4A"}
                  size={"14px"}
                  weight={"700"}
                  width={"100%"}
                  widthMd={"unset"}
                  padding={"8px 18px"}
                  lineHeight={"22px"}
                  margin={"10px 0"}
                  onClick={() => {
                    navigate("/notice/write");
                    let jsonString = JSON.stringify(noticePageState);
                    sessionStorage.setItem("noticePageState", jsonString);
                  }}
                >
                  공지 등록
                </Btn>
              </>
            )}
            <WhiteBox margin={"0 0"}>
              {!isMobile && (
                <FlexBox justify={"space-between"} margin={"0 0 "} marginMd={"0 0 30px"}>
                  <FlexBox gap={"10px"}>
                    {isAdmin && (
                      <BasicSelect
                        width={"130px"}
                        data={NOTICE_STATUS}
                        selectedValue={noticeGubun}
                        setSelectedValue={setNoticeGubun}
                        canSelectAll={false}
                        placeholder={"전체"}
                      />
                    )}
                    <BasicSelect
                      width={"130px"}
                      data={UNIT_10_TO_30_LIST}
                      selectedValue={pageSize}
                      setSelectedValue={setPageSize}
                      canSelectAll={false}
                      setCurrentPage={setCurrentPage}
                    />
                  </FlexBox>
                  {isAdmin && (
                    <Btn
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      width={"100%"}
                      widthMd={"unset"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      onClick={() => {
                        navigate("/notice/write");
                        let jsonString = JSON.stringify(noticePageState);
                        sessionStorage.setItem("noticePageState", jsonString);
                      }}
                    >
                      공지 등록
                    </Btn>
                  )}
                </FlexBox>
              )}

              <TableWrap>
                <table style={{ width: "100%", minWidth: "1024px" }}>
                  <colgroup>
                    <col style={{ width: "10.5%" }} />
                    <col style={{ width: "10.5%" }} />
                    <col />
                    <col style={{ width: "10.5%" }} />
                    <col style={{ width: "16%" }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <Th>No</Th>
                      <Th>구분</Th>
                      <Th>제목</Th>
                      <Th>작성자</Th>
                      <Th>등록일</Th>
                    </tr>
                  </thead>
                  {isAdmin ? (
                    <tbody>
                      {data?.length > 0 ? (
                        data?.map((e, i) => (
                          <tr
                            key={i}
                            onClick={() => {
                              navigate("/notice/View", { state: { idx: e.idx } });
                              let jsonString = JSON.stringify(noticePageState);
                              sessionStorage.setItem("noticePageState", jsonString);
                            }}
                          >
                            <Td>{e.no === "공지" ? e.no : start + i + 1}</Td>
                            <Td>{e.type == "event" ? "이벤트" : "일반"}</Td>
                            <Td>{e.title}</Td>
                            <Td>{e.writer}</Td>
                            <Td>{e.createdat}</Td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={5}
                            style={{
                              borderTop: "1px solid #e1e1e1",
                              textAlign: "center",
                              padding: 10,
                              fontSize: "14px",
                            }}
                          >
                            데이터가 없습니다.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  ) : isStoreowner ? (
                    <tbody>
                      {storeownerData?.length > 0 ? (
                        storeownerData?.map((e, i) => (
                          <tr
                            key={i}
                            onClick={() => {
                              navigate("/notice/View", { state: { idx: e.idx } });
                              let jsonString = JSON.stringify(noticePageState);
                              sessionStorage.setItem("noticePageState", jsonString);
                            }}
                          >
                            <Td>{e.no === "공지" ? e.no : start + i + 1}</Td>

                            <Td>{e.type == "event" ? "이벤트" : "일반"}</Td>
                            <Td>{e.title}</Td>
                            <Td>{e.writer}</Td>
                            <Td>{e.createdat}</Td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={5}
                            style={{
                              borderTop: "1px solid #e1e1e1",
                              textAlign: "center",
                              padding: 10,
                              fontSize: "14px",
                            }}
                          >
                            데이터가 없습니다.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  ) : (
                    <tbody>
                      {userData?.length > 0 ? (
                        userData?.map((e, i) => (
                          <tr
                            key={i}
                            onClick={() => {
                              navigate("/notice/View", { state: { idx: e.idx } });
                              let jsonString = JSON.stringify(noticePageState);
                              sessionStorage.setItem("noticePageState", jsonString);
                            }}
                          >
                            <Td>{e.no === "공지" ? e.no : start + i + 1}</Td>

                            <Td>{e.type == "event" ? "이벤트" : "일반"}</Td>
                            <Td>{e.title}</Td>
                            <Td>{e.writer}</Td>
                            <Td>{e.createdat}</Td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={5}
                            style={{
                              borderTop: "1px solid #e1e1e1",
                              textAlign: "center",
                              padding: 10,
                              fontSize: "14px",
                            }}
                          >
                            데이터가 없습니다.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </table>
              </TableWrap>
              <div>
                {data?.length > 0 ? (
                  <PagerWrap margin={"40px 0 0 0"}>
                    <Paging totalPage={totalPage} limit={pageSize} page={currentPage} setPage={setCurrentPage} />
                  </PagerWrap>
                ) : (
                  ""
                )}
              </div>
            </WhiteBox>
          </Container>
        </Wrapper>
      </ContentContainer>
    </>
  );
}

export default Notice;
