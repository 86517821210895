import React from "react";
import { useNavigate } from "react-router-dom";

export default function HandleBack() {
  const navigate = useNavigate();

  const handlePopstate = () => {
    const url = window.location.href;

    if (url.slice(-1) !== "#") {
      navigate(-1);
    }
  };

  return { handlePopstate };
};

