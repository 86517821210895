import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Header from "../../Components/Header/HeaderVer2";

import {
  BoldText,
  Btn,
  Container,
  ContentContainer,
  FlexBox,
  Text,
  TextBar,
  Title,
  WhiteBox,
  Wrapper,
} from "../../Assets/css/commonVer2";
import Authority from "../../Components/Modal/Authority";
import Loading from "../../Components/Loading";
import { AddComma } from "../../Utiles/Custom";
import close from "../../Assets/images/close.png";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import InfoModal from "../../Components/Modal/InfoModal";
import API from "../../Utiles/API";
import InputModal from "../../Components/Modal/InputModal";

// 혜택관리 > 추가 포인트 관리

export default function BenefitsPoint() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [loading, setLoading] = useState(false);
  const [deleteIdx, setDeleteIdx] = useState(null); // 삭제할 item idx

  // 모달
  const [addRangeModal, setAddRangeModal] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");

  const [rangeData, setRangeData] = useState([]);
  const [tempRangeValue, setTempRangeValue] = useState({
    more: 0,
    less: 0,
    ratio: 0
  });

  // 추가포인트 범위, 비율 get
  const getRangeData = async () => {
    await API.get(`/admin/charge-point-ratio`)
      .then((res) => {
        if (res.data.status) {
          setRangeData(res.data.results);
        }
      })
  }

  // 저장하기 post
  const onSubmit = async () => {
    if (tempRangeValue.less <= 0 || tempRangeValue.less <= tempRangeValue.more) {
      showTextModal("올바른 범위를 입력 해 주세요.");
      return;
    } else if (tempRangeValue.ratio <= 0) {
      showTextModal("추가포인트 비율은 0이 될 수 없습니다.");
      return;
    }

    setLoading(true);

    await API.post(`/admin/charge-point-ratio`, tempRangeValue)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setTempRangeValue({
            more: 0,
            less: 0,
            ratio: 0
          });
          setAddRangeModal(false);
          getRangeData();
        } else {
          showTextModal(res.data.msg || "잠시후 다시 시도해주세요.");
        }
      }).catch((error) => {
        setLoading(false);
        showTextModal(error.response.data?.meta?.description || "잠시후 다시 시도해주세요.");
      })
  }

  // 금액대 delete
  const handleDeleteRange = async () => {
    setDeleteConfirmModal(false);
    setLoading(true);

    await API.delete(`/admin/charge-point-ratio?point_charge_ratio_id=${deleteIdx}`)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          getRangeData();
        } else {
          showTextModal(res.data.msg || "잠시후 다시 시도해주세요.");
        }
      }).catch(() => {
        setLoading(false);
        showTextModal("E01\n잠시후 다시 시도해주세요.");
      })
  }

  const showTextModal = (msg) => {
    setTextMsg(msg);
    setTextModal(true);
  };

  useEffect(() => {
    getRangeData();
  }, []);

  return (
    <>
      {
        sessionStorage.getItem("gubun") !== "admin" ? (
          <Authority title={"관리자"} />
        ) : (
          <>
            {loading ? <Loading /> : null}
            {isMobile && <Header title={"추가 포인트 관리"} />}
            <ContentContainer>
              <Wrapper>
                <Container>
                  {!isMobile && (
                    <>
                      <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                        추가 포인트 관리
                      </Title>
                      <FlexBox />
                    </>
                  )}

                  {/* 웹 - 안내사항 시작 */}
                  <div className="greyBox d-none d-md-block" style={{ marginTop: "24px", padding: "28px 40px" }}>
                    <Text
                      color={"#666666"}
                      size={"18px"}
                      weight={"700"}
                      align={"left"}
                      margin={"0 0 10px 0"}
                      style={{
                        latterSpacing: "-0.36px",
                        lineHeight: "normal",
                      }}
                    >
                      유의사항
                    </Text>
                    <TextBar
                      align={"left"}
                      style={{
                        latterSpacing: "-0.28px",
                        lineHeight: "25px",
                      }}
                    >
                      충전 최대 금액은{" "}
                      <BoldText>100,000원</BoldText>입니다.
                    </TextBar>
                    <TextBar
                      align={"left"}
                      style={{
                        latterSpacing: "-0.28px",
                        lineHeight: "25px",
                      }}
                    >
                      범위는{" "}
                      <BoldText>이상 ~ 이하</BoldText>입니다. 금액대가{" "}<BoldText>겹치지 않게 등록</BoldText>해 주시기 바랍니다.
                    </TextBar>
                    <Text
                      align={"left"}
                      padding={"0 0 0 10px"}
                    >
                      ex. 10,000원 이상 ~ 19,999원 이하 5%, 20,000원 이상 ~ 29,999원 이하 10%
                    </Text>
                    <TextBar
                      align={"left"}
                      style={{
                        latterSpacing: "-0.28px",
                        lineHeight: "25px",
                      }}
                    >
                      추가 포인트 비율 계산 시{" "}
                      <BoldText>소수점은 버림으로 계산</BoldText>됩니다.
                    </TextBar>
                  </div>

                  <WhiteBox>
                    {rangeData?.length > 0 ? (
                      rangeData?.map((list, key) => (
                        <RangeWrapBox
                          justify={"start"}
                          margin={"0 0 6px"}
                          key={key}
                        >
                          <FlexBox
                            gap={"8px"}
                            gapMd={"16px"}
                            className="range-justify"
                          >
                            <FlexBox className="range-direction">
                              <Text>{AddComma(list.more)}원 이상</Text>
                              <Text>~</Text>
                              <Text>{AddComma(list.less)}원 이하</Text>
                            </FlexBox>
                            <FlexBox>
                              <Text
                                family={"Montserrat"}
                                weight={"700"}
                                size={"18px"}
                                sizeMd={"24px"}
                              >
                                {list.ratio}%
                              </Text>
                              <Btn
                                border={"none"}
                                hoverBackground={"transparent"}
                                padding={"6px"}
                                onClick={() => {
                                  setDeleteConfirmModal(true);
                                  setDeleteIdx(list.idx);
                                }}>
                                <img src={close} />
                              </Btn>
                            </FlexBox>
                          </FlexBox>
                        </RangeWrapBox>
                      ))) : (
                      <Text
                        size={"12px"}
                        sizeMd={"14px"}
                        weight={"400"}
                        color={"#6c6c6c"}
                      >
                        데이터가 없습니다.
                      </Text>
                    )}

                    <FlexBox
                      justify={"start"}
                      margin={"10px 0 0"}
                    >
                      <Btn
                        width={"100%"}
                        widthMd={"initial"}
                        border={"1px solid #666666"}
                        hoverBorder={"1px solid #C7C7C7"}
                        hoverBackground={"#C7C7C7"}
                        color={"#666666"}
                        hoverColor={"#FFFFFF"}
                        weight={"500"}
                        hoverWeight={"700"}
                        padding={"9px 19px"}
                        size={"14px"}
                        radius={"6px"}
                        lineHeight={"22px"}
                        onClick={() => setAddRangeModal(true)}
                      >
                        &#43; 금액대 추가
                      </Btn>
                    </FlexBox>
                  </WhiteBox>
                </Container>
                <InfoModal
                  modal={textModal}
                  setModal={setTextModal}
                  description={textMsg}
                />
                <InputModal
                  modal={addRangeModal}
                  setModal={setAddRangeModal}
                  mode={"setRangePoint"}
                  tempRangeValue={tempRangeValue}
                  setTempRangeValue={setTempRangeValue}
                  onSubmit={onSubmit}
                  title={"금액대 추가"}
                />
                <ConfirmModal
                  modal={deleteConfirmModal}
                  setModal={setDeleteConfirmModal}
                  onSubmit={() => handleDeleteRange(deleteIdx)}
                  description={"추가포인트 비율을 삭제하시겠습니까?"}
                />
              </Wrapper>
            </ContentContainer>
          </>
        )}
    </>
  );
}

const RangeWrapBox = styled(FlexBox)`
  @media all and (max-width: 360px) {
    padding: 16px 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);

    .range-justify {
      width: 100%;
      justify-content: space-between;
    }

    .range-direction {
      flex-direction: column;
    }
  }
`;