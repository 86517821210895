import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./slide.css";
import { Navigation, Pagination, Grid, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { AddComma } from "../../Utiles/Custom";
import { FlexBox, Text, Btn } from "../../Assets/css/commonVer2";
import { useMediaQuery } from "react-responsive";

function Slider(props) {
  const { space, perview, pagination, naviPrev, naviNext, contents, styles } = props;
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();
  const [isClick, setIsClick] = useState(false);
  const [franName, setFranName] = useState("");

  // 가맹점 매출 순위 카드 클릭 시 franName 을 state에 넣고 가맹점 매출관리 페이지로 이동
  const onClickMoveToRevenue = () => {
    setIsClick(true);

    if (franName && franName !== undefined) {
      navigate("/franchisee/revenue", { state: { franName: franName } });
    }
  };

  useEffect(() => {
    onClickMoveToRevenue();
  }, [isClick, franName]);

  return (
    <>
      <Swiper
        style={{
          width: "100%",
        }}
        modules={[Navigation, Pagination, Grid, Autoplay]}
        // spaceBetween={space}
        navigation={
          naviPrev && naviNext
            ? {
                prevEl: `.${naviPrev}`,
                nextEl: `.${naviNext}`,
              }
            : false
        }
        pagination={pagination ? { el: `.${pagination}`, clickable: true } : false}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
      >
        <>
          {contents?.map((e, i) => (
            <SwiperSlide key={i}>
              {e.map((item, idx) => (
                <ListItem
                  key={idx}
                  onClick={() => {
                    onClickMoveToRevenue();
                    setFranName(item.franchise_name);
                  }}
                  style={{
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ width: isMobile ? "100%" : "auto" }}>
                    <Text
                      weight={"400"}
                      color={"#2d2d2d"}
                      align={"left"}
                      size={"14px"}
                      sizeMd={"16px"}
                      lineHeight={"20px"}
                      margin={"0 0 26px"}
                    >
                      <span
                        style={{
                          fontWeight: "700",
                          color: "#FF4A4A",
                          paddingRight: "10px",
                        }}
                      >
                        {idx + 1 + i * 3}
                      </span>
                      {item.franchise_name}
                    </Text>
                    {isMobile && (
                      <Btn
                        style={{
                          position: "absolute",
                          right: "17px",
                          top: "16px",
                        }}
                        border={"none"}
                        size={"12px"}
                        padding={"0"}
                        hoverColor={"none"}
                        hoverWeight={"none"}
                        hoverBackground={"transparent"}
                      >
                        자세히 보기 &#8594;
                      </Btn>
                    )}
                    <FlexBox justify={"space-between"} width={"100%"}>
                      <FlexBox
                        margin={" 0 0"}
                        marginMd={"0 20px 0 0"}
                        gap={"8px"}
                        direction={"column"}
                        flex={isMobile ? "unset" : 1}
                        align={"flex-start"}
                      >
                        <Text size={"12px"} sizeMd={"14px"} color={"#1e1e1e"} align={"left"}>
                          오늘의 매출
                        </Text>
                        <Text size={"12px"} sizeMd={"14px"} color={"#1e1e1e"} align={"left"}>
                          이 달 매출
                        </Text>
                      </FlexBox>
                      <FlexBox
                        margin={" 0 0"}
                        marginMd={"0 20px 0 0"}
                        gap={"8px"}
                        direction={"column"}
                        flex={isMobile ? 1 : "unset"}
                        align={"flex-end"}
                      >
                        <Text size={"14px"} sizeMd={"18px"} weight={"700"} color={"#1e1e1e"}>
                          {AddComma(item.c_d_cnt)}개
                        </Text>
                        <Text size={"14px"} sizeMd={"18px"} weight={"700"} color={"#1e1e1e"}>
                          {AddComma(item.c_m_cnt)}개
                        </Text>
                      </FlexBox>
                      <FlexBox gap={"8px"} direction={"column"} flex={isMobile ? 1 : "unset"} align={"flex-end"}>
                        <Text size={"14px"} sizeMd={"18px"} weight={"700"} color={"#1e1e1e"}>
                          {AddComma(item.c_d_amount)}원
                        </Text>
                        <Text size={"14px"} sizeMd={"18px"} weight={"700"} color={"#1e1e1e"}>
                          {AddComma(item.c_m_amount)}원
                        </Text>
                      </FlexBox>
                    </FlexBox>
                  </div>
                  {!isMobile && (
                    <FlexBox>
                      <Btn size={"14px"} lineHeight={"22px"} padding={"8px 18px"}>
                        자세히 보기 &#8594;
                      </Btn>
                    </FlexBox>
                  )}
                </ListItem>
              ))}
            </SwiperSlide>
          ))}
        </>
        <div className={`swiper-pagination ${pagination}`}></div>
      </Swiper>
    </>
  );
}

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  margin-bottom: 10px;
  flex-wrap: wrap;
  gap: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export default Slider;
