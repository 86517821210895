import React from "react";
import { TableWrap, Td, Th } from "../../../Assets/css/commonVer2";
import { AddComma } from "../../../Utiles/Custom";

/* code from sub_outsourcing */

export default function PeriodTable({ revenueItems }) {
  return (
    <TableWrap>
      <table id="periodTable" style={{ width: "100%", minWidth: "1024px" }}>
        <thead>
          <tr>
            <Th>날짜</Th>
            <Th>
              총 합계
              <br />
              <span style={{ fontSize: "12px" }}>(본사/자점매입)</span>
            </Th>
            <Th>카드</Th>
            <Th>현금</Th>
            <Th>포인트</Th>
            <Th>카카오페이</Th>
            <Th>제로페이</Th>
            <Th>네이버페이</Th>
          </tr>
        </thead>
        <tbody>
          {revenueItems.length > 0 ? (
            revenueItems?.map((item) => (
              <tr key={item.kiosk_sale_id}>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item ? item.date : ""}</Td>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                  {AddComma(item.amount?.total)}원 <br />
                  <span style={{ fontWeight: "400", fontSize: "12px" }}>
                    ({AddComma(item.headquarter_amount?.total)}원 / {AddComma(item.franchise_amount?.total)}원)
                  </span>
                </Td>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{AddComma(item.card?.total)}원</Td>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{AddComma(item.cash?.total)}원</Td>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{AddComma(item.point?.total)}원</Td>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{AddComma(item.kakao?.total)}원</Td>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{AddComma(item.zero?.total)}원</Td>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{AddComma(item.naver?.total)}원</Td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={8}
                style={{ borderTop: "1px solid #e1e1e1", textAlign: "center", padding: 10, fontSize: "14px" }}
              >
                데이터가 없습니다.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </TableWrap>
  );
}
