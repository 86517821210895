import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import ExChartBar from "../../Components/Chart/ExChartBar";
import SliderBtn from "../../Components/Slider/SliderBtn";
import RankSlider from "../../Components/Slider/RankSlider";
import API from "../../Utiles/API";
import { AddComma } from "../../Utiles/Custom";
import Authority from "../../Components/Modal/Authority";
import {
  Btn,
  Container,
  FlexBox,
  Text,
  Title,
  Wrapper,
  ContentContainer,
  WhiteBox,
  RadioLabel,
  RadioIcon,
  Td,
  Th,
} from "../../Assets/css/commonVer2";
import Header from "../../Components/Header/HeaderVer2";
import { useMediaQuery } from "react-responsive";
import Cate2Select from "../../Components/Select/Cate2Select";
import InfoSlider from "../../Components/Slider/InfoSlider";
import DataLoading from "../../Components/DataLoading";

// 가맹마스터 > 대쉬보드

function Franchisee() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const isTablet = useMediaQuery({ query: "(min-width:1202px) and (max-width:1800px)"});
  const navigate = useNavigate();

  // 인기 판매 상품
  const [bestItemtype, setBestItemType] = useState("day");
  const [bestItemData, setBestItemData] = useState([]);

  // 재입고 알림 상품
  const [restockData, setRestockData] = useState([]);

  const [returnData, setReturnData] = useState([]);
  const [price, setPrice] = useState();

  const [chartType, setChartType] = useState("day");
  const [chart, setChart] = useState([]);

  const [cate2Value, setCate2Value] = useState("");

  const [dataLoading, setDataLoading] = useState(false);

  // 모바일 슬라이더
  const slideData = [
    { price: `${AddComma(price ? price.storeonwer_num : 0)}`, title: "보유 매장 수", unit: "개" },
    { price: `${AddComma(price ? price.storeonwer_num : 0)}`, title: "총 가맹 점주 수", unit: "명" },
    { exceptionPrice: [`${AddComma(price ? price.payment_total_only_franchise_1 : 0)}`, `${AddComma(price ? price.payment_total : 0)}`],
      exceptionTitle: ["본사 매출", "총 매출"] },
    { price: `${AddComma(price ? price.deposit_num : 0)}`, title: "예치금 현황", unit: "원" },
  ];

  // 대시보드(보유 매장 수, 총 가맹 점주 수, 총매출, 본사 매출, 예치금 현황)
  const getDashboardData = async () => {
    API.get(`/admin/dashboard`).then((res) => {
      if (res.data.status) {
        setPrice(res.data.result.franchise_status);
      }
    });
  };

  // 가맹점 매출 순위
  const getFranSalesData = async () => {
    API.get(`/admin/dashboard/franchise`).then((res) => {
      if (res.data.status) {
        setReturnData(res.data.result.franchise_sale_top_result);
      }
    });
  };

  // 매출 조회
  const getSalesData = async () => {
    API.get(`/admin/dashboard/sales?date_type=${chartType}`).then((res) => {
      if (res.data.status) {
        setChart(res.data.result.saleStatus_chart_result);
      }
    });
  };

  // 인기판매상품 조회
  const getBestProductList = async () => {
    let api_link = `/admin/dashboard/best-product?date_type=${bestItemtype}`;
    if (cate2Value) {
      api_link += `&product2nd_id=${cate2Value}`;
    }
    API.get(api_link).then((res) => {
      if (res.data?.status) {
        setBestItemData(res.data.result.popularity_product_result);
      }
    });
  };

  // 재입고 알림 내역 조회
  const getRestockList = async () => {
    API.get(`/admin/restock?page=1&unit=8&block=10`).then((res) => {
      if (res.data.status) {
        setRestockData(res.data.results);
      }
    });
  };

  const groupedRankData = returnData?.reduce((acc, cur, idx) => {
    if (idx % 3 === 0) {
      acc.push([cur]);
    } else {
      acc[acc.length - 1].push(cur);
    }
    return acc;
  }, []);

  const groupedRestockData = restockData?.reduce((acc, cur, idx) => {
    if (idx % 2 === 0) {
      acc.push([cur]);
    } else {
      acc[acc.length - 1].push(cur);
    }
    return acc;
  }, []);

  useEffect(() => {
    getSalesData();
  }, [chartType]);

  useEffect(() => {
    getBestProductList();
  }, [cate2Value, bestItemtype]);

  useEffect(() => {
    getSalesData();
    getBestProductList();
    getRestockList();
    getDashboardData();
    getFranSalesData();
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {dataLoading ? <DataLoading /> : null}
          {isMobile && <Header title={"가맹마스터"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                <Title size={"18px"} sizeMd={"26px"} color={"#1E1E1E"} weight={"700"}>
                  대쉬보드
                </Title>
                {!isMobile && (
                  <CardFlexBox>
                    {!isTablet ? (
                      <>
                        <CardItem>
                          <Text size={"12px"} sizeMd={"18px"} color={"#1e1e1e"}>
                            보유 매장 수
                          </Text>
                          <Text size={"12px"} sizeMd={"18px"} color={"#FF4A4A"}>
                            <span
                              style={{
                                fontSize: "26px",
                                fontWeight: "700",
                              }}
                            >
                              {AddComma(price ? price.storeonwer_num : 0)}
                            </span>
                            개
                          </Text>
                        </CardItem>
                        <CardItem>
                          <Text size={"12px"} sizeMd={"18px"} color={"#1e1e1e"}>
                            총 가맹 점주 수
                          </Text>
                          <Text size={"12px"} sizeMd={"18px"} color={"#FF4A4A"}>
                            <span
                              style={{
                                fontSize: "26px",
                                fontWeight: "700",
                              }}
                            >
                              {AddComma(price ? price.storeonwer_num : 0)}
                            </span>
                            명
                          </Text>
                        </CardItem>
                        <CardItem className="exception" style={{ display: "unset" }}>
                          <FlexBox flex={1} justify={"space-between"}>
                            <Text size={"12px"} sizeMd={"18px"} color={"#1e1e1e"}>
                              본사 매출
                            </Text>
                            <Text size={"12px"} sizeMd={"18px"} color={"#FF4A4A"}>
                              <span
                                style={{
                                  fontSize: "26px",
                                  fontWeight: "700",
                                }}
                              >
                                {AddComma(price ? price.payment_total_only_franchise_1 : 0)}
                              </span>
                              원
                            </Text>
                          </FlexBox>
                          <FlexBox flex={1} justify={"space-between"} margin={"4px 0 0"}>
                            <Text size={"10px"} sizeMd={"16px"} color={"#1e1e1e"}>
                              총 매출
                            </Text>
                            <Text size={"10px"} sizeMd={"16px"} color={"#1e1e1e"}>
                              <span
                                style={{
                                  fontSize: "24px",
                                  fontWeight: "700",
                                }}
                              >
                                {AddComma(price ? price.payment_total : 0)}
                              </span>
                              원
                            </Text>
                          </FlexBox>
                        </CardItem>
                        <CardItem>
                          <Text size={"12px"} sizeMd={"18px"} color={"#1e1e1e"}>
                            예치금 현황
                          </Text>
                          <Text size={"12px"} sizeMd={"18px"} color={"#FF4A4A"}>
                            <span
                              style={{
                                fontSize: "26px",
                                fontWeight: "700",
                              }}
                            >
                              {AddComma(price ? price.deposit_num : 0)}
                            </span>
                            원
                          </Text>
                        </CardItem>
                      </>
                    ) : (
                      <>
                        <TabletBox>
                          <CardItem>
                            <Text size={"12px"} sizeMd={"18px"} color={"#1e1e1e"}>
                              보유 매장 수
                            </Text>
                            <Text size={"12px"} sizeMd={"18px"} color={"#FF4A4A"}>
                              <span
                                style={{
                                  fontSize: "26px",
                                  fontWeight: "700",
                                }}
                              >
                                {AddComma(price ? price.storeonwer_num : 0)}
                              </span>
                              개
                            </Text>
                          </CardItem>
                          <CardItem>
                            <Text size={"12px"} sizeMd={"18px"} color={"#1e1e1e"}>
                              총 가맹 점주 수
                            </Text>
                            <Text size={"12px"} sizeMd={"18px"} color={"#FF4A4A"}>
                              <span
                                style={{
                                  fontSize: "26px",
                                  fontWeight: "700",
                                }}
                              >
                                {AddComma(price ? price.storeonwer_num : 0)}
                              </span>
                              명
                            </Text>
                          </CardItem>
                        </TabletBox>
                        <TabletBox>
                          <CardItem className="exception" style={{ display: "unset" }}>
                            <FlexBox flex={1} justify={"space-between"}>
                              <Text size={"12px"} sizeMd={"18px"} color={"#1e1e1e"}>
                                본사 매출
                              </Text>
                              <Text size={"12px"} sizeMd={"18px"} color={"#FF4A4A"}>
                                <span
                                  style={{
                                    fontSize: "26px",
                                    fontWeight: "700",
                                  }}
                                >
                                  {AddComma(price ? price.payment_total_only_franchise_1 : 0)}
                                </span>
                                원
                              </Text>
                            </FlexBox>
                            <FlexBox flex={1} justify={"space-between"} margin={"4px 0 0"}>
                              <Text size={"10px"} sizeMd={"16px"} color={"#1e1e1e"}>
                                총 매출
                              </Text>
                              <Text size={"10px"} sizeMd={"16px"} color={"#1e1e1e"}>
                                <span
                                  style={{
                                    fontSize: "24px",
                                    fontWeight: "700",
                                  }}
                                >
                                  {AddComma(price ? price.payment_total : 0)}
                                </span>
                                원
                              </Text>
                            </FlexBox>
                          </CardItem>
                          <CardItem>
                            <Text size={"12px"} sizeMd={"18px"} color={"#1e1e1e"}>
                              예치금 현황
                            </Text>
                            <Text size={"12px"} sizeMd={"18px"} color={"#FF4A4A"}>
                              <span
                                style={{
                                  fontSize: "26px",
                                  fontWeight: "700",
                                }}
                              >
                                {AddComma(price ? price.deposit_num : 0)}
                              </span>
                              원
                            </Text>
                          </CardItem>
                        </TabletBox>
                      </>
                    )}

                  </CardFlexBox>
                )}
                {isMobile && <InfoSlider contents={slideData} slidesPerView={slideData.length} />}
                <FlexBox align={"start"} direction={"column"} margin={"0 0 20px"} marginMd={"0 0 30px"}>
                  <FlexBox margin={"0 0 10px"} marginMd={"30px 0 20px"}>
                    <Title size={"18px"} sizeMd={"26px"} color={"#1E1E1E"} weight={"700"}>
                      매출 현황 📊
                    </Title>
                  </FlexBox>
                  <WhiteBox margin={"0"} marginMd={"0"}>
                    <FlexBox
                      direction={"column"}
                      directionMd={"row"}
                      justify={"space-between"}
                      align={"start"}
                      margin={"0 0 20px"}
                      marginMd={"0 0 40px"}
                    >
                      <Text size={"14px"} sizeMd={"16px"} weight={"700"}>
                        일간 매출 현황
                      </Text>
                      <FlexBox width={"100%"} widthMd={"unset"} justify={"end"} gap={"10px"}>
                        <RadioLabel>
                          <input
                            type="radio"
                            name="day"
                            value="day"
                            checked={chartType === "day"}
                            onChange={() => setChartType("day")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          일간
                        </RadioLabel>

                        <RadioLabel>
                          <input
                            type="radio"
                            name="week"
                            value="week"
                            checked={chartType === "week"}
                            onChange={() => setChartType("week")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          주간
                        </RadioLabel>

                        <RadioLabel>
                          <input
                            type="radio"
                            name="month"
                            value="month"
                            checked={chartType === "month"}
                            onChange={() => setChartType("month")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          월간
                        </RadioLabel>
                      </FlexBox>
                    </FlexBox>
                    {isMobile ? (
                      <div
                        style={{
                          overflowX: "scroll",
                          flex: 1,
                        }}
                      >
                        <div style={{ minWidth: "600px" }}>
                          <ExChartBar selfPurchaseChart={chart} />
                        </div>
                      </div>
                    ) : (
                      <ExChartBar selfPurchaseChart={chart} />
                    )}
                  </WhiteBox>
                </FlexBox>

                <FlexBox
                  align={"start"}
                  direction={"column"}
                  directionXl={"row"}
                  width={"100%"}
                  gap={"20px"}
                  gapMd={"26px"}
                >
                  <FlexBox direction={"column"} width={"100%"} widthXl={"50%"} align={"start"}>
                    <FlexBox justify={"space-between"}>
                      <Title size={"18px"} sizeMd={"26px"} color={"#1E1E1E"} weight={"700"}>
                        가맹점 매출 순위 🏆
                      </Title>
                    </FlexBox>
                    <WhiteBox margin={"10px 0 0"} marginMd={"20px 0 0"}>
                      <FlexBox justify={"end"} margin={"0 0 10px"}>
                        <SliderBtn prevClass={"rankPrev"} nextClass={"rankNext"} />
                      </FlexBox>
                      {returnData ? (
                        <RankSlider naviPrev={"rankPrev"} naviNext={"rankNext"} contents={groupedRankData} />
                      ) : (
                        <Text size={"14px"} color={"#999"} weight={"500"} width={"100%"} padding={"10px 0"}>
                          현재 매출 순위가 없습니다.
                        </Text>
                      )}
                    </WhiteBox>
                  </FlexBox>

                  <FlexBox direction={"column"} width={"100%"} widthXl={"50%"} align={"start"}>
                    <FlexBox justify={"space-between"} width={"100%"}>
                      <Title size={"18px"} sizeMd={"26px"} color={"#1E1E1E"} weight={"700"}>
                        인기 판매 상품 👍🏻
                      </Title>
                      {isMobile && (
                        <FlexBox justify={"start"} gap={"10px"}>
                          <RadioLabel>
                            <input
                              type="radio"
                              value={bestItemtype === "day"}
                              checked={bestItemtype === "day"}
                              onChange={() => setBestItemType("day")}
                            />
                            <RadioIcon>
                              <svg viewBox="0 0 18 18">
                                <circle cx="9" cy="9" r="8.5" />
                                <circle cx="9" cy="9" r="5" />
                              </svg>
                            </RadioIcon>
                            일간
                          </RadioLabel>

                          <RadioLabel>
                            <input
                              type="radio"
                              value={bestItemtype === "week"}
                              checked={bestItemtype === "week"}
                              onChange={() => setBestItemType("week")}
                            />
                            <RadioIcon>
                              <svg viewBox="0 0 18 18">
                                <circle cx="9" cy="9" r="8.5" />
                                <circle cx="9" cy="9" r="5" />
                              </svg>
                            </RadioIcon>
                            주간
                          </RadioLabel>

                          <RadioLabel>
                            <input
                              type="radio"
                              value={bestItemtype === "month"}
                              checked={bestItemtype === "month"}
                              onChange={() => setBestItemType("month")}
                            />
                            <RadioIcon>
                              <svg viewBox="0 0 18 18">
                                <circle cx="9" cy="9" r="8.5" />
                                <circle cx="9" cy="9" r="5" />
                              </svg>
                            </RadioIcon>
                            월간
                          </RadioLabel>
                        </FlexBox>
                      )}
                    </FlexBox>
                    <WhiteBox margin={"10px 0 0"} marginMd={"20px 0 0"}>
                      <FlexBox gap={"10px"} justify={"end"} wrap={"wrap"}>
                        <Cate2Select
                          width={"130px"}
                          widthMd={"110px"}
                          idx={1}
                          cate2Value={cate2Value}
                          setCate2Value={setCate2Value}
                        />
                        {!isMobile && (
                          <FlexBox justify={"end"} gap={"10px"}>
                            <RadioLabel>
                              <input
                                type="radio"
                                value={bestItemtype === "day"}
                                checked={bestItemtype === "day"}
                                onChange={() => setBestItemType("day")}
                              />
                              <RadioIcon>
                                <svg viewBox="0 0 18 18">
                                  <circle cx="9" cy="9" r="8.5" />
                                  <circle cx="9" cy="9" r="5" />
                                </svg>
                              </RadioIcon>
                              일간
                            </RadioLabel>

                            <RadioLabel>
                              <input
                                type="radio"
                                value={bestItemtype === "week"}
                                checked={bestItemtype === "week"}
                                onChange={() => setBestItemType("week")}
                              />
                              <RadioIcon>
                                <svg viewBox="0 0 18 18">
                                  <circle cx="9" cy="9" r="8.5" />
                                  <circle cx="9" cy="9" r="5" />
                                </svg>
                              </RadioIcon>
                              주간
                            </RadioLabel>

                            <RadioLabel>
                              <input
                                type="radio"
                                value={bestItemtype === "month"}
                                checked={bestItemtype === "month"}
                                onChange={() => setBestItemType("month")}
                              />
                              <RadioIcon>
                                <svg viewBox="0 0 18 18">
                                  <circle cx="9" cy="9" r="8.5" />
                                  <circle cx="9" cy="9" r="5" />
                                </svg>
                              </RadioIcon>
                              월간
                            </RadioLabel>
                          </FlexBox>
                        )}
                      </FlexBox>

                      <TableWrap>
                        <table>
                          <colgroup>
                            <col style={{ width: "14%" }} />
                            <col />
                            <col />
                            <col />
                          </colgroup>
                          <thead>
                            <tr>
                              <Th>순위</Th>
                              <Th>상품명</Th>
                              <Th>구매건수</Th>
                              <Th>상품금액</Th>
                            </tr>
                          </thead>
                          <tbody>
                            {bestItemData?.length > 0 ? (
                              bestItemData?.map((item, key) => (
                                <tr key={key}>
                                  <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{key + 1}</Td>
                                  <Td align={"left"} style={{ borderBottom: "1px solid #F2F2F2" }}>
                                    {item.product_name}
                                  </Td>
                                  <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.cnt}개</Td>
                                  <Td
                                    align={"right"}
                                    style={{
                                      borderBottom: "1px solid #F2F2F2",
                                    }}
                                  >
                                    {AddComma(item.amount)}원
                                  </Td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan={4}
                                  style={{
                                    borderTop: "1px solid #e1e1e1",
                                    textAlign: "center",
                                    padding: 10,
                                    fontSize: "16px",
                                  }}
                                >
                                  현재 인기 상품이 없습니다.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </TableWrap>
                    </WhiteBox>
                  </FlexBox>
                </FlexBox>

                <FlexBox justify={"space-between"} margin={"20px 0 10px"} marginMd={"30px 0 20px"}>
                  <Title size={"18px"} sizeMd={"26px"} color={"#1E1E1E"} weight={"700"}>
                    재입고 알림 상품 순위🏅
                  </Title>
                  {!isMobile && (
                    <Btn
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      width={"100%"}
                      widthMd={"unset"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      onClick={() => {
                        navigate("/product/restock");
                      }}
                      style={{ flexShrink: 0 }}
                    >
                      전체보기
                    </Btn>
                  )}
                </FlexBox>
                {!isMobile && (
                  <FlexBox justify={"flex-start"} wrap={"wrap"} gap={"12px"}>
                    {restockData.length > 0 ? (
                      restockData?.map((e, i) => (
                        <ListItem key={i}>
                          <Text weight={"500"} color={"#1E1E1E"} align={"left"} size={"16px"} margin={"0 0 12px"}>
                            <span style={{ color: "#dd1212", paddingRight: "5px" }}>{i + 1}.</span>
                            {e.product_name}
                          </Text>
                          <FlexBox justify={"space-between"} padding={"0 8px"}>
                            <Text size={"16px"} color={"#1E1E1E"}>
                              신청 건수 :
                            </Text>
                            <Text size={"16px"} color={"#2d2d2d"}>
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "700",
                                }}
                              >
                                {e.count}
                              </span>
                              건
                            </Text>
                          </FlexBox>
                          <FlexBox justify={"space-between"} margin={"8px 0 0"} padding={"0 8px"}>
                            <Text size={"16px"} color={"#1E1E1E"}>
                              상품 금액 :
                            </Text>
                            <Text size={"16px"} color={"#1E1E1E"}>
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "700",
                                }}
                              >
                                {" "}
                                {AddComma(e.shipping_price)}
                              </span>
                              원
                            </Text>
                          </FlexBox>
                        </ListItem>
                      ))
                    ) : (
                      <Text size={"14px"} color={"#999"} weight={"500"} width={"100%"} padding={"10px 0"}>
                        현재 알림신청 상품이 없습니다.
                      </Text>
                    )}
                  </FlexBox>
                )}
                {isMobile &&
                  (restockData.length > 0 ? (
                    <InfoSlider lists={groupedRestockData} slidesPerView={groupedRestockData.length} />
                  ) : (
                    <Text size={"14px"} color={"#999"} weight={"500"} width={"100%"} padding={"10px 0"}>
                      현재 알림신청 상품이 없습니다.
                    </Text>
                  ))}

                {isMobile && (
                  <Link style={{ width: "100%" }} to={"/product/restock"}>
                    <Btn
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      width={"100%"}
                      widthMd={"unset"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      margin={"12px 0 0"}
                    >
                      전체보기
                    </Btn>
                  </Link>
                )}
              </Container>
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const TableWrap = styled.div`
  overflow-y: auto;
  table {
    width: 100%;
  }
`;

const CardFlexBox = styled(FlexBox)`
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 1801px) {
    flex-direction: row;
  }
`
  
const TabletBox = styled(FlexBox)`
  width: 100%;
`

const CardItem = styled.div`
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: calc((100% - 40px) / 3);
  margin-right: 15px;
  margin-top: 20px;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :last-child {
    margin-right: 0;
  }
  @media (max-width: 1800px) {
    width: 50%;
  }
  @media (max-width: 1201px) {
    width: 100%;
    margin-right: 0;
  }

  &.exception{
    padding: 23px 40px;
  }
`;

const ListItem = styled.div`
  width: calc(25% - 9px);
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media all and (max-width: 1200px) {
    width: calc(50% - 6px);
  }
  @media all and (max-width: 1024px) {
    width: 100%;
    padding: 12px 10px;
  }
`;
export default Franchisee;
