// 외부 모듈
import React, { useEffect, useState, useReducer } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// 내부 모듈
import {
  Btn,
  Container,
  FlexBox,
  Wrapper,
  Th,
  Td,
  Text,
  TableWrap,
  DateInputBox,
  ContentContainer,
  WhiteBox,
  Title,
} from "../../Assets/css/commonVer2";

import "../../Assets/css/DatePicker.css";
import Authority from "../../Components/Modal/Authority";
import { DELIVERY_STATUS } from "../../Utiles/ListData";
import Pagination from "../../Components/Paging/Pagination";
import HandleBack from "../../Components/HandleBack";
import { SearchOrderReducer } from "../../Store";
import { AddComma } from "../../Utiles/Custom";
import { dateToString } from "../../Utiles/dateToString";
import { delayCall } from "../../Utiles/delayCall";
import API from "../../Utiles/API";
import { getOrderProducts, updateDeliveryState } from "../../Utiles";
import { searchToObject } from "../../Utiles/searchToObject";
import { UNIT_10_TO_50_LIST } from "../../Utiles/ListData";
import BasicSelect from "../../Components/Select/BasicSelect";
import InfoModal from "../../Components/Modal/InfoModal";
import Header from "../../Components/Header/HeaderVer2";
import { useMediaQuery } from "react-responsive";
import InputModal from "../../Components/Modal/InputModal";
import FranSelect from "../../Components/Select/FranSelect";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";

// 상품마스터 > 주문내역관리

export default function OrderManagement() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();
  const location = useLocation();
  const { handlePopstate } = HandleBack();

  // 날짜 제어 state
  const date = new Date();
  const year = date.getFullYear(); // 년
  const month = date.getMonth(); // 월
  const day = date.getDate(); // 일
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  // 배송 상태 변경
  const [franchiseId, setFranchiseId] = useState("");
  const [clickedItemOrderDate, setClickedItemOrderDate] = useState("");
  const [tempDeliveryInfo, setTempDeliveryInfo] = useState([]);

  const [onDeliveryModal, setOnDeliveryModal] = useState(false); // 배송전 -> 배송중 변경 모달
  const [completeDeliveryModal, setCompleteDeliveryModal] = useState(false); // 배송완료처리 확인 모달
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [refreshModal, setRefreshModal] = useState(false);

  // 페이징에 사용할 스테이트
  const [pagination, setPagination] = useState("");

  // 주문 상품 리스트
  const [orderList, setOrderList] = useState([]);

  // 총  금액
  const [totalAmount, setTotalAmount] = useState();

  // 가맹점 검색 결과를 돌려 받을 state
  const [searchFranchise, setSearchFranchise] = useState("");
  const [franShow, setFranShow] = useState(false);
  const [franText, setFranText] = useState("가맹점 전체");

  // 주문 상세를 다녀온 경우 저장 해놓은 파라미터 정보
  const parsedProductOrderPageState = sessionStorage.getItem("productOrderPageState")
    ? JSON.parse(sessionStorage.getItem("productOrderPageState"))
    : {};

  const [unit, setUnit] = useState(
    parsedProductOrderPageState ? (parsedProductOrderPageState?.unit ? parsedProductOrderPageState?.unit : 10) : 10,
  );

  // 검색 객체
  const [searchState, dispatchSearchState] = useReducer(SearchOrderReducer, {
    // franchise_id : 1, // 가맹점 아이디, 주문 번호 필드가 없으면 전체 제품이 검색된다
    start_date: dateToString(new Date(year, month, day - 7)) + " 00:00:00",
    end_date: dateToString(new Date()) + " 23:59:59",
    page: 1,
    unit: parsedProductOrderPageState
      ? parsedProductOrderPageState?.unit
        ? parsedProductOrderPageState?.unit
        : 10
      : 10,
    block: 10,
    search: true,
    ...searchToObject(),
  });

  // 주문 상세를 갈 때 sessionStorage에 저장할 객체
  const productOrderPageState = {
    unit: unit,
    page: searchState.page,
  };

  const completeDelivery = async () => {
    await API.put(`storeowner/order-list/status/delivery`, {
      order_date: clickedItemOrderDate,
      franchise_id: franchiseId,
    })
      .then((res) => {
        if (res.data.status) {
          setTextMsg("주문이 배송완료 상태로 변경되었습니다.");
          setRefreshModal(true);
        }
      })
      .catch(() => {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  // API - 1: 배송전 -> 배송중, 2: 배송중 -> 배송완료
  const onSubmit = (e) => {
    switch (e) {
      case 1:
        if (
          tempDeliveryInfo &&
          tempDeliveryInfo.courier_company !== null &&
          tempDeliveryInfo.tracking_number !== null &&
          tempDeliveryInfo.courier_company.trim() !== "" &&
          tempDeliveryInfo.tracking_number.trim() !== ""
        ) {
          updateDeliveryState(
            clickedItemOrderDate,
            franchiseId,
            tempDeliveryInfo.tracking_number,
            tempDeliveryInfo.courier_company,
          )
            .then((res) => {
              if (res.status) {
                setTextMsg("주문이 배송중 상태로 변경되었습니다.");
                setRefreshModal(true);
              } else {
                setTextMsg(res.msg || "잠시후 다시 시도해주세요.");
                setTextModal(true);
              }
            })
            .catch(() => handleError());
        } else {
          setTextMsg("내용을 입력해주세요.");
          setTextModal(true);
        }
        break;
      case 2:
        setCompleteDeliveryModal(false);
        // 배송 완료 api 실행
        completeDelivery();
        break;
      default:
        // e 값이 위의 case에 매치되지 않을 때의 동작 추가
        break;
    }
  };

  function handleError() {
    setTextMsg("E01\n잠시후 다시 시도해주세요.");
    setTextModal(true);
  }

  // 검색 버튼
  function searchOrder() {
    // 가맹점 이름으로 찾기
    if (searchFranchise) {
      // 가맹점 id로만 찾기
      dispatchSearchState({
        type: "SEARCH_BY_FEANCHISE_ID",
        value: searchFranchise,
      });
      dispatchSearchState({ type: "CHANGE_NOW_PAGE", value: 1 });
    } else if (!searchFranchise) {
      // 가맹점 id가 없을 때는 url에서 값을 없애줘야하기 때문에 state에서 미리 삭제시켜준다.
      dispatchSearchState({ type: "DELETE_IDS" });
    }
    dispatchSearchState({ type: "CHANGE_SEARCH", value: true });
    dispatchSearchState({ type: "CHANGE_NOW_PAGE", value: 1 });
    dispatchSearchState({
      type: "CHANGE_UNIT",
      value: parsedProductOrderPageState
        ? parsedProductOrderPageState?.unit
          ? parsedProductOrderPageState?.unit
          : searchState.unit
        : searchState.unit,
    });
  }

  // 주문 등록한 상품 정보
  function callGetProducts() {
    // 검색 객체는 자동으로 등록이 되지만 보여주기 위한 날짜 객체를 넣는다.
    // 날짜 선택 창에 검색 객체랑 동일한 날짜를 넣어준다.
    setStartDate(new Date(searchState.start_date));
    setEndDate(new Date(searchState.end_date));

    getOrderProducts(searchState)
      .then((res) => {
        if (res.status) {
          setPagination(res.pagination);
          setOrderList(res.results.rowsArr);
          setTotalAmount(res.results.entire_price);
          dispatchSearchState({ type: "CHANGE_SEARCH", value: false });
        }
      })
      .catch((res) => {
        dispatchSearchState({ type: "CHANGE_SEARCH", value: false });
      });
  }

  // 주문관리 상세로 이동
  function onClickItem(franchise_id, order_date) {
    navigate(`/product/order/management/${order_date}`, {
      state: {
        order_date: order_date,
        franchise_id: franchise_id,
      },
    });
    let jsonString = JSON.stringify(productOrderPageState);
    sessionStorage.setItem("productOrderPageState", jsonString);
  }

  // 페이지 이동 시 작동
  function movePage(e) {
    dispatchSearchState({
      type: "CHANGE_NOW_PAGE",
      value: e,
    });
    // setIsSearch(true)
    // setIsTableSearch(true)
  }

  const onClickChangeStatusBtn = (franchise_id, order_date, status) => {
    setFranchiseId(franchise_id);
    setClickedItemOrderDate(order_date);
    if (status === "before-delivery") {
      setOnDeliveryModal(true);
    } else if (status === "on-delivery") {
      setCompleteDeliveryModal(true);
    }
  };

  // 1. searchState를 searchBar에서도 변경하기 때문에 초기에 두번 작동된다.
  // 2. 검색버튼을 여러번 누를때를 대비해서 방지 가능
  useEffect(() => {
    delayCall(callGetProducts);
  }, [location]);

  // 현재 페이지에 왔을 때 searchState 에 맞는 데이터 가져옴
  useEffect(() => {
    if (searchState.search) {
      const params = {};
      Object.entries(searchState).forEach((item) => (params[item[0]] = item[1]));
      navigate(
        {
          pathname: "/product/order/management",
          search: `?${createSearchParams(params)}`,
        },
        { replace: true },
      );
    }
  }, [searchState]);

  useEffect(() => {
    dispatchSearchState({
      type: "CHANGE_UNIT",
      value: unit,
    });
  }, [unit]);

  // 현재 페이지에 도착하면 무조건 한 번 초기화
  useEffect(() => {
    sessionStorage.removeItem("productOrderPageState");
  }, []);

  // 뒤로가기 제어
  useEffect(() => {
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {isMobile && <Header title={"주문내역 관리"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    주문내역 관리 📋
                  </Title>
                )}
                <WhiteBox margin={"0 0"}>
                  <FlexBox wrap={"wrap"} justify={"end"} gap={"10px"}>
                    <FlexBox gap={"10px"} flexMd={"unset"} flex={"1"}>
                      <DateInputBox>
                        <DatePicker
                          dateFormatCalendar="yyyy년 MM월"
                          selected={startDate}
                          onChange={(date) => {
                            setStartDate(date);
                            dispatchSearchState({
                              type: "CHANGE_START_DATE",
                              value: dateToString(date) + " 00:00:00",
                            });
                          }}
                          dateFormat="yyyy-MM-dd"
                          maxDate={endDate}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </DateInputBox>
                      <DateInputBox>
                        <DatePicker
                          dateFormatCalendar="yyyy년 MM월"
                          selected={endDate}
                          onChange={(date) => {
                            setEndDate(date);
                            dispatchSearchState({
                              type: "CHANGE_END_DATE",
                              value: dateToString(date) + " 23:59:59",
                            });
                          }}
                          dateFormat="yyyy-MM-dd"
                          minDate={startDate}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </DateInputBox>
                    </FlexBox>

                    <FranSelect
                      width={"200px"}
                      widthMd={"100%"}
                      gubun={"fran"}
                      placeholder={"가맹점을"}
                      show={franShow}
                      setFranShow={setFranShow}
                      selectBtnText={franText}
                      setTextChange={setFranText}
                      setIdxChange={setSearchFranchise}
                    />

                    <Btn
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      width={"100%"}
                      widthMd={"unset"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      onClick={searchOrder}
                      style={{ flexShrink: 0 }}
                    >
                      검 색
                    </Btn>
                  </FlexBox>

                  <FlexBox
                    justify={"start"}
                    gap={"10px"}
                    width={"100%"}
                    widthMd={"auto"}
                    margin={"20px 0 10px"}
                    marginMd={"20px 0 30px"}
                  >
                    <Text
                      width={"100%"}
                      widthMd={"unset"}
                      color={"#FF4A4A"}
                      size={"16px"}
                      padding={"10px 29px 10px 27px"}
                      background={"rgba(255, 116, 64, 0.10)"}
                      radius={"8px"}
                      letterSpacing={"-0.32px"}
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      금액 합계 <span style={{ fontWeight: "700" }}>{AddComma(totalAmount ? totalAmount : "")}원</span>
                    </Text>
                    {!isMobile && (
                      <BasicSelect
                        width={"130px"}
                        data={UNIT_10_TO_50_LIST}
                        selectedValue={unit}
                        setSelectedValue={setUnit}
                        canSelectAll={false}
                      />
                    )}
                  </FlexBox>

                  <TableWrap>
                    <table style={{ width: "100%", minWidth: "1024px" }}>
                      <colgroup>
                        <col style={{ width: "10.5%" }} />
                        <col style={{ width: "16%" }} />
                        <col style={{ width: "16%" }} />
                        <col style={{ width: "21.5%" }} />
                        <col style={{ width: "15.5%" }} />
                        <col style={{ width: "15.5%" }} />
                        <col style={{ width: "10.5%" }} />
                        <col style={{ width: "10.5%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>No</Th>
                          <Th>주문일</Th>
                          <Th>주문번호</Th>
                          <Th>가맹점</Th>
                          <Th>품목 수량</Th>
                          <Th>총 금액</Th>
                          <Th>상 태</Th>
                          <Th>상태 변경</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderList.length > 0 ? (
                          orderList?.map((item, key) => (
                            <tr key={key} style={{ cursor: "pointer" }}>
                              <Td onClick={() => onClickItem(item.franchise_id, item.order_date)}>
                                {(searchState.page - 1) * searchState.unit + key + 1}
                              </Td>
                              <Td onClick={() => onClickItem(item.franchise_id, item.order_date)}>{item.order_date}</Td>
                              <Td onClick={() => onClickItem(item.franchise_id, item.order_date)}>{item.order_id}</Td>
                              <Td onClick={() => onClickItem(item.franchise_id, item.order_date)}>{item.franchise}</Td>
                              <Td onClick={() => onClickItem(item.franchise_id, item.order_date)}>{item.count}</Td>
                              <Td align={"right"} onClick={() => onClickItem(item.franchise_id, item.order_date)}>
                                {AddComma(item.totalPrice)}원
                              </Td>
                              <Td weight={"700"} onClick={() => onClickItem(item.franchise_id, item.order_date)}>
                                {item.status
                                  ? DELIVERY_STATUS.find((statusItem) => statusItem.value === item.status)?.text || "-"
                                  : "-"}
                              </Td>
                              <Td>
                                {item.status === "before-delivery" || item.status === "on-delivery" ? (
                                  <Btn
                                    margin={"0 auto"}
                                    size={"14px"}
                                    padding={"8px 18px"}
                                    onClick={() =>
                                      onClickChangeStatusBtn(item.franchise_id, item.order_date, item.status)
                                    }
                                  >
                                    변경
                                  </Btn>
                                ) : (
                                  "-"
                                )}
                              </Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={7}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  {pagination?.total_page > 0 ? (
                    <Pagination
                      pagination={pagination}
                      movePage={movePage}
                      currentPage={
                        parsedProductOrderPageState
                          ? parsedProductOrderPageState?.page
                            ? parsedProductOrderPageState?.page
                            : searchState.page
                          : searchState.page
                      }
                    />
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <InputModal
                modal={onDeliveryModal}
                setModal={setOnDeliveryModal}
                deliveryInfo={""}
                tempDeliveryInfo={tempDeliveryInfo}
                setTempDeliveryInfo={setTempDeliveryInfo}
                title={"배송중으로 상태 변경"}
                mode={"delivery"}
                onSubmit={() => onSubmit(1)}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
              <InfoModal modal={refreshModal} setModal={setRefreshModal} description={textMsg} mode={"refresh"} />
              <ConfirmModal
                modal={completeDeliveryModal}
                setModal={setCompleteDeliveryModal}
                onSubmit={() => onSubmit(2)}
                description={"배송완료 상태로 바꾸시겠습니까?\n배송완료 시 예치금이 차감되며\n취소하실 수 없습니다."}
              />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}
