import React, { useEffect, useState, useReducer } from "react";
import { useMediaQuery } from "react-responsive";
import { getProducts } from "../../Utiles";
import { dateToString } from "../../Utiles/dateToString";
import { delayCall } from "../../Utiles/delayCall";
import Pagination from "../../Components/Paging/Pagination";
import { SearchTakeBackReducer } from "../../Store";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { searchToObject } from "../../Utiles/searchToObject";
import { utils, writeFile } from "xlsx";
import { AddComma } from "../../Utiles/Custom";
import Header from "../../Components/Header/HeaderVer2";
import {
  Btn,
  Container,
  FlexBox,
  Wrapper,
  Th,
  Td,
  Text,
  TableWrap,
  ContentContainer,
  Title,
  WhiteBox,
} from "../../Assets/css/commonVer2";

import Authority from "../../Components/Modal/Authority";
import HandleBack from "../../Components/HandleBack";
import BasicSelect from "../../Components/Select/BasicSelect";
import { UNIT_10_TO_50_LIST, TAKEBACK_STATUS_LIST } from "../../Utiles/ListData";
import InfoModal from "../../Components/Modal/InfoModal";
import SearchBar from "../../Components/SearchInput/SearchBarVer2";

/* code from sub_outsourcing */

// 상품마스터 > 반품관리

export default function TakeBack() {
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();
  const { handlePopstate } = HandleBack();

  const [unit, setUnit] = useState(10);
  const [pagination, setPagination] = useState("");
  const [takeBackList, setTakeBackList] = useState([]);
  const [inputTakeBackId, setInpuTakeBackId] = useState(); // 반품 번호 입력 받을 state

  // 가맹점 검색 결과를 돌려 받을 state
  const [searchFranchise, setSearchFranchise] = useState("");
  const [franShow, setFranShow] = useState(false);
  const [franText, setFranText] = useState("가맹점 전체");

  // 날짜 제어 state
  const date = new Date();
  const year = date.getFullYear(); // 년
  const month = date.getMonth(); // 월
  const day = date.getDate(); // 일
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);

  const [textModal, setTextModal] = useState(false); // 모달 창 표시 여부 상태
  const [textMsg, setTextMsg] = useState("");

  // 반품 상세를 다녀온 경우 저장 해놓은 파라미터 정보
  const parsedTakeBackPageState = sessionStorage.getItem("takeBackPageState")
    ? JSON.parse(sessionStorage.getItem("takeBackPageState"))
    : {};

  // 반품 번호로만 찾을 시 가맹점의 값은 무시됩니다.
  function searchTakeBack() {
    // 반품번호로 찾기
    if (inputTakeBackId) {
      // 반품번호로만 찾기, franchise_id 속성은 삭제한다.
      dispatchSearchState({ type: "SEARCH_BY_TAKEBACK_ID", value: inputTakeBackId });
    }
    // 가맹점 이름으로 찾기
    else if (searchFranchise) {
      // 가맹점 id로만 찾기 , take_back_id속성은 삭제한다
      dispatchSearchState({ type: "SEARCH_BY_FEANCHISE_ID", value: searchFranchise });
    } else if (!inputTakeBackId && !searchFranchise) {
      // 가맹점 id와 반품 번호 둘 다 없을 때는 url에서 두 개의 값을 없애줘야하기 때문에 state에서 미리 삭제시켜준다.
      dispatchSearchState({ type: "DELETE_IDS" });
    }
    dispatchSearchState({ type: "CHANGE_SEARCH", value: true });
  }

  // 검색 객체
  const [searchState, dispatchSearchState] = useReducer(SearchTakeBackReducer, {
    // franchise_id : 1, // 가맹점 아이디, 반품 번호 필드가 없으면 전체 제품이 검색된다
    // take_back_id:
    start_date: dateToString(new Date(year, month, day - 7)) + " 00:00:00",
    end_date: dateToString(new Date()) + " 23:59:59",
    page: 1,
    unit: parsedTakeBackPageState ? (parsedTakeBackPageState?.unit ? parsedTakeBackPageState?.unit : 10) : 10,
    block: 10,
    search: true,
    ...searchToObject(),
  });

  // 반품 상세를 갈 때 sessionStorage에 저장할 객체
  const takeBackPageState = {
    unit: searchState.unit,
    page: searchState.page,
  };

  // 반품 등록한 상품 정보
  function callGetProducts() {
    // 검색 객체는 자동으로 등록이 되지만 보여주기 위한 날짜 객체를 넣는다.
    // 날짜 선택 창에 검색 객체랑 동일한 날짜를 넣어준다.
    setStartDate(new Date(searchState.start_date));
    setEndDate(new Date(searchState.end_date));
    setInpuTakeBackId(searchState.take_back_id);

    getProducts(searchState)
      .then((res) => {
        if (res.status) {
          setPagination(res.pagination);
          setTakeBackList(res.results);
          dispatchSearchState({ type: "CHANGE_SEARCH", value: false });
        }
      })
      .catch((res) => {
        dispatchSearchState({ type: "CHANGE_SEARCH", value: false });
      });
  }

  function onClickItem(take_back_id) {
    navigate("/product/takeback/" + take_back_id);
    let jsonString = JSON.stringify(takeBackPageState);
    sessionStorage.setItem("takeBackPageState", jsonString);
  }

  // 페이지 이동 시 작동
  function movePage(e) {
    dispatchSearchState({ type: "CHANGE_NOW_PAGE", value: e });
    // setIsSearch(true)
    // setIsTableSearch(true)
  }

  function ExportHandler() {
    if (takeBackList?.length > 0) {
      getProducts({ ...searchState, unit: 99999999, page: 1 }).then((res) => {
        if (res.status) {
          const data = [];
          const excelTableHead = [["No", "반품번호", "가맹점", "품목수량", "총금액", "주문일", "반품상태"]];
          // excel 문서 만들기
          const wb = utils.book_new();
          // 열 순서를 sheet화
          const ws = utils.json_to_sheet([]);
          // ws에 excelTableHead를 추가
          utils.sheet_add_aoa(ws, excelTableHead);

          res.results.forEach((item) => {
            data.push({
              row_num: `${item.row_num}`,
              take_back_id: `${item.take_back_id}`,
              franchise_name: `${item.franchise_name}`,
              take_back_count: `${item.take_back_count}`,
              amount: `${item.amount}원`,
              createdAt: `${item.createdAt}`,
              status: `${TAKEBACK_STATUS_LIST.find((i) => i.value === item.status)?.text || "-"}`,
            });
          });

          utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
          utils.book_append_sheet(wb, ws, "Report");
          // 두 번째 arg에는 export 될 파일의 이름을 넣어주면 된다.
          writeFile(wb, `반품관리_${dateToString(startDate)}~${dateToString(endDate)}.xlsx`);
        } else {
          setTextMsg(res.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      });
    } else {
      setTextMsg("다운받을 데이터가 없습니다.");
      setTextModal(true);
    }
  }

  useEffect(() => {
    // 1. searchState를 searchBar에서도 변경하기 때문에 초기에 두번 작동된다.
    // 2. 검색버튼을 여러번 누를때를 대비해서 방지 가능
    delayCall(callGetProducts);
  }, [location]);

  useEffect(() => {
    if (searchState.search) {
      const params = {};
      Object.entries(searchState).forEach((item) => (params[item[0]] = item[1]));
      navigate({ pathname: "/product/takeback", search: `?${createSearchParams(params)}` }, { replace: true });
    }
  }, [searchState]);

  useEffect(() => {
    dispatchSearchState({ type: "CHANGE_UNIT", value: unit });
    dispatchSearchState({ type: "CHANGE_NOW_PAGE", value: 1 });
  }, [unit]);

  useEffect(() => {
    // 현재 페이지에 도착하면 무조건 한 번 초기화
    sessionStorage.removeItem("takeBackPageState");
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  console.log(takeBackList);
  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {isMobile && <Header title={"반품 관리"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    반품 관리 📋
                  </Title>
                )}
                <WhiteBox margin={"0 0"}>
                  {/* searchFunction,  setInputNumber로 정의 해 준 이유는 반품 페이지와 주문내역관리 페이지 두 곳에서 사용할 것이기 때문에 각자 이름을 따로 붙여줬다.*/}
                  <SearchBar
                    searchState={searchState}
                    dispatchSearchState={dispatchSearchState}
                    searchFunction={searchTakeBack}
                    franText={franText}
                    setFranText={setFranText}
                    franShow={franShow}
                    setFranShow={setFranShow}
                    setSearchFranchise={setSearchFranchise}
                    inputNumber={inputTakeBackId}
                    setInputNumber={setInpuTakeBackId}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    startDate={startDate}
                    endDate={endDate}
                    inputPlaceholder="반품 번호를 입력해 주세요"
                  />

                  <FlexBox
                    justify={"space-between"}
                    gap={"10px"}
                    margin={"20px 0 10px"}
                    marginMd={"20px 0 30px"}
                    wrap={"wrap"}
                    wrapMd={"no-wrap"}
                  >
                    <FlexBox gap={"10px"} width={"100%"} widthMd={"auto"}>
                      <Text
                        width={"100%"}
                        widthMd={"unset"}
                        color={"#FF4A4A"}
                        size={"16px"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        style={{
                          flexShrink: 0,
                        }}
                      >
                        금액 합계 :{" "}
                        <span style={{ fontWeight: "700" }}>
                          {AddComma(takeBackList.reduce((sum, item) => sum + parseInt(item.amount), 0))}원
                        </span>
                      </Text>
                      {!isMobile && (
                        <BasicSelect
                          width={"130px"}
                          data={UNIT_10_TO_50_LIST}
                          selectedValue={unit}
                          setSelectedValue={setUnit}
                          canSelectAll={false}
                        />
                      )}
                    </FlexBox>
                    <FlexBox gap={"10px"} justify={"flex-end"} width={"100%"} widthMd={"auto"}>
                      {!isMobile && (
                        <Btn
                          size={"14px"}
                          color={"#666"}
                          border={"1px solid #666"}
                          padding={"8px 18px"}
                          lineHeight={"22px"}
                          hoverWeight={"700"}
                          hoverColor={"#fff"}
                          hoverBackground={"#C7C7C7"}
                          hoverBorder={"1px solid transparent"}
                          onClick={ExportHandler}
                        >
                          엑셀 다운
                        </Btn>
                      )}
                    </FlexBox>
                  </FlexBox>

                  <TableWrap>
                    <table style={{ width: "100%", minWidth: "1024px" }}>
                      <colgroup>
                        <col style={{ width: "6%" }} />
                        <col style={{ width: "11%" }} />
                        <col />
                        <col style={{ width: "11%" }} />
                        <col style={{ width: "16%" }} />
                        <col style={{ width: "16%" }} />
                        <col style={{ width: "11%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>No</Th>
                          <Th>반품번호</Th>
                          <Th>가맹점</Th>
                          <Th>품목 수량</Th>
                          <Th>총 금액</Th>
                          <Th>주문일</Th>
                          <Th>상 태</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {takeBackList?.length > 0 ? (
                          takeBackList?.map((item, key) => (
                            <tr key={key} onClick={() => onClickItem(item.take_back_id)}>
                              <Td>{item.row_num}</Td>
                              <Td>{item.take_back_id}</Td>
                              <Td>{item.franchise_name}</Td>
                              <Td>{item.take_back_count}</Td>
                              <Td>{AddComma(item.amount)}원</Td>
                              <Td onClick={() => onClickItem(item.take_back_id)}>{item.createdAt}</Td>
                              <Td>
                                {item.status
                                  ? TAKEBACK_STATUS_LIST.find((i) => i.value === item.status)?.text || "-"
                                  : "-"}
                              </Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={7}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>

                  {pagination?.total_page > 0 ? (
                    <Pagination
                      pagination={pagination}
                      movePage={movePage}
                      currentPage={
                        parsedTakeBackPageState
                          ? parsedTakeBackPageState?.page
                            ? parsedTakeBackPageState?.page
                            : searchState.page
                          : searchState.page
                      }
                    />
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}
