import React, { useState, useEffect } from "react";
import close from "../../../Assets/images/close.svg";
import { AddComma } from "../../../Utiles/Custom";
import API from "../../../Utiles/API";
import * as Hangul from "hangul-js";
import {
  Btn,
  Text,
  FlexBox,
  ModalBg,
  ModalConts,
  CancelBtn,
  SelectBtn,
  SearchList,
  SelectWrap,
  SearchInput,
  SelectContainer,
} from "../../../Assets/css/commonVer2";

// 이전 자점매입상품 검색

export default function BeforeSelfGoodsSearchModal(props) {
  const { setModal, setValues, setPriceValue } = props;

  const [franchise_id] = useState(sessionStorage.getItem("franchise_id"));
  const [text, setText] = useState("상품 선택");
  const [listShow, setListShow] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedIdx, setSelectedIdx] = useState(null);
  const [selfGoodsList, setSelfGoodsList] = useState([]);
  const [modifiedList, setModifiedList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const getBeforeSelfGoodsList = async () => {
    await API.get(`/slot/user/previous-product?franchise_id=${franchise_id}`)
      .then((res) => {
        if (res.data.length) {
          const modifiedList = res.data.map((item) => ({
            idx: item.idx,
            text: `${item.product_name}(${item.barcode_num})`,
          }));
          modifiedList.map((item) => {
            const dis = Hangul.disassemble(item.text, true);
            const cho = dis.reduce(function (prev, elem) {
              elem = elem[0] ? elem[0] : elem;
              return prev + elem;
            }, "");
            item.diassembled = cho;
          });
          setFilteredData(modifiedList);
          setModifiedList(modifiedList);
          setSelfGoodsList(res.data);
        }
      })
      .catch(() => {});
  };

  // 검색 결과 클릭 시
  const btnClick = (e, idx) => {
    setListShow(false);
    setText(e.target.innerText);
    setSelectedIdx(idx);
  };

  // 확인
  const onSubmit = () => {
    const val = selfGoodsList.filter((e, i) => e.idx === selectedIdx).map((e) => e);
    setValues({
      barcode_num: val[0].barcode_num,
      category2nd_id: val[0]?.["category2nd.idx"],
      product_name: val[0].product_name,
      consumer_price: val[0].consumer_price,
    });
    setPriceValue(AddComma(val[0].consumer_price));
    setModal(false);
  };

  const onClose = () => {
    setModal(false);
  };

  useEffect(() => {
    getBeforeSelfGoodsList();
  }, []);

  useEffect(() => {
    if (inputValue.length > 0) {
      setFilteredData(
        modifiedList.filter(
          (item) => item.text.includes(inputValue) || item.diassembled.includes(Hangul.disassemble(inputValue).join(""))
        )
      );
    } else {
      setFilteredData(modifiedList);
    }
  }, [inputValue, modifiedList]);

  return (
    <ModalBg>
      <ModalConts style={{ width: "360px" }}>
        <CancelBtn onClick={onClose}>
          <img src={close} />
        </CancelBtn>
        <Text
          color={"#1E1E1E"}
          size={"16px"}
          weight={"500"}
          lineHeight={"22px"}
          style={{
            whiteSpace: "pre-wrap",
            marginBottom: "10px",
          }}
        >
          {"상품 선택"}
        </Text>

        <SelectContainer>
          <SelectBtn
            width={"100%"}
            show={listShow}
            onClick={() => {
              setListShow((state) => !state);
            }}
          >
            {text}
          </SelectBtn>
          {modifiedList.length > 0 && listShow ? (
            <SelectWrap width={"100%"}>
              <div style={{ padding: "2px" }}>
                <SearchInput
                  id={`search_text`}
                  placeholder={`상품을 검색해보세요.`}
                  // style={{ width: "100%" }}
                  value={inputValue}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                  }}
                  radius={"20px"}
                  width={"100%"}
                  size={"14px"}
                  border={"1px solid var(--light-gray-01, #f2f2f2)"}
                  boxShadow={"0px 1px 2px 0px rgba(0, 0, 0, 0.08)"}
                />
              </div>
              <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                {filteredData.map((item, i) => (
                  <SearchList id={`search_list`} key={i} onClick={(e) => btnClick(e, item.idx)}>
                    <li>
                      <span>{item.text}</span>
                    </li>
                  </SearchList>
                ))}
              </div>
            </SelectWrap>
          ) : (
            ""
          )}
        </SelectContainer>

        <FlexBox margin={"20px 0 0"} marginMd={"15px 0 0"}>
          <Btn
            background={"#f2f2f2"}
            color={"#666"}
            weight={"500"}
            margin={"0 10px 0 0"}
            border={"none"}
            size={"12px"}
            sizeMd={"14px"}
            padding={"9px 19px"}
            paddingMd={"9px 19px"}
            onClick={onClose}
          >
            취소
          </Btn>
          <Btn
            background={"#FF4A4A"}
            color={"#fff"}
            weight={"700"}
            border={"none"}
            size={"12px"}
            sizeMd={"14px"}
            padding={"9px 19px"}
            paddingMd={"9px 19px"}
            onClick={onSubmit}
          >
            확인
          </Btn>
        </FlexBox>
      </ModalConts>
    </ModalBg>
  );
}
