import { FlexBox, Text } from "../../Assets/css/common";
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// 기존 UI : 사용O

const Authority = (props) => {
  const navigate = useNavigate();
  const { title } = props;
  return (
    <ModalBg>
      <FindBox>
        <Text color={"#2D2D2D"} size={"16px"} weight={"700"} style={{ paddingBottom: "20px" }}>
          {title === "wrong" ? "잘못된 접근입니다.": title + "만 접속이 가능한 페이지 입니다."}
        </Text>
        <FlexBox justify={"center"} style={{ marginTop: "20px" }}>
          <CancleBtn>
            <Text color={"#FFFFFF"} size={"18px"} weight={"700"} onClick={() => navigate(-1)}>
              확 인
            </Text>
          </CancleBtn>
        </FlexBox>
      </FindBox>
    </ModalBg>
  );
};

const ModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FindBox = styled.div`
  background-color: #fff;
  max-width: 380px;
  max-height: 600px;
  width: 90%;
  padding: 60px 40px 40px;
  border-radius: 8px;
  position: relative;
`;
const Cancel = styled.img``;
const CancleBtn = styled.button`
  background-color: #dd1212;
  height: 40px;
  width: 84px;
  left: 0px;
  top: 0px;
  border-radius: 8px;
  outline: none;
  border: 1px solid #dd1212;
  margin: 7px;
`;

export default Authority;
