import React, { useEffect, useState, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";

import {
  ContentContainer,
  Container,
  FlexBox,
  Title,
  Wrapper,
  Th,
  Td,
  Text,
  TableWrap,
  Btn,
  WhiteBox,
  OrderAddrBox,
  ReturnListMWrap,
  SquareImg,
  Tdm,
  ShopImg,
  ShopText,
  TakeBackText,
} from "../../Assets/css/commonVer2";
import img_noimage from "../../Assets/images/no_image.jpg";
import HeaderSub from "../../Components/Header/HeaderSub";
import Authority from "../../Components/Modal/Authority";
import InfoModal from "../../Components/Modal/InfoModal";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import Loading from "../../Components/Loading";
import { AddComma } from "../../Utiles/Custom";
import { TAKEBACK_ITEM_STATUS_LIST, SHOP_TAKEBACK_STATUS_LIST,SHOP_TAKEBACK_REASON_LIST } from "../../Utiles/ListData";
import { getShopTackBackDetail, reviewShopTakeback } from "../../Utiles";
import ShopInputTakeBackModal from "./Components/ShopInputTakeBackModal";

// 쇼핑몰 > 반품 관리 > 상세
// 반품 신청 건 상태값: check_request 검수 요쳥 / check_completed 검수 완료 / deposit_completed 입금 완료
// 고객정보 > 휴대폰번호는 반품신청 시 고객이 입력한 휴대폰번호입니다

function ShopTakeBackView() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [loading, setLoading] = useState(false);
  const { idx } = useParams();

  // 모달
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [goBackModal, setGoBackModal] = useState(false);
  const [refreshModal, setRefreshModal] = useState(false);
  const [inputTakebackModal, setInputTakebackModal] = useState(false);

  // 검수완료 상태
  const [hoverRow, setHoverRow] = useState(null); // 부분승인, 반려 표시
  const [reasonModal, setReasonModal] = useState(false);
  const [reasonModalTxts] = useState({
    title: "",
    text: "",
    reason: "",
  });
  
  // 페이지 상태를 나타내는 값
  const [isBasicMode, setIsBasicMode] = useState(true); // 일반 상태
  const [isReviewMode, setIsReviewMode] = useState(false); // 검수 상태

  // 고객 정보 및 반품 정보
  const [takeBackInfo, setTakeBackInfo] = useState({});

  // 반품 상품 리스트(표)
  const [takeBackList, setTakeBackList] = useState([]);

  // 반품 검수 시 필요한 데이터
  const [reviewList, setReviewList] = useState([]);

  // 모바일_반품검수
  const [clickedIdx, setClickedIdx] = useState({});
  const [clickedReview, setClickedReview] = useState({
    shoppingmall_take_back_allow_quantity: "",
    shoppingmall_take_back_not_approval_reason: "",
  });
  const [clickedQuantity, setClickedQuantity] = useState(0); // 반품신청수량

  // API
  // 1. 반품 상세 조회 GET api
  const getData = () => {
    getShopTackBackDetail(idx).then((res) => {
      if (res.status) {
        setTakeBackInfo(res);
        setTakeBackList(res.take_back_detail);

        // 반품 검수하기 전이라면 검수할 준비
        if (res.take_back_status === "check_request") {
          const data = res.take_back_detail.map((item) => ({
            take_back_detail_id: item.shoppingmall_take_back_detail_idx,
            reviewStatus: "approval",
            shoppingmall_take_back_not_approval_reason: "",
          }));
          setReviewList(data);
        }
      }
    });
  };

  // 2. 반품 검수 PUT api
  const review = () => {
    setLoading(true);
    const hasMismatch = reviewList.some((item) => {
      const correspondingTakeBackItem = takeBackList.find(
        (takeBackItem) => takeBackItem.shoppingmall_take_back_detail_idx === item.take_back_detail_id
      );
      // 반품인정수량 미입력 시 입력 안내
      if (
        correspondingTakeBackItem &&
        !item.hasOwnProperty("shoppingmall_take_back_allow_quantity")
      ) {
        setTextMsg("반품인정수량을 입력해주세요.");
        return true;
      }

      // 반품인정수량이 반품신청수량보다 많은 경우
      if (
        correspondingTakeBackItem &&
        item.shoppingmall_take_back_allow_quantity > correspondingTakeBackItem.shoppingmall_take_back_quantity
      ) {
        setTextMsg("반품인정수량은 반품신청수량보다 작거나 같아야합니다.");
        return true;
      }

      // 반품승인 시 반품거절사유 삭제 안내
      if (
        correspondingTakeBackItem &&
        item.shoppingmall_take_back_allow_quantity === correspondingTakeBackItem.shoppingmall_take_back_quantity &&
        item.shoppingmall_take_back_not_approval_reason
      ) {
        setTextMsg("반품신청수량 모두 인정하는 경우 반품거절사유는 삭제해야합니다.");
        return true;
      }

      // 부분승인 또는 반품거절 시 반품거절사유 필요
      if (
        correspondingTakeBackItem &&
        item.shoppingmall_take_back_allow_quantity !== correspondingTakeBackItem.shoppingmall_take_back_quantity &&
        !item.shoppingmall_take_back_not_approval_reason
      ) {
        setTextMsg("1개라도 반품을 인정하지 않는 경우 반품거절사유를 입력해야합니다.");
        return true;
      } else if (
        correspondingTakeBackItem &&
        item.shoppingmall_take_back_allow_quantity === 0 &&
        !item.shoppingmall_take_back_not_approval_reason
      ) {
        setTextMsg("1개라도 반품을 인정하지 않는 경우 반품거절사유를 입력해야합니다.");
        return true;
      }

      return false; // 모든 조건 만족
    });

    if (hasMismatch) {
      // 조건을 만족하지 않는 경우 처리
      setLoading(false);
      setTextModal(true);
      return;
    }

    // 서버로 보낼 반품검수 데이터
    const updatedReviewList = reviewList.map((item) => {
      const correspondingTakeBackItem = takeBackList.find(
        (takeBackItem) => takeBackItem.shoppingmall_take_back_detail_idx === item.take_back_detail_id
        );

      // 조건 1: shoppingmall_take_back_allow_quantity 가 shoppingmall_take_back_quantity 와 같은 경우
      if (
        correspondingTakeBackItem &&
        item.shoppingmall_take_back_allow_quantity === correspondingTakeBackItem.shoppingmall_take_back_quantity
      ) {
        const updatedItem = { ...item };
        // 반품승인 시 반품거절사유 key 삭제
        delete updatedItem.shoppingmall_take_back_not_approval_reason;
        updatedItem.reviewStatus = "approval";
        return updatedItem;
      }

      // 조건 2: shoppingmall_take_back_allow_quantity 가 shoppingmall_take_back_quantity 보다 작고
      // shoppingmall_take_back_allow_quantity 가 0이 아닌 경우
      if (
        correspondingTakeBackItem &&
        item.shoppingmall_take_back_allow_quantity < correspondingTakeBackItem.shoppingmall_take_back_quantity &&
        item.shoppingmall_take_back_allow_quantity !== 0
      ) {
        return {
          ...item,
          reviewStatus: "part_approval",
        };
      }

      // 조건 3: shoppingmall_take_back_allow_quantity 가 shoppingmall_take_back_quantity 보다 작고
      // shoppingmall_take_back_allow_quantity 가 0인 경우
      if (
        correspondingTakeBackItem &&
        item.shoppingmall_take_back_allow_quantity < correspondingTakeBackItem.shoppingmall_take_back_quantity &&
        item.shoppingmall_take_back_allow_quantity === 0
      ) {
        return {
          ...item,
          reviewStatus: "reject",
        };
      }

      return item; // 조건을 만족하지 않는 항목은 그대로 유지
    });

    // PUT api 호출
    reviewShopTakeback(idx, updatedReviewList)
      .then((res) => {
        setLoading(false);
        if (res.status) {
          setTextMsg("검수가 완료되었습니다.");
          setRefreshModal(true);
        } else {
          setTextMsg(res.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setLoading(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const handleSubmit = (e) => {
    setConfirmModal(false);
    review();
  };

  // 웹_반품검수 정보 입력
  function onChangeReviewValue(gubun, index, value) {
    setReviewList((prevList) => {
      const newList = [...prevList];
      const itemToUpdate = newList[index];

      if (itemToUpdate) {
        if (gubun === "shoppingmall_take_back_allow_quantity") {
          itemToUpdate.shoppingmall_take_back_allow_quantity = parseInt(value);
        } else {
          itemToUpdate.shoppingmall_take_back_not_approval_reason = value;
        }
      }
      return newList;
    });
  }

  // 모바일_반품인정수량 변경
  const setReview = (updatedClickedReview) => {
    const updatedReviewList = reviewList.map((item, index) => {
      if (index === clickedIdx) {
        return updatedClickedReview; // clickedIdx 위치의 항목을 clickedReview로 대체
      }
      return item; // 나머지 항목은 그대로 유지
    });
    setReviewList(updatedReviewList);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {loading ? <Loading /> : null}
          <HeaderSub title={"반품 상세"} />
          <ContentContainer>
            <Wrapper type={2} pbM={"112px"}>
              <Container>
              {!isMobile && (
                <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                  반품 상세 👩🏻‍💻
                </Title>
              )}
                <WhiteBox>
                  <Title
                    color={"#1E1E1E"}
                    weight={"700"}
                    size={"16px"}
                    sizeMd={"18px"}
                    style={{
                      paddingBottom: "10px",
                      borderBottom: "1px solid #666",
                    }}
                  >
                    고객 정보
                  </Title>
                  <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                    <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                      고객명
                    </Text>
                    <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                      {takeBackInfo?.order_user_name ? takeBackInfo.order_user_name : "-"}
                    </Text>
                  </OrderAddrBox>
                  <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                    <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                      휴대폰번호
                    </Text>
                    <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                      {takeBackInfo?.phone ? takeBackInfo.phone : "-"}
                    </Text>
                  </OrderAddrBox>
                  <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                    <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                      주소
                    </Text>
                    <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                      {takeBackInfo?.address ? takeBackInfo.address : "-"}
                    </Text>
                  </OrderAddrBox>
                </WhiteBox>
                <WhiteBox>
                  <FlexBox justify={"space-between"}>
                    <Title
                      color={"#1E1E1E"}
                      weight={"700"}
                      size={"16px"}
                      sizeMd={"18px"}
                      style={{
                        paddingBottom: "10px",
                      }}
                    >
                      반품 정보
                    </Title>
                    {takeBackInfo.deletedAt === null &&
                      takeBackInfo?.take_back_status === "check_request" && (
                      <FlexBox style={{ marginLeft: "auto" }} gap={"10px"}>
                        {isBasicMode ? (
                          <Btn
                            size={"14px"}
                            paddingMd={"8px 18px"}
                            padding={"4px 10px"}
                            lineHeight={"22px"}
                            hoverWeight={"700"}
                            onClick={() => {
                              setIsBasicMode(false);
                              setIsReviewMode(true);
                            }}
                          >
                            반품 검수
                          </Btn>
                        ) : (
                          <>
                            <Btn
                              size={"14px"}
                              paddingMd={"8px 18px"}
                              padding={"4px 10px"}
                              lineHeight={"22px"}
                              hoverWeight={"700"}
                              background={"#C7C7C7"}
                              border={"1px solid #C7C7C7"}
                              color="#fff"
                              width={"100%"}
                              widthMd={"auto"}
                              onClick={() => {
                                setIsBasicMode(true);
                                setIsReviewMode(false);
                                const data = takeBackList.map((item) => ({
                                  shoppingmall_take_back_detail_idx: item.shoppingmall_take_back_detail_idx,
                                  shoppingmall_take_back_allow_quantity: item.shoppingmall_take_back_allow_quantity,
                                  shoppingmall_take_back_review_status: "approval",
                                  shoppingmall_take_back_not_approval_reason: "",
                                }));
                                setReviewList(data);
                              }}
                            >
                              취소
                            </Btn>
                            <Btn
                              color="#fff"
                              background={"#FF4A4A"}
                              size={"14px"}
                              paddingMd={"8px 18px"}
                              padding={"4px 10px"}
                              lineHeight={"22px"}
                              hoverWeight={"700"}
                              width={"100%"}
                              widthMd={"auto"}
                              onClick={() => {
                                setConfirmModal(true);
                              }}
                            >
                              저장
                            </Btn>
                          </>
                        )}
                      </FlexBox>
                    )}
                  </FlexBox>
                  <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                    <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                      반품상태
                    </Text>
                    <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                    {takeBackInfo.deletedAt !== null
                      ? "반품취소"
                      : SHOP_TAKEBACK_STATUS_LIST.find((i) => i.value === takeBackInfo.take_back_status)?.text || "-"}
                    </Text>
                  </OrderAddrBox>
                  <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                    <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                      반품사유
                    </Text>
                    <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                      {takeBackInfo?.shoppingmall_take_back_reason
                        ? SHOP_TAKEBACK_REASON_LIST.find((x) => x.value === takeBackInfo.shoppingmall_take_back_reason)?.text || "-"
                        : "-"}
                    </Text>
                  </OrderAddrBox>
                  <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                    <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                      반품상세사유
                    </Text>
                    <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                      {takeBackInfo?.shoppingmall_take_back_detail_reason ? takeBackInfo.shoppingmall_take_back_detail_reason : "-"}
                    </Text>
                  </OrderAddrBox>
                  <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                    <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                      은행명
                    </Text>
                    <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                      {takeBackInfo?.bank ? takeBackInfo.bank : "-"}
                    </Text>
                  </OrderAddrBox>
                  <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                    <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                      예금주
                    </Text>
                    <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                      {takeBackInfo?.account_holder ? takeBackInfo.account_holder : "-"}
                    </Text>
                  </OrderAddrBox>
                  <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                    <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                      계좌번호
                    </Text>
                    <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                      {takeBackInfo?.account_number ? takeBackInfo.account_number : "-"}
                    </Text>
                  </OrderAddrBox>               
                  <FlexBox
                    gap={"10px"}
                    justify={"start"}
                    margin={"20px 0 0"}
                    direction={"column"}
                    directionMd={"row"}
                    wrap={"wrap"}
                  >
                    <TakeBackText>
                      반품번호 : <span>{takeBackInfo?.idx}</span>
                    </TakeBackText>
                    <TakeBackText>
                      반품신청일 : <span>{takeBackInfo?.createdAt}</span>
                    </TakeBackText>
                    <TakeBackText>
                      품목 : <span>{takeBackList.length} 개</span>
                    </TakeBackText>
                    <TakeBackText>
                      총 금액 : <span style={{ color: "#FF4A4A", fontWeight: "500" }}>{AddComma(takeBackInfo.expected_take_back_amount)}원</span>
                    </TakeBackText>
                    <TakeBackText>
                      최종 반품 금액 : <span style={{ color: "#FF4A4A", fontWeight: "500" }}>{AddComma(takeBackInfo.final_take_back_amount)}원</span>
                    </TakeBackText>
                    <TakeBackText>
                      현금 : <span style={{ color: "#FF4A4A", fontWeight: "500" }}>{AddComma(takeBackInfo.final_take_back_amount_cash)}원</span>
                    </TakeBackText>
                    <TakeBackText>
                      포인트 : <span style={{ color: "#FF4A4A", fontWeight: "500" }}>{AddComma(takeBackInfo.final_take_back_amount_point)}원</span>
                    </TakeBackText>
                  </FlexBox>
                  <ReturnListMWrap>
                    <table style={{ width: "100%", overflow: "hidden" }}>
                      <colgroup className="d-none d-lg-table-column-group">
                        {isBasicMode && (
                          <>
                            <col style={{ width: "8%" }} />
                            <col />
                            <col style={{ width: "20%" }} />
                            {takeBackInfo.take_back_status === "check_request" ? (
                              <col style={{ width: "15%" }} />
                            ) : (
                              <>
                                <col style={{ width: "11%" }} />
                                <col style={{ width: "11%" }} />
                              </>
                            )}
                            <col style={{ width: "15%" }} />
                          </>
                        )}
                        {isReviewMode && (
                          <>
                            <col style={{ width: "8%" }} />
                            <col />
                            <col style={{ width: "15%" }} />
                            <col style={{ width: "13%" }} />
                            <col style={{ width: "15%" }} />
                            <col />
                          </>
                        )}
                      </colgroup>
                      <thead className="d-none d-lg-table-head">
                      {isBasicMode && (
                        <>
                          <Th>No</Th>
                          <Th>상품명</Th>
                          <Th>상품 금액</Th>
                          {takeBackInfo.take_back_status === "check_request" ? (
                            <Th>반품신청수량</Th>
                          ) : (
                            <>
                              <Th>반품신청수량</Th>
                              <Th>최종반품수량</Th>
                            </>
                          )}
                          <Th>상태</Th>
                        </>
                      )}
                      {isReviewMode && (
                        <>
                          <Th>No</Th>
                          <Th>상품명</Th>
                          <Th>반품신청수량</Th>
                          <Th>최종반품금액</Th>
                          <Th>반품인정수량</Th>
                          <Th>거절사유</Th>
                        </>
                      )}
                      </thead>
                      <tbody className="d-block d-lg-table-row-group">
                        {takeBackList?.length > 0 ? (
                          takeBackList?.map((item, key) => (
                            <tr
                              key={key}
                              className="d-block d-lg-table-row"
                              onMouseEnter={() => {
                                setHoverRow(key);
                              }}
                              onMouseLeave={() => {
                                setHoverRow(null);
                              }}
                              onClick={() => {
                                if (item.shoppingmall_take_back_review_status === "part_approval" || item.shoppingmall_take_back_review_status === "reject") {
                                  reasonModalTxts.title = "반품반려사유";
                                  reasonModalTxts.text = "아래와 같은 사유로 반품이 반려되었습니다.";
                                  reasonModalTxts.reason = item?.shoppingmall_take_back_not_approval_reason;
                                  setReasonModal(true);
                                  return;
                                }
                              }}
                            >
                              {isBasicMode && (
                                <>
                                  <Td className="d-none d-lg-table-cell">
                                    {key + 1}
                                  </Td>
                                  <Td className="d-none d-lg-table-cell" align={"left"}>
                                    <FlexBox justify={"flex-start"} gap={"10px"}>
                                      <ShopImg>
                                        <SquareImg>
                                          <div>
                                            <img
                                              src={
                                                item?.image_path[0]?.image_path
                                                  ? `${process.env.REACT_APP_S3_KEY}${item?.image_path[0]?.image_path}`
                                                  : img_noimage
                                              }
                                            />
                                          </div>
                                        </SquareImg>
                                      </ShopImg>
                                      <ShopText>
                                        <Text size={"12px"} weight={"500"} color={"#2d2d2d"} align={"left"}>
                                          {item?.product_name}
                                        </Text>
                                        <Text size={"14px"} color={"#666"} align={"left"}>
                                          {item?.barcode_num}
                                        </Text>
                                      </ShopText>
                                    </FlexBox>
                                  </Td>
                                  <Td className="d-none d-lg-table-cell" align={"right"}>
                                    {AddComma(item?.consumer_price)}원
                                  </Td>
                                  {takeBackInfo.take_back_status === "check_request" ? (
                                    <Td className="d-none d-lg-table-cell">
                                      {item?.shoppingmall_take_back_quantity}개
                                    </Td>
                                  ) : (
                                    <>
                                      <Td className="d-none d-lg-table-cell">
                                        {item?.shoppingmall_take_back_quantity}개
                                      </Td>
                                      <Td className="d-none d-lg-table-cell">
                                        {item?.shoppingmall_take_back_allow_quantity}개
                                      </Td>
                                    </>
                                  )}
                                  <Td
                                    data-type="status"
                                    align={"center"}
                                    className="d-none d-lg-table-cell"
                                    color={
                                      item.shoppingmall_take_back_review_status === "part_approval" || item.shoppingmall_take_back_review_status === "reject"
                                        ? "#F63958"
                                        : "#666666"
                                    }
                                    style={{
                                      cursor:
                                        item.shoppingmall_take_back_review_status === "part_approval" || item.shoppingmall_take_back_review_status === "reject"
                                          ? "pointer"
                                          : "",
                                      textDecoration:
                                        item.shoppingmall_take_back_review_status === "part_approval" || item.shoppingmall_take_back_review_status === "reject"
                                          ? "underline"
                                          : "none",
                                      fontWeight:
                                        hoverRow === key &&
                                        (item.shoppingmall_take_back_review_status === "part_approval" || item.shoppingmall_take_back_review_status === "reject")
                                          ? "bold"
                                          : "normal",
                                    }}
                                  >
                                    {item.shoppingmall_take_back_review_status
                                      ? TAKEBACK_ITEM_STATUS_LIST.find((x) => x.value === item.shoppingmall_take_back_review_status)?.text ||
                                        "-"
                                      : "미검수"}
                                  </Td>
                                </>
                              )}
                              {isReviewMode && (
                                <>
                                  <Td className="d-none d-lg-table-cell">
                                    {key +1}
                                  </Td>
                                  <Td className="d-none d-lg-table-cell" align={"left"}>
                                    <FlexBox justify={"flex-start"} gap={"10px"}>
                                      <ShopImg>
                                        <SquareImg>
                                          <div>
                                            <img
                                              src={
                                                item?.image_path[0]?.image_path
                                                  ? `${process.env.REACT_APP_S3_KEY}${item.image_path[0]?.image_path}`
                                                  : img_noimage
                                              }
                                            />
                                          </div>
                                        </SquareImg>
                                      </ShopImg>
                                      <ShopText>
                                        <Text size={"12px"} weight={"500"} color={"#2d2d2d"} align={"left"}>
                                          {item?.product_name}
                                        </Text>
                                        <Text size={"14px"} color={"#666"} align={"left"}>
                                          {item?.barcode_num}
                                        </Text>
                                      </ShopText>
                                    </FlexBox>
                                  </Td>
                                  <Td className="d-none d-lg-table-cell">
                                    {item?.shoppingmall_take_back_quantity}
                                  </Td>
                                  <Td className="d-none d-lg-table-cell">
                                    {AddComma(item?.consumer_price * reviewList[key]?.shoppingmall_take_back_allow_quantity)}원
                                  </Td>
                                  <Td className="d-none d-lg-table-cell">
                                    <input
                                      type="number"
                                      id={item.barcode_num}
                                      onChange={(e) => {
                                        onChangeReviewValue("shoppingmall_take_back_allow_quantity", key, e.target.value);
                                      }}
                                      value={reviewList[key]?.shoppingmall_take_back_allow_quantity}
                                      style={{
                                        fontSize: "12px",
                                        color: "#6c6c6c",
                                        width: "40px",
                                        textAlign: "center",
                                        border: "1px solid #c0c0c0",
                                        padding: "2px",
                                        borderRadius: "6px",
                                      }}
                                      min={0}
                                      max={item?.shoppingmall_take_back_quantity}
                                    />
                                  </Td>
                                  <Td className="d-none d-lg-table-cell">
                                    <input
                                      id={item.barcode_num}
                                      onChange={(e) => {
                                        onChangeReviewValue("shoppingmall_take_back_not_approval_reason", key, e.target.value);
                                      }}
                                      value={reviewList[key]?.shoppingmall_take_back_not_approval_reason}
                                      style={{
                                        fontSize: "12px",
                                        color: "#6c6c6c",
                                        width: "100%",
                                        textAlign: "left",
                                        border: "1px solid #c0c0c0",
                                        padding: "2px",
                                        borderRadius: "6px",
                                      }}
                                    />
                                  </Td>
                                </>
                              )}
                              {/* 모바일_반품 정보 tbody */}
                              <Tdm className="d-block d-lg-none">
                                <FlexBox justify={"flex-start"} gap={"10px"} style={{ position: "relative" }}>
                                  <ShopImg>
                                    <SquareImg>
                                      <div>
                                        <img
                                          src={
                                            item?.image_path[0]?.image_path
                                              ? `${process.env.REACT_APP_S3_KEY}${item?.image_path[0]?.image_path}`
                                              : img_noimage
                                          }
                                        />
                                      </div>
                                    </SquareImg>
                                  </ShopImg>
                                  <ShopText>
                                    <Text size={"12px"} weight={"500"} color={"#6c6c6c"} align={"left"}>
                                      {item?.product_name}
                                    </Text>
                                    <FlexBox justify={"flex-start"} gap={"10px"}>
                                      <Text size={"12px"} color={"#6c6c6c"} align={"left"}>
                                        {item?.barcode_num}
                                      </Text>
                                    </FlexBox>
                                  </ShopText>
                                </FlexBox>
                                <FlexBox align={"flex-end"} justify={"space-between"} margin={"10px 0"}>
                                  {takeBackInfo.take_back_status === "check_request" ? (
                                    <>
                                      {isBasicMode && (
                                        <Text size={"12px"} color={"#6c6c6c"}>
                                          반품신청수량: {item?.shoppingmall_take_back_quantity}
                                        </Text>
                                      )}
                                      {isReviewMode && (
                                        <FlexBox direction={"column"}>
                                          <Text size={"12px"} color={"#6c6c6c"}>
                                            반품신청수량: {item?.shoppingmall_take_back_quantity}
                                          </Text>
                                          <Text size={"12px"} color={"#F63958"} weight={"700"}>
                                            반품인정수량: {reviewList[key]?.shoppingmall_take_back_allow_quantity}
                                          </Text>
                                        </FlexBox>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <Text size={"12px"} color={"#6c6c6c"}>
                                        반품신청수량: {item?.shoppingmall_take_back_quantity}
                                        <br />
                                        최종반품수량: {item?.shoppingmall_take_back_allow_quantity}
                                      </Text>
                                    </>
                                  )}
                                </FlexBox>
                                <FlexBox align={"flex-end"} justify={"space-between"} margin={"10px 0 4px"}>
                                  <Text size={"10px"} color={"#2d2d2d"} align={"right"}>
                                    상품금액
                                  </Text>
                                  <Text size={"10px"} color={"#2d2d2d"} align={"right"}>
                                    {AddComma(item?.consumer_price)}원
                                  </Text>
                                </FlexBox>
                                <FlexBox justify={"space-between"} padding={"0 0 10px"}>
                                  <Text color={"#2d2d2d"} size={"12px"}>
                                    상태
                                  </Text>
                                  <Text
                                    size={"14px"}
                                    onClick={() => {
                                      if (item.shoppingmall_take_back_review_status === "part_approval" || item.shoppingmall_take_back_review_status === "reject") {
                                        reasonModalTxts.title = "반품반려사유";
                                        reasonModalTxts.text = "아래와 같은 사유로 반품이 반려되었습니다.";
                                        reasonModalTxts.reason = item?.shoppingmall_take_back_not_approval_reason;
                                        setReasonModal(true);
                                        return;
                                      }
                                    }}
                                    color={
                                      item.shoppingmall_take_back_review_status === "part_approval" || item.shoppingmall_take_back_review_status === "reject"
                                        ? "#F63958"
                                        : "#666666"
                                    }
                                    style={{
                                      textDecoration:
                                        item.shoppingmall_take_back_review_status === "part_approval" || item.shoppingmall_take_back_review_status === "reject"
                                          ? "underline"
                                          : "none",
                                      fontWeight:
                                        item.shoppingmall_take_back_review_status === "part_approval" || item.shoppingmall_take_back_review_status === "reject"
                                          ? "bold"
                                          : "normal",
                                    }}
                                  >
                                    {item.shoppingmall_take_back_review_status
                                      ? TAKEBACK_ITEM_STATUS_LIST.find((x) => x.value === item.shoppingmall_take_back_review_status)?.text ||
                                        "-"
                                      : "미검수"}
                                  </Text>
                                </FlexBox>
                                {isReviewMode && (
                                  <Btn
                                    width={"100%"}
                                    margin={"10px 0"}
                                    size={"14px"}
                                    padding={"9px 19px"}
                                    onClick={() => {
                                      setClickedIdx(key);
                                      setClickedReview(reviewList[key]);
                                      setClickedQuantity(item?.shoppingmall_take_back_quantity);
                                      setInputTakebackModal(true);
                                    }}
                                  >
                                    반품인정수량 변경
                                  </Btn>
                                )}
                              </Tdm>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={6}
                              style={{
                                // borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              반품된 상품이 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </ReturnListMWrap>
                </WhiteBox>
              </Container>
              <ConfirmModal
                modal={confirmModal}
                setModal={setConfirmModal}
                onSubmit={handleSubmit}
                description={"검수를 완료하시겠습니까? 완료 시 취소가 불가합니다."}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
              <InfoModal modal={refreshModal} setModal={setRefreshModal} description={textMsg} mode={"refresh"} />
              <InfoModal modal={goBackModal} setModal={setGoBackModal} description={textMsg} mode={"goBack"} />
              <InfoModal
                modal={reasonModal}
                setModal={setReasonModal}
                title={reasonModalTxts.title}
                description={reasonModalTxts.text}
                detailText={reasonModalTxts.reason}
              />
              <ShopInputTakeBackModal
                modal={inputTakebackModal}
                setModal={setInputTakebackModal}
                clickedReview={clickedReview}
                setClickedReview={setClickedReview}
                clickedQuantity={clickedQuantity}
                onSubmit={(item) => setReview(item)}
              />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

export default ShopTakeBackView;