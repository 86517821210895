import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import HeaderSub from "../../Components/Header/HeaderSub";
import SearchAddress from "../../Components/SearchAddress/SearchAddress";
import API from "../../Utiles/API";
import { AddComma } from "../../Utiles/Custom";
import Authority from "../../Components/Modal/Authority";
import Loading from "../../Components/Loading";

import {
  Btn,
  Container,
  TextInput,
  Wrapper,
  FlexBox,
  Text,
  Title,
  WhiteBox,
  ContentContainer,
  RadioLabel,
  RadioIcon,
} from "../../Assets/css/commonVer2";
import InfoModal from "../../Components/Modal/InfoModal";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";

// 가맹점 등록
function FranchiseeWrite() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();

  const [values, setValues] = useState({
    franchise_name: "",
    business_num: "",
    franchise_address: "",
    detail_address: "",
    rental_information: "",
    membership_fee: "",
    royalty: "",
    contract_performance_bond: "",
    interior: "",
    maintenance_cost: "",
    kiosk_buy: true,
    individual_product_available: true,
    existing_stationery: true,
    capstack_cost: "",
    educational_cost: "",
    fi_logistics_setting_cost: "",
    fi_product_cost: "",
    sales_manager_id: "",
    logistics_manager_id: "",
    cash_receipts: true,
    franchise_type: "direct",
    type_of_business: "", // 업태
    items_of_business: "", // 종목
    status: "active",
  });

  const [priceValues, setPriceValues] = useState({
    membership_fee: "",
    royalty: "",
    contract_performance_bond: "",
    interior: "",
    maintenance_cost: "",
    capstack_cost: "",
    educational_cost: "",
    fi_logistics_setting_cost: "",
    fi_product_cost: "",
  });

  const [addrValue, setAddrValue] = useState("");

  // 카카오 지도 api 키(카카오 지도 SDK를 사용하기 위한 인증 키(앱 키))
  const apiKey = process.env.REACT_APP_KAKAOMAP_API_KEY;

  const [latitude, setLatitude] = useState(""); // 위도
  const [longitude, setLongitude] = useState(""); // 경도
  const [isSearch, setIsSearch] = useState(false); // 주소찾기로 주소 변경 시 true

  const [saveModal, setSaveModal] = useState(false);
  const [listModal, setListModal] = useState(false);
  const [addrModal, setAddrModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [textModal, setTextModal] = useState(false); // 모달 창 표시 여부 상태
  const [textMsg, setTextMsg] = useState("");

  // API
  const postFranchise = async () => {
    setLoading(true);

    await API.post("/admin/franchise", {
      franchise_name: values.franchise_name,
      business_num: values.business_num,
      franchise_address: addrValue,
      detail_address: values.detail_address,
      lat: latitude === "" ? null : latitude,
      lng: longitude === "" ? null : longitude,
      rental_information: values.rental_information,
      type_of_business: values.type_of_business,
      items_of_business: values.items_of_business,
      membership_fee: values.membership_fee,
      royalty: values.royalty,
      contract_performance_bond: values.contract_performance_bond,
      interior: values.interior,
      maintenance_cost: values.maintenance_cost,
      kiosk_buy: values.kiosk_buy,
      individual_product_available: values.individual_product_available,
      existing_stationery: values.existing_stationery,
      capstack_cost: values.capstack_cost,
      educational_cost: values.educational_cost,
      fi_logistics_setting_cost: values.fi_logistics_setting_cost,
      fi_product_cost: values.fi_product_cost,
      cash_receipts: values.cash_receipts,
      franchise_type: values.franchise_type,
      status: values.status, //슬롯 구분상태
    })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          navigate(-1, { replace: true });
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(function (error) {
        setLoading(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const changeType = (gubun, type) => {
    if (type === "kisok") {
      if (gubun === 0) {
        setValues({
          ...values,
          kiosk_buy: false,
        });
      } else {
        setValues({
          ...values,
          kiosk_buy: true,
        });
      }
    } else if (type === "iProduct") {
      if (gubun === 0) {
        setValues({
          ...values,
          individual_product_available: false,
        });
      } else {
        setValues({
          ...values,
          individual_product_available: true,
        });
      }
    } else if (type === "eStationery") {
      if (gubun === 0) {
        setValues({
          ...values,
          existing_stationery: false,
        });
      } else {
        setValues({
          ...values,
          existing_stationery: true,
        });
      }
    } else if (type === "cash_receipts") {
      if (gubun === 0) {
        setValues({
          ...values,
          cash_receipts: false,
        });
      } else {
        setValues({
          ...values,
          cash_receipts: true,
        });
      }
    } else if (type === "franchise_type") {
      if (gubun === 0) {
        setValues({
          ...values,
          franchise_type: "direct",
        });
      } else {
        setValues({
          ...values,
          franchise_type: "franchise",
        });
      }
    } else if (type === "status") {
      if (gubun === 0) {
        setValues({
          ...values,
          status: "active",
        });
      }
      if (gubun === 1) {
        setValues({
          ...values,
          status: "terminated",
        });
      }
      if (gubun === 2) {
        setValues({
          ...values,
          status: "service_only",
        });
      }
      if (gubun === 3) {
        setValues({
          ...values,
          status: "paused",
        });
      }
    }
  };

  const onAddrChange = (e) => {
    setAddrValue(e.target.value);
  };

  const handleChange = (e, isPriceValue) => {
    if (isPriceValue && e.target.value) {
      const price = e.target.value.replaceAll(",", "");
      setValues({ ...values, [e.target.name]: price });
      setPriceValues({ ...priceValues, [e.target.name]: AddComma(price) });
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
      setPriceValues({ ...priceValues, [e.target.name]: "" });
    }
  };

  const onSubmit = (e) => {
    if (e === 1) {
      setSaveModal(false);
      navigate(-1, { replace: true });
    } else {
      postFranchise();
      setSaveModal(false);
    }
  };

  // 가맹점 주소 -> 위경도 변환 로직
  useEffect(() => {
    // 가맹점 주소가 있는 경우에만 실행시키기
    if (addrValue !== "") {
      const script = document.createElement("script"); // 새로운 script 요소를 생성
      script.async = true; // script 요소를 비동기 방식으로 로드하도록 설정
      // script 요소의 src 속성을 설정하여 카카오 지도 SDK의 스크립트를 로드함
      // autoload=false는 스크립트를 로드한 후에 수동으로 카카오 지도를 초기화하는 것
      // libraries=services는 geocoder (카카오맵 자체) 라이브러리를 사용할 수 있게 설정하는 것
      script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${apiKey}&libraries=services&autoload=false`;
      document.head.appendChild(script); // 생성한 script 요소를 head 요소에 추가

      // script 요소가 로드되면 이벤트 리스너를 추가함
      script.addEventListener("load", () => {
        // window.kakao.maps.load() 함수를 호출
        window.kakao?.maps.load(() => {
          // 주소-좌표 변환 객체를 생성합니다
          var geocoder = new window.kakao.maps.services.Geocoder();
          // 주소로 좌표를 검색합니다s
          geocoder.addressSearch(addrValue, function (result, status) {
            // 정상적으로 검색이 완료됐으면
            if (status === window.kakao.maps.services.Status.OK) {
              // result[0] 의 x(coords의 La)에 경도, y(coords의 Ma)에 위도가 담김
              var coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);
              setLatitude(coords.Ma); // coords의 Ma라는 key의 value에 있는 위도값 담기
              setLongitude(coords.La); // coords의 La라는 key의 value에 있는 경도값 담기
            }
          });
        });
      });
    }
    setIsSearch(false);
  }, [addrValue, isSearch]);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {loading ? <Loading /> : null}
          <HeaderSub title={"가맹점 등록"} />
          <ContentContainer>
            <Wrapper>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    가맹점 등록 📋
                  </Title>
                )}
                <WhiteBox>
                  <Text align={"left"} color={"#1E1E1E"} weight={"700"} size={"16px"} sizeMd={"20px"}>
                    가맹 정보
                  </Text>
                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    margin={"20px 0 20px"}
                    marginMd={"20px 0 30px"}
                    wrap={"wrap"}
                    wrapMd={"unset"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        가맹점명
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"가맹점명을 입력해주세요."}
                        name="franchise_name"
                        value={values.franchise_name}
                        onChange={(e) => {
                          handleChange(e, false);
                        }}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        사업자 등록번호
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"사업자 등록번호를 입력해주세요."}
                        name="business_num"
                        value={values.business_num}
                        onChange={(e) => {
                          handleChange(e, false);
                        }}
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBox justify={"start"} align={"start"}>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        가맹점 주소
                      </Text>
                      <FlexBox margin={"0 0 5px"} marginMd={"0 0 10px"} gap={"10px"} onClick={() => setAddrModal(true)}>
                        <TextInput
                          style={{ width: "calc(100% - 95px)" }}
                          type={"text"}
                          placeholder={"주소를 입력해주세요."}
                          value={addrValue}
                          onChange={onAddrChange}
                          readOnly
                        />
                        <Btn
                          color={"#fff"}
                          background={"#FF4A4A"}
                          size={"14px"}
                          weight={"700"}
                          padding={"8px 18px"}
                          lineHeight={"22px"}
                          style={{ flexShrink: 0 }}
                        >
                          주소찾기
                        </Btn>
                      </FlexBox>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"상세주소를 입력해주세요."}
                        name="detail_address"
                        value={values.detail_address}
                        onChange={(e) => {
                          handleChange(e, false);
                        }}
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBox justify={"start"} align={"start"} margin={"20px 0 20px"} marginMd={"30px 0 30px"}>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        가맹점 임차정보
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"가맹점 임차정보를 입력해주세요."}
                        name="rental_information"
                        value={values.rental_information}
                        onChange={(e) => {
                          handleChange(e, false);
                        }}
                      />
                    </Contents>
                  </FlexBox>
                  <FlexBox justify={"start"} align={"start"} wrap={"wrap"} wrapMd={"unset"} gap={"20px"} gapMd={"26px"}>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        업태
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"업태를 입력해주세요."}
                        name="type_of_business"
                        value={values.type_of_business}
                        onChange={(e) => {
                          handleChange(e, false);
                        }}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        종목
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"종목을 입력해주세요."}
                        name="items_of_business"
                        value={values.items_of_business}
                        onChange={(e) => {
                          handleChange(e, false);
                        }}
                      />
                    </Contents>
                  </FlexBox>
                </WhiteBox>

                <WhiteBox>
                  <Text align={"left"} color={"#1E1E1E"} weight={"700"} size={"16px"} sizeMd={"20px"}>
                    가맹점 계약 정보
                  </Text>
                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    margin={"20px 0 20px"}
                    marginMd={"20px 0 30px"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        가맹비
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="membership_fee"
                          value={priceValues.membership_fee}
                          onChange={(e) => {
                            const input = e.target.value.replaceAll(",", "");
                            if (/^\d*$/.test(input)) {
                              handleChange(e, true);
                            }
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "13px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                    <Contents flex={1} ss>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        로열티
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="royalty"
                          value={priceValues.royalty}
                          onChange={(e) => {
                            const input = e.target.value.replaceAll(",", "");
                            if (/^\d*$/.test(input)) {
                              handleChange(e, true);
                            }
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "13px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                  </FlexBox>
                  <FlexBox justify={"start"} align={"start"}>
                    <Contents padding={"0 10px 0 0"} paddingMd={"0 20px 0 0"} flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        계약 이행 보증금
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="contract_performance_bond"
                          value={priceValues.contract_performance_bond}
                          onChange={(e) => {
                            const input = e.target.value.replaceAll(",", "");
                            if (/^\d*$/.test(input)) {
                              handleChange(e, true);
                            }
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "13px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        인테리어(평당)
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="interior"
                          value={priceValues.interior}
                          onChange={(e) => {
                            const input = e.target.value.replaceAll(",", "");
                            if (/^\d*$/.test(input)) {
                              handleChange(e, true);
                            }
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "13px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                  </FlexBox>
                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    margin={"20px 0 20px"}
                    marginMd={"30px 0 30px"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        키오스크 유지관리비
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="maintenance_cost"
                          value={priceValues.maintenance_cost}
                          onChange={(e) => {
                            const input = e.target.value.replaceAll(",", "");
                            if (/^\d*$/.test(input)) {
                              handleChange(e, true);
                            }
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "13px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        키오스크 구매 여부
                      </Text>
                      <FlexBox justify={"start"} gap={"10px"}>
                        <RadioLabel>
                          <input
                            type="radio"
                            value={values.kiosk_buy}
                            checked={values.kiosk_buy}
                            onChange={() => changeType(1, "kisok")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          구매
                        </RadioLabel>

                        <RadioLabel>
                          <input
                            type="radio"
                            value={!values.kiosk_buy}
                            checked={!values.kiosk_buy}
                            onChange={() => changeType(0, "kisok")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          미구매
                        </RadioLabel>
                      </FlexBox>
                    </Contents>
                  </FlexBox>

                  <FlexBox justify={"start"} align={"start"} gap={"20px"} gapMd={"26px"}>
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        개인 상품 가능 여부
                      </Text>
                      <FlexBox justify={"start"} gap={"10px"}>
                        <RadioLabel>
                          <input
                            type="radio"
                            value={values.individual_product_available}
                            checked={values.individual_product_available}
                            onChange={() => changeType(1, "iProduct")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          가능
                        </RadioLabel>

                        <RadioLabel>
                          <input
                            type="radio"
                            value={!values.individual_product_available}
                            checked={!values.individual_product_available}
                            onChange={() => changeType(0, "iProduct")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          불가능
                        </RadioLabel>
                      </FlexBox>
                    </Contents>
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        기존 문방구 여부
                      </Text>
                      <FlexBox justify={"start"} gap={"10px"}>
                        <RadioLabel>
                          <input
                            type="radio"
                            value={values.existing_stationery}
                            checked={values.existing_stationery}
                            onChange={() => changeType(1, "eStationery")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          Yes
                        </RadioLabel>

                        <RadioLabel>
                          <input
                            type="radio"
                            value={!values.existing_stationery}
                            checked={!values.existing_stationery}
                            onChange={() => changeType(0, "eStationery")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          No
                        </RadioLabel>
                      </FlexBox>
                    </Contents>
                  </FlexBox>
                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    margin={"20px 0 20px"}
                    marginMd={"30px 0 30px"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        캡스택 비용
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="capstack_cost"
                          value={priceValues.capstack_cost}
                          onChange={(e) => {
                            const input = e.target.value.replaceAll(",", "");
                            if (/^\d*$/.test(input)) {
                              handleChange(e, true);
                            }
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "13px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        교육비
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="educational_cost"
                          value={priceValues.educational_cost}
                          onChange={(e) => {
                            const input = e.target.value.replaceAll(",", "");
                            if (/^\d*$/.test(input)) {
                              handleChange(e, true);
                            }
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "13px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                  </FlexBox>
                  <FlexBox justify={"start"} align={"start"} gap={"20px"} gapMd={"26px"}>
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        초도 물류 세팅 비용
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="fi_logistics_setting_cost"
                          value={priceValues.fi_logistics_setting_cost}
                          onChange={(e) => {
                            const input = e.target.value.replaceAll(",", "");
                            if (/^\d*$/.test(input)) {
                              handleChange(e, true);
                            }
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "12px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        초도 상품 비용
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="fi_product_cost"
                          value={priceValues.fi_product_cost}
                          onChange={(e) => {
                            const input = e.target.value.replaceAll(",", "");
                            if (/^\d*$/.test(input)) {
                              handleChange(e, true);
                            }
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"#C7C7C7"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "12px",
                            top: "12px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                  </FlexBox>

                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    margin={"20px 0 20px"}
                    marginMd={"30px 0 30px"}
                    gap={"20px"}
                    gapMd={"26px"}
                  >
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        가맹 유형
                      </Text>
                      <FlexBox justify={"start"} gap={"10px"}>
                        <RadioLabel>
                          <input
                            type="radio"
                            name="direct"
                            value={values.franchise_type === "direct"}
                            checked={values.franchise_type === "direct"}
                            onChange={() => changeType(0, "franchise_type")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          직영점
                        </RadioLabel>

                        <RadioLabel>
                          <input
                            type="radio"
                            name="franchise"
                            value={values.franchise_type === "franchise"}
                            checked={values.franchise_type === "franchise"}
                            onChange={() => changeType(1, "franchise_type")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          가맹점
                        </RadioLabel>
                      </FlexBox>
                    </Contents>
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"700"}
                        color={"#1e1e1e"}
                        size={"14px"}
                        sizeMd={"16px"}
                        letterSpacing={"-0.32px"}
                        margin={"0 0 8px"}
                        marginMd={"0 0 10px"}
                      >
                        가맹점 상태
                      </Text>
                      <FlexBox justify={"start"} gap={"10px"} wrap="wrap">
                        <RadioLabel>
                          <input
                            type="radio"
                            value="active"
                            checked={values.status === "active"}
                            onChange={() => changeType(0, "status")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          정상
                        </RadioLabel>

                        <RadioLabel>
                          <input
                            type="radio"
                            value="terminated"
                            checked={values.status === "terminated"}
                            onChange={() => changeType(1, "status")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          해지
                        </RadioLabel>
                        <RadioLabel>
                          <input
                            type="radio"
                            value="service_only"
                            checked={values.status === "service_only"}
                            onChange={() => changeType(2, "status")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          별도 계약(서비스만 이용)
                        </RadioLabel>
                        <RadioLabel>
                          <input
                            type="radio"
                            value="paused"
                            checked={values.status === "paused"}
                            onChange={() => changeType(3, "status")}
                          />
                          <RadioIcon>
                            <svg viewBox="0 0 18 18">
                              <circle cx="9" cy="9" r="8.5" />
                              <circle cx="9" cy="9" r="5" />
                            </svg>
                          </RadioIcon>
                          일시정지
                        </RadioLabel>
                      </FlexBox>
                    </Contents>
                  </FlexBox>
                </WhiteBox>
                <FlexBox justify={"end"}>
                  <FlexBox flex={"1 0 auto"} flexMd={"unset"} gap={"8px"} gapMd={"10px"}>
                    <Btn
                      width={"100%"}
                      widthMd={"90px"}
                      size={"14px"}
                      color={"#666"}
                      border={"1px solid #666"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      hoverWeight={"700"}
                      hoverColor={"#fff"}
                      hoverBackground={"#C7C7C7"}
                      hoverBorder={"1px solid transparent"}
                      onClick={() => setListModal(true)}
                    >
                      목록
                    </Btn>
                    <Btn
                      width={"100%"}
                      widthMd={"90px"}
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      onClick={() => setSaveModal(true)}
                    >
                      저장
                    </Btn>
                  </FlexBox>
                </FlexBox>
              </Container>
              {addrModal ? (
                <SearchAddress setModal={setAddrModal} setValue={setAddrValue} setIsSearch={setIsSearch} />
              ) : (
                ""
              )}
              <ConfirmModal
                modal={listModal}
                setModal={setListModal}
                onSubmit={() => onSubmit(1)}
                description={"가맹점 등록을 취소하고\n목록화면으로 이동하시겠습니까?"}
              />
              <ConfirmModal
                modal={saveModal}
                setModal={setSaveModal}
                onSubmit={() => onSubmit(2)}
                description={"가맹점 등록을 완료하시겠습니까?"}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const Contents = styled.div`
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  background-color: ${(props) => props.background || ""};
  width: ${(props) => props.width || "100%"};
  border-radius: ${(props) => props.radius || "8px"};
  flex-wrap: ${(props) => props.wrap ?? props.wrap};
  flex: ${(props) => props.flex ?? props.flex};
  @media only screen and (min-width: 1024px) {
    flex-direction: ${(props) => props.directionMd ?? props.directionMd};
    flex: ${(props) => props.flexMd ?? props.flexMd};
    width: ${(props) => props.widthMd ?? props.widthMd};
    margin: ${(props) => props.marginMd ?? props.marginMd};
    padding: ${(props) => props.paddingMd ?? props.paddingMd};
    flex-wrap: ${(props) => props.wrapMd ?? props.wrapMd};
  }
`;

export default FranchiseeWrite;
