import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import {
  ContentContainer,
  Wrapper,
  Container,
  WhiteBox,
  FlexBox,
  Title,
  Text,
  Btn,
  TableWrap,
  Th,
  Td,
  CheckLabel,
  CheckIcon,
} from "../../Assets/css/commonVer2";
import Header from "../../Components/Header/HeaderVer2";
import Authority from "../../Components/Modal/Authority";
import InfoModal from "../../Components/Modal/InfoModal";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import BasicSelect from "../../Components/Select/BasicSelect";
import ProductSearchInputVer2 from "../../Components/SearchInput/ProductSearchInputVer2";
import Pagination from "../../Components/Paging/Pagination";
import HandleBack from "../../Components/HandleBack";
import { AddComma } from "../../Utiles/Custom";
import { UNIT_10_TO_50_LIST } from "../../Utiles/ListData";
import API from "../../Utiles/API";

// 쇼핑몰 > 프로모션 리스트

function ShopPromotion() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const { handlePopstate } = HandleBack();

  // 모달
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  // 상품 등록, 상품 수정 다녀왔을 때 state
  const parsedShopPromotionPageState = sessionStorage.getItem("shopPromotionPageState")
    ? JSON.parse(sessionStorage.getItem("shopPromotionPageState"))
    : {};

  // 페이징
  const [pagination, setPagination] = useState("");
  // 체크한 상품들
  const [checkItems, setCheckItems] = useState([]);

  // 프로모션 리스트 데이터
  const [shopPromotionCnt, setPromotionCnt] = useState(0);
  const [promotionList, setPromotionList] = useState([]);

  // 본사상품 검색 데이터
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchList, setShowSearchList] = useState(false);

  const [pageSize, setPageSize] = useState(
    parsedShopPromotionPageState
      ? parsedShopPromotionPageState?.pageSize
        ? parsedShopPromotionPageState?.pageSize
        : 10
      : 10,
  );
  const [currentPage, setCurrentPage] = useState(
    parsedShopPromotionPageState
      ? parsedShopPromotionPageState?.currentPage
        ? parsedShopPromotionPageState?.currentPage
        : 1
      : 1,
  );
  const [inputValue, setInputValue] = useState(
    parsedShopPromotionPageState
      ? parsedShopPromotionPageState?.inputValue
        ? parsedShopPromotionPageState?.inputValue
        : ""
      : "",
  );

  // 프로모션 등록, 프로모션 수정 갈 때 sessionStorage에 저장할 객체
  let shopPromotionPageState = {
    pageSize,
    currentPage,
    inputValue,
  };

  // API
  // 1. 프로모션 상품 조회
  const getPromotionList = async () => {
    let api_link = `/shoppingmall/promotion/product-list?page=${currentPage}&unit=${pageSize}`;
    if (inputValue) api_link += `&filter_value=${inputValue}`;

    await API.get(api_link).then((res) => {
      if (res.data.status) {
        setPromotionCnt(res.data.cntAll);
        setPagination(res.data.pagination);
        setPromotionList(res.data.results);
      }
    });
  };

  // 2. 프로모션 상품 삭제
  const deletePromotionProduct = async () => {
    await API.delete(`/shoppingmall/product?shoppingmall_product_ids=${checkItems}`).then((res) => {
      if (res.data.status) {
        setDeleteModal(false);
        window.location.replace("/shoppingmall/promotion"); // 새로고침
      } else {
        setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    });
  };

  // 아이템 수정 클릭
  const onClickEditPromotion = () => {
    if (checkItems.length === 0) {
      setTextMsg("수정할 프로모션 상품을 선택해주세요.");
      setTextModal(true);
      return;
    }
    if (checkItems.length > 1) {
      setTextMsg("하나만 선택해주세요.");
      setTextModal(true);
      return;
    }
    if (checkItems.length === 1) {
      const foundItem = promotionList.find((item) => item.idx === checkItems[0]);
      navigate("/shoppingmall/promotion/edit", {
        state: { idx: foundItem.idx },
      });
      let jsonString = JSON.stringify(shopPromotionPageState);
      sessionStorage.setItem("shopPromotionPageState", jsonString);
    }
  };

  // 아이템 삭제 클릭
  const onClickDeletePromotion = () => {
    if (checkItems.length > 0) {
      setDeleteModal(true);
    } else {
      setTextMsg("삭제할 프로모션 상품을 선택해주세요.");
      setTextModal(true);
    }
  };

  // 검색 함수
  const onSearch = () => {
    setSearchResults([]);
    setShowSearchList(false);
    setCurrentPage(1);
    getPromotionList();
  };

  // 체크박스 개별 체크
  const onChangeChx = (checked, id) => {
    if (checked) {
      setCheckItems((prev) => [...prev, id]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  // 체크박스 전체 체크
  const onChangeAllChk = (checked) => {
    if (checked) {
      const chkArr = promotionList.map((item) => item.idx);
      setCheckItems(chkArr);
    } else {
      setCheckItems([]);
    }
  };

  const movePage = (e) => {
    setCurrentPage(e);
    setCheckItems([]);
  };

  useEffect(() => {
    getPromotionList();
  }, [currentPage, pageSize]);

  useEffect(() => {
    sessionStorage.removeItem("shopPromotionPageState");

    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {isMobile && <Header title={"프로모션 리스트"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    프로모션 리스트 🎁
                  </Title>
                )}
                <WhiteBox margin={"0 0"}>
                  <InputAndBtnBox>
                    <ProductSearchInputVer2
                      showSearchList={showSearchList}
                      setShowSearchList={setShowSearchList}
                      searchResults={searchResults}
                      setSearchResults={setSearchResults}
                      inputValue={inputValue}
                      setInputValue={setInputValue}
                      onSearch={onSearch}
                      placeholder={"프로모션명을 검색해보세요."}
                    />
                    <Btn
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      width={"100%"}
                      widthMd={"unset"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      onClick={onSearch}
                      style={{ flexShrink: 0 }}
                    >
                      검 색
                    </Btn>
                  </InputAndBtnBox>
                  <FlexBox
                    justify={"space-between"}
                    gap={"10px"}
                    margin={"20px 0 10px"}
                    marginMd={"20px 0 30px"}
                    wrap={"wrap"}
                    wrapMd={"no-wrap"}
                  >
                    <FlexBox gap={"10px"} width={"100%"} widthMd={"auto"}>
                      <Text
                        width={"100%"}
                        widthMd={"unset"}
                        color={"#FF4A4A"}
                        size={"16px"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        style={{
                          flexShrink: 0,
                        }}
                      >
                        총 프로모션 수 :{" "}
                        <span style={{ fontWeight: "700" }}>{AddComma(shopPromotionCnt ? shopPromotionCnt : 0)}개</span>
                      </Text>
                      {!isMobile && (
                        <BasicSelect
                          width={"130px"}
                          data={UNIT_10_TO_50_LIST}
                          selectedValue={pageSize}
                          setSelectedValue={setPageSize}
                          canSelectAll={false}
                          setCurrentPage={setCurrentPage}
                        />
                      )}
                    </FlexBox>
                    <FlexBox gap={"10px"} justify={"flex-end"} width={"100%"} widthMd={"auto"}>
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        onClick={() => {
                          navigate("/shoppingmall/promotion/write");
                          let jsonString = JSON.stringify(shopPromotionPageState);
                          sessionStorage.setItem("shopPromotionPageState", jsonString);
                        }}
                      >
                        등록
                      </Btn>
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        onClick={onClickEditPromotion}
                      >
                        수정
                      </Btn>
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        onClick={onClickDeletePromotion}
                      >
                        삭제
                      </Btn>
                    </FlexBox>
                  </FlexBox>
                  <TableWrap>
                    <table style={{ width: "100%", minWidth: "1024px" }}>
                      <colgroup>
                        <col style={{ width: "5.5%" }} />
                        <col />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "20%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>
                            <CheckLabel htmlFor="chkAll" style={{ justifyContent: "center" }}>
                              <input
                                type="checkbox"
                                id="chkAll"
                                onChange={(event) => onChangeAllChk(event.target.checked)}
                                checked={checkItems.length === promotionList.length ? true : false}
                              />
                              <CheckIcon>
                                <svg viewBox="0 0 24 24">
                                  <polyline points="19 7 10 17 5 12" />
                                </svg>
                              </CheckIcon>
                            </CheckLabel>
                          </Th>
                          <Th>프로모션명</Th>
                          <Th>매입가</Th>
                          <Th>소비자가</Th>
                          <Th>최종소비자가</Th>
                          <Th>등록일시</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {promotionList.length > 0 ? (
                          promotionList.map((item, key) => (
                            <tr
                              style={{ cursor: "pointer" }}
                              key={item.idx}
                              onClick={(event) => {
                                const targetElement = event.target;
                                if (targetElement.nodeName !== "INPUT") {
                                  const isChecked = document.getElementById(`prod${item.idx}`).checked;
                                  onChangeChx(!isChecked, item.idx);
                                }
                              }}
                            >
                              <Td align={"center"}>
                                <CheckLabel htmlFor={`prod${item.idx}`} style={{ justifyContent: "center" }}>
                                  <input
                                    type="checkbox"
                                    name="prod"
                                    id={`prod${item.idx}`}
                                    readOnly
                                    checked={checkItems.includes(item.idx) ? true : false}
                                  />
                                  <CheckIcon>
                                    <svg viewBox="0 0 24 24">
                                      <polyline points="19 7 10 17 5 12" />
                                    </svg>
                                  </CheckIcon>
                                </CheckLabel>
                              </Td>
                              <Td>{item ? item.product_name : "-"}</Td>
                              <Td>{item ? AddComma(item.purchase_price) : 0}원</Td>
                              <Td style={{ textDecoration: "line-through" }}>
                                {item ? AddComma(item.consumer_price) : 0}원
                              </Td>
                              <Td weight={700}>{item ? AddComma(item.discount_price) : 0}원</Td>
                              <Td>{item ? item.created_at : "-"}</Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={5}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  {pagination?.total_page > 0 ? (
                    <Pagination pagination={pagination} movePage={movePage} currentPage={currentPage} />
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <ConfirmModal
                modal={deleteModal}
                setModal={setDeleteModal}
                onSubmit={deletePromotionProduct}
                description={"선택된 프로모션 상품을 삭제하시겠습니까?"}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

export default ShopPromotion;

const InputAndBtnBox = styled.div`
  display: flex;
  gap: 10px;
  justify-content: end;

  @media all and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
  }
`;
