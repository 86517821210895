import React, { useState } from "react";
import styled from "styled-components";

import { Line, FlexBox, Btn, Text } from "../../../Assets/css/commonVer2";
import close from "../../../Assets/images/close.png";
import ConfirmModal from "../../../Components/Modal/ConfirmModalVer2";
import { AddComma } from "../../../Utiles/Custom";

// 쇼핑몰 > 반품관리 > 입금처리

export default function ShopDepositModal(props) {
  const { setModal, onSubmit = () => {}, depositInfoData } = props;

  const [saveModal, setSaveModal] = useState(false);

  const onClose = () => {
    setModal(false);
  };

  return (
    <>
      {saveModal ? (
        <ConfirmModal
          modal={saveModal}
          setModal={setSaveModal}
          title={"입금처리를 완료하시겠습니까?"}
          description={"상품은 즉시 반품되며\n취소할 수 없습니다."}
          onSubmit={onSubmit}
        />
      ) : (
        <ModalBg>
          <ModalConts>
            <CancelBtn onClick={onClose}>
              <img src={close} />
            </CancelBtn>
            <Text
              color={"#2D2D2D"}
              size={"20px"}
              weight={"700"}
              lineHeight={"22px"}
              style={{
                whiteSpace: "pre-wrap",
                marginBottom: "16px",
              }}
            >
              현금 환불 안내
            </Text>
            <DataBox>
              <FlexBox justify={"space-between"}>
                <Text color={"#666"} size={"12px"} weight={"400"} lineHeight={"18px"}>
                  은행명
                </Text>
                <DataText>{depositInfoData?.bank}</DataText>
              </FlexBox>
              <FlexBox justify={"space-between"}>
                <Text color={"#666"} size={"12px"} weight={"400"} lineHeight={"18px"}>
                  예금주
                </Text>
                <DataText>{depositInfoData?.account_holder}</DataText>
              </FlexBox>
              <FlexBox justify={"space-between"}>
                <Text color={"#666"} size={"12px"} weight={"400"} lineHeight={"18px"}>
                  계좌번호
                </Text>
                <DataText>{depositInfoData?.account_number}</DataText>
              </FlexBox>
              <Line />
              <FlexBox align={"flex-end"} justify={"space-between"}>
                <Text color={"#FF4A4A"} size={"12px"} weight={"400"} lineHeight={"18px"}>
                  현금
                </Text>
                <ToTalText>
                  <span>{AddComma(depositInfoData?.cash)}원</span>
                </ToTalText>
              </FlexBox>
            </DataBox>
            <FlexBox margin={"20px 0 0"} marginMd={"15px 0 0"}>
              <Btn
                background={"#f2f2f2"}
                border={"unset"}
                color={"#666"}
                radius={"6px"}
                weight={"500"}
                margin={"0 10px 0 0"}
                lineHeight={"22px"}
                size={"12px"}
                sizeMd={"14px"}
                padding={"9px 19px"}
                paddingMd={"9px 19px"}
                onClick={onClose}
              >
                취소
              </Btn>
              <Btn
                background={"#FF4A4A"}
                color={"#fff"}
                radius={"6px"}
                weight={"700"}
                lineHeight={"22px"}
                size={"12px"}
                sizeMd={"14px"}
                padding={"9px 19px"}
                paddingMd={"9px 19px"}
                onClick={() => setSaveModal(true)}
              >
                확 인
              </Btn>
            </FlexBox>
          </ModalConts>
        </ModalBg>
      )}
    </>
  );
}

const ModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

const ModalConts = styled.div`
  background-color: #fff;
  max-width: 360px;
  max-height: 600px;
  width: 100%;
  padding: 44px 30px 26px;
  border-radius: 8px;
  position: relative;

  @media all and (max-width: 1024px) {
    max-width: 280px; // 390
    padding: 40px 20px 30px;
    margin: 0 20px;
  }
`;

const DataBox = styled.div`
  background-color: #f2f2f2;
  border-radius: 6px;
  padding: 16px 16px 20px;
  font-size: 12px;
  line-height: 18px;

  div + div {
    margin-top: 10px;
  }
`;

const DataText = styled(Text)`
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;
const ToTalText = styled(Text)`
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 18px;
  color: #ff4a4a;
  span {
    font-size: 14px;
  }
`;

const CancelBtn = styled.button`
  position: absolute;
  right: 17.5px;
  top: 17.5px;

  @media all and (max-width: 1024px) {
    top: 12px;
    right: 12px;
  }
`;
