import React, { useEffect, useState, useRef } from "react";

import { SelectBtn, SearchList, SelectWrap, SelectContainer } from "../../../Assets/css/commonVer2";

// 쇼핑몰 > 반품관리 > 반품 상태 셀렉박스

export default function ShopTakeBackStatusSelect(props) {
  let {
    selectedText, // 반품상태값
    setSelectedText, // 반품상태 변경 함수
    setSearchReview, // 반품 상태 돌려 받을 state
    width,
    widthMd,
  } = props;

  const [show, setShow] = useState(false); // 목록 visible
  const selectBoxRef = useRef(); // 외부 클릭 컨트롤

  const [data, setData] = useState([
    { value: "all", text: "상태 전체" },
    { value: "check_request", text: "검수요청" },
    { value: "check_completed", text: "검수완료" },
    { value: "deposit_wait", text: "입금대기" },
    { value: "deposit_completed", text: "입금완료" },
    { value: "take_back_cancel", text: "반품취소" },
  ]);

  // 목록에서 하나 클릭 시
  const itemClick = (item) => {
    setShow(false);

    if (item.text === "상태 전체") {
      // 상태 전체로 검색 시 searchReview 초기화
      setSearchReview("");
    } else {
      setSearchReview(item.value);
    }
    setSelectedText(item.text);
  }

  // 외부 클릭 컨트롤
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (selectBoxRef.current && !selectBoxRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <SelectContainer width={width} widthMd={widthMd} ref={selectBoxRef}>
        <SelectBtn
          width={"100%"}
          show={show}
          onClick={() => {
            setShow((state) => !state);
          }}
        >
          {selectedText}
        </SelectBtn>
        {show && (
          <SelectWrap width={"100%"}>
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              {data?.map((item, idx) => (
                <SearchList key={idx} onClick={() => itemClick(item)}>
                  <li>
                    <span>{item.text}</span>
                  </li>
                </SearchList>
              ))}
            </div>
          </SelectWrap>
        )}
      </SelectContainer>
    </>
  );
}