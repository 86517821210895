import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import DatePicker from "react-datepicker";
import Authority from "../../Components/Modal/Authority";
import {
  HeaderContainer,
  ContentContainer,
  Btn,
  Container,
  DateInputBox,
  FlexBox,
  TableWrap,
  Td,
  Text,
  Th,
  Wrapper,
  Title,
  WhiteBox,
  SearchInput,
} from "../../Assets/css/commonVer2";
import API from "../../Utiles/API";
import { TAKEBACK_STATUS_LIST, UNIT_10_TO_30_LIST } from "../../Utiles/ListData";
import BasicSelect from "../../Components/Select/BasicSelect";
import { Xlsx_download, AddComma, handleKeyDown, dateToString, str } from "../../Utiles/Custom";
import Header from "../../Components/Header/HeaderVer2";
import HandleBack from "../../Components/HandleBack";
import Pagination from "../../Components/Paging/Pagination";
import InfoModal from "../../Components/Modal/InfoModal";

//  상품관리 > 반품관리

export default function GoodsTakeBack() {
  const navigate = useNavigate();
  const [franchise_id] = useState(sessionStorage.getItem("franchise_id"));
  const { handlePopstate } = HandleBack();
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  // 데이터
  const [takeBackList, setTakeBackList] = useState([]);
  const [pagination, setPagination] = useState("");

  // 날짜 제어 state
  const date = new Date();
  const year = date.getFullYear(); // 년
  const month = date.getMonth(); // 월
  const day = date.getDate(); // 일
  const [startDate, setStartDate] = useState(new Date(year, month, day - 7));
  const [endDate, setEndDate] = useState(new Date());

  // 모달들
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");

  const parsedGoodsTakeBackPageState = sessionStorage.getItem("goodsTakeBackPageState")
    ? JSON.parse(sessionStorage.getItem("goodsTakeBackPageState"))
    : {};

  const [currentPage, setCurrentPage] = useState(
    parsedGoodsTakeBackPageState
      ? parsedGoodsTakeBackPageState?.currentPage
        ? parsedGoodsTakeBackPageState?.currentPage
        : 1
      : 1,
  );

  const [pageSize, setPageSize] = useState(
    parsedGoodsTakeBackPageState
      ? parsedGoodsTakeBackPageState?.pageSize
        ? parsedGoodsTakeBackPageState?.pageSize
        : 10
      : 10,
  );

  const [inputValue, setInputValue] = useState(
    parsedGoodsTakeBackPageState
      ? parsedGoodsTakeBackPageState?.inputValue
        ? parsedGoodsTakeBackPageState?.inputValue
        : ""
      : "",
  );

  const start = (currentPage - 1) * pageSize;

  // 반품 상세를 갈 때 sessionStorage에 저장할 객체
  let goodsTakeBackPageState = {
    currentPage,
    pageSize,
    inputValue,
  };

  const getTakeBackList = async () => {
    try {
      const res = await API.get(
        `/storeowner/take-back/?franchise_id=${franchise_id}&start_date=${dateToString(
          startDate,
        )} 00:00:00&end_date=${dateToString(endDate)} 23:59:59&page=${currentPage}&unit=${pageSize}&block=10
        ${inputValue !== "" ? `&take_back_id=${inputValue}` : ""}`,
      );
      if (res.data.status) {
        setTakeBackList(res.data.results);
        setPagination(res.data.pagination);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSearch = async () => {
    setCurrentPage(1);
    getTakeBackList();
  };

  function onClickItem(take_back_id) {
    navigate(`/goods/takeback/` + take_back_id);
    let jsonString = JSON.stringify(goodsTakeBackPageState);
    sessionStorage.setItem("goodsTakeBackPageState", jsonString);
  }

  const movePage = (e) => {
    setCurrentPage(e);
  };

  const handleExcelDown = () => {
    if (takeBackList.length > 0) {
      API.get(
        `/storeowner/take-back/?franchise_id=${franchise_id}&start_date=${dateToString(
          startDate,
        )} 00:00:00&end_date=${dateToString(endDate)} 23:59:59&page=${currentPage}&unit=${pageSize}&block=10
        ${inputValue !== "" ? `&take_back_id=${inputValue}` : ""}`,
      )
        .then(function (res) {
          if (res.data.status && res.data.results.length > 0) {
            var xlsxTemp = res.data.results.map(function (item) {
              const status = item.status === "review" ? "검수요청" : "검수완료";
              return {
                No: item?.row_num,
                반품번호: item?.take_back_id,
                품목수량: item?.take_back_count,
                총금액: item?.amount,
                반품신청일: str(item?.createdAt),
                상태: status,
              };
            });
            Xlsx_download(xlsxTemp, `반품관리_${dateToString(startDate)}~${dateToString(endDate)}`);
          }
        })
        .catch(function (error) {
          console.log("3", error);
          setTextMsg("E01\n잠시후 다시 시도해주세요.");
          setTextModal(true);
        });
    } else {
      setTextMsg("다운받을 데이터가 없습니다.");
      setTextModal(true);
    }
  };

  // useEffect(() => {
  //   getTakeBackList();
  //   setCheckItems([]);
  // }, [currentPage]);

  // useEffect(() => {
  //   if (!parsedGoodsTakeBackPageState?.currentPage && currentPage !== 1) {
  //     // 등록이나 수정 다녀왔을 때 sessionStorage가 초기화 되고 currentPage가 1이 아니었을 때
  //     // 평소 페이징에서 평소 currentPage가 1이 아니었을 때
  //     setCurrentPage(1);
  //   } else {
  //     // 등록이나 수정 다녀왔을 때 sessionStorage가 초기화 되기 전 실행
  //     // currentPage 1에서 유닛을 바꿀 때
  //     getTakeBackList();
  //   }
  // }, [pageSize, currentPage]);

  useEffect(() => {
    getTakeBackList();
  }, [currentPage, pageSize]);

  useEffect(() => {
    // 현재 페이지에 도착하면 무조건 한 번 초기화
    sessionStorage.removeItem("goodsTakeBackPageState");
    // 페이징 후 # 붙었을때 뒤로가기 처리
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          {isMobile && <Header title={"반품 관리"} />}
          <ContentContainer>
            <Wrapper type={2} pbM={"112px"}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    반품관리
                  </Title>
                )}
                {isMobile && (
                  <FlexBox direction={"column"} gap={"8px"}>
                    <FlexBox gap={"10px"} width="100%" widthMd="auto">
                      <DateInputBox style={{ width: "100%" }}>
                        <DatePicker
                          dateFormatCalendar="yyyy년 MM월"
                          dateFormat="yyyy-MM-dd"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={endDate}
                        />
                      </DateInputBox>
                      <DateInputBox style={{ width: "100%" }}>
                        <DatePicker
                          dateFormatCalendar="yyyy년 MM월"
                          dateFormat="yyyy-MM-dd"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </DateInputBox>
                    </FlexBox>
                    <SearchInput
                      borderBottom={"1px solid #666666"}
                      borderBottomColor={"#ff4a4a"}
                      transition={"border-color 0.3s ease-in-out"}
                      flex={1}
                      placeholder="반품번호를 입력해주세요."
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      onKeyDown={(event) => handleKeyDown(event, onSearch)}
                    />
                    <Btn
                      width={"100%"}
                      color={"#fff"}
                      background={"#FF4A4A"}
                      radius={"6px"}
                      size={"14px"}
                      weight={"700"}
                      lineHeight={"22px"}
                      padding={"9px 19px"}
                      onClick={onSearch}
                    >
                      검 색
                    </Btn>
                  </FlexBox>
                )}
                <WhiteBox>
                  {!isMobile && (
                    <FlexBox justify={"end"} gap={"10px"} wrap={"wrap"}>
                      <FlexBox />
                      <FlexBox gap={"10px"} width="100%" widthMd="auto">
                        <DateInputBox>
                          <DatePicker
                            dateFormatCalendar="yyyy년 MM월"
                            dateFormat="yyyy-MM-dd"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={endDate}
                          />
                        </DateInputBox>
                        <DateInputBox>
                          <DatePicker
                            dateFormatCalendar="yyyy년 MM월"
                            dateFormat="yyyy-MM-dd"
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                          />
                        </DateInputBox>
                      </FlexBox>

                      <FlexBox justify={"end"} gap={"10px"} width="100%" widthMd="auto">
                        <SearchInput
                          borderBottom={"1px solid #666666"}
                          borderBottomColor={"#ff4a4a"}
                          transition={"border-color 0.3s ease-in-out"}
                          flex={1}
                          placeholder="반품번호를 입력해주세요."
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                          onKeyDown={(event) => handleKeyDown(event, onSearch)}
                        />

                        <Btn
                          color={"#fff"}
                          background={"#FF4A4A"}
                          radius={"6px"}
                          size={"14px"}
                          weight={"700"}
                          lineHeight={"22px"}
                          padding={"9px 19px"}
                          onClick={onSearch}
                        >
                          검 색
                        </Btn>
                      </FlexBox>
                    </FlexBox>
                  )}

                  <FlexBox justify={"space-between"} margin={"0 0"} marginMd={"20px 0"} wrap={"wrap"} gap={"10px"}>
                    <FlexBox gap={"10px"}>
                      <Text
                        width={"100%"}
                        widthMd={"unset"}
                        color={"#FF4A4A"}
                        size={"16px"}
                        weight={"400"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        lineHeight={"normal"}
                      >
                        금액 합계 :{" "}
                        <span style={{ fontWeight: "700" }}>
                          {AddComma(takeBackList.reduce((sum, item) => sum + parseInt(item.amount), 0))}원
                        </span>
                      </Text>
                      {!isMobile && (
                        <BasicSelect
                          data={UNIT_10_TO_30_LIST}
                          selectedValue={pageSize}
                          setSelectedValue={setPageSize}
                          canSelectAll={false}
                          setCurrentPage={setCurrentPage}
                        />
                      )}
                    </FlexBox>
                    <FlexBox gap={"10px"}>
                      <Btn
                        border={"1px solid #FF4A4A"}
                        color={"#FF4A4A"}
                        hoverColor={"#FFFFFF"}
                        hoverBackground={"#FF4A4A"}
                        hoverWeight={"700"}
                        padding={"9px 19px"}
                        size={"14px"}
                        radius={"6px"}
                        weight={"400"}
                        lineHeight={"22px"}
                        onClick={() => {
                          navigate(`/goods/takeback/add`);
                        }}
                      >
                        반품 등록
                      </Btn>
                      {!isMobile && (
                        <Btn
                          border={"1px solid #666666"}
                          hoverBorder={"1px solid #C7C7C7"}
                          hoverBackground={"#C7C7C7"}
                          color={"#666666"}
                          hoverColor={"#FFFFFF"}
                          weight={"500"}
                          hoverWeight={"700"}
                          padding={"9px 19px"}
                          size={"14px"}
                          radius={"6px"}
                          lineHeight={"22px"}
                          onClick={handleExcelDown}
                        >
                          엑셀 다운
                        </Btn>
                      )}
                    </FlexBox>
                  </FlexBox>

                  <TableWrap>
                    <table style={{ width: "100%" }}>
                      <colgroup>
                        <col />
                        <col style={{ width: "18.5%" }} />
                        <col style={{ width: "18%" }} />
                        <col style={{ width: "18%" }} />
                        <col style={{ width: "22%" }} />
                        <col style={{ width: "16%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>No</Th>
                          <Th>반품번호</Th>
                          <Th>품목 수량</Th>
                          <Th>총 금액</Th>
                          <Th>반품신청일</Th>
                          <Th>상 태</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {takeBackList?.length > 0 ? (
                          takeBackList?.map((item, key) => (
                            <tr key={key} onClick={() => onClickItem(item.take_back_id)}>
                              <Td>{key + 1}</Td>
                              <Td>{item.take_back_id}</Td>
                              <Td>{item.take_back_count}</Td>
                              <Td>{AddComma(item.amount)}원</Td>
                              <Td>{item.createdAt}</Td>
                              <Td>
                                {item.status
                                  ? TAKEBACK_STATUS_LIST.find((i) => i.value === item.status)?.text || "-"
                                  : "-"}
                              </Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={6}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  {pagination?.total_page > 0 ? (
                    <Pagination pagination={pagination} movePage={movePage} currentPage={currentPage} />
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}
