import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Components/Header/HeaderVer2";
import Home from "./Pages/Home/index";
import Login from "./Pages/Login/LoginVer2";
import Sales from "./Pages/Sales/Sales";
import SalesView from "./Pages/Sales/SalesView";
import ReportSales from "./Pages/Sales/ReportSales";
import SalesItem from "./Pages/Sales/SalesItem";
import SalesItemView from "./Pages/Sales/SalesItemView";
import SalesPayment from "./Pages/Sales/SalesPayment";
import SalesCalendar from "./Pages/Sales/SalesCalendar";
import Franchisee from "./Pages/Franchisee/FranchiseeVer2";
import FranchiseeManagement from "./Pages/Franchisee/FranchiseeManagementVer2";
import FranchiseeUser from "./Pages/Franchisee/FranchiseeUserVer2";
import FranchiseeDeposit from "./Pages/Franchisee/FranchiseeDepositVer2";
import FranchiseeWrite from "./Pages/Franchisee/FranchiseeWriteVer2";
import FranchiseeEdit from "./Pages/Franchisee/FranchiseeEditVer2";
import FranchiseeUserWrite from "./Pages/Franchisee/FranchiseeUserWriteVer2";
import Goods from "./Pages/Goods/Goods";
import GoodsView from "./Pages/Goods/GoodsView";
import Deposit from "./Pages/Goods/Deposit";
import DepositView from "./Pages/Goods/DepositView";
import Shopping from "./Pages/Goods/Shopping";
import OrderHistory from "./Pages/Goods/OrderHistory";
import OrderView from "./Pages/Goods/OrderView";
import GoodsOrder from "./Pages/Goods/GoodsOrder";
import OrderComplete from "./Pages/Goods/OrderComplete";
import Mypage from "./Pages/Mypage/Mypage";
import Alarm from "./Pages/Mypage/Alarm";
import MypageEdit from "./Pages/Mypage/MypageEdit";
import Notice from "./Pages/Notice/Notice";
import NoticeView from "./Pages/Notice/NoticeView";
import NoticeWrite from "./Pages/Notice/NoticeWrite";
import NoticeEdit from "./Pages/Notice/NoticeEdit";
import ProductCategory from "./Pages/Product/ProductCategory";
import ProductAll from "./Pages/Product/ProductAllVer2";
import ProductWrite from "./Pages/Product/ProductWriteVer2";
import ProductBulkWrite from "./Pages/Product/ProductBulkWriteVer2";
import ProductEdit from "./Pages/Product/ProductEditVer2";
import TakeBack from "./Pages/Product/TakeBackVer2";
import AddTakeBack from "./Pages/Goods/AddTakeBack";
import DetailTakeBack from "./Pages/Product/DetailTakeBack";
import Inventory from "./Pages/Product/InventoryVer2";
import InventoryStoreOwner from "./Pages/Product/InventoryStoreOwner";
import ProductOrder from "./Pages/Product/OrderVer2";
import ProductOrderView from "./Pages/Product/ProductOrderViewVer2";
import OrderManagement from "./Pages/Product/OrderManagementVer2";
import OrderManagementView from "./Pages/Product/OrderManagementViewVer2";
import DashBoard from "./Pages/Product/DashBoardVer2";
import FranchiseRevenue from "./Pages/Franchisee/FranchiseRevenueVer2";
import FranchiseeKiosk from "./Pages/Franchisee/FranchiseeKioskVer2";
import FranchiseeKioskWrite from "./Pages/Franchisee/FranchiseeKioskWriteVer2";
import FranchiseeUserEdit from "./Pages/Franchisee/FranchiseeUserEditVer2";
import SalesItemRank from "./Pages/Sales/SalesItemRank";
import FranchiseeInquiry from "./Pages/Franchisee/FranchiseeInquiryVer2";
import ProductRestock from "./Pages/Product/ProductRestockVer2";
import RestockStoreOwner from "./Pages/Mypage/RestockStoreOwner";
import InventoryView from "./Pages/Product/InventoryView";
import InventoryBulkWrite from "./Pages/Product/InventoryBulkWriteVer2";
import ProductBundle from "./Pages/Product/BundleVer2";
import BundleWrite from "./Pages/Product/BundleWriteVer2";
import BundleEdit from "./Pages/Product/BundleEditVer2";
import SelfGoods from "./Pages/Goods/SelfGoods";
import BuySlot from "./Pages/Goods/BuySlot";
import SelfGoodsWrite from "./Pages/Goods/SelfGoodsWrite";
import SelfGoodsEdit from "./Pages/Goods/SelfGoodsEdit";
import SlotHistory from "./Pages/Goods/SlotHistory";
import SlotHistoryView from "./Pages/Goods/SlotHistoryView";
import OwnPurchaseProduct from "./Pages/OwnPurchase/Product";
import OwnPurchaseInspection from "./Pages/OwnPurchase/Inspection";
import GoodsTakeBack from "./Pages/Goods/GoodsTakeBack";
import { HeaderContainer } from "./Assets/css/commonVer2";
import ShopSales from "./Pages/ShoppingMall/ShopSales";
import ShopProduct from "./Pages/ShoppingMall/ShopProduct";
import ShopProductWrite from "./Pages/ShoppingMall/ShopProductWrite";
import ShopProductEdit from "./Pages/ShoppingMall/ShopProductEdit";
import ShopPromotion from "./Pages/ShoppingMall/ShopPromotion";
import ShopPromotionWrite from "./Pages/ShoppingMall/ShopPromotionWrite";
import ShopPromotionEdit from "./Pages/ShoppingMall/ShopPromotionEdit";
import ShopOrder from "./Pages/ShoppingMall/ShopOrder";
import ShopOrderView from "./Pages/ShoppingMall/ShopOrderView";
import ShopTakeBack from "./Pages/ShoppingMall/ShopTakeBack";
import ShopTakeBackView from "./Pages/ShoppingMall/ShopTakeBackView";
import ShopCustomer from "./Pages/ShoppingMall/ShopCustomer";
import ShopCustomerView from "./Pages/ShoppingMall/ShopCustomerView";
import DirectSelfGoods from "./Pages/Goods/DirectSelfGoods";
import DirectSelfGoodsWrite from "./Pages/Goods/DirectSelfGoodsWrite";
import DirectSelfGoodsBulkWrite from "./Pages/Goods/DirectSlefGoodsBulkWrite";
import BenefitsPoint from "./Pages/Benefits/BenefitsPoint";
import PrivateRoute from "./PrivateRoute";
import { DIRECT_SELFGOODS_FRANCHISE } from "./Utiles/ListData";
import BenefitsCoupon from "./Pages/Benefits/BenefitsCoupon";
import BenefitsCouponWrite from "./Pages/Benefits/BenefitsCouponWrite";
import TermsView from "./Pages/Mypage/TermsView";
import BenefitsCharge from "./Pages/Benefits/BenefitsCharge";
import SlotAddPage from "./Pages/OwnPurchase/SlotAddPage";
import BenefitsChargeWrite from "./Pages/Benefits/BenefitsChargeWrite";

function Router() {
  const viewportHeight = window.innerHeight;

  // 자점매입 상품관리 페이지_상품 즉시 등록 가능한 가맹점 구분 위한 데이터
  const isExceptFran = DIRECT_SELFGOODS_FRANCHISE.find((item) => item.value == sessionStorage.getItem("franchise_id"))
    ? true
    : false;

  return (
    <BrowserRouter>
      <HeaderContainer viewportHeight={`${viewportHeight}px`}>
        <Header />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/main" element={<Home />} />
          <Route path="/sales" element={<Sales />} />
          <Route path="/sales/salesview" element={<SalesView />} />
          <Route path="/sales/reportsales" element={<ReportSales />} />
          <Route path="/sales/item" element={<SalesItem />} />
          <Route path="/sales/itemview" element={<SalesItemView />} />
          <Route path="/sales/itemrank" element={<SalesItemRank />} />
          <Route path="/sales/salespayment" element={<SalesPayment />} />
          <Route path="/sales/calendar" element={<SalesCalendar />} />

          <Route path="/franchisee" element={<Franchisee />} />
          <Route path="/franchisee/management" element={<FranchiseeManagement />} />
          <Route path="/franchisee/management/write" element={<FranchiseeWrite />} />
          <Route path="/franchisee/management/edit" element={<FranchiseeEdit />} />
          <Route path="/franchisee/user" element={<FranchiseeUser />} />
          <Route path="/franchisee/user/write" element={<FranchiseeUserWrite />} />
          <Route path="/franchisee/deposit" element={<FranchiseeDeposit />} />
          <Route path="/franchisee/user/edit" element={<FranchiseeUserEdit />} />
          <Route path="/franchisee/revenue" element={<FranchiseRevenue />} />
          <Route path="/franchisee/kiosk" element={<FranchiseeKiosk />} />
          <Route path="/franchisee/kiosk/write" element={<FranchiseeKioskWrite />} />
          <Route path="/franchisee/inquiry" element={<FranchiseeInquiry />} />

          <Route path="/goods/all" element={<Goods />} />
          <Route path="/goods/view" element={<GoodsView />} />
          <Route path="/goods" element={<Deposit />} />
          <Route path="/goods/deposit/view" element={<DepositView />} />
          <Route path="/goods/shopping" element={<Shopping />} />
          <Route path="/goods/orderhistory" element={<OrderHistory />} />
          <Route path="/goods/orderhistory/view" element={<OrderView />} />
          <Route path="/goods/order" element={<GoodsOrder />} />
          <Route path="/goods/ordercomplete" element={<OrderComplete />} />
          {/* 기본 슬롯구매, 편집 페이지 */}

          {/* <Route path="/goods/selfgoods" element={<SelfGoods />} /> */}
          {/* <Route path="/goods/selfgoods/buy" element={<BuySlot />} />
          <Route path="/goods/selfgoods/write" element={<SelfGoodsWrite />} />
          <Route path="/goods/selfgoods/edit" element={<SelfGoodsEdit />} />
          <Route path="/goods/selfgoods/slothistory" element={<SlotHistory />} />
          <Route path="/goods/selfgoods/slothistory/view" element={<SlotHistoryView />} /> */}

          {/* 특정지점만 슬롯구매없이 자점매입 상품등록 가능=> 전 지점 슬롯구매없이 자점매입 상품등록 가능*/}
          <Route path="/goods/selfgoods/edit" element={<SelfGoodsEdit />} />
          <Route path="/goods/direct/selfgoods" element={<DirectSelfGoods />}></Route>
          <Route path="/goods/direct/selfgoods/write" element={<DirectSelfGoodsWrite />}></Route>
          <Route path="/goods/direct/selfgoods/bulk/write" element={<DirectSelfGoodsBulkWrite />}></Route>
          <Route path="/goods/takeback" element={<GoodsTakeBack />} />
          <Route path="/goods/takeback/add" element={<AddTakeBack />} />
          <Route path="/goods/takeback/:take_back_id" element={<DetailTakeBack />} />

          <Route path="/mypage" element={<Mypage />} />
          <Route path="/mypage/alarm" element={<Alarm />} />
          <Route path="/mypage/edit" element={<MypageEdit />} />
          <Route path="/mypage/restock/storeowner" element={<RestockStoreOwner />} />
          <Route path="/mypage/terms" element={<TermsView />} />

          <Route path="/notice" element={<Notice />} />
          <Route path="/notice/view" element={<NoticeView />} />
          <Route path="/notice/write" element={<NoticeWrite />} />
          <Route path="/notice/edit" element={<NoticeEdit />} />

          <Route path="/product/category" element={<ProductCategory />} />
          <Route path="/product/all" element={<ProductAll />} />
          <Route path="/product/write" element={<ProductWrite />} />
          <Route path="/product/bulk/write" element={<ProductBulkWrite />} />
          <Route path="/product/edit" element={<ProductEdit />} />
          <Route path="/product" element={<DashBoard />} />
          <Route path="/product/restock" element={<ProductRestock />} />
          <Route path="/product/takeback" element={<TakeBack />} />
          <Route path="/product/takeback/:take_back_id" element={<DetailTakeBack />} />
          <Route path="/product/inventory" element={<Inventory />} />
          <Route path="/product/inventory/view" element={<InventoryView />} />
          <Route path="/product/inventory/bulk/write" element={<InventoryBulkWrite />} />
          <Route path="/product/inventory/storeowner" element={<InventoryStoreOwner />} />
          <Route path="/product/order" element={<ProductOrder />} />
          <Route path="/product/order/:order_date" element={<ProductOrderView />} />
          <Route path="/product/order/management" element={<OrderManagement />} />
          <Route path="/product/order/management/:order_date" element={<OrderManagementView />} />
          <Route path="/product/bundle" element={<ProductBundle />} />
          <Route path="/product/bundle/write" element={<BundleWrite />} />
          <Route path="/product/bundle/edit/:bundle_id" element={<BundleEdit />} />

          <Route path="/ownpurchase" element={<OwnPurchaseProduct />} />
          <Route path="/ownpurchase/inspection" element={<OwnPurchaseInspection />} />
          <Route path="/ownpurchase/slothistory" element={<SlotHistory />} />
          <Route path="/ownpurchase/slothistory/view" element={<SlotHistoryView />} />
          <Route path="/ownpurchase/slot/add" element={<SlotAddPage />} />

          <Route path="/shoppingmall" element={<ShopSales />} />
          <Route path="/shoppingmall/product" element={<ShopProduct />} />
          <Route path="/shoppingmall/product/write" element={<ShopProductWrite />} />
          <Route path="/shoppingmall/product/edit" element={<ShopProductEdit />} />
          <Route path="/shoppingmall/promotion" element={<ShopPromotion />} />
          <Route path="/shoppingmall/promotion/write" element={<ShopPromotionWrite />} />
          <Route path="/shoppingmall/promotion/edit" element={<ShopPromotionEdit />} />
          <Route path="/shoppingmall/order" element={<ShopOrder />} />
          <Route path="/shoppingmall/order/:idx" element={<ShopOrderView />} />
          <Route path="/shoppingmall/takeback" element={<ShopTakeBack />} />
          <Route path="/shoppingmall/takeback/:idx" element={<ShopTakeBackView />} />
          <Route path="/shoppingmall/customer" element={<ShopCustomer />} />
          <Route path="/shoppingmall/customer/:idx" element={<ShopCustomerView />} />

          <Route path="/benefits" element={<BenefitsCoupon />} />
          <Route path="/benefits/coupon/write" element={<BenefitsCouponWrite />} />
          <Route path="/benefits/point" element={<BenefitsPoint />} />
          <Route path="/benefits/charge" element={<BenefitsCharge />} />
          <Route path="/benefits/charge/write" element={<BenefitsChargeWrite />} />
        </Routes>
      </HeaderContainer>
    </BrowserRouter>
  );
}
export default Router;
