import React from "react";
import { AddComma } from "../../../Utiles/Custom";
import { TableWrap, Td, Th } from "../../../Assets/css/commonVer2";

/* code from sub_outsourcing */

export default function ProductTable({ revenueItems }) {
  return (
    <TableWrap>
      <table style={{ width: "100%", minWidth: "1024px" }}>
        <thead>
          <tr>
            <Th>1차분류</Th>
            <Th>상품명</Th>
            <Th>바코드번호</Th>
            <Th>총 합계</Th>
            <Th>카드</Th>
            <Th>현금</Th>
            <Th>포인트</Th>
            <Th>카카오페이</Th>
            <Th>제로페이</Th>
            <Th>네이버페이</Th>
          </tr>
        </thead>
        <tbody>
          {revenueItems.length > 0 ? (
            revenueItems?.map((item) => (
              <tr key={item.kiosk_sale_id}>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.category1st_name}</Td>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.product_name}</Td>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{item.barcode_num}</Td>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                  {AddComma(item.amount?.count)}개 | {AddComma(item.amount?.total)}원
                </Td>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                  {AddComma(item.card?.count)}개 | {AddComma(item.card?.total)}원
                </Td>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                  {AddComma(item.cash?.count)}개 | {AddComma(item.cash?.total)}원
                </Td>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                  {AddComma(item.point?.count)}개 | {AddComma(item.point?.total)}원
                </Td>
                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                  {AddComma(item.kakao?.count)}개 | {AddComma(item.kakao?.total)}원
                </Td>
                <Td>
                  {AddComma(item.zero?.count)}개 | {AddComma(item.zero?.total)}원
                </Td>
                <Td>
                  {AddComma(item.naver?.count)}개 | {AddComma(item.naver?.total)}원
                </Td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={7}
                style={{ borderTop: "1px solid #e1e1e1", textAlign: "center", padding: 10, fontSize: "14px" }}
              >
                데이터가 없습니다.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </TableWrap>
  );
}
