import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import {
  ContentContainer,
  Wrapper,
  Container,
  Contents,
  WhiteBox,
  FlexBox,
  Title,
  Text,
  Btn,
  TextInput,
  FileImg,
} from "../../Assets/css/commonVer2";
import img_noimage470 from "../../Assets/images/no_image_470px.jpg";
import HeaderSub from "../../Components/Header/HeaderSub";
import Authority from "../../Components/Modal/Authority";
import InfoModal from "../../Components/Modal/InfoModal";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import QuillContainer from "../../Components/QuillEditor/QuillContainer";
import { AddComma } from "../../Utiles/Custom";
import API from "../../Utiles/API";

// 쇼핑몰 > 상품 리스트 > 상품 수정

function ShopProductWrite() {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const idx = location.state.idx; // 쇼핑몰 상품 idx

  const shopProductQuillRef = useRef(); // 상품 설명 에디터 ref

  // 모달
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [listModal, setListModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [moveToBackModal, setMoveToBackModal] = useState(false);

  // 수정할 쇼핑몰 상품 데이터
  const [imagePath, setImagePath] = useState([]);
  const [cate2Value, setCate2Value] = useState("");
  const [values, setValues] = useState({
    idx: "",
    barcode_num: "",
    category2nd_id: "",
    brand: "",
    product_name: "",
    purchase_price: "",
    purchase_quantity: "",
    shipping_price: "",
    basic_shipment_quantity: "",
    product_quantity: "",
    supplier: "",
    order_unit: "",
    consumer_price: "",
    discount_rate: "",
    discount_price: "",
    information_detail: "",
  });
  const [description, setDescription] = useState("");

  // 할인가 계산 시 보여줄 데이터
  const [priceValues, setPriceValues] = useState({
    consumer_price: "",
    discount_rate: "",
    discount_price: "",
  });

  // API
  // 1. 등록된 쇼핑몰 상품 조회
  const getShopProduct = async () => {
    let api_link = `/shoppingmall/product?idx=${idx}`;

    await API.get(api_link).then((res) => {
      if (res.status) {
        setValues(res.data.results);
        setPriceValues(res.data.results);
        setDescription(res.data.results.information_detail);
        setImagePath(res.data.results.shoppingmall_product_imgs);
      }
    });
  };

  // 2. 쇼핑몰 상품 수정
  const putShopProduct = async () => {
    if (!priceValues.consumer_price) {
      setTextMsg("소비자가를 입력해주세요.");
      setTextModal(true);
      return;
    }
    if (!priceValues.discount_rate) {
      setTextMsg("할인율을 입력해주세요.");
      setTextModal(true);
      return;
    }
    if (!description) {
      setTextMsg("상품 설명을 입력해주세요.");
      setTextModal(true);
      return;
    }

    await API.put(`/shoppingmall/product`, {
      idx: idx,
      product_id: values.idx,
      barcode_num: values.barcode_num,
      category2nd_id: values.category2nd_id,
      brand: values.brand,
      product_name: values.product_name,
      purchase_price: values.purchase_price,
      purchase_quantity: values.purchase_quantity,
      shipping_price: values.shipping_price,
      basic_shipment_quantity: values.basic_shipment_quantity,
      product_quantity: values.product_quantity,
      supplier: values.supplier,
      order_unit: values.order_unit,
      consumer_price: values.consumer_price,
      discount_rate: values.discount_rate,
      discount_price: values.discount_price,
      information_detail: description,
      image_path: imagePath,
    })
      .then((res) => {
        if (res.data.status) {
          setMoveToBackModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  // 3. 상품 분류 id 로 분류명 구하기
  const getCate2List = async () => {
    if (values.category2nd_id) {
      API.get(`/admin/productCatrgory2nd/1`).then((res) => {
        if (res.data.status) {
          res.data.category2nd.map((item) => {
            if (item.idx === values.category2nd_id) {
              setCate2Value(item.name);
            }
          });
        }
      });
    }
  };

  // 1 목록 2 저장 버튼
  const onSubmit = (e) => {
    if (e === 1) {
      setSaveModal(false);
      navigate(-1, { replace: true });
    } else {
      putShopProduct();
      setSaveModal(false);
    }
  };

  // 할인가 계산
  const handleChange = (e) => {
    const updatedValues = {
      ...values,
      [e.target.name]: e.target.value.replaceAll(",", ""),
    };
    const updatedPriceValues = {
      ...priceValues,
      [e.target.name]: e.target.value ? AddComma(e.target.value.replaceAll(",", "")) : e.target.value,
    };
    if (e.target.name === "consumer_price" || e.target.name === "discount_rate") {
      const price = +document.querySelector("input[name=consumer_price]").value.replaceAll(",", "") || 0;
      const rate = +document.querySelector("input[name=discount_rate]").value.replaceAll(",", "") || 0;
      if (price && rate) {
        const calPrice = price - price * (rate / 100); // 할인율을 백분율로 사용하기 위해 100으로 나눔
        // 등록 시 보낼 소비자가, 할인율, 할인가 숫자로 할당
        updatedValues.consumer_price = price;
        updatedValues.discount_rate = rate;
        updatedValues.discount_price = calPrice;
        // 할인가는 콤마 붙인 문자 형식
        updatedPriceValues.discount_price = AddComma(calPrice);
      } else if (rate === 0) {
        // 등록 시 보낼 소비자가, 할인율, 할인가 숫자로 할당
        updatedValues.consumer_price = price;
        updatedValues.discount_rate = rate;
        updatedValues.discount_price = price;
        // 할인가는 콤마 붙인 문자 형식
        updatedPriceValues.discount_price = AddComma(price);
      } else {
        updatedValues.consumer_price = "";
        updatedValues.discount_rate = "";
        updatedValues.discount_price = "";
        updatedPriceValues.discount_price = "";
      }
    }
    setValues(updatedValues);
    setPriceValues(updatedPriceValues);
  };

  useEffect(() => {
    getCate2List();
  }, [values?.category2nd_id]);

  useEffect(() => {
    getShopProduct();
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          <HeaderSub title={"상품 수정"} />
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    상품 리스트 🛒
                  </Title>
                )}
                <WhiteBox>
                  <Title color={"#1E1E1E"} weight={"700"} size={"14px"} sizeMd={"20px"}>
                    상품 수정
                  </Title>
                  <Contents>
                    <WhiteBox>
                      <FileImg border={"unset"}>
                        <img
                          src={
                            imagePath[0] ? `${process.env.REACT_APP_S3_KEY}${imagePath[0]?.image_path}` : img_noimage470
                          }
                        />
                      </FileImg>
                    </WhiteBox>
                  </Contents>
                  <FlexBoxGrid3>
                    <Contents>
                      <ProText>바코드 번호</ProText>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"-"}
                        name="barcode_num"
                        value={values?.barcode_num}
                        disabled
                      />
                    </Contents>
                    <Contents>
                      <ProText>상품명</ProText>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"-"}
                        name="product_name"
                        value={values?.product_name}
                        disabled
                      />
                    </Contents>
                  </FlexBoxGrid3>
                  <FlexBoxGrid3>
                    <Contents>
                      <ProText>분류</ProText>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"-"}
                        name="cate2Value"
                        value={cate2Value}
                        disabled
                      />
                    </Contents>
                    <Contents>
                      <ProText>브랜드</ProText>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"-"}
                        name="brand"
                        value={values?.brand}
                        disabled
                      />
                    </Contents>
                  </FlexBoxGrid3>
                  <FlexBoxGrid3>
                    <Contents>
                      <ProText>매입가</ProText>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="purchase_price"
                          value={values?.purchase_price}
                          disabled
                        />
                        <InputSmallText>원</InputSmallText>
                      </div>
                    </Contents>
                    <Contents>
                      <ProText>매입수량</ProText>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        align={"right"}
                        placeholder={"0"}
                        name="purchase_quantity"
                        value={values?.purchase_quantity}
                        disabled
                      />
                    </Contents>
                    <Contents>
                      <ProText>매입비용</ProText>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="purchase_cost"
                          value={values?.purchase_price * values?.purchase_quantity || ""}
                          disabled
                        />
                        <InputSmallText>원</InputSmallText>
                      </div>
                    </Contents>
                  </FlexBoxGrid3>
                  <FlexBoxGrid3>
                    <Contents>
                      <ProText>출하가</ProText>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="shipping_price"
                          value={values?.shipping_price}
                          disabled
                        />
                        <InputSmallText>원</InputSmallText>
                      </div>
                    </Contents>
                    <Contents>
                      <ProText>기본 출하 수량</ProText>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        align={"right"}
                        placeholder={"0"}
                        name="basic_shipment_quantity"
                        value={values?.basic_shipment_quantity}
                        disabled
                      />
                    </Contents>
                    <Contents>
                      <ProText>출하비용</ProText>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="shipping_cost"
                          value={values?.shipping_price * values?.basic_shipment_quantity || ""}
                          disabled
                        />
                        <InputSmallText>원</InputSmallText>
                      </div>
                    </Contents>
                  </FlexBoxGrid3>
                  <FlexBoxGrid3>
                    <Contents>
                      <ProText>상품 갯수</ProText>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "38px", width: "100%" }}
                          name="product_quantity"
                          value={values?.product_quantity}
                          disabled
                        />
                        <InputSmallText>개입</InputSmallText>
                      </div>
                    </Contents>
                    <Contents>
                      <ProText>공급업체</ProText>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"-"}
                        name="supplier"
                        value={values?.supplier}
                        disabled
                      />
                    </Contents>
                    <Contents>
                      <ProText>발주단위</ProText>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"-"}
                        name="order_unit"
                        value={values?.order_unit}
                        disabled
                      />
                    </Contents>
                  </FlexBoxGrid3>
                  <FlexBoxGrid3>
                    <Contents>
                      <ProText>소비자가</ProText>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="consumer_price"
                          value={priceValues?.consumer_price}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <InputSmallText>원</InputSmallText>
                      </div>
                    </Contents>
                    <Contents>
                      <ProText>할인율</ProText>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          style={{ paddingRight: "25px", width: "100%" }}
                          type={"text"}
                          align={"right"}
                          placeholder={"0"}
                          name="discount_rate"
                          value={priceValues?.discount_rate}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <InputSmallText>%</InputSmallText>
                      </div>
                    </Contents>
                    <Contents>
                      <ProText>최종소비자가</ProText>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="discount_price"
                          value={priceValues?.discount_price}
                          disabled
                        />
                        <InputSmallText>원</InputSmallText>
                      </div>
                    </Contents>
                  </FlexBoxGrid3>
                  <Contents margin={"10px 0 0 0"} marginMd={"30px 0 0 0"}>
                    <ProText>상품 설명</ProText>
                    <QuillContainer quillRef={shopProductQuillRef} value={description} setValue={setDescription} />
                  </Contents>
                </WhiteBox>
                <FlexBox justify={"end"} gap={"8px"} gapMd={"10px"}>
                  <Btn
                    width={"100%"}
                    widthMd={"90px"}
                    size={"14px"}
                    color={"#666"}
                    border={"1px solid #666"}
                    padding={"8px 18px"}
                    lineHeight={"22px"}
                    hoverWeight={"700"}
                    hoverColor={"#fff"}
                    hoverBackground={"#C7C7C7"}
                    hoverBorder={"1px solid transparent"}
                    onClick={() => setListModal(true)}
                  >
                    목록
                  </Btn>
                  <Btn
                    width={"100%"}
                    widthMd={"90px"}
                    color={"#fff"}
                    background={"#FF4A4A"}
                    size={"14px"}
                    weight={"700"}
                    padding={"8px 18px"}
                    lineHeight={"22px"}
                    onClick={() => setSaveModal(true)}
                  >
                    저장
                  </Btn>
                </FlexBox>
              </Container>
              <ConfirmModal
                modal={listModal}
                setModal={setListModal}
                onSubmit={() => onSubmit(1)}
                description={"상품 수정을 취소하고\n목록화면으로 이동하시겠습니까?"}
              />
              <ConfirmModal
                modal={saveModal}
                setModal={setSaveModal}
                onSubmit={() => onSubmit(2)}
                description={"상품 수정을 완료하시겠습니까?"}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
              <InfoModal
                modal={moveToBackModal}
                setModal={setMoveToBackModal}
                mode={"goBack"}
                description={"수정이 완료되었습니다."}
              />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const FlexBoxGrid3 = styled(FlexBox)`
  justify-content: start;
  align-items: start;
  margin: 20px 0 0 0;
  gap: 10px;

  @media (min-width: 1024px) {
    margin: 30px 0 0 0;
    gap: 25px;
    & > div:nth-of-type(1),
    & > div:nth-of-type(2) {
      width: calc(50% - 5px);
    }
    & > div:nth-of-type(3) {
      width: 100%;
    }
  }

  @media (max-width: 410px) {
    display: unset;
    margin: 0;

    & > div:nth-of-type(1),
    & > div:nth-of-type(2) {
      width: 100%;
    }

    & > div:nth-of-type(3) {
      width: 100%;
      margin: 0 0 10px 0;
    }

    & > div:nth-of-type(2) {
      margin: 10px 0;
    }
  }
`;

const ProText = styled(Text)`
  text-align: left;
  font-weight: 700;
  color: #1e1e1e;
  font-size: 14px;
  letter-spacing: -0.32px;
  margin: 0 0 8px;

  @media (min-width: 1024px) {
    font-size: 16px;
    margin: 0 0 10px;
  }
`;

const InputSmallText = styled(Text)`
  position: absolute;
  top: 13px;
  right: 12px;
  font-size: 14px;
  color: #c7c7c7;
`;

export default ShopProductWrite;
