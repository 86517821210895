import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import {
  ContentContainer,
  Container,
  FlexBox,
  Title,
  Text,
  Wrapper,
  WhiteBox,
  HalfDiv,
  OrderAddrBox,
  TableWrap,
  Th,
  Td,
} from "../../Assets/css/commonVer2";
import HeaderSub from "../../Components/Header/HeaderSub";
import Authority from "../../Components/Modal/Authority";
import Pagination from "../../Components/Paging/Pagination";
import HandleBack from "../../Components/HandleBack";
import { AddComma, hypen } from "../../Utiles/Custom";
import API from "../../Utiles/API";

// 쇼핑몰 > 고객 리스트 > 상세

function ShopCustomerView() {
  const navigate = useNavigate();
  const { handlePopstate } = HandleBack();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const { idx } = useParams();

  // 고객 정보
  const [customerInfo, setCustomerInfo] = useState({});

  // 포인트
  // 포인트 구분 : - 사용(구매, 사용, 선물)  / + 적립(환불, 충전)
  const [totalPoint, setTotalPoint] = useState(0);
  const [attendancePoint, setAttendancePoint] = useState(0);
  const [pointList, setPointList] = useState([]);
  const [pointPagination, setPointPagination] = useState("");
  const [pointCurrentPage, setPointCurrentPage] = useState(1);

  // 주문 상세를 다녀온 경우 저장 해놓은 파라미터 정보
  const parsedCustomerViewPageState = sessionStorage.getItem("customerViewPageState")
    ? JSON.parse(sessionStorage.getItem("customerViewPageState"))
    : {};

  // 거래 내역
  const [transList, setTransList] = useState([]);
  const [transPagination, setTransPagination] = useState("");
  const [transCurrentPage, setTransCurrentPage] = useState(
    parsedCustomerViewPageState
      ? parsedCustomerViewPageState?.transCurrentPage
        ? parsedCustomerViewPageState?.transCurrentPage
        : 1
      : 1
  );

  // 주문 상세를 갈 때 sessionStorage에 저장할 객체
  const customerViewPageState = {
    transCurrentPage,
  };

  // API
  // 1. 고객 상세 GET
  const getCustomerView = async () => {
    let api_link = `/shoppingmall/consumer/${idx}`;

    await API.get(api_link).then((res) => {
      if (res.data.status) {
        setCustomerInfo(res.data.results);
      }
    });
  };

  // 2. 포인트 현황 GET
  const getPointList = async () => {
    let api_link = `/shoppingmall/consumer/point/${idx}?page=${pointCurrentPage}&unit=5`;

    await API.get(api_link).then((res) => {
      if (res.data.status) {
        setTotalPoint(res.data.total_point);
        setAttendancePoint(res.data.attendance_point);
        setPointList(res.data.results);
        setPointPagination(res.data.pagination);
      }
    });
  };

  // 3. 거래 내역 GET
  const getTransactionList = async () => {
    let api_link = `/shoppingmall/deal-list?idx=${idx}&page=${transCurrentPage}&unit=10`;

    await API.get(api_link).then((res) => {
      if (res.data.status) {
        setTransList(res.data.results);
        setTransPagination(res.data.pagination);
      }
    });
  };

  // 앱에서 구매한 경우, 쇼핑몰 > 주문내역 조회 및 관리 > 상세로 이동
  // (오프라인 가맹점에서 구매한 경우 상세 페이지 없이 표에서 데이터만 노출함)
  function onClickItem(gubun, idx) {
    if (gubun === "앱") {
      navigate(`/shoppingmall/order/${idx}`);
      let jsonString = JSON.stringify(customerViewPageState);
      sessionStorage.setItem("customerViewPageState", jsonString);
    }
  }

  const movePointPage = (e) => {
    setPointCurrentPage(e);
  };

  const moveOrderPage = (e) => {
    setTransCurrentPage(e);
  };

  useEffect(() => {
    getCustomerView();
  }, [idx]);

  useEffect(() => {
    getPointList();
  }, [idx, pointCurrentPage]);

  useEffect(() => {
    getTransactionList();
  }, [idx, transCurrentPage]);

  useEffect(() => {
    sessionStorage.removeItem("customerViewPageState");
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          <HeaderSub title={"고객 상세"} />
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    고객 상세 📋
                  </Title>
                )}
                <FlexBox justify={"space-between"} align={"flex-start"} wrap={"wrap"}>
                  <HalfFlexDiv>
                    <WhiteBox>
                      <Title
                        color={"#1E1E1E"}
                        weight={"700"}
                        size={"16px"}
                        sizeMd={"18px"}
                        style={{
                          paddingBottom: "10px",
                          borderBottom: "1px solid #666",
                        }}
                      >
                        고객 정보
                      </Title>
                      <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          이름
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          {customerInfo?.name ? customerInfo.name : "-"}
                        </Text>
                      </OrderAddrBox>
                      <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          이메일
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          {customerInfo?.email ? customerInfo.email : "-"}
                        </Text>
                      </OrderAddrBox>
                      <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          휴대폰번호
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          {hypen(customerInfo?.phone) ? hypen(customerInfo?.phone) : "-"}
                        </Text>
                      </OrderAddrBox>
                      <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          주소
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"} width={"100%"}>
                          {customerInfo?.address ? customerInfo.address : "-"}
                        </Text>
                      </OrderAddrBox>
                      <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          상세주소
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"} width={"100%"}>
                          {customerInfo?.detail_address ? customerInfo.detail_address : "-"}
                        </Text>
                      </OrderAddrBox>
                      <FamilyBox justify={"flex-start"} align={"flex-start"} style={{ marginTop: "15px" }}>
                        <div>
                          <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                            가족 구성원
                          </Text>
                        </div>
                        {customerInfo?.family?.length > 0 ? (
                          <div>
                            {customerInfo.family?.map((item, key) => (
                              <Text key={key} color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                                {`${item.name}(${item.family_role === "leader" ? "대표" : "구성원"}) | ${hypen(
                                  item.phone
                                )}`}
                              </Text>
                            ))}
                          </div>
                        ) : (
                          <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                            -
                          </Text>
                        )}
                      </FamilyBox>
                    </WhiteBox>
                  </HalfFlexDiv>
                  <HalfFlexDiv>
                    <WhiteBox margin={"0"}>
                      <Title
                        color={"#1E1E1E"}
                        weight={"700"}
                        size={"16px"}
                        sizeMd={"18px"}
                        style={{
                          paddingBottom: "10px",
                          borderBottom: "1px solid #666",
                        }}
                      >
                        포인트 현황
                      </Title>
                      <Text
                        color={"#1E1E1E"}
                        weight={"500"}
                        align={"left"}
                        size={"14px"}
                        sizeMd={"16px"}
                        margin={"15px 0 0 0"}
                      >
                        적립 포인트
                      </Text>
                      <FlexBox justify={"space-between"} align={"flex-start"} wrap={"wrap"}>
                        <HalfFlexDiv>
                          <WhiteBox>
                            <Text color={"#666"} align={"left"} size={"14px"} sizeMd={"16px"} margin={"15px 10px"}>
                              총 적립 포인트
                            </Text>
                            <Text
                              size={"20px"}
                              sizeMd={"26px"}
                              color={"#FF4A4A"}
                              weight={"700"}
                              align={"right"}
                              style={{
                                fontFamily: "Montserrat",
                                marginBottom: "10px",
                              }}
                            >
                              {AddComma(totalPoint)}
                              <PointSpan>p</PointSpan>
                            </Text>
                          </WhiteBox>
                        </HalfFlexDiv>
                        <HalfFlexDiv>
                          <WhiteBox margin={"0 0 20px"}>
                            <Text color={"#666"} align={"left"} size={"14px"} sizeMd={"16px"} margin={"15px 10px"}>
                              출석 포인트
                            </Text>
                            <Text
                              size={"20px"}
                              sizeMd={"26px"}
                              color={"#FF4A4A"}
                              weight={"700"}
                              align={"right"}
                              style={{
                                fontFamily: "Montserrat",
                                marginBottom: "10px",
                              }}
                            >
                              {AddComma(attendancePoint)}
                              <PointSpan>p</PointSpan>
                            </Text>
                          </WhiteBox>
                        </HalfFlexDiv>
                      </FlexBox>
                      <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"16px"}>
                        포인트 이용내역
                      </Text>
                      <TableWrap>
                        <table style={{ width: "100%" }}>
                          <colgroup>
                            <col style={{ width: "10%" }} />
                            <col style={{ width: "15%" }} />
                            <col />
                            <col />
                            <col style={{ width: "24%" }} />
                          </colgroup>
                          <thead>
                            <tr>
                              <Th>No</Th>
                              <Th>구분</Th>
                              <Th>포인트 금액</Th>
                              <Th>사용일시</Th>
                              <Th>사용(적립)처</Th>
                            </tr>
                          </thead>
                          <tbody>
                            {pointList.length > 0 ? (
                              pointList.map((item, key) => (
                                <tr key={key}>
                                  <Td>{(pointCurrentPage - 1) * 5 + key + 1}</Td>
                                  {/* <Td>{item.idx}</Td> */}
                                  <Td color={item.type === "환불" || item.type === "충전" ? "#3957F6" : "#F63958"}>
                                    {item.type}
                                  </Td>
                                  <Td weight={700}>{AddComma(item.point)}원</Td>
                                  <Td>{item.created_at}</Td>
                                  <Td>{item.franchise_name}</Td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan={5}
                                  style={{
                                    borderTop: "1px solid #e1e1e1",
                                    textAlign: "center",
                                    padding: 10,
                                    fontSize: "16px",
                                  }}
                                >
                                  데이터가 없습니다.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </TableWrap>
                      {pointPagination?.total_page > 0 ? (
                        <Pagination
                          pagination={pointPagination}
                          movePage={movePointPage}
                          currentPage={pointCurrentPage}
                        />
                      ) : (
                        ""
                      )}
                    </WhiteBox>
                  </HalfFlexDiv>
                </FlexBox>
                <WhiteBox>
                  <Title
                    color={"#1E1E1E"}
                    weight={"700"}
                    size={"16px"}
                    sizeMd={"18px"}
                    style={{ paddingBottom: "10px" }}
                  >
                    거래 내역
                  </Title>
                  <TableWrap>
                    <table style={{ width: "100%" }}>
                      <colgroup>
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "15%" }} />
                        <col />
                        <col style={{ width: "20%" }} />
                        <col style={{ width: "15" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>구분</Th>
                          <Th>주문번호</Th>
                          <Th>구매 수량</Th>
                          <Th>총 구매 금액</Th>
                          <Th>거래일시</Th>
                          <Th>상태</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {transList.length > 0 ? (
                          transList.map((item, key) => (
                            <tr
                              key={key}
                              style={{ cursor: item.gubun === "앱" ? "pointer" : "unset" }}
                              onClick={() => onClickItem(item.gubun, item.require_idx)}
                            >
                              <Td>{item ? item.gubun : "-"}</Td>
                              <Td>{item ? item.require_idx : 0}</Td>
                              <Td>{item ? item.quantity : 0}</Td>
                              <Td weight={700}>{item ? AddComma(item.total_amount) : 0}원</Td>
                              <Td>{item ? item.transaction_date : "-"}</Td>
                              <Td weight={700}>{item ? item.status : "-"}</Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={6}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  {transPagination?.total_page > 0 ? (
                    <Pagination pagination={transPagination} movePage={moveOrderPage} currentPage={transCurrentPage} />
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

export default ShopCustomerView;

const FamilyBox = styled(FlexBox)`
  div {
    width: 135px;
  }
  div:nth-of-type(2) {
    width: calc(100% - 135px);
  }

  @media all and (max-width: 1024px) {
    div {
      width: 90px;
    }
    div:nth-of-type(2) {
      width: calc(100% - 90px);
    }
  }
`;

const HalfFlexDiv = styled.div`
  width: calc(50% - 13px);

  @media all and (max-width: 1464px) {
    width: 100%;
  }
`;

const PointSpan = styled.span`
  font-size: 20px;
  font-weight: 400;

  @media all and (max-width: 1024px) {
    font-size: 14px;
  }
`;
