import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import Header from "../../Components/Header/HeaderVer2";
import {
  TableWrap,
  Th,
  Td,
  Btn,
  ContentContainer,
  HeaderContainer,
  Wrapper,
  Container,
  WhiteBox,
  Title,
  PagerWrap,
  CheckLabel,
  CheckIcon,
  FlexBox,
} from "../../Assets/css/commonVer2";
import API from "../../Utiles/API";
import { str, hypen } from "../../Utiles/Custom";
import Authority from "../../Components/Modal/Authority";
import Paging from "../../Components/Paging/Paging";
import FranSelect from "../../Components/Select/FranSelect";
import BasicSelect from "../../Components/Select/BasicSelect";
import { FRANCHISE_STATUS_LIST, UNIT_10_TO_30_LIST } from "../../Utiles/ListData";
import InfoModal from "../../Components/Modal/InfoModal";
import HandleBack from "../../Components/HandleBack";

// (관리자) 가맹마스터 > 가맹점 관리

function FranchiseeManagement() {
  const navigate = useNavigate();
  const { handlePopstate } = HandleBack();
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const [franShow, setFranShow] = useState(false);
  const [franUserShow, setFranUserShow] = useState(false);

  const [searchFran, setSearchFran] = useState([]);
  const [searchUser, setSearchUser] = useState([]);

  const [data, setData] = useState([]);

  const [searchVal, setSearchVal] = useState(0);

  const [totalCount, setTotalCount] = useState(0);

  const [franSelect, setFranSelect] = useState(false);
  const [userSelect, setUserSelect] = useState(false);

  const [checkItems, setCheckItems] = useState([]);
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");

  // 수정 및 등록을 다녀온 경우 저장 해놓은 파라미터 정보
  const parsedFranchiseeManagementPageState = sessionStorage.getItem("franchiseeManagementPageState")
    ? JSON.parse(sessionStorage.getItem("franchiseeManagementPageState"))
    : {};

  const [currentPage, setCurrentPage] = useState(
    parsedFranchiseeManagementPageState
      ? parsedFranchiseeManagementPageState?.currentPage
        ? parsedFranchiseeManagementPageState?.currentPage
        : 1
      : 1,
  );
  const [pageSize, setPageSize] = useState(
    parsedFranchiseeManagementPageState
      ? parsedFranchiseeManagementPageState?.pageSize
        ? parsedFranchiseeManagementPageState?.pageSize
        : 10
      : 10,
  );
  const [franText, setFranText] = useState(
    parsedFranchiseeManagementPageState
      ? parsedFranchiseeManagementPageState?.franText
        ? parsedFranchiseeManagementPageState?.franText
        : "가맹점 전체"
      : "가맹점 전체",
  );
  const [franUserText, setFranUserText] = useState(
    parsedFranchiseeManagementPageState
      ? parsedFranchiseeManagementPageState?.franUserText
        ? parsedFranchiseeManagementPageState?.franUserText
        : "가맹주 전체"
      : "가맹주 전체",
  );

  let val = parsedFranchiseeManagementPageState
    ? parsedFranchiseeManagementPageState?.val
      ? parsedFranchiseeManagementPageState?.val
      : []
    : [];

  const [valbusinessNum, setValbusinessNum] = useState(
    parsedFranchiseeManagementPageState
      ? parsedFranchiseeManagementPageState?.valbusinessNum
        ? parsedFranchiseeManagementPageState?.valbusinessNum
        : undefined
      : undefined,
  );
  const [statusVal, setStatusVal] = useState(
    parsedFranchiseeManagementPageState
      ? parsedFranchiseeManagementPageState?.statusVal
        ? parsedFranchiseeManagementPageState?.statusVal
        : ""
      : "",
  );

  const totalPage = Math.ceil(totalCount / pageSize); // 페이징 관련

  // 가맹점 등록을 다녀온 경우 저장 해놓은 파라미터 정보
  let franchiseeManagementPageState = {
    currentPage,
    pageSize,
    val,
    valbusinessNum,
    statusVal,
    franText,
    franUserText,
  };

  // 가맹점 목록 가져오기
  const getFranshiseeList = async () => {
    const { data } = await API.get(
      `/admin/franchise?curPage=${currentPage}&pageSize=${pageSize}&filterVal1=${val}&filterVal2=${val}&filterVal3=${statusVal}&filterVal4=${valbusinessNum}`,
    );
    if (data.status) {
      setData(data?.result);
      setTotalCount(data?.cntAll);
    }
  };
  const getsearch_F = async () => {
    //가맹점 리스트호출
    await API.get(`/admin/search-franchise`).then((res) => {
      if (res.data.status) {
        setSearchFran(res.data.result);
      }
    });
  };

  const getsearch_S = async () => {
    //가맹주 리스트 호출
    await API.get(`/admin/search-storeonwer`).then((res) => {
      if (res.data.status) {
        setSearchUser(res.data.result);
      }
    });
  };

  const getsearch_user = async (idx, gubun) => {
    //가맹점 가맹주 기준으로 사업자번호 반환
    if (idx) {
      await API.get(`/admin/search-result/${idx}`).then((res) => {
        if (res.data.status) {
          if (gubun === 1) {
            setFranUserText(res.data.result[0] ? res.data.result[0].name : "가맹주 전체");
          } else {
            setFranText(res.data.result[0] ? res.data.result[0]["franchise_name"] : "가맹점 전체");
          }
          setValbusinessNum(res.data.result[0] ? res.data.result[0]["business_num"] : "");
        }
      });
    } else {
      if (gubun === 1) {
        setFranUserText("가맹주 전체");
      } else {
        setFranText("가맹점 전체");
      }
      setValbusinessNum("");
    }
  };

  if (franSelect) {
    val = searchFran.filter((e, i) => e.franchise_name.trim() == franText.trim()).map((e) => e.idx);
    setFranSelect(false);
    getsearch_user(val[0], 1);
  }

  if (userSelect) {
    val = searchUser.filter((e, i) => e.name === franUserText).map((e) => e.franchise_id);
    setUserSelect(false);
    getsearch_user(val[0], 2);
  }

  const search = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    setSearchVal(1);
    getFranshiseeList();
  };

  // 아이템 체크
  const onChangeChx = (checked, id) => {
    if (checked) {
      setCheckItems((prev) => [...prev, id]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  // 아이템 전체 체크
  const onChangeAllChk = (checked) => {
    if (checked) {
      const chkArr = [];
      for (let i = 0; i < data.length; i++) {
        chkArr.push(data[i].idx);
      }
      setCheckItems(chkArr);
    } else {
      setCheckItems([]);
    }
  };

  // 아이템 수정 클릭
  const onClickEditFranchisee = () => {
    if (checkItems.length === 0) {
      setTextMsg("수정할 가맹점을 선택해주세요.");
      setTextModal(true);
      return;
    }
    if (checkItems.length > 1) {
      setTextMsg("하나만 선택해주세요.");
      setTextModal(true);
      return;
    }
    if (checkItems.length === 1) {
      const foundItem = data.find((item) => item.idx === checkItems[0]);
      navigate("Edit", { state: { idx: foundItem.idx } });
    }
    let jsonString = JSON.stringify(franchiseeManagementPageState);
    sessionStorage.setItem("franchiseeManagementPageState", jsonString);
  };

  useEffect(() => {
    // 현재 페이지에 도착하면 무조건 한 번 초기화
    sessionStorage.removeItem("franchiseeManagementPageState");
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    getsearch_F();
    getsearch_S();
  }, []);

  useEffect(() => {
    if (!parsedFranchiseeManagementPageState?.currentPage && currentPage !== 1) {
      // 등록이나 수정 다녀왔을 때 sessionStorage가 초기화 되고 currentPage가 1이 아니었을 때
      // 평소 페이징에서 currentPage가 1이 아니었을 때
      setCurrentPage(1);
    } else {
      // 등록이나 수정 다녀왔을 때 sessionStorage가 초기화 되기 전 실행
      // currentPage 1에서 유닛을 바꿀 때
      getFranshiseeList();
    }
  }, [pageSize]);

  useEffect(() => {
    getFranshiseeList();
    setCheckItems([]);
  }, [currentPage]);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {isMobile && <Header title={"가맹점 관리"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    가맹점 관리 📋
                  </Title>
                )}

                <WhiteBox margin={"0 0"}>
                  <FlexBox justify={"end"} wrap={"wrap"} wrapMd={"nowrap"} gap={"10px"}>
                    <FranSelect
                      widthMd={"100%"}
                      gubun={"fran"}
                      selectBtnText={franText}
                      setTextChange={setFranText}
                      placeholder={"가맹점을"}
                      show={franShow}
                      setFranShow={setFranShow}
                      setFranUserShow={setFranUserShow}
                      setFranSelect={setFranSelect}
                      setUserSelect={setUserSelect}
                    />
                    <FranSelect
                      widthMd={"100%"}
                      gubun={"franUser"}
                      selectBtnText={franUserText}
                      setTextChange={setFranUserText}
                      placeholder={"가맹주를"}
                      show={franUserShow}
                      setFranShow={setFranShow}
                      setFranUserShow={setFranUserShow}
                      setFranSelect={setFranSelect}
                      setUserSelect={setUserSelect}
                    />
                    <BasicSelect
                      width={"140px"}
                      data={FRANCHISE_STATUS_LIST}
                      selectedValue={statusVal}
                      setSelectedValue={setStatusVal}
                      placeholder={"상태 전체"}
                      canSelectAll={true}
                    />
                    <Btn
                      color={"#fff"}
                      background={"#FF4A4A"}
                      size={"14px"}
                      weight={"700"}
                      width={"100%"}
                      widthMd={"unset"}
                      padding={"8px 18px"}
                      lineHeight={"22px"}
                      onClick={(e) => search(e)}
                      style={{ flexShrink: 0 }}
                    >
                      검 색
                    </Btn>
                  </FlexBox>
                  <FlexBox
                    justify={"space-between"}
                    wrap={"wrap"}
                    gap={"10px"}
                    wrapLg={"nowrap"}
                    margin={"20px 0 10px"}
                    marginMd={"20px 0 30px"}
                  >
                    {!isMobile && (
                      <BasicSelect
                        width={"130px"}
                        data={UNIT_10_TO_30_LIST}
                        selectedValue={pageSize}
                        setSelectedValue={setPageSize}
                        canSelectAll={false}
                        setCurrentPage={setCurrentPage}
                      />
                    )}
                    <FlexBox style={{ marginLeft: "auto" }}>
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        hoverWeight={"700"}
                        onClick={(props) => {
                          navigate("write");
                          let jsonString = JSON.stringify(franchiseeManagementPageState);
                          sessionStorage.setItem("franchiseeManagementPageState", jsonString);
                        }}
                      >
                        등록
                      </Btn>
                      <Btn
                        size={"14px"}
                        padding={"8px 18px"}
                        lineHeight={"22px"}
                        margin={"0 0 0 10px"}
                        hoverWeight={"700"}
                        onClick={onClickEditFranchisee}
                      >
                        수정
                      </Btn>
                    </FlexBox>
                  </FlexBox>
                  <TableWrap>
                    <table style={{ width: "100%", minWidth: "1024px" }}>
                      <colgroup>
                        <col style={{ width: "4%" }} />
                        <col style={{ width: "6%" }} />
                        <col />
                        <col />
                        <col style={{ width: "8%" }} />
                        <col />
                        <col />
                        <col style={{ width: "12%" }} />
                        <col style={{ width: "8%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>
                            <CheckLabel htmlFor="chkAll" style={{ justifyContent: "center" }}>
                              <input
                                type="checkbox"
                                id="chkAll"
                                onChange={(event) => onChangeAllChk(event.target.checked)}
                                checked={checkItems.length === data.length ? true : false}
                              />
                              <CheckIcon>
                                <svg viewBox="0 0 24 24">
                                  <polyline points="19 7 10 17 5 12" />
                                </svg>
                              </CheckIcon>
                            </CheckLabel>
                          </Th>
                          <Th>No</Th>
                          <Th>가맹점명</Th>
                          <Th>사업자 등록번호</Th>
                          <Th>가맹점주</Th>
                          <Th>전화번호</Th>
                          <Th>주소</Th>
                          <Th>등록일</Th>
                          <Th>상태</Th>
                        </tr>
                      </thead>

                      <tbody>
                        {data?.length > 0 ? (
                          data?.map((e, i) => (
                            <tr
                              style={{ cursor: "pointer" }}
                              key={i}
                              onClick={(event) => {
                                const targetElement = event.target;
                                if (targetElement.nodeName !== "INPUT") {
                                  // 체크박스를 클릭한 경우에는 이벤트를 처리하지 않음
                                  const isChecked = document.getElementById(`prod${e.idx}`).checked;
                                  onChangeChx(!isChecked, e.idx);
                                }
                              }}
                            >
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                <CheckLabel htmlFor={`prod${e.idx}`} style={{ justifyContent: "center" }}>
                                  <input
                                    type="checkbox"
                                    name="prod"
                                    id={`prod${e.idx}`}
                                    readOnly
                                    checked={checkItems.includes(e.idx) ? true : false}
                                  />
                                  <CheckIcon>
                                    <svg viewBox="0 0 24 24">
                                      <polyline points="19 7 10 17 5 12" />
                                    </svg>
                                  </CheckIcon>
                                </CheckLabel>
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {(currentPage - 1) * pageSize + i + 1}
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{e.franchise_name || "-"}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{e.business_num || "-"}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{e["users.name"] || "-"}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {e["users.tel"] ? hypen(e["users.tel"]) : "-"}
                                {/* {hypen(e['users.tel'])} */}
                              </Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{e.franchise_address || "-"}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{str(e.createdat)}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                {FRANCHISE_STATUS_LIST.find((item) => item.value === e.status)?.text}
                              </Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={8}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  {data.length > 0 ? (
                    <PagerWrap>
                      <Paging totalPage={totalPage} limit={pageSize} page={currentPage} setPage={setCurrentPage} />
                    </PagerWrap>
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

export default FranchiseeManagement;
