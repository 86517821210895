import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

import { FlexBox, Btn, Text, TextInput } from "../../../Assets/css/commonVer2";
import close from "../../../Assets/images/close.png";
import { updateShopDeliveryState, changeShopDeliveryState } from "../../../Utiles";
import CourierCompanySelect from "../../../Components/Select/CourierCompanySelect";
import InfoModal from "../../../Components/Modal/InfoModal";

// 택배사, 운송장번호 모달
// 1. 쇼핑몰 > 주문내역 조회 및 관리 > 배송중으로 상태 변경
// 2. 쇼핑몰 > 주문내역 조회 및 관리 > 상세 > 택배사, 운송장 번호 정보 변경

export default function ShopDeliveryModal(props) {
  const {
    gubun, // 주문내역 메인 <-> 상세 페이지 구분값
    modal,
    setModal,
    title,
    idx,
    editCourierCompany,
    setEditCourierCompany,
    editTrackingNumber,
    setEditTrackingNumber,
  } = props;

  // 모달
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [refreshModal, setRefreshModal] = useState(false);

  // 택배사명과 택배사 코드 배열
  const [courierList, setCourierList] = useState([]);
  // 선택한 택배사의 코드
  const [courierCode, setCourierCode] = useState("");
  
  // 주문내역 메인 페이지에서 배송상태 변경 시 필요한 state
  const [courierCompany, setCourierCompany] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  
  // API
  // 1. 택배사 목록 조회
  const getCourierList = async () => {
    await axios.get("https://apis.tracker.delivery/carriers")
    .then((res) => {
      setCourierList(res.data);
    })
  }
  
  // 2. 배송상태 또는 배송정보 변경
  const onSubmit = () => {
    if (gubun === "write") {
      // 예외처리
      if (!courierCompany || courierCompany === "택배사 전체") {
        setTextMsg("택배사를 선택해주세요.");
        setTextModal(true);
      } else if (trackingNumber === ""){
        setTextMsg("운송장 번호를 입력해주세요.");
        setTextModal(true);
      } else if (trackingNumber.includes(" ")) {
        setTextMsg("운송장 번호의 공백문자를 삭제해주세요.");
        setTextModal(true);
      } else {
        // 배송상태 변경(배송전 -> 배송중)
        updateShopDeliveryState(
          idx,
          courierCompany,
          courierCode,
          trackingNumber,
        )
          .then((res) => {
            if (res.status) {
              setTextMsg("주문이 배송중 상태로 변경되었습니다.");
              setRefreshModal(true);
            } else {
              setTextMsg(res.msg || "잠시후 다시 시도해주세요.");
              setTextModal(true);
            }
          })
          .catch(() => {
            setTextMsg("E01\n잠시후 다시 시도해주세요.");
            setTextModal(true);
          });
      }
    } else {
      // 예외처리
      if (editCourierCompany === "택배사 전체") {
        setTextMsg("택배사를 선택해주세요.");
        setTextModal(true);
      } else if (editTrackingNumber === ""){
        setTextMsg("운송장 번호를 입력해주세요.");
        setTextModal(true);
      } else if (editTrackingNumber.includes(" ")) {
        setTextMsg("운송장 번호의 공백문자를 삭제해주세요.");
        setTextModal(true);
      } else {
        // 배송정보(택배사, 운송장번호) 변경
        changeShopDeliveryState(
          idx,
          editCourierCompany,
          courierCode,
          editTrackingNumber,
        )
          .then((res) => {
            if (res.status) {
              setTextMsg("배송 정보 수정이 완료되었습니다.");
              setRefreshModal(true);
            } else {
              setTextMsg(res.msg || "잠시후 다시 시도해주세요.");
              setTextModal(true);
            }
          })
          .catch(() => {
            setTextMsg("E01\n잠시후 다시 시도해주세요.");
            setTextModal(true);
          });
      }
    }
  }

  // 운송장번호 변경
  const handleInputChange = (e) => {
    if (gubun === "write") {
      setTrackingNumber(e.target.value);
    } else {
      setEditTrackingNumber(e.target.value);
    }
  };
  
  const onClose = () => {
    setModal(false);
    window.location.reload();
  };

  useEffect(() => {
    getCourierList();
  }, []);

  return (
    <>
      {modal ? (
        <ModalBg>
          <ModalConts>
            <CancelBtn onClick={onClose}>
              <img src={close} />
            </CancelBtn>
            <FlexBox
              direction={"column"}
              justify={"flex-start"}
              style={{ height: "220px" }}
            >
              <Text
                color={"#1E1E1E"}
                size={"20px"}
                weight={"700"}
                style={{
                  whiteSpace: "pre-wrap",
                  lineHeight: "22px",
                  marginBottom: "20px",
                }}
              >
                {title}
              </Text>
              <div>
                <FlexBox
                  justify={"space-between"}
                  direction={"column"}
                  directionMd={"row"}
                  align={"flex-start"}
                  alignMd={"center"}
                  margin={"0 0 20px"}
                >
                  <Text color={"#666"} weight={"400"} margin={"0 0 10px"} marginMd={"0"}>
                    택배사
                  </Text>
                  <CourierCompanySelect
                    data={courierList}
                    gubun={gubun}
                    setCourierCompany={setCourierCompany}
                    setCourierCode={setCourierCode}
                    editCourierCompany={editCourierCompany}
                    setEditCourierCompany={setEditCourierCompany}
                    width={"180px"}
                  />
                </FlexBox>
                <FlexBox
                  justify={"space-between"}
                  direction={"column"}
                  directionMd={"row"}
                  align={"flex-start"}
                  alignMd={"center"}
                  margin={"0 0 20px"}
                >
                  <Text color={"#666"} weight={"400"} margin={"0 0 10px"} marginMd={"0"}>
                    운송장 번호
                  </Text>
                  <TextInput
                    type={"text"}
                    name={"tracking_number"}
                    value={gubun === "write" ? trackingNumber : editTrackingNumber}
                    onChange={handleInputChange}
                    placeholder="운송장 번호를 입력해주세요."
                    width={"180px"}
                  />
                </FlexBox>
              </div>
            </FlexBox>
            <FlexBox margin={"20px 0 0"} marginMd={"15px 0 0"}>
              <Btn
                background={"#f2f2f2"}
                border={"unset"}
                color={"#666"}
                radius={"6px"}
                weight={"500"}
                margin={"0 10px 0 0"}
                lineHeight={"22px"}
                size={"12px"}
                sizeMd={"14px"}
                padding={"9px 19px"}
                paddingMd={"9px 19px"}
                onClick={onClose}
              >
                취소
              </Btn>
              <Btn
                background={"#FF4A4A"}
                color={"#fff"}
                radius={"8px"}
                weight={"700"}
                size={"14px"}
                padding={"9px 19px"}
                onClick={onSubmit}
              >
                확 인
              </Btn>
            </FlexBox>
            <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            <InfoModal modal={refreshModal} setModal={setRefreshModal} description={textMsg} mode={"refresh"} />
          </ModalConts>
        </ModalBg>
      ) : (
        ""
      )}
    </>
  );
};

const ModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

const ModalConts = styled.div`
  background-color: #fff;
  max-width: 380px;
  /* max-height: 600px; */
  width: 100%;
  padding: 60px 40px 40px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #f2f2f2;

  @media all and (max-width: 1024px) {
    max-width: 280px; // 390
    padding: 40px 20px 30px;
    margin: 0 20px;
  }
`;

const CancelBtn = styled.button`
  position: absolute;
  right: 17.5px;
  top: 17.5px;

  @media all and (max-width: 1024px) {
    top: 12px;
    right: 12px;
  }
`;
