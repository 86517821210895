export const INDIV_SLOT_STATUS_LIST = [
  { value: "unRegistered", text: "미등록" },
  { value: "registered", text: "등록" },
  { value: "onSale", text: "판매중" },
  { value: "adminChecking", text: "검수요청" },
  { value: "reject", text: "검수반려" },
  { value: "refund", text: "환불" },
  { value: "expired", text: "기간만료" },
  { value: "stopSale", text: "판매중지" },
];

export const INDIV_SLOT_STATUS_STOREOWNER_LIST = [
  { value: "unRegistered", text: "미등록" },
  { value: "registered", text: "등록" },
  { value: "onSale", text: "판매중" },
  { value: "adminChecking", text: "검수중" },
  { value: "reject", text: "검수반려" },
  { value: "stopSale", text: "판매중지" },
];

export const WHOLE_SLOT_STATUS_LIST = [
  { value: "used", text: "사용중" },
  { value: "expired", text: "기간만료" },
  { value: "partial_refund", text: "부분환불" },
  { value: "refund", text: "환불" },
];

export const PURCHASE_TYPE_LIST = [
  { value: "one_month", text: "기간형(1개월)" },
  { value: "six_month", text: "기간형(6개월)" },
  { value: "regular_purchase", text: "구독형" },
];

export const SEARCH_TYPE_LIST = [
  { value: "barcode_num", text: "바코드 번호" },
  { value: "product_name", text: "상품명" },
];

export const UNIT_10_TO_30_LIST = [
  { value: 10, text: "10개씩 보기" },
  { value: 20, text: "20개씩 보기" },
  { value: 30, text: "30개씩 보기" },
];

export const UNIT_10_TO_40_LIST = [
  { value: 10, text: "10개씩 보기" },
  { value: 20, text: "20개씩 보기" },
  { value: 30, text: "30개씩 보기" },
  { value: 40, text: "40개씩 보기" },
];

export const UNIT_10_TO_50_LIST = [
  { value: 10, text: "10개씩 보기" },
  { value: 20, text: "20개씩 보기" },
  { value: 30, text: "30개씩 보기" },
  { value: 40, text: "40개씩 보기" },
  { value: 50, text: "50개씩 보기" },
];

export const INSPECTION_STATUS_LIST = [
  { value: "onSale", text: "검수 승인" },
  { value: "reject", text: "검수 반려" },
];

export const DEPOSIT_NOTE_LIST = [
  { value: "product_order", text: "상품 주문" },
  { value: "product_return", text: "상품 반품" },
  { value: "manual_deposit", text: "수동 입금" },
  { value: "auto_deposit", text: "자동 입금" },
  { value: "manual_withdrawal", text: "수동 출금" },
  { value: "point_settlement", text: "포인트 정산" },
  { value: "return_cancellation", text: "반품 취소" },
  { value: "order_cancel", text: "주문 취소" },
  { value: "slot_purchase", text: "슬롯 구매" },
  { value: "slot_refund", text: "슬롯 환불" },
];

export const USER_CATEGORY_LIST = [
  { value: "admin", text: "관리자" },
  { value: "storeonwer", text: "점주" },
];

export const USER_SEARCH_TYPE_LIST = [
  { value: "user_id", text: "아이디" },
  { value: "name", text: "이름" },
];

export const DEPOSIT_SEARCH_STATUS_LIST = [
  { value: "0", text: "입금" },
  { value: "1", text: "출금" },
];
export const PAYMENT_TYPE = [
  { value: "card", text: "카드결제내역" },
  { value: "cash", text: "현금결제내역" },
  { value: "etc", text: "기타결제내역" },
];

export const CUSTOMER_PAYMENT_TYPE = [
  { value: "card", text: "카드" },
  { value: "cash", text: "현금" },
];

export const CATEGORY_1ST = [
  { value: 1, text: "본사" },
  { value: 2, text: "자점매입" },
];

export const DELIVERY_STATUS = [
  { value: "before-delivery", text: "배송전" },
  { value: "on-delivery", text: "배송중" },
  { value: "over-delivery", text: "배송완료" },
  { value: "cancel-order", text: "주문취소" },
];

export const SALE_STATUS_LIST = [
  { value: "all", text: "전체" },
  { value: "on_sale", text: "판매 중" },
];

export const TAKEBACK_STATUS_LIST = [
  { value: "review", text: "검수요청" },
  { value: "complete", text: "검수완료" },
];

export const TAKEBACK_ITEM_STATUS_LIST = [
  { value: "before", text: "미검수" },
  { value: "approval", text: "승인" },
  { value: "part_approval", text: "부분승인" },
  { value: "reject", text: "반려" },
];

export const TAKEBACK_REASON_LIST = [
  { value: "general", text: "일반사항" },
  { value: "flawed", text: "상품 불량" },
  { value: "wrong-delivery", text: "오배송" },
  { value: "cancel-contract", text: "계약해지" },
  { value: "etc", text: "기타사항" },
];

export const INVENTORY_STATUS = [
  { value: "warehousing", text: "입고" },
  { value: "take_back", text: "반품" },
  { value: "disposal", text: "폐기" },
  { value: "lost", text: "분실" },
];

export const NOTICE_STATUS = [
  { value: "all", text: "전체" },
  { value: "storeowner", text: "점주용" },
  { value: "user", text: "고객용" },
];
export const SHOP_ORDER_STATUS_LIST = [
  { value: "non-deposit-cancel", text: "가상계좌 미입금 취소" },
  { value: "waiting-deposit", text: "가상계좌 입금 대기" },
  { value: "before-delivery", text: "배송 전" },
  { value: "on-delivery", text: "배송 중" },
  { value: "over-delivery", text: "배송 완료" },
  { value: "take-back-request", text: "반품 신청" },
  { value: "take-back", text: "반품" },
  { value: "partial-take-back", text: "부분 반품" },
  { value: "take-back-reject", text: "반품 거절" },
  { value: "refund-success", text: "주문 취소" },
];

export const SHOP_TAKEBACK_STATUS_LIST = [
  { value: "check_request", text: "검수요청" },
  { value: "check_completed", text: "검수완료" },
  { value: "deposit_wait", text: "입금대기" },
  { value: "deposit_completed", text: "입금완료" },
  { value: "take_back_cancel", text: "반품취소" },
];

export const SHOP_TAKEBACK_REASON_LIST = [
  { value: "change-mind", text: "단순변심" },
  { value: "wrong-order", text: "주문실수" },
  { value: "flawed", text: "상품 불량" },
  { value: "wrong-delivery", text: "오배송" },
  { value: "etc", text: "기타사항" },
];

export const SHOP_SEARCH_TYPE_LIST = [
  { value: "phone", text: "전화번호" },
  { value: "name", text: "고객명" },
];

export const DIRECT_SELFGOODS_STATUS_LIST = [
  { value: "onSale", text: "판매중" },
  { value: "stopSale", text: "판매중지" },
];

export const DIRECT_SELFGOODS_FRANCHISE = [{ value: 9, text: "옥정연푸른초점" }];

export const COUPON_PRICE_LIST = [
  { value: 1000, text: "1,000원" },
  { value: 3000, text: "3,000원" },
  { value: 5000, text: "5,000원" },
  { value: 10000, text: "10,000원" },
  { value: 50000, text: "50,000원" },
];

export const COUPON_STATUS_LIST = [
  { value: 1, text: "등록" },
  { value: 2, text: "미등록" },
];

export const COUPON_SEARCH_TYPE_LIST = [
  { value: "coupon_num", text: "쿠폰 번호" },
  { value: "phone", text: "휴대폰 번호" },
  { value: "franchise_name", text: "가맹점명" },
];

export const FRANCHISE_STATUS_LIST = [
  { value: "active", text: "정상" },
  { value: "terminated", text: "해지" },
  { value: "service_only", text: "별도계약" },
  { value: "paused", text: "일시정지" },
];

export const PRODUCT_SALE_STATUS_LIST = [
  { value: "", text: "판매상태 전체" },
  { value: "sale", text: "정상" },
  { value: "missing", text: "입고지연" },
  { value: "extinction", text: "단종" },
];
