import React, { useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { postProducts } from "../../Utiles";
import styled from "styled-components";
import {
  ContentContainer,
  Btn,
  Container,
  FlexBox,
  SquareImg,
  Td,
  Text,
  Th,
  Wrapper,
  WhiteBox,
  Title,
  TableWrap,
  Select,
} from "../../Assets/css/commonVer2";
import ico_plus from "../../Assets/images/ico_plus.svg";
import ico_minus from "../../Assets/images/ico_minus.svg";
import ico_minus_disable from "../../Assets/images/ico_minus_disable.svg";
import { TAKEBACK_REASON_LIST } from "../../Utiles/ListData";
import ico_close from "../../Assets/images/ico_close.svg";
import HeaderSub from "../../Components/Header/HeaderSub";
import { AddComma } from "../../Utiles/Custom";
import Authority from "../../Components/Modal/Authority";
import img_noimage from "../../Assets/images/no_image.jpg";
import Loading from "../../Components/Loading";
import AddProductModal from "../../Components/Modal/AddProductModal";
import InfoModal from "../../Components/Modal/InfoModal";

/* code from sub_outsourcing */

// 상품관리 > 반품관리 > 반품등록

export default function AddTakeBack() {
  const navigate = useNavigate();
  const [franchise_id] = useState(sessionStorage.getItem("franchise_id"));
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  // 데이터
  const [takeBackItems, setTakeBackItems] = useState([]); // 반품할 상품 리스트

  const [addingProductModal, setAddingProductModal] = useState(false);
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [goBackModal, setGoBackModal] = useState(false);

  // 반품 상품 등록
  function addTackBackProducts() {
    if (takeBackItems.length > 0) {
      setLoading(true);
      postProducts(franchise_id, takeBackItems)
        .then((res) => {
          setLoading(false);
          if (res.data.status) {
            setTextMsg("등록이 완료되었습니다.");
            setGoBackModal(true);
          } else {
            setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
            setTextModal(true);
          }
        })
        .catch(() => {
          setLoading(false);
          setTextMsg(`E01\n  잠시후 다시 시도해주세요.`);
          setTextModal(true);
        });
    } else {
      setTextMsg("반품할 상품이 존재하지 않습니다.");
      setTextModal(true);
    }
  }

  const addTakeBackItem = (item) => {
    const newTakeBackItem = {
      pieces: 1,
      product_id: item.product_id,
      reason: "general",
      product_name: item.product_name,
      shipping_price: item.shipping_price,
      consumer_price: item.consumer_price,
      total_price: item.shipping_price,
      barcode_num: item.barcode_num,
    };

    const isDuplicate = takeBackItems.some((item) => item.product_id == newTakeBackItem.product_id);

    if (isDuplicate) {
      setTextMsg("이미 추가된 상품입니다.");
      setTextModal(true);
    } else {
      setTakeBackItems((items) => [...items, newTakeBackItem]);
    }
  };

  // 반품 목록에서 삭제 (TODO 받아온 데이터에 id라는 값있으면 product_id -> id)
  const removeReturnItem = (id) => {
    const newItems = takeBackItems.filter((item) => item.product_id !== id);
    setTakeBackItems(newItems);
  };

  // 반품 아이템 상품 갯수 조절
  const plusMinusItem = (action, product_id) => {
    const newItems = [...takeBackItems];
    switch (action) {
      case "plus":
        newItems.forEach((item) => {
          if (item.product_id === product_id) {
            item.pieces += 1;
            item.total_price = item.pieces * item.shipping_price;
          }
        });
        setTakeBackItems(newItems);
        break;

      case "minus":
        newItems.forEach((item) => {
          if (item.product_id === product_id && item.pieces > 1) {
            item.pieces -= 1;
            item.total_price = item.pieces * item.shipping_price;
          }
        });
        setTakeBackItems(newItems);
        break;
    }
  };

  // 반품 사유
  const onChangeReturnReason = (e) => {
    const newItems = [...takeBackItems];
    newItems.forEach((item) => {
      if (item.barcode_num === e.target.id) {
        item.reason = e.target.value;
      }
    });
    setTakeBackItems(newItems);
  };

  const onChangeReason = (barcode_num, reason) => {
    const newItems = [...takeBackItems];
    newItems.forEach((item) => {
      if (item.barcode_num === barcode_num) {
        item.reason = reason;
      }
    });
    setTakeBackItems(newItems);
  };

  // 총 반품 금액
  const totalAmount = useMemo(() => {
    let ammount = 0;
    takeBackItems.forEach((item) => (ammount += item.total_price));
    return ammount;
  }, [takeBackItems]);

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          {loading ? <Loading /> : null}

          <HeaderSub title={"반품 등록"} />
          <ContentContainer>
            <Wrapper type={2} pbM={"112px"} style={{ paddingBottom: "300px" }}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    반품등록
                  </Title>
                )}
                <WhiteBox margin={"0  0"} style={{ paddingBottom: "100px" }}>
                  <FlexBox justify={"space-between"} wrap={"wrap"} gap={"10px"}>
                    {isMobile ? (
                      <>
                        <Text
                          width={"100%"}
                          widthMd={"unset"}
                          color={"#FF4A4A"}
                          size={"16px"}
                          weight={"400"}
                          padding={"10px 29px 10px 27px"}
                          background={"rgba(255, 116, 64, 0.10)"}
                          radius={"8px"}
                          letterSpacing={"-0.32px"}
                          lineHeight={"normal"}
                        >
                          품목 : <span style={{ fontWeight: "700" }}>{takeBackItems.length}개</span>
                        </Text>
                        <Text
                          width={"100%"}
                          widthMd={"unset"}
                          color={"#FF4A4A"}
                          size={"16px"}
                          weight={"400"}
                          padding={"10px 29px 10px 27px"}
                          background={"rgba(255, 116, 64, 0.10)"}
                          radius={"8px"}
                          letterSpacing={"-0.32px"}
                          lineHeight={"normal"}
                        >
                          총 반품 금액 : <span style={{ fontWeight: "700" }}>{AddComma(totalAmount)}원</span>
                        </Text>
                        <Btn
                          width={"100%"}
                          color={"#FF4A4A"}
                          background={"#fff"}
                          radius={"6px"}
                          size={"14px"}
                          sizeMd={"12px"}
                          weight={"400"}
                          hoverWeight={"700"}
                          lineHeight={"22px"}
                          padding={"9px 19px"}
                          margin={"0 0 10px"}
                          onClick={() => {
                            setAddingProductModal(true);
                          }}
                        >
                          상품 추가
                        </Btn>
                      </>
                    ) : (
                      <>
                        <FlexBox wrap={"wrap"} gap={"10px"}>
                          <Text
                            width={"100%"}
                            widthMd={"unset"}
                            color={"#FF4A4A"}
                            size={"16px"}
                            weight={"400"}
                            padding={"10px 29px 10px 27px"}
                            background={"rgba(255, 116, 64, 0.10)"}
                            radius={"8px"}
                            letterSpacing={"-0.32px"}
                            lineHeight={"normal"}
                          >
                            품목 : <span style={{ fontWeight: "700" }}>{takeBackItems.length}개</span>
                          </Text>
                          <Text
                            width={"100%"}
                            widthMd={"unset"}
                            color={"#FF4A4A"}
                            size={"16px"}
                            weight={"400"}
                            padding={"10px 29px 10px 27px"}
                            background={"rgba(255, 116, 64, 0.10)"}
                            radius={"8px"}
                            letterSpacing={"-0.32px"}
                            lineHeight={"normal"}
                          >
                            총 반품 금액 : <span style={{ fontWeight: "700" }}>{AddComma(totalAmount)}원</span>
                          </Text>
                        </FlexBox>
                        <FlexBox justify={"end"} gap={"10px"} wrap={"wrap"}>
                          <Btn
                            color={"#FF4A4A"}
                            background={"#fff"}
                            size={"14px"}
                            lineHeight={"22px"}
                            padding={"9px 19px"}
                            onClick={() => {
                              setAddingProductModal(true);
                            }}
                          >
                            상품 추가
                          </Btn>
                          <Btn
                            color={"#fff"}
                            background={"#FF4A4A"}
                            size={"14px"}
                            weight={"700"}
                            lineHeight={"22px"}
                            padding={"9px 19px"}
                            onClick={addTackBackProducts}
                          >
                            등록 완료
                          </Btn>
                        </FlexBox>
                      </>
                    )}
                  </FlexBox>
                  <ReturnTableWrap>
                    {/** 1트 */}
                    <table style={{ overflow: "hidden" }}>
                      <colgroup className="d-none d-lg-table-column-group">
                        <col style={{ width: "5%" }} />
                        <col />
                        <col style={{ width: "13%" }} />
                        <col style={{ width: "13%" }} />
                        <col style={{ width: "24%" }} />
                        <col style={{ width: "10.5%" }} />
                        <col style={{ width: "8%" }} />
                      </colgroup>

                      <thead className="d-none d-lg-table-head">
                        <tr>
                          <Th>No</Th>
                          <Th>상품명</Th>
                          <Th>상품 금액</Th>
                          <Th>반품 수량</Th>
                          <Th>반품 사유</Th>
                          <Th>총 반품 금액</Th>
                          <Th>삭제</Th>
                        </tr>
                      </thead>

                      <tbody>
                        {takeBackItems.length !== 0 ? (
                          takeBackItems.map((e, i, arr) => (
                            <tr key={i}>
                              <Td
                                className="d-none d-lg-table-cell"
                                style={{
                                  borderBottom: "1px solid #F2F2F2",
                                }}
                              >
                                {i + 1}
                              </Td>

                              <Td
                                className="d-none d-lg-table-cell"
                                align={"left"}
                                style={{
                                  borderBottom: "1px solid #F2F2F2",
                                }}
                              >
                                <FlexBox justify={"flex-start"} gap={"10px"}>
                                  <ShopImg>
                                    <SquareImg>
                                      <div>
                                        <img
                                          src={
                                            e.image_path_1
                                              ? `${process.env.REACT_APP_S3_KEY}${e.image_path_1}`
                                              : img_noimage
                                          }
                                        />
                                      </div>
                                    </SquareImg>
                                  </ShopImg>

                                  <ShopText>
                                    <Text size={"10px"} color={"#FF4A4A"} align={"left"}>
                                      {e.brand}
                                    </Text>

                                    <Text size={"16px"} weight={"500"} color={"#1e1e1e"} align={"left"}>
                                      {e.product_name}
                                    </Text>

                                    <FlexBox justify={"flex-start"} gap={"10px"}>
                                      <Text size={"14px"} color={"#666"} align={"left"}>
                                        {e.barcode_num}
                                      </Text>
                                      <Text size={"14px"} color={"#666"} align={"left"}>
                                        {e.unit}
                                      </Text>
                                    </FlexBox>
                                  </ShopText>
                                </FlexBox>
                              </Td>

                              <Td
                                className="d-none d-lg-table-cell"
                                align={"right"}
                                style={{
                                  borderBottom: "1px solid #F2F2F2",
                                }}
                              >
                                <Text size={"12px"} color={"#2d2d2d"} align={"right"}>
                                  (공급가) {AddComma(e.shipping_price)}원
                                </Text>
                                <Text size={"12px"} color={"#6c6c6c"} align={"right"} padding={"8px 0 0"}>
                                  (소비자가) {AddComma(e.consumer_price)}원
                                </Text>
                              </Td>

                              <Td
                                className="d-none d-lg-table-cell"
                                align={"center"}
                                style={{
                                  borderBottom: "1px solid #F2F2F2",
                                }}
                              >
                                <FlexBox
                                  width={"auto"}
                                  style={{
                                    border: "1px solid #c0c0c0",
                                    borderRadius: "6px",
                                  }}
                                  justify={"space-evenly"}
                                  margin={"auto"}
                                >
                                  <FlexBox
                                    style={{
                                      borderRight: "1px solid #c0c0c0",
                                      padding: "6px",
                                    }}
                                  >
                                    <img
                                      src={e.pieces > 1 ? ico_minus : ico_minus_disable}
                                      onClick={() => plusMinusItem("minus", e.product_id)}
                                    />
                                  </FlexBox>
                                  <Text padding="0 10px" family="Montserrat" weight="700" color={"#1E1E1E"}>
                                    {e.pieces}
                                  </Text>
                                  <FlexBox
                                    style={{
                                      borderLeft: "1px solid #c0c0c0",
                                      padding: "6px",
                                    }}
                                  >
                                    <img src={ico_plus} onClick={() => plusMinusItem("plus", e.product_id)} />
                                  </FlexBox>
                                </FlexBox>
                              </Td>

                              <Td
                                className="d-none d-lg-table-cell"
                                align={"center"}
                                style={{
                                  borderBottom: "1px solid #F2F2F2",
                                  position: "relative",
                                }}
                              >
                                <Select
                                  id={e.barcode_num}
                                  onChange={(event) => {
                                    onChangeReturnReason(event);
                                    event.target.blur();
                                  }}
                                  style={{ width: "100%" }}
                                >
                                  {TAKEBACK_REASON_LIST?.map((e, i) => (
                                    <option key={i} value={e.value}>
                                      {e.text}
                                    </option>
                                  ))}
                                </Select>
                              </Td>

                              <Td
                                className="d-none d-lg-table-cell"
                                align={"right"}
                                style={{
                                  borderBottom: "1px solid #F2F2F2",
                                }}
                              >
                                {AddComma(e.total_price)}원
                              </Td>

                              <Td
                                className="d-none d-lg-table-cell"
                                align={"center"}
                                style={{
                                  borderBottom: "1px solid #F2F2F2",
                                }}
                              >
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                  <img
                                    onClick={() => removeReturnItem(e.product_id)}
                                    src={ico_close}
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </Td>

                              <Tdm width={"100%"} className="d-block d-lg-none">
                                <FlexBox justify={"flex-start"} gap={"10px"} style={{ position: "relative" }}>
                                  <TrashBtn>
                                    <img
                                      onClick={() => removeReturnItem(e.product_id)}
                                      src={ico_close}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </TrashBtn>
                                  <ShopImg>
                                    <SquareImg>
                                      <div>
                                        <img
                                          src={
                                            e.image_path_1
                                              ? `${process.env.REACT_APP_S3_KEY}${e.image_path_1}`
                                              : img_noimage
                                          }
                                        />
                                      </div>
                                    </SquareImg>
                                  </ShopImg>
                                  <ShopText>
                                    <Text size={"12px"} color={"#969696"} align={"left"}>
                                      {e.brand}
                                    </Text>
                                    <Text size={"14px"} weight={"500"} color={"#6c6c6c"} align={"left"}>
                                      {e.product_name}
                                    </Text>
                                    <FlexBox justify={"flex-start"} gap={"10px"}>
                                      <Text size={"14px"} color={"#6c6c6c"} align={"left"}>
                                        {e.barcode_num}
                                      </Text>
                                      <Text size={"12px"} color={"#6c6c6c"} align={"left"}>
                                        {e.unit}
                                      </Text>
                                    </FlexBox>
                                  </ShopText>
                                </FlexBox>

                                <FlexBox justify={"space-between"} margin={"10px 0"}>
                                  <FlexBox
                                    style={{
                                      border: "1px solid #c0c0c0",
                                      borderRadius: "6px",
                                    }}
                                    justify={"space-evenly"}
                                  >
                                    <FlexBox
                                      style={{
                                        borderRight: "1px solid #c0c0c0",
                                        padding: "6px",
                                      }}
                                    >
                                      <img
                                        src={e.pieces > 1 ? ico_minus : ico_minus_disable}
                                        onClick={() => plusMinusItem("minus", e.product_id)}
                                      />
                                    </FlexBox>
                                    <Text padding="0 10px" family="Montserrat" weight="700" color={"#1E1E1E"}>
                                      {e.pieces}
                                    </Text>
                                    <FlexBox
                                      style={{
                                        borderLeft: "1px solid #c0c0c0",
                                        padding: "6px",
                                      }}
                                    >
                                      <img src={ico_plus} onClick={() => plusMinusItem("plus", e.product_id)} />
                                    </FlexBox>
                                  </FlexBox>

                                  <div>
                                    <Text size={"12px"} color={"#2d2d2d"} align={"right"}>
                                      (공급가) {AddComma(e.shipping_price)}원
                                    </Text>

                                    <Text size={"12px"} color={"#6c6c6c"} align={"right"} padding={"2px 0 0"}>
                                      (소비자가) {AddComma(e.consumer_price)}원
                                    </Text>
                                  </div>
                                </FlexBox>

                                <FlexBox
                                  justify={"space-between"}
                                  padding={"10px 0"}
                                  style={{ borderTop: "1px solid #eaeaea" }}
                                >
                                  <Text color={"#2d2d2d"} size={"12px"}>
                                    총 반품 금액
                                  </Text>

                                  <Text color={"#dd1212"} size={"14px"} weight={"500"}>
                                    {AddComma(e.total_price)}원
                                  </Text>
                                </FlexBox>
                                <Select
                                  id={e.barcode_num}
                                  onChange={(event) => {
                                    onChangeReturnReason(event);
                                    event.target.blur();
                                  }}
                                  style={{ width: "100%" }}
                                >
                                  {TAKEBACK_REASON_LIST?.map((e, i) => (
                                    <option key={i} value={e.value}>
                                      {e.text}
                                    </option>
                                  ))}
                                </Select>
                              </Tdm>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <Td colSpan={"7"} size={"14px"} color={"#6c6c6c"} align={"center"} padding={"20px 0"}>
                              추가된 상품이 없습니다.
                            </Td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </ReturnTableWrap>
                </WhiteBox>

                {isMobile && (
                  <Btn
                    color={"#fff"}
                    background={"#FF4A4A"}
                    radius={"6px"}
                    size={"14px"}
                    sizeMd={"12px"}
                    weight={"700"}
                    lineHeight={"22px"}
                    padding={"9px 19px"}
                    width={"100%"}
                    onClick={addTackBackProducts}
                    margin={"20px 0 "}
                  >
                    등록 완료
                  </Btn>
                )}
              </Container>
              <AddProductModal
                modal={addingProductModal}
                setModal={setAddingProductModal}
                onSubmit={(item) => addTakeBackItem(item)}
              />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
              <InfoModal modal={goBackModal} setModal={setGoBackModal} description={textMsg} mode={"goBack"} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const ReturnTableWrap = styled(TableWrap)`
  table {
    // width: unset;
    width: 100%;
  }
  @media all and (max-width: 991px) {
    table {
      // width: unset;
      min-width: 100%;
    }
  }
`;

const Tdm = styled(Td)`
  @media all and (max-width: 991px) {
    border: 1px solid #eaeaea;
    border-radius: 8px;
    background-color: #fff;
  }
`;
const ShopImg = styled.div`
  width: 48px;
  border: 1px solid #eaeaea;
  border-radius: 10px;
`;
const ShopText = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media all and (max-width: 991px) {
    gap: 0;
  }
`;
const TrashBtn = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
`;
