import styled from "styled-components";
import ico_select from "../images/ico_select_arr.png";
import ico_cal from "../images/ico_cal.png";
import ico_time from "../images/ico_time.png";
import ico_pgprev from "../images/ico_page_prev.png";
import ico_pgnext from "../images/ico_page_next.png";

/*
  공통으로 tailwind CSS 처럼 쓰이는 스타일 :
  @mediaQuery min-width (1201, 992, 768) 로 잡고,
  개별적으로 잡은 스타일 :
  @mediaQuery max-width (1200, 991, 767) 로 잡았습니다.
*/

export const Wrapper = styled.div`
  padding-top: ${(props) => (props.type === 1 ? "96px" : "144px")};
  padding-bottom: 100px;

  @media only screen and (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: ${(props) => props.pbM || "60px"};
  }
`;

export const AlarmHead = styled(Wrapper)`
  padding-top: ${(props) => (props.type === 1 ? "160px" : "210px")};
  min-height: 100vh;
  background-color: #fffae6;

  @media all and (max-width: 767px) {
    padding-top: 100px;
    background-color: transparent;
  }
`;
export const WrapperYellow = styled(Wrapper)`
  padding-top: ${(props) => (props.type === 1 ? "160px" : "210px")};
  min-height: 100vh;
  background-color: #fffae6;

  @media all and (max-width: 767px) {
    padding-top: 146px;
    background-color: transparent;
  }
`;
export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 0 30px;
  margin: 0 auto;

  @media all and (max-width: 767px) {
    padding: 0 16px;
  }
`;
// 사이 gap 20px
export const LeftArea = styled.div`
  width: calc(100% - 340px);

  @media all and (max-width: 991px) {
    width: calc(100% - 310px);
  }
  @media all and (max-width: 767px) {
    width: 100%;
  }
`;
export const RightArea = styled.div`
  width: 320px;

  @media all and (max-width: 991px) {
    width: 300px;
  }
  @media all and (max-width: 767px) {
    width: 100%;
  }
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || "center"};
  align-items: ${(props) => props.align || "center"};
  flex-wrap: ${(props) => props.wrap || "nowrap"};
  gap: ${(props) => props.gap ?? props.gap};
  flex-direction: ${(props) => props.direction || "row"};
  flex: ${(props) => props.flex ?? props.flex};
  width: ${(props) => props.width ?? props.width};
  margin: ${(props) => props.margin ?? props.margin};
  padding: ${(props) => props.padding ?? props.padding};
  background-color: ${(props) => props.background ?? props.background};
  border-radius: ${(props) => props.radius ?? props.radius};
  border: ${(props) => props.border ?? props.border};

  @media only screen and (min-width: 768px) {
    justify-content: ${(props) => props.justifyMd ?? props.justifyMd};
    align-items: ${(props) => props.alignMd ?? props.alignMd};
    flex-wrap: ${(props) => props.wrapMd ?? props.wrapMd};
    gap: ${(props) => props.gapMd ?? props.gapMd};
    flex-direction: ${(props) => props.directionMd ?? props.directionMd};
    flex: ${(props) => props.flexMd ?? props.flexMd};
    width: ${(props) => props.widthMd ?? props.widthMd};
    margin: ${(props) => props.marginMd ?? props.marginMd};
    padding: ${(props) => props.paddingMd ?? props.paddingMd};
  }
  @media (min-width: 992px) {
    justify-content: ${(props) => props.justifyLg ?? props.justifyLg};
    align-items: ${(props) => props.alignLg ?? props.alignLg};
    flex-wrap: ${(props) => props.wrapLg ?? props.wrapLg};
    gap: ${(props) => props.gapLg ?? props.gapLg};
    flex-direction: ${(props) => props.directionLg ?? props.directionLg};
    flex: ${(props) => props.flexLg ?? props.flexLg};
    width: ${(props) => props.widthLg ?? props.widthLg};
    margin: ${(props) => props.marginLg ?? props.marginLg};
    padding: ${(props) => props.paddingLg ?? props.paddingLg};
  }
  @media (min-width: 1201px) {
    justify-content: ${(props) => props.justifyXl ?? props.justifyXl};
    align-items: ${(props) => props.alignXl ?? props.alignXl};
    flex-wrap: ${(props) => props.wrapXl ?? props.wrapXl};
    gap: ${(props) => props.gapXl ?? props.gapXl};
    flex-direction: ${(props) => props.directionXl ?? props.directionXl};
    flex: ${(props) => props.flexXl ?? props.flexXl};
    width: ${(props) => props.widthXl ?? props.widthXl};
    margin: ${(props) => props.marginXl ?? props.marginXl};
    padding: ${(props) => props.paddingXl ?? props.paddingXl};
  }
`;
export const Title = styled.h3`
  font-family: ${(props) => props.family ?? props.family};
  font-size: ${(props) => props.size || "14px"};
  font-weight: ${(props) => props.weight || "400"};
  color: ${(props) => props.color || "#6C6C6C"};
  padding: ${(props) => props.padding || "0"};

  @media (min-width: 768px) {
    font-size: ${(props) => props.sizeMd ?? props.sizeMd};
    font-weight: ${(props) => props.weightMd ?? props.weightMd};
    color: ${(props) => props.colorMd ?? props.colorMd};
    padding: ${(props) => props.paddingMd ?? props.paddingMd};
  }
  @media (min-width: 992px) {
    font-size: ${(props) => props.sizeLg ?? props.sizeLg};
    font-weight: ${(props) => props.weightLg ?? props.weightLg};
    color: ${(props) => props.colorLg ?? props.colorLg};
    padding: ${(props) => props.paddingLg ?? props.paddingLg};
  }
`;
export const Text = styled.p`
  font-family: ${(props) => props.family ?? props.family};
  font-size: ${(props) => props.size || "14px"};
  font-weight: ${(props) => props.weight || "400"};
  color: ${(props) => props.color || "#666666"};
  text-align: ${(props) => props.align || "center"};
  padding: ${(props) => props.padding || "0"};
  background-color: ${(props) => props.background || ""};
  margin: ${(props) => props.margin || "0"};
  border-radius: ${(props) => props.radius || "0"};
  width: ${(props) => props.width ?? props.width};
  opacity: ${(props) => props.opacity ?? "1"};

  line-height: ${(props) => props.lineHeight ?? "normal"};
  letter-spacing: ${(props) => props.letterSpacing ?? "normal"};
  &.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (min-width: 768px) {
    font-size: ${(props) => props.sizeMd ?? props.sizeMd};
    font-weight: ${(props) => props.weightMd ?? props.weightMd};
    color: ${(props) => props.colorMd ?? props.colorMd};
    padding: ${(props) => props.paddingMd ?? props.paddingMd};
    text-align: ${(props) => props.alignMd ?? props.alignMd};
    margin: ${(props) => props.marginMd ?? props.marginMd};
    border-radius: ${(props) => props.radiusMd ?? props.radiusMd};
    width: ${(props) => props.widthMd ?? props.widthMd};
  }
  @media (min-width: 992px) {
    font-size: ${(props) => props.sizeLg ?? props.sizeLg};
    font-weight: ${(props) => props.weightLg ?? props.weightLg};
    color: ${(props) => props.colorLg ?? props.colorLg};
    padding: ${(props) => props.paddingLg ?? props.paddingLg};
    text-align: ${(props) => props.alignLg ?? props.alignLg};
    margin: ${(props) => props.marginLg ?? props.marginLg};
    border-radius: ${(props) => props.radiusLg ?? props.radiusLg};
  }
`;
export const Btn = styled.button`
  font-family: ${(props) => props.family ?? props.family};
  font-size: ${(props) => props.size || "14px"};
  font-weight: ${(props) => props.weight || "400"};
  color: ${(props) => props.color || "#6C6C6C"};
  text-align: ${(props) => props.align || "center"};
  padding: ${(props) => props.padding || "0"};
  border: ${(props) => props.border ?? props.border};
  border-radius: ${(props) => props.radius || "0"};
  background: ${(props) => props.background || "transparent"};
  margin: ${(props) => props.margin || "0"};
  width: ${(props) => props.width ?? props.width};
  line-height: ${(props) => props.lineHeight ?? "normal"};
  letter-spacing: ${(props) => props.letterSpacing ?? "normal"};

  @media (min-width: 768px) {
    font-size: ${(props) => props.sizeMd ?? props.sizeMd};
    font-weight: ${(props) => props.weightMd ?? props.weightMd};
    color: ${(props) => props.colorMd ?? props.colorMd};
    text-align: ${(props) => props.alignMd ?? props.alignMd};
    padding: ${(props) => props.paddingMd ?? props.paddingMd};
    border: ${(props) => props.borderMd ?? props.borderMd};
    border-radius: ${(props) => props.radiusMd ?? props.radiusMd};
    margin: ${(props) => props.marginMd ?? props.marginMd};
    width: ${(props) => props.widthMd ?? props.widthMd};
  }
  @media (min-width: 992px) {
    font-size: ${(props) => props.sizeLg ?? props.sizeLg};
    font-weight: ${(props) => props.weightLg ?? props.weightLg};
    color: ${(props) => props.colorLg ?? props.colorLg};
    text-align: ${(props) => props.alignLg ?? props.alignLg};
    padding: ${(props) => props.paddingLg ?? props.paddingLg};
    margin: ${(props) => props.marginLg ?? props.marginLg};
    border: ${(props) => props.borderLg ?? props.borderLg};
    border-radius: ${(props) => props.radiusLg ?? props.radiusLg};
    width: ${(props) => props.widthLg ?? props.widthLg};
  }
`;
export const SquareImg = styled.div`
  border: ${(props) => props.border && "1px solid #eaeaea"};
  border-radius: 8px;
  overflow: hidden;

  div {
    position: relative;
    padding-bottom: 100%;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 100%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
    }
  }
`;

/* Table */
export const TableWrap = styled.div`
  overflow-y: auto;
  table {
    min-width: 1140px;
  }
`;
export const Th = styled.th`
  padding: ${(props) => props.padding || "10px"};
  background-color: ${(props) => props.bgColor || ""};
  color: ${(props) => props.color || "#2d2d2d"};
  font-size: ${(props) => props.size || "14px"};
  font-weight: ${(props) => props.weight || "500"};
  margin: ${(props) => props.margin || "0"};
  text-align: ${(props) => props.align || "center"};
`;
export const Td = styled.td`
  padding: ${(props) => props.padding || "10px"};
  background-color: ${(props) => props.bgColor || ""};
  color: ${(props) => props.color || "#2d2d2d"};
  font-size: ${(props) => props.size || "14px"};
  font-weight: ${(props) => props.weight || "500"};
  margin: ${(props) => props.margin || "0"};
  text-align: ${(props) => props.align || "center"};
`;

/* Input Custom */
export const TextInput = styled.input`
  height: 40px;
  outline: none;
  font-size: ${(props) => props.size || "14px"};
  font-weight: ${(props) => props.weight || "400"};
  color: ${(props) => props.color || "#6c6c6c"};
  padding: ${(props) => props.padding || "0 12px"};
  border: ${(props) => props.border || "1px solid #c0c0c0"};
  border-radius: ${(props) => props.radius || "6px"};
  text-align: ${(props) => props.align || "left"};
  &::placeholder {
    color: #c0c0c0;
  }
  &:disabled {
    color: #6c6c6c;
    background-color: #eaeaea;
  }

  @media all and (max-width: 767px) {
    height: 36px;
  }
`;
export const Select = styled.select`
  height: 40px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  cursor: pointer;
  background: #fff url(${ico_select}) no-repeat right 13px center;
  font-size: ${(props) => props.size || "14px"};
  font-weight: ${(props) => props.weight || "400"};
  color: ${(props) => props.color || "#6c6c6c"};
  padding: ${(props) => props.padding || "0px 36px 0px 12px"};
  border: ${(props) => props.border || "1px solid #c0c0c0"};
  border-radius: ${(props) => props.radius || "6px"};
  text-align: ${(props) => props.align || "left"};

  &:disabled {
    color: #6c6c6c;
    background-color: #eaeaea;
  }
  &::-ms-expand {
    display: none;
  }

  @media all and (max-width: 767px) {
    height: 36px;
  }
`;
export const TextArea = styled.textarea`
  resize: none;
  outline: none;
  font-size: ${(props) => props.size || "14px"};
  font-weight: ${(props) => props.weight || "400"};
  color: ${(props) => props.color || "#6c6c6c"};
  padding: ${(props) => props.padding || "9px 12px"};
  border: ${(props) => props.border || "1px solid #c0c0c0"};
  border-radius: ${(props) => props.radius || "6px"};
  text-align: ${(props) => props.align || "left"};
  height: ${(props) => props.height || "auto"};

  &::placeholder {
    color: #c0c0c0;
  }
  &:disabled {
    color: #6c6c6c;
    background-color: #eaeaea;
  }
`;

export const CheckIcon = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid #eaeaea;
  background-color: #fff;

  svg {
    fill: none;
    stroke: white;
    stroke-width: 3px;
    border-radius: 50%;
  }
`;

export const DisableCheckIcon = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid #eaeaea;
  background-color: #eaeaea;

  svg {
    fill: none;
    stroke: #eaeaea;
    stroke-width: 3px;
    border-radius: 50%;
  }
`;

export const CheckLabel = styled.label`
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;

  input {
    position: absolute;
    display: none;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    opacity: 0;
    visibility: hidden;

    &:checked + ${CheckIcon} {
      border-color: #dd1212;
      background-color: #dd1212;
    }
  }

  @media all and (max-width: 767px) {
    gap: 4px;
  }
`;
export const ToggleLabel = styled.label`
  cursor: pointer;

  & * {
    transition: all 0.3s ease-in;
  }

  .bg {
    position: relative;
    display: block;
    width: 56px;
    height: 32px;
    border-radius: 18px;
    background-color: #eaeaea;

    .circle {
      position: absolute;
      top: 3px;
      left: 3px;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 1px 0 2px rgba(0, 0, 0, 0.1);
    }
  }
  input {
    position: absolute;
    display: none;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    opacity: 0;
    visibility: hidden;

    &:checked + .bg {
      background-color: #dd1212;
      .circle {
        left: calc(100% - 29px);
        box-shadow: -1px 0 2px rgba(0, 0, 0, 0.1);
      }
    }
  }
`;

export const DateInputBox = styled.div`
  position: relative;
  max-width: 100px;
  height: 26px;
  padding-bottom: 30px;
  line-height: 1;

  &::before {
    content: "";
    position: absolute;
    top: 46%;
    left: 8px;
    display: block;
    width: 12px;
    height: 12px;
    z-index: 2;
    background: url(${ico_cal}) no-repeat center center / contain;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    z-index: 2;
  }
  input {
    width: 100%;
    height: 24px;
    padding: 0 9px 0 22px;
    border: none;
    border-radius: 4px;
    background-color: #eaeaea;
    font-size: 12px;
    color: #6c6c6c;
    text-align: center;
  }
`;

export const MinusBtn = styled(Btn)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #ef6f6f;

  &:disabled {
    background-color: #fcb1b1;
  }
  i {
    position: relative;
    display: block;
    width: 10px;
    height: 10px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background-color: #fff;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
    }
  }
`;
export const PlusBtn = styled(MinusBtn)`
  i {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 1px;
      height: 100%;
      background-color: #fff;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
    }
  }
`;
export const NumberInput = styled.input`
  width: 60px;
  border: none;
  outline: none;
  font-size: 12px;
  color: #2d2d2d;
  font-weight: 500;
  text-align: center;

  &:disabled {
    color: #c0c0c0;
  }
`;

export const SelectBtn = styled(Btn)`
  position: relative;
  width: 100%;
  height: 40px;
  padding: 0 12px;
  border: 1px solid #c0c0c0;
  border-radius: 6px;
  background: #fff url(${ico_select}) no-repeat right 13px center;
  text-align: left;

  @media all and (max-width: 767px) {
    height: 36px;
  }
`;

/* pager */
export const PagerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.margin ?? props.margin};

  a {
    display: block;
    padding: 5px 8px;
    font-size: 12px;
    color: #969696;

    &.active {
      color: #2d2d2d;
    }
    &.page_prev {
      padding: 0;
      i {
        display: block;
        width: 24px;
        height: 24px;
        background: url(${ico_pgprev}) no-repeat center center / contain;
      }
    }
    &.page_next {
      padding: 0;
      i {
        display: block;
        width: 24px;
        height: 24px;
        background: url(${ico_pgnext}) no-repeat center center / contain;
      }
    }
  }
`;
export const Line = styled.div`
  border-bottom: 1px solid #969696;
  width: 100%;
`;

export const FilterWrap = styled(FlexBox)`
  & > div:first-of-type {
    gap: 16px;
  }
  & > div:last-of-type {
    flex: 1;
    & > div {
      padding: 9px 10px 8px;
    }
  }
  & > input,
  & > select {
    flex: 0.3;
    max-width: 160px;
  }

  @media all and (max-width: 1200px) {
    flex-wrap: wrap;

    & > div:last-of-type {
      flex: auto;
      width: 100%;
    }
    & > input,
    & > select {
      flex: 1;
      max-width: 100%;
    }
  }
  @media all and (max-width: 767px) {
    margin-bottom: 10px;
    & > div:first-of-type {
      flex-wrap: wrap;
      flex: 1;
      gap: 5px;
      & > div:first-of-type {
        width: 100%;
        button {
          flex: 1;
        }
      }
      & > div:last-of-type {
        flex: 1;
        & > div:not(:nth-of-type(2)) {
          flex: 1;
          max-width: 100%;
        }
      }
    }
    & > div:last-of-type {
      & > div {
        padding: 7px 10px 6px;
      }
    }
    & > input,
    & > select {
      flex: auto;
      width: 100%;
    }
  }
`;

export const TextBar = styled(Text)`
  position: relative;
  padding-left: 10px;
  margin-top: 4px;
  word-break: keep-all;
  color: ${(props) => props.color || "#666"};
  font-weight: ${(props) => props.weight || "400"};
  line-height: ${(props) => props.lineHeight ?? "25px"};
  opacity: ${(props) => props.opacity ?? "unset"};

  &::before {
    content: "*";
    position: absolute;
    top: 0;
    left: 0;
  }

  @media all and (max-width: 767px) {
    margin-top: 4px;
  }
`;

export const SelectBox = styled.div`
  position: relative;
  flex: 1;

  @media all and (max-width: 768px) {
    width: 100%;
    flex: unset;
  }
`;

export const Contents = styled.div`
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  background-color: ${(props) => props.background || ""};
  width: ${(props) => props.width || "100%"};
  border-radius: ${(props) => props.radius || "8px"};
  display: ${(props) => props.display || "block"};
  justify-content: ${(props) => props.justify ?? props.justify};
  align-items: ${(props) => props.align ?? props.align};

  @media all and (min-width: 768px) {
    margin: ${(props) => props.marginMd || "0"};
    padding: ${(props) => props.paddingMd || "0"};
  }
`;
