import React from "react";
import styled from "styled-components";
import close from "../../Assets/images/close.png";
import { Btn, FlexBox, Text, TextInput } from "../../Assets/css/commonVer2";
import { AddComma } from "../../Utiles/Custom";
import ConfirmModal from "./ConfirmModalVer2";
import FranSelect from "../Select/FranSelect";

export default function DepositModal(props) {
  const {
    modal,
    setModal,
    setFranchise,
    setContent,
    setName,
    money,
    setMoney,
    saveModal,
    setSaveModal,
    modalFranText,
    setModalFranText,
    modalFranShow,
    setModalFranShow,
    onSubmit,
    putCartClick,
  } = props;

  const onClose = () => {
    setModal(false);
  };

  return (
    <>
      {modal && !saveModal ? (
        <ModalBg>
          <ModalConts>
            <CancelBtn onClick={onClose}>
              <img src={close} />
            </CancelBtn>
            <Text
              color={"#1E1E1E"}
              size={"20px"}
              weight={"700"}
              style={{
                whiteSpace: "pre-wrap",
                lineHeight: "22px",
              }}
            >
              예치금 등록
            </Text>
            <FlexBox direction={"column"} gap={"10px"} margin={"10px 0 22px"}>
              <FranSelect
                width={"100%"}
                gubun={"fran"}
                selectBtnText={modalFranText}
                setTextChange={setModalFranText}
                placeholder={"가맹점을"}
                show={modalFranShow}
                setFranShow={setModalFranShow}
                setIdxChange={setFranchise}
              />
              <TextInput
                type={"text"}
                placeholder={"내용을 입력해주세요."}
                style={{ width: "100%" }}
                onChange={(e) => {
                  setContent(e.target.value);
                }}
              />
              <TextInput
                type={"text"}
                placeholder={"예금주를 입력해주세요."}
                style={{ width: "100%" }}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <div style={{ width: "100%", position: "relative" }}>
                <TextInput
                  type={"text"}
                  placeholder={"0"}
                  align={"right"}
                  padding={"10px 25px 10px 15px"}
                  value={money ? AddComma(money.replaceAll(",", "")) : money}
                  onChange={(e) => {
                    setMoney(e.target.value);
                  }}
                />
                <Text
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "13px",
                  }}
                >
                  원
                </Text>
              </div>
            </FlexBox>
            <FlexBox>
              <Btn
                background={"#f2f2f2"}
                color={"#666"}
                border={"none"}
                weight={"500"}
                margin={"0 10px 0 0"}
                size={"12px"}
                sizeMd={"14px"}
                padding={"9px 19px"}
                paddingMd={"9px 19px"}
                onClick={() => onClose()}
              >
                취 소
              </Btn>
              <Btn
                background={"#FF4A4A"}
                color={"#fff"}
                border={"none"}
                weight={"700"}
                size={"12px"}
                sizeMd={"14px"}
                padding={"9px 19px"}
                paddingMd={"9px 19px"}
                onClick={() => putCartClick()}
              >
                저 장
              </Btn>
            </FlexBox>
          </ModalConts>
        </ModalBg>
      ) : (
        ""
      )}
      <ConfirmModal
        modal={saveModal}
        setModal={setSaveModal}
        onSubmit={() => onSubmit()}
        description={"예치금을 등록하시겠습니까?"}
      />
    </>
  );
}

const ModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

const ModalConts = styled.div`
  background-color: #fff;
  max-width: 380px;
  max-height: 600px;
  width: 100%;
  padding: 60px 40px 40px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #f2f2f2;

  @media all and (max-width: 1024px) {
    max-width: 280px; // 390
    padding: 40px 20px 30px;
    margin: 0 20px;
  }
`;

const CancelBtn = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;

  @media all and (max-width: 1024px) {
    top: 12px;
    right: 12px;
  }
`;
