import API from "./API";

/* code from sub_outsourcing */

// 상품 정보 가지고 오는 API
export const getProduct = async (barcode_num) => {
  try {
    const result = await API.get("/storeowner/product/take-back-registration", {
      params: { barcode_num: barcode_num },
    });

    return result.data;
  } catch (error) {
    return error;
  }
};

//상품 이름 검색하는 API
export const getProductName = async (parameter) => {
  try {
    const result = await API.get(
      "/admin/product/name?product_name=" + parameter + "&scope=idx,barcode_num,product_name"
    ); //, {product_name: parameter}
    return result.data.results;
  } catch (error) {
    return error;
  }
};

// 반품 API
// 등록된 반품 리스트 가지고 오는 API
export const getProducts = async (searchState) => {
  try {
    const result = await API.get("/admin/take-back", { params: searchState });
    return result.data;
  } catch (error) {
    return error;
  }
};

// 반품 상세 조회
export const getTackBackDetail = async (parameter) => {
  try {
    const result = await API.get(`/storeowner/take-back/${parameter}`);
    return result.data;
  } catch (error) {
    return error;
  }
};

// 반품 삭제
export const deleteTakeBack = async (take_back_ids) => {
  try {
    const result = await API.delete(`/storeowner/take-back?take_back_id=${take_back_ids}`);
    return result.data.status;
  } catch (error) {
    return error;
  }
};

// 반품 상품 등록 API
export const postProducts = async (franchise_id, products) => {
  try {
    const result = await API.post("/storeowner/take-back", {
      franchise_id: franchise_id,
      takeBackProductDetail: products,
    });
    return result;
  } catch (error) {
    return error;
  }
};

// 반품 검수 API
export const reviewTakeback = async (take_back_id, franchise_id, take_back_review) => {
  try {
    const result = await API.post(`/admin/take-back/${take_back_id}`, {
      take_back_id: take_back_id,
      franchise_id: franchise_id,
      take_back_review: take_back_review,
    });
    return result;
  } catch (error) {
    return error;
  }
};

// 반품 검수 API
export const editTakeback = async (take_back_id, editedData) => {
  try {
    const result = await API.put(`/admin/take-back/${take_back_id}`, editedData);
    return result;
  } catch (error) {
    return error;
  }
};

// 가맹점 재고 가지고 오기
export const getInventory = async (searchState) => {
  try {
    const result = await API.get("/admin/inventory", {
      params: {
        ...searchState,
        block: 10,
      },
    });
    return result.data;
  } catch (error) {
    return error;
  }
};

// 관리자 재고 수정
export const putInventory = async (params) => {
  try {
    const result = await API.put("/admin/inventory", params);
    return result;
  } catch (error) {
    return error;
  }
};

// 점주 재고 수정
export const putInventoryStoreowner = async (params) => {
  try {
    const result = await API.put("/storeowner/inventory", params);
    return result;
  } catch (error) {
    return error;
  }
};

// 주문 리스트 가지고 오는 API
export const getOrderProducts = async (searchState) => {
  try {
    const result = await API.get("/admin/order-list", {
      params: searchState,
    });
    return result.data;
  } catch (error) {
    return error;
  }
};

// 조회를 위한 주문 리스트 가지고 오는 API
export const getOrderProductsView = async (searchState) => {
  try {
    const result = await API.get("/admin/order-list/view", {
      params: searchState,
    });
    return result.data;
  } catch (error) {
    return error;
  }
};

// 주문 내역 엑셀 다운 API
export const getOrderProductsExcel = async (start_date, end_date, order) => {
  try {
    if (order.length === 0) {
      const result = await API.post(`/admin/order-list/excel`, {
        start_date: start_date,
        end_date: end_date,
      });
      return result;
    } else {
      const result = await API.post(`/admin/order-list/excel`, {
        order: order,
      });
      return result;
    }
  } catch (error) {
    return error;
  }
};

// 가맹점 매출관리 > 그래프
export const getGraph = async (searchState) => {
  try {
    const result = await API.get("/admin/franchise/revenue/period", {
      params: { ...searchState, category: "period", figure: "graph" },
    });
    return result.data;
  } catch (error) {
    return error;
  }
};

// 가맹점 매출관리 > 표
export const getTable = async (searchState) => {
  try {
    const result = await API.get(`/admin/franchise/revenue/${searchState.category}`, { params: searchState });
    return result.data;
  } catch (error) {
    return error;
  }
};

// 관리자_상품마스터_주문메인_배송전 -> 배송중 상태 변경
export const updateDeliveryState = async (order_date, franchise_id, tracking_number, courier_company) => {
  try {
    const result = await API.put(`admin/order-list/status/delivery`, {
      order_date: order_date,
      franchise_id: franchise_id,
      tracking_number: tracking_number,
      courier_company: courier_company,
    });
    return result.data;
  } catch (error) {
    return error;
  }
};

// 관리자_상품마스터_주문상세_택배사, 운송장번호 변경
export const changeDeliveryState = async (order_date, franchise_id, tracking_number, courier_company) => {
  try {
    const result = await API.put(`admin/order-list/detail/delivery`, {
      order_date,
      franchise_id: franchise_id,
      tracking_number,
      courier_company,
    });
    return result.data;
  } catch (error) {
    return error;
  }
};

// 관리자 > 쇼핑몰 API
// 쇼핑몰 매출관리 조회
export const getShopSales = async (searchState) => {
  try {
    const result = await API.get("/shoppingmall/sale", {
      params: searchState,
    });
    return result.data;
  } catch (error) {
    return error;
  }
};

// 주문내역 조회 및 관리 > 조회
export const getShopOrders = async (searchState) => {
  try {
    const result = await API.get("/shoppingmall/order-list", {
      params: searchState,
    });
    return result.data;
  } catch (error) {
    return error;
  }
};

// 주문내역 조회 및 관리 > 배송전 -> 배송중 상태 변경
export const updateShopDeliveryState = async (clickedItemOrderNum, courierCompany, courierCode, trackingNumber) => {
  try {
    const result = await API.put(`shoppingmall/order/${clickedItemOrderNum}`, {
      status: "on-delivery",
      courier_company: courierCompany,
      courier_code: courierCode,
      tracking_number: trackingNumber,
    });
    return result.data;
  } catch (error) {
    return error;
  }
};

// 주문내역 조회 및 관리 > 상세 > 조회
export const getShopOrderDetail = async (parameter) => {
  try {
    const result = await API.get(`/shoppingmall/order/${parameter}`);
    return result.data;
  } catch (error) {
    return error;
  }
};

// 주문내역 조회 및 관리 > 상세 > 택배사, 운송장번호 변경
export const changeShopDeliveryState = async (idx, editCourierCompany, courierCode, editTrackingNumber) => {
  try {
    const result = await API.put(`shoppingmall/order/${idx}`, {
      status: "on-delivery",
      courier_company: editCourierCompany,
      courier_code: courierCode,
      tracking_number: editTrackingNumber,
    });
    return result.data;
  } catch (error) {
    return error;
  }
};

// 주문내역 조회 및 관리 > 상세 > 주문취소
export const cancelShopOrder = async (idx, consumerId, reason, detailReason) => {
  try {
    const result = await API.put(`/shoppingmall/admin/order`, {
      shoppingmall_order_id: idx,
      consumer_id: consumerId,
      etc: reason,
      detail_reason: detailReason,
    });
    return result.data;
  } catch (error) {
    return error;
  }
};

// 반품관리
export const getShopTakeBackProducts = async (searchState) => {
  try {
    const result = await API.get("/shoppingmall/take-back-list", {
      params: searchState,
    });
    return result.data;
  } catch (error) {
    return error;
  }
};

// 반품관리 > 검수완료 -> 입금완료 상태 변경
export const depositShopTakeback = async (idx, cash, point) => {
  try {
    const result = await API.put(`/shoppingmall/take-back/check`, {
      shoppingmall_take_back_id: idx,
      status: "deposit_completed", // 원하는 상태값 보냄
    });
    return result;
  } catch (error) {
    return error;
  }
};

// 반품관리 > 상세 > 조회
export const getShopTackBackDetail = async (parameter) => {
  try {
    const result = await API.get(`/shoppingmall/take-back/${parameter}`);
    return result.data;
  } catch (error) {
    return error;
  }
};

// 반품관리 > 상세 > 반품 검수(검수요청 -> 검수완료)
export const reviewShopTakeback = async (idx, updatedReviewList) => {
  try {
    const result = await API.put(`/shoppingmall/take-back/check`, {
      shoppingmall_take_back_id: idx,
      take_back_review: updatedReviewList,
      status: "check_completed", // 원하는 상태값 보냄
    });
    return result;
  } catch (error) {
    return error;
  }
};
