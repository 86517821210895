import axios from "axios";

const baseURL = process.env.REACT_APP_BASEURL;

const API = axios.create({
  baseURL: baseURL,
  timeout: 200000,
  data: {},
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

API.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    config.headers.authorization = `Bearer${accessToken}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { config, response } = error;
    if (error.response?.data && error.response.data.message === "jwt expired") {
      const originalRequest = config;
      const refreshToken = localStorage.getItem("refreshToken");
      const accessToken = localStorage.getItem("accessToken");

      // token refresh 요청
      try {
        const { data } = await axios.post(
          `${baseURL}/auth/refresh`,
          {},
          {
            headers: {
              authorization: `Bearer${accessToken}`,
              refresh: refreshToken,
            },
          },
        );
        if (data) {
          localStorage.setItem("refreshToken", data.data.refreshToken);
          localStorage.setItem("accessToken", data.data.accessToken);
          originalRequest.headers.authorization = `Bearer${data.data.accessToken}`;
          return await API.request(originalRequest);
        }
      } catch (error) {
        sessionStorage.removeItem("user_id");
        sessionStorage.removeItem("franchise_id");
        sessionStorage.removeItem("gubun");
        localStorage.removeItem("user_id");
        localStorage.removeItem("franchise_id");
        localStorage.removeItem("gubun");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        window.location.replace("/");
        alert("토큰이 만료되었습니다. 다시 로그인 해주세요.");
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);

export default API;
