import React, { useEffect, useState, useMemo } from "react";
import Quill from "quill";
import ReactQuill from "react-quill";
import ImageResize from "@looop/quill-image-resize-module-react";
import "react-quill/dist/quill.snow.css";
import AWS from "aws-sdk";

import CustomToolbar from "./CustomToolbar.js";

// window 객체의 Quill 에 react-quill 모듈 할당(quill 모듈을 import 한 이후 기능 실행되도록 하는 역할)
// 아래 코드 없는 경우 이미지 리사이징 모듈 인식 못 해서 window.Quill is undefined 에러남
window.Quill = Quill;

// font-family 모듈
const Font = Quill.import("formats/font");
Font.whitelist = ["Pretendard", "Binggrae", "Montserrat"];
Quill.register(Font, true);

// 이미지 리사이징 모듈
Quill.register("modules/ImageResize", ImageResize);

// 이미지 s3 업로드, 삭제 위한 정보
const NAME = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_S3_BUCKET_REGION;
const ACCESS_KEY = process.env.REACT_APP_S3_BUCKET_ACCESS_KEY_ID;
const SECRET_ACCESS_KEY = process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY;
const URL = process.env.REACT_APP_S3_KEY;

// quill 에디터 만들기
const QuillContainer = ({ quillRef, value, setValue }) => {
  // 에디터에서 업로드한 이미지 파일들 배열
  const [imageFiles, setImageFiles] = useState([]);

  // 커스텀 이미지 핸들러 *react-quill 의 자체 이미지 업로드(base-64 인코딩) 기능을 막음
  // 1. s3에 이미지 업로드하는 함수
  const imageHandler = () => {
    const input = document.createElement("input"); // input 요소 추가
    input.setAttribute("type", "file"); // input 타입을 file로 지정
    input.setAttribute("accept", "image/*"); // input_file 중 이미지만 업로드할 수 있도록 설정
    input.click(); // toolbar 이미지 아이콘(기능은 file input) 클릭

    // toolbar 이미지 아이콘 클릭 시 이벤트 등록
    input.addEventListener("change", async () => {
      // 이미지를 담아 전송할 file을 만든다
      const file = input.files?.[0];
      try {
        // 업로드할 파일의 이름으로 Date 사용(아래 params의 Key는 고유한 값이어야 하기 때문)
        // Date.now(); UTC 기준으로 1970년 1월 1일 0시 0분 0초부터 현재까지 경과된 밀리초를 반환
        const fileName = Date.now();
        // 생성한 s3 관련 설정들(AWS 계정 정보 및 리전 설정)
        AWS.config.update({
          region: REGION, // 내 버킷 지역
          accessKeyId: ACCESS_KEY, // s3의 액세스 키 ID
          secretAccessKey: SECRET_ACCESS_KEY, // s3의 보안 액세스 키 ID
        });
        // 앞서 생성한 file을 담아 s3에 업로드하는 객체를 만든다
        const upload = new AWS.S3.ManagedUpload({
          params: {
            Bucket: NAME, // 업로드할 대상 버킷명
            Key: `notice/${fileName}`, // 업로드할 파일명
            Body: file, // 업로드할 파일 객체
          },
        });

        // 이미지를 s3에 업로드
        await upload.promise().then((res) => {
          const url_key = res.Key; // s3에 업로드한 이미지의 Key(업로드된 이미지의 url을 가져오기 위함)

          const editor = quillRef.current.getEditor(); // useRef로 QuillEditor 컴포넌트의 'editor' 속성에 접근하기
          const range = editor.getSelection(); // QuillEditor의 editor의 selection 현재 커서 위치에 이미지 삽입하기

          editor.insertEmbed(range?.index, "image", URL + url_key); // 해당 커서 위치에 이미지 url 삽입 -> 이미지 프리뷰
          editor.setSelection(range?.index + 1); // 유저 편의를 위해 이미지 삽입 후 커서 위치 이미지 오른쪽으로 이동

          // 이미지의 key값과 고유값 id를 부여함
          setImageFiles((prev) => [...prev, { key: `notice/${fileName}`, id: fileName }]);
        });
      } catch (error) {
        // console.log(error);
      }
    });
  };

  // 2. s3에 업로드된 이미지를 삭제하는 함수
  const deleteImage = async (fileKey) => {
    try {
      // 생성한 s3 관련 설정들(AWS 계정 정보 및 리전 설정)
      AWS.config.update({
        region: REGION, // 내 버킷 지역
        accessKeyId: ACCESS_KEY, // s3의 액세스 키 ID
        secretAccessKey: SECRET_ACCESS_KEY, // s3의 보안 액세스 키 ID
      });

      // s3 객체 생성
      const s3 = new AWS.S3();

      // deleteObject 실행을 위한 params
      const params = {
        Bucket: NAME,
        Key: fileKey,
      };

      // s3에 업로드된 이미지 파일 객체를 삭제하는 함수 deleteObject
      await s3
        .deleteObject(params)
        .promise()
        .then(() => {
          // s3에 업로드된 이미지가 삭제된 경우 아래 콘솔이 찍힘
          // (network 탭의 DELETE 메서드 204 코드 뜬 경우 삭제 성공한 것임)
          // console.log(`Image deleted from S3: ${fileKey}`);
        });
    } catch (error) {
      // s3에 업로드된 이미지 삭제가 실패하는 경우 아래 콘솔이 찍힘
      // console.error("Error deleting image from S3:", error);
    }
  };

  /*
  delteImage가 실행되는 조건
  : quillRef.current?.value Quill 에디터에서 현재 입력하는 값이 바뀔 때
  */
  useEffect(() => {
    // 이미지 배열 for 반복문으로 모두 검사
    for (let i = 0; i < imageFiles.length; i++) {
      // 에디터의 입력값 value에 이미지 파일의 id, fileName이 없는 경우(즉, 사용자가 이미지를 삭제한 경우)
      if (!quillRef.current?.value.includes(imageFiles[i].id)) {
        const tempImageFiles = structuredClone(imageFiles); // 이미지 배열 원본 유지를 위한 깊은 복사 structuredClone() 자바스크립트 내장 함수
        const filteredIamgeFiles = tempImageFiles.filter((image) => image.id !== imageFiles[i].id); // 이미지 배열에서 고유값 id로 사용자가 삭제한 이미지 찾기

        deleteImage(imageFiles[i].key); // 그 이미지를 s3에서도 삭제해야 하므로 key값을 넘겨주고 deleteImage 호출
        setImageFiles(filteredIamgeFiles); // 그리고 이미지 배열을 업데이트
      }
    }
  }, [quillRef.current?.value]);

  // react-quill 모듈 정의
  // useMemo를 사용하여 modules가 리렌더링되는 것을 막고 이미지 업로드 시 커서 위치 찾을 수 있음(최초 한 번만 실행)
  const modules = useMemo(() => {
    return {
      toolbar: {
        container: "#toolbar",
        handlers: {
          image: imageHandler,
        },
      },
      history: {
        delay: 500, //변경 이벤트 발생 후 기록까지의 지연 시간
        maxStack: 100, // 변경 이벤트를 저장하는 스택의 최대 크기
        userOnly: true, // Quill 에디터에서 사용자가 작성한 변경 이벤트만 기록
      },
      ImageResize: { modules: ["Resize"] },
    };
  }, []);

  // react-quill 형식 정의
  // toolbar 아이콘이 실제 동작하도록 기능 설정한 것임
  const formats = [
    "font",
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
    "align",
    "color",
    "link",
    "background",
    "image",
  ];

  return (
    <div className="text-editor">
      <CustomToolbar />
      <ReactQuill
        id="react-quill"
        ref={quillRef}
        value={value}
        onChange={setValue}
        modules={modules}
        formats={formats}
        selection={{ start: 0, end: 0 }}
        placeholder={"내용을 입력해주세요."}
        theme="snow"
        preserveWhitespace // 에디터 영역에서 공백 요소를 유지하도록 설정(해당 속성 없는 경우 에디터 영역이 사라지고 toolbar만 남음)
      />
    </div>
  );
};

export default QuillContainer;
