// 외부 모듈
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import styled from "styled-components";
import { utils } from "xlsx";
import { useMediaQuery } from "react-responsive";
import XLSX from "xlsx-js-style";

// 내부 모듈
import {
  ContentContainer,
  Btn,
  Container,
  FlexBox,
  LeftArea,
  RightArea,
  SquareImg,
  Text,
  Title,
  Wrapper,
  CheckIcon,
  CheckLabel,
  WhiteBox,
  OrderAddrBox,
} from "../../Assets/css/commonVer2";
import { DELIVERY_STATUS } from "../../Utiles/ListData";
import img_banner from "../../Assets/images/banner_img.jpg";
import HeaderSub from "../../Components/Header/HeaderSub";
import API from "../../Utiles/API";
import { AddComma } from "../../Utiles/Custom";
import Authority from "../../Components/Modal/Authority";
import img_noimage from "../../Assets/images/no_image.jpg";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import InfoModal from "../../Components/Modal/InfoModal";

// 상품관리 > 주문내역 관리 > 날짜별 주문 상세

function OrderView() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();
  const location = useLocation();

  // 서버로 get 요청 시 보내는 정보
  const [franchise_id] = useState(sessionStorage.getItem("franchise_id"));
  const [order_date] = useState(location.state.order_date);

  // 서버에서 가져오는 정보
  const [orderStatus, setOrderStatus] = useState(""); // 주문 상태
  const [productData, setProductData] = useState([]); // 상품 정보
  const [deliveyInfo, setDeliveryInfo] = useState([]); // 배송 정보
  const [paymentInfo, setPaymentInfo] = useState(""); // 결제 정보
  const shippingFee = 5000; // 택배비 금액 상관없이 5,000원으로 통일
  const [banner, setBanner] = useState(""); // 공지

  const [isCancelOrder, setIsCancelOrder] = useState(false); // 주문취소 여부
  const [cancelOrderModal, setCancelOrderModal] = useState(false); // 주문취소 확인 모달
  const [shoppingModal, setShoppingModal] = useState(false); // 장바구니 담기

  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [refreshModal, setRefreshModal] = useState(false);
  const [goBackModal, setGoBackModal] = useState(false);

  const [checkItem, setCheckItem] = useState([]);

  const [resData, setResData] = useState([]); // 거래명세서 데이터

  // API
  const List = async () => {
    try {
      const res = await API.get(`/storeowner/order-list/detail?franchise_id=${franchise_id}&order_date=${order_date}`);
      if (res.data.status) {
        setOrderStatus(res?.data.results.delivery.status);
        setProductData(res?.data.results.table);
        setDeliveryInfo(res?.data.results.delivery);
        setPaymentInfo(res?.data.results.order_info);
      } else {
        setTextMsg(res.data.msg || "데이터를 불러오는데 문제가 발생했습니다. 잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    } catch (error) {
      console.log(error);
      setTextMsg("E01\n데이터를 불러오는데 문제가 발생했습니다. 잠시후 다시 시도해주세요.");
      setTextModal(true);
    }
  };

  const Notice = async () => {
    await API.get(`/admin/notice-event`).then((res) => {
      if (res.data.status) {
        let bannerData = [];
        for (var i = 0; i < res.data.result.image_path.length; i++) {
          if (res.data.result.image_path[i]?.includes("type3")) {
            bannerData = {
              idx: res.data.result.idx,
              image_path_1: res.data.result.image_path[i],
            };
          }
        }
        setBanner(bannerData);
      }
    });
  };

  const shopping = async (cnt, e) => {
    API.post(
      `/storeowner/shopping-basket`,
      {
        user_id: sessionStorage.getItem("user_id"),
        product_id: e,
        quantity: cnt,
      },
      { header: { "content-type": "multipart/form-data" } },
    )
      .then(function (res) {
        if (res.data.status) {
          setShoppingModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(function (error) {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const cancelOrder = async () => {
    await API.delete(`/storeowner/order-list/detail/case?order_history_id=${checkItem}`)
      .then((res) => {
        if (res.data.status) {
          setTextMsg("주문이 정상적으로 취소되었습니다.");
          if (productData.length !== checkItem.length) {
            setRefreshModal(true);
          } else {
            setGoBackModal(true);
          }
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const completeDelivery = async () => {
    await API.put(`storeowner/order-list/status/delivery`, {
      order_date,
      franchise_id,
    })
      .then((res) => {
        if (res.data.status) {
          setOrderStatus("over-delivery");
          setTextMsg("주문이 정상적으로 배송완료되었습니다.");
          setTextModal(true);
          setTimeout(() => {
            window.location.replace("/goods/orderhistory/view");
          }, 200);
        }
      })
      .catch(() => {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const onSubmit = (e) => {
    if (e === 1) {
      if (checkItem.length === 0) {
        setTextMsg("취소할 상품 주문 건을 선택해주세요.");
        setTextModal(true);
        setCancelOrderModal(false);
        return;
      } else {
        setCancelOrderModal(false);
        // 주문 취소 api 실행
        cancelOrder();
      }
    } else {
      // setCompleteDeliveryModal(false);
      // // 배송 완료 api 실행
      // completeDelivery();
    }
  };

  const handleChk = (checked, id) => {
    if (checked) {
      setCheckItem((prev) => [...prev, id]);
    } else {
      setCheckItem(checkItem.filter((el) => el !== id));
    }
  };

  // 거래명세서 API 호출
  const onClickTradingStatementBtn = async () => {
    await API.get(
      `/storeowner/order-list/detail/product-description?franchise_id=${franchise_id}&order_date=${order_date}`,
    )
      .then((res) => {
        if (res.data.status) {
          setResData(res.data.results);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch((error) => {
        setTextMsg("E01\n잠시 후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  // 거래명세서 다운로드
  function DownTradingStatement() {
    // workbook 만들기
    const wb = utils.book_new();

    // 합계금액 숫자 한글로 바꾸는 함수
    function convertToKoreanNumber(num) {
      var result = "";
      var digits = ["영", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구"];
      var units = ["", "십", "백", "천", "만", "십", "백", "천", "억"];

      var numStr = num.toString(); // 문자열로 변환
      var numLen = numStr.length; // 문자열의 길이

      for (var i = 0; i < numLen; i++) {
        var digit = parseInt(numStr.charAt(i)); // i번째 자릿수 숫자
        var unit = units[numLen - i - 1]; // i번째 자릿수 단위

        // 일의 자리인 경우에는 숫자를 그대로 한글로 변환
        if (i === numLen - 1 && digit === 1 && numLen !== 1) {
          result += "일";
        } else if (digit !== 0) {
          // 일의 자리가 아니거나 숫자가 0이 아닐 경우
          result += digits[digit] + unit;
        } else if (i === numLen - 5) {
          // 십만 단위에서는 '만'을 붙이지 않습니다.
          result += "만";
        }
      }

      return result;
    }

    // 공통 데이터 - origin 위치의 r(row) 와 c(column)은 0부터 시작
    const commonData = [
      {
        data: [
          [
            {
              v: "거래명세서",
              s: {
                font: { underline: true, bold: true, sz: "30" },
                alignment: { horizontal: "center", vertical: "center" },
              },
            },
          ],
        ],
      },
      {
        data: [
          [
            {
              v: `주문일자: ${order_date}`,
              s: { font: { sz: "16" }, alignment: { horizontal: "left", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 1, c: 0 },
      },
      {
        data: [
          [{ v: "공급받는자", s: { font: { sz: "12" }, alignment: { horizontal: "center", vertical: "center" } } }],
        ],
        origin: { r: 2, c: 0 },
      },
      {
        data: [[{ v: "공급자", s: { font: { sz: "12" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 2, c: 10 },
      },
      {
        data: [
          [{ v: "사업자 번호", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }],
        ],
        origin: { r: 2, c: 1 },
      },
      {
        data: [
          [{ v: "사업자 번호", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }],
        ],
        origin: { r: 2, c: 11 },
      },
      {
        data: [[{ v: "상호", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 4, c: 1 },
      },
      {
        data: [[{ v: "상호", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 4, c: 11 },
      },
      {
        data: [[{ v: "주소", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 5, c: 1 },
      },
      {
        data: [[{ v: "주소", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 5, c: 11 },
      },
      {
        data: [[{ v: "업태", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 6, c: 1 },
      },
      {
        data: [[{ v: "업태", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 6, c: 11 },
      },
      {
        data: [[{ v: "성명", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 4, c: 7 },
      },
      {
        data: [[{ v: "성명", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 4, c: 17 },
      },
      {
        data: [[{ v: "종목", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 6, c: 5 },
      },
      {
        data: [[{ v: "종목", s: { font: { sz: "10" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 6, c: 15 },
      },
      {
        data: [
          [
            {
              v: `${resData.franchise?.business_num}`,
              s: { font: { sz: "14" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 2, c: 2 },
      },
      {
        data: [
          [
            {
              v: `Tel. ${resData.franchise?.tel}`,
              s: { font: { sz: "12" }, alignment: { horizontal: "left", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 2, c: 6 },
      },
      {
        data: [
          [
            {
              v: `Fax. ${resData.franchise?.fax || "-"}`,
              s: { font: { sz: "12" }, alignment: { horizontal: "left", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 3, c: 6 },
      },
      {
        data: [
          [
            {
              v: `${resData.franchise?.franchise_name}`,
              s: { font: { sz: "14" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 4, c: 2 },
      },
      {
        data: [
          [
            {
              v: `${resData.franchise?.name}`,
              s: { font: { sz: "12" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 4, c: 8 },
      },
      {
        data: [
          [
            {
              v: `${resData.franchise?.franchise_address}`,
              s: { font: { sz: "11" }, alignment: { horizontal: "left", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 5, c: 2 },
      },
      {
        data: [
          [
            {
              v: `${resData.franchise?.type_of_business || "도매 및 소매업"}`,
              s: { font: { sz: "11" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 6, c: 2 },
      },
      {
        data: [
          [
            {
              v: `${resData.franchise?.items_of_business || "문구용품, 완구용품, 식음료품"}`,
              s: { font: { sz: "11" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 6, c: 6 },
      },
      {
        data: [
          [
            {
              v: `${resData.head?.head_business_num}`,
              s: { font: { sz: "14" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 2, c: 12 },
      },
      {
        data: [
          [
            {
              v: `Tel. ${resData.head?.tel}`,
              s: { font: { sz: "12" }, alignment: { horizontal: "left", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 2, c: 16 },
      },
      {
        data: [
          [
            {
              v: `Fax. ${resData.head?.fax || "-"}`,
              s: { font: { sz: "12" }, alignment: { horizontal: "left", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 3, c: 16 },
      },
      {
        data: [
          [
            {
              v: `${resData.head?.corp_name}`,
              s: { font: { sz: "14" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 4, c: 12 },
      },
      {
        data: [
          [
            {
              v: `${resData.head?.ceo}`,
              s: { font: { sz: "12" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 4, c: 18 },
      },
      {
        data: [
          [
            {
              v: `${resData.head?.address}`,
              s: { font: { sz: "11" }, alignment: { horizontal: "left", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 5, c: 12 },
      },
      {
        data: [
          [
            {
              v: `${resData.head?.head_type_of_business}`,
              s: { font: { sz: "11" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 6, c: 12 },
      },
      {
        data: [
          [
            {
              v: `${resData.head?.head_items_of_business}`,
              s: { font: { sz: "11" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 6, c: 16 },
      },
      {
        data: [
          [
            {
              v: `합계금액: 一金 ${convertToKoreanNumber(paymentInfo.product_amount + shippingFee)}원整 (\ ${AddComma(
                paymentInfo.product_amount + shippingFee,
              )})[VAT 포함]`,
              s: { font: { bold: true, sz: "18" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 7, c: 0 },
      },
      {
        data: [[{ v: "No", s: { font: { sz: "11" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 8, c: 0 },
      },
      {
        data: [
          [
            {
              v: "상품명 및 바코드 번호",
              s: { font: { sz: "11" }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 8, c: 1 },
      },
      {
        data: [[{ v: "단위", s: { font: { sz: "11" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 8, c: 10 },
      },
      {
        data: [[{ v: "수량", s: { font: { sz: "11" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 8, c: 12 },
      },
      {
        data: [[{ v: "단가", s: { font: { sz: "11" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 8, c: 14 },
      },
      {
        data: [[{ v: "총 금액", s: { font: { sz: "11" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 8, c: 16 },
      },
      {
        data: [
          [
            {
              v: `인수자            (인)`,
              s: { font: { sz: "12" }, alignment: { horizontal: "right", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 30, c: 0 },
      },
      {
        data: [[{ v: "총 계", s: { font: { sz: "12" }, alignment: { horizontal: "center", vertical: "center" } } }]],
        origin: { r: 31, c: 14 },
      },
      {
        data: [
          [
            {
              v: `${AddComma(paymentInfo.product_amount + shippingFee)}원`,
              s: { font: { sz: "13", bold: true }, alignment: { horizontal: "center", vertical: "center" } },
            },
          ],
        ],
        origin: { r: 31, c: 16 },
      },
    ];

    // 각 시트에 넣을 상품 데이터
    const productData = {
      NoBody: [],
      productBody: [],
      unitBody: [],
      cntBody: [],
      unitPriceBody: [],
      priceBody: [],
    };

    for (let i = 0; i < resData?.products.length; i++) {
      productData.NoBody.push([
        { v: i + 1, s: { font: { sz: "13", bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      ]);
      productData.productBody.push([
        {
          v: `${resData.products[i].product_name}_${resData.products[i].barcode_num}`,
          s: { font: { sz: "12", bold: true }, alignment: { horizontal: "left", vertical: "center" } },
        },
      ]);
      productData.unitBody.push([
        {
          v: `${resData.products[i].order_unit}(${resData.products[i].product_quantity}개)`,
          s: { font: { sz: "14", bold: true }, alignment: { horizontal: "center", vertical: "center" } },
        },
      ]); // 임시
      productData.cntBody.push([
        {
          v: `${resData.products[i].quantity}`, //* resData.products[i].product_quantity
          s: { font: { sz: "14", bold: true }, alignment: { horizontal: "right", vertical: "center" } },
        },
      ]);
      productData.unitPriceBody.push([
        {
          v: `${resData.products[i].shipping_price}`,
          s: { font: { sz: "14", bold: true }, alignment: { horizontal: "right", vertical: "center" } },
        },
      ]);
      productData.priceBody.push([
        {
          v: `${AddComma(resData.products[i].totalPrice)}원`,
          s: { font: { sz: "14", bold: true }, alignment: { horizontal: "right", vertical: "center" } },
        },
      ]);
    }

    // 한 시트에 20개 상품
    const maxItemsPerSheet = 20;

    // productData를 시트별로 나누는 함수
    function splitDataIntoSheets(data) {
      const sheetDataArray = [];

      for (let i = 0; i < data.NoBody.length; i += maxItemsPerSheet) {
        const sheetData = {
          NoBody: data.NoBody.slice(i, i + maxItemsPerSheet),
          productBody: data.productBody.slice(i, i + maxItemsPerSheet),
          unitBody: data.unitBody.slice(i, i + maxItemsPerSheet),
          cntBody: data.cntBody.slice(i, i + maxItemsPerSheet),
          unitPriceBody: data.unitPriceBody.slice(i, i + maxItemsPerSheet),
          priceBody: data.priceBody.slice(i, i + maxItemsPerSheet),
        };
        sheetDataArray.push(sheetData);
      }

      return sheetDataArray;
    }

    // 모든 시트 데이터 배열
    const sheetDataArray = splitDataIntoSheets(productData);

    // 상품 데이터의 열 지정하는 함수
    function getColumnIndex(key) {
      const columns = {
        NoBody: 0,
        productBody: 1,
        unitBody: 10,
        cntBody: 12,
        unitPriceBody: 14,
        priceBody: 16,
      };
      return columns[key];
    }

    // 상품 데이터 worksheet에 추가하는 함수
    function addDataToWorksheet(ws, data, startRow) {
      Object.keys(data).forEach((key) => {
        XLSX.utils.sheet_add_aoa(ws, data[key], { origin: { r: startRow, c: getColumnIndex(key) } });
      });
    }

    for (let i = 0; i < sheetDataArray.length; i++) {
      const sheetData = sheetDataArray[i];
      const ws = XLSX.utils.aoa_to_sheet([]);
      const wsName = `거래명세서 ${i + 1}p`;

      // 택배비 추가
      const shippingFeeTit = [
        [{ v: "택배비", s: { font: { sz: "14" }, alignment: { horizontal: "center", vertical: "center" } } }],
      ];
      const shippingFeeData = [
        [
          {
            v: `${AddComma(shippingFee)}원`,
            s: { font: { sz: "14" }, alignment: { horizontal: "center", vertical: "center" } },
          },
        ],
      ];

      if (sheetDataArray?.length === 1) {
        XLSX.utils.sheet_add_aoa(ws, shippingFeeTit, { origin: { r: 9 + productData?.NoBody.length, c: 1 } });
        XLSX.utils.sheet_add_aoa(ws, shippingFeeData, { origin: { r: 9 + productData?.NoBody.length, c: 16 } });
      } else {
        XLSX.utils.sheet_add_aoa(ws, shippingFeeTit, { origin: { r: productData?.NoBody.length - 11, c: 1 } });
        XLSX.utils.sheet_add_aoa(ws, shippingFeeData, { origin: { r: productData?.NoBody.length - 11, c: 16 } });
      }

      // 공통 데이터 각 origin에 추가
      commonData.forEach(({ data, origin }) => {
        XLSX.utils.sheet_add_aoa(ws, data, { origin });
      });

      // 상품 데이터 10행에 추가
      addDataToWorksheet(ws, sheetData, 9);

      // 페이지 및 소계 데이터 추가
      const pageAndTotalSum = [
        [
          {
            v: `${i + 1} 페이지(${i + 1}/${sheetDataArray.length}), 소계: ${AddComma(
              paymentInfo.product_amount + shippingFee,
            )}원`,
            s: { font: { sz: "12" }, alignment: { horizontal: "right", vertical: "center" } },
          },
        ],
      ];
      XLSX.utils.sheet_add_aoa(ws, pageAndTotalSum, { origin: { r: 29, c: 0 } });

      // 셀 병합
      const merges = [
        "A1:T1", // 거래명세서
        "A2:T2", // 주문일자
        "A3:A7",
        "B3:B4",
        "C3:F4",
        "G3:J3",
        "G4:J4",
        "C5:G5",
        "I5:J5",
        "C6:J6",
        "C7:E7",
        "G7:J7", // 공급받는자
        "K3:K7",
        "L3:L4",
        "M3:P4",
        "Q3:T3",
        "Q4:T4",
        "M5:Q5",
        "S5:T5",
        "M6:T6",
        "M7:O7",
        "Q7:T7", // 공급자
        "A8:T8", // 합계금액
        "B9:J9",
        "B10:J10",
        "B11:J11",
        "B12:J12",
        "B13:J13",
        "B14:J14",
        "B15:J15",
        "B16:J16",
        "B17:J17",
        "B18:J18",
        "B19:J19",
        "B20:J20",
        "B21:J21",
        "B22:J22",
        "B23:J23",
        "B24:J24",
        "B25:J25",
        "B26:J26",
        "B27:J27",
        "B28:J28",
        "B29:J29", // 상품명 및 바코드번호
        "K9:L9",
        "K10:L10",
        "K11:L11",
        "K12:L12",
        "K13:L13",
        "K14:L14",
        "K15:L15",
        "K16:L16",
        "K17:L17",
        "K18:L18",
        "K19:L19",
        "K20:L20",
        "K21:L21",
        "K22:L22",
        "K23:L23",
        "K24:L24",
        "K25:L25",
        "K26:L26",
        "K27:L27",
        "K28:L28",
        "K29:L29", // 단위
        "M9:N9",
        "M10:N10",
        "M11:N11",
        "M12:N12",
        "M13:N13",
        "M14:N14",
        "M15:N15",
        "M16:N16",
        "M17:N17",
        "M18:N18",
        "M19:N19",
        "M20:N20",
        "M21:N21",
        "M22:N22",
        "M23:N23",
        "M24:N24",
        "M25:N25",
        "M26:N26",
        "M27:N27",
        "M28:N28",
        "M29:N29", // 수량
        "O9:P9",
        "O10:P10",
        "O11:P11",
        "O12:P12",
        "O13:P13",
        "O14:P14",
        "O15:P15",
        "O16:P16",
        "O17:P17",
        "O18:P18",
        "O19:P19",
        "O20:P20",
        "O21:P21",
        "O22:P22",
        "O23:P23",
        "O24:P24",
        "O25:P25",
        "O26:P26",
        "O27:P27",
        "O28:P28",
        "O29:P29", // 단가
        "Q9:T9",
        "Q10:T10",
        "Q11:T11",
        "Q12:T12",
        "Q13:T13",
        "Q14:T14",
        "Q15:T15",
        "Q16:T16",
        "Q17:T17",
        "Q18:T18",
        "Q19:T19",
        "Q20:T20",
        "Q21:T21",
        "Q22:T22",
        "Q23:T23",
        "Q24:T24",
        "Q25:T25",
        "Q26:T26",
        "Q27:T27",
        "Q28:T28",
        "Q29:T29", // 총 금액
        "A30:T30",
        "A31:T31",
        "O32:P32",
        "Q32:T32",
        // "A32:N32",
      ];

      if (!ws["!merges"]) ws["!merges"] = [];

      merges.forEach((range) => ws["!merges"].push(XLSX.utils.decode_range(range)));

      ws["!merges"].push({
        // 거래명세서
        s: { r: 0, c: 0 },
        e: { r: 0, c: 19 },
        // 주문일자
        s: { r: 1, c: 0 },
        e: { r: 1, c: 9 },
        // 주문번호
        s: { r: 1, c: 10 },
        e: { r: 1, c: 19 },
        // 가맹점
        s: { r: 2, c: 0 },
        e: { r: 6, c: 0 },
        // 가맹점_사업자번호_제목
        s: { r: 2, c: 1 },
        e: { r: 3, c: 1 },
        // 본사
        s: { r: 2, c: 10 },
        e: { r: 6, c: 10 },
        // 본사_사업자번호_제목
        s: { r: 2, c: 11 },
        e: { r: 3, c: 11 },
        // 가맹점_사업자번호
        s: { r: 2, c: 2 },
        e: { r: 3, c: 5 },
        // 가맹점_전화번호
        s: { r: 2, c: 6 },
        e: { r: 2, c: 9 },
        // 가맹점_팩스
        s: { r: 3, c: 6 },
        e: { r: 3, c: 9 },
        // 가맹점_상호
        s: { r: 4, c: 2 },
        e: { r: 4, c: 6 },
        // 가맹점_성명
        s: { r: 4, c: 8 },
        e: { r: 4, c: 9 },
        // 가맹점_주소
        s: { r: 5, c: 2 },
        e: { r: 5, c: 9 },
        // 가맹점_업태
        s: { r: 6, c: 2 },
        e: { r: 6, c: 4 },
        // 가맹점_종목
        s: { r: 6, c: 6 },
        e: { r: 6, c: 9 },
        // 본사_사업자번호
        s: { r: 2, c: 12 },
        e: { r: 3, c: 15 },
        // 본사_전화번호
        s: { r: 2, c: 16 },
        e: { r: 2, c: 19 },
        // 본사_팩스
        s: { r: 3, c: 16 },
        e: { r: 3, c: 19 },
        // 본사_상호
        s: { r: 4, c: 12 },
        e: { r: 4, c: 16 },
        // 본사_성명
        s: { r: 4, c: 18 },
        e: { r: 4, c: 19 },
        // 본사_주소
        s: { r: 4, c: 12 },
        e: { r: 4, c: 19 },
        // 본사_업태
        s: { r: 5, c: 12 },
        e: { r: 5, c: 14 },
        // 본사_종목
        s: { r: 5, c: 16 },
        e: { r: 5, c: 19 },
        // 합계금액 표 상단 제목
        s: { r: 7, c: 0 },
        e: { r: 7, c: 19 },
        // 상품명 및 바코드번호_제목
        s: { r: 8, c: 1 },
        e: { r: 8, c: 10 },
        // 상품명 및 바코드번호
        s: { r: 9, c: 1 },
        e: { r: 9, c: 9 },
        s: { r: 10, c: 1 },
        e: { r: 10, c: 9 },
        s: { r: 11, c: 1 },
        e: { r: 11, c: 9 },
        s: { r: 12, c: 1 },
        e: { r: 12, c: 9 },
        s: { r: 13, c: 1 },
        e: { r: 13, c: 9 },
        s: { r: 14, c: 1 },
        e: { r: 14, c: 9 },
        s: { r: 15, c: 1 },
        e: { r: 15, c: 9 },
        s: { r: 16, c: 1 },
        e: { r: 16, c: 9 },
        s: { r: 17, c: 1 },
        e: { r: 17, c: 9 },
        s: { r: 18, c: 1 },
        e: { r: 18, c: 9 },
        s: { r: 19, c: 1 },
        e: { r: 19, c: 9 },
        s: { r: 20, c: 1 },
        e: { r: 20, c: 9 },
        s: { r: 21, c: 1 },
        e: { r: 21, c: 9 },
        s: { r: 22, c: 1 },
        e: { r: 22, c: 9 },
        s: { r: 23, c: 1 },
        e: { r: 23, c: 9 },
        s: { r: 24, c: 1 },
        e: { r: 24, c: 9 },
        s: { r: 25, c: 1 },
        e: { r: 25, c: 9 },
        s: { r: 26, c: 1 },
        e: { r: 26, c: 9 },
        s: { r: 27, c: 1 },
        e: { r: 27, c: 9 },
        s: { r: 28, c: 1 },
        e: { r: 28, c: 9 },
        // 단위
        s: { r: 9, c: 10 },
        e: { r: 9, c: 11 },
        s: { r: 10, c: 10 },
        e: { r: 10, c: 11 },
        s: { r: 11, c: 10 },
        e: { r: 11, c: 11 },
        s: { r: 12, c: 10 },
        e: { r: 12, c: 11 },
        s: { r: 13, c: 10 },
        e: { r: 13, c: 11 },
        s: { r: 14, c: 10 },
        e: { r: 14, c: 11 },
        s: { r: 15, c: 10 },
        e: { r: 15, c: 11 },
        s: { r: 16, c: 10 },
        e: { r: 16, c: 11 },
        s: { r: 17, c: 10 },
        e: { r: 17, c: 11 },
        s: { r: 18, c: 10 },
        e: { r: 18, c: 11 },
        s: { r: 19, c: 10 },
        e: { r: 19, c: 11 },
        s: { r: 20, c: 10 },
        e: { r: 20, c: 11 },
        s: { r: 21, c: 10 },
        e: { r: 21, c: 11 },
        s: { r: 22, c: 10 },
        e: { r: 22, c: 11 },
        s: { r: 23, c: 10 },
        e: { r: 23, c: 11 },
        s: { r: 24, c: 10 },
        e: { r: 24, c: 11 },
        s: { r: 25, c: 10 },
        e: { r: 25, c: 11 },
        s: { r: 26, c: 10 },
        e: { r: 26, c: 11 },
        s: { r: 27, c: 10 },
        e: { r: 27, c: 11 },
        s: { r: 28, c: 10 },
        e: { r: 28, c: 11 },
        // 수량
        s: { r: 9, c: 12 },
        e: { r: 9, c: 13 },
        s: { r: 10, c: 12 },
        e: { r: 10, c: 13 },
        s: { r: 11, c: 12 },
        e: { r: 11, c: 13 },
        s: { r: 12, c: 12 },
        e: { r: 12, c: 13 },
        s: { r: 13, c: 12 },
        e: { r: 13, c: 13 },
        s: { r: 14, c: 12 },
        e: { r: 14, c: 13 },
        s: { r: 15, c: 12 },
        e: { r: 15, c: 13 },
        s: { r: 16, c: 12 },
        e: { r: 16, c: 13 },
        s: { r: 17, c: 12 },
        e: { r: 17, c: 13 },
        s: { r: 18, c: 12 },
        e: { r: 18, c: 13 },
        s: { r: 19, c: 12 },
        e: { r: 19, c: 13 },
        s: { r: 20, c: 12 },
        e: { r: 20, c: 13 },
        s: { r: 21, c: 12 },
        e: { r: 21, c: 13 },
        s: { r: 22, c: 12 },
        e: { r: 22, c: 13 },
        s: { r: 23, c: 12 },
        e: { r: 23, c: 13 },
        s: { r: 24, c: 12 },
        e: { r: 24, c: 13 },
        s: { r: 25, c: 12 },
        e: { r: 25, c: 13 },
        s: { r: 26, c: 12 },
        e: { r: 26, c: 13 },
        s: { r: 27, c: 12 },
        e: { r: 27, c: 13 },
        s: { r: 28, c: 12 },
        e: { r: 28, c: 13 },
        // 단가
        s: { r: 9, c: 14 },
        e: { r: 9, c: 15 },
        s: { r: 10, c: 14 },
        e: { r: 10, c: 15 },
        s: { r: 11, c: 14 },
        e: { r: 11, c: 15 },
        s: { r: 12, c: 14 },
        e: { r: 12, c: 15 },
        s: { r: 13, c: 14 },
        e: { r: 13, c: 15 },
        s: { r: 14, c: 14 },
        e: { r: 14, c: 15 },
        s: { r: 15, c: 14 },
        e: { r: 15, c: 15 },
        s: { r: 16, c: 14 },
        e: { r: 16, c: 15 },
        s: { r: 17, c: 14 },
        e: { r: 17, c: 15 },
        s: { r: 18, c: 14 },
        e: { r: 18, c: 15 },
        s: { r: 19, c: 14 },
        e: { r: 19, c: 15 },
        s: { r: 20, c: 14 },
        e: { r: 20, c: 15 },
        s: { r: 21, c: 14 },
        e: { r: 21, c: 15 },
        s: { r: 22, c: 14 },
        e: { r: 22, c: 15 },
        s: { r: 23, c: 14 },
        e: { r: 23, c: 15 },
        s: { r: 24, c: 14 },
        e: { r: 24, c: 15 },
        s: { r: 25, c: 14 },
        e: { r: 25, c: 15 },
        s: { r: 26, c: 14 },
        e: { r: 26, c: 15 },
        s: { r: 27, c: 14 },
        e: { r: 27, c: 15 },
        s: { r: 28, c: 14 },
        e: { r: 28, c: 15 },
        // 총 금액
        s: { r: 9, c: 16 },
        e: { r: 9, c: 19 },
        s: { r: 10, c: 16 },
        e: { r: 10, c: 19 },
        s: { r: 11, c: 16 },
        e: { r: 11, c: 19 },
        s: { r: 12, c: 16 },
        e: { r: 12, c: 19 },
        s: { r: 13, c: 16 },
        e: { r: 13, c: 19 },
        s: { r: 14, c: 16 },
        e: { r: 14, c: 19 },
        s: { r: 15, c: 16 },
        e: { r: 15, c: 19 },
        s: { r: 16, c: 16 },
        e: { r: 16, c: 19 },
        s: { r: 17, c: 16 },
        e: { r: 17, c: 19 },
        s: { r: 18, c: 16 },
        e: { r: 18, c: 19 },
        s: { r: 19, c: 16 },
        e: { r: 19, c: 19 },
        s: { r: 20, c: 16 },
        e: { r: 20, c: 19 },
        s: { r: 21, c: 16 },
        e: { r: 21, c: 19 },
        s: { r: 22, c: 16 },
        e: { r: 22, c: 19 },
        s: { r: 23, c: 16 },
        e: { r: 23, c: 19 },
        s: { r: 24, c: 16 },
        e: { r: 24, c: 19 },
        s: { r: 25, c: 16 },
        e: { r: 25, c: 19 },
        s: { r: 26, c: 16 },
        e: { r: 26, c: 19 },
        s: { r: 27, c: 16 },
        e: { r: 27, c: 19 },
        s: { r: 28, c: 16 },
        e: { r: 28, c: 19 },
        // 페이지 및 소계
        s: { r: 29, c: 0 },
        e: { r: 29, c: 19 },
        // 인수자
        s: { r: 30, c: 0 },
        e: { r: 30, c: 19 },
        // 총계
        s: { r: 31, c: 14 },
        e: { r: 31, c: 15 },
        s: { r: 31, c: 16 },
        e: { r: 31, c: 19 },
        // 빈행
        s: { r: 31, c: 0 },
        e: { r: 31, c: 13 },
      });

      // 셀 높이 지정
      ws["!rows"] = [
        { hpt: 100 },
        { hpt: 40 },
        { hpt: 20 },
        { hpt: 20 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
        { hpt: 40 },
      ];

      // 셀 너비 지정
      ws["!cols"] = [
        { wch: 10 },
        { wch: "unset" },
        { wch: "unset" },
        { wch: "unset" },
        { wch: "unset" },
        { wch: "unset" },
        { wch: "unset" },
        { wch: "unset" },
        { wch: "unset" },
        { wch: 10 },
      ];

      // 워크북에 워크시트 추가
      XLSX.utils.book_append_sheet(wb, ws, wsName);
    }

    XLSX.writeFile(wb, `거래명세서_${order_date}.xlsx`);
  }
  useEffect(() => {
    List();
    Notice();
  }, []);

  useEffect(() => {
    if (resData?.length !== 0) {
      DownTradingStatement();
    }
  }, [resData]);

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          <HeaderSub title={`${order_date} 주문 상세`} />
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                <FlexBox justify={"space-between"} style={{ flex: 1 }} wrap={"wrap"}>
                  {!isMobile && (
                    <>
                      <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                        주문일자: {order_date}
                      </Title>
                      <FlexBox />
                    </>
                  )}

                  <FlexBox justify={"end"} gap={"8px"} gapMd={"15px"} width={"100%"} widthMd={"unset"}>
                    {!isCancelOrder ? (
                      <>
                        {!isMobile && (
                          <Btn
                            color={"#fff"}
                            weight={"700"}
                            radius={"6px"}
                            padding={"9px 19px"}
                            background={"#C7C7C7"}
                            border={"1px solid #C7C7C7"}
                            lineHeight={"22px"}
                            size={"14px"}
                            width={"100%"}
                            widthMd={"unset"}
                            onClick={onClickTradingStatementBtn}
                          >
                            거래명세서 다운
                          </Btn>
                        )}

                        {orderStatus === "before-delivery" && (
                          <Btn
                            color={"#FF4A4A"}
                            weight={"700"}
                            radius={"6px"}
                            padding={"9px 19px"}
                            background={"#FFf"}
                            lineHeight={"22px"}
                            size={"14px"}
                            width={"100%"}
                            widthMd={"unset"}
                            onClick={() => setIsCancelOrder(true)}
                          >
                            주문 취소
                          </Btn>
                        )}
                        {/* {orderStatus === "on-delivery" && (
                          <Btn
                            color={"#FF4A4A"}
                            weight={"700"}
                            radius={"6px"}
                            padding={"9px 19px"}
                            background={"#FFf"}
                            lineHeight={"22px"}
                            size={"14px"}
                            width={"100%"}
                            widthMd={"unset"}
                            onClick={() => setCompleteDeliveryModal(true)}
                          >
                            배송 완료
                          </Btn>
                        )} */}
                      </>
                    ) : (
                      <>
                        <Btn
                          color={"#fff"}
                          weight={"700"}
                          radius={"6px"}
                          padding={"9px 19px"}
                          background={"#C7C7C7"}
                          border={"1px solid #C7C7C7"}
                          lineHeight={"22px"}
                          size={"14px"}
                          width={"100%"}
                          widthMd={"unset"}
                          onClick={() => setIsCancelOrder(false)}
                        >
                          돌아가기
                        </Btn>
                        <Btn
                          color={"#fff"}
                          weight={"700"}
                          radius={"6px"}
                          padding={"9px 19px"}
                          background={"#FF4A4A"}
                          lineHeight={"22px"}
                          size={"14px"}
                          width={"100%"}
                          widthMd={"unset"}
                          onClick={() => setCancelOrderModal(true)}
                        >
                          취소하기
                        </Btn>
                      </>
                    )}
                  </FlexBox>
                </FlexBox>
                <FlexBox
                  align={"normal"}
                  wrap={"wrap"}
                  wrapMd={"flex-wrap"}
                  gap={"0"}
                  gapMd={"26px"}
                  // gapLg={"20px"}
                >
                  <LeftArea>
                    <WhiteBox>
                      <Title
                        color={"#1E1E1E"}
                        weight={"700"}
                        size={"14px"}
                        sizeMd={"18px"}
                        style={{
                          paddingBottom: "20px",
                          borderBottom: "1px solid #666",
                        }}
                      >
                        상품 정보{" "}
                        <span
                          style={{
                            background: "rgba(255, 116, 64, 0.10)",
                            padding: isMobile ? "10px" : "10px 20px",
                            fontWeight: "400",
                            color: "#FF4A4A",
                            borderRadius: "6px",
                            fontSize: "16px",
                            margin: "0 0 0 10px",
                          }}
                        >
                          총 {productData.length}건
                        </span>
                      </Title>
                      {productData.map((item, key) => {
                        return (
                          <FlexBox
                            key={key}
                            justify={"flex-start"}
                            direction={"column"}
                            wrap={"wrap"}
                            gap={"10px"}
                            gapMd={"20px"}
                            style={{ marginTop: "10px" }}
                          >
                            <OrderItem gap={"10px"} gapMd={"20px"}>
                              {isCancelOrder && (
                                <CheckLabel
                                  htmlFor={`prod${item.order_history_id}`}
                                  style={{ justifyContent: "center" }}
                                >
                                  <input
                                    type="checkbox"
                                    name="prod"
                                    id={`prod${item.order_history_id}`}
                                    checked={checkItem.includes(item.order_history_id) ? true : false}
                                    onClick={(event) => handleChk(event.target.checked, item.order_history_id)}
                                    readOnly
                                  />
                                  <CheckIcon>
                                    <svg viewBox="0 0 24 24">
                                      <polyline points="19 7 10 17 5 12" />
                                    </svg>
                                  </CheckIcon>
                                </CheckLabel>
                              )}
                              <ShopImg>
                                <SquareImg>
                                  <div>
                                    <img
                                      src={
                                        item["image_path"]
                                          ? process.env.REACT_APP_S3_KEY + item["image_path"]
                                          : img_noimage
                                      }
                                    />
                                  </div>
                                </SquareImg>
                              </ShopImg>
                              <ShopText>
                                <TextTit weight={"500"} color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                                  {item["p_name"]}
                                </TextTit>
                                <FlexBox justify={"space-between"}>
                                  <Text family="Montserrat" color={"#666"} align={"left"} size={"12px"} sizeMd={"16px"}>
                                    {item["quantity"]}개 | {AddComma(item["shipping_price"])}원
                                  </Text>
                                </FlexBox>
                                <Text
                                  family="Montserrat"
                                  color={"#FF4A4A"}
                                  weight={"700"}
                                  align={"left"}
                                  size={"14px"}
                                  className="d-block d-md-none"
                                >
                                  {item["quantity"] || item["shipping_price"]
                                    ? AddComma(item["shipping_price"] * item["quantity"])
                                    : 0}
                                  원
                                </Text>
                                {!isCancelOrder && (
                                  <Btn
                                    size={"12px"}
                                    color={"#FF4A4A"}
                                    border={"1px solid #FF4A4A"}
                                    padding={"4px 12px"}
                                    background={"#fff"}
                                    width="auto"
                                    className="d-block d-md-none"
                                    style={{ marginLeft: "auto" }}
                                    onClick={() => {
                                      if (item.quantity > 0) {
                                        shopping(item.quantity, item.product_id);
                                      } else {
                                        alert("주문수량이 0개인 상품이라 장바구니에 담을 수 없습니다.");
                                      }
                                    }}
                                  >
                                    장바구니 담기
                                  </Btn>
                                )}
                              </ShopText>
                              <FlexBox
                                direction={"column"}
                                justify="space-between"
                                style={{ alignItems: "flex-end", minHeight: "70px" }}
                              >
                                {!isCancelOrder && (
                                  <>
                                    <Btn
                                      size={"14px"}
                                      width={"120px"}
                                      padding={"9px 19px"}
                                      color={"#FF4A4A"}
                                      background={"#FFf"}
                                      className="d-none d-md-block"
                                      onClick={() => {
                                        if (item["quantity"] > 0) {
                                          shopping(item["quantity"], item["product_id"]);
                                        } else {
                                          alert("주문수량이 0개인 상품이라 장바구니에 담을 수 없습니다.");
                                        }
                                      }}
                                    >
                                      장바구니 담기
                                    </Btn>
                                  </>
                                )}
                                {/* <FlexBox /> */}
                                <Text
                                  family="Montserrat"
                                  color={"#FF4A4A"}
                                  weight={"700"}
                                  align={"left"}
                                  size={"14px"}
                                  sizeMd={"16px"}
                                  className="d-none d-md-block"
                                  style={{ marginTop: "auto" }}
                                >
                                  {item["quantity"] || item["shipping_price"]
                                    ? AddComma(item["shipping_price"] * item["quantity"])
                                    : 0}
                                  원
                                </Text>
                              </FlexBox>
                            </OrderItem>
                          </FlexBox>
                        );
                      })}
                    </WhiteBox>
                    <WhiteBox margin={"17px 0"} paddingMd="28px 30px">
                      <Title
                        color={"#1E1E1E"}
                        weight={"700"}
                        size={"16px"}
                        sizeMd={"18px"}
                        style={{
                          paddingBottom: "10px",
                          borderBottom: "1px solid #666",
                        }}
                      >
                        배송지 정보
                      </Title>
                      <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          가맹점명
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          {deliveyInfo ? deliveyInfo.franchise_name : ""}
                        </Text>
                      </OrderAddrBox>
                      <OrderAddrBox justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          휴대폰번호
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          {deliveyInfo ? deliveyInfo.tel : ""}
                        </Text>
                      </OrderAddrBox>
                      <OrderAddrBox align={"flex-start"} justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          주소
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          {deliveyInfo ? deliveyInfo.address : ""}
                        </Text>
                      </OrderAddrBox>
                      <OrderAddrBox align={"flex-start"} justify={"flex-start"} style={{ marginTop: "15px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          배송 상태
                        </Text>
                        <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                          {orderStatus ? DELIVERY_STATUS.find((item) => item.value === orderStatus).text : "-"}
                        </Text>
                      </OrderAddrBox>
                      {orderStatus == "before-delivery" || orderStatus == "cancel-order" ? (
                        ""
                      ) : (
                        <>
                          <OrderAddrBox align={"flex-start"} justify={"flex-start"} style={{ marginTop: "15px" }}>
                            <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                              택배사
                            </Text>
                            <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                              {deliveyInfo ? deliveyInfo.courier_company : ""}
                            </Text>
                          </OrderAddrBox>
                          <OrderAddrBox align={"flex-start"} justify={"flex-start"} style={{ marginTop: "15px" }}>
                            <Text color={"#1E1E1E"} weight={"500"} align={"left"} size={"14px"} sizeMd={"18px"}>
                              운송장번호
                            </Text>
                            <Text color={"#1E1E1E"} align={"left"} size={"14px"} sizeMd={"18px"}>
                              {deliveyInfo ? deliveyInfo.tracking_number : ""}
                            </Text>
                          </OrderAddrBox>
                        </>
                      )}
                    </WhiteBox>
                  </LeftArea>
                  <RightArea>
                    <WhiteBox>
                      <Title
                        color={"#1E1E1E"}
                        weight={"700"}
                        size={"16px"}
                        sizeMd={"18px"}
                        style={{
                          paddingBottom: "10px",
                          borderBottom: "1px solid #666",
                        }}
                      >
                        결제 정보
                      </Title>
                      <FlexBox justify={"space-between"} style={{ paddingTop: "20px" }}>
                        <Text color={"#1E1E1E"} weight={"500"} size={"14px"} sizeMd={"18px"}>
                          결제 수단
                        </Text>
                        <Text color={"#1E1E1E"} size={"14px"} sizeMd={"18px"}>
                          예치금 차감
                        </Text>
                      </FlexBox>
                      <Text
                        color={"#1E1E1E"}
                        weight={"500"}
                        align={"left"}
                        padding={"20px 0 0"}
                        size={"14px"}
                        sizeMd={"18px"}
                      >
                        총 결제 금액
                      </Text>
                      <WhiteBox marginMd={"20px 0 0"} paddingMd="20px">
                        <FlexBox justify={"space-between"}>
                          <Text color={"#1E1E1E"} weight={"500"} size={"14px"} sizeMd={"18px"}>
                            상품 가격
                          </Text>
                          <Text color={"#1E1E1E"} size={"14px"} sizeMd={"18px"}>
                            {paymentInfo ? AddComma(paymentInfo.product_amount) : 0}원
                          </Text>
                        </FlexBox>
                        <FlexBox justify={"space-between"} padding={"20px 0 0"}>
                          <Text color={"#1E1E1E"} weight={"500"} size={"14px"} sizeMd={"18px"}>
                            {orderStatus === "before-delivery" ? "예상 택배비" : "택배비"}
                          </Text>
                          <Text color={"#1E1E1E"} size={"14px"} sizeMd={"18px"}>
                            {/* 배송전엔 5,000원 하드한값. 배송후엔 서버에서 내려주는 값 - 바뀌기 전 데이터들 때문에 구분지어놓음 */}
                            {orderStatus === "before-delivery"
                              ? AddComma(shippingFee)
                              : AddComma(paymentInfo.courier_charges)}
                            원
                          </Text>
                        </FlexBox>
                        <FlexBox
                          justify={"space-between"}
                          margin={"20px 0 0"}
                          padding={"20px 0 0"}
                          style={{
                            borderTop: "1px solid #969696",
                          }}
                        >
                          <Text color={"#1E1E1E"} weight={"500"} size={"14px"} sizeMd={"18px"}>
                            결제금액
                          </Text>
                          <Text color={"#1E1E1E"} weight={"700"} size={"14px"} sizeMd={"18px"}>
                            {paymentInfo ? AddComma(paymentInfo.product_amount + shippingFee) : 0}원
                          </Text>
                        </FlexBox>
                      </WhiteBox>
                    </WhiteBox>
                    <BannerWrap
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (banner.idx) {
                          navigate("/notice/View", { state: { idx: banner.idx } });
                        }
                      }}
                    >
                      <img
                        src={banner ? `${process.env.REACT_APP_S3_KEY}${banner.image_path_1}` : img_banner}
                        alt="공지사항 배너"
                      />
                    </BannerWrap>
                  </RightArea>
                </FlexBox>
              </Container>
              <ConfirmModal
                modal={cancelOrderModal}
                setModal={setCancelOrderModal}
                onSubmit={() => onSubmit(1)}
                description={"주문을 취소하시겠습니까?\n취소된 주문은 복구할 수 없습니다."}
              />
              {/* <ConfirmModal
                modal={completeDeliveryModal}
                setModal={setCompleteDeliveryModal}
                onSubmit={() => onSubmit(2)}
                description={"배송완료 상태로 바꾸시겠습니까?\n배송완료 시 예치금이 차감되며\n취소하실 수 없습니다."}
              /> */}
              <InfoModal modal={goBackModal} setModal={setGoBackModal} description={textMsg} mode={"goBack"} />
              <InfoModal modal={shoppingModal} setModal={setShoppingModal} description={"장바구니에 담겼습니다."} />
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
              <InfoModal modal={refreshModal} setModal={setRefreshModal} description={textMsg} mode={"refresh"} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const BannerWrap = styled.div`
  position: relative;
  padding-bottom: 0;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  @media all and (max-width: 1024px) {
    padding-bottom: 0;
  }
`;

const OrderItem = styled(FlexBox)`
  // position: relative;
  // width: calc(50% - 10px);
  width: 100%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
  // background-color: #fffae6;
  border-radius: 10px;
  justify-content: flex-start;
  gap: 20px;
  @media all and (max-width: 1080px) {
    width: 100%;
    padding: 12px;
    gap: 10px;
  }
  @media all and (max-width: 1024px) {
    padding: 10px;
    // border: 1px solid #eaeaea;
    // background-color: #fff;
  }
`;
const ShopImg = styled.div`
  width: 90px;
  border: 1px solid #eaeaea;

  @media all and (max-width: 761024px7px) {
    width: 82px;
  }
`;
const ShopText = styled.div`
  // width: calc(100% - 110px);
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media all and (max-width: 1024px) {
    // width: calc(100% - 92px);
    gap: 0;
  }
`;
const TextTit = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media all and (max-width: 1024px) {
    white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.4em;
    // height: 2.8em;
  }
`;

export default OrderView;
