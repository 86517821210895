import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import {
  ContentContainer,
  Btn,
  Container,
  TextInput,
  Wrapper,
  FlexBox,
  Text,
  Title,
  WhiteBox,
  Contents,
  RadioIcon,
  RadioLabel,
  UploadFileBtn,
  FileImg,
} from "../../Assets/css/commonVer2";
import ico_x from "../../Assets/images/ico_close.png";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import HeaderSub from "../../Components/Header/HeaderSub";
import formAPI from "../../Utiles/formAPI";
import ico_close_round from "../../Assets/images/ico_close_round.png";
import Loading from "../../Components/Loading";
import InfoModal from "../../Components/Modal/InfoModal";
import QuillContainer from "../../Components/QuillEditor/QuillContainer.js";

// 공지관리 > 작성

function NoticeWrite() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const navigate = useNavigate();

  const noticeQuillRef = useRef(); // 내용 에디터 ref

  const imgRef = useRef();
  const [previewNewImg, setPreviewNewImg] = useState([]); //미리보기할때 사용됨
  const [newImg, setNewImg] = useState([]); //서버에 전송할 때 사용됨

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [gubun, setGubun] = useState("storeowner");
  const [type, setType] = useState("notification");
  const [top_fixed, settop_fixed] = useState("0");

  const [saveModal, setSaveModal] = useState(false);
  const [listModal, setListModal] = useState(false);

  const [previewFile, setPreviewFile] = useState([]);
  const [filePath, setFilePath] = useState([]);

  const [loading, setLoading] = useState(false);
  const [textModal, setTextModal] = useState(false); // 모달 창 표시 여부 상태
  const [textMsg, setTextMsg] = useState("");

  async function noticePost() {
    try {
      setLoading(true);
      let formData = new FormData();
      formData.append("title", title);
      formData.append("content", content);

      formData.append("gubun", gubun);
      formData.append("type", type);
      formData.append("top_fixed", top_fixed);
      // image_path 배열의 파일 추가
      newImg.forEach((file) => {
        formData.append("img", file);
      });

      // filePath 배열의 파일 추가
      filePath.forEach((file) => {
        formData.append("notice_file", file);
      });

      await formAPI.post(`/admin/notice`, formData).then((res) => {
        setLoading(false);
        if (res.data.status) {
          navigate("/notice", { replace: true });
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      });
    } catch (error) {
      setLoading(false);
      setTextMsg("E01\n잠시후 다시 시도해주세요.");
      setTextModal(true);
    }
  }

  const changeTag_gubun = (gubun) => {
    if (gubun === "storeowner") {
      setGubun("storeowner");
    } else if (gubun === "user") {
      setGubun("user");
    }
  };

  const changeTag_type = (type) => {
    if (type === "event") {
      setType("event");
    } else if (type === "notification") {
      setType("notification");
    }
  };

  const changeTag_top_fixed = (gubun) => {
    if (gubun === "check") {
      settop_fixed(1);
    } else if (gubun === "uncheck") {
      settop_fixed(0);
    }
  };

  const onSubmit = (e) => {
    if (e === 1) {
      setSaveModal(false);
      navigate("/notice", { replace: true });
    } else {
      noticePost();
      setSaveModal(false);
    }
  };

  const handleImgAdd = (e) => {
    const nowSelectImageList = e.target.files;
    const nowImageURLList = [...previewNewImg];

    for (let i = 0; i < nowSelectImageList.length; i++) {
      const nowImageUrl = URL.createObjectURL(nowSelectImageList[i]);
      nowImageURLList.push(nowImageUrl);
      newImg.push(nowSelectImageList[i]);
    }
    setPreviewNewImg(nowImageURLList);
  };

  const handleImgRemove = (idx) => {
    setPreviewNewImg(previewNewImg.filter((_, index) => index !== idx));
    setNewImg(newImg.filter((_, index) => index !== idx));
  };

  const handleFileSelect = (event) => {
    const files = event.target.files;
    handleFiles(files);
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFiles(files);
  };

  const handleFiles = (files) => {
    // 선택된 파일들에 대한 처리 로직을 구현합니다.
    // 예를 들어, 파일을 첨부하거나 업로드하는 동작 등을 수행할 수 있습니다.
    const nowSelectFileList = files;
    const nowFileURLList = [...previewFile];

    for (let i = 0; i < nowSelectFileList.length; i++) {
      const nowFileUrl = URL.createObjectURL(nowSelectFileList[i]);
      nowFileURLList.push(nowFileUrl);
      filePath.push(nowSelectFileList[i]);
    }
    setPreviewFile(nowFileURLList);
  };

  const handleFileRemove = (idx) => {
    setPreviewFile(previewFile.filter((_, index) => index !== idx));
    setFilePath(filePath.filter((_, index) => index !== idx));
  };

  return (
    <>
      {loading ? <Loading /> : null}
      <HeaderSub title={"공지사항 등록"} />
      <ContentContainer>
        <Wrapper type={2}>
          <Container>
            {!isMobile && (
              <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                공지사항 등록 🔔
              </Title>
            )}
            <WhiteBox>
              <Contents>
                <Text
                  align={"left"}
                  weight={"700"}
                  color={"#1e1e1e"}
                  size={"14px"}
                  sizeMd={"16px"}
                  letterSpacing={"-0.32px"}
                  margin={"0 0 8px"}
                  marginMd={"0 0 10px"}
                >
                  공지구분
                </Text>
                <FlexBox gap={"10px"} justify={"start"}>
                  <RadioLabel>
                    <input
                      type="radio"
                      name="gubun"
                      value={"gubun"}
                      checked={gubun === "storeowner"}
                      onChange={(props) => changeTag_gubun("storeowner")}
                    />
                    <RadioIcon>
                      <svg viewBox="0 0 18 18">
                        <circle cx="9" cy="9" r="8.5" />
                        <circle cx="9" cy="9" r="5" />
                      </svg>
                    </RadioIcon>
                    점주용
                  </RadioLabel>
                  <RadioLabel>
                    <input
                      type="radio"
                      name="gubun"
                      value={"gubun"}
                      checked={gubun === "user"}
                      onChange={(props) => changeTag_gubun("user")}
                    />
                    <RadioIcon>
                      <svg viewBox="0 0 18 18">
                        <circle cx="9" cy="9" r="8.5" />
                        <circle cx="9" cy="9" r="5" />
                      </svg>
                    </RadioIcon>
                    고객용
                  </RadioLabel>
                </FlexBox>
              </Contents>
              <Contents>
                <Text
                  align={"left"}
                  weight={"700"}
                  color={"#1e1e1e"}
                  size={"14px"}
                  sizeMd={"16px"}
                  letterSpacing={"-0.32px"}
                  margin={"20px 0 8px"}
                  marginMd={"30px 0 10px"}
                >
                  배너노출여부
                </Text>
                <FlexBox gap={"10px"} justify={"start"}>
                  <RadioLabel>
                    <input
                      type="radio"
                      name="type"
                      value={"type"}
                      checked={type === "event"}
                      onChange={(props) => changeTag_type("event")}
                    />
                    <RadioIcon>
                      <svg viewBox="0 0 18 18">
                        <circle cx="9" cy="9" r="8.5" />
                        <circle cx="9" cy="9" r="5" />
                      </svg>
                    </RadioIcon>
                    Yes(이벤트)
                  </RadioLabel>
                  <RadioLabel>
                    <input
                      type="radio"
                      name="type"
                      value={"type"}
                      checked={type === "notification"}
                      onChange={(props) => changeTag_type("notification")}
                    />
                    <RadioIcon>
                      <svg viewBox="0 0 18 18">
                        <circle cx="9" cy="9" r="8.5" />
                        <circle cx="9" cy="9" r="5" />
                      </svg>
                    </RadioIcon>
                    No(일반)
                  </RadioLabel>
                </FlexBox>
              </Contents>
              <Contents>
                <Text
                  align={"left"}
                  weight={"700"}
                  color={"#1e1e1e"}
                  size={"14px"}
                  sizeMd={"16px"}
                  letterSpacing={"-0.32px"}
                  margin={"20px 0 8px"}
                  marginMd={"30px 0 10px"}
                >
                  상단고정여부
                </Text>
                <FlexBox gap={"10px"} justify={"start"}>
                  <RadioLabel>
                    <input
                      type="radio"
                      name="top_fixed"
                      value={"top_fixed"}
                      checked={top_fixed === 1}
                      onChange={(props) => changeTag_top_fixed("check")}
                    />
                    <RadioIcon>
                      <svg viewBox="0 0 18 18">
                        <circle cx="9" cy="9" r="8.5" />
                        <circle cx="9" cy="9" r="5" />
                      </svg>
                    </RadioIcon>
                    Yes
                  </RadioLabel>
                  <RadioLabel>
                    <input
                      type="radio"
                      name="top_fixed"
                      value={"top_fixed"}
                      checked={top_fixed !== 1}
                      onChange={(props) => changeTag_top_fixed("uncheck")}
                    />
                    <RadioIcon>
                      <svg viewBox="0 0 18 18">
                        <circle cx="9" cy="9" r="8.5" />
                        <circle cx="9" cy="9" r="5" />
                      </svg>
                    </RadioIcon>
                    No
                  </RadioLabel>
                </FlexBox>
                <Text
                  align={"left"}
                  color={"#666666"}
                  size={"12px"}
                  sizeMd={"14px"}
                  letterSpacing={"-0.32px"}
                  margin={"5px 0 0"}
                  marginMd={"10px 0 0"}
                >
                  * Yes를 체크하시면 공지목록에서 상단고정 되며, 점주용 공지의 경우 홈화면 공지목록에 노출됩니다.
                </Text>
              </Contents>
              <Contents>
                <Text
                  align={"left"}
                  weight={"700"}
                  color={"#1e1e1e"}
                  size={"14px"}
                  sizeMd={"16px"}
                  letterSpacing={"-0.32px"}
                  margin={"20px 0 8px"}
                  marginMd={"30px 0 10px"}
                >
                  제목
                </Text>
                <TextInput
                  style={{ width: "100%" }}
                  placeholder={"제목을 입력해주세요."}
                  type={"text"}
                  name={"title"}
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </Contents>
              <Contents>
                <Text
                  align={"left"}
                  weight={"700"}
                  color={"#1e1e1e"}
                  size={"14px"}
                  sizeMd={"16px"}
                  letterSpacing={"-0.32px"}
                  margin={"20px 0 8px"}
                  marginMd={"30px 0 10px"}
                >
                  내용
                </Text>
                <QuillContainer quillRef={noticeQuillRef} value={content} setValue={setContent} />
              </Contents>
              {type == "event" && (
                <Contents>
                  <Text
                    align={"left"}
                    weight={"700"}
                    color={"#1e1e1e"}
                    size={"14px"}
                    sizeMd={"16px"}
                    letterSpacing={"-0.32px"}
                    margin={"20px 0 8px"}
                    marginMd={"30px 0 10px"}
                  >
                    썸네일 등록
                  </Text>
                  <Text
                    align={"left"}
                    color={"#666"}
                    size={"14px"}
                    letterSpacing={"-0.32px"}
                    margin={"0 0 8px"}
                    marginMd={"0 0 10px"}
                  >
                    *필수(배너 type {gubun == "storeowner" ? 3 : 1}개 썸네일을 등록해주세요.)
                  </Text>
                  {gubun === "storeowner" && (
                    <Text
                      align={"left"}
                      color={"#666"}
                      size={"14px"}
                      letterSpacing={"-0.32px"}
                      margin={"0 0 8px"}
                      marginMd={"0 0 10px"}
                    >
                      * 홈화면용 배너는 'type1', 웹용 배너는 'type2', 모바일용 배너는 'type3'라는 단어가 파일명에
                      포함되어 있어야 합니다.
                    </Text>
                  )}

                  {isMobile ? (
                    <FlexBox justify={"flex-start"} gap={"10px"} wrap={"wrap"}>
                      <UploadFileBtn>
                        <input type="file" multiple="multiple" accept="image/*" onChange={handleImgAdd} ref={imgRef} />
                        <Text size={"14px"} color={"#C7C7C7"}>
                          썸네일 등록
                        </Text>
                        <span>파일 첨부</span>
                      </UploadFileBtn>
                      {previewNewImg?.map((e, i) => (
                        <FileImg key={i}>
                          <FileXBtn onClick={() => handleImgRemove(i)}>
                            <img src={ico_x} />
                          </FileXBtn>
                          <div>
                            <img src={e} />
                          </div>
                        </FileImg>
                      ))}
                    </FlexBox>
                  ) : (
                    <WhiteBox>
                      <FlexBox justify={"flex-start"} gap={"10px"} wrap={"wrap"}>
                        <UploadFileBtn>
                          <input
                            type="file"
                            multiple="multiple"
                            accept="image/*"
                            onChange={handleImgAdd}
                            ref={imgRef}
                          />
                          <Text size={"14px"} color={"#C7C7C7"}>
                            썸네일 등록
                          </Text>
                          <span>파일 첨부</span>
                        </UploadFileBtn>
                        {previewNewImg?.map((e, i) => (
                          <FileImg key={i}>
                            <FileXBtn onClick={() => handleImgRemove(i)}>
                              <img src={ico_x} />
                            </FileXBtn>
                            <div>
                              <img src={e} />
                            </div>
                          </FileImg>
                        ))}
                      </FlexBox>
                    </WhiteBox>
                  )}
                </Contents>
              )}
              <Contents>
                <Text
                  align={"left"}
                  weight={"700"}
                  color={"#1e1e1e"}
                  size={"14px"}
                  sizeMd={"16px"}
                  letterSpacing={"-0.32px"}
                  margin={"20px 0 8px"}
                  marginMd={"30px 0 10px"}
                >
                  파일첨부
                </Text>
                <DropArea
                  onDragOver={(event) => event.preventDefault()}
                  onDragEnter={(event) => event.preventDefault()}
                  onDrop={handleFileDrop}
                >
                  <label htmlFor="fileInput">
                    <FileSelectText>파일 선택</FileSelectText>
                  </label>
                  <input id="fileInput" type="file" style={{ display: "none" }} onChange={handleFileSelect} multiple />
                  {!isMobile && <DragText>또는 여기로 파일을 끌고오세요</DragText>}
                </DropArea>
                <FlexBox justify={"flex-start"} gap={"10px"} wrap={"wrap"}>
                  {previewFile?.map((e, i) => (
                    <Btn
                      key={i}
                      size={"14px"}
                      color={"#2d2d2d"}
                      weight={"500"}
                      background={"#ffffff"}
                      border={"1px solid #c0c0c0"}
                      radius={"20px"}
                      padding={"7px 10px 7px 10px"}
                      margin={"10px 0 0 0"}
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      {filePath[i].name}
                      <img
                        src={ico_close_round}
                        style={{ width: "24px", marginLeft: "5px" }}
                        onClick={() => handleFileRemove(i)}
                      />
                    </Btn>
                  ))}
                </FlexBox>
              </Contents>
            </WhiteBox>
            <FlexBox justify={"end"} style={{ marginTop: "20px" }}>
              <Btn
                size={"14px"}
                padding={"8px 18px"}
                lineHeight={"22px"}
                margin={"0 0 0 10px"}
                hoverWeight={"700"}
                background={"#C7C7C7"}
                border={"1px solid #C7C7C7"}
                color="#fff"
                width={"100%"}
                widthMd={"auto"}
                onClick={() => setListModal(true)}
              >
                목 록
              </Btn>
              <Btn
                color="#fff"
                background={"#FF4A4A"}
                size={"14px"}
                padding={"8px 18px"}
                lineHeight={"22px"}
                margin={"0 0 0 10px"}
                hoverWeight={"700"}
                width={"100%"}
                widthMd={"auto"}
                onClick={() => setSaveModal(true)}
              >
                저 장
              </Btn>
            </FlexBox>
          </Container>
          <ConfirmModal
            modal={listModal}
            setModal={setListModal}
            onSubmit={() => onSubmit(1)}
            description={"공지사항 등록을 취소하고\n목록화면으로 이동하시겠습니까?"}
          />
          <ConfirmModal
            modal={saveModal}
            setModal={setSaveModal}
            onSubmit={() => onSubmit(2)}
            description={"공지사항 등록을 완료하시겠습니까?"}
          />
          <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
        </Wrapper>
      </ContentContainer>
    </>
  );
}

const FileXBtn = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 16px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  z-index: 1;
`;

const DropArea = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px dashed #eaeaea;
  // padding: 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  min-height: 180px;
  width: 100%;
  @media all and (max-width: 1024px) {
    width: 100%;
    min-height: 100px;
  }
`;

const FileSelectText = styled.span`
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  color: #666;
`;

const DragText = styled.span`
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
  color: #c7c7c7;
  display: block;
`;

export default NoticeWrite;
