import React, { useEffect, useState, useRef } from "react";
import { SelectBtn, SearchList, SelectWrap, SelectContainer } from "../../Assets/css/commonVer2";

// 일반 셀렉 박스

function BasicSelect(props) {
  let {
    data, // 목록에 위치시킬 데이터
    selectedValue, // 선택된 데이터
    setSelectedValue, // 데이터 선택
    placeholder, // 디폴트 문자
    canSelectAll, // 전체 필요 유무
    setCurrentPage, // unit 변경 시 1페이지로 이동 (unit 사용 시에만 props로 전달하기/다른경우는 X)
    width,
    widthMd,
    borderColor, // 옵션
    disabled,
    setCate2Value,
  } = props;

  const [selectedText, setSelectedText] = useState(""); // 선택된 텍스트 (버튼에 보여주기 위해)
  const [show, setShow] = useState(false); // 목록 visible
  const selectBoxRef = useRef(); // 외부 클릭 컨트롤

  // 목록에서 하나 클릭 시
  const itemClick = (item) => {
    setShow(false);
    if (item) {
      if (placeholder === "발주단위") {
        setSelectedValue(item.text);
      } else {
        setSelectedValue(item.value);
      }
      setSelectedText(item.text);
    } else {
      // 전체 클릭 시 데이터 비우기
      setSelectedValue(null);
      setSelectedText(null);
    }
    if (setCurrentPage) {
      setCurrentPage(1);
    }
    if (setCate2Value) {
      setCate2Value(null);
    }
  };

  // 선택된 데이터가 있다면 해당 텍스트로 보여주기
  useEffect(() => {
    if (selectedValue) {
      if (placeholder === "발주단위") {
        const selectedOption = data.find((option) => option.text == selectedValue);
        if (selectedOption) {
          setSelectedText(selectedOption.text);
        } else {
          setSelectedText("");
        }
      } else {
        const selectedOption = data.find((option) => option.value == selectedValue);
        if (selectedOption) {
          setSelectedText(selectedOption.text);
        } else {
          setSelectedText("");
        }
      }
    } else {
      setSelectedText("");
    }
  }, [data, selectedValue]);

  // 외부 클릭 컨트롤
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (selectBoxRef.current && !selectBoxRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    // 컴포넌트가 mount 시 이벤트리스너 달기
    document.addEventListener("click", handleOutsideClick);

    // 컴포넌트가 unmount 시 이벤트리스너 삭제
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <SelectContainer width={width} widthMd={widthMd} ref={selectBoxRef}>
        <SelectBtn
          borderColor={borderColor}
          width={width}
          show={show}
          disabled={disabled}
          onClick={() => {
            setShow((state) => !state);
          }}
        >
          {selectedText ? selectedText : placeholder}
        </SelectBtn>
        {show && (
          <>
            <SelectWrap width={width}>
              {canSelectAll && (
                <SearchList key={-1} value={""} onClick={() => itemClick(-1)}>
                  <li>
                    <span>{placeholder}</span>
                  </li>
                </SearchList>
              )}
              {data?.map((item, idx) => (
                <SearchList key={idx} value={item.value} onClick={() => itemClick(item)}>
                  <li>
                    <span>{item.text}</span>
                  </li>
                </SearchList>
              ))}
            </SelectWrap>
          </>
        )}
      </SelectContainer>
    </>
  );
}

export default BasicSelect;
