import React from "react";
import styled from "styled-components";
import close from "../../../Assets/images/close.png";
import { Btn, FlexBox, Text } from "../../../Assets/css/common";

export default function BundleProductModal(props) {
  const {
    modal,
    setModal,
    data,
    setData
  } = props;

  const onClose = () => {
    setData([]);
    setModal(false);
  };

  return (
    <>
      {
        modal ? (
          <ModalBg>
            <ModalConts>
              <CancelBtn onClick={onClose}>
                <img src={close} />
              </CancelBtn>
              <Text
                color={"#2D2D2D"}
                size={"14px"}
                sizeMd={"16px"}
                weight={"700"}
                style={{ whiteSpace: "pre-wrap", lineHeight: 1.5 }}
              >
                묶음상품
              </Text>
              <FlexBox
                direction={"column"}
                align={"start"}
                padding={"14px"}
                paddingMd={"20px"}
                radius={"8px"}                
                margin={"16px 0 0"}
                marginMd={"20px 0 0"}
                background={"#F2F2F2"}
                gap={"4px"}
              >
                {
                  data?.length > 0 ? (
                    data?.map((item, idx) => (
                      <Text
                        key={idx}
                        color={"#6C6C6C"}
                        size={"12px"}
                        sizeMd={"14px"}
                        weight={"500"}
                      >
                        {item.product_name}
                      </Text>
                    ))
                  ) : (
                    ""
                  )
                }
              </FlexBox>
              <FlexBox margin={"20px 0 0"} marginMd={"30px 0 0"}>
                <Btn
                  background={"#dd1212"}
                  color={"#fff"}
                  radius={"8px"}
                  weight={"500"}
                  size={"12px"}
                  sizeMd={"14px"}
                  padding={"9px 22px"}
                  paddingMd={"10px 30px"}
                  onClick={onClose}
                >
                  확 인
                </Btn>
              </FlexBox>
            </ModalConts>
          </ModalBg>
        ) : (
          ""
        )
      }
    </>
  )
}

const ModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

const ModalConts = styled.div`
  background-color: #fff;
  max-width: 380px;
  max-height: 600px;
  width: 100%;
  padding: 40px 30px 30px;
  border-radius: 8px;
  position: relative;

  @media all and (max-width: 1024px) {
    max-width: 280px; // 390
    padding: 40px 20px 30px;
  }
`;

const CancelBtn = styled.button`
  position: absolute;
  right: 17.5px;
  top: 17.5px;

  @media all and (max-width: 1024px) {
    top: 12px;
    right: 12px;
  }
`;