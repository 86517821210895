import React from 'react';
import "./quillstyle.css";

export default function CustomToolbar() {
  return (
    <div id="toolbar">
      <select className="ql-font" defaultValue="Pretendard">
        <option value="Pretendard">Pretendard</option>
        <option value="Binggrae">Binggrae</option>
        <option value="Montserrat">Montserrat</option>
      </select>
      <select className="ql-header"></select>
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
      <button className="ql-strike"></button>
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
      <select className="ql-align"></select>
      <select className="ql-color"></select>
      <select className="ql-background"></select>
      <button className="ql-link" />
      <button className="ql-image"></button>
      <button className="ql-clean"></button>
    </div>
  )
};
