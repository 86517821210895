import React from "react";
import styled from "styled-components";
import HomeWeather from "./HomeWeather";
import EventSlider from "../../Components/Slider/EventSlider";

// 날씨 & 이벤트 배너

function HomeEvent(props) {
  const { event } = props;

  let bannerData = [];
  if (event.length > 0) {
    for (var a = 0; a < event.length; a++) {
      for (var i = 0; i < event[a].image_path.length; i++) {
        if (event[a].image_path[i]?.includes("type1")) {
          bannerData.push({
            idx: event[a].idx,
            image_path_1: event[a].image_path[i],
          });
        }
      }
    }
  }

  return (
    <>
      {/* 모바일 */}
      <div className="d-block d-md-none" style={{ marginTop: "20px" }}>
        <HomeWeather></HomeWeather>
        <SliderWrap>
          <EventSlider space={4} perview={1} contents={bannerData} link={"/notice/view"} />
        </SliderWrap>
      </div>
      {/* 웹 */}
      <div className="d-none d-md-block">
        <HomeWeather></HomeWeather>
        <SliderWrap>
          <EventSlider space={4} perview={1} contents={bannerData} link={"/notice/view"} />
        </SliderWrap>
      </div>
    </>
  );
}

const SliderWrap = styled.div`
  width: 100%;
  /* height: 254px; */
  height: 100%;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;

  @media all and (max-width: 1024px) {
    // height: 140px;
  }
`;

export default HomeEvent;
