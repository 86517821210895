import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import {
  Btn,
  HeaderContainer,
  Wrapper,
  Container,
  ContentContainer,
  FlexBox,
  Text,
  WhiteBox,
  TextBar,
  Title,
  TextInput,
} from "../../Assets/css/commonVer2";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import Header from "../../Components/Header/HeaderVer2";
import HeaderSub from "../../Components/Header/HeaderSub";
import API from "../../Utiles/API";
import Authority from "../../Components/Modal/Authority";
import { AddComma } from "../../Utiles/Custom";
import Loading from "../../Components/Loading";
import InfoModal from "../../Components/Modal/InfoModal";
import BeforeSelfGoodsSearchModal from "./Components/BeforeSelfGoodsSearchModal";
import Cate2Select from "../../Components/Select/Cate2Select";

// 상품관리 > 자점매입 상품관리 > 상품등록

function SelfGoodsWrite() {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [franchise_id] = useState(sessionStorage.getItem("franchise_id"));

  // 다루는 데이터
  const [slot_id] = useState(location.state?.slot_id);
  const [values, setValues] = useState({
    barcode_num: "",
    category2nd_id: "",
    product_name: "",
    consumer_price: "",
  });
  const [priceValue, setPriceValue] = useState("");

  // 모달들
  const [saveModal, setSaveModal] = useState(false);
  const [listModal, setListModal] = useState(false);
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [moveToBackModal, setMoveToBackModal] = useState(false);
  const [beforeSelfGoodsModal, setBeforeSelfGoodsModal] = useState(false);
  const [loading, setLoading] = useState(false);

  // 상품 등록 api
  const postProduct = async () => {
    setLoading(true);
    await API.post(`/slot/product`, {
      slot_id: parseInt(slot_id),
      franchise_id: parseInt(franchise_id),
      category2nd_id: parseInt(values.category2nd_id),
      barcode_num: values.barcode_num.trim(),
      product_name: values.product_name,
      consumer_price: parseInt(values.consumer_price),
    })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setMoveToBackModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setLoading(false);
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  // 이전상품등록 체크 api
  const chkBeforeSelfGoods = async () => {
    await API.get(`/slot/user/previous-product?franchise_id=${franchise_id}`)
      .then((res) => {
        if (res.data.length) {
          setBeforeSelfGoodsModal(true);
        } else {
          setTextMsg("이전에 등록했던 상품이 없습니다.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const onChangeCate2 = (e) => {
    setValues({ ...values, category2nd_id: e });
  };

  function onClickBarcodeBtn() {
    if (isMobile) {
      if (window.ReactNativeWebView !== undefined) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ method: "barcodeScan" }));
      } else {
        setTextMsg("전용앱에서만 사용가능합니다.");
        setTextModal(true);
      }
    } else {
      setTextMsg("전용앱에서만 사용가능합니다.");
      setTextModal(true);
    }
  }

  window.addEventListener("message", function (e) {
    let data;
    try {
      data = JSON.parse(e.data);
    } catch (err) {
      // 예외 처리 코드
      console.error(err);
      return;
    }

    if (data && data.barcodeValue) {
      setValues({ ...values, barcode_num: data.barcodeValue });
    }
  });

  // 입력값 바뀔때
  const handleChange = (e, isPriceValue) => {
    // 금액 관련일때
    if (isPriceValue) {
      const updatedValues = {
        ...values,
        [e.target.name]: e.target.value.replaceAll(",", ""),
      };
      setValues(updatedValues);
      setPriceValue(e.target.value ? AddComma(e.target.value.replaceAll(",", "")) : e.target.value);
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    if (e === 1) {
      setSaveModal(false);
      navigate(-1, { replace: true });
    } else {
      setSaveModal(false);
      postProduct();
    }
  };

  return (
    <>
      {sessionStorage.getItem("gubun") === "admin" ? (
        <Authority title={"매장점주"} />
      ) : (
        <>
          {loading ? <Loading /> : null}
          <HeaderSub title={"자점매입 상품 등록"} />
          <ContentContainer>
            <Wrapper type={2} pbM={"112px"}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    자점매입 상품 등록
                  </Title>
                )}
                <WhiteBox>
                  <FlexBox justify={"end"}>
                    <Btn
                      border={"1px solid #FF4A4A"}
                      color={"#FF4A4A"}
                      hoverColor={"#FFFFFF"}
                      hoverBackground={"#FF4A4A"}
                      hoverWeight={"700"}
                      padding={"9px 19px"}
                      size={"12px"}
                      sizeMd={"14px"}
                      radius={"6px"}
                      weight={"400"}
                      lineHeight={"22px"}
                      onClick={() => chkBeforeSelfGoods()}
                    >
                      이전 상품 조회
                    </Btn>
                  </FlexBox>
                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    margin={"10px 0 0 0"}
                    marginMd={"20px 0 0 0"}
                    gap={"10px"}
                    gapMd={"20px"}
                  >
                    <Contents flex={1}>
                      <Text
                        align={"left"}
                        weight={"500"}
                        color={"#2d2d2d"}
                        size={"12px"}
                        sizeMd={"14px"}
                        padding={"0 0 4px 0"}
                        paddingMd={"0 0 10px 0"}
                      >
                        상품 분류
                      </Text>
                      <Contents>
                        <Cate2Select
                          width={"100%"}
                          idx={1}
                          cate2Value={values.category2nd_id}
                          setCate2Value={onChangeCate2}
                          borderColor={"#666"}
                        />
                      </Contents>
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"500"}
                        color={"#2d2d2d"}
                        size={"12px"}
                        sizeMd={"14px"}
                        padding={"0 0 4px 0"}
                        paddingMd={"0 0 10px 0"}
                      >
                        소비자가
                      </Text>
                      <div style={{ position: "relative" }}>
                        <TextInput
                          type={"text"}
                          placeholder={"0"}
                          align={"right"}
                          style={{ paddingRight: "25px", width: "100%" }}
                          name="consumer_price"
                          value={priceValue}
                          onChange={(e) => {
                            const input = e.target.value.replaceAll(",", "");
                            if (/^\d*$/.test(input)) {
                              handleChange(e, true);
                            }
                          }}
                        />
                        <Text
                          size={"14px"}
                          color={"6c6c6c"}
                          weight={"400"}
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "11px",
                          }}
                        >
                          원
                        </Text>
                      </div>
                    </Contents>
                  </FlexBox>

                  <FlexBox
                    justify={"start"}
                    align={"start"}
                    margin={"10px 0 0 0"}
                    marginMd={"20px 0 0 0"}
                    direction={"column"}
                    directionMd={"row"}
                    gap={"10px"}
                    gapMd={"20px"}
                  >
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"500"}
                        color={"#2d2d2d"}
                        size={"12px"}
                        sizeMd={"14px"}
                        padding={"0 0 4px 0"}
                        paddingMd={"0 0 10px 0"}
                      >
                        상품명
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"상품명을 입력해주세요."}
                        name="product_name"
                        value={values.product_name}
                        onChange={(e) => {
                          handleChange(e, false);
                        }}
                      />
                    </Contents>
                    <Contents>
                      <Text
                        align={"left"}
                        weight={"500"}
                        color={"#2d2d2d"}
                        size={"12px"}
                        sizeMd={"14px"}
                        padding={"0 0 4px 0"}
                        paddingMd={"0 0 10px 0"}
                      >
                        바코드 번호
                      </Text>
                      <TextInput
                        style={{ width: "100%" }}
                        type={"text"}
                        placeholder={"바코드번호를 입력해주세요."}
                        name="barcode_num"
                        value={values.barcode_num}
                        onChange={(e) => {
                          handleChange(e, false);
                        }}
                      />
                    </Contents>
                  </FlexBox>
                  <Btn
                    background={"#F2F2F2"}
                    border={"#F2F2F2"}
                    color={"#666"}
                    radius={"6px"}
                    padding={"9px 19px"}
                    paddingMd={"9px 19px"}
                    margin={"10px 0"}
                    width={"30%"}
                    widthMd={"unset"}
                    weight={"700"}
                    size={"12px"}
                    sizeMd={"14px"}
                    lineHeight={"22px"}
                    className="d-block d-md-none"
                    onClick={() => onClickBarcodeBtn()}
                  >
                    바코드 스캔
                  </Btn>
                </WhiteBox>

                <FlexBox justify={"end"} margin={"10px 0 "} marginMd={"20px 0 "}>
                  <FlexBox gap={"10px"}>
                    <Btn
                      background={"#C7C7C7"}
                      border={"#C7C7C7"}
                      color={"#fff"}
                      radius={"6px"}
                      padding={"9px 19px"}
                      weight={"700"}
                      size={"12px"}
                      sizeMd={"14px"}
                      lineHeight={"22px"}
                      onClick={() => setListModal(true)}
                    >
                      목 록
                    </Btn>
                    <Btn
                      background={"#FF4A4A"}
                      border={"#FF4A4A"}
                      color={"#fff"}
                      radius={"6px"}
                      weight={"700"}
                      size={"12px"}
                      sizeMd={"14px"}
                      padding={"9px 19px"}
                      lineHeight={"22px"}
                      onClick={() => {
                        if (!values.category2nd_id) {
                          setTextMsg("상품분류를 선택해주세요.");
                          setTextModal(true);
                          return;
                        }
                        if (!values.consumer_price) {
                          setTextMsg("소비자가를 입력해주세요.");
                          setTextModal(true);
                          return;
                        }

                        if (values.consumer_price % 100 !== 0) {
                          setTextMsg("소비자가는 100원 단위로 \n등록할 수 있습니다.");
                          setTextModal(true);
                          return;
                        }
                        if (!values.product_name) {
                          setTextMsg("상품명을 입력해주세요.");
                          setTextModal(true);
                          return;
                        }
                        if (!values.barcode_num) {
                          setTextMsg("바코드번호를 입력해주세요.");
                          setTextModal(true);
                          return;
                        }
                        setSaveModal(true);
                      }}
                    >
                      저 장
                    </Btn>
                  </FlexBox>
                </FlexBox>
                <div className="greyBox" style={{ marginTop: "24px", padding: isMobile ? "20px 20px" : "28px 40px" }}>
                  <Text
                    color={"#666666"}
                    size={"14px"}
                    sizeMd={"18px"}
                    weight={"700"}
                    align={"left"}
                    margin={"0 0 10px 0"}
                    style={{
                      latterSpacing: "-0.36px",
                      lineHeight: "normal",
                    }}
                  >
                    주의 사항
                  </Text>
                  <TextBar
                    align={"left"}
                    size={"14px"}
                    style={{
                      latterSpacing: "-0.28px",
                      lineHeight: "25px",
                    }}
                  >
                    상품 등록 후 반드시 <BoldText>검수요청</BoldText>을 해주셔야 검수가 진행되며 검수 승인이 되어야 상품
                    판매가 가능합니다.
                  </TextBar>
                  <TextBar
                    align={"left"}
                    size={"14px"}
                    style={{
                      latterSpacing: "-0.28px",
                      lineHeight: "25px",
                    }}
                  >
                    구매하신 상품 중 일부 상품의 경우 검수를 통해 지원되지 않을 수 있습니다. (유통기한이 있는 상품,
                    출처가 불분명한 상품 등)
                  </TextBar>
                </div>
              </Container>
              <ConfirmModal
                modal={listModal}
                setModal={setListModal}
                onSubmit={() => onSubmit(1)}
                description={"상품 등록을 취소하고\n목록화면으로 이동하시겠습니까?"}
              />
              <ConfirmModal
                modal={saveModal}
                setModal={setSaveModal}
                onSubmit={() => onSubmit(2)}
                description={"상품 등록을 완료하시겠습니까?\n등록 후 검수요청을 진행해주세요."}
              />

              {beforeSelfGoodsModal ? (
                <BeforeSelfGoodsSearchModal
                  setModal={setBeforeSelfGoodsModal}
                  setValues={setValues}
                  setPriceValue={setPriceValue}
                />
              ) : (
                ""
              )}
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
              <InfoModal
                modal={moveToBackModal}
                setModal={setMoveToBackModal}
                description={"등록이 완료되었습니다."}
                mode={"goBack"}
              />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

const Contents = styled.div`
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  background-color: ${(props) => props.background || ""};
  width: ${(props) => props.width || "100%"};
  border-radius: ${(props) => props.radius || "8px"};
`;

const BoldText = styled.span`
  font-weight: bold;
`;

export default SelfGoodsWrite;
