import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./slide.css";
import { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Btn, Text } from "../../Assets/css/commonVer2";
import { AddComma } from "../../Utiles/Custom";
import img_noimage470 from "../../Assets/images/no_image_470px.jpg";

function Slider(props) {
  const navigate = useNavigate();
  const { space, perview, pagination, naviPrev, naviNext, contents, styles, breaks, link } = props;

  return (
    <Swiper
      modules={[Navigation, Pagination, A11y, Autoplay]}
      navigation={
        naviPrev && naviNext
          ? {
              prevEl: `.${naviPrev}`,
              nextEl: `.${naviNext}`,
            }
          : false
      }
      pagination={
        pagination ? { el: `.${pagination}`, clickable: true, bulletClass: `swiper-pagination-bullet` } : false
      }
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      loop={true}
      breakpoints={breaks}
      className="productSwiper"
    >
      <>
        {contents?.map((e, i) => (
          <SwiperSlide
            key={i}
            style={{ cursor: link ? "pointer" : "" }}
            onClick={() => {
              if (link) navigate(link, { state: { idx: e.idx } });
            }}
          >
            <SlideImg stopSale={e?.sale_status === "extinction"}>
              <div>
                <img src={e.image_path_1 ? `${process.env.REACT_APP_S3_KEY}${e.image_path_1}` : img_noimage470} />
              </div>
            </SlideImg>

            {e.sale_status === "sale" && e.text && (
              <SlideText className={styles?.align}>
                <Text color={"#2d2d2d"} weight={"500"} size={"10px"} sizeMd={"14px"} className="ellipsis">
                  {e.text}
                </Text>
              </SlideText>
            )}
            {/* 입고지연 태그 */}
            {e.sale_status === "missing" && (
              <SlideText className={styles?.align}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                  }}
                >
                  <ProductTag className="missingTag">
                    <Text size={"8px"} sizeMd={"12px"} color={"#fff"} lineHeight={"100%"} weight={"400"}>
                      입고지연
                    </Text>
                  </ProductTag>
                  {e.text && (
                    <Text color={"#000"} size={"12px"} className="ellipsis">
                      {e.text}
                    </Text>
                  )}
                </div>
              </SlideText>
            )}
            {/* 단종 태그 */}
            {e.sale_status === "extinction" && (
              <SlideText className={styles?.align}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    minHeight: "16px",
                  }}
                >
                  <ProductTag className="extinctionTag">
                    <Text size={"8px"} sizeMd={"12px"} color={"#fff"} lineHeight={"100%"} weight={"400"}>
                      단종
                    </Text>
                  </ProductTag>
                  {e.text && (
                    <Text color={"#000"} size={"12px"} className="ellipsis">
                      {e.text}
                    </Text>
                  )}
                </div>
              </SlideText>
            )}
            {e.product_name && (
              <SlideText className={styles?.align}>
                <Text size={"10px"} sizeMd={"14px"} color={"#666"} weight={"400"}>
                  {e.brand}
                </Text>
                <Text
                  color={"#000"}
                  weight={"500"}
                  padding={"5px 0 0 0"}
                  size={"12px"}
                  sizeMd={"16px"}
                  className="ellipsis"
                >
                  {e.product_name}
                </Text>
                {e.shipping_price != null && e.shipping_price != 0 && (
                  <Text
                    color={"#2d2d2d"}
                    weight={"500"}
                    padding={"4px 0 0 0"}
                    paddingMd={"8px 0 0 0"}
                    size={"10px"}
                    sizeMd={"14px"}
                  >
                    공급가 : {AddComma(e.shipping_price)}원
                  </Text>
                )}
                {e.consumer_price && (
                  <Text
                    color={"#2d2d2d"}
                    weight={"500"}
                    padding={"3px 0 0 0"}
                    paddingMd={"5px 0 0 0"}
                    size={"10px"}
                    sizeMd={"14px"}
                  >
                    소비자가 : {AddComma(e.consumer_price)}원
                  </Text>
                )}
              </SlideText>
            )}
          </SwiperSlide>
        ))}
      </>
      <div className={`swiper-pagination ${pagination}`}></div>
    </Swiper>
  );
}
const SlideImg = styled.div`
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background: #fff;

  div {
    position: relative;
    padding-bottom: 100%;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      display: block;
    }

    ${(props) =>
      props.stopSale &&
      `
      &::before {
        content: "";
        position: absolute;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        background: linear-gradient(0deg, rgba(30, 30, 30, 0.6) 0%, rgba(30, 30, 30, 0.6) 100%), #1e1e1e;
        z-index: 10;
        border-radius: 10px;
        opacity: 0.6;
        pointer-events: none;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &::after {
        content: "단종";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 14px;
        z-index: 11;
        background: #1e1e1e;
        padding: 6px 12px;
        font-weight: 700;

        @media (max-width: 1024px) {
          font-size: 8px;
          padding: 5px;
        }
      }
    `}
  }
`;

const SlideText = styled.div`
  padding-top: 10px;

  &.left * {
    text-align: left;

    @media (max-width: 280px) {
      text-align: center;
    }
  }

  @media all and (max-width: 1024px) {
    padding-top: 5px;
  }
`;

const ProductTag = styled.div`
  background: #c7c7c7;
  margin-right: 10px;
  padding: 4px 6px;
  min-width: 34px;
  text-align: center;
`;

export default Slider;
