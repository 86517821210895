import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Title } from "../../Assets/css/commonVer2";

// 문구는 못말려 영상

function HomeVideo() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  return (
    <>
      {isMobile ? (
        <div style={{ marginTop: "20px" }}>
          <Title size={"18px"} color={"#1E1E1E"} weight={"bold"}>
            문구는 못말려 영상 🎥
          </Title>
          <VideoWrap>
            <iframe
              src="https://www.youtube.com/embed/ku1L7fA2hdY" //https://www.youtube.com/embed/jl9-Z3GDfrU //30 : ku1L7fA2hdY, 1'30 : yNUyKumFELQ
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </VideoWrap>
        </div>
      ) : (
        <div>
          <Title
            size={"26px"}
            color={"#1E1E1E"}
            weight={"bold"}
            style={{
              marginTop: "30px",
            }}
          >
            문구는 못말려 영상 🎥
          </Title>
          <VideoWrap>
            <iframe
              src="https://www.youtube.com/embed/ku1L7fA2hdY" //https://www.youtube.com/embed/jl9-Z3GDfrU //30 : ku1L7fA2hdY, 1'30 : yNUyKumFELQ
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </VideoWrap>
        </div>
      )}
    </>
  );
}

const VideoWrap = styled.div`
  position: relative;
  /* padding-bottom: 56.5%; */
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  width: 100%;
  // height: 610px;
  /* height: 100%; */
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    padding-top: 56.25%; /* 16:9 aspect ratio */
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1024px) {
    // height: 160px;
  }
`;

export default HomeVideo;
