import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import { ContentContainer, Container, Wrapper, Text, FlexBox, WhiteBox, Title } from "../../Assets/css/commonVer2";
import Header from "../../Components/Header/HeaderVer2";

// 이용약관 상세보기

export default function TermsView() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  return (
    <>
      {isMobile && <Header title={"이용약관"} />}
      <ContentContainer>
        <Wrapper type={1}>
          <Container>
            {!isMobile && (
              <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                이용약관
              </Title>
            )}
            <WhiteBox margin={"0 0"}>
              <FlexBox align={"starts"} direction={"column"}>
                <BasicTermsDiv>
                  <BoldText>제1조 (목적) </BoldText>
                  <BasicText>
                    이 약관은 (주)문구는못말려(이하 “당사”라고 합니다)에서 운영하는 인터넷 폐쇄몰 사이트
                    https://admin.cantstopmoongu.kr/) (이하 “폐쇄몰”이라 합니다)에서 제공하는 인터넷 관련 서비스(이하
                    "서비스"라 합니다)를 이용함에 있어 “당사”와 이용자의 권리, 의무 및 책임 사항을 규정함을 목적으로
                    합니다.
                  </BasicText>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제2조 (정의) </BoldText>
                  <Tap>
                    <BasicText>
                      1. “폐쇄몰”이란 당사가 재화 또는 용역(이하 "재화 등"이라 함)을 이용자에게 제공하기 위하여 컴퓨터,
                      TV, 모바일 등 정보통신 설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며,
                      아울러 사이버몰을 운영하는 사업자의 의미로도 사용합니다.
                    </BasicText>
                  </Tap>
                  <Tap>
                    <BasicText>
                      2. "이용자"란 “폐쇄몰”에 접속하여 이 약관에 따라 “폐쇄몰”이 제공하는 서비스를 받는 회원을
                      말합니다.
                    </BasicText>
                  </Tap>
                  <Tap>
                    <BasicText>
                      3. "회원"이라 함은 “폐쇄몰”에 회원 등록을 한 자로서, “폐쇄몰”이 제공하는 서비스를 계속적으로
                      이용할 수 있는 자를 말합니다.
                    </BasicText>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제3조 (약관 등의 명시와 설명 및 개정)</BoldText>
                  <Tap>
                    <BasicText>
                      1. “폐쇄몰”은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수
                      있는 곳의 주소를 포함), 전화번호, 사업자등록번호, 통신판매업 신고번호, 개인 정보보호 책임자 등을
                      이용자가 쉽게 알 수 있도록 “폐쇄몰”의 초기 서비스 화면(전면)에 게시합니다. 다만, 약관의 내용은
                      이용자가 연결 화면을 통하여 볼 수 있도록 할 수 있습니다.
                    </BasicText>
                  </Tap>
                  <Tap>
                    <BasicText>
                      2. “폐쇄몰”은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약 철회, 배송 책임,
                      환불 조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결 화면 또는 팝업 화면 등을
                      제공하여 이용자의 확인을 구하여야 합니다. ※ 청약 철회, 배송, 환불에 대한 안내 보기
                    </BasicText>
                  </Tap>
                  <Tap>
                    <BasicText>
                      3. “폐쇄몰”은 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」,
                      「전자문서 및 전자거래 기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및
                      정보보호 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할
                      수 있습니다.
                    </BasicText>
                  </Tap>
                  <Tap>
                    <BasicText>
                      4. “폐쇄몰”이 약관을 개정할 경우에는 적용 일자 및 개정 사유를 명시하여 현행 약관과 함께 “폐쇄몰”의
                      초기 화면에 그 적용 일자 7일 전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관
                      내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예 기간을 두고 공지합니다. 이 경우 “폐쇄몰”은
                      개정 전/후 내용을 명확히 비교하여 이용자가 알기 쉽도록 표시합니다.
                    </BasicText>
                  </Tap>
                  <Tap>
                    <BasicText>
                      5. “폐쇄몰”이 개정 약관을 공지 또는 통지하면서 회원에게 일정 기한 내에 의사표시를 하지 않으면
                      의사표시가 표명된 것으로 본다는 뜻을 명확하게 따로 공지 또는 고지하였음에도 회원이 명시적으로 거부
                      의사를 표시하지 아니한 경우 회원이 개정 약관에 동의한 것으로 봅니다. 또한, 회원이 개정 약관의
                      적용에 동의하지 않는 경우 “폐쇄몰”은 개정 약관의 내용을 적용할 수 없으며, 이 경우, 회원은
                      이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는
                      “폐쇄몰”은 이용계약을 해지할 수 있습니다.
                    </BasicText>
                  </Tap>
                  <Tap>
                    <BasicText>
                      6. “폐쇄몰”이 약관을 개정할 경우에는 그 개정 약관은 그 적용 일자 이후에 체결되는 계약에만 적용되고
                      그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관 조항이 그대로 적용됩니다. 다만 이미 계약을
                      체결한 이용자가 개정 약관 조항의 적용을 받기를 원하는 뜻을 제4항에 의한 개정 약관의 공지 기간 내에
                      “폐쇄몰”에 송신하여 “폐쇄몰”의 동의를 받은 경우에는 개정 약관 조항이 적용됩니다.
                    </BasicText>
                  </Tap>
                  <Tap>
                    <BasicText>
                      7. 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 「전자상거래 등에서의 소비자보호에
                      관한 법률」, 「약관의 규제에 관한 법률」, 공정거래위원회가 정하는 「전자상거래 등에서의 소비자보호
                      지침」 및 관계 법령 또는 상관례에 따릅니다.
                    </BasicText>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제4조 (서비스의 제공 및 변경)</BoldText>
                  <Tap>
                    <BasicText>1. “폐쇄몰”은 다음과 같은 업무를 수행합니다.</BasicText>
                    <Tap>
                      <BasicText>1) 재화 또는 용역에 대한 정보 제공 및 구매 계약의 체결</BasicText>
                      <BasicText>2) 구매 계약이 체결된 재화 또는 용역의 배송</BasicText>
                      <BasicText>3) 기타 “폐쇄몰”이 정하는 업무</BasicText>
                    </Tap>
                    <BasicText>
                      2. “폐쇄몰”은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에
                      의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용
                      및 제공 일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
                    </BasicText>
                    <BasicText>
                      3. “폐쇄몰”이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의
                      변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 본 약관 8조에서 정한 방법 등으로 즉시
                      통지합니다.
                    </BasicText>
                    <BasicText>
                      4. 전항의 경우 “폐쇄몰”은 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, “폐쇄몰”이 고의 또는
                      과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
                    </BasicText>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제5조 (서비스의 중단)</BoldText>
                  <Tap>
                    <BasicText>
                      1. “폐쇄몰”은 컴퓨터 등 전기통신설비의 보수 점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한
                      경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
                    </BasicText>

                    <BasicText>
                      2. “폐쇄몰”은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은
                      손해에 대하여 배상합니다. 단, “폐쇄몰”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지
                      아니합니다.
                    </BasicText>
                    <BasicText>
                      3. 사업 종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는
                      “폐쇄몰”은 제8조에 정한 방법으로 이용자에게 통지하고 당초 “폐쇄몰”에서 제시한 조건에 따라
                      소비자에게 보상합니다. 다만, “폐쇄몰”이 보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지
                      또는 적립금 등을 “폐쇄몰”에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게
                      지급합니다.
                    </BasicText>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제6조 (회원가입)</BoldText>
                  <Tap>
                    <BasicText>
                      1. 회원으로 가입하고자 하는 이용자는 “폐쇄몰”이 정한 가입 양식에 정해진 사항을 기입한 후 본 약관과
                      "개인 정보의 수집, 제공 및 활용 동의서"에 동의한다는 의사표시를 함으로써 회원가입을 신청합니다.
                    </BasicText>

                    <BasicText>
                      2. “폐쇄몰”은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각호에 해당하지 않는 한
                      회원으로 등록합니다.
                    </BasicText>
                    <Tap>
                      <BasicText>
                        1) 가입 신청자가 이 약관 제7조 제3항에 의하여 이전에 회원 자격을 상실한 적이 있는 경우, 다만
                        제7조 제3항에 의한 회원 자격 상실 후 6개월이 경과한 자로서 “폐쇄몰”의 회원 재가입 승낙을 얻은
                        경우에는 예외로 합니다.
                      </BasicText>
                      <BasicText>2) 등록 내용에 허위, 기재 누락, 오기가 있는 경우.</BasicText>
                      <BasicText>
                        3) 기타 회원으로 등록하는 것이 “폐쇄몰”의 기술상 현저히 지장이 있다고 판단되는 경우.
                      </BasicText>
                      <BasicText>4) 회원가입 신청일 현재 만 14세 미만인 경우.</BasicText>
                    </Tap>
                    <BasicText>
                      3. 회원 가입 계약의 성립 시기는 “폐쇄몰”의 승낙이 회원에게 도달한 시점으로 합니다.
                    </BasicText>
                    <BasicText>
                      4. 회원은 회원가입 시 등록 사항에 변경이 있는 경우, 상당한 기간 이내에 “폐쇄몰”에 대하여 회원정보
                      수정 등의 방법으로 그 변경사항을 알려야 합니다.
                    </BasicText>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제7조 (회원 탈퇴 및 자격 정지 등)</BoldText>
                  <Tap>
                    <BasicText>
                      1. 회원은 “폐쇄몰”에 언제든지 탈퇴를 요청할 수 있으며 “폐쇄몰”은 즉시 회원 탈퇴를 처리합니다.
                    </BasicText>
                    <BasicText>
                      2. 회원이 다음 각호의 사유에 해당하는 경우, “폐쇄몰”은 회원 자격을 제한 및 정지시킬 수 있습니다.
                    </BasicText>
                    <Tap>
                      <BasicText>1) 회원이 사망한 경우</BasicText>
                      <BasicText>
                        2) “폐쇄몰”을 이용하여 구입한 재화 등의 대금, 기타 “폐쇄몰” 이용에 관련하여 회원이 부담하는
                        채무를 기일에 지급하지 않는 경우
                      </BasicText>
                      <BasicText>3) 가입 신청 시 허위 내용을 등록한 경우</BasicText>
                      <BasicText>4)다른 사람의 ID와 비밀번호 또는 그 개인 정보를 도용하는 경우</BasicText>
                      <BasicText>
                        5)다음 각 호에 해당하는 행위를 하거나, 기타 “폐쇄몰”을 이용하여 법령 또는 이 약관이 금지하거나
                        공서양속에 반하는 행위를 하는 경우
                      </BasicText>
                      <Tap>
                        <BasicText>
                          가)“폐쇄몰”에서 다른 사람의 명예를 훼손하거나 다른 사람의 “폐쇄몰” 이용을 방해하는 행위
                        </BasicText>
                        <BasicText>나) “폐쇄몰”에 음란물을 게재하거나 음란사이트를 링크하는 행위</BasicText>
                      </Tap>
                      <BasicText>
                        6) 다음 각 호에 해당하는 행위를 하거나, 기타 “폐쇄몰”의 정상적인 영업활동 및 서비스 운영을
                        고의로 방해하는 행위를 하는 경우
                      </BasicText>
                      <Tap>
                        <BasicText>
                          가) “폐쇄몰”의 이용과정에서 “폐쇄몰”의 직원에게 폭언, 협박 또는 음란한 언행 등을 하는 행위
                        </BasicText>
                        <BasicText>
                          나) “폐쇄몰”을 통해 재화 등을 구매한 후 정당한 이유 없이 상습 또는 반복적으로 취소, 반품하여
                          업무를 방해하는 행위
                        </BasicText>
                      </Tap>
                    </Tap>
                    <BasicText>
                      3. 회원 가입 계약의 성립 시기는 “폐쇄몰”의 승낙이 회원에게 도달한 시점으로 합니다.
                    </BasicText>
                    <BasicText>
                      4. 회원은 회원가입 시 등록 사항에 변경이 있는 경우, 상당한 기간 이내에 “폐쇄몰”에 대하여 회원정보
                      수정 등의 방법으로 그 변경사항을 알려야 합니다.
                    </BasicText>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제8조 (회원에 대한 통지)</BoldText>
                  <Tap>
                    <BasicText>
                      1. “폐쇄몰”이 회원에 대한 통지를 하는 경우, 회원이 “폐쇄몰”과 미리 약정하여 지정한 전자우편 주소로
                      할 수 있습니다.
                    </BasicText>
                    <BasicText>
                      2. “폐쇄몰”은 불특정 다수 회원에 대한 통지의 경우 1주일 이상 “폐쇄몰” 게시판에 게시함으로써 개별
                      통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는
                      개별 통지를 합니다.
                    </BasicText>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제9조 (구매 신청)</BoldText>
                  <Tap>
                    <BasicText>
                      1. “폐쇄몰” 이용자는 “폐쇄몰”상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며,
                      “폐쇄몰”은 이용자가 구매를 신청함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
                    </BasicText>
                    <Tap>
                      <BasicText>1) 재화 등의 검색 및 선택</BasicText>
                      <BasicText>2) 성명, 주소, 전화번호, 전자우편 주소(또는 이동전화번호) 등의 입력</BasicText>
                      <BasicText>
                        3) 약관 내용, 청약 철회권이 제한되는 서비스, 배송료, 설치비 등의 비용 부담과 관련한 내용에 대한
                        확인
                      </BasicText>
                      <BasicText>
                        4) 이 약관에 동의하고 위 3호의 사항을 확인하거나 거부하는 표시(예. 마우스 클릭)
                      </BasicText>
                      <BasicText>5) 재화 등의 구매 신청 및 이에 관한 확인 또는 “폐쇄몰”의 확인에 대한 동의</BasicText>
                      <BasicText>6) 결제 방법의 선택</BasicText>
                      <BasicText>7) 기타 “폐쇄몰”이 별도로 정하는 절차</BasicText>
                    </Tap>
                    <BasicText>
                      2. “폐쇄몰”이 제3자에게 구매자 개인 정보를 제공•위탁할 필요가 있는 경우 실제 구매 신청 시 구매자의
                      동의를 받아야 하며, 회원가입 시 미리 포괄적으로 동의를 받지 않습니다. 이때 “폐쇄몰”은 제공되는
                      개인 정보 항목, 제공받는 자, 제공받는 자의 개인 정보 이용 목적 및 보유‧이용 기간 등을 구매자에게
                      명시하여야 합니다. 다만 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제25조 제1항에 의한
                      개인 정보 취급 위탁의 경우 등 관련 법령에 달리 정함이 있는 경우에는 그에 따릅니다.
                    </BasicText>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제10조 (계약의 성립)</BoldText>
                  <Tap>
                    <BasicText>
                      1. “폐쇄몰”은 제9조와 같은 구매 신청에 대하여 다음 각호에 해당하면 승낙하지 않을 수 있습니다.
                      다만, 미성년자와 계약을 체결하는 경우에는 법정 대리인의 동의를 얻지 못하면 미성년자 본인 또는
                      법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
                    </BasicText>
                    <Tap>
                      <BasicText>1) 신청 내용에 허위, 기재 누락, 오기가 있는 경우</BasicText>
                      <BasicText>2) 미성년자가 「청소년 보호법」에서 금지하는 재화 및 용역을 구매하는 경우</BasicText>
                      <BasicText>
                        3) 상행위(재판매)를 목적으로 구매하는 거래이거나, 거래 정황상 상행위(재판매)를 목적으로 한
                        구매로 판단되는 경우
                      </BasicText>
                      <BasicText>
                        4) 기타 구매 신청에 승낙하는 것이 “폐쇄몰” 기술상 현저히 지장이 있다고 판단하는 경우
                      </BasicText>
                    </Tap>
                    <BasicText>
                      2. “폐쇄몰”의 승낙이 제12조 제1항의 수신 확인 통지 형태로 이용자에게 도달한 시점에 계약이 성립한
                      것으로 봅니다.
                    </BasicText>
                    <BasicText>
                      3. “폐쇄몰”의 승낙의 의사 표시에는 이용자의 구매 신청에 대한 확인 및 판매 가능 여부, 구매 신청의
                      정정 취소 등에 관한 정보 등을 포함하여야 합니다.
                    </BasicText>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제11조 (지급 방법)</BoldText>
                  <Tap>
                    <BasicText>
                      “폐쇄몰”에서 구매한 재화 또는 용역에 대한 대금 지급 방법은 다음 각호의 방법 중 가용한 방법으로 할
                      수 있습니다. 단, “폐쇄몰”은 이용자의 지급 방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도
                      추가하여 징수할 수 없습니다.
                    </BasicText>
                    <Tap>
                      <BasicText>1) 온라인 무통장 입금, 폰뱅킹, 인터넷 뱅킹, 메일 뱅킹 등의 각종 계좌 이체</BasicText>
                      <BasicText>2) 선불 카드, 직불 카드, 신용카드 등의 각종 카드 결제</BasicText>
                      <BasicText>3) 전자 화폐에 의한 결제</BasicText>
                      <BasicText>4) 마일리지 등 “폐쇄몰”이 지급한 포인트에 의한 결제</BasicText>
                      <BasicText>5) “폐쇄몰”과 계약을 맺었거나 “폐쇄몰”이 인정한 상품권에 의한 결제</BasicText>
                      <BasicText>6) 기타 전자적 지급 방법에 의한 대금 지급 등</BasicText>
                    </Tap>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제12조 (수신 확인 통지, 구매 신청 변경 및 취소)</BoldText>
                  <Tap>
                    <BasicText>
                      1. “폐쇄몰”은 이용자의 구매 신청이 있는 경우 이용자에게 수신 확인 통지를 합니다.
                    </BasicText>
                    <BasicText>
                      2. 수신 확인 통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인 통지를 받은 후
                      즉시 구매 신청 변경 및 취소를 요청할 수 있고 “폐쇄몰”은 배송 전에 이용자의 요청이 있는 경우에는
                      지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제15조 청약 철회 등에
                      관한 규정에 따릅니다.
                    </BasicText>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제13조 (재화 등의 공급)</BoldText>
                  <Tap>
                    <BasicText>
                      1. “폐쇄몰”은 이용자와 재화 등의 공급 시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한
                      날부터 7일 이내에 재화 등을 배송할 수 있도록 주문 제작, 포장 등 기타의 필요한 조치를 취합니다.
                      다만 “폐쇄몰”이 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은
                      날부터 3영업일 이내에 조치를 취합니다. 다만, 소비자와 “폐쇄몰”간 재화 등의 공급 시기에 관하여
                      별도의 약정이 있는 경우에는 그러하지 아니합니다. 이때 “폐쇄몰”은 이용자가 재화 등의 공급 절차 및
                      진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
                    </BasicText>
                    <BasicText>
                      2. “폐쇄몰”은 이용자가 구매한 재화에 대해 배송 수단, 수단별 배송 비용 부담자, 수단별 배송 기간
                      등을 명시합니다. 만약 “폐쇄몰”이 약정 배송 기간을 초과한 경우에는 그로 인한 이용자의 손해를
                      배상하여야 합니다. 다만 “폐쇄몰”이 고의, 과실이 없음을 입증한 경우에는 그러하지 아니합니다.
                    </BasicText>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제14조 (환급)</BoldText>
                  <Tap>
                    <BasicText>
                      “폐쇄몰”은 이용자가 구매 신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체
                      없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터
                      3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.
                    </BasicText>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제15조 (청약 철회 등)</BoldText>
                  <Tap>
                    <BasicText>
                      1. “폐쇄몰”과 재화 등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한
                      법률」 제13조 제2항에 따른 계약 내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이
                      늦게 이루어진 경우에는 재화 등을 공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일
                      이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에 관하여 「전자상거래 등에서의 소비자보호에
                      관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다.
                    </BasicText>
                    <BasicText>
                      2. 이용자는 재화 등을 배송받은 경우 다음 각호에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.
                    </BasicText>
                    <Tap>
                      <BasicText>
                        1) 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우 (다만, 단순히 재화 등의 내용을
                        확인하기 위하여 포장 등을 훼손한 경우는 제외)
                      </BasicText>
                      <BasicText>
                        2) 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우
                      </BasicText>
                      <BasicText>
                        3) 패키지 상품의 경우 구성상품 일부의 개봉/설치/사용으로 상품가치가 현저히 감소된 경우
                      </BasicText>
                      <Tap>
                        <BasicText>
                          ※ 특히, 잉크/토너의 경우 상품 박스 훼손 및 내부 비닐포장 개봉 시 교환/반품이 불가합니다. 상품
                          주문 전 호환 가능 상품을 반드시 확인하시고 주문해주시기 바랍니다.
                        </BasicText>
                        <BasicText>
                          ※ 무약정폰의 경우 반품은 상품을 수령하신 날부터 7일 이내, 통신사 개통 이전에만 가능합니다.
                        </BasicText>
                      </Tap>
                      <BasicText>
                        4) 시간의 경과에 의하여 재판매가 곤란할 정도로 재화 등의 가치가 현저히 감소한 경우
                      </BasicText>
                      <BasicText>
                        5) 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우
                      </BasicText>
                      <BasicText>6) 설치 완료 상품에 하자가 없는 경우</BasicText>
                      <BasicText>
                        7) 상품 등의 내용이 표시ㆍ광고 내용 및 계약 내용과 같고, 별도의 하자가 없음에도 단순 변심으로
                        인한 교환을 요구하는 경우
                      </BasicText>
                      <BasicText>
                        8) 용역 또는 「문화산업진흥 기본법」 제2조 제5호의 디지털콘텐츠의 제공이 개시된 경우 (다만
                        가분적 용역 및 가분적 디지털콘텐츠로 구성된 계약의 경우에는 제공이 개시되지 아니한 부분에
                        대하여는 그러하지 아니합니다)
                      </BasicText>
                      <BasicText>
                        9) 주문에 따라 개별적으로 생산되는 물품 등 사전에 해당 거래에 대하여 별도로 고지하고 이용자의
                        서면(전자 문서 포함) 동의를 받은 경우
                      </BasicText>
                      <BasicText>
                        10) 기타, 「전자상거래 등에서의 소비자보호에 관한 법률」 등 관계 법령이 정하는 교환/반품 제한
                        사유에 해당되는 경우
                      </BasicText>
                    </Tap>
                    <BasicText>
                      3. 제2항 제2호 내지 제3호, 제5호의 경우에는 “폐쇄몰”이 사전에 청약 철회 등이 제한되는 사실을
                      소비자가 쉽게 알 수 있는 곳에 명기하거나 시용 상품을 제공하는 등의 조치를 하지 않았다면 이용자의
                      청약 철회 등이 제한되지 않습니다.
                    </BasicText>
                    <BasicText>
                      4. 이용자는 제1항 및 제2항의 규정에도 불구하고 재화 등의 내용이 표시, 광고 내용과 다르거나 계약
                      내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수
                      있었던 날부터 30일 이내에 청약 철회 등을 할 수 있습니다.
                    </BasicText>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제16조 (청약 철회 등의 효과)</BoldText>
                  <Tap>
                    <BasicText>
                      1.“폐쇄몰”은 이용자로부터 재화 등을 반환받은 경우 5영업일 이내에 이미 지급받은 재화 등의 대금을
                      환급합니다. 이 경우 “폐쇄몰”이 이용자에게 재화 등의 환급을 지연한 때에는 그 지연 기간에 대하여
                      「전자상거래 등에서의 소비자보호에 관한 법률 시행령」 제21조의3에서 정하는 지연 이자율을 곱하여
                      산정한 지연 이자를 지급합니다.
                    </BasicText>
                    <BasicText>
                      2.“폐쇄몰”은 위 대금을 환급함에 있어서 이용자가 신용카드 그밖에 대통령령이 정하는 결제 수단으로
                      재화 등의 대금을 지급한 때에는 지체 없이 당해 결제 수단을 제공한 사업자로 하여금 재화 등의 대금
                      청구를 정지 또는 취소하도록 요청합니다.
                    </BasicText>
                    <BasicText>
                      3.청약 철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. “폐쇄몰”은
                      이용자에게 청약 철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이
                      표시, 광고 내용과 다르거나 계약 내용과 다르게 이행되어 청약 철회 등을 하는 경우 재화 등의 반환에
                      필요한 비용은 “폐쇄몰”이 부담합니다.
                    </BasicText>
                    <BasicText>
                      4.이미 재화 등이 일부 사용 또는 일부 소비된 경우, “폐쇄몰”은 그 재화 등의 사용 또는 일부 소비에
                      의하여 이용자가 얻은 이익 또는 그 재화 등의 공급에 소요된 비용에 상당하는 금액으로서 「전자상거래
                      등에서의 소비자보호에 관한 법률 시행령」 제24조에서 정하는 범위의 금액의 지급을 이용자에게 청구할
                      수 있습니다.
                    </BasicText>
                    <BasicText>
                      5.이용자가 상품을 구매하여 적립금을 지급받은 경우 청약철회를 하기 위해서는 이를 “폐쇄몰”에
                      반환해야 합니다.
                    </BasicText>
                    <BasicText>
                      6.이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 “폐쇄몰”은 청약 철회 시 그 비용을 누가
                      부담하는지를 알기 쉽도록 명확하게 표시합니다.
                    </BasicText>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제17조 (적립금 등 제도 운영)</BoldText>
                  <Tap>
                    <BasicText>
                      1. 적립금은 “폐쇄몰” 내에서 현금처럼 사용할 수 있는 전자 화폐로 포인트라고 합니다.
                    </BasicText>
                    <BasicText>
                      2.“폐쇄몰” 회원으로 가입하시고, 적립금 지급 상품을 구입하실 경우, “폐쇄몰”의 운영정책에 따라 상품
                      별로 정해진 사은 적립금을 드립니다.
                    </BasicText>
                    <BasicText>
                      3.사은 적립금은 “폐쇄몰”에서 상품을 구매하실 경우 현금과 동일하게 사용하실 수 있으나, (상품권,
                      기프트카드, PP카드, 공동구매, 여행 등 일부 품목 제외) 현금으로 교환할 수 없습니다
                    </BasicText>
                    <BasicText>4.다음의 경우 적립금이 소멸됩니다.</BasicText>
                    <Tap>
                      <BasicText>1) 회원을 탈퇴한 경우</BasicText>
                      <BasicText>2) 각 지급 적립금별 정의된 사용 가능 조건 및 소멸에 대한 기준이 도래한 경우</BasicText>
                      <BasicText>
                        3) 최대 유효기간이 만기 되었을 경우 (당해 연도 10월 초일부터 다음 연도 9월 말일까지 발생한
                        적립금은 다음 연도 12월 말일에 소멸, 최대 유효기간은 15개월 이내)
                      </BasicText>
                    </Tap>
                    <BasicText>
                      5.적립금을 이용하여 부당 이득을 취하거나, 악의적인 상거래가 발생할 경우, “폐쇄몰”은 해당 적립금의
                      지급을 중지 또는 회수할 수 있습니다.
                    </BasicText>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제18조 (개인 정보보호)</BoldText>
                  <Tap>
                    <BasicText>
                      1. “폐쇄몰”은 이용자의 개인 정보 수집 시 서비스 제공을 위하여 필요한 범위에서 최소한의 개인 정보를
                      수집합니다.
                    </BasicText>
                    <BasicText>
                      2. “폐쇄몰”은 회원가입 시 구매계약 이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상
                      의무 이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인 정보를 수집하는
                      경우에는 그러하지 아니합니다.
                    </BasicText>
                    <BasicText>
                      3. “폐쇄몰”이 이용자의 개인 정보를 수집•이용하는 때에는 반드시 당해 이용자에게 그 목적을 고지하고
                      동의를 받습니다.
                    </BasicText>
                    <BasicText>
                      4.“폐쇄몰”은 수집된 개인 정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우
                      또는 제3자에게 제공하는 경우에는 이용•제공 단계에서 당해 이용자에게 그 목적을 고지하고 동의를
                      받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
                    </BasicText>

                    <BasicText>
                      5.“폐쇄몰”이 제2항과 제3항에 의해 이용자의 동의를 받아야만 하는 경우에는 개인 정보보호 책임자의
                      신원(소속, 성명 및 전화번호 기타 연락처), 정보의 수집 목적 및 이용 목적, 제3자에 대한 정보제공
                      관련 사항(제공받는 자, 제공 목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에
                      관한 법률」 및 「개인정보 보호법」에서 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는
                      언제든지 이 동의를 철회할 수 있습니다.
                    </BasicText>
                    <BasicText>
                      6. 이용자는 언제든지 “폐쇄몰”이 가지고 있는 자신의 개인 정보에 대해 열람 및 오류 정정을 요구할 수
                      있으며 “폐쇄몰”은 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을
                      요구한 경우에는 “폐쇄몰”은 그 오류를 정정할 때까지 당해 개인 정보를 이용하지 않습니다.
                    </BasicText>
                    <BasicText>
                      7. “폐쇄몰”은 개인 정보 보호를 위하여 이용자의 개인 정보를 처리하는 자를 최소한으로 제한하여야
                      하며 신용카드, 은행 계좌 등을 포함한 이용자의 개인 정보의 분실, 도난, 유출, 동의 없는 제3자 제공,
                      변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
                    </BasicText>
                    <BasicText>
                      8. “폐쇄몰” 또는 그로부터 개인 정보를 제공받는 제3자는 개인 정보의 수집 목적 또는 제공받은 목적을
                      달성한 때에는 당해 개인 정보를 지체 없이 파기합니다.
                    </BasicText>
                    <BasicText>
                      9. “폐쇄몰”은 개인 정보의 수집•이용•제공에 관한 동의란을 미리 선택한 것으로 설정해두지 않습니다.
                      또한 개인 정보의 수집•이용•제공에 관한 이용자의 동의 거절 시 제한되는 서비스를 구체적으로
                      명시하고, 필수 수집항목이 아닌 개인 정보의 수집•이용•제공에 관한 이용자의 동의 거절을 이유로
                      회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.
                    </BasicText>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제19조 (“폐쇄몰”의 의무)</BoldText>
                  <Tap>
                    <BasicText>
                      1. “폐쇄몰”은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는
                      바에 따라 지속적이고, 안정적으로 재화, 용역을 제공하는 데 최선을 다하여야 합니다.
                    </BasicText>
                    <BasicText>
                      2. “폐쇄몰”은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인 정보(신용 정보
                      포함) 보호를 위한 방안을 갖추어야 합니다.
                    </BasicText>
                    <BasicText>
                      3. “폐쇄몰”은 이용자가 원하지 않는 영리 목적의 광고성 전자 우편을 발송하지 않습니다.
                    </BasicText>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제20조 (회원의 ID 및 비밀번호에 대한 의무)</BoldText>
                  <Tap>
                    <BasicText>1. 제18조의 경우를 제외한 ID와 비밀번호에 관한 관리 책임은 회원에게 있습니다.</BasicText>
                    <BasicText>2. 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안 됩니다.</BasicText>
                    <BasicText>
                      3. 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로
                      “폐쇄몰”에게 통보하고 “폐쇄몰”의 안내가 있는 경우에는 그에 따라야 합니다.
                    </BasicText>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제21조 (이용자의 의무)</BoldText>
                  <Tap>
                    <BasicText>이용자는 다음 행위를 하여서는 안됩니다.</BasicText>
                    <Tap>
                      <BasicText>1) 개인 정보를 허위로 등록, 신청, 변경하는 행위</BasicText>
                      <BasicText>
                        2) 타인의 명의나 카드 정보 등을 도용하여 물품을 구입하거나, 물품 구입 등을 가장하여 “폐쇄몰”의
                        서비스를 이용하는 행위.
                      </BasicText>
                      <BasicText>
                        3) “폐쇄몰”이 제공하는 서비스 이용방법에 의하지 아니하고 비정상적인 방법(컴퓨터 프로그램
                        포함)으로 서비스를 이용하거나 시스템에 접속하는 행위
                      </BasicText>
                      <BasicText>4) “폐쇄몰” 및 기타 제3자의 지적재산권을 침해하는 행위</BasicText>
                      <BasicText>5) “폐쇄몰” 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</BasicText>
                      <BasicText>
                        6) 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 “폐쇄몰”에 공개 또는
                        게시하는 행위
                      </BasicText>
                    </Tap>
                  </Tap>
                </BasicTermsDiv>

                <BasicTermsDiv>
                  <BoldText>제22조 (저작권의 귀속 및 이용 제한)</BoldText>
                  <Tap>
                    <BasicText>
                      1. “폐쇄몰”이 작성한 저작물에 대한 저작권 기타 지적 재산권은 “폐쇄몰”에 귀속합니다.
                    </BasicText>
                    <BasicText>
                      2. 이용자는 “폐쇄몰”을 이용함으로써 얻은 정보 중 “폐쇄몰”에게 지적 재산권이 귀속된 정보를
                      “폐쇄몰”의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나
                      제3자에게 이용하게 하여서는 안됩니다.
                    </BasicText>
                    <BasicText>
                      3. “폐쇄몰”은 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야
                      합니다.
                    </BasicText>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제23조 (분쟁 해결)</BoldText>
                  <Tap>
                    <BasicText>
                      1. “폐쇄몰”은 이용자로부터 제출되는 불만 사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만,
                      신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리 일정을 즉시 통보합니다.
                    </BasicText>
                    <BasicText>
                      2. ”폐쇄몰”과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해 구제 신청이 있는
                      경우에는 공정거래위원회 또는 분쟁 조정 기관의 조정에 따를 수 있습니다.
                    </BasicText>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BoldText>제24조 (준거법 및 관할법원)</BoldText>
                  <Tap>
                    <BasicText>
                      ”폐쇄몰”과 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 “폐쇄몰”의 본사 소재지를 관할하는
                      법원을 전속 합의관할법원으로 정합니다.
                    </BasicText>
                  </Tap>
                </BasicTermsDiv>
                <BasicTermsDiv>
                  <BasicText>부칙 제1조 (약관의 효력)</BasicText>
                  <BasicText>본 약관은 2023.09.20 부터 적용합니다. </BasicText>
                </BasicTermsDiv>
              </FlexBox>
            </WhiteBox>
          </Container>
        </Wrapper>
      </ContentContainer>
    </>
  );
}

const BasicTermsDiv = styled.div`
  padding: 0 0 20px;
`;

const Tap = styled.div`
  padding-left: 10px;
  text-indent: -10px; /* 들여쓰기 제거 */
  margin-left: 10px; /* 새로운 들여쓰기 설정 */
`;

const BoldText = styled(Text)`
  font-weight: 700;
  padding: 0 0 6px 0;
  text-align: start;
`;

const BasicText = styled(Text)`
  text-align: start;
`;
