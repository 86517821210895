// 외부 모듈
import React, { useState } from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";

// 내부 모듈
import InfoModal from "../Modal/InfoModal";

function FileUpload({ setIsSelected, setFileData, placeholder, setJsonFile }) {
  const [textModal, setTextModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [isClick, setIsClick] = useState(false);
  const [fileName, setFileName] = useState("");

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const arrayBuffer = e.target.result;
      const workbook = XLSX.read(arrayBuffer, { type: "array" });
      const sheetName = workbook.SheetNames[0]; // 워크북의 첫 번째 시트 이름을 가져옵니다.
      const worksheet = workbook.Sheets[sheetName]; // 시트 이름을 사용해 해당 시트 객체를 가져옵니다.
      const json = XLSX.utils.sheet_to_json(worksheet);

      setJsonFile(json);
    };

    reader.readAsArrayBuffer(file); // readAsArrayBuffer를 사용하여 파일을 읽습니다.
  };

  const onChangeFile = (event) => {
    if (event.target.files[0]?.size === 0) {
      setTextMsg("파일이 비어있어 내용을 확인할 수 없습니다.\n파일을 다시 확인하시어 첨부해 주시기 바랍니다.");
      setTextModal(true);
    } else {
      const tempFileName = event.target.files[0]?.name;
      if (tempFileName) {
        setFileName(tempFileName);
        setIsSelected(true);
        setFileData(event.target.files[0]);
        if (setJsonFile) return handleFileUpload(event);
      }
    }
  };

  return (
    <>
      <StFileUpload>
        <StLabel htmlFor="file">
          {!isClick ? <StSpan>{placeholder}</StSpan> : <StSpan>{fileName ? fileName : placeholder}</StSpan>}
          <Stinput
            id="file"
            name="file_name"
            type="file"
            accept=".xlsx"
            onChange={(e) => {
              onChangeFile(e);
            }}
            onClick={() => setIsClick(true)}
          />
          <p>파일 선택</p>
        </StLabel>
      </StFileUpload>
      <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
    </>
  );
}

const StFileUpload = styled.div`
  width: 100%;
  height: 40px;
  background: #fff;
  position: relative;
  font-size: 14px;
  border: 1px solid #c7c7c7;
  border-radius: 6px;
`;

const StSpan = styled.span`
  color: #c7c7c7;
`;

const StLabel = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  width: 100%;
  height: 100%;
  position: absolute;
  color: #666666;
  cursor: pointer;
  p {
    font-weight: 500;
  }
`;

const Stinput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

export default FileUpload;
