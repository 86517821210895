import React, { useEffect, useState } from "react";
import styled from "styled-components";
import close from "../../Assets/images/close.png";
import { Btn } from "../../Assets/css/commonVer2";
import { Text } from "../../Assets/css/common";
import InfoModal from "../../Components/Modal/InfoModal";

// 최초 비밀번호 설정
// 비밀번호 찾기

function FindPw(props) {
  const [textModal, setTextModal] = useState(false); // 모달 창 표시 여부 상태
  const [textMsg, setTextMsg] = useState("");

  const { setModal, w, setW, userId } = props;
  const [id, setId] = useState("");
  const [pw, setPw] = useState("");
  const [ckpw, setCkPw] = useState("");
  const [enc_data, setEnc] = useState("");
  const [integrity_value, setIntegrity] = useState("");
  const [token_version_id, setVersion] = useState("");

  // setW("u");

  const onSubmit = async () => {
    // 비밀번호 변경이라면 휴대폰 인증 여부도 확인 필요
    alert("잠시후 다시 시도해주세요");
    setModal(false);
    // if (pw !== ckpw) {
    //   alert("패스워드가 동일하지 않습니다.");
    //   return false;
    // }

    // API.put(`/users/password`, {
    //   user_id: userId ? userId : id,
    //   password: pw,
    // })
    //   .then(function (res) {
    //     // if (res.data.msg === "존재하지 않는 아이디입니다") {
    //     //   alert(res.data.msg);
    //     //   return false;
    //     // }
    //     if (res.data.status) {

    //       alert("변경되었습니다.");
    //       setModal(false);
    //     } else {
    //       setTextMsg(res.data.msg || "잠시후 다시 시도해주세요");
    //       setTextModal(true);
    //       return false;
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log("3", error);
    //   });
  };

  useEffect(() => {
    const token = async () => {
      // (
      //   await axios.post(
      //     "https://svc.niceapi.co.kr:22001/digital/niceid/oauth/oauth/token"
      //   )
      // )
      //   .headers({
      //     "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      //     Authorization:
      //       "Basic Base64Encode(13d0baea-694a-4bae-ad4e-24d3d8188356:63bedc1dec36131b23b968fc7d761224)",
      //   })
      //   .then(function (res) {
      //     console.log("res :", res);
      //   })
      //   .catch(function (error) {
      //     console.log("3", error);
      //   });
      // await API.get(`/auth`)
      //   .then(function (res) {
      //     console.log("res :", res);
      //     setIntegrity(res.data.integrity_value);
      //     setVersion(res.data.token_version_id);
      //     setEnc(res.data.enc_data);
      //   })
      //   .catch(function (error) {
      //     console.log("3", error);
      //   });
    };
    token();
  }, []);

  // const token_version_id = '';
  // const enc_data = '/nOvDDH+UUW30UmMins5WN9xR5ZnijkBsVVymaFZMQtU7BsdAmu7r2VfGelZR+OvaMNDd5yzTyAKFFCMoN4C4iJDmyBk2UNa0tllEs4gcobIcAC1+PL4cxZRUW88AP5Xr5i8dvKkaMAMCgxyS518Q/CJM5TCA9wbIooLPJvuKrGQ53JpD8YxDkM7l8hDf5B3SS3cONLi+nMq5U/m5hatTCvJ9knd5GI5ngpMKdbCphEmmt2z5FldLecCOy/gtdLXqiC+VCUIjlziSusYSwvjbNB8I3WATbbg0p+MKcXpBnk=';
  // const integrity_value = 'fa68PuQehyzbYWgxrJ+6pQPUI3+wz2XqdAu9gATfWtY=';
  const HpComfirm = async () => {
    //   console.log('2',token_version_id);
    //   console.log('2',enc_data)
    //   console.log('2',integrity_value)
    // await API.get(`https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb?m=service&token_version_id=${token_version_id}&enc_data=${enc_data}&integrity_value=${integrity_value}`,{headers: {'Access-Control-Allow-Origin': '*'}})
    // .then(function (res) {
    //   console.log('1',res);
    //   // alert("등록이 완료되었습니다.")
    // })
    // .catch(function (error) {
    //   console.log('3', error);
    // });
  };

  const onModal = () => {
    setW("u");
  };

  const onClose = () => {
    setModal(false);
    setW("");
  };

  return (
    <ModalBg>
      <FindBox>
        <div
          style={{
            position: "absolute",
            right: 20,
            top: 20,
            cursor: "pointer",
          }}
          onClick={(props) => onClose()}
        >
          <Cancel src={close} />
        </div>
        <Text color={"#000"} size={"20px"} sizeMd={"26px"} margin={"0 0 20px"} marginMd={"0 0 32px"} weight={"700"}>
          {w === "u" ? "최초비밀번호 변경" : "비밀번호 찾기"}
        </Text>
        <PwInner>
          {!w ? (
            <InnerBox>
              <Text
                family={"Montserrat"}
                align={"left"}
                size={"12px"}
                sizeMd={"14px"}
                margin={"0 0 8px"}
                marginMd={"0 0 10px"}
                weight={"700"}
                color={"#000"}
              >
                ID
              </Text>
              <InputBox type={"text"} placeholder={"아이디 입력"} value={id} onChange={(e) => setId(e.target.value)} />
            </InnerBox>
          ) : (
            ""
          )}
          {!w ? (
            <InnerBox>
              <Text
                family={"Montserrat"}
                align={"left"}
                size={"12px"}
                sizeMd={"14px"}
                margin={"0 0 8px"}
                marginMd={"0 0 10px"}
                weight={"700"}
                color={"#000"}
              >
                Mobile Number
              </Text>
              <InputBtnWrap>
                <InputBox style={{ marginRight: "10px" }} type={"text"} placeholder={"전화번호 입력"} />
                <Btn
                  size={"16px"}
                  weight={"700"}
                  color={"#fff"}
                  background={"#C7C7C7"}
                  border={"none"}
                  padding={"0"}
                  width={"84px"}
                  hoverBackground={"#FF4A4A"}
                >
                  인증번호
                </Btn>
              </InputBtnWrap>
              <InputBtnWrap>
                <InputBox style={{ marginRight: "10px" }} type={"text"} placeholder={"인증번호 입력"} />
                <Btn
                  size={"16px"}
                  weight={"700"}
                  color={"#fff"}
                  background={"#C7C7C7"}
                  border={"none"}
                  padding={"0"}
                  width={"84px"}
                  hoverBackground={"#FF4A4A"}
                  onClick={() => HpComfirm()}
                >
                  인증 확인
                </Btn>
              </InputBtnWrap>
            </InnerBox>
          ) : (
            ""
          )}

          <PwBox
            w={w}
            // style={w ? { marginBottom: "120px" } : { marginBottom: "60px" }}
          >
            <Text
              family={"Montserrat"}
              align={"left"}
              size={"12px"}
              sizeMd={"14px"}
              margin={"0 0 8px"}
              marginMd={"0 0 10px"}
              weight={"700"}
              color={"#000"}
            >
              Password
            </Text>
            <InputBox
              type={"password"}
              placeholder={"새 비밀번호 입력"}
              value={pw}
              onChange={(e) => setPw(e.target.value)}
              style={{ marginBottom: "10px" }}
            />
            <InputBox
              type={"password"}
              placeholder={"새 비밀번호 확인"}
              value={ckpw}
              onChange={(e) => setCkPw(e.target.value)}
            />
          </PwBox>

          <Btn
            type={"submit"}
            color={"#fff"}
            background={"#ff4a4a"}
            radius={"40px"}
            size={"18px"}
            sizeMd={"20px"}
            padding={"13px"}
            paddingMd={"16px"}
            width={"100%"}
            weight={"700"}
            onClick={onSubmit}
          >
            비밀번호 변경
          </Btn>
        </PwInner>
      </FindBox>
      <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
    </ModalBg>
  );
}

const Cancel = styled.img``;
// 패스워드 찾기
const ModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 1024px) {
    background-color: #fff;
  }
`;
const FindBox = styled.div`
  background-color: #fff;
  max-width: 558px;
  width: 100%;
  padding: 60px 97px;
  border-radius: 8px;
  position: relative;
  height: 90%;
  overflow: auto;

  @media all and (max-width: 1024px) {
    max-width: calc(100% - 36px);
    padding: 30px 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
`;

const PwInner = styled.div``;

const InnerBox = styled.div`
  margin-bottom: 32px;

  @media all and (max-width: 1024px) {
    margin-bottom: 20px;
  }
`;

const PwBox = styled.div`
  margin-bottom: ${(props) => (props.w ? "120px" : "60px")};

  @media all and (max-width: 1024px) {
    margin-bottom: 40px;
  }
`;

const InputBox = styled.input.attrs({
  placeholderColor: "#c7c7c7",
})`
  width: 100%;
  height: 60px;
  border-radius: 6px;
  border: 1px solid #c7c7c7;
  outline: none;
  padding: 0 16px;
  font-size: 16px;
  color: 11eE1E;

  &:focus {
    border: ${(props) => props.border || "1px solid #666666"};
  }

  @media all and (max-width: 1024px) {
    height: 48px;
    font-size: 14px;
    padding: 0 10px;
  }
`;
const InputBtnWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;

  input {
    flex: 1;
  }
`;

export default FindPw;
