import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import Header from "../../Components/Header/HeaderVer2";
import {
  Btn,
  ContentContainer,
  Title,
  WhiteBox,
  Container,
  Wrapper,
  FlexBox,
  Text,
  TableWrap,
  Th,
  Td,
} from "../../Assets/css/commonVer2";
import API from "../../Utiles/API";
import Authority from "../../Components/Modal/Authority";
import { AddComma, Xlsx_download } from "../../Utiles/Custom";
import { UNIT_10_TO_30_LIST } from "../../Utiles/ListData";
import Pagination from "../../Components/Paging/Pagination";
import BasicSelect from "../../Components/Select/BasicSelect";
import InfoModal from "../../Components/Modal/InfoModal";
import HandleBack from "../../Components/HandleBack";

function FranchiseeInquiry() {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const { handlePopstate } = HandleBack();

  const [excelData, setExcelData] = useState([]); // 엑셀 데이터
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState("");
  const [unit, setUnit] = useState(10);
  const [nowPage, setNowPage] = useState(1);
  const [cnt, setCnt] = useState(0);
  const [textModal, setTextModal] = useState(false); // 모달 창 표시 여부 상태
  const [textMsg, setTextMsg] = useState("");

  const start = (nowPage - 1) * unit;

  const getInquiryList = () => {
    API.get(`/admin/franchise-inquiry?page=${nowPage}&unit=${unit}&block=10`)
      .then(function (res) {
        if (res) {
          setCnt(res.data.cntAll);
          setData(res.data.results);
          setPagination(res.data.pagination);
        }
      })
      .catch(function (error) {
        // console.log("3", error);
      });
  };

  const getExcelData = () => {
    if (cnt > 0) {
      API.get(`/admin/franchise-inquiry?page=1&unit=999999&type=excel`)
        .then(function (res) {
          if (res) {
            setExcelData(res.data.results);
          } else {
            setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
            setTextModal(true);
          }
        })
        .catch(function (error) {
          // console.log("3", error);
          setTextMsg("E01\n잠시후 다시 시도해주세요.");
          setTextModal(true);
        });
    } else {
      setTextMsg("다운받을 데이터가 없습니다.");
      setTextModal(true);
    }
  };

  var xlsxTemp = excelData.map(function (item, idx) {
    return {
      No: idx + 1,
      이름: item?.name,
      이메일: item?.email,
      전화번호: item?.tel,
      나이대: item?.age,
      희망지역: item?.hope_location,
      희망오픈시기: item?.possible_open_moment,
      연락가능시간대: item?.possible_contact_time || "-",
      문의내용: item?.question,
      작성날짜: item?.createdAt,
      utm_source: item?.utm_source || "-",
      utm_medium: item?.utm_medium || "-",
      utm_campaign: item?.utm_campaign || "-",
      utm_term: item?.utm_term ? decodeURI(decodeURIComponent(item?.utm_term)) : "-",
    };
  });

  const onExcelDown = () => {
    Xlsx_download(xlsxTemp, "가맹 문의 목록");
  };

  function movePage(e) {
    setNowPage(e);
  }

  useEffect(() => {
    getInquiryList();
  }, []);

  useEffect(() => {
    if (nowPage === 1) {
      // 유닛 변경 시 현재페이지가 1일 때 통과
      getInquiryList();
    } else {
      // 유닛 변경 시 현재페이지가 1아닐 때 통과
      setNowPage(1);
    }
  }, [unit]);

  useEffect(() => {
    getInquiryList();
  }, [nowPage]);

  useEffect(() => {
    if (excelData.length > 0) onExcelDown();
  }, [excelData]);

  useEffect(() => {
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          {isMobile && <Header title={"가맹 문의 조회"} />}
          <ContentContainer>
            <Wrapper type={2}>
              <Container>
                {!isMobile && (
                  <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                    가맹문의 조회 📋
                  </Title>
                )}

                <WhiteBox margin={"0 0"}>
                  <FlexBox
                    justify={"space-between"}
                    margin={"0 0 16px"}
                    marginMd={"0 0 30px"}
                    wrap={"wrap"}
                    wrapMd={"no-wrap"}
                    gap={"0"}
                    gapMd={"10px"}
                  >
                    <FlexBox gap={"10px"} flex={"1"} flexMd={"none"}>
                      {!isMobile && (
                        <BasicSelect
                          width={"130px"}
                          data={UNIT_10_TO_30_LIST}
                          selectedValue={unit}
                          setSelectedValue={setUnit}
                          canSelectAll={false}
                          setCurrentPage={setNowPage}
                        />
                      )}
                      <Text
                        width={"100%"}
                        widthMd={"unset"}
                        color={"#FF4A4A"}
                        size={"16px"}
                        padding={"10px 29px 10px 27px"}
                        background={"rgba(255, 116, 64, 0.10)"}
                        radius={"8px"}
                        letterSpacing={"-0.32px"}
                        style={{
                          flexShrink: 0,
                        }}
                      >
                        총 문의 건수 : <span style={{ fontWeight: "700" }}>{AddComma(cnt ? cnt : "0")}건</span>
                      </Text>
                    </FlexBox>

                    <FlexBox gap={"10px"} style={{ marginLeft: "auto" }}>
                      {!isMobile && (
                        <Btn
                          size={"14px"}
                          padding={"8px 18px"}
                          border={"1px solid #666"}
                          color={"#666"}
                          hoverBackground={"#C7C7C7"}
                          hoverColor={"#fff"}
                          hoverBorder={"transparent"}
                          lineHeight={"22px"}
                          hoverWeight={"700"}
                          onClick={getExcelData}
                        >
                          엑셀 다운
                        </Btn>
                      )}
                    </FlexBox>
                  </FlexBox>
                  <TableWrap>
                    <table style={{ width: "100%", minWidth: "1024px" }}>
                      <colgroup>
                        <col style={{ width: "6%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "14%" }} />
                        <col style={{ width: "8%" }} />
                        <col />
                        <col style={{ width: "16%" }} />
                        <col style={{ width: "12%" }} />
                        <col style={{ width: "18%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <Th>No</Th>
                          <Th>이름</Th>
                          <Th>전화번호</Th>
                          <Th>나이대</Th>
                          <Th>희망지역</Th>
                          <Th>희망오픈시기</Th>
                          <Th>연락가능시간대</Th>
                          <Th>문의일자</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 ? (
                          data?.map((lsit, idx) => (
                            <tr key={idx}>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{start + idx + 1}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{lsit.name}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{lsit.tel}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{lsit.age}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{lsit.hope_location}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{lsit.possible_open_moment}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{lsit.possible_contact_time || "-"}</Td>
                              <Td style={{ borderBottom: "1px solid #F2F2F2" }}>{lsit.createdAt}</Td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={8}
                              style={{
                                borderTop: "1px solid #e1e1e1",
                                textAlign: "center",
                                padding: 10,
                                fontSize: "14px",
                              }}
                            >
                              데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TableWrap>
                  {pagination?.total_page > 0 ? (
                    <Pagination pagination={pagination} movePage={movePage} currentPage={nowPage} />
                  ) : (
                    ""
                  )}
                </WhiteBox>
              </Container>
              <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
            </Wrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}

export default FranchiseeInquiry;
