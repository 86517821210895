import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  TableWrap,
  Th,
  Td,
  Btn,
  SearchInput,
  Wrapper,
  Container,
  ContentContainer,
  FlexBox,
  Text,
  WhiteBox,
  Title,
} from "../../Assets/css/commonVer2";

import { SEARCH_TYPE_LIST, UNIT_10_TO_30_LIST } from "../../Utiles/ListData";
import Header from "../../Components/Header/HeaderVer2";
import { Xlsx_download, AddComma } from "../../Utiles/Custom";
import API from "../../Utiles/API";
import ConfirmModal from "../../Components/Modal/ConfirmModalVer2";
import Authority from "../../Components/Modal/Authority";
import Pagination from "../../Components/Paging/Pagination";
import Cate2Select from "../../Components/Select/Cate2Select";
import BasicSelect from "../../Components/Select/BasicSelect";
import ProductSearchInputVer2 from "../../Components/SearchInput/ProductSearchInputVer2";
import FranSelect from "../../Components/Select/FranSelect";
import InfoModal from "../../Components/Modal/InfoModal";
import InputModal from "../../Components/Modal/InputModal";

// 자점매입 > 자점매입상품관리

function Product() {
  const [franchise_id, setFranchiseId] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  // 필터 관련
  const [cate2Value, setCate2Value] = useState("");
  const [statusValue, setStatusValue] = useState("");

  // 검색 관련
  const [filter, setFilter] = useState("product_name");
  const [inputValue, setInputValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchList, setShowSearchList] = useState(false);
  const [franShow, setFranShow] = useState(false);
  const [franText, setFranText] = useState("가맹점 전체");

  // 페이징 관련
  const [pagination, setPagination] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // 데이터 관련
  const [data, setData] = useState([]);
  const [slotCnt, setSlotCnt] = useState(0);
  const [checkItems, setCheckItems] = useState([]);

  // 모달들
  const [requestConfirmModal, setRequestConfirmModal] = useState(false);
  const [textInputModal, setTextInputModal] = useState(false);
  const [inputReason, setInputReason] = useState("");
  const [textModal, setTextModal] = useState(false);
  const [refreshModal, setRefreshModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");
  const [reasonModal, setReasonModal] = useState(false);
  const [reasonModalTxts] = useState({
    title: "",
    text: "",
    reason: "",
  });

  const getSlotList = async () => {
    let api_link = `/slot/user/product?page=${currentPage}&unit=${pageSize}&block=10&gubun=admin`;
    if (franchise_id) api_link += `&franchise_id=${franchise_id}`;
    if (franchise_id == 9) api_link += `&type=pass`;
    if (cate2Value) api_link += `&category2nd_filter=${cate2Value}`;
    if (inputValue) api_link += `&search_type=${filter}&search_word=${inputValue}`;
    if (statusValue) api_link += `&slot_status_filter=${statusValue}`;

    await API.get(api_link).then((res) => {
      if (res.data.status) {
        setSlotCnt(res.data.cntAll);
        setPagination(res.data.pagination);
        setData(res.data.results);
      }
    });
  };

  const getReason = async (idx, gubun) => {
    await API.get(`/slot/refuse-reason?slot_id=${idx}`).then((res) => {
      if (res.data.status) {
        reasonModalTxts.reason = res.data.results.refuse_reason || "잠시후 다시 시도해주세요.";
        if (gubun === "reject") {
          reasonModalTxts.title = "검수 승인이 반려되었습니다.";
          reasonModalTxts.text = "반려 사유는 아래와 같습니다. 상품 수정 후 다시 검수 요청을 해주세요.";
        } else {
          reasonModalTxts.title = "판매가 중지되었습니다.";
          reasonModalTxts.text = "판매 중지 사유는 아래와 같습니다. 상품 수정 후 다시 검수 요청을 해주세요.";
        }
        setReasonModal(true);
      } else {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    });
  };

  const setStopSale = async () => {
    await API.put(`/slot/admin-checking`, {
      change_status: "stopSale",
      return_reason: inputReason,
      request_list: checkItems,
    })
      .then((res) => {
        if (res.data.status) {
          setTextMsg("판매중지 처리되었습니다.");
          setRefreshModal(true);
        } else {
          setTextMsg(res.data.msg || "잠시후 다시 시도해주세요.");
          setTextModal(true);
        }
      })
      .catch(() => {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      });
  };

  const movePage = (e) => {
    setCurrentPage(e);
    setCheckItems([]);
  };

  const onExcelDown = async () => {
    // 데이터 유무
    if (data.length > 0) {
      // api 호출
      let api_link = `/slot/user/product?page=1&unit=99999999&block=10&gubun=admin`;
      if (franchise_id) api_link += `&franchise_id=${franchise_id}`;
      if (cate2Value) api_link += `&category2nd_filter=${cate2Value}`;
      if (inputValue) api_link += `&search_type=${filter}&search_word=${inputValue}`;
      if (statusValue) api_link += `&slot_status_filter=${statusValue}`;

      const res = await API.get(api_link);
      if (res.data.status && res.data.results.length > 0) {
        // 엑셀 포맷으로 변경
        var xlsxTemp = res.data.results.map(function (item) {
          return {
            가맹점명: item.franchise_name ? item.franchise_name : "-",
            상품분류: item.category2nd_name,
            바코드번호: item.product_barcode_num,
            상품명: item.product_product_name,
            소비자가: item.product_consumer_price,
          };
        });
        // 다운로드 실행
        Xlsx_download(xlsxTemp, `자점매입상품`);
      } else {
        setTextMsg("E01\n잠시후 다시 시도해주세요.");
        setTextModal(true);
      }
    } else {
      setTextMsg("다운받을 데이터가 없습니다.");
      setTextModal(true);
    }
  };

  const onChangeChx = (checked, id) => {
    if (checked) {
      setCheckItems((prev) => [...prev, id]);
    } else {
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  const onChangeAllChk = (checked) => {
    if (checked) {
      const chkArr = data.map((item) => item.idx);
      setCheckItems(chkArr);
    } else {
      setCheckItems([]);
    }
  };

  const onSubmit = (e) => {
    if (e === 1) {
      setRequestConfirmModal(false);
      const isAllItemsRight = checkItems.every((idx) => data.find((item) => item.idx === idx)?.status === "onSale");
      if (isAllItemsRight) {
        setTextInputModal(true);
      } else {
        setTextMsg("상태가 '판매중'인 상품만 판매 중지할 수 있습니다.");
        setTextModal(true);
      }
    } else if (e === 2) {
      if (inputReason !== "") {
        setTextInputModal(false);
        setStopSale();
        setInputValue("");
      } else {
        alert("판매중지 사유를 입력해주세요");
      }
    }
  };

  const onSearch = () => {
    setSearchResults([]);
    setShowSearchList(false);
    setCurrentPage(1);
    getSlotList();
    setCheckItems([]);
  };

  useEffect(() => {
    setInputValue("");
  }, [filter]);

  useEffect(() => {
    getSlotList();
  }, [currentPage, pageSize]);

  return (
    <>
      {sessionStorage.getItem("gubun") !== "admin" ? (
        <Authority title={"관리자"} />
      ) : (
        <>
          <>
            {isMobile && <Header title={"자점매입상품 관리"} />}
            <ContentContainer>
              <Wrapper type={2}>
                <Container>
                  {!isMobile && (
                    <Title size={"26px"} color={"#1E1E1E"} weight={"700"}>
                      자점매입 상품관리 📋
                    </Title>
                  )}
                  {/* 공통 - 필터 시작 */}
                  <WhiteBox margin={"0 0"}>
                    <FlexBox
                      justify={"end"}
                      gap={"10px"}
                      gapMd={"15px"}
                      margin={"0 0 "}
                      marginMd={"28px 0 20px 0 "}
                      wrap={"wrap"}
                    >
                      <FranSelect
                        widthMd={"48%"}
                        gubun={"fran"}
                        selectBtnText={franText}
                        setTextChange={setFranText}
                        placeholder={"가맹점을"}
                        show={franShow}
                        setFranShow={setFranShow}
                        setIdxChange={setFranchiseId}
                      />
                      <Cate2Select widthMd={"48%"} idx={1} cate2Value={cate2Value} setCate2Value={setCate2Value} />
                      {/* <BasicSelect
                        widthMd={"48%"}
                        data={INDIV_SLOT_STATUS_LIST}
                        selectedValue={statusValue}
                        setSelectedValue={setStatusValue}
                        placeholder={"상태 전체"}
                        canSelectAll={true}
                      /> */}
                      <BasicSelect
                        widthMd={"48%"}
                        data={SEARCH_TYPE_LIST}
                        selectedValue={filter}
                        setSelectedValue={setFilter}
                        canSelectAll={false}
                      />
                      {!isMobile && (
                        <FlexBox gap={"10px"}>
                          {filter === "barcode_num" ? (
                            <SearchInput
                              borderBottom={"1px solid #666666"}
                              placeholder="바코드번호를 입력해주세요."
                              value={inputValue}
                              onChange={(e) => setInputValue(e.target.value)}
                            />
                          ) : (
                            <ProductSearchInputVer2
                              showSearchList={showSearchList}
                              setShowSearchList={setShowSearchList}
                              searchResults={searchResults}
                              setSearchResults={setSearchResults}
                              inputValue={inputValue}
                              setInputValue={setInputValue}
                              onSearch={onSearch}
                              cate1value={2}
                              franchiseId={franchise_id}
                            />
                          )}
                          <Btn
                            color={"#fff"}
                            background={"#FF4A4A"}
                            radius={"6px"}
                            size={"14px"}
                            weight={"700"}
                            padding={"9px 19px"}
                            maxHeight={"40px"}
                            style={{ lineHeight: "22px" }}
                            onClick={onSearch}
                          >
                            검 색
                          </Btn>
                        </FlexBox>
                      )}
                    </FlexBox>
                    {isMobile && (
                      <FlexBox gap={"10px"} margin={"10px 0"}>
                        {filter === "barcode_num" ? (
                          <SearchInput
                            placeholder="바코드번호를 입력해주세요."
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                          />
                        ) : (
                          <ProductSearchInputVer2
                            showSearchList={showSearchList}
                            setShowSearchList={setShowSearchList}
                            searchResults={searchResults}
                            setSearchResults={setSearchResults}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            onSearch={onSearch}
                            cate1value={2}
                            franchiseId={franchise_id}
                          />
                        )}
                        <Btn
                          color={"#fff"}
                          background={"#FF4A4A"}
                          radius={"6px"}
                          size={"14px"}
                          weight={"700"}
                          width={"80px"}
                          padding={"9px 19px"}
                          lineHeight={"22px"}
                          onClick={onSearch}
                        >
                          검 색
                        </Btn>
                      </FlexBox>
                    )}
                    <FlexBox
                      justify={"space-between"}
                      margin={"0 0 15px"}
                      marginMd={"0 0 30px"}
                      gap={"10px"}
                      gapMd={"10px"}
                      wrap={"wrap"}
                    >
                      <FlexBox justify={"starts"}>
                        <Text
                          width={"100%"}
                          widthMd={"unset"}
                          color={"#FF4A4A"}
                          size={"16px"}
                          weight={"400"}
                          padding={"10px 29px 10px 27px"}
                          background={"rgba(255, 116, 64, 0.10)"}
                          marginMd={"0 10px 0 0 "}
                          radius={"8px"}
                          letterSpacing={"-0.32px"}
                          lineHeight={"normal"}
                        >
                          슬롯 수량 : <span style={{ fontWeight: "700" }}>{AddComma(slotCnt ? slotCnt : "")}개</span>
                        </Text>
                        {!isMobile && (
                          <BasicSelect
                            data={UNIT_10_TO_30_LIST}
                            selectedValue={pageSize}
                            setSelectedValue={setPageSize}
                            canSelectAll={false}
                            setCurrentPage={setCurrentPage}
                          />
                        )}
                      </FlexBox>
                      <FlexBox justify={"end"} gap={"15px"}>
                        {!isMobile && (
                          <Btn
                            border={"1px solid #666666"}
                            hoverBorder={"1px solid #C7C7C7"}
                            hoverBackground={"#C7C7C7"}
                            color={"#666666"}
                            hoverColor={"#FFFFFF"}
                            weight={"500"}
                            hoverWeight={"700"}
                            padding={"9px 19px"}
                            size={"14px"}
                            radius={"6px"}
                            lineHeight={"22px"}
                            onClick={onExcelDown}
                          >
                            엑셀 다운
                          </Btn>
                        )}
                      </FlexBox>
                    </FlexBox>
                    {/* 공통 - 데이터 시작 */}
                    <TableWrap>
                      <table style={{ width: "100%", minWidth: "1024px" }}>
                        <thead>
                          <tr>
                            <Th>가맹점명</Th>
                            <Th>상품분류</Th>
                            <Th>바코드 번호</Th>
                            <Th>상품명</Th>
                            <Th>소비자가</Th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.length > 0 ? (
                            data.map((list, key) => (
                              <tr
                                style={{
                                  cursor: "pointer",
                                }}
                                key={key}
                                // onMouseEnter={() => {
                                //   setHoverRow(key);
                                // }}
                                // onMouseLeave={() => {
                                //   setHoverRow(null);
                                // }}
                                onClick={(event) => {
                                  const targetElement = event.target;
                                  // 체크박스 클릭 시 반복호출 방지
                                  if (targetElement.nodeName !== "INPUT") {
                                    const dataType = targetElement.getAttribute("data-type");

                                    // 1. 클릭한 곳이 상태(status)인지 체크
                                    if (dataType === "status") {
                                      if (list.status === "reject" || list.status === "stopSale") {
                                        getReason(list.idx, list.status);
                                        return;
                                      }
                                    }
                                    const isChecked = document.getElementById(`prod${list.idx}`)?.checked;
                                    onChangeChx(!isChecked, list.idx);
                                  }
                                }}
                              >
                                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                  {list.franchise_name ? list.franchise_name : "덕은한강점"}
                                </Td>
                                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                  {list.category2nd_name ? list.category2nd_name : "-"}
                                </Td>
                                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                  {list.product_barcode_num ? list.product_barcode_num : "-"}
                                </Td>
                                <Td data-type="product_name" style={{ borderBottom: "1px solid #F2F2F2" }}>
                                  {list.product_product_name ? list.product_product_name : "-"}
                                </Td>
                                <Td style={{ borderBottom: "1px solid #F2F2F2" }}>
                                  {list.product_consumer_price ? AddComma(list.product_consumer_price) : "-"}
                                  {list.product_consumer_price ? "원" : ""}
                                </Td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan={5}
                                style={{
                                  borderTop: "1px solid #e1e1e1",
                                  textAlign: "center",
                                  padding: 10,
                                  fontSize: "14px",
                                }}
                              >
                                데이터가 없습니다.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </TableWrap>
                    {pagination?.total_page > 0 ? (
                      <Pagination pagination={pagination} movePage={movePage} currentPage={currentPage} />
                    ) : (
                      ""
                    )}
                  </WhiteBox>
                </Container>
                <InfoModal modal={textModal} setModal={setTextModal} description={textMsg} />
                {requestConfirmModal && (
                  <ConfirmModal
                    modal={requestConfirmModal}
                    setModal={setRequestConfirmModal}
                    onSubmit={() => onSubmit(1)}
                    description={"선택한 상품을 \n판매중지 상태로 변경하시겠습니까?"}
                  />
                )}
                <InfoModal modal={refreshModal} setModal={setRefreshModal} description={textMsg} mode={"refresh"} />
                <InputModal
                  modal={textInputModal}
                  setModal={setTextInputModal}
                  mode={"writeReason"}
                  title={"판매중지 사유"}
                  placeholder={"판매중지 사유를 작성해주세요."}
                  inputValue={inputReason}
                  setInputValue={setInputReason}
                  onSubmit={() => onSubmit(2)}
                />
                <InfoModal
                  modal={reasonModal}
                  setModal={setReasonModal}
                  title={reasonModalTxts.title}
                  description={reasonModalTxts.text}
                  detailText={reasonModalTxts.reason}
                />
              </Wrapper>
            </ContentContainer>
          </>
        </>
      )}
    </>
  );
}

export default Product;
